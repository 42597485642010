import { useMemo } from 'react'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import Box from '@mui/system/Box'
import CloudBox from 'components/Forecast/CloudBox'

const useStyles = makeStyles({
  topBar: {
    height: '14px',
    width: '100%'
  }
})

interface CardDailyCompositeForecastProps {
  selected: boolean
  handleCardClick: () => void
  dayOfWeek: string
  dateOfForecast: string
  aggregateChanceOfIncident: number
  selectedProviderId: string
  hasPrediction: boolean
}

export default function CardDailyCompositeForecast(
  props: CardDailyCompositeForecastProps
) {
  const {
    selected,
    handleCardClick,
    dayOfWeek,
    dateOfForecast,
    aggregateChanceOfIncident,
    hasPrediction
  } = props

  const classes = useStyles()

  const forecastText = useMemo(() => {
    if (hasPrediction) {
      if (aggregateChanceOfIncident <= 50) {
        return `Chance of Incident ${aggregateChanceOfIncident}%`
      } else if (aggregateChanceOfIncident > 50) {
        return `Incident likely ${aggregateChanceOfIncident}%`
      }
    }
    return 'No Forecast'
  }, [aggregateChanceOfIncident, hasPrediction])

  return (
    <Card
      style={{
        height: '480px'
      }}
    >
      <CardActionArea onClick={handleCardClick}>
        <Box
          className={classes.topBar}
          sx={{
            background: `${
              aggregateChanceOfIncident === 0
                ? 'transparent'
                : aggregateChanceOfIncident < 20
                  ? 'green'
                  : aggregateChanceOfIncident >= 20 &&
                      aggregateChanceOfIncident < 50
                    ? 'yellow'
                    : 'red'
            }`,
            border: `1px solid  ${
              aggregateChanceOfIncident === 0
                ? 'gray'
                : aggregateChanceOfIncident < 20
                  ? 'green'
                  : aggregateChanceOfIncident >= 20 &&
                      aggregateChanceOfIncident < 50
                    ? 'yellow'
                    : 'red'
            }`
          }}
        />
        <CardHeader
          avatar={
            <input
              type="checkbox"
              checked={selected}
              onChange={handleCardClick}
            />
          }
        />
        <CardContent>
          <h2 style={{ textAlign: 'center' }}>{forecastText}</h2>
          <CloudBox
            name=""
            percent={aggregateChanceOfIncident}
            hasPrediction={hasPrediction}
          />

          <Typography
            gutterBottom
            variant="h5"
            component="div"
            align={'center'}
          >
            {dayOfWeek}
          </Typography>
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            align={'center'}
          >
            {dateOfForecast}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
