import Skeleton from '@mui/material/Skeleton'
import SharedStyles from './SharedStyles'

export default function CanaryEditPageLoader() {
  const classes = SharedStyles()
  return (
    <div
      className={classes.loadingSkeletons}
      style={{ width: '100%', padding: '2rem', paddingTop: '1rem' }}
    >
      <Skeleton
        variant="rectangular"
        width="20%"
        height={45}
        className={classes.skeleton}
      />
      <div className={classes.skeletonsRow} style={{ paddingTop: '1rem' }}>
        <Skeleton
          variant="rectangular"
          width="55%"
          height={100}
          className={classes.skeleton}
        />
      </div>
    </div>
  )
}
