import { selectNoDataRequiredAvailableModalOpen } from 'client_side_state/slices/app'
import { useAppSelector } from 'client_side_state/store'
import useDocumentTitle from 'hooks/useDocumentTitle'
import { useContext } from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Icon, { IconType } from 'components/Icons'
import NoRequiredDataAvailableDialog, {
  NoRequiredDataAvailableMessage
} from 'components/Modals/NoRequiredDataAvailableDialog'
import { AdminPagesTitles } from 'components/MyAdminPages/Constants'
import MyAdminPagesCommonHeader from 'components/MyAdminPages/MyAdminPagesCommonHeader'
import NoDataFound from 'components/MyAdminPages/NoDataFound'
import CreateModal from 'components/ServiceSettingsGroup/CreateModal'
import DeleteModal from 'components/ServiceSettingsGroup/DeleteModal'
import EditModal from 'components/ServiceSettingsGroup/EditModal'
import ProvidersFilter from 'components/ServiceSettingsGroup/ProvidersFilter'
import PagesSkeletonLoader from 'components/common/Loaders/PagesSkeletonLoader'
import { rowsPerPageOptions } from 'constants/AppConfig'
import { OrganizationsContext } from 'contexts/OrganizationsContext'
import { ServiceSettingsGroupContext } from 'contexts/ServiceSettingsGroupContext'
import adminPagesSharedStyles from 'pages/AdminPages/adminPagesSharedStyles'

export default function SettingsGroup() {
  const classes = adminPagesSharedStyles()
  useDocumentTitle('Service Setting Groups')

  const noRequiredDataAvailableDialogOpen = useAppSelector(
    selectNoDataRequiredAvailableModalOpen
  )

  const { organizationId } = useContext(OrganizationsContext)
  const {
    filteredServiceSettingsGroups,
    areServiceSettingsGroupsLoading,
    rowCount,
    page,
    onRowsPerPageChange,
    handleChangePage,
    rowsPerPage
  } = useContext(ServiceSettingsGroupContext)

  return (
    <Container maxWidth="xl" className={classes.mainContainer}>
      <MyAdminPagesCommonHeader />
      <h1 className={classes.headerRow}>
        {AdminPagesTitles.SettingGroups}
        {noRequiredDataAvailableDialogOpen && (
          <NoRequiredDataAvailableDialog
            message={NoRequiredDataAvailableMessage.CanaryableServices}
          />
        )}
        <CreateModal />
      </h1>
      {areServiceSettingsGroupsLoading ? (
        <PagesSkeletonLoader />
      ) : (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <ProvidersFilter />
          </Box>
          <TableContainer component={Paper} elevation={3}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  {!organizationId && <TableCell>Organization</TableCell>}
                  <TableCell align="center" className={classes.smallCell}>
                    Default
                  </TableCell>
                  <TableCell>Provider</TableCell>
                  <TableCell>Serivce</TableCell>
                  <TableCell>Creation Date</TableCell>
                  <TableCell>Period</TableCell>
                  <TableCell>Urgency</TableCell>
                  <TableCell>Impact</TableCell>
                  <TableCell align="center" width={200}>
                    Sync Alarms to Start
                  </TableCell>
                  <TableCell align="center" width={200}>
                    Recovered Alarms
                  </TableCell>
                  <TableCell className={classes.firstCell}>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredServiceSettingsGroups &&
                filteredServiceSettingsGroups?.length > 0 ? (
                  filteredServiceSettingsGroups.map((serviceSettingsGroup) => {
                    const creationDate = new Date(serviceSettingsGroup.created!)
                      .toLocaleString()
                      .split(',')[0]
                    return (
                      <TableRow key={serviceSettingsGroup.id}>
                        <TableCell>
                          <EditModal
                            settingsGroup={serviceSettingsGroup}
                            currentName={serviceSettingsGroup.name!}
                          />
                        </TableCell>
                        {!organizationId && (
                          <TableCell>
                            {serviceSettingsGroup.organization_name}
                          </TableCell>
                        )}
                        <TableCell align="center" className={classes.smallCell}>
                          <Icon
                            name={
                              serviceSettingsGroup.is_default
                                ? IconType.StateClear
                                : IconType.None
                            }
                            style={{
                              color: serviceSettingsGroup.is_default
                                ? '#81c784'
                                : ''
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          {serviceSettingsGroup.provider_name}
                        </TableCell>
                        <TableCell>
                          {serviceSettingsGroup.service_name}
                        </TableCell>
                        <TableCell>{creationDate}</TableCell>
                        <TableCell>
                          {serviceSettingsGroup.period_category}
                        </TableCell>
                        <TableCell>
                          {serviceSettingsGroup.events_per_high_urgency}
                        </TableCell>
                        <TableCell>
                          {serviceSettingsGroup.events_per_high_impact}
                        </TableCell>
                        <TableCell align="center">
                          {
                            serviceSettingsGroup.concurrent_alarms_alarming_to_start_incident
                          }
                        </TableCell>
                        <TableCell align="center">
                          {
                            serviceSettingsGroup.concurrent_alarms_healthy_to_stop_incident
                          }
                        </TableCell>
                        <TableCell className={classes.firstCell}>
                          <DeleteModal settingsGroup={serviceSettingsGroup} />
                        </TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <NoDataFound
                    pageName="Settings Groups"
                    colSpan={organizationId ? 12 : 11}
                  />
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={rowCount}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </>
      )}
    </Container>
  )
}
