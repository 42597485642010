import { selectAppVersion, setAppVersion } from 'client_side_state/slices/app'
import { userLogout } from 'client_side_state/slices/auth'
import { useAppDispatch, useAppSelector } from 'client_side_state/store'
import { useContext, useEffect, useState } from 'react'
import { IIdleTimer, useIdleTimer } from 'react-idle-timer'
import { Redirect } from 'react-router'
import { Route, Switch, useHistory } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import CanaryForecastManage from 'components/CanaryForecastManage'
import NavBar from 'components/NavBar'
import Toast from 'components/Toast'
import InActiveModal from 'components/common/InActiveModal'
import { promptTimout, userInactivityAllowedMinutes } from 'constants/AppConfig'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { AuthContext } from 'contexts/AuthContext'
import ComplianceHistoryContextProvider from 'contexts/ComplianceHistoryContext'
import IncidentsContextProvider from 'contexts/IncidentsContext'
import OrganizationsContextProvider from 'contexts/OrganizationsContext'
import CustomRestfulSDKSchemaEditor from 'pages/AdminPages/CustomRestfulSDK/CustomRestfulSDKSchemaEditor'
import CustomRestfulSDKSchemaViewer from 'pages/AdminPages/CustomRestfulSDK/CustomRestfulSDKSchemaViewer'
import UserOrganizationManage from 'pages/AdminPages/UserOrganization/UserOrganizationManage'
import UserOrganizationsList from 'pages/AdminPages/UserOrganization/UserOrganizationsList'
import CustomCanaryPrototypeEditor from 'pages/CustomCanary/CustomCanaryPrototypeEditor'
import RestfulLibEdit from 'pages/CustomCanary/RestfulLibEdit'
import CanaryManage from 'pages/CustomPages/CanaryManage'
import CanaryMetricsPage from 'pages/CustomPages/CanaryMetricsPage'
import ComplianceHistory from 'pages/CustomPages/Compliance/ComplianceHistory'
import CookiePolicy from 'pages/CustomPages/CookiePolicy'
import Error404Page from 'pages/CustomPages/Error404'
import PrivacyPolicy from 'pages/CustomPages/PrivacyPolicy'
import TermsAndConditions from 'pages/CustomPages/TermsAndConditions'
import VersionsPage from 'pages/CustomPages/VersionsPage'
import DashboardIncidents from 'pages/Dashboards/Incidents'
import DashboardServicesForecast from 'pages/Dashboards/ServicesForecast'
import ChangeTempPassword from 'pages/User/ChangeTempPassword'
import ImpersonateUser from 'pages/User/Impersonate'
import UserLoggedInInsterstial from 'pages/User/Interstitial'
import LoginPage from 'pages/User/Login'
import UserLogout from 'pages/User/Logout'
import UserProfile from 'pages/User/Profile'
// import RegisterPage from 'pages/User/Register'
import RequestResetPasswordLink from 'pages/User/RequestResetPasswordLink'
import ResetPassword from 'pages/User/ResetPassword'
import APIKeysAdminPage from 'routes/APIKeysAdminPage'
import CustomRestfulSDKPage from 'routes/CustomRestfulSDKPage'
import DashboardCanaries from 'routes/DashboardCanaries'
import DashboardCompliance from 'routes/DashboardCompliance'
import DashboardServiceHealth from 'routes/DashboardServiceHealth'
import ForecastModelsPage from 'routes/ForecastModelsPage'
import SettingsGroupAdminPage from 'routes/SettingsGroupAdminPage'
import SharedTemplateLibrariesAdminPage from 'routes/SharedTemplateLibrariesAdminPage'
import TemplateLibrariesAdminPage from 'routes/TemplateLibrariesAdminPage'
import UserBillingDetailsAdminPage from 'routes/USerBillingDetailsAdminPage'
import UserClustersPage from 'routes/UserClustersPage'
import UserPlansDetailsAdminPage from 'routes/UserPlansDetailsAdminPage'
import WalletPage from 'routes/WalletPage'
import RoutesService from 'services/routes'

const pkg = require('../package.json')

export default function App() {
  const version = useAppSelector(selectAppVersion)
  const dispatch = useAppDispatch()
  const history = useHistory()

  const { isAuthenticated, getIsTempPassword } = useContext(AuthContext)
  const { refreshToken } = useContext(APIServiceContext)

  const [showInActiveWarning, setShowInActiveWarning] = useState<boolean>(false)

  useEffect(() => {
    if (pkg.version !== version) {
      if (isAuthenticated()) {
        dispatch(userLogout())
      }
      toast.success(
        <Toast
          title="Notification"
          text="We've released new features. Login for the free upgrade!"
        />,
        {
          position: 'top-right'
        }
      )
      dispatch(setAppVersion(pkg.version))
    }
  }, [version, dispatch, isAuthenticated])

  const onPrompt = () => {
    setShowInActiveWarning(true)
  }

  const logoutUser = () => {
    setShowInActiveWarning(false)
    idleTimer.pause()
    history.push(RoutesService.userLogout())
  }

  const onActive = async (event?: Event, idleTimer?: IIdleTimer) => {
    await refreshToken()
    setShowInActiveWarning(false)
  }

  const idleTimer = useIdleTimer({
    onPrompt,
    onIdle: logoutUser,
    timeout: (userInactivityAllowedMinutes - promptTimout) * 60000,
    promptBeforeIdle: promptTimout * 60000,
    startOnMount: true,
    stopOnIdle: false,
    crossTab: true,
    onActive,
    disabled: !isAuthenticated()
  })

  const onAction = async () => {
    setShowInActiveWarning(false)
    idleTimer.reset()
    if (isAuthenticated()) {
      await refreshToken()
    }
  }

  useEffect(() => {
    if (isAuthenticated() && getIsTempPassword()) {
      history.push(RoutesService.authenticatedChangeTempPasswordRoute())
    }
  }, [isAuthenticated, getIsTempPassword, history])

  return (
    <OrganizationsContextProvider>
      <div className="application">
        <IncidentsContextProvider>
          <ComplianceHistoryContextProvider>
            <NavBar />
            <Switch>
              <Route
                exact
                path={RoutesService.versions()}
                component={VersionsPage}
              />
              <Route
                exact
                path={RoutesService.termsAndConditions()}
                component={TermsAndConditions}
              />
              <Route
                exact
                path={RoutesService.privacyPolicy()}
                component={PrivacyPolicy}
              />
              <Route
                exact
                path={RoutesService.cookiePolicy()}
                component={CookiePolicy}
              />
              <Route
                exact
                path={RoutesService.userProfile()}
                component={UserProfile}
              />
              <Route
                exact
                path={RoutesService.userWallet()}
                component={WalletPage}
              />
              <Route
                exact
                path={RoutesService.userForecastModels()}
                component={ForecastModelsPage}
              />
              {/* <Route
                exact
                path={RoutesService.userRegister()}
                component={RegisterPage}
              /> */}
              <Route
                exact
                path={RoutesService.userLogin()}
                component={LoginPage}
              />
              <Route
                exact
                path={RoutesService.userForgotPassword()}
                component={RequestResetPasswordLink}
              />
              <Route
                exact
                path={RoutesService.userResetPassword()}
                component={ResetPassword}
              />
              <Route
                exact
                path={RoutesService.userChangeTempPassword()}
                component={ChangeTempPassword}
              />
              <Route
                exact
                path={RoutesService.userLogout()}
                component={UserLogout}
              />
              <Route
                exact
                path={RoutesService.userOrganizationsList()}
                component={UserOrganizationsList}
              />
              <Route
                exact
                path={RoutesService.userSettingGroupsList()}
                component={SettingsGroupAdminPage}
              />
              <Route
                exact
                path={RoutesService.userClusters()}
                component={UserClustersPage}
              />
              <Route
                path={RoutesService.userOrganizationManage()}
                component={UserOrganizationManage}
              />
              <Route
                path={RoutesService.userBillingDetails()}
                component={UserBillingDetailsAdminPage}
              />
              <Route
                path={RoutesService.userPlansDetails()}
                component={UserPlansDetailsAdminPage}
              />
              <Route
                exact
                path={RoutesService.dashboardCanaries()}
                component={DashboardCanaries}
              />
              <Route
                path={RoutesService.dashboardServiceHealth()}
                component={DashboardServiceHealth}
              />
              <Route
                path={RoutesService.dashboardCompliance()}
                component={DashboardCompliance}
              />
              <Route
                exact
                path={RoutesService.dashboardServicesForecast()}
                component={DashboardServicesForecast}
              />
              <Route
                path={RoutesService.canaryForecastManage()}
                component={CanaryForecastManage}
              />
              <Route
                path={RoutesService.dashboardIncidents()}
                exact
                component={DashboardIncidents}
              />
              <Route
                path={RoutesService.canaryManage()}
                component={CanaryManage}
              />
              <Route
                path={RoutesService.canaryMetrics()}
                component={CanaryMetricsPage}
              />
              <Route
                path={RoutesService.APIKeysList()}
                component={APIKeysAdminPage}
              />
              <Route
                exact
                path={RoutesService.templateLibraryList()}
                component={TemplateLibrariesAdminPage}
              />
              <Route
                exact
                path={RoutesService.sharedTemplateLibraryList()}
                component={SharedTemplateLibrariesAdminPage}
              />
              <Route
                path={RoutesService.customSchemaView()}
                component={CustomRestfulSDKSchemaViewer}
              />
              <Route
                path={RoutesService.customSchemaEdit()}
                component={CustomRestfulSDKSchemaEditor}
              />
              <Route
                path={RoutesService.restfulLibList()}
                component={CustomRestfulSDKPage}
              />
              <Route
                path={RoutesService.customRestfulLibEdit()}
                component={RestfulLibEdit}
              />
              <Route
                path={RoutesService.customCanaryPrototypeEdit()}
                component={CustomCanaryPrototypeEditor}
              />
              <Route
                path={RoutesService.complianceHistory()}
                component={ComplianceHistory}
              />
              <Route
                path={RoutesService.impersonateUser()}
                component={ImpersonateUser}
              />
              <Route
                path={RoutesService.userLoggedInInterstitial()}
                exact
                component={UserLoggedInInsterstial}
              />
              <Route exact path="/">
                {isAuthenticated() ? (
                  getIsTempPassword() ? (
                    <Redirect
                      to={RoutesService.authenticatedChangeTempPasswordRoute()}
                    />
                  ) : (
                    <Redirect to={RoutesService.authenticatedDefaultRoute()} />
                  )
                ) : (
                  <Redirect to={RoutesService.userLogin()} />
                )}
              </Route>

              <Route component={Error404Page} />
            </Switch>
            <InActiveModal
              open={showInActiveWarning}
              activeAction={onAction}
              cancelAction={logoutUser}
            />
            <ToastContainer autoClose={3000} hideProgressBar closeOnClick />
          </ComplianceHistoryContextProvider>
        </IncidentsContextProvider>
      </div>
    </OrganizationsContextProvider>
  )
}
