import useDocumentTitle from 'hooks/useDocumentTitle'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import { makeStyles } from '@mui/styles'
import CanaryDataTable from 'components/CanaryManage/CanaryDataTable'
import CanaryStatusButton from 'components/CanaryManage/CanaryStatusButton'
import CanaryTaskGraphSelector from 'components/CanaryManage/CanaryTaskGraphSelector'
import DateTimeFilter from 'components/CanaryManage/DateTimeFilter'
import ExportDataButton from 'components/CanaryManage/ExportDataButton'
import CanaryGraphs from 'components/CanaryManage/GraphiteGraph'
import RefreshButton from 'components/CanaryManage/RefreshButton'
import Icon, { IconType } from 'components/Icons'
import CustomButton from 'components/common/Button'
import CanaryManageHeaderLoader from 'components/common/Loaders/CanaryManageHeaderLoader'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { GenericIDMatchProps } from 'interfaces/GenericIDMatchProps'
import {
  Canary,
  CanaryDetailTaskReadOnlyMinimal,
  CanaryTask
} from 'lib/CloudCanariesRestfulAPI'
import {
  ButtonContainer,
  MiddleContainer,
  PageContainer
} from 'styles/pages/CanaryManage.styled'

const useStyles = makeStyles({
  root: {
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'space-between',
    paddingTop: '8px',
    paddingBottom: '8px'
  },
  provider: {
    fontSize: '20px',
    fontWeight: 700
  },
  center: {
    display: 'flex',
    justifyContent: 'center'
  },
  closeIcon: {
    textAlign: 'right',
    marginTop: '8px'
  },
  muiForm: {
    marginBottom: '0px !important',
    '& .MuiGrid-grid-xs-12"': {
      marginBottom: '0px !important'
    }
  }
})

export default function CanaryManage(props: GenericIDMatchProps) {
  const {
    match: {
      params: { id: canaryId }
    }
  } = props
  const history = useHistory()

  const { apiService } = useContext(APIServiceContext)
  const [isCanaryDataLoading, setIsCanaryDataLoading] = useState<boolean>(true)
  const [canary, setCanary] = useState<Canary>({} as Canary)

  const [selectedTask, setSelectedTask] = useState<CanaryTask>({} as CanaryTask)

  useDocumentTitle(`Canary "${canary.name!}" Management`)

  const getCanaryMeta = useCallback(async () => {
    setIsCanaryDataLoading(true)
    if (canaryId) {
      await apiService.retrieveCanary(canaryId).then((json) => {
        setCanary(json.data)
        setIsCanaryDataLoading(false)
      })
    }
  }, [apiService, canaryId])

  const getCanaryTasks = useCallback(async () => {
    if (canary.tasks?.length! > 0) {
      await apiService.retrieveCanaryTask(canary.tasks![0].id!).then((json) => {
        if (json.data && json.data) {
          const selectedTask = json.data
          setSelectedTask(selectedTask)
        }
      })
    }
  }, [apiService, canary.tasks])

  const handleRefresh = () => {
    getCanaryMeta()
  }

  useEffect(() => {
    getCanaryMeta()
  }, [canaryId, getCanaryMeta])
  useEffect(() => {
    getCanaryTasks()
  }, [getCanaryTasks])

  const classes = useStyles()

  const { center, root, provider, closeIcon } = classes

  return isCanaryDataLoading ? (
    <CanaryManageHeaderLoader />
  ) : (
    canary && (
      <PageContainer>
        <Grid container spacing={2} style={{ marginBottom: 8 }}>
          <Grid item xs={11} md={11}>
            <Grid container alignItems="flex-start">
              <Grid item xs={12} md={4} style={{ marginTop: '8px' }}>
                <h2 className={'canaryName'}>
                  {canary.name}
                  <CanaryStatusButton status={canary.status!} />
                </h2>
              </Grid>
              <Grid item xs={12} md={8} style={{ marginTop: '8px' }}>
                <span className={provider}>
                  {canary.provider_name || ''} {'>'} {canary.service_name || ''}{' '}
                  {'>'} {canary.template_canary_name || ''}
                </span>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} md={1} className={closeIcon}>
            <CustomButton
              className="CustomButton"
              leftIcon={<Icon name={IconType.X} />}
              backgroundColor="#48A2F4"
              action={() => history.goBack()}
            />
          </Grid>
        </Grid>
        <Grid
          direction="row"
          className={root}
          container
          id={'manageCanarySelectTaskContainer'}
        >
          <Grid item xs={3} className={center}>
            <ButtonContainer>
              <CanaryTaskGraphSelector />
            </ButtonContainer>
          </Grid>
          <Grid item xs={6} className={center}>
            <ButtonContainer>
              <DateTimeFilter />
            </ButtonContainer>
          </Grid>
          <Grid
            container
            item
            xs={1}
            className={center}
            style={{
              justifyContent: 'center'
            }}
          >
            <ButtonContainer>
              <ExportDataButton canaryId={canaryId} />
            </ButtonContainer>
          </Grid>
          <Grid
            container
            item
            xs={2}
            className={center}
            style={{
              justifyContent: 'flex-start'
            }}
          >
            <ButtonContainer>
              <RefreshButton onClick={handleRefresh} />
            </ButtonContainer>
          </Grid>
        </Grid>
        <MiddleContainer>
          <CanaryGraphs
            canaryTask={selectedTask as CanaryDetailTaskReadOnlyMinimal}
          />
        </MiddleContainer>
        <CanaryDataTable canaryId={canaryId} />
      </PageContainer>
    )
  )
}
