import { useCallback, useContext, useEffect, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Icon, { IconType } from 'components/Icons'
import DialogModal from 'components/Modals/DialogModal'
import SnowFlakeSettingLoader from 'components/common/Loaders/SnowFlakeSettingLoader'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { Snowflake } from 'lib/CloudCanariesRestfulAPI'

type WalletViewModalProps = {
  wallet: Snowflake
}

type SnowflakeView = Snowflake & {
  username: string
  organization_id: string
  account_id: string
  warehouse: string
  database: string
  schema: string
}

export default function WalletViewModal(props: WalletViewModalProps) {
  const {
    wallet: { id }
  } = props

  const { apiService } = useContext(APIServiceContext)

  const [snowFlakeSetting, setSnowFlakeSetting] = useState<
    SnowflakeView | undefined
  >(undefined)
  const [isSnowflakeSettingLoading, setIsSnowflakeSettingLoading] =
    useState<boolean>(true)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const fetchSnowflakeSetting = useCallback(async () => {
    if (isModalOpen) {
      await apiService.retrieveSnowflake(id!).then((json) => {
        setSnowFlakeSetting((json.data as SnowflakeView) ?? undefined)
        setIsSnowflakeSettingLoading(false)
      })
    }
  }, [apiService, id, isModalOpen])
  useEffect(() => {
    fetchSnowflakeSetting()
  }, [fetchSnowflakeSetting])

  const handleClickOpen = () => {
    setIsModalOpen(true)
  }

  const handleClose = () => {
    setIsModalOpen(false)
  }
  return (
    <>
      <IconButton
        onClick={handleClickOpen}
        aria-label="View Wallet Setting"
        color="primary"
      >
        <Icon name={IconType.View} style={{ marginRight: '0.5rem' }} />
      </IconButton>
      <DialogModal
        open={isModalOpen}
        onClose={handleClose}
        dialogTitle="Wallet Setting"
        dialogContent={
          isSnowflakeSettingLoading ? (
            <SnowFlakeSettingLoader />
          ) : (
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Snowflake Username</TableCell>
                  <TableCell>{snowFlakeSetting?.username}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Snowflake Organization ID</TableCell>
                  <TableCell>{snowFlakeSetting?.organization_id}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Account ID</TableCell>
                  <TableCell>{snowFlakeSetting?.account_id}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Warehouse</TableCell>
                  <TableCell>{snowFlakeSetting?.warehouse}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Database</TableCell>
                  <TableCell>{snowFlakeSetting?.database}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Schema</TableCell>
                  <TableCell>{snowFlakeSetting?.schema}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )
        }
      />
    </>
  )
}
