import { Theme } from '@mui/material/styles'
import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles(({ spacing }: Theme) =>
  createStyles({
    mainButton: {
      textTransform: 'capitalize',
      letterSpacing: '0.1em'
    },
    dialog: {
      '& .MuiDialog-paperWidthSm': {
        minWidth: 500,
        minHeight: 600
      }
    },
    dialogTitleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: '#dff2ff',
      padding: spacing(1.25)
    },
    dialogTitle: {
      margin: '0 auto'
    },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    },
    sectionTitle: {
      fontWeight: 400,
      fontSize: spacing(3),
      textAlign: 'center',
      marginBottom: '0.5rem'
    },
    dialogActions: {
      padding: spacing(2)
    },
    settingsForm: {
      textAlign: 'right'
    },
    artifactsForm: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      flexGrow: 1
    },
    saveBtn: {
      fontWeight: 'bold',
      fontSize: spacing(2.25),
      lineHeight: spacing(2.75),
      padding: '4px 10px',
      height: spacing(4.5),
      textTransform: 'capitalize'
    },
    formActionsButtons: {
      marginTop: '1.25rem',
      '& > :nth-child(2)': {
        marginLeft: '0.5rem'
      }
    },
    artifactsDialog: {
      '& .MuiDialog-paperWidthSm': {
        minWidth: 600,
        minHeight: 600
      }
    },
    whatIfDialog: {
      '& .MuiDialog-paperWidthSm': {
        minWidth: 1000
      }
    },
    tabs: {
      '& .MuiTab-textColorInherit': {
        color: '#000000',
        fontSize: spacing(3),
        fontWeight: 700,
        textTransform: 'capitalize'
      }
    },
    tabIndicator: {
      backgroundColor: '#000000'
    },
    publishButton: {
      color: '#ffffff',
      backgroundColor: '#3d89ff',
      '&:hover': {
        backgroundColor: '#3d89ff'
      }
    }
  })
)
