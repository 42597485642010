import { Document, Page, Text, View } from '@react-pdf/renderer'
// import { uniqueId } from 'lodash'
import { ReportLevel } from 'constants/formconfig/ArtifactConfigs'
import { PDFFileData } from 'interfaces/PDFFileData'
import ReportTable from './ReportTable/ReportTable'
import styles from './styles'

interface PDFCreatorProps {
  pdfFileData: PDFFileData
}

export default function PDFCreator(props: PDFCreatorProps) {
  const { pdfFileData } = props
  const todayDate = new Date()
  const artifactDate = todayDate.toUTCString()
  const currentYear = todayDate.getFullYear()

  return (
    <Document>
      <Page size="A3" orientation="landscape" style={styles.page}>
        <View style={styles.container}>
          <View style={{ textAlign: 'right', width: '100%' }}>
            <Text style={{ fontSize: 16, fontWeight: 600, margin: '4px 0' }}>
              {artifactDate}
            </Text>
          </View>
          <View
            style={{
              textAlign: 'center',
              marginTop: 6
            }}
          >
            <Text
              style={{
                fontSize: 16,
                fontWeight: 700,
                fontFamily: 'Helvetica-Bold'
              }}
            >
              {pdfFileData.companyName}
            </Text>
          </View>
          <View
            style={{
              textAlign: 'center',
              marginTop: 8
            }}
          >
            <Text
              style={{
                fontSize: 16,
                fontWeight: 700,
                fontFamily: 'Helvetica-Bold'
              }}
            >
              {ReportLevel[pdfFileData.formData.report_level]}
            </Text>
          </View>
          <View
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              flexDirection: 'row',
              marginTop: 6
            }}
          >
            <Text
              style={{
                fontSize: 16,
                fontWeight: 700,
                marginRight: 170
              }}
            >
              {pdfFileData.reporterName}
            </Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              marginTop: 6
            }}
          >
            <Text
              style={{
                fontSize: 16,
                fontWeight: 700,
                fontFamily: 'Helvetica-Bold'
              }}
            >
              {pdfFileData.formData.period}
            </Text>
            <Text
              style={{
                fontSize: 16,
                fontWeight: 600,
                fontFamily: 'Helvetica-Bold',
                marginLeft: 70
              }}
            >
              {pdfFileData.reportStartDate}
            </Text>
            <Text
              style={{
                fontSize: 16,
                fontWeight: 600,
                fontFamily: 'Helvetica-Bold',
                marginLeft: 70
              }}
            >
              {pdfFileData.reportEndDate}
            </Text>
            {!pdfFileData.periodEnded && (
              <Text
                style={{
                  fontSize: 16,
                  fontWeight: 600,
                  fontFamily: 'Helvetica-Bold',
                  marginLeft: 70
                }}
              >
                Remaining Days {pdfFileData.remainingDays}
              </Text>
            )}
          </View>
          <View
            style={{
              textAlign: 'left',
              marginTop: 16
            }}
          >
            <Text
              style={{
                fontSize: 16,
                fontWeight: 700,
                marginTop: 8,
                fontFamily: 'Helvetica-Bold'
              }}
            >
              {pdfFileData.organizationName}
            </Text>
          </View>
          <ReportTable pdfFileData={pdfFileData} />
          {/* {pdfFileData.services.map((service, serviceIndex) => (
            <Fragment key={uniqueId(service.serviceName)}>
              <View
                style={{
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  flexDirection: 'row',
                  marginTop: 20
                }}
              >
                <Text style={{ fontSize: 16, fontWeight: 500 }}>
                  {`${serviceIndex + 1}. Serive Name: `}
                </Text>
                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: 700,
                    margin: '0 8px 0',
                    fontFamily: 'Helvetica-Bold'
                  }}
                >
                  {service.serviceName}
                </Text>
              </View>
              <View
                style={{
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  flexDirection: 'row',
                  marginTop: 8
                }}
              >
                <Text style={{ fontSize: 16, fontWeight: 500 }}>Period</Text>
                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: 700,
                    margin: '0 20px 0',
                    fontFamily: 'Helvetica-Bold'
                  }}
                >
                  {service.period}
                </Text>
              </View>
              <View
                style={{
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  flexDirection: 'row',
                  marginTop: 8
                }}
              >
                <Text style={{ fontSize: 16, fontWeight: 500 }}>
                  {service.errors.length} Errors during {service.period} and{' '}
                  {service.incidents.length} incidents
                </Text>
              </View>
              <View
                style={{
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  flexDirection: 'column',
                  marginTop: 12
                }}
              >
                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: 700,
                    fontFamily: 'Helvetica-Bold'
                  }}
                >
                  Errors
                </Text>
                <View>
                  {service.errors.length > 0 ? (
                    service.errors.map((error, errorIndex) => (
                      <Text
                        key={uniqueId(error)}
                        style={{
                          fontSize: 14,
                          marginLeft: 30,
                          fontWeight: 500
                        }}
                      >{`${errorIndex + 1}. ${error}`}</Text>
                    ))
                  ) : (
                    <Text
                      style={{ fontSize: 14, marginLeft: 30, fontWeight: 500 }}
                    >
                      No Errors
                    </Text>
                  )}
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  flexDirection: 'row',
                  marginTop: 8
                }}
              >
                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: 700,
                    fontFamily: 'Helvetica-Bold'
                  }}
                >
                  Meantime between Errors{' '}
                </Text>
                <Text style={{ fontSize: 16, fontWeight: 500 }}>
                  {` is ${service.meantimeBetweenErrors || 0}`}
                </Text>
              </View>
              <View
                style={{
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  flexDirection: 'column',
                  marginTop: 12
                }}
              >
                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: 700,
                    fontFamily: 'Helvetica-Bold'
                  }}
                >
                  Incidents
                </Text>
                <View>
                  {service.incidents.length > 0 ? (
                    service.incidents.map((incident, incidentIndex) => (
                      <Text
                        key={uniqueId(incident)}
                        style={{
                          fontSize: 14,
                          marginLeft: 30,
                          fontWeight: 500
                        }}
                      >{`${incidentIndex + 1}. ${incident}`}</Text>
                    ))
                  ) : (
                    <Text
                      style={{ fontSize: 14, marginLeft: 30, fontWeight: 500 }}
                    >
                      No Incidents
                    </Text>
                  )}
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  flexDirection: 'row',
                  marginTop: 8
                }}
              >
                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: 700,
                    fontFamily: 'Helvetica-Bold'
                  }}
                >
                  Meantime between Incidents{' '}
                </Text>
                <Text style={{ fontSize: 16, fontWeight: 500 }}>
                  {` is ${service.meantimeBetweenIncidents || 0}`}
                </Text>
              </View>
            </Fragment>
          ))} */}
          <Text
            style={styles.pageNumber}
            render={({ pageNumber }) => `pg. ${pageNumber}`}
            fixed
          />
          <Text
            style={styles.copyRight}
            render={() => `Copyright Cloud Canaries Inc. ${currentYear}`}
            fixed
          />
        </View>
      </Page>
    </Document>
  )
}
