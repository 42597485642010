import { ChangeEvent, useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import { isAxiosError } from 'axios'
import Icon, { IconType } from 'components/Icons'
import DialogModal from 'components/Modals/DialogModal'
import { ToastErrors } from 'constants/toast_errors'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { CanariesContext } from 'contexts/CanariesContext'
import { CanaryErrors } from 'interfaces/Canary'

export default function ModalEditCanaryImage() {
  const { apiService } = useContext(APIServiceContext)
  const { canaries, getCanaries, selectedCanaryIds } =
    useContext(CanariesContext)

  const [open, setOpen] = useState(false)
  const [selectedImage, setSelectedImage] = useState('')
  const [isDisabledForCanaryAgent, setIsDisabledForCanaryAgent] =
    useState<boolean>(false)

  useEffect(() => {
    if (open) {
      const selectedImage = canaries.filter((canary) =>
        selectedCanaryIds.includes(canary.id!)
      )[0].image!
      setSelectedImage(selectedImage)
    }
    return () => {
      setSelectedImage('')
    }
  }, [canaries, open, selectedCanaryIds])

  useEffect(() => {
    const selectedCanaries = canaries.filter((canary) =>
      selectedCanaryIds.includes(canary.id!)
    )
    if (selectedCanaries.find((canary) => canary.is_agent_canary)) {
      setIsDisabledForCanaryAgent(true)
    } else {
      setIsDisabledForCanaryAgent(false)
    }
  }, [canaries, selectedCanaryIds])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleAction = async () => {
    let canaryErrors: CanaryErrors[] = []
    const canaryClusterDeployment = {
      image: selectedImage
    }
    await Promise.all(
      selectedCanaryIds.map(async (canaryId) => {
        try {
          await apiService.partialUpdateCanaryClusterDeployment(
            canaryId,
            canaryClusterDeployment
          )
        } catch (err: any) {
          if (isAxiosError(err)) {
            if (err && err.response && err.response.data) {
              const erroringCanary: CanaryErrors = {
                canaryName: canaries.find((canary) => canary.id === canaryId)
                  ?.name!,
                error: true
              }
              canaryErrors.push(erroringCanary)
            }
          }
        }
      })
    )
    if (canaryErrors.some((canary) => canary.error)) {
      canaryErrors.forEach((error) => {
        toast.error(
          `${ToastErrors.ERROR_UPDATING_CANARY_IMAGE} "${error.canaryName}"`,
          {
            position: 'top-right'
          }
        )
      })
    }
    setOpen(false)
    await getCanaries()
  }

  const onChangeImage = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedImage(event.target.value)
  }

  const isDisabled =
    !selectedCanaryIds ||
    selectedCanaryIds.length === 0 ||
    isDisabledForCanaryAgent

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        color={'primary'}
        disabled={isDisabled}
      >
        <Icon
          name={IconType.DockerIcon}
          style={{ marginRight: '0.25rem' }}
          color="action"
        />{' '}
        Image
      </Button>
      <DialogModal
        open={open}
        onClose={handleClose}
        dialogTitle="Edit Selected Canaries Image"
        dialogContent={
          <FormControl size="small">
            <InputLabel id="canaries-image-input-helper-label">
              Image
            </InputLabel>
            <Input
              value={selectedImage}
              onChange={onChangeImage}
              style={{ minWidth: '400px' }}
            />
          </FormControl>
        }
        dialogActions={
          <>
            <Button variant="outlined" onClick={handleClose} color="primary">
              <Icon name={IconType.Stop} /> Cancel
            </Button>
            <Button variant="outlined" onClick={handleAction} color="primary">
              <Icon name={IconType.Check} /> Apply
            </Button>
          </>
        }
      />
    </>
  )
}
