import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function OpenLockIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="24px"
        height="24px"
        className="MuiSvgIcon-root"
        viewBox="0 0 24 24"
        {...props}
      >
        <g id="surface1">
          <path
            style={{
              stroke: 'none',
              fillRule: 'nonzero',
              fill: 'rgb(0%,0%,0%)',
              fillOpacity: 1
            }}
            d="M 16.941406 0 C 13.441406 0 10.589844 2.847656 10.589844 6.351562 L 10.589844 9.882812 L 2.820312 9.882812 C 1.65625 9.882812 0.707031 10.832031 0.707031 12 L 0.707031 21.882812 C 0.707031 23.050781 1.65625 24 2.820312 24 L 14.117188 24 C 15.285156 24 16.234375 23.050781 16.234375 21.882812 L 16.234375 12 C 16.234375 11.082031 15.644531 10.296875 14.820312 10.003906 L 14.820312 6.351562 C 14.820312 5.183594 15.773438 4.230469 16.941406 4.230469 C 18.113281 4.230469 19.0625 5.183594 19.0625 6.351562 L 19.0625 7.765625 C 19.0625 8.152344 19.378906 8.46875 19.765625 8.46875 L 22.589844 8.46875 C 22.980469 8.46875 23.292969 8.152344 23.292969 7.765625 L 23.292969 6.351562 C 23.292969 2.847656 20.445312 0 16.941406 0 Z M 14.828125 21.882812 C 14.828125 22.277344 14.507812 22.59375 14.117188 22.59375 L 2.820312 22.59375 C 2.429688 22.59375 2.113281 22.277344 2.113281 21.882812 L 2.113281 12 C 2.113281 11.609375 2.429688 11.289062 2.820312 11.289062 L 14.117188 11.289062 C 14.507812 11.289062 14.828125 11.609375 14.828125 12 Z M 21.886719 7.0625 L 20.46875 7.0625 L 20.46875 6.351562 C 20.46875 4.40625 18.886719 2.824219 16.941406 2.824219 C 14.996094 2.824219 13.414062 4.40625 13.414062 6.351562 L 13.414062 9.882812 L 11.996094 9.882812 L 11.996094 6.351562 C 11.996094 3.625 14.214844 1.40625 16.941406 1.40625 C 19.667969 1.40625 21.886719 3.625 21.886719 6.351562 Z M 21.886719 7.0625 "
          />
          <path
            style={{
              stroke: 'none',
              fillRule: 'nonzero',
              fill: 'rgb(0%,0%,0%)',
              fillOpacity: 1
            }}
            d="M 8.46875 14.121094 C 7.304688 14.121094 6.355469 15.070312 6.355469 16.234375 C 6.355469 17.15625 6.945312 17.941406 7.765625 18.230469 L 7.765625 19.0625 C 7.765625 19.449219 8.082031 19.765625 8.46875 19.765625 C 8.859375 19.765625 9.171875 19.449219 9.171875 19.0625 L 9.171875 18.230469 C 9.996094 17.941406 10.585938 17.15625 10.585938 16.234375 C 10.585938 15.070312 9.636719 14.121094 8.46875 14.121094 Z M 8.46875 16.945312 C 8.078125 16.945312 7.761719 16.628906 7.761719 16.234375 C 7.761719 15.84375 8.078125 15.527344 8.46875 15.527344 C 8.859375 15.527344 9.179688 15.84375 9.179688 16.234375 C 9.179688 16.628906 8.859375 16.945312 8.46875 16.945312 Z M 8.46875 16.945312 "
          />
        </g>
      </svg>
    </SvgIcon>
  )
}
