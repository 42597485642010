import { StyleSheet } from '@react-pdf/renderer'

export default StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'row'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    margin: 8,
    padding: 16,
    border: '2px solid #000',
    borderRadius: 4
  },
  flexContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'space-between'
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: '#48A2F4'
  },
  copyRight: {
    position: 'absolute',
    fontFamily: 'Helvetica-Bold',
    fontSize: 14,
    bottom: 18,
    left: 20,
    right: 0,
    textAlign: 'left',
    color: '#48A2F4'
  }
})
