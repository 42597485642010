import { useMemo } from 'react'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import CloudBox from 'components/Forecast/CloudBox'
import { ServiceDailyForecast } from 'lib/CloudCanariesRestfulAPI'

const useStyles = makeStyles({
  tooltip: {
    fontSize: 20
  }
})

interface CardDailyServiceForecastProps {
  selected: boolean
  handleCardClick: () => void
  dailyForecast: ServiceDailyForecast
}

export default function CardDailyServiceForecast(
  props: CardDailyServiceForecastProps
) {
  const { selected, handleCardClick, dailyForecast } = props
  const classes = useStyles()

  const forecastText = useMemo(() => {
    if (dailyForecast.has_prediction) {
      if (dailyForecast.percentage_chance_of_incident! <= 50) {
        return `Chance of Incident ${dailyForecast.percentage_chance_of_incident}%`
      } else if (dailyForecast.percentage_chance_of_incident! > 50) {
        return `Incident likely ${dailyForecast.percentage_chance_of_incident}%`
      }
    }
    return 'No Forecast'
  }, [dailyForecast])

  return (
    <Card style={{ maxWidth: 300, margin: '0 0.5rem' }}>
      <CardActionArea onClick={handleCardClick}>
        <CardHeader
          avatar={
            <input
              type="checkbox"
              checked={selected}
              onChange={handleCardClick}
            />
          }
        />
        <CardContent>
          <h2 style={{ textAlign: 'center' }}>{forecastText}</h2>
          <CloudBox
            name=""
            percent={dailyForecast.percentage_chance_of_incident!}
            hasPrediction={dailyForecast.has_prediction!}
          />

          <Tooltip
            title={dailyForecast.service_name!}
            placement="top"
            arrow
            classes={{
              tooltip: classes.tooltip
            }}
          >
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              align={'center'}
              style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
              }}
            >
              {dailyForecast.service_name}
            </Typography>
          </Tooltip>
          <hr />
          <TableContainer component={Paper}>
            <Table aria-label="simple table" size={'small'}>
              <TableBody>
                <TableRow>
                  <TableCell align="left">Forecast Downtime</TableCell>
                  <TableCell align="right">0</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Forecasted Outages</TableCell>
                  <TableCell align="right">0</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
