import { useContext, useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import { makeStyles } from '@mui/styles'
import { IChangeEvent } from '@rjsf/core'
import MuiForm from '@rjsf/mui'
import {
  ErrorSchema,
  ErrorSchemaBuilder,
  RJSFSchema,
  UiSchema
} from '@rjsf/utils'
import validator from '@rjsf/validator-ajv8'
import { AxiosError, isAxiosError } from 'axios'
import DialogModal from 'components/Modals/DialogModal'
import FormsUpdateCancelButtons from 'components/common/FormsUpdateCancelButtons'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { OrganizationsContext } from 'contexts/OrganizationsContext'
import { PlansDetailsContext } from 'contexts/PlansDetailsContext'
import { UserSubscribedPlanOrganizationsInner } from 'lib/CloudCanariesRestfulAPI'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  muiForm: {
    '& label.MuiFormLabel-root': {
      fontSize: '1.2rem'
    },
    '& span.MuiFormControlLabel-label ': {
      fontSize: '1.2rem'
    },
    '& input[type="file" i]': {
      fontSize: '1.2rem'
    }
  }
})

const initialFormData: any = {
  organization: []
}

type AddOrganizationsModalProps = {
  plan_id: string | undefined
  organizations: UserSubscribedPlanOrganizationsInner[] | undefined
}

export default function AddOrganizationsModal(
  props: AddOrganizationsModalProps
) {
  const classes = useStyles()
  const { plan_id, organizations } = props
  const { apiService } = useContext(APIServiceContext)
  const { userOrganizations } = useContext(OrganizationsContext)
  const { fetchUserSubscribedPlans } = useContext(PlansDetailsContext)

  const [open, setOpen] = useState<boolean>(false)
  const [extraErrors, setExtraErrors] = useState<ErrorSchema>({})
  const [formData, setFormData] = useState<any>(initialFormData)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setExtraErrors({})
    setFormData({})
    setOpen(false)
  }

  useEffect(() => {
    if (organizations?.length! > 0) {
      const newFormData: any = {
        organizations: organizations
          ?.flatMap((o) => {
            return o.id
          })
          .flatMap((f) => (f ? [f] : []))
      }
      setFormData(newFormData)
    }
  }, [organizations])

  const handleFormChange = (
    event: IChangeEvent<any, RJSFSchema, any>,
    id?: string | undefined
  ) => {
    let data = JSON.parse(JSON.stringify(event.formData))
    setFormData(data)
  }

  const onSubmit = async (
    data: IChangeEvent<any, RJSFSchema, any>,
    event: React.FormEvent<any>
  ) => {
    let requestBody = JSON.parse(JSON.stringify(data.formData))
    requestBody.plan_id = plan_id

    try {
      await apiService
        .addOrganizationUserSubscribedPlan(plan_id!, requestBody)
        .then(async (json) => {
          if (json.status === 200) {
            handleClose()
            await fetchUserSubscribedPlans()
          }
        })
    } catch (e: any) {
      if (isAxiosError(e)) {
        const err = e as AxiosError
        if (err.response?.status === 400) {
          const errors = err.response?.data as {}
          const builder = new ErrorSchemaBuilder()
          Object.entries(errors).forEach(([key, value]) => {
            builder.addErrors(value as string, key)
          })
          setExtraErrors(builder.ErrorSchema)
        }
      }
    }
  }

  const organizationsEnumNames: string[] | undefined =
    userOrganizations?.flatMap((o) => {
      return o.name ?? ''
    })
  const organizationsEnumValues: string[] | undefined = userOrganizations
    ?.flatMap((o) => {
      return o.id
    })
    .flatMap((f) => (f ? [f] : []))
  const schema = {
    type: 'object',
    properties: {
      organizations: {
        type: 'array',
        title: 'Organizations',
        items: {
          type: 'string',
          enum: organizationsEnumValues,
          enumNames: organizationsEnumNames
        },
        uniqueItems: true
      },
      plan_id: {
        type: 'string'
      }
    }
  } as RJSFSchema

  const uiSchema: UiSchema = {
    plan_id: { 'ui:widget': 'hidden' }
  }

  return (
    <>
      <Button
        variant="outlined"
        sx={{
          mx: 0,
          my: 1,
          mb: 1.5,
          mr: 7.75,
          textTransform: 'capitalize'
        }}
        onClick={handleClickOpen}
      >
        Add Organization
      </Button>
      <DialogModal
        open={open}
        onClose={handleClose}
        dialogTitle="Add Organizations"
        dialogContent={
          <MuiForm
            schema={schema}
            onSubmit={onSubmit}
            formData={formData}
            onChange={handleFormChange}
            uiSchema={uiSchema}
            extraErrors={extraErrors}
            className={classes.muiForm}
            validator={validator}
          >
            <FormsUpdateCancelButtons
              rightButtonText="Add"
              cancelButtonOnClick={handleClose}
            />
          </MuiForm>
        }
      />
    </>
  )
}
