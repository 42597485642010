import { setEditingLabel } from 'client_side_state/slices/pageFilter'
import { useAppDispatch } from 'client_side_state/store'
import useDocumentTitle from 'hooks/useDocumentTitle'
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import CanaryTemplatesAutoLoader from 'components/CanaryTemplateLibrary/CanaryTemplatesAutoLoader'
import CreateModal from 'components/CanaryTemplateLibrary/CreateModal'
import DeleteModal from 'components/CanaryTemplateLibrary/DeleteModal'
import EditDockerfileModal from 'components/CanaryTemplateLibrary/EditDockerfileModal'
import ProvidersFilter from 'components/CanaryTemplateLibrary/ProvidersFilter'
import TemplateProviderNameEditModal from 'components/CanaryTemplateLibrary/TemplateProviderNameEditModal'
import ViewImageURLModal from 'components/CanaryTemplateLibrary/ViewImageURLModal'
import Icon, { IconType } from 'components/Icons'
import { AdminPagesTitles } from 'components/MyAdminPages/Constants'
import MyAdminPagesCommonHeader from 'components/MyAdminPages/MyAdminPagesCommonHeader'
import NoDataFound from 'components/MyAdminPages/NoDataFound'
import Toast from 'components/Toast'
import PagesSkeletonLoader from 'components/common/Loaders/PagesSkeletonLoader'
import { rowsPerPageOptions } from 'constants/AppConfig'
import { APIServiceContext } from 'contexts/APIServiceContext'
import AutoLoadCanaryTemplatesContextProvider from 'contexts/AutoLoadCanaryTemplatesContext'
import { OrganizationsContext } from 'contexts/OrganizationsContext'
import { TemplateLibrariesContext } from 'contexts/TemplateLibrariesContext'
import { CanaryTemplateLibrary } from 'lib/CloudCanariesRestfulAPI'
import adminPagesSharedStyles from 'pages/AdminPages/adminPagesSharedStyles'
import RoutesService from 'services/routes'

interface GenerateReGenerateButtonProps {
  generated: boolean
  buttonAction: (schemaId: string) => void
  schemaId: string
}

function GenerateReGenerateButton({
  generated,
  buttonAction,
  schemaId
}: GenerateReGenerateButtonProps) {
  const generateButton = (
    <Button onClick={() => buttonAction(schemaId)}>Generate</Button>
  )
  const regenerateButton = (
    <Button onClick={() => buttonAction(schemaId)}>ReGenerate</Button>
  )
  return generated ? regenerateButton : generateButton
}

export default function CanaryTemplateLibraryList() {
  const classes = adminPagesSharedStyles()
  useDocumentTitle('Canary Template Libraries')

  const dispatch = useAppDispatch()

  const { apiService } = useContext(APIServiceContext)
  const { organizationId } = useContext(OrganizationsContext)

  const {
    areTemplateLibrariesLoading,
    filteredTemplateLibraries,
    page,
    rowCount,
    rowsPerPage,
    onRowsPerPageChange,
    handleChangePage,
    getTemplateLibraries
  } = useContext(TemplateLibrariesContext)

  const generateCanaries = (schemaId: string) => {
    const canaryTemplateLibrary = {
      is_processed: false,
      canaries_generated: false
    } as CanaryTemplateLibrary
    apiService
      .partialUpdateCanaryTemplateLibrary(
        schemaId,
        organizationId,
        canaryTemplateLibrary
      )
      .then(getTemplateLibraries)
  }

  const populateTemplates = (schemaId: string) => {
    const canaryTemplateLibrary = {
      is_processed: false,
      templates_populated: false
    } as CanaryTemplateLibrary
    apiService
      .partialUpdateCanaryTemplateLibrary(
        schemaId,
        organizationId,
        canaryTemplateLibrary
      )
      .then(getTemplateLibraries)
  }

  const deleteCanaryTemplateLibrary = async (
    schemaId: string,
    currentName: string
  ) => {
    await apiService
      .destroyCanaryTemplateLibrary(schemaId, organizationId)
      .then(async (response) => {
        if (response.status === 204) {
          toast.success(
            <Toast
              title="Success"
              text={`Canary template library "${currentName}" is deleted successfully!`}
            />,
            {
              position: 'top-right'
            }
          )
        } else {
          toast.error(
            <Toast
              title="Error"
              text="Something went wrong, please try again!"
            />,
            {
              position: 'top-right'
            }
          )
        }
        await getTemplateLibraries()
      })
  }

  return (
    <Container maxWidth="xl" className={classes.mainContainer}>
      <MyAdminPagesCommonHeader />
      <h1 className={classes.headerRow}>
        {AdminPagesTitles.CanaryTemplateLibraries}
        <CreateModal />
      </h1>
      {areTemplateLibrariesLoading ? (
        <PagesSkeletonLoader />
      ) : (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <ProvidersFilter />
            <AutoLoadCanaryTemplatesContextProvider>
              <CanaryTemplatesAutoLoader />
            </AutoLoadCanaryTemplatesContextProvider>
          </Box>
          <TableContainer component={Paper} elevation={3}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Provider</TableCell>
                  <TableCell>Service</TableCell>
                  <TableCell>Organization</TableCell>
                  <TableCell>Source Code</TableCell>
                  <TableCell>Templates</TableCell>
                  <TableCell>Canary Template Libraries</TableCell>
                  <TableCell>Dockerfile</TableCell>
                  <TableCell>Image</TableCell>
                  <TableCell className={classes.firstCell}>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredTemplateLibraries.length > 0 ? (
                  filteredTemplateLibraries.map((templateLibrary) => {
                    const templateLibraryId = templateLibrary.id!
                    const currentName = templateLibrary.generated_provider_name!
                    const dockerfile_contents =
                      templateLibrary.dockerfile_contents!

                    let templateCanariesColumnDescription: string | number =
                      'Not Built'
                    if (
                      templateLibrary.templates_populated &&
                      templateLibrary.template_tasks_available_count === 0
                    ) {
                      templateCanariesColumnDescription = 'No Canary Templates'
                    } else if (
                      templateLibrary.templates_populated &&
                      templateLibrary.template_tasks_available_count! > 0
                    ) {
                      templateCanariesColumnDescription =
                        templateLibrary.template_tasks_available_count!
                    } else if (!templateLibrary.is_processed) {
                      templateCanariesColumnDescription = 'Not Built'
                    } else if (
                      !templateLibrary.templates_populated &&
                      templateLibrary.template_tasks_available_count! <= 0
                    ) {
                      templateCanariesColumnDescription = 'Error'
                    }

                    return (
                      <TableRow key={templateLibraryId}>
                        <TableCell>
                          <TemplateProviderNameEditModal
                            templateLibrary={templateLibrary}
                          />
                        </TableCell>
                        <TableCell>
                          {templateLibrary.generated_service_name}
                        </TableCell>
                        <TableCell>
                          {templateLibrary.organization_name}
                        </TableCell>
                        <TableCell>
                          <Link
                            className={classes.viewCreateLinks}
                            to={RoutesService.customCanaryPrototypeEdit(
                              templateLibraryId
                            )}
                            onClick={() => {
                              dispatch(setEditingLabel(currentName))
                            }}
                          >
                            <Icon name={IconType.Edit} color="primary" />
                          </Link>
                          <GenerateReGenerateButton
                            generated={templateLibrary.canaries_generated!}
                            buttonAction={generateCanaries}
                            schemaId={templateLibraryId}
                          />
                        </TableCell>
                        <TableCell>
                          <GenerateReGenerateButton
                            generated={templateLibrary.templates_populated!}
                            buttonAction={populateTemplates}
                            schemaId={templateLibraryId}
                          />
                        </TableCell>
                        <TableCell style={{ paddingLeft: '4rem' }}>
                          {templateCanariesColumnDescription}
                        </TableCell>
                        <TableCell>
                          <EditDockerfileModal
                            templateLibraryId={templateLibraryId}
                            dockerfile_contents={dockerfile_contents}
                          />
                        </TableCell>
                        <TableCell>
                          {templateLibrary.image_built ? (
                            <ViewImageURLModal
                              container_url={templateLibrary.container_url!}
                            />
                          ) : (
                            'Not Yet Built'
                          )}
                        </TableCell>
                        <TableCell className={classes.firstCell}>
                          <DeleteModal
                            schema={templateLibrary}
                            currentName={currentName}
                            afterSubmit={() =>
                              deleteCanaryTemplateLibrary(
                                templateLibraryId,
                                currentName
                              )
                            }
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <NoDataFound pageName="Template Libraries" colSpan={6} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={rowCount}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </>
      )}
    </Container>
  )
}
