import useIsMatchingMD from 'hooks/useIsMatchingMD'
import { useState } from 'react'
import Input from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'
import Icon, { IconType } from 'components/Icons'
import { SearchContainer } from 'styles/components/common/SearchField.styled'

interface SearchFieldProps {
  searchText: string
  handleSubmit: (input: string) => void
  width?: number
}

export default function SearchField(props: SearchFieldProps) {
  const { searchText, handleSubmit, width = 250 } = props
  const isMatchingMD = useIsMatchingMD()

  const [inputText, setInputText] = useState(searchText)

  const handleKeyUp = (event: any) => {
    if (event.key === 'Enter') {
      event.target.blur()
      handleSubmit(inputText)
    } else setInputText(event.target.value)
  }

  return (
    <SearchContainer $width={width} $isMatchingMD={isMatchingMD!}>
      <Input
        type="text"
        onKeyUp={handleKeyUp}
        value={inputText}
        onChange={(e) => setInputText(e.target.value)}
        placeholder="Search"
        disableUnderline
        startAdornment={
          inputText !== '' ? (
            <InputAdornment
              position="start"
              sx={{ '&:hover': { cursor: 'pointer' } }}
            >
              <Icon
                name={IconType.X}
                fontSize="small"
                onClick={() => {
                  setInputText('')
                  handleSubmit('')
                }}
              />
            </InputAdornment>
          ) : undefined
        }
      />
      <Icon
        name={IconType.SearchRounded}
        fontSize="small"
        onClick={() => handleSubmit(inputText)}
      />
    </SearchContainer>
  )
}
