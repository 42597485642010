export const AppConfig = { apiUrl: '127.0.0.1:8000' }
export const userTokenKey = 'cloud_canaries_user_token'
export const userIdKey = 'cloud_canaries_user_id'
export const companyIdKey = 'cloud_canaries_company_id'
export const usePlatformClustersIdKey = 'cloud_canaries_use_platform_clusters'
export const userTokenExpirationKey = 'cloud_canaries_user_token_expiration'
export const userIsTempPassword = 'cloud_canaries_user_is_temp_password'
export const userIsFirstTime = 'cloud_canaries_user_is_first_time_user'
export const userTokenExpirationMinutes = 29
export const userInactivityAllowedMinutes = 20
export const promptTimout = 5
export const queryParamPageSizeDefault = 200
export const queryParamPageSizeJumbo = 1000
export const rowsPerPageOptions = [10, 50, 100]

export default AppConfig
