import { userLogout } from 'client_side_state/slices/auth'
import { useAppDispatch } from 'client_side_state/store'
import useDocumentTitle from 'hooks/useDocumentTitle'
import { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Button from '@mui/material/Button'
import Box from '@mui/system/Box'
import { IChangeEvent } from '@rjsf/core'
import Form from '@rjsf/mui'
import { RJSFSchema } from '@rjsf/utils'
import validator from '@rjsf/validator-ajv8'
import CustomBaseInputTemplate from 'components/common/RJSF/MUI/CustomBaseInputTemplate'
import { configLogin } from 'constants/formconfig/Login'
import { AuthContext } from 'contexts/AuthContext'
import RoutesService from 'services/routes'
import 'styles/pages/User/Login.scss'
import useStyles from './styles'

const schema = configLogin.schema

export default function LoginPage() {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const { isAuthenticated, loginUser, handleLogout } = useContext(AuthContext)
  useDocumentTitle('Sign In')

  useEffect(() => {
    if (isAuthenticated()) {
      dispatch(userLogout())
      handleLogout()
    }
  }, [dispatch, handleLogout, isAuthenticated])

  const handleSubmit = async (
    data: IChangeEvent<any, RJSFSchema, any>,
    event: React.FormEvent<any>
  ) => {
    const body = JSON.parse(JSON.stringify(data.formData))
    const creds = { email: body.email, password: body.password }
    await loginUser(creds)
  }

  return (
    <div className="login">
      <div className="box">
        <h3 className="centered-text">
          Sign in to your <br />
          Cloud Canaries Account
        </h3>
        <img
          className="logo"
          src="/img/new-sitting-scientist.png"
          alt="login"
        />
        <Form
          schema={schema}
          uiSchema={configLogin.uiSchema}
          onSubmit={handleSubmit}
          validator={validator}
          templates={{ BaseInputTemplate: CustomBaseInputTemplate }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignContent: 'center'
            }}
          >
            <Link
              to={RoutesService.userForgotPassword()}
              className={classes.button}
            >
              Forgot Password?
            </Link>
            <Button
              variant={'outlined'}
              color="primary"
              className="submit mt-2"
              type="submit"
            >
              Sign In
            </Button>
          </Box>
        </Form>
      </div>
    </div>
  )
}
