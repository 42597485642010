import { history } from 'client_side_state/history'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { OrganizationRequiredMessage } from 'components/UserOrganization/OrganizationRequiredDialog'
import Routes from 'services/routes'
import { userImpersonateSetup, userLogout } from './auth'
import type { RootState } from '../store'

const pkg = require('../../../package.json')

export interface AppStates {
  version: string
  organizationRequiredModalOpen: boolean
  organizationRequiredModalMessage: OrganizationRequiredMessage
  noDataRequiredAvailableModalOpen: boolean
}

const initialState: AppStates = {
  version: pkg.version,
  organizationRequiredModalOpen: false,
  organizationRequiredModalMessage: OrganizationRequiredMessage.CanaryCreate,
  noDataRequiredAvailableModalOpen: false
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAppVersion: (state, action: PayloadAction<string>) => {
      state.version = action.payload
    },
    setOrganizationRequiredModalOpen: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.organizationRequiredModalOpen = action.payload
    },
    setOrganizationRequiredModalMessage: (
      state,
      action: PayloadAction<OrganizationRequiredMessage>
    ) => {
      state.organizationRequiredModalMessage = action.payload
    },
    setNoDataRequiredAvailableModalOpen: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.noDataRequiredAvailableModalOpen = action.payload
    },
    resetAppStateRequest: (state) => {
      state.version = initialState.version
      state.organizationRequiredModalOpen =
        initialState.organizationRequiredModalOpen
      state.noDataRequiredAvailableModalOpen =
        initialState.noDataRequiredAvailableModalOpen
    }
  },
  extraReducers: (builder) => {
    builder.addCase(userLogout, (state, action) => {
      localStorage.clear()
      history.push(Routes.userLogin())
    })
    builder.addCase(userImpersonateSetup, (state, action) => {
      localStorage.clear()
    })
  }
})

// Action creators are generated for each case reducer function
export const {
  resetAppStateRequest,
  setAppVersion,
  setOrganizationRequiredModalOpen,
  setOrganizationRequiredModalMessage,
  setNoDataRequiredAvailableModalOpen
} = appSlice.actions

export default appSlice.reducer

export const selectAppVersion = (state: RootState) => state.app.version
export const selectOrganizationRequiredModalOpen = (state: RootState) =>
  state.app.organizationRequiredModalOpen
export const selectOrganizationRequiredModalMessage = (state: RootState) =>
  state.app.organizationRequiredModalMessage
export const selectNoDataRequiredAvailableModalOpen = (state: RootState) =>
  state.app.noDataRequiredAvailableModalOpen
