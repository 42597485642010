import Skeleton from '@mui/material/Skeleton'
import SharedStyles from './SharedStyles'

export default function AuoLoadCanaryTemplatesLoader() {
  const classes = SharedStyles()
  return (
    <div className={classes.loadingSkeletons}>
      {Array.from([1, 2, 3, 4, 5, 6]).map((element) => (
        <Skeleton
          key={element}
          variant="rectangular"
          width="100%"
          height={60}
          className={classes.skeleton}
        />
      ))}
    </div>
  )
}
