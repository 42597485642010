import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Box from '@mui/system/Box'
import { IChangeEvent } from '@rjsf/core'
import MuiForm from '@rjsf/mui'
import { FormValidation, RJSFSchema } from '@rjsf/utils'
import validator from '@rjsf/validator-ajv8'
import { isAxiosError } from 'axios'
import CustomBaseInputTemplate from 'components/common/RJSF/MUI/CustomBaseInputTemplate'
import { configResetPassword } from 'constants/formconfig/ResetPassword'
import { AuthContext } from 'contexts/AuthContext'
import RoutesService from 'services/routes'
import useStyles from './styles'

const initialFormData: any = {
  token: '',
  new_password: '',
  new_password_repeat: ''
}
export default function ResetPassword() {
  const classes = useStyles()
  const history = useHistory()

  const { unAuthenticatedAPIService } = useContext(AuthContext)
  const schema = configResetPassword.schema

  const { search } = history.location

  const token: string | null = useMemo(() => {
    const searchParams = new URLSearchParams(search)
    return searchParams.get('token')
  }, [search])

  const [formData, setFormData] = useState<any>(initialFormData)
  const [isVerifyingTokenLoading, setIsVerifyingTokenLoading] =
    useState<boolean>(true)
  const [resetPasswordTokenValid, setResetPasswordTokenValid] =
    useState<boolean>(false)
  const [passwordResetSuccessful, setPasswordResetSuccessful] =
    useState<boolean>(false)

  const validateResetToken = useCallback(async () => {
    setIsVerifyingTokenLoading(true)
    try {
      await unAuthenticatedAPIService
        .validateResetTokenAuthViewSet({ token: token })
        .then((json) => {
          if (
            json.status === 200 &&
            json.data.detail === 'The password reset link is valid.'
          ) {
            setResetPasswordTokenValid(true)
          } else {
            setResetPasswordTokenValid(false)
          }
        })
    } catch (err: any) {
      if (isAxiosError(err)) {
        if (err && err.response && err.response.data) {
          setResetPasswordTokenValid(false)
        }
      }
    }
    setIsVerifyingTokenLoading(false)
  }, [token, unAuthenticatedAPIService])

  useEffect(() => {
    validateResetToken()
  }, [validateResetToken])

  const handleFormChange = (
    event: IChangeEvent<any, RJSFSchema, any>,
    id?: string | undefined
  ) => {
    const data = JSON.parse(JSON.stringify(event.formData))
    setFormData(data)
  }
  function validate(formData: any, errors: FormValidation) {
    if (formData.new_password !== formData.new_password_repeat) {
      errors.new_password_repeat!.addError("Passwords don't match")
    }
    return errors
  }

  const handleSubmit = async (
    data: IChangeEvent<any, RJSFSchema, any>,
    event: React.FormEvent<any>
  ) => {
    let requestBody = JSON.parse(JSON.stringify(data.formData))
    requestBody.token = token

    await unAuthenticatedAPIService
      .resetPasswordAuthViewSet(requestBody)
      .then((json) => {
        if (
          json.status === 200 &&
          json.data.detail === 'Password reset successful.'
        ) {
          setPasswordResetSuccessful(true)
        } else {
          setPasswordResetSuccessful(false)
        }
      })
  }

  if (isVerifyingTokenLoading) {
    return <div>Loading...</div>
  }
  return resetPasswordTokenValid ? (
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      {passwordResetSuccessful ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
          }}
        >
          <Typography
            variant="h5"
            component="h1"
            sx={{
              mt: 1,
              color: '#4eb020',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <CheckCircleOutlineRoundedIcon sx={{ mr: 1 }} />
            Password Reset Successful. Please Sign In.
          </Typography>

          <Link
            to={RoutesService.userLogin()}
            className={`${classes.button} ${classes.forgotPasswordButton}`}
          >
            Sign In
          </Link>
        </Box>
      ) : (
        <>
          <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Set New Password
          </Typography>
          <MuiForm
            schema={schema}
            formData={{ ...formData, token: token }}
            onSubmit={handleSubmit}
            onChange={handleFormChange}
            customValidate={validate}
            uiSchema={configResetPassword.uiSchema}
            validator={validator}
            templates={{ BaseInputTemplate: CustomBaseInputTemplate }}
          >
            <Button
              variant="outlined"
              color="primary"
              className={`${classes.button} ${classes.forgotPasswordButton}`}
              type="submit"
            >
              Update Password
            </Button>
          </MuiForm>
        </>
      )}
    </Box>
  ) : (
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Typography
        variant="h5"
        component="h1"
        sx={{
          mt: 1,
          color: '#f93e3e',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        The link is invalid or expired, please obtain a new link for resetting
        the password.
      </Typography>
    </Box>
  )
}
