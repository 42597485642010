import {
  ChangeEvent,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { Snowflake } from 'lib/CloudCanariesRestfulAPI'
import { APIServiceContext } from './APIServiceContext'
import { OrganizationsContext } from './OrganizationsContext'

interface WalletContextProviderProps {
  children: ReactNode
}

interface WalletContextProps {
  areWalletsLoading: boolean
  wallets: Snowflake[]
  rowsPerPage: number
  page: number
  rowCount: number
  getWallets: () => Promise<void>
  onRowsPerPageChange: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void
  handleChangePage: (event: any, newPage: number) => void
}
export const WalletContext = createContext<WalletContextProps>(null as any)

export default function WalletContextProvider(
  props: WalletContextProviderProps
) {
  const { children } = props

  const { apiService } = useContext(APIServiceContext)
  const { organizationId } = useContext(OrganizationsContext)

  const [page, setPage] = useState<number>(0)
  const [rowCount, setRowCount] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [wallets, setWallets] = useState<Snowflake[]>([])
  const [areWalletsLoading, setAreWalletsLoading] = useState<boolean>(true)

  useEffect(() => {
    setRowCount(0)
  }, [organizationId])

  const getWallets = useCallback(async () => {
    setRowCount(0)
    const requestedPage = page + 1

    setAreWalletsLoading(true)
    await apiService
      .listSnowflakes(requestedPage, rowsPerPage, organizationId)
      .then((json) => {
        setWallets(json.data.results ?? [])
        setRowCount(json.data.count ?? 0)
        setAreWalletsLoading(false)
      })
  }, [apiService, organizationId, page, rowsPerPage])

  useEffect(() => {
    getWallets()
  }, [getWallets])

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage)
  }

  const onRowsPerPageChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <WalletContext.Provider
      value={{
        areWalletsLoading,
        wallets,
        getWallets,
        page,
        rowCount,
        rowsPerPage,
        handleChangePage,
        onRowsPerPageChange
      }}
    >
      {children}
    </WalletContext.Provider>
  )
}
