import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'

interface NoDataFoundProps {
  pageName: string
  colSpan: number
}
export default function NoDataFound(props: NoDataFoundProps) {
  const { pageName, colSpan = 6 } = props
  return (
    <TableRow>
      <TableCell colSpan={colSpan} style={{ marginLeft: '0.5rem' }}>
        <Typography variant="h4" align="left">
          {`No ${pageName} Found`}
        </Typography>
      </TableCell>
    </TableRow>
  )
}
