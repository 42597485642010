import ServicesContextProvider from 'contexts/ServicesContext'
import TemplateLibrariesContextProvider from 'contexts/TemplateLibrariesContext'
import CanaryTemplateLibraryList from 'pages/AdminPages/CanaryTemplateLibraryList'

export default function TemplateLibrariesAdminPage() {
  return (
    <ServicesContextProvider>
      <TemplateLibrariesContextProvider>
        <CanaryTemplateLibraryList />
      </TemplateLibrariesContextProvider>
    </ServicesContextProvider>
  )
}
