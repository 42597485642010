import { FormConfigTypes } from './FormConfigsTypes'

export const configOrganizationCreateUser: FormConfigTypes = {
  schema: {
    type: 'object',
    required: ['name'],
    properties: {
      name: { type: 'string', title: 'Name' }
    }
  }
}

export const configOrganizationEdit: FormConfigTypes = {
  schema: {
    type: 'object',
    required: ['name'],
    properties: {
      name: { type: 'string', title: 'Name' },
      send_data_to_snowflake: {
        type: 'boolean',
        title: 'Send Data to Snowflake?'
      },
      use_cortex_forecast_model: {
        type: 'boolean',
        title: 'Use Snowflake Cortex?'
      }
    }
  }
}
