import { CanaryForecastDaily } from '.'
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import Box from '@mui/system/Box'
import { CanaryForecastGraphMetrics } from 'constants/CanaryForecastGraphMetrics'
import { CanaryTaskReturnDataTypeEnum } from 'lib/CloudCanariesRestfulAPI'
import { formatDateTimeShort } from 'services/utils'

interface ForecastCanaryDailyForecastDataManageChartProps {
  selectedCanaryDailyForecastChart: CanaryForecastGraphMetrics
  canaryDailyDetailedForecastsData: CanaryForecastDaily[]
}

export default function ForecastCanaryDailyForecastDataManageChart(
  props: ForecastCanaryDailyForecastDataManageChartProps
) {
  const { selectedCanaryDailyForecastChart, canaryDailyDetailedForecastsData } =
    props

  if (
    selectedCanaryDailyForecastChart !==
      CanaryForecastGraphMetrics.RESPONSE_LATENCY &&
    selectedCanaryDailyForecastChart !==
      CanaryForecastGraphMetrics.RESPONSE_VALUE
  ) {
    return <></>
  }
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  )

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      x: {
        ticks: {
          font: {
            size: 20
          }
        }
      },
      y: {
        display: true,
        title: {
          display: true,
          text:
            selectedCanaryDailyForecastChart ===
            CanaryForecastGraphMetrics.RESPONSE_VALUE
              ? 'Value'
              : selectedCanaryDailyForecastChart ===
                  CanaryForecastGraphMetrics.RESPONSE_LATENCY
                ? 'Latency'
                : 'Percentage',
          font: {
            size: 20
          }
        },
        ticks: {
          font: {
            size: 20
          }
        }
      }
    },
    plugins: {
      legend: {
        position: 'top' as const,
        labels: {
          font: {
            size: 20
          }
        }
      },
      interaction: {
        intersect: true
      }
    }
  }

  let labels = ['']
  if (
    selectedCanaryDailyForecastChart ===
    CanaryForecastGraphMetrics.RESPONSE_VALUE
  ) {
    canaryDailyDetailedForecastsData.forEach((data) => {
      labels?.push(
        formatDateTimeShort(
          data.for_timestamp ? data.for_timestamp : data.for_date!
        )
      )
    })
  } else if (
    selectedCanaryDailyForecastChart ===
    CanaryForecastGraphMetrics.RESPONSE_LATENCY
  ) {
    canaryDailyDetailedForecastsData.forEach((data) => {
      labels?.push(
        formatDateTimeShort(
          data.for_timestamp ? data.for_timestamp : data.for_date!
        )
      )
    })
  }

  let datasets = []
  if (
    selectedCanaryDailyForecastChart ===
    CanaryForecastGraphMetrics.RESPONSE_VALUE
  ) {
    datasets.push({
      label: 'Response Value',
      data: canaryDailyDetailedForecastsData.map((data) => {
        if (
          data.canary_return_data_type === CanaryTaskReturnDataTypeEnum.Boolean
        ) {
          if (data.forecast_normalized_value! !== 'No Forecast Value') {
            if (data.forecast_normalized_value) {
              return 1.0
            } else {
              return 0.0
            }
          } else {
            return 0.0
          }
        } else {
          return data.forecast_normalized_value
            ? parseFloat(data.forecast_normalized_value)
            : data.forecasted_response_value
        }
      }),
      borderColor: '#35a2eb',
      backgroundColor: '#35a2eb80',
      tension: 0.5
    })
  } else if (
    selectedCanaryDailyForecastChart ===
    CanaryForecastGraphMetrics.RESPONSE_LATENCY
  ) {
    datasets.push({
      label: 'Response Latecny',
      data: canaryDailyDetailedForecastsData.map((data) =>
        data.response_latency
          ? data.response_latency
          : data.forecasted_response_latency
      ),
      borderColor: '#a8d1f5',
      backgroundColor: '#a8d1f580',
      tension: 0.5
    })
  }

  const data = {
    labels,
    datasets
  }

  return (
    <Box sx={{ position: 'relative', width: '49%' }}>
      <Line options={options} data={data} />
    </Box>
  )
}
