import { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { IChangeEvent } from '@rjsf/core'
import MuiForm from '@rjsf/mui'
import {
  ErrorSchema,
  ErrorSchemaBuilder,
  RJSFSchema,
  UiSchema
} from '@rjsf/utils'
import validator from '@rjsf/validator-ajv8'
import { AxiosError, isAxiosError } from 'axios'
import DialogModal from 'components/Modals/DialogModal'
import ModalSharedStyles from 'components/Modals/ModalSharedStyles'
import CreateButton from 'components/common/CreateButton'
import FormsCreateButton from 'components/common/FormsCreateButton'
import { ToastInfos } from 'constants/toast_success_messages'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { AuthContext } from 'contexts/AuthContext'
import { OrganizationsContext } from 'contexts/OrganizationsContext'
import { SharedCanaryTemplateLibrariesContext } from 'contexts/SharedCanaryTemplateLibrariesContext'
import CreateModalObjectFieldTemplate from './CreateModalObjectFieldTemplate'

export default function CreateModal() {
  const classes = ModalSharedStyles()

  const { companyId } = useContext(AuthContext)
  const { apiService } = useContext(APIServiceContext)
  const { organizationId } = useContext(OrganizationsContext)
  const {
    storeCanaryClouds,
    storeCanaryComponents,
    getSharedTemplateLibraries
  } = useContext(SharedCanaryTemplateLibrariesContext)

  const [open, setOpen] = useState<boolean>(false)
  const [formData, setFormData] = useState<any>({})
  const [extraErrors, setExtraErrors] = useState<ErrorSchema>({})

  useEffect(() => {
    if (organizationId) {
      setFormData((prev: any) => ({ ...prev, organization: organizationId }))
    }
  }, [organizationId])

  const handleFormDataChange = (
    event: IChangeEvent<any, RJSFSchema, any>,
    id?: string | undefined
  ) => {
    const data = JSON.parse(JSON.stringify(event.formData))
    setFormData(data)
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleModalClose = () => {
    setOpen(false)
    setFormData({})
    setExtraErrors({})
  }

  const onSubmit = async (
    data: IChangeEvent<any, RJSFSchema, any>,
    event: React.FormEvent<any>
  ) => {
    let requestBody = JSON.parse(JSON.stringify(data.formData))

    requestBody.company = companyId
    requestBody.version = requestBody.group.version
    requestBody.canary_templates_count =
      requestBody.group.canary_templates_count
    requestBody.cloud = requestBody.group1.cloud
    requestBody.component = requestBody.group1.component
    requestBody.description = requestBody.group2.description
    requestBody.zip_file = requestBody.group2.zip_file

    try {
      await apiService.createStoreCanary(requestBody)
      await getSharedTemplateLibraries()
      setOpen(false)
      toast.info(ToastInfos.WAITING_FOR_APPROVAL, {
        position: 'top-right'
      })
    } catch (e: any) {
      if (isAxiosError(e)) {
        const err = e as AxiosError
        if (err.response?.status === 400) {
          const errors = err.response?.data as {}
          const builder = new ErrorSchemaBuilder()
          Object.entries(errors).forEach(([key, value]) => {
            builder.addErrors(value as string, key)
          })
          setExtraErrors(builder.ErrorSchema)
        }
      }
    }
  }
  const storeCanaryCloudsEnums: { const: string; title: string }[] | undefined =
    storeCanaryClouds?.flatMap((o) => {
      return {
        const: o.id!,
        title: o.name!
      }
    })

  const storeCanaryComponentsEnums:
    | { const: string; title: string }[]
    | undefined = storeCanaryComponents?.flatMap((o) => {
    return {
      const: o.id!,
      title: o.name!
    }
  })

  const schema = {
    type: 'object',
    required: ['name', 'provider_name', 'service_name', 'is_private'],
    properties: {
      name: { type: 'string', title: 'Title' },
      provider_name: { type: 'string', title: 'Default Provider' },
      service_name: { type: 'string', title: 'Default Service' },
      group: {
        type: 'object',
        title: '',
        required: ['version', 'canary_templates_count'],
        properties: {
          version: {
            type: 'string',
            title: 'Version'
          },
          canary_templates_count: {
            type: 'number',
            title: 'Number of Canary Templates'
          }
        }
      },
      group1: {
        type: 'object',
        title: '',
        required: ['cloud', 'component'],
        properties: {
          cloud: {
            type: 'string',
            title: 'Cloud',
            oneOf: storeCanaryCloudsEnums
          },
          component: {
            type: 'string',
            title: 'Categories',
            oneOf: storeCanaryComponentsEnums
          }
        }
      },
      is_private: {
        type: 'boolean',
        title: 'Privacy',
        oneOf: [
          { const: true, title: 'Private' },
          { const: false, title: 'Public' }
        ]
      },
      group2: {
        type: 'object',
        title: '',
        required: ['description', 'zip_file'],
        properties: {
          description: {
            type: 'string',
            title: 'Description'
          },
          zip_file: {
            type: 'string',
            format: 'data-url',
            title: 'ZIP file'
          }
        }
      }
    }
  } as RJSFSchema

  const uiSchema: UiSchema = {
    'ui:ObjectFieldTemplate': CreateModalObjectFieldTemplate,
    group2: {
      description: {
        'ui:options': {
          widget: 'textarea',
          classNames: 'description',
          rows: 7
        }
      },
      zip_file: {
        'ui:options': {
          widget: 'file',
          accept: '.zip'
        },
        'ui:help': 'Please use .ZIP files only.'
      }
    },
    is_private: {
      'ui:options': {
        widget: 'select'
      }
    },
    'ui:order': [
      'name',
      'provider_name',
      'service_name',
      'group',
      'group1',
      'version',
      'is_private',
      'group2'
    ]
  }

  return (
    <>
      <CreateButton text="Share a Canary" onClick={handleClickOpen} />
      <DialogModal
        open={open}
        onClose={handleModalClose}
        // className={extraClasses.paperWidthSm}
        // maxWidth='md'
        dialogTitle="Share a Cloud Canary"
        dialogContent={
          <MuiForm
            schema={schema}
            onSubmit={onSubmit}
            formData={formData}
            onChange={handleFormDataChange}
            extraErrors={extraErrors}
            uiSchema={uiSchema}
            className={classes.muiForm}
            validator={validator}
          >
            <FormsCreateButton>Upload</FormsCreateButton>
          </MuiForm>
        }
      />
    </>
  )
}
