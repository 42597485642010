import {
  selectNoDataRequiredAvailableModalOpen,
  setNoDataRequiredAvailableModalOpen
} from 'client_side_state/slices/app'
import { useAppDispatch, useAppSelector } from 'client_side_state/store'
import parse from 'html-react-parser'
import Button from '@mui/material/Button'
import DialogContentText from '@mui/material/DialogContentText'
import DialogModal from './DialogModal'

export enum NoRequiredDataAvailableMessage {
  CanaryableServices = 'There are no <b>Services</b> or <b>Canaries</b> available for your selected Organization'
}

interface NoRequiredDataAvailableDialogProps {
  message: string
}

export default function NoRequiredDataAvailableDialog(
  props: NoRequiredDataAvailableDialogProps
) {
  const { message } = props
  const dispatch = useAppDispatch()

  const open = useAppSelector(selectNoDataRequiredAvailableModalOpen)

  const handleClose = () => {
    dispatch(setNoDataRequiredAvailableModalOpen(false))
  }
  return (
    <DialogModal
      onClose={handleClose}
      open={open}
      fullWidth
      dialogTitle="No Data Available"
      dialogContent={
        <DialogContentText id="alert-dialog-description">
          {parse(message)}
        </DialogContentText>
      }
      dialogActions={<Button onClick={handleClose}>OK</Button>}
    />
  )
}
