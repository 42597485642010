import { Theme } from '@mui/material/styles'
import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles(({ spacing, breakpoints }: Theme) =>
  createStyles({
    mainContainer: {
      top: '3rem',
      position: 'relative'
    },
    headerRow: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: '10px 0 12px',
      [breakpoints.down('md')]: {
        justifyContent: 'center'
      }
    },
    firstCell: {
      width: '6rem'
    },
    lastCell: {
      width: '6rem'
    },
    viewCreateLinks: {
      textDecoration: 'none',
      fontWeight: 600,
      color: '#3D89FD',
      verticalAlign: 'middle'
    },
    smallCell: {
      padding: spacing(2, 0.5)
    },
    incidentsTitle: {
      marginBlockEnd: 4,
      marginBlockStart: 6
    }
  })
)
