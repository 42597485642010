import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { CanaryDailyForecasts } from './IndividualCanaryForecastsSection'

interface ForecastCanaryDailyForecastsGridChartsProps {
  canaryDailyForecast: CanaryDailyForecasts
}

export default function ForecastCanaryDailyForecastsGridCharts(
  props: ForecastCanaryDailyForecastsGridChartsProps
) {
  const { canaryDailyForecast } = props

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
  )

  const options = {
    responsive: true,
    scales: {
      y: {
        display: true,
        title: {
          display: true,
          text: 'Percentage'
        }
      }
    },
    plugins: {
      legend: {
        position: 'top' as const
      },
      interaction: {
        intersect: false
      }
    }
  }

  const labels = canaryDailyForecast.day_of_week

  const data = {
    labels,
    datasets: [
      {
        label: 'Response Health',
        data: canaryDailyForecast.percentage_chance_of_incident,
        borderColor: '#e0a173',
        backgroundColor: '#e0a173',
        tension: 0.5
      },
      {
        label: 'Latency Health',
        data: canaryDailyForecast.latency_issues_predicted,
        borderColor: 'rgb(44, 79, 133)',
        backgroundColor: 'rgb(44, 79, 133, 0.5)',
        tension: 0.5
      }
    ]
  }

  return <Bar options={options} data={data} />
}
