import { useMemo, useState } from 'react'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import DialogModal from 'components/Modals/DialogModal'
import ModalSharedStyles from 'components/Modals/ModalSharedStyles'
import { UserSubscribedPlan } from 'lib/CloudCanariesRestfulAPI'

type SubscriptionCancelModalProps = {
  selectedSubscriptionPlan: UserSubscribedPlan | undefined
  subscription: UserSubscribedPlan
  afterSubmit: () => Promise<void>
}

export default function SubscriptionCancelModal(
  props: SubscriptionCancelModalProps
) {
  const { selectedSubscriptionPlan, subscription, afterSubmit } = props
  const classes = ModalSharedStyles()

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false)

  const handleClickOpen = () => {
    setDeleteConfirmOpen(true)
  }

  const handleClose = () => {
    setDeleteConfirmOpen(false)
  }

  const confirmDelete = () => {
    afterSubmit()
    handleClose()
  }
  const isSelectedPlan = useMemo(
    () => selectedSubscriptionPlan?.id === subscription.id,
    [selectedSubscriptionPlan?.id, subscription.id]
  )

  const cancelButtonDisabled = useMemo(
    () =>
      !isSelectedPlan ||
      selectedSubscriptionPlan?.assigned_canaries_count! !== 0,
    [isSelectedPlan, selectedSubscriptionPlan?.assigned_canaries_count]
  )

  return (
    <>
      <Tooltip
        disableHoverListener={!cancelButtonDisabled}
        title={
          <Typography variant="body2">
            {isSelectedPlan
              ? 'Please make sure to subtract assigned canaries before canceling the subscription'
              : 'Please select the plan first'}
          </Typography>
        }
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: '#ffffff',
              color: '#000000C3',
              border: '1px solid #0000004D'
            }
          }
        }}
        arrow
      >
        <span>
          <Button
            variant="contained"
            size="small"
            disabled={cancelButtonDisabled}
            sx={{
              ml: 1,
              textTransform: 'capitalize',
              background: '#3D89FF',
              color: '#FFFFFF'
            }}
            onClick={handleClickOpen}
          >
            Cancel Subscription
          </Button>
        </span>
      </Tooltip>
      <DialogModal
        open={deleteConfirmOpen}
        onClose={handleClose}
        dialogTitle="Cancel Subscription"
        dialogContent={
          <Typography variant="body1" align="center">
            Are you sure you want to cancel the subscription{' '}
            <b>{subscription.plan_name}</b>?
          </Typography>
        }
        dialogActions={
          <>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              onClick={confirmDelete}
              variant="contained"
              className={classes.deleteButton}
              autoFocus
            >
              Confirm
            </Button>
          </>
        }
      />
    </>
  )
}
