import useDocumentTitle from 'hooks/useDocumentTitle'
import { useContext } from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Icon, { IconType } from 'components/Icons'
import { AdminPagesTitles } from 'components/MyAdminPages/Constants'
import MyAdminPagesCommonHeader from 'components/MyAdminPages/MyAdminPagesCommonHeader'
import NoDataFound from 'components/MyAdminPages/NoDataFound'
import CategoriesFilter from 'components/ShareCanaryTemplateLibraries/CategoriesFilter'
import CreateModal from 'components/ShareCanaryTemplateLibraries/CreateModal'
import PagesSkeletonLoader from 'components/common/Loaders/PagesSkeletonLoader'
import { rowsPerPageOptions } from 'constants/AppConfig'
import { SharedCanaryTemplateLibrariesContext } from 'contexts/SharedCanaryTemplateLibrariesContext'
import adminPagesSharedStyles from 'pages/AdminPages/adminPagesSharedStyles'

export default function SharedCanaryTemplateLibrariesList() {
  const classes = adminPagesSharedStyles()
  useDocumentTitle('Shared Canary Template Libraries')

  const {
    areSharedTemplateLibrariesLoading,
    sharedTemplateLibraries,
    page,
    rowCount,
    rowsPerPage,
    onRowsPerPageChange,
    handleChangePage
  } = useContext(SharedCanaryTemplateLibrariesContext)

  return (
    <Container maxWidth="xl" className={classes.mainContainer}>
      <MyAdminPagesCommonHeader />
      <h1 className={classes.headerRow}>
        {AdminPagesTitles.SharedCanaryTemplateLibraries}
        <CreateModal />
      </h1>
      {areSharedTemplateLibrariesLoading ? (
        <PagesSkeletonLoader />
      ) : (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center'
            }}
          >
            <CategoriesFilter />
          </Box>
          <TableContainer component={Paper} elevation={3}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Ver</TableCell>
                  <TableCell>Canaries</TableCell>
                  <TableCell>Services</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell align="center">Public</TableCell>
                  <TableCell>Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sharedTemplateLibraries.length > 0 ? (
                  sharedTemplateLibraries.map((sharedTemplateLibrary) => {
                    return (
                      <TableRow key={sharedTemplateLibrary.id}>
                        <TableCell>{sharedTemplateLibrary.name}</TableCell>
                        <TableCell>{sharedTemplateLibrary.status}</TableCell>
                        <TableCell>{sharedTemplateLibrary.version}</TableCell>
                        <TableCell>
                          {sharedTemplateLibrary.canary_templates_count}
                        </TableCell>
                        <TableCell>
                          {sharedTemplateLibrary.service_name}
                        </TableCell>
                        <TableCell>
                          {new Date(
                            sharedTemplateLibrary.created!
                          ).toLocaleDateString()}
                        </TableCell>
                        <TableCell align="center">
                          {sharedTemplateLibrary.is_private ? (
                            <Icon name={IconType.LockIcon} />
                          ) : (
                            <Icon name={IconType.LockOpenIcon} />
                          )}
                        </TableCell>
                        <TableCell>
                          {sharedTemplateLibrary.description}
                        </TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <NoDataFound
                    pageName="Shared Canary Template Libraries"
                    colSpan={7}
                  />
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={rowCount}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </>
      )}
    </Container>
  )
}
