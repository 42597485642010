import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { queryParamPageSizeJumbo } from 'constants/AppConfig'
import {
  CanaryServiceProvider,
  CanaryableService
} from 'lib/CloudCanariesRestfulAPI'
import { APIServiceContext } from './APIServiceContext'
import { AuthContext } from './AuthContext'
import { OrganizationsContext } from './OrganizationsContext'

interface ServicesContextProviderProps {
  children: ReactNode
}

interface ServicesContextProps {
  serviceProviders: CanaryServiceProvider[] | null
  fetchServiceProviders: () => Promise<void>
  canaryableServices: CanaryableService[] | null
}
export const ServicesContext = createContext<ServicesContextProps>(null as any)

export default function ServicesContextProvider(
  props: ServicesContextProviderProps
) {
  const { children } = props
  const { isAuthenticated } = useContext(AuthContext)

  const { organizationId } = useContext(OrganizationsContext)
  const { apiService } = useContext(APIServiceContext)

  const [serviceProviders, setServiceProviders] = useState<
    CanaryServiceProvider[] | null
  >([])
  const [canaryableServices, setCanaryableServices] = useState<
    CanaryableService[] | null
  >([])

  const fetchServiceProviders = useCallback(async () => {
    if (isAuthenticated()) {
      apiService
        .listServiceProviders(
          undefined,
          queryParamPageSizeJumbo,
          organizationId
        )
        .then((json) => {
          setServiceProviders(json.data.results ?? [])
        })
    }
  }, [apiService, isAuthenticated, organizationId])

  const getCanaryableServices = useCallback(async () => {
    if (isAuthenticated()) {
      apiService
        .listCanaryableServices(undefined, queryParamPageSizeJumbo, undefined)
        .then((json) => setCanaryableServices(json.data.results ?? []))
    }
  }, [apiService, isAuthenticated])

  useEffect(() => {
    fetchServiceProviders()
  }, [fetchServiceProviders])

  useEffect(() => {
    getCanaryableServices()
  }, [getCanaryableServices])

  return (
    <ServicesContext.Provider
      value={{
        serviceProviders,
        fetchServiceProviders,
        canaryableServices
      }}
    >
      {children}
    </ServicesContext.Provider>
  )
}
