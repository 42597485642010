import useDocumentTitle from 'hooks/useDocumentTitle'
import { useContext } from 'react'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Typography from '@mui/material/Typography'
import Box from '@mui/system/Box'
import IncidentsServiceLink from 'components/Incidents/IncidentsServiceLink'
import IncidentsStateFilter from 'components/Incidents/IncidentsStateFilter'
import MyAdminPagesCommonHeader from 'components/MyAdminPages/MyAdminPagesCommonHeader'
import { rowsPerPageOptions } from 'constants/AppConfig'
import { IncidentsContext } from 'contexts/IncidentsContext'
import adminPagesSharedStyles from 'pages/AdminPages/adminPagesSharedStyles'
import { secondsToHhMmSs } from 'services/utils'

export default function Incidents() {
  useDocumentTitle('Incidents Dashboard')
  const classes = adminPagesSharedStyles()

  const {
    incidents,
    rowCount,
    rowsPerPage,
    page,
    ordering,
    onRowsPerPageChange,
    handleChangePage,
    onChangeSort
  } = useContext(IncidentsContext)

  const getSortDirection = (
    ordering: string | undefined,
    field_name: string
  ) => {
    if (!ordering) {
      return 'asc'
    } else {
      return ordering.indexOf(field_name) === 0 ? 'asc' : 'desc'
    }
  }

  const isSortColumnActive = (
    ordering: string | undefined,
    field_name: string
  ) => {
    if (!ordering) {
      return false
    } else {
      return (
        ordering.indexOf(field_name) === 0 || ordering.indexOf(field_name) === 1
      )
    }
  }
  return (
    <Container maxWidth="xl" className={classes.mainContainer}>
      <MyAdminPagesCommonHeader />
      <Grid container>
        <Grid item xs={12} md={12}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <h1 className={`${classes.headerRow} ${classes.incidentsTitle}`}>
              Incidents
            </h1>
            <IncidentsStateFilter />
          </Box>
        </Grid>
      </Grid>
      <TableContainer component={Paper} elevation={3}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sortDirection={getSortDirection(ordering, 'service')}>
                <TableSortLabel
                  active={isSortColumnActive(ordering, 'service')}
                  direction={getSortDirection(ordering, 'service')}
                  onClick={(event) => onChangeSort(event, 'service')}
                >
                  Service
                </TableSortLabel>
              </TableCell>
              <TableCell
                sortDirection={getSortDirection(ordering, 'settings_group')}
              >
                <TableSortLabel
                  active={isSortColumnActive(ordering, 'settings_group')}
                  direction={getSortDirection(ordering, 'settings_group')}
                  onClick={(event) => onChangeSort(event, 'settings_group')}
                >
                  Service Settings Group
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={getSortDirection(ordering, 'start')}>
                <TableSortLabel
                  active={isSortColumnActive(ordering, 'start')}
                  direction={getSortDirection(ordering, 'start')}
                  onClick={(event) => onChangeSort(event, 'start')}
                >
                  Start
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={getSortDirection(ordering, 'stop')}>
                <TableSortLabel
                  active={isSortColumnActive(ordering, 'stop')}
                  direction={getSortDirection(ordering, 'stop')}
                  onClick={(event) => onChangeSort(event, 'stop')}
                >
                  Stop
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={getSortDirection(ordering, 'downtime')}>
                <TableSortLabel
                  active={isSortColumnActive(ordering, 'downtime')}
                  direction={getSortDirection(ordering, 'downtime')}
                  onClick={(event) => onChangeSort(event, 'downtime')}
                >
                  Downtime
                </TableSortLabel>
              </TableCell>
              <TableCell
                sortDirection={getSortDirection(ordering, 'incident_status')}
              >
                <TableSortLabel
                  active={isSortColumnActive(ordering, 'incident_status')}
                  direction={getSortDirection(ordering, 'incident_status')}
                  onClick={(event) => onChangeSort(event, 'incident_status')}
                >
                  Status
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={getSortDirection(ordering, 'impact')}>
                <TableSortLabel
                  active={isSortColumnActive(ordering, 'impact')}
                  direction={getSortDirection(ordering, 'impact')}
                  onClick={(event) => onChangeSort(event, 'impact')}
                >
                  Impact
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={getSortDirection(ordering, 'urgency')}>
                <TableSortLabel
                  active={isSortColumnActive(ordering, 'urgency')}
                  direction={getSortDirection(ordering, 'urgency')}
                  onClick={(event) => onChangeSort(event, 'urgency')}
                >
                  Urgency
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {incidents.length > 0 ? (
              incidents?.map((incident) => (
                <TableRow key={incident.id}>
                  <TableCell sortDirection={getSortDirection(ordering, 'name')}>
                    <IncidentsServiceLink incident={incident} />
                  </TableCell>
                  <TableCell>{incident.settings_group_name}</TableCell>
                  <TableCell>{incident.start}</TableCell>
                  <TableCell>{incident.stop}</TableCell>
                  <TableCell>
                    {secondsToHhMmSs(incident.downtime ?? 0)}
                  </TableCell>
                  <TableCell>{incident.incident_status}</TableCell>
                  <TableCell>{incident.impact}</TableCell>
                  <TableCell>{incident.urgency}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8} style={{ marginLeft: '0.5rem' }}>
                  <Typography variant="h4" align="left">
                    No Incidents
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={rowCount}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </Container>
  )
}
