import useDocumentTitle from 'hooks/useDocumentTitle'
import SwiperCore from 'swiper'
import 'swiper/css/bundle'
import 'swiper/css/navigation'
import { Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useContext, useRef } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import ComplianceArtifactsModal from 'components/Artifacts/ComplianceArtifactsModal'
import CanariesGrid from 'components/CanariesGrid/CanariesGrid'
import ComplianceCard from 'components/Compliance/ComplianceCard'
import SLAPeriodSelector from 'components/Compliance/SLAPeriodSelector'
import SettingsModal from 'components/Compliance/SettingsModal'
// import WhatIfModal from 'components/Compliance/WhatIfModal'
import DashboardsCommonHeader from 'components/Dashboards/DashboardsCommonHeader'
import CanariesGridLoader from 'components/common/Loaders/CanariesGridLoader'
import HealthAndComplianceDashboardsLoader from 'components/common/Loaders/HealthAndComplianceDashboardsLoader'
import PageFilterServiceProvider from 'components/common/PageFilterServiceProvider'
import { ComplianceContext } from 'contexts/ComplianceContext'
import { ServiceComplianceSummary } from 'lib/CloudCanariesRestfulAPI'
import { DashboardContainer } from 'styles/components/CanaryDashboard/DashboardContainer.styled'

export default function Compliance() {
  useDocumentTitle('Service Compliance Dashboard')
  const {
    areServiceSummaryAndColorsLoading,
    areCanariesLoading,
    filteredSummaryEvaluations,
    filteredCanaries,
    selectedServiceName
  } = useContext(ComplianceContext)

  SwiperCore.use([Navigation])
  const PrevRef = useRef<any>(null)
  const NextRef = useRef<any>(null)

  return (
    <DashboardContainer>
      {areServiceSummaryAndColorsLoading ? (
        <HealthAndComplianceDashboardsLoader isCompliance />
      ) : (
        <>
          <DashboardsCommonHeader pageName="Compliance" />
          <Grid
            container
            spacing={2}
            sx={{ marginBottom: '1.5rem', justifyContent: 'space-between' }}
          >
            <Grid
              item
              xs={6}
              md={4}
              sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <PageFilterServiceProvider />
              <SLAPeriodSelector />
            </Grid>
            {/* <Grid item xs={12} md={2} /> */}
            <Grid
              item
              // xs={12}
              // md={4}
              // lg={4}
              // xl={4}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                p: 2
              }}
            >
              <ComplianceArtifactsModal />
              {/* <WhatIfModal /> */}
              <SettingsModal />
            </Grid>
            {/* <Grid
              item
              xs={12}
              md={2}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
            </Grid> */}
          </Grid>

          {filteredSummaryEvaluations.length <= 0 ? (
            <Typography variant="h3" align="center">
              No Service selected or found...
            </Typography>
          ) : (
            <div
              style={{
                position: 'relative'
              }}
            >
              <div className=" swiper-button-prev" ref={PrevRef}></div>
              <div className=" swiper-button-next " ref={NextRef}></div>
              <Swiper
                navigation={{
                  prevEl: PrevRef.current ? PrevRef.current : undefined,
                  nextEl: NextRef.current ? NextRef.current : undefined
                }}
                onBeforeInit={(swiper) => {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  // eslint-disable-next-line no-param-reassign
                  swiper.params.navigation.prevEl = PrevRef.current
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  // eslint-disable-next-line no-param-reassign
                  swiper.params.navigation.nextEl = NextRef.current
                  swiper.navigation.update()
                }}
                slidesPerView={3}
                spaceBetween={10}
                breakpoints={{
                  640: {
                    slidesPerView: 1
                  },
                  850: {
                    slidesPerView: 2
                  },
                  1240: {
                    slidesPerView: 3
                  },
                  1650: {
                    slidesPerView: 4
                  }
                }}
              >
                {filteredSummaryEvaluations.map(
                  (summary: ServiceComplianceSummary) => (
                    <SwiperSlide key={summary.id}>
                      <ComplianceCard complianceSummary={summary} />
                    </SwiperSlide>
                  )
                )}
              </Swiper>
            </div>
          )}
        </>
      )}

      {areCanariesLoading ? (
        <CanariesGridLoader />
      ) : (
        <CanariesGrid
          canaries={filteredCanaries}
          serviceName={selectedServiceName}
        />
      )}
    </DashboardContainer>
  )
}
