import { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { AuthContext } from 'contexts/AuthContext'
import LoggedInNavBar from './LoggedInNav'
import PublicNav from './PublicNav'

export default function NavBar() {
  const location = useLocation()
  const { isAuthenticated, getIsTempPassword } = useContext(AuthContext)
  const noDisplay: boolean =
    location.pathname.split('/')[1] === 'canary' &&
    (location.pathname.split('/')[3] === 'manage' ||
      location.pathname.split('/')[3] === 'forecast' ||
      location.pathname.split('/')[3] === 'metrics')
  if (noDisplay) {
    return null
  } else {
    if (isAuthenticated() && !getIsTempPassword()) return <LoggedInNavBar />
    else return <PublicNav />
  }
}
