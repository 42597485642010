import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import { queryParamPageSizeDefault } from 'constants/AppConfig'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { CanaryDetailTaskReadOnlyMinimal } from 'lib/CloudCanariesRestfulAPI'
import RoutesService from 'services/routes'
import { GraphContainer } from 'styles/pages/CanaryManage.styled'
import CanaryTaskGraph from './CanaryTaskGraph'

const useStyles = makeStyles({
  cardContentRoot: {
    paddingTop: '0.5rem'
  },
  cardHeaderRoot: {
    textAlign: 'center',
    paddingBottom: '0.5rem'
  },
  cardHeaderTitle: {
    color: '#000000de',
    borderRadius: 4
  },
  cardContentTypography: {
    marginBottom: '0.75rem'
  }
})

interface GridItemProps {
  canaryName: string
  canaryId: string
  isAlarming: boolean
  hidden: boolean
}

export default function CanaryGridItem(props: GridItemProps) {
  const { canaryName, canaryId, hidden } = props
  const classes = useStyles()

  const { apiService } = useContext(APIServiceContext)

  const [canaryTasks, setCanaryTasks] =
    useState<CanaryDetailTaskReadOnlyMinimal[]>()

  const [firstTask, setFirstTask] =
    useState<CanaryDetailTaskReadOnlyMinimal | null>(null)

  const getCanaryTasks = useCallback(async () => {
    if (canaryId) {
      await apiService
        .listCanaryTasks(undefined, queryParamPageSizeDefault, canaryId)
        .then((json) => {
          setCanaryTasks(json.data.results ?? [])
        })
    }
  }, [apiService, canaryId])

  useEffect(() => {
    getCanaryTasks()
  }, [getCanaryTasks])

  useEffect(() => {
    if (canaryTasks && canaryTasks.length > 0) {
      setFirstTask(canaryTasks[0])
    }
  }, [canaryTasks])

  const [selectedCanaryTaskId, setSelectedCanaryTaskId] = useState('')

  useEffect(() => {
    if (firstTask) {
      setSelectedCanaryTaskId(firstTask.id!)
    }
  }, [firstTask])

  const canaryTaskGraph = useMemo(() => {
    if (canaryTasks && canaryTasks.length > 0) {
      const task = canaryTasks?.filter((v) => {
        return v.id === selectedCanaryTaskId
      })[0]
      if (task) {
        return <CanaryTaskGraph canaryTask={task} />
      }
    }
  }, [canaryTasks, selectedCanaryTaskId])

  if (!canaryTasks) {
    return <></>
  }
  return (
    <Card>
      <CardHeader
        title={canaryName}
        classes={{
          root: classes.cardHeaderRoot,
          title: classes.cardHeaderTitle
        }}
      />
      <CardContent classes={{ root: classes.cardContentRoot }}>
        <Typography
          align="center"
          gutterBottom
          classes={{ root: classes.cardContentTypography }}
        >
          {firstTask?.name!}
        </Typography>
        <CardActionArea>
          <Link
            to={RoutesService.canaryManage(canaryId)}
            hidden={hidden}
            style={{
              textDecoration: 'none',
              cursor: 'default'
            }}
          >
            <GraphContainer>{canaryTaskGraph}</GraphContainer>
          </Link>
        </CardActionArea>
      </CardContent>
    </Card>
  )
}
