import styled from 'styled-components'

export const ResetPasswordStyle = styled.div`
  .Background {
    position: fixed;
    left: 0;
    top: 0;
    background-color: #000000;
    opacity: 0.2;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .Spread {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    .Content {
      padding: 30px 50px 50px;
      background-color: white;
      .closeBtn {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
`
