import { ReactNode } from 'react'
import Box from '@mui/system/Box'

interface TabPanelProps {
  [x: string]: any
  children: ReactNode
  value: any
  index: any
}

export default function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3, pt: 1, px: 0 }}>{children}</Box>}
    </div>
  )
}
