import styled from 'styled-components'

export const CloudContainer = styled.div`
  margin: 1em;
  height: 60%;
  border-radius: 1em .percentLabel {
    padding-top: 2em;
    color: white;
  }
`
