import useIsMatchingMD from 'hooks/useIsMatchingMD'
import Skeleton from '@mui/material/Skeleton'
import SharedStyles from './SharedStyles'

interface LoaderProps {
  isCompliance?: boolean
}
export default function HealthAndComplianceDashboardsLoader(
  props: LoaderProps
) {
  const { isCompliance } = props
  const classes = SharedStyles()
  const isMatchingMD = useIsMatchingMD()

  return (
    <div className={classes.loadingSkeletons}>
      <div className={classes.centerRow}>
        <Skeleton
          variant="text"
          width="15%"
          height={55}
          className={classes.skeleton}
          animation="wave"
        />
      </div>
      <div className={classes.skeletonsRow}>
        {Array.from([1, 2]).map((element) => (
          <Skeleton
            key={element}
            variant="rectangular"
            width="13%"
            height={50}
            className={classes.skeleton}
            animation="wave"
          />
        ))}
      </div>
      <div className={classes.cardsContainer} style={{ marginTop: '2.25rem' }}>
        {Array.from(isMatchingMD ? [1] : [1, 2, 3]).map((element) => (
          <Skeleton
            key={element}
            variant="rectangular"
            width={380}
            height={isCompliance ? 615 : 500}
            className={classes.skeleton}
            classes={{
              root: `${classes.skeletonRoot} ${classes.card}`
            }}
          />
        ))}
      </div>
    </div>
  )
}
