import ArtifactsContextProvider from 'contexts/ArtifactsContext'
import ComplianceContextProvider from 'contexts/ComplianceContext'
import Compliance from 'pages/Dashboards/Compliance'

export default function DashboardCompliance() {
  return (
    <ComplianceContextProvider>
      <ArtifactsContextProvider>
        <Compliance />
      </ArtifactsContextProvider>
    </ComplianceContextProvider>
  )
}
