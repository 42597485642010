import { useContext, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import AbstractCanaryEditor from 'components/CanaryUpdator/AbstractCanaryEditor'
import Icon, { IconType } from 'components/Icons'
import DialogModal from 'components/Modals/DialogModal'
import { CanariesContext } from 'contexts/CanariesContext'

interface CanaryEditModalProps {
  canaryId?: string
}

export default function ModalEditCanary(props: CanaryEditModalProps) {
  const { canaryId } = props
  const { getCanaries } = useContext(CanariesContext)
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    getCanaries()
  }
  return (
    <>
      <IconButton
        aria-label="Edit Canary"
        title="Edit Canary"
        color="primary"
        size="small"
        onClick={handleClickOpen}
        style={{ marginRight: '0.5rem' }}
      >
        <Icon name={IconType.Edit} />
      </IconButton>
      <DialogModal fullScreen open={open} onClose={handleClose}>
        {canaryId && (
          <AbstractCanaryEditor canaryId={canaryId} onClose={handleClose} />
        )}
      </DialogModal>
    </>
  )
}
