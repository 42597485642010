export enum Quarters {
  Q1 = 'Q1',
  Q2 = 'Q2',
  Q3 = 'Q3',
  Q4 = 'Q4'
}

export interface StartEndDateReturnType {
  start: Date | null
  end: Date | null
}

const currentDate = new Date()
const currentMonth = currentDate.getMonth() + 1
const currentQuarter = Math.ceil(currentMonth / 3)
const currentYear = currentDate.getFullYear()

export const Years = [currentYear]
export function getQuartersPeriods() {
  let quartersPeriods = ['Q1', 'Q2', 'Q3', 'Q4']
  let QuartersPeriods = []
  for (let quarter = 1; quarter <= currentQuarter; quarter++) {
    const startingMonth = (quarter - 1) * 3 + 1
    if (startingMonth <= currentMonth) {
      QuartersPeriods.push(quartersPeriods[quarter - 1])
    }
  }
  return QuartersPeriods
}

export function getMonthsUntilCurrent(): string[] {
  const monthNames: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  return monthNames.slice(0, currentMonth)
}

export function getWeeksUntilCurrent(): number[] {
  const today = new Date()
  const currentYear = today.getFullYear()
  const currentMonth = today.getMonth()
  const currentDate = today.getDate()

  const firstDayOfMonth = new Date(currentYear, currentMonth, 1)
  const currentWeek = Math.ceil((currentDate + firstDayOfMonth.getDay()) / 7)
  const weeks: number[] = []
  for (let week = 1; week <= currentWeek; week++) {
    weeks.push(week)
  }

  return weeks
}
export function getMonthAbbreviation(month: number): string {
  const monthNames: string[] = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]
  return monthNames[month]
}

export declare type PeriodType = 'Year' | 'Quarter' | 'Month' | 'Week'
export declare type Period = string | number

export default class ArtifactReportsPeriodAndDatesService {
  private __type: PeriodType = 'Quarter'
  private __period: Period = Quarters.Q1

  constructor(type: PeriodType, period: Period) {
    this.__type = type
    this.__period = period
  }

  public get type(): PeriodType {
    return this.__type
  }

  public set type(type: PeriodType) {
    this.__type = type
  }

  public get period(): Period {
    return this.__period
  }

  public set period(period: Period) {
    this.__period = period
  }

  // Returns the period selection schema enums based on the selected type of the Period
  getPeriodBasedOnPeriodType() {
    let period: number[] | string[] = getQuartersPeriods()
    switch (this.type) {
      case 'Year':
        period = Years
        break
      case 'Month':
        period = getMonthsUntilCurrent()
        break
      case 'Week':
        period = getWeeksUntilCurrent()
        break
      default:
        period = getQuartersPeriods()
        break
    }
    return period
  }

  // Return the artifact form period data based on the selected type of the Period
  getPeriodDataBasedOnPeriodType() {
    let periodData: number | string = getQuartersPeriods()[0]
    switch (this.type) {
      case 'Year':
        periodData = Years[0]
        break
      case 'Month':
        periodData = getMonthsUntilCurrent()[0]
        break
      case 'Week':
        periodData = getWeeksUntilCurrent()[0]
        break
      case 'Quarter':
        periodData = getWeeksUntilCurrent()[0]
        break
      default:
        periodData = getQuartersPeriods()[0]
        break
    }
    return periodData
  }

  getQuarterStartEndDates(): StartEndDateReturnType {
    const today = new Date()
    const year = today.getFullYear()
    const quarters = []

    for (let quarter = 1; quarter <= 4; quarter++) {
      const quarterStart = new Date(year, (quarter - 1) * 3, 1)
      const quarterEnd = new Date(year, quarter * 3, 0)
      quarters.push({ start: quarterStart, end: quarterEnd })
    }

    let dates: StartEndDateReturnType = { start: null, end: null }

    switch (this.period) {
      case Quarters.Q1:
        dates = quarters[0]
        break
      case Quarters.Q2:
        dates = quarters[1]
        break
      case Quarters.Q3:
        dates = quarters[2]
        break
      case Quarters.Q4:
        dates = quarters[3]
        break
    }
    return dates
  }

  getYearStartAndEndDate(): StartEndDateReturnType {
    const yearStartDate = new Date(currentYear, 0, 1)
    const yearEndDate = new Date(currentYear, 11, 31)
    return { start: yearStartDate, end: yearEndDate }
  }

  getMonthStartEndDates(): StartEndDateReturnType {
    const monthIndex = new Date(`${this.period} 1, ${currentYear}`).getMonth()
    const monthStartDate = new Date(currentYear, monthIndex, 1)
    const nextMonthStartDate = new Date(currentYear, monthIndex + 1, 1)
    const monthEndDate = new Date(nextMonthStartDate.getTime() - 1)
    return { start: monthStartDate, end: monthEndDate }
  }

  getWeekStartEndDates(): StartEndDateReturnType {
    const weekNumber = Number(this.period)
    const firstDayOfMonth = new Date(currentYear, currentMonth - 1, 1)
    const firstDayOfWeek = new Date(
      firstDayOfMonth.getTime() +
        ((weekNumber - 1) * 7 - firstDayOfMonth.getDay() + 1) *
          24 *
          60 *
          60 *
          1000
    )
    const lastDayOfWeek: Date = new Date(
      firstDayOfWeek.getTime() + 6 * 24 * 60 * 60 * 1000
    )
    return { start: firstDayOfWeek, end: lastDayOfWeek }
  }

  getStartAndEndDateOfSelectedPeriod(): StartEndDateReturnType {
    let dates: StartEndDateReturnType = { start: null, end: null }
    switch (this.type) {
      case 'Year':
        dates = this.getYearStartAndEndDate()
        break
      case 'Quarter':
        dates = this.getQuarterStartEndDates()
        break
      case 'Month':
        dates = this.getMonthStartEndDates()
        break
      case 'Week':
        dates = this.getWeekStartEndDates()
        break
      default:
        dates = this.getQuarterStartEndDates()
        break
    }
    return dates
  }

  checkIfSelectedPeriodEnded(): boolean {
    const { end } = this.getStartAndEndDateOfSelectedPeriod()
    if (end!.setHours(0, 0, 0, 0) <= currentDate.setHours(0, 0, 0, 0)) {
      return true
    }
    return false
  }

  getRemainingDaysInPeriod(): number {
    let remainingDays = 0
    if (!this.checkIfSelectedPeriodEnded()) {
      const { end } = this.getStartAndEndDateOfSelectedPeriod()
      const timeDiff = end!.getTime() - currentDate.getTime()
      remainingDays = Math.floor(timeDiff / (1000 * 60 * 60 * 24))
    }
    return remainingDays
  }

  getMappedPeriodCategoryFromSelectedType():
    | 'yearly'
    | 'quarterly'
    | 'monthly'
    | 'weekly' {
    switch (this.type) {
      case 'Year':
        return 'yearly'
      case 'Quarter':
        return 'quarterly'
      case 'Month':
        return 'monthly'
      case 'Week':
        return 'weekly'
    }
  }
}
