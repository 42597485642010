import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function LockIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="24px"
        height="24px"
        className="MuiSvgIcon-root"
        viewBox="0 0 24 24"
        {...props}
      >
        <g id="surface1">
          <path
            style={{
              stroke: 'none',
              fillRule: 'nonzero',
              fill: 'rgb(0%,0%,0%)',
              fillOpacity: 1
            }}
            d="M 10.820312 16.609375 L 10.503906 19.445312 C 10.488281 19.585938 10.53125 19.726562 10.628906 19.832031 C 10.722656 19.9375 10.859375 20 11 20 L 13 20 C 13.140625 20 13.277344 19.9375 13.371094 19.832031 C 13.46875 19.726562 13.511719 19.585938 13.496094 19.445312 L 13.183594 16.609375 C 13.695312 16.234375 14 15.648438 14 15 C 14 13.898438 13.101562 13 12 13 C 10.898438 13 10 13.898438 10 15 C 10 15.648438 10.304688 16.234375 10.820312 16.609375 Z M 12 14 C 12.550781 14 13 14.449219 13 15 C 13 15.382812 12.78125 15.726562 12.429688 15.898438 C 12.242188 15.988281 12.128906 16.191406 12.152344 16.402344 L 12.441406 19 L 11.558594 19 L 11.847656 16.402344 C 11.871094 16.191406 11.757812 15.988281 11.570312 15.898438 C 11.21875 15.726562 11 15.382812 11 15 C 11 14.449219 11.449219 14 12 14 Z M 12 14 "
          />
          <path
            style={{
              stroke: 'none',
              fillRule: 'nonzero',
              fill: 'rgb(0%,0%,0%)',
              fillOpacity: 1
            }}
            d="M 20.5 9 L 19 9 L 19 7 C 19 3.140625 15.859375 0 12 0 C 8.140625 0 5 3.140625 5 7 L 5 9 L 3.5 9 C 3.222656 9 3 9.222656 3 9.5 L 3 22 C 3 23.101562 3.898438 24 5 24 L 19 24 C 20.101562 24 21 23.101562 21 22 L 21 9.5 C 21 9.222656 20.777344 9 20.5 9 Z M 6 7 C 6 3.691406 8.691406 1 12 1 C 15.308594 1 18 3.691406 18 7 L 18 9 L 17 9 L 17 7 C 17 4.242188 14.757812 2 12 2 C 9.242188 2 7 4.242188 7 7 L 7 9 L 6 9 Z M 16 7 L 16 9 L 8 9 L 8 7 C 8 4.792969 9.792969 3 12 3 C 14.207031 3 16 4.792969 16 7 Z M 20 22 C 20 22.550781 19.550781 23 19 23 L 5 23 C 4.449219 23 4 22.550781 4 22 L 4 10 L 20 10 Z M 20 22 "
          />
        </g>
      </svg>
    </SvgIcon>
  )
}
