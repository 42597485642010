import {
  ChangeEvent,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { OpenAPIRestfulLibrary } from 'lib/CloudCanariesRestfulAPI'
import { APIServiceContext } from './APIServiceContext'
import { OrganizationsContext } from './OrganizationsContext'

interface OpenAPIRestfulLibrariesContextProviderProps {
  children: ReactNode
}

interface OpenAPIRestfulLibrariesContextProps {
  areOpenAPIRestfulLibrariesLoading: boolean
  openAPIRestfulLibraries: OpenAPIRestfulLibrary[]
  rowsPerPage: number
  page: number
  rowCount: number
  createOpenAPIRestfulLibraryOpen: boolean
  viewOpenAPIRestfulLibraryeOpen: boolean
  schemaId: string
  setSchemaId: (id: string) => void
  fetchOpenAPIRestfulLibraries: () => Promise<void>
  openCloseCreateOpenAPIRestfulLibrary: (
    open: boolean,
    schemaId: string,
    reload?: boolean
  ) => void
  openCloseViewOpenAPIRestfulLibrary: (
    open: boolean,
    schemaId: string,
    reload?: boolean
  ) => void
  onRowsPerPageChange: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void
  handleChangePage: (event: any, newPage: number) => void
}
export const OpenAPIRestfulLibrariesContext =
  createContext<OpenAPIRestfulLibrariesContextProps>(null as any)

export default function OpenAPIRestfulLibrariesContextProvider(
  props: OpenAPIRestfulLibrariesContextProviderProps
) {
  const { children } = props

  const { organizationId } = useContext(OrganizationsContext)

  const { apiService } = useContext(APIServiceContext)

  const [page, setPage] = useState<number>(0)
  const [rowCount, setRowCount] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)

  const [
    areOpenAPIRestfulLibrariesLoading,
    setAreOpenAPIRestfulLibrariesLoading
  ] = useState<boolean>(true)
  const [openAPIRestfulLibraries, setOpenAPIRestfulLibraries] = useState<
    OpenAPIRestfulLibrary[]
  >([])
  const [createOpenAPIRestfulLibraryOpen, setCreateOpenAPIRestfulLibraryOpen] =
    useState<boolean>(false)
  const [viewOpenAPIRestfulLibraryeOpen, setViewOpenAPIRestfulLibraryeOpen] =
    useState<boolean>(false)
  const [schemaId, setSchemaId] = useState<string>('')

  useEffect(() => {
    setRowCount(0)
  }, [organizationId])

  const fetchOpenAPIRestfulLibraries = useCallback(async () => {
    setRowCount(0)
    const requestedPage = page + 1

    setAreOpenAPIRestfulLibrariesLoading(true)
    await apiService
      .listOpenAPIRestfulLibraries(requestedPage, rowsPerPage, organizationId)
      .then((json) => {
        setOpenAPIRestfulLibraries(json.data.results ?? [])
        setRowCount(json.data.count ?? 0)
        setAreOpenAPIRestfulLibrariesLoading(false)
      })
  }, [apiService, organizationId, page, rowsPerPage])

  useEffect(() => {
    fetchOpenAPIRestfulLibraries()
  }, [fetchOpenAPIRestfulLibraries])

  async function openCloseCreateOpenAPIRestfulLibrary(
    open: boolean,
    schemaId: string,
    reload?: boolean
  ) {
    setCreateOpenAPIRestfulLibraryOpen(open)
    setSchemaId(schemaId)
    if (reload) {
      await fetchOpenAPIRestfulLibraries()
    }
  }

  async function openCloseViewOpenAPIRestfulLibrary(
    open: boolean,
    schemaId: string,
    reload?: boolean
  ) {
    setViewOpenAPIRestfulLibraryeOpen(open)
    setSchemaId(schemaId)
    if (reload) {
      await fetchOpenAPIRestfulLibraries()
    }
  }

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage)
  }

  const onRowsPerPageChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <OpenAPIRestfulLibrariesContext.Provider
      value={{
        areOpenAPIRestfulLibrariesLoading,
        openAPIRestfulLibraries,
        schemaId,
        createOpenAPIRestfulLibraryOpen,
        viewOpenAPIRestfulLibraryeOpen,
        page,
        rowCount,
        rowsPerPage,
        setSchemaId,
        fetchOpenAPIRestfulLibraries,
        openCloseCreateOpenAPIRestfulLibrary,
        openCloseViewOpenAPIRestfulLibrary,
        handleChangePage,
        onRowsPerPageChange
      }}
    >
      {children}
    </OpenAPIRestfulLibrariesContext.Provider>
  )
}
