import OpenAPIRestfulLibrariesContextProvider from 'contexts/OpenAPIRestfulLibrariesContext'
import CustomRestfulSDKList from 'pages/AdminPages/CustomRestfulSDK/CustomRestfulSDKList'

export default function CustomRestfulSDKPage() {
  return (
    <OpenAPIRestfulLibrariesContextProvider>
      <CustomRestfulSDKList />
    </OpenAPIRestfulLibrariesContextProvider>
  )
}
