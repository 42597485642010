import useDocumentTitle from 'hooks/useDocumentTitle'
import { useContext } from 'react'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import APIKeysCreateModal from 'components/APIKeys/APIKeysCreateModal'
import DeactivateModal from 'components/APIKeys/DeactivateModal'
import DeleteModal from 'components/APIKeys/DeleteModal'
import { AdminPagesTitles } from 'components/MyAdminPages/Constants'
import MyAdminPagesCommonHeader from 'components/MyAdminPages/MyAdminPagesCommonHeader'
import NoDataFound from 'components/MyAdminPages/NoDataFound'
import PagesSkeletonLoader from 'components/common/Loaders/PagesSkeletonLoader'
import { rowsPerPageOptions } from 'constants/AppConfig'
import { APIKeysContext } from 'contexts/APIKeysContext'
import adminPagesSharedStyles from 'pages/AdminPages/adminPagesSharedStyles'

export default function APIKeysList() {
  useDocumentTitle('API Keys List')
  const classes = adminPagesSharedStyles()

  const {
    areApiKeysLoading,
    apiKeys,
    page,
    rowCount,
    rowsPerPage,
    onRowsPerPageChange,
    handleChangePage
  } = useContext(APIKeysContext)

  return (
    <Container maxWidth="xl" className={classes.mainContainer}>
      <MyAdminPagesCommonHeader />
      <h1 className={classes.headerRow}>
        {AdminPagesTitles.APIKeys}
        <APIKeysCreateModal />
      </h1>
      {areApiKeysLoading ? (
        <PagesSkeletonLoader />
      ) : (
        <>
          <TableContainer component={Paper} elevation={3}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>API Key</TableCell>
                  <TableCell>Organization</TableCell>
                  <TableCell>Creation Date</TableCell>
                  <TableCell align="center" className={classes.lastCell}>
                    Revoke
                  </TableCell>
                  <TableCell align="center" className={classes.lastCell}>
                    Delete
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {apiKeys.length > 0 ? (
                  apiKeys.map((apiKey) => {
                    const apiKeyName = apiKey.name!
                    return (
                      <TableRow key={apiKeyName}>
                        <TableCell>{apiKey.prefix}</TableCell>
                        <TableCell>{apiKey.organization_name}</TableCell>
                        <TableCell>
                          {new Date(apiKey.created!).toLocaleString()}
                        </TableCell>
                        <TableCell align="center" className={classes.lastCell}>
                          <DeactivateModal apiKey={apiKey} />
                        </TableCell>
                        <TableCell align="center" className={classes.lastCell}>
                          <DeleteModal apiKey={apiKey} />
                        </TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <NoDataFound pageName="API Keys" colSpan={5} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={rowCount}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </>
      )}
    </Container>
  )
}
