import { toast } from 'react-toastify'
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { ToastErrors } from 'constants/toast_errors'
import { APIServiceContextProps } from 'contexts/APIServiceContext'
import { AppDispatch, RootState } from '../store'

export const retrieveAuthUserProfile = createAsyncThunk<
  any,
  void,
  {
    state: RootState
    dispatch: AppDispatch
    extra: APIServiceContextProps
  }
>('auth/userRetrieveProfile', async (_, { extra, rejectWithValue }) => {
  const { apiService } = extra
  try {
    const response = await apiService.userProfileAuthViewSet()
    if (response.status === 200) {
      return response.data
    } else {
      toast.error(ToastErrors.ERROR_RETRIEVING_AUTH_PROFILE, {
        position: 'top-right'
      })
      return rejectWithValue(ToastErrors.ERROR_RETRIEVING_AUTH_PROFILE)
    }
  } catch (e) {
    toast.error(ToastErrors.ERROR_RETRIEVING_AUTH_PROFILE, {
      position: 'top-right'
    })
    return rejectWithValue(ToastErrors.ERROR_RETRIEVING_AUTH_PROFILE)
  }
})

export const userLogout = createAction('auth/logout')
export const userImpersonateSetup = createAction('auth/userImpersonateSetup')
