import { useContext, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Button from '@mui/material/Button'
import Skeleton from '@mui/material/Skeleton'
import Icon, { IconType } from 'components/Icons'
import { ComplianceHistoryContext } from 'contexts/ComplianceHistoryContext'
import RoutesService from 'services/routes'
import { getCurrentDate, isDateBetween } from 'services/utils'

export default function ComplianceHistoryNavButton() {
  const location = useLocation()
  const history = useHistory()

  const { areComplianceHistoriesLoading, filteredComplianceHistories } =
    useContext(ComplianceHistoryContext)

  const [currentPath, setCurrentPath] = useState<string>('')
  const [previousPath, setPreviousPath] = useState<string>('')

  /**
   * We use this useEffect to maintain the current and previous paths
   * so we can get the previous path in order to pass it to Link component.
   */
  useEffect(() => {
    if (location.pathname !== currentPath) {
      setPreviousPath(currentPath)
      setCurrentPath(location.pathname)
    }
  }, [currentPath, location.pathname])

  /**
   * We use this useMemo to detect changes in current and previous paths
   * and set the link that should be next for Link component.
   */
  const complianceHistoryPageToLink = useMemo<string>(() => {
    const complianceHistoryLink = RoutesService.complianceHistory()
    if (currentPath === complianceHistoryLink) {
      return previousPath
    } else {
      return complianceHistoryLink
    }
  }, [currentPath, previousPath])

  const isSLACompliantOrNot = useMemo(() => {
    const currentDate = getCurrentDate()
    const currentCompliances = filteredComplianceHistories?.filter((history) =>
      isDateBetween(currentDate, history.period_from!, history.period_to!)
    )
    if (currentCompliances && currentCompliances.length > 0) {
      if (
        currentCompliances.some(
          (history) =>
            history.sla_seconds_percentage! < history.sla_target_goal!
        )
      ) {
        return false
      } else return true
    }
    return true
  }, [filteredComplianceHistories])

  const isIncidentsPageActive = useMemo(() => {
    const dashboardIncidentsLink = RoutesService.dashboardIncidents()
    return currentPath === dashboardIncidentsLink
  }, [currentPath])

  return areComplianceHistoriesLoading ? (
    <Skeleton
      animation="wave"
      variant="rectangular"
      width={126}
      height={34}
      sx={{
        borderRadius: 1,
        ml: 1
      }}
    />
  ) : (
    <Button
      disabled={isIncidentsPageActive}
      onClick={() => {
        history.push(complianceHistoryPageToLink)
      }}
      sx={{
        backgroundColor: areComplianceHistoriesLoading
          ? 'rgba(0, 0, 0, 0.08) !important'
          : isSLACompliantOrNot
            ? '#81c784cc !important'
            : '#ff9800cc !important',
        color: isSLACompliantOrNot ? '#000000' : '#FFFFFF',
        '&:hover': {
          border: 0,
          backgroundColor: areComplianceHistoriesLoading
            ? 'rgba(0, 0, 0, 0.08) !important'
            : isSLACompliantOrNot
              ? '#81c78480 !important'
              : '#ff980080 !important'
        },
        textTransform: 'capitalize',
        fontSize: '1rem',
        lineHeight: '1.1876em',
        height: 32,
        padding: areComplianceHistoriesLoading ? 0 : 1,
        ml: 1,
        borderRadius: 1,
        alignItems: 'center',
        boxShadow: 2,
        minWidth: 64,
        border: 0
      }}
    >
      <Icon
        name={IconType.History}
        fontSize="medium"
        color="inherit"
        style={{ marginRight: '0.5rem' }}
      />
      SLA Results
    </Button>
  )
}
