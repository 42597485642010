import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { userLogout } from './auth'
import type { RootState } from '../store'

export interface ServiceHealthState {
  selectedServiceHealthId: string
  selectedServiceHealthName: string
}

const initialState: ServiceHealthState = {
  selectedServiceHealthId: '',
  selectedServiceHealthName: ''
}

export const serviceHealthSlice = createSlice({
  name: 'serviceHealth',
  initialState,
  reducers: {
    setSelectedServiceHealthID: (state, action: PayloadAction<string>) => {
      state.selectedServiceHealthId = action.payload
    },
    setSelectedServiceHealthNAME: (state, action: PayloadAction<string>) => {
      state.selectedServiceHealthName = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(userLogout, (state, action) => {
      state.selectedServiceHealthId = ''
      state.selectedServiceHealthName = ''
    })
  }
})

// Action creators are generated for each case reducer function
export const { setSelectedServiceHealthID, setSelectedServiceHealthNAME } =
  serviceHealthSlice.actions

export default serviceHealthSlice.reducer

export const selectSelectedServiceHealthId = (state: RootState) =>
  state.serviceHealth.selectedServiceHealthId

export const selectSelectedServiceHealthName = (state: RootState) =>
  state.serviceHealth.selectedServiceHealthName
