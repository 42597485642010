import { useCallback, useContext, useEffect, useState } from 'react'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Box from '@mui/system/Box'
import PerAlarmAlarmEvaluationsTabPanel from 'components/MetricNotifications/PerAlarmAlarmEvaluationsTabPanel'
import PerAlarmAlarmHistoryTabPanel from 'components/MetricNotifications/PerAlarmAlarmHistoryTabPanel'
import PerAlarmAlarmMetricDataTabPanel from 'components/MetricNotifications/PerAlarmAlarmMetricDataTabPanel'
import PerAlarmNotificationHistoryTabPanel from 'components/MetricNotifications/PerAlarmNotificationHistoryTabPanel'
import PerAlarmNotificationsConfiguredTabPanel from 'components/MetricNotifications/PerAlarmNotificationsConfiguredTabPanel'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { SimpleAlarm } from 'lib/CloudCanariesRestfulAPI'
import { a11yProps } from 'services/utils'

const defaultSimpleAlarm = {
  metric_id: '',
  canary_id: '',
  task_id: ''
} as SimpleAlarm

interface MetricAlarmManagerProps {
  alarmId: string
}

export default function MetricAlarmManager(props: MetricAlarmManagerProps) {
  const { alarmId } = props

  const { apiService } = useContext(APIServiceContext)

  const [value, setValue] = useState(0)
  const [alarmMeta, setAlarmMeta] = useState<SimpleAlarm>(defaultSimpleAlarm)

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue)
  }

  const getAlarmMeta = useCallback(async () => {
    if (alarmId) {
      await apiService.retrieveSimpleAlarm(alarmId).then((json) => {
        setAlarmMeta(json.data)
      })
    }
  }, [alarmId, apiService])

  useEffect(() => {
    getAlarmMeta()
  }, [getAlarmMeta])

  return (
    alarmMeta && (
      <Box sx={{ width: '100%', marginTop: '1em' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Metric Alarms"
            variant="fullWidth"
            centered
            textColor="inherit"
          >
            <Tab
              label="Notifications"
              {...a11yProps(0)}
              sx={{ textTransform: 'capitalize' }}
            />
            <Tab
              label="Notification History"
              {...a11yProps(1)}
              sx={{ textTransform: 'capitalize' }}
            />
            <Tab
              label="Alarm Evaluations"
              {...a11yProps(2)}
              sx={{ textTransform: 'capitalize' }}
            />
            <Tab
              label="Alarm History"
              {...a11yProps(3)}
              sx={{ textTransform: 'capitalize' }}
            />
            <Tab
              label="Metric Data"
              {...a11yProps(4)}
              sx={{ textTransform: 'capitalize' }}
            />
          </Tabs>
        </Box>
        <PerAlarmNotificationsConfiguredTabPanel
          alarmId={alarmId}
          selectedTab={value}
          tabIndex={0}
        />
        <PerAlarmNotificationHistoryTabPanel
          alarmId={alarmId}
          selectedTab={value}
          tabIndex={1}
        />
        <PerAlarmAlarmEvaluationsTabPanel
          alarmId={alarmId}
          selectedTab={value}
          tabIndex={2}
        />
        <PerAlarmAlarmHistoryTabPanel
          alarmId={alarmId}
          selectedTab={value}
          tabIndex={3}
        />
        <PerAlarmAlarmMetricDataTabPanel
          alarmId={alarmId}
          metricId={alarmMeta.metric_id!}
          selectedTab={value}
          tabIndex={4}
        />
      </Box>
    )
  )
}
