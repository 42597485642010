import { useContext, useEffect, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/system/Box'
import { IChangeEvent } from '@rjsf/core'
import MuiForm from '@rjsf/mui'
import { ErrorSchema, ErrorSchemaBuilder, RJSFSchema } from '@rjsf/utils'
import validator from '@rjsf/validator-ajv8'
import { AxiosError, isAxiosError } from 'axios'
import Icon, { IconType } from 'components/Icons'
import DialogModal from 'components/Modals/DialogModal'
import FormsUpdateCancelButtons from 'components/common/FormsUpdateCancelButtons'
import { configCreateEditNotification } from 'constants/formconfig/CreateEditNotification'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { Notification } from 'lib/CloudCanariesRestfulAPI'
import useStyles from './styles'

interface EditNotificationDialogProps {
  alarmId: string
  onClose: () => void
  notification: Notification
}

export default function EditNotificationDialog(
  props: EditNotificationDialogProps
) {
  const classes = useStyles()
  const { alarmId, onClose, notification } = props

  const { apiService } = useContext(APIServiceContext)

  const schema = configCreateEditNotification.schema
  const [formData, setFormData] = useState(notification)
  const [open, setOpen] = useState(false)
  const [extraErrors, setExtraErrors] = useState<ErrorSchema>({})

  useEffect(() => {
    const newFormData: any = {
      ...notification,
      channel_and_from_to_status: {
        channels: notification.channels,
        transition_from: notification.transition_from,
        transition_to: notification.transition_to
      },
      urgency_impact_priority: {
        urgency: notification.urgency,
        impact: notification.impact,
        priority: notification.priority
      },
      summary_description: {
        summary: notification.summary || '',
        description: notification.description || ''
      }
    }
    setFormData(newFormData)
  }, [notification])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  function handleModalClose() {
    setExtraErrors({})
    handleClose()
    onClose()
  }

  const onSubmit = async (
    data: IChangeEvent<any, RJSFSchema, any>,
    event: React.FormEvent<any>
  ) => {
    let requestBody = JSON.parse(JSON.stringify(data.formData))

    requestBody.alarm = alarmId
    requestBody.channels = requestBody.channel_and_from_to_status.channels
    requestBody.transition_from =
      requestBody.channel_and_from_to_status.transition_from
    requestBody.transition_to =
      requestBody.channel_and_from_to_status.transition_to
    requestBody.urgency = requestBody.urgency_impact_priority.urgency
    requestBody.impact = requestBody.urgency_impact_priority.impact
    requestBody.priority = requestBody.urgency_impact_priority.priority
    requestBody.summary = requestBody.summary_description.summary
    requestBody.description = requestBody.summary_description.description
    try {
      await apiService
        .updateNotification(notification.id!, alarmId, requestBody)
        .then((json) => {
          if (json.status === 200) {
            handleModalClose()
          }
        })
    } catch (e: any) {
      if (isAxiosError(e)) {
        const err = e as AxiosError
        if (err.response?.status === 400) {
          const errors = err.response?.data as {}
          const builder = new ErrorSchemaBuilder()
          Object.entries(errors).forEach(([key, value]) => {
            builder.addErrors(value as string, key)
          })
          setExtraErrors(builder.ErrorSchema)
        }
      }
    }
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <IconButton
        onClick={handleClickOpen}
        color="primary"
        size="small"
        style={{ marginRight: '0.5rem' }}
      >
        <Icon name={IconType.Edit} />
      </IconButton>
      {notification.is_system_notification ? (
        <Icon name={IconType.Analytics} style={{ marginRight: 4 }} />
      ) : null}
      {notification.name}
      <DialogModal
        onClose={handleClose}
        open={open}
        fullWidth
        dialogTitle="Update Notification"
        dialogContent={
          <MuiForm
            schema={schema}
            onSubmit={onSubmit}
            formData={formData}
            extraErrors={extraErrors}
            uiSchema={configCreateEditNotification.uiSchema}
            className={classes.muiForm}
            validator={validator}
          >
            <FormsUpdateCancelButtons cancelButtonOnClick={handleClose} />
          </MuiForm>
        }
      />
    </Box>
  )
}
