import {
  setOrganizationRequiredModalMessage,
  setOrganizationRequiredModalOpen
} from 'client_side_state/slices/app'
import { useAppDispatch } from 'client_side_state/store'
import { useContext, useState } from 'react'
import Button from '@mui/material/Button'
import Icon, { IconType } from 'components/Icons'
import DialogModal from 'components/Modals/DialogModal'
import OrganizationRequiredDialog, {
  OrganizationRequiredMessage
} from 'components/UserOrganization/OrganizationRequiredDialog'
import { CanariesContext } from 'contexts/CanariesContext'
import { OrganizationsContext } from 'contexts/OrganizationsContext'
import CanaryCreator from './CanaryCreator'

export default function CanaryCreateModal() {
  const dispatch = useAppDispatch()

  const { organizationId } = useContext(OrganizationsContext)
  const { getCanaries } = useContext(CanariesContext)

  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    if (!organizationId) {
      dispatch(
        setOrganizationRequiredModalMessage(
          OrganizationRequiredMessage.CanaryCreate
        )
      )
      dispatch(setOrganizationRequiredModalOpen(true))
    } else {
      setOpen(true)
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleCreate = async () => {
    setOpen(false)
    await getCanaries()
  }

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen} color={'primary'}>
        <Icon name={IconType.Add} /> Create
      </Button>
      {organizationId ? (
        <DialogModal fullWidth maxWidth="sm" open={open} onClose={handleClose}>
          <CanaryCreator onClose={handleCreate} />
        </DialogModal>
      ) : (
        <OrganizationRequiredDialog />
      )}
    </div>
  )
}
