import {
  selectRefreshInterval,
  setRefreshInterval
} from 'client_side_state/slices/pageFilter'
import { useAppDispatch, useAppSelector } from 'client_side_state/store'
import { MouseEvent, useRef, useState } from 'react'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import { makeStyles } from '@mui/styles'
import Icon, { IconType } from 'components/Icons'

const useStyles = makeStyles({
  icon: {
    width: '24px',
    height: '24px'
  },
  refreshBtn: {
    border: '1px solid',
    marginLeft: 8,
    paddingTop: 7,
    paddingBottom: 7,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4
  }
})

const INTERVALS = [1, 5, 30, 60]

interface RefreshButtonProps {
  onClick: () => void
}

export default function RefreshButton(props: RefreshButtonProps) {
  const { onClick } = props
  const dispatch = useAppDispatch()
  const interval = useAppSelector(selectRefreshInterval)
  const changeInterval = (e: number) => {
    dispatch(setRefreshInterval(e))
  }
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLDivElement>(null)
  const options = INTERVALS
  const [selectedIndex, setSelectedIndex] = useState(options.indexOf(interval))

  const handleClick = () => {
    onClick()
  }

  const handleMenuItemClick = (
    event: MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    changeInterval(options[index])
    setSelectedIndex(index)
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: any) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }
  const classes = useStyles()
  return (
    <>
      <ButtonGroup
        variant={'outlined'}
        ref={anchorRef}
        aria-label="split button"
      >
        <Button className={classes.refreshBtn} onClick={handleClick}>
          {options[selectedIndex]} Min
        </Button>
        <Button
          size="small"
          style={{
            marginRight: 8,
            lineHeight: 1,
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4
          }}
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select refresh interval"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <Icon
            name={IconType.Refresh}
            fontSize="small"
            className={classes.icon}
          />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event: any) =>
                        handleMenuItemClick(event, index)
                      }
                    >
                      {option} Min
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}
