import {
  setOrganizationRequiredModalMessage,
  setOrganizationRequiredModalOpen
} from 'client_side_state/slices/app'
import { useAppDispatch } from 'client_side_state/store'
import { useContext, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { IChangeEvent } from '@rjsf/core'
import MuiForm from '@rjsf/mui'
import {
  ErrorSchema,
  ErrorSchemaBuilder,
  RJSFSchema,
  UiSchema
} from '@rjsf/utils'
import validator from '@rjsf/validator-ajv8'
import { AxiosError, isAxiosError } from 'axios'
import Icon, { IconType } from 'components/Icons'
import DialogModal from 'components/Modals/DialogModal'
import OrganizationRequiredDialog, {
  OrganizationRequiredMessage
} from 'components/UserOrganization/OrganizationRequiredDialog'
import FormsCreateButton from 'components/common/FormsCreateButton'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { OrganizationsContext } from 'contexts/OrganizationsContext'
import { ServicesContext } from 'contexts/ServicesContext'
import { TemplateLibrariesContext } from 'contexts/TemplateLibrariesContext'
import { CreateBtn } from 'styles/components/common/Buttons.styled'
import { initialFormData } from './FormData'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  muiForm: {
    '& label.MuiFormLabel-root': {
      fontSize: '1.2rem'
    },
    '& span.MuiFormControlLabel-label ': {
      fontSize: '1.2rem'
    },
    '& input[type="file" i]': {
      fontSize: '1.2rem'
    }
  }
})

export default function CreateModal() {
  const classes = useStyles()

  const dispatch = useAppDispatch()

  const { apiService } = useContext(APIServiceContext)
  const { organizationId, organization } = useContext(OrganizationsContext)
  const { serviceProviders } = useContext(ServicesContext)
  const { getTemplateLibraries } = useContext(TemplateLibrariesContext)

  const [open, setOpen] = useState(false)
  const [extraErrors, setExtraErrors] = useState<ErrorSchema>({})
  const [formData, setFormData] = useState<any>(initialFormData)

  const handleClickOpen = () => {
    if (!organizationId) {
      dispatch(
        setOrganizationRequiredModalMessage(
          OrganizationRequiredMessage.TemplateLibraryCreate
        )
      )
      dispatch(setOrganizationRequiredModalOpen(true))
    } else {
      setOpen(true)
    }
  }

  const handleClose = () => {
    setExtraErrors({})
    setFormData({})
    setOpen(false)
  }

  const handleFormChange = (
    event: IChangeEvent<any, RJSFSchema, any>,
    id?: string | undefined
  ) => {
    let data = JSON.parse(JSON.stringify(event.formData))
    setFormData(data)
  }

  const onSubmit = async (
    data: IChangeEvent<any, RJSFSchema, any>,
    event: React.FormEvent<any>
  ) => {
    let requestBody = JSON.parse(JSON.stringify(data.formData))
    requestBody.organization_id = organizationId
    let isNewProvider = false

    // if the user selects existing generated provider, we pass it to the backend to choose it instead of creating a new one.
    if (requestBody.generated_provider_name !== 'New Provider') {
      const provider = serviceProviders?.filter(
        (provider) => provider.id === requestBody.generated_provider_name
      )
      if (provider) {
        requestBody.generated_provider_name = provider[0].id
      }
    } else {
      isNewProvider = true
      requestBody.generated_provider_name = requestBody.new_provider_name
    }
    try {
      await apiService
        .createCanaryTemplateLibrary(requestBody)
        .then(async (json) => {
          if (json.status === 201) {
            handleClose()
            await getTemplateLibraries()
          }
        })
    } catch (e: any) {
      if (isAxiosError(e)) {
        const err = e as AxiosError
        if (err.response?.status === 400) {
          const errors = err.response?.data as {}
          const builder = new ErrorSchemaBuilder()
          Object.entries(errors).forEach(([key, value]) => {
            if (isNewProvider && key === 'generated_provider_name') {
              builder.addErrors(value as string, 'new_provider_name')
            } else {
              builder.addErrors(value as string, key)
            }
          })
          setExtraErrors(builder.ErrorSchema)
        }
      }
    }
  }

  const serviceProvidersEnumNames: string[] | undefined = serviceProviders
    ?.concat({ name: 'New Provider' })
    ?.flatMap((o) => {
      return o.name ?? ''
    })
  const serviceProvidersEnumValues: string[] | undefined = serviceProviders
    ?.concat({ id: 'New Provider' })
    ?.flatMap((o) => {
      return o.id
    })
    .flatMap((f) => (f ? [f] : []))

  const schema = {
    type: 'object',
    properties: {
      generated_provider_name: {
        title: 'Provider Name',
        enum: serviceProvidersEnumValues,
        enumNames: serviceProvidersEnumNames,
        default: 'New Provider'
      },
      organization_id: { type: 'string' }
    },
    allOf: [
      {
        if: {
          properties: {
            generated_provider_name: {
              const: 'New Provider'
            }
          }
        },
        then: {
          properties: {
            new_provider_name: {
              type: 'string',
              title: 'New Provider'
            },
            generated_service_name: {
              type: 'string',
              title: 'Service Name'
            },
            uploaded_file: {
              type: 'string',
              format: 'data-url',
              title: 'Template File'
            }
          },
          required: [
            'generated_provider_name',
            'new_provider_name',
            'generated_service_name',
            'uploaded_file'
          ]
        }
      },
      {
        if: {
          properties: {
            generated_provider_name: {
              const: serviceProvidersEnumValues?.filter(
                (item) => item === formData.generated_provider_name
              )['0']
            }
          }
        },
        then: {
          properties: {
            generated_provider_name: {
              type: 'string',
              title: 'Provider Name',
              enum: serviceProvidersEnumValues,
              enumNames: serviceProvidersEnumNames
            },
            generated_service_name: {
              type: 'string',
              title: 'Service Name'
            },
            uploaded_file: {
              type: 'string',
              format: 'data-url',
              title: 'Template File'
            }
          },
          required: [
            'generated_provider_name',
            'generated_service_name',
            'uploaded_file'
          ]
        }
      },
      {
        required: ['generated_provider_name']
      }
    ]
  } as RJSFSchema

  const uiSchema: UiSchema = {
    organization_id: { 'ui:widget': 'hidden' },
    uploaded_file: {
      'ui:options': { accept: '.zip' },
      'ui:help': 'Please use .ZIP files only.'
    }
  }

  return (
    <div>
      <CreateBtn style={{ marginLeft: 8 }} onClick={handleClickOpen}>
        <Icon name={IconType.Add} /> Create
      </CreateBtn>
      {organization && organization.id ? (
        <DialogModal
          open={open}
          onClose={handleClose}
          dialogTitle="Upload Canary Template Library"
          dialogContent={
            <MuiForm
              schema={schema}
              onSubmit={onSubmit}
              formData={formData}
              onChange={handleFormChange}
              uiSchema={uiSchema}
              extraErrors={extraErrors}
              className={classes.muiForm}
              validator={validator}
            >
              <FormsCreateButton>Upload</FormsCreateButton>
            </MuiForm>
          }
        />
      ) : (
        <OrganizationRequiredDialog />
      )}
    </div>
  )
}
