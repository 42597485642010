import { CanaryDailyForcastManageChart } from '.'
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import Box from '@mui/system/Box'
import { CanaryForecastGraphMetrics } from 'constants/CanaryForecastGraphMetrics'
import { formatDateShort } from 'services/utils'

interface ForecastCanaryDailyForecastManageChartProps {
  canaryDailyForecast: CanaryDailyForcastManageChart
  selectedCanaryDailyForecastChart: CanaryForecastGraphMetrics
}

export default function ForecastCanaryDailyForecastManageChart(
  props: ForecastCanaryDailyForecastManageChartProps
) {
  const { canaryDailyForecast, selectedCanaryDailyForecastChart } = props

  if (
    selectedCanaryDailyForecastChart !==
      CanaryForecastGraphMetrics.INICDENT_AND_LATENCY &&
    selectedCanaryDailyForecastChart !==
      CanaryForecastGraphMetrics.LATENCY_ISSUES_PREDICTED &&
    selectedCanaryDailyForecastChart !==
      CanaryForecastGraphMetrics.PERCENTAGE_CHANCE_OF_INCIDENT
  ) {
    return <></>
  }

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
  )

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      x: {
        ticks: {
          font: {
            size: 20
          }
        }
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'Percentage',
          font: {
            size: 20
          }
        },
        ticks: {
          font: {
            size: 20
          }
        }
      }
    },
    plugins: {
      legend: {
        position: 'top' as const,
        labels: {
          font: {
            size: 20
          }
        }
      },
      interaction: {
        intersect: true
      }
    }
  }

  const labels = canaryDailyForecast.for_date?.map((date) =>
    formatDateShort(date)
  )

  let datasets = []
  if (
    selectedCanaryDailyForecastChart ===
    CanaryForecastGraphMetrics.INICDENT_AND_LATENCY
  ) {
    datasets.push(
      {
        label: 'Incident',
        data: canaryDailyForecast.percentage_chance_of_incident,
        borderColor: '#e0a173',
        backgroundColor: '#e0a17380',
        tension: 0.5,
        minBarLength: 4
      },
      {
        label: 'Latency',
        data: canaryDailyForecast.latency_issues_predicted,
        borderColor: 'rgb(44, 79, 133)',
        backgroundColor: 'rgb(44, 79, 133, 0.5)',
        tension: 0.5,
        minBarLength: 4
      }
    )
  } else if (
    selectedCanaryDailyForecastChart ===
    CanaryForecastGraphMetrics.LATENCY_ISSUES_PREDICTED
  ) {
    datasets.push({
      label: 'Latency Health',
      data: canaryDailyForecast.latency_issues_predicted,
      borderColor: 'rgb(44, 79, 133)',
      backgroundColor: 'rgb(44, 79, 133, 0.5)',
      tension: 0.5,
      minBarLength: 4
    })
  } else if (
    selectedCanaryDailyForecastChart ===
    CanaryForecastGraphMetrics.PERCENTAGE_CHANCE_OF_INCIDENT
  ) {
    datasets.push({
      label: 'Response Health',
      data: canaryDailyForecast.percentage_chance_of_incident,
      borderColor: '#e0a173',
      backgroundColor: '#e0a17380',
      tension: 0.5,
      minBarLength: 4
    })
  }

  const data = {
    labels,
    datasets
  }

  return (
    <Box sx={{ position: 'relative', width: '49%' }}>
      <Bar options={options} data={data} />
    </Box>
  )
}
