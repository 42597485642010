import { useContext } from 'react'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { Theme } from '@mui/material/styles'
import { createStyles, makeStyles } from '@mui/styles'
import { SharedCanaryTemplateLibrariesContext } from 'contexts/SharedCanaryTemplateLibrariesContext'

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    root: {
      padding: spacing(1),
      paddingLeft: spacing(0)
    },
    select: {
      padding: spacing(1)
    }
  })
)

export default function CategoriesFilter() {
  const classes = useStyles()
  const { storeCanaryComponents, selectedComponent, setSelectedComponent } =
    useContext(SharedCanaryTemplateLibrariesContext)

  const onChange = (event: SelectChangeEvent) => {
    setSelectedComponent(String(event.target.value))
  }

  if (!storeCanaryComponents || storeCanaryComponents.length <= 0) return <></>
  return (
    <FormControl className={classes.root} size="small">
      <InputLabel id="select-helper-label-filter-shared-canary-category">
        {Boolean(selectedComponent) ? 'Category' : null}
      </InputLabel>
      <Select
        style={{ minWidth: 240 }}
        labelId="select-helper-label-filter-shared-canary-category"
        id="select-helper-filter-shared-canary-category"
        value={selectedComponent}
        onChange={onChange}
        autoWidth
        displayEmpty
        label={Boolean(selectedComponent) ? 'Category' : null}
        classes={{
          select: classes.select
        }}
      >
        <MenuItem value="">
          <em>All Categories</em>
        </MenuItem>
        {storeCanaryComponents.map((o) => (
          <MenuItem key={o.id} value={o.id}>
            {o.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
