import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { MainTabsNames } from 'components/Alarms/TabsNames'
import { userLogout } from './auth'
import type { RootState } from '../store'

export interface CanaryAlarmPageState {
  selectedAlarmId: string
  selectedTabName: MainTabsNames
}

const initialState: CanaryAlarmPageState = {
  selectedAlarmId: '',
  selectedTabName: MainTabsNames.RESPONSE
}

export const canaryAlarmPageSlice = createSlice({
  name: 'canaryAlarmPage',
  initialState,
  reducers: {
    setSelectAlarmId: (state, action: PayloadAction<string>) => {
      state.selectedAlarmId = action.payload
    },
    setSelectedTabName: (state, action: PayloadAction<MainTabsNames>) => {
      state.selectedTabName = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(userLogout, (state, action) => {
      state.selectedAlarmId = ''
      state.selectedTabName = MainTabsNames.RESPONSE
    })
  }
})

// Action creators are generated for each case reducer function
export const { setSelectAlarmId, setSelectedTabName } =
  canaryAlarmPageSlice.actions

export default canaryAlarmPageSlice.reducer

export const getSelectedAlarmId = (state: RootState) =>
  state.canaryAlarmPage.selectedAlarmId
export const getSelectedTabName = (state: RootState) =>
  state.canaryAlarmPage.selectedTabName
