import {
  ChangeEvent,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import isEqual from 'lodash/isEqual'
import {
  UserSubscribedPlan,
  UserSubscribedPlanOrganizationsInner
} from 'lib/CloudCanariesRestfulAPI'
import { APIServiceContext } from './APIServiceContext'
import { AuthContext } from './AuthContext'

interface PlansDetailsContextProviderProps {
  children: ReactNode
}

interface PlansDetailsContextProps {
  areUserSubscribedPlansLoading: boolean
  userSubscribedPlans: UserSubscribedPlan[]
  selectedSubscriptionPlan: UserSubscribedPlan | undefined
  selectedOrganization: UserSubscribedPlanOrganizationsInner | undefined
  organizations: UserSubscribedPlanOrganizationsInner[] | undefined
  rowsPerPage: number
  page: number
  rowCount: number
  changeSelectedPlan: (plan: UserSubscribedPlan | undefined) => void
  setSelectedOrganization: (
    organization: UserSubscribedPlanOrganizationsInner
  ) => void
  fetchUserSubscribedPlans: () => Promise<void>
  onRowsPerPageChange: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void
  handleChangePage: (event: any, newPage: number) => void
}
export const PlansDetailsContext = createContext<PlansDetailsContextProps>(
  null as any
)

export default function PlansDetailsContextProvider(
  props: PlansDetailsContextProviderProps
) {
  const { children } = props

  const { apiService } = useContext(APIServiceContext)
  const { userId } = useContext(AuthContext)

  const [page, setPage] = useState<number>(0)
  const [rowCount, setRowCount] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [areUserSubscribedPlansLoading, setAreUserSubscribedPlansLoading] =
    useState<boolean>(true)
  const [userSubscribedPlans, setUserSubscribedPlans] = useState<
    UserSubscribedPlan[]
  >([])
  const [organizations, setOrganizations] = useState<
    UserSubscribedPlanOrganizationsInner[]
  >([])

  const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = useState<
    UserSubscribedPlan | undefined
  >(userSubscribedPlans?.length > 0 ? userSubscribedPlans[0] : undefined)

  const fetchUserSubscribedPlans = useCallback(async () => {
    setRowCount(0)
    const requestedPage = page + 1

    setAreUserSubscribedPlansLoading(true)
    await apiService
      .listUserSubscribedPlans(requestedPage, rowsPerPage, userId, 'True')
      .then((json) => {
        setUserSubscribedPlans(json.data.results ?? [])
        setRowCount(json.data.count ?? 0)
        if (
          json.data.results.length > 0 &&
          selectedSubscriptionPlan !== undefined
        ) {
          const subscriptionPlan = json.data.results.find(
            (plan) => plan.id === selectedSubscriptionPlan.id
          )
          if (subscriptionPlan) {
            const organizations = subscriptionPlan.organizations
            if (organizations?.length > 0) {
              setOrganizations(organizations)
            } else {
              setOrganizations([])
            }
          } else {
            const organizations = json.data.results[0].organizations
            if (organizations?.length > 0) {
              setOrganizations(organizations)
            } else {
              setOrganizations([])
            }
          }
        } else {
          setOrganizations([])
        }
        setAreUserSubscribedPlansLoading(false)
      })
  }, [apiService, page, rowsPerPage, selectedSubscriptionPlan, userId])

  useEffect(() => {
    fetchUserSubscribedPlans()
  }, [fetchUserSubscribedPlans])

  const [selectedOrganization, setSelectedOrganization] = useState<
    UserSubscribedPlanOrganizationsInner | undefined
  >(
    userSubscribedPlans?.length > 0
      ? userSubscribedPlans[0].organizations?.length > 0
        ? userSubscribedPlans[0].organizations[0]
        : undefined
      : undefined
  )

  useEffect(() => {
    if (userSubscribedPlans?.length > 0) {
      if (selectedSubscriptionPlan === undefined) {
        setSelectedSubscriptionPlan(userSubscribedPlans[0])
      } else {
        const updatedUserSubscribedPlan = userSubscribedPlans?.find(
          (plan) => plan.id === selectedSubscriptionPlan.id
        )
        if (updatedUserSubscribedPlan) {
          if (!isEqual(selectedSubscriptionPlan, updatedUserSubscribedPlan)) {
            setSelectedSubscriptionPlan(updatedUserSubscribedPlan)
          }
        } else {
          setSelectedSubscriptionPlan(userSubscribedPlans[0])
        }
      }
    }
  }, [selectedSubscriptionPlan, userSubscribedPlans])

  useEffect(() => {
    if (selectedSubscriptionPlan !== undefined) {
      const organizations = selectedSubscriptionPlan.organizations
      if (organizations.length > 0) {
        setOrganizations(organizations)
        setSelectedOrganization(organizations[0])
      } else {
        setOrganizations([])
        setSelectedOrganization(undefined)
      }
    }
  }, [selectedSubscriptionPlan])

  const changeSelectedPlan = (plan: UserSubscribedPlan | undefined) => {
    if (plan) {
      setSelectedSubscriptionPlan(plan)
      setOrganizations(plan.organizations)
    }
  }
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage)
  }

  const onRowsPerPageChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <PlansDetailsContext.Provider
      value={{
        areUserSubscribedPlansLoading,
        userSubscribedPlans,
        selectedOrganization,
        selectedSubscriptionPlan,
        organizations,
        page,
        rowCount,
        rowsPerPage,
        setSelectedOrganization,
        changeSelectedPlan,
        fetchUserSubscribedPlans,
        handleChangePage,
        onRowsPerPageChange
      }}
    >
      {children}
    </PlansDetailsContext.Provider>
  )
}
