import {
  selectSLAPeriodCategory,
  setSlaPeriodCategory
} from 'client_side_state/slices/pageFilter'
import { useAppDispatch, useAppSelector } from 'client_side_state/store'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { ServiceComplianceSummaryPeriodCategoryEnum } from 'lib/CloudCanariesRestfulAPI'

function isEnumKey(
  value: string
): value is ServiceComplianceSummaryPeriodCategoryEnum {
  return Object.values(ServiceComplianceSummaryPeriodCategoryEnum)
    .flatMap((o) => o.toString())
    .includes(value)
}

export default function SLAPeriodSelector() {
  const dispatch = useAppDispatch()

  const selectedPeriodCategory = useAppSelector(selectSLAPeriodCategory)

  const changeSelectedPeriodCategory = (event: SelectChangeEvent) => {
    const value = String(event.target.value)
    if (value && isEnumKey(value)) {
      dispatch(setSlaPeriodCategory(value))
    }
  }

  return (
    <FormControl style={{ marginLeft: '1rem' }} size="small">
      <InputLabel id="select-helper-label-SLA-period-categroy">
        SLA Period
      </InputLabel>
      <Select
        style={{ minWidth: 120 }}
        labelId="select-helper-label-SLA-period-categroy"
        id="select-helper-SLA-period-categroy"
        value={selectedPeriodCategory}
        label="SLA Period"
        onChange={changeSelectedPeriodCategory}
        autoWidth
      >
        <MenuItem value={ServiceComplianceSummaryPeriodCategoryEnum.Yearly}>
          Yearly
        </MenuItem>
        <MenuItem value={ServiceComplianceSummaryPeriodCategoryEnum.Quarterly}>
          Quarterly
        </MenuItem>
        <MenuItem value={ServiceComplianceSummaryPeriodCategoryEnum.Monthly}>
          Monthly
        </MenuItem>
        <MenuItem value={ServiceComplianceSummaryPeriodCategoryEnum.Weekly}>
          Weekly
        </MenuItem>
      </Select>
    </FormControl>
  )
}
