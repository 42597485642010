export enum AdminPagesTitles {
  SettingGroups = 'Service Setting Groups',
  APIKeys = 'API Keys',
  CanaryTemplateLibraries = 'Canary Template Libraries',
  SharedCanaryTemplateLibraries = 'Shared Canary Template Libraries',
  Wallet = 'Wallet',
  ForecastModels = 'Forecast Models',
  Clusters = 'Canary Clusters',
  UserOrganizations = 'User Organizations',
  CustomSchemas = 'Custom Schemas',
  ComplianceHistory = 'SLA Results',
  BillingDetails = 'Billing Details',
  PlansDetails = 'Plans Details'
}
