import history from 'client_side_state/history'
import { useContext } from 'react'
import IconButton from '@mui/material/IconButton'
import Icon, { IconType } from 'components/Icons'
import { OrganizationsContext } from 'contexts/OrganizationsContext'
import { CanaryReadWriteMinimal } from 'lib/CloudCanariesRestfulAPI'
import RoutesService from 'services/routes'

interface MetricsPageProps {
  canary: CanaryReadWriteMinimal
}
export default function MetricsPage(props: MetricsPageProps) {
  const { canary } = props

  const {
    setLastUserSelectedOrganization,
    setUserSelectedOrganization,
    organization,
    userOrganizations
  } = useContext(OrganizationsContext)

  const handleNavigationToMetricsPage = () => {
    setLastUserSelectedOrganization(organization)
    setUserSelectedOrganization(
      userOrganizations.find(
        (organization) => organization.id === canary.organization!
      )!
    )
    history.push(RoutesService.canaryMetrics(canary.id))
  }
  return (
    <IconButton size="small" onClick={handleNavigationToMetricsPage}>
      <Icon name={IconType.Notifications} style={{ color: '#000' }} />
    </IconButton>
  )
}
