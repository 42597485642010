import { useCallback, useContext, useEffect, useState } from 'react'
import { IChangeEvent } from '@rjsf/core'
import MuiForm from '@rjsf/mui'
import { RJSFSchema } from '@rjsf/utils'
import validator from '@rjsf/validator-ajv8'
import FormsCreateButton from 'components/common/FormsCreateButton'
import { configUserOrganizationAddMember } from 'constants/formconfig/UserOrganizationAddMember'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { Organization } from 'lib/CloudCanariesRestfulAPI'
import RoutesService from 'services/routes'

interface UserOrganizationAddMemberProps {
  id: string
}

export default function UserOrganizationAddMember(
  props: UserOrganizationAddMemberProps
) {
  const { id } = props

  const { apiService } = useContext(APIServiceContext)

  const [name, setName] = useState<string>('')

  function setOrganizationDetails(organization?: Organization) {
    if (organization) {
      setName(organization.name)
    }
  }

  const getOrganizationDetails = useCallback(async () => {
    await apiService
      .retrieveOrganization(id)
      .then((json) => setOrganizationDetails(json.data))
  }, [apiService, id])
  useEffect(() => {
    getOrganizationDetails()
  }, [getOrganizationDetails])

  async function onSubmit(
    data: IChangeEvent<any, RJSFSchema, any>,
    event: React.FormEvent<any>
  ) {
    const requestBody = JSON.parse(JSON.stringify(data.formData))
    const id_organization = id
    try {
      await apiService
        .addMemberOrganization(id_organization, requestBody)
        .then(() => {
          window.location.href =
            RoutesService.userOrganizationManage(id_organization)
        })
    } catch {
      /**
       * TODO
       * Handle different api errors to show the user what went wrong
       */
      console.log('error contacting api')
    }
    return false
  }

  const schema = configUserOrganizationAddMember.schema
  return (
    <>
      <h1>Organization {name}</h1>
      <MuiForm schema={schema} id="" onSubmit={onSubmit} validator={validator}>
        <FormsCreateButton>Add Member</FormsCreateButton>
      </MuiForm>
    </>
  )
}
