const pkg = require('../../package.json')

const PublicRoutes = {
  landingPage() {
    return pkg.urlPublicSite
  },
  versions() {
    return '/versions'
  },
  termsAndConditions() {
    return '/terms-and-conditions'
  },
  privacyPolicy() {
    return '/privacy-policy'
  },
  cookiePolicy() {
    return '/cookie-policy'
  }
}
const UserRoutes = {
  userRegister() {
    return '/account/register'
  },
  userLogin() {
    return '/account/login'
  },
  userLoggedInInterstitial() {
    return '/user/logged-in'
  },
  userLogout() {
    return '/account/logout'
  },
  userVerifyEmail() {
    return '/account/verify-email'
  },
  userForgotPassword() {
    return '/account/forgot-password'
  },
  userResetPassword() {
    return '/account/reset-password'
  },
  userChangeTempPassword() {
    return '/account/change-temp-password'
  },
  userBillingDetails() {
    return '/account/billing-details'
  },
  userPlansDetails() {
    return '/account/plans-details'
  },
  userProfile() {
    return '/user/profile'
  },
  userClusters() {
    return '/user/clusters'
  },
  userWallet() {
    return '/user/wallet'
  },
  userForecastModels() {
    return '/user/forecast-models'
  },
  userOrganizationsList() {
    return '/user/organizations'
  },
  userSettingGroupsList() {
    return '/user/setting-groups'
  },
  restfulLibList() {
    return '/user/restful-lib-schema/'
  },
  templateLibraryList() {
    return '/user/template-libraries/'
  },
  sharedTemplateLibraryList() {
    return '/user/shared-template-libraries/'
  },
  APIKeysList() {
    return '/user/api-keys'
  },
  userOrganizationManage(organizationID?: string) {
    if (organizationID) {
      return `/user/organizations/${organizationID}/manage`
    } else {
      return '/user/organizations/:id/manage'
    }
  }
}

const DashboardRoutes = {
  dashboardCanaries() {
    return '/dashboard/canaries'
  },
  dashboardServiceHealth() {
    return '/dashboard/service-health-dashboard'
  },
  dashboardServicesForecast() {
    return '/dashboard/forecast'
  },
  dashboardCompliance() {
    return '/dashboard/compliance'
  },
  dashboardIncidents() {
    return '/dashboard/service-health-incident'
  }
}

const GenericRoutes = {
  authenticatedDefaultRoute() {
    return DashboardRoutes.dashboardCanaries()
  },
  authenticatedChangeTempPasswordRoute() {
    return UserRoutes.userChangeTempPassword()
  },
  dailyForecast(day?: string) {
    if (day) {
      return `/forecast/${day}`
    } else {
      return '/forecast/:day'
    }
  },
  canaryForecastManage(canaryID?: string) {
    if (canaryID) {
      return `/canary/${canaryID}/forecast`
    } else {
      return '/canary/:id/forecast'
    }
  },
  canaryCreate() {
    return '/canary/create'
  },
  canaryManage(canaryID?: string) {
    if (canaryID) {
      return `/canary/${canaryID}/manage`
    } else {
      return '/canary/:id/manage'
    }
  },
  canaryEdit(canaryID?: string) {
    if (canaryID) {
      return `/canary/${canaryID}/edit`
    } else {
      return '/canary/:id/edit'
    }
  },
  canaryMetrics(canaryID?: string) {
    if (canaryID) {
      return `/canary/${canaryID}/metrics`
    } else {
      return '/canary/:id/metrics'
    }
  },
  alarmOverview(alarmID?: string) {
    if (alarmID) {
      return `/alarm/${alarmID}`
    } else {
      return '/alarm/:id'
    }
  },
  customSchemaView(schemaID?: string) {
    if (schemaID) {
      return `/view-custom-schema/${schemaID}`
    } else {
      return '/view-custom-schema/:id'
    }
  },
  customSchemaEdit(schemaID?: string) {
    if (schemaID) {
      return `/edit-custom-schema/${schemaID}`
    } else {
      return '/edit-custom-schema/:id'
    }
  },
  customRestfulLibEdit(schemaID?: string) {
    if (schemaID) {
      return `/edit-restful-lib/${schemaID}`
    } else {
      return '/edit-restful-lib/:id'
    }
  },
  customCanaryPrototypeEdit(schemaID?: string) {
    if (schemaID) {
      return `/edit-canary-prototype/${schemaID}`
    } else {
      return '/edit-canary-prototype/:id'
    }
  },
  customCanariesList(schemaID?: string) {
    if (schemaID) {
      return `/custom-canaries-for-schema/${schemaID}`
    } else {
      return '/custom-canaries-for-schema/:id'
    }
  },
  customCanaryEdit(canaryID?: string) {
    if (canaryID) {
      return `/edit-custom-canary/${canaryID}`
    } else {
      return '/edit-custom-canary/:id'
    }
  },
  customCanaryCreate(schemaID?: string) {
    if (schemaID) {
      return `/create-custom-canary/${schemaID}`
    } else {
      return '/create-custom-canary/:id'
    }
  },
  complianceHistory() {
    return '/compliance/history'
  },
  impersonateUser() {
    return '/impersonate_user/:user_id/:token/:company/:is_first_time_user/:use_platform_clusters/:is_temp_password'
  }
}
const RoutesService = {
  ...UserRoutes,
  ...PublicRoutes,
  ...GenericRoutes,
  ...DashboardRoutes
}

export default RoutesService
