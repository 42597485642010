import useIsMatchingMD from 'hooks/useIsMatchingMD'
import { useEffect, useState } from 'react'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { CanaryStatusEnum } from 'lib/CloudCanariesRestfulAPI'

interface CanaryStatusFilterProps {
  selectedStatus: string
  setSelectedStatus: (status: string) => void
}

export default function CanaryStatusFilter(props: CanaryStatusFilterProps) {
  const { selectedStatus, setSelectedStatus } = props

  const isMatchingMD = useIsMatchingMD()

  const [selectedValue, setSelectedValue] = useState(selectedStatus)

  useEffect(() => {
    setSelectedValue(selectedStatus)
  }, [selectedStatus])

  const options = Array.from(Object.values(CanaryStatusEnum))

  const onChange = (event: SelectChangeEvent) => {
    setSelectedStatus(String(event.target.value))
  }

  return (
    <FormControl fullWidth size="small">
      <InputLabel id="select-helper-label-canaries-grid-filter-canary-status">
        {Boolean(selectedValue) ? null : null}
      </InputLabel>
      <Select
        labelId="select-helper-label-canaries-grid-filter-canary-status"
        id="select-helper-canaries-grid-filter-canary-status"
        value={selectedValue}
        onChange={onChange}
        autoWidth={!isMatchingMD}
        fullWidth={isMatchingMD}
        displayEmpty
        label={Boolean(selectedValue) ? null : null}
      >
        <MenuItem value="">
          <em>All Statuses</em>
        </MenuItem>
        {options.map((o) => {
          return (
            <MenuItem key={o} value={String(o)}>
              {String(o)}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}
