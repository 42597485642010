import { useContext, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import { IChangeEvent } from '@rjsf/core'
import MuiForm from '@rjsf/mui'
import { RJSFSchema } from '@rjsf/utils'
import validator from '@rjsf/validator-ajv8'
import Icon, { IconType } from 'components/Icons'
import DialogModal from 'components/Modals/DialogModal'
import FormsUpdateCancelButtons from 'components/common/FormsUpdateCancelButtons'
import { configEditTemplateLibraryDockerfile } from 'constants/formconfig/TemplateLibraries'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { TemplateLibrariesContext } from 'contexts/TemplateLibrariesContext'

const schema = configEditTemplateLibraryDockerfile.schema

interface EditModalProps {
  templateLibraryId: string
  dockerfile_contents: string
}

const organizationId = undefined

export default function EditDockerfileModal(props: EditModalProps) {
  const { apiService } = useContext(APIServiceContext)
  const { getTemplateLibraries } = useContext(TemplateLibrariesContext)

  const { templateLibraryId, dockerfile_contents } = props
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const onSubmit = async (
    data: IChangeEvent<any, RJSFSchema, any>,
    event: React.FormEvent<any>
  ) => {
    let requestBody = JSON.parse(JSON.stringify(data.formData))
    try {
      await apiService.partialUpdateCanaryTemplateLibrary(
        templateLibraryId,
        organizationId,
        requestBody
      )
      await getTemplateLibraries()
      handleClose()
    } catch {
      console.log('error contacting api')
    }
    handleClose()
    return false
  }

  const formData = { dockerfile_contents: dockerfile_contents }

  return (
    <>
      <IconButton
        onClick={handleClickOpen}
        color="primary"
        size="small"
        style={{ marginRight: '0.5rem' }}
      >
        <Icon name={IconType.Edit} />
      </IconButton>
      <DialogModal
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        dialogTitle="Edit Dockerfile Contents"
        dialogContent={
          <MuiForm
            schema={schema}
            onSubmit={onSubmit}
            formData={formData}
            uiSchema={configEditTemplateLibraryDockerfile.uiSchema}
            validator={validator}
          >
            <FormsUpdateCancelButtons cancelButtonOnClick={handleClose} />
          </MuiForm>
        }
      />
    </>
  )
}
