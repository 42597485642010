import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { CanaryGraphMetrics } from 'constants/CanaryGraphMetrics'
import { GenericSelectItemOption } from 'interfaces/PageFilters'
import { ServiceComplianceSummaryPeriodCategoryEnum } from 'lib/CloudCanariesRestfulAPI'
import DateTimeService from 'services/DateTimeService'
import type { RootState } from '../store'

const defaultDateTimes = DateTimeService.getDefaultDateTimes()

export interface AppStates {
  DateFrom: string
  DateTo: string
  TimeFrom: string
  TimeTo: string
  SlaPeriodCategory: ServiceComplianceSummaryPeriodCategoryEnum
  ServiceProviderOptions: GenericSelectItemOption[]
  ServiceProviderOptionValueSelected: string
  CanaryTaskSelectedGraphs: CanaryGraphMetrics[]
  CanaryTaskSelectedGraph: CanaryGraphMetrics
  RefreshInterval: number
  EditingLabel: string
}

const initialState: AppStates = {
  DateFrom: defaultDateTimes.dateStart,
  DateTo: defaultDateTimes.dateStop,
  TimeFrom: defaultDateTimes.timeStart,
  TimeTo: defaultDateTimes.timeStop,
  SlaPeriodCategory: ServiceComplianceSummaryPeriodCategoryEnum.Weekly,
  ServiceProviderOptions: [],
  ServiceProviderOptionValueSelected: '',
  CanaryTaskSelectedGraphs: [
    CanaryGraphMetrics.RESPONSE_LATENCY,
    CanaryGraphMetrics.LATENCY_HEALTH
  ],
  CanaryTaskSelectedGraph: CanaryGraphMetrics.ERROR_OBTAINING_RESPONSE,
  RefreshInterval: 1,
  EditingLabel: ''
}

export const pageFilterSlice = createSlice({
  name: 'pageFilter',
  initialState,
  reducers: {
    setDateFrom: (state, action: PayloadAction<string>) => {
      state.DateFrom = action.payload
    },
    setDateTo: (state, action: PayloadAction<string>) => {
      state.DateTo = action.payload
    },
    setTimeFrom: (state, action: PayloadAction<string>) => {
      state.TimeFrom = action.payload
    },
    setTimeTo: (state, action: PayloadAction<string>) => {
      state.TimeTo = action.payload
    },
    setSlaPeriodCategory: (
      state,
      action: PayloadAction<ServiceComplianceSummaryPeriodCategoryEnum>
    ) => {
      state.SlaPeriodCategory = action.payload
    },
    setServiceProviderOptions: (
      state,
      action: PayloadAction<GenericSelectItemOption[]>
    ) => {
      state.ServiceProviderOptions = action.payload
    },
    setServiceProviderOptionValueSelected: (
      state,
      action: PayloadAction<string>
    ) => {
      state.ServiceProviderOptionValueSelected = action.payload
    },
    setCanaryTaskSelectedGraphs: (
      state,
      action: PayloadAction<CanaryGraphMetrics[]>
    ) => {
      state.CanaryTaskSelectedGraphs = action.payload
    },
    setCanaryTaskSelectedGraph: (
      state,
      action: PayloadAction<CanaryGraphMetrics>
    ) => {
      state.CanaryTaskSelectedGraph = action.payload
    },
    setRefreshInterval: (state, action: PayloadAction<number>) => {
      state.RefreshInterval = action.payload
    },
    resetPageFilterState: (state) => {
      console.log('todo')
    },
    setEditingLabel: (state, action: PayloadAction<string>) => {
      state.EditingLabel = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const {
  setDateFrom,
  setDateTo,
  setTimeFrom,
  setTimeTo,
  setSlaPeriodCategory,
  setServiceProviderOptions,
  setServiceProviderOptionValueSelected,
  setCanaryTaskSelectedGraphs,
  setCanaryTaskSelectedGraph,
  setRefreshInterval,
  resetPageFilterState,
  setEditingLabel
} = pageFilterSlice.actions

export default pageFilterSlice.reducer

export const selectServiceProviderSelected = (state: RootState) =>
  state.page.ServiceProviderOptionValueSelected
export const selectServiceProviderOptions = (state: RootState) =>
  state.page.ServiceProviderOptions

export const selectSLAPeriodCategory = (state: RootState) =>
  state.page.SlaPeriodCategory

export const selectEditingLabel = (state: RootState) => state.page.EditingLabel

export const selectSelectedTaskGraph = (state: RootState) =>
  state.page.CanaryTaskSelectedGraph

export const selectSelectedGraphs = (state: RootState) =>
  state.page.CanaryTaskSelectedGraphs

export const selectDateFrom = (state: RootState) => state.page.DateFrom
export const selectDateTo = (state: RootState) => state.page.DateTo
export const selectTimeFrom = (state: RootState) => state.page.TimeFrom
export const selectTimeTo = (state: RootState) => state.page.TimeTo

export const selectRefreshInterval = (state: RootState) =>
  state.page.RefreshInterval
