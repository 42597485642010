import {
  setNoDataRequiredAvailableModalOpen,
  setOrganizationRequiredModalMessage,
  setOrganizationRequiredModalOpen
} from 'client_side_state/slices/app'
import { useAppDispatch } from 'client_side_state/store'
import {
  ChangeEvent,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { toast } from 'react-toastify'
import Toast from 'components/Toast'
import { OrganizationRequiredMessage } from 'components/UserOrganization/OrganizationRequiredDialog'
import { queryParamPageSizeJumbo } from 'constants/AppConfig'
import { GenericSelectItemOption } from 'interfaces/PageFilters'
import {
  CanaryReadWriteMinimal,
  ServiceHealthEvaluationSettingsGroup
} from 'lib/CloudCanariesRestfulAPI'
import { deDuplicateServiceProviderOptions } from 'services/utils'
import { APIServiceContext } from './APIServiceContext'
import { OrganizationsContext } from './OrganizationsContext'
import { ServicesContext } from './ServicesContext'

interface ServiceSettingsGroupContextProviderProps {
  children: ReactNode
}

interface ServiceSettingsGroupContextProps {
  page: number
  rowCount: number
  rowsPerPage: number
  areServiceSettingsGroupsLoading: boolean
  filteredServiceSettingsGroups: ServiceHealthEvaluationSettingsGroup[] | null
  canaries: CanaryReadWriteMinimal[] | null
  createModalOpen: boolean
  providersOptions: GenericSelectItemOption[]
  selectedProvider: string
  setSelectedProvider: (value: string) => void
  onRowsPerPageChange: (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void
  handleChangePage: (e: any, newPage: number) => void
  deleteSettingsGroup: (schemaId: string, currentName: string) => void
  fetchServiceSettingsGroups: () => Promise<void>
  handleCreateModalOpen: () => void
  handleCreateModalClose: () => void
}
export const ServiceSettingsGroupContext =
  createContext<ServiceSettingsGroupContextProps>(null as any)

export default function ServiceSettingsGroupContextProvider(
  props: ServiceSettingsGroupContextProviderProps
) {
  const { children } = props
  const dispatch = useAppDispatch()

  const { organizationId } = useContext(OrganizationsContext)

  const { apiService } = useContext(APIServiceContext)
  const { canaryableServices } = useContext(ServicesContext)

  const [page, setPage] = useState<number>(0)
  const [rowCount, setRowCount] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [areServiceSettingsGroupsLoading, setAreServiceSettingsGroupsLoading] =
    useState<boolean>(true)
  const [serviceSettingsGroups, setServiceSettingsGroups] = useState<
    ServiceHealthEvaluationSettingsGroup[] | null
  >([])
  const [canaries, setCanaries] = useState<CanaryReadWriteMinimal[] | null>([])
  const [filteredServiceSettingsGroups, setFilteredServiceSettingsGroups] =
    useState<ServiceHealthEvaluationSettingsGroup[]>([])

  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false)
  const [providersOptions, setProvidersOptions] = useState<
    GenericSelectItemOption[]
  >([])
  const [selectedProvider, setSelectedProvider] = useState<string>('')

  const fetchServiceSettingsGroups = useCallback(async () => {
    setRowCount(0)
    setAreServiceSettingsGroupsLoading(true)
    const requestedPage = page + 1
    apiService
      .listServiceHealthEvaluationSettingsGroups(
        requestedPage,
        rowsPerPage,
        organizationId
      )
      .then((json) => {
        setServiceSettingsGroups(json.data.results ?? [])
        setRowCount(json.data.count ?? 0)
        setAreServiceSettingsGroupsLoading(false)
      })
  }, [apiService, organizationId, page, rowsPerPage])

  const fetchCanaries = useCallback(async () => {
    apiService
      .listCanaries(undefined, queryParamPageSizeJumbo, organizationId)
      .then((json) => setCanaries(json.data.results ?? []))
  }, [apiService, organizationId])

  useEffect(() => {
    fetchServiceSettingsGroups()
    return () => {
      setServiceSettingsGroups([])
      setRowCount(0)
      setAreServiceSettingsGroupsLoading(false)
    }
  }, [fetchServiceSettingsGroups])

  useEffect(() => {
    const providers = deDuplicateServiceProviderOptions(serviceSettingsGroups!)
    setProvidersOptions(providers)
  }, [serviceSettingsGroups])

  useEffect(() => {
    fetchCanaries()
    return () => {
      setCanaries([])
    }
  }, [fetchCanaries])

  useEffect(() => {
    setSelectedProvider('')
    setRowCount(0)
  }, [organizationId])

  useEffect(() => {
    if (serviceSettingsGroups && serviceSettingsGroups.length > 0) {
      if (selectedProvider !== '') {
        const filteredTemplateLibraries = serviceSettingsGroups!.filter(
          (group) => group.provider_id === selectedProvider
        )
        setFilteredServiceSettingsGroups(filteredTemplateLibraries)
      } else {
        setFilteredServiceSettingsGroups(serviceSettingsGroups)
      }
    }
  }, [selectedProvider, serviceSettingsGroups])

  const deleteSettingsGroup = (schemaId: string, currentName: string) => {
    apiService
      .destroyServiceHealthEvaluationSettingsGroup(schemaId, organizationId)
      .then((response) => {
        if (response.status === 204) {
          toast.success(
            <Toast
              title="Success"
              text={`Settings Group "${currentName}" is deleted successfully!`}
            />,
            {
              position: 'top-right'
            }
          )
        } else {
          toast.error(
            <Toast
              title="Error"
              text="Something went wrong, please try again!"
            />,
            {
              position: 'top-right'
            }
          )
        }
        fetchServiceSettingsGroups()
      })
  }

  const onRowsPerPageChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage)
  }

  const handleCreateModalOpen = () => {
    if (!organizationId) {
      dispatch(
        setOrganizationRequiredModalMessage(
          OrganizationRequiredMessage.ServiceSettingsGroupCreate
        )
      )
      dispatch(setOrganizationRequiredModalOpen(true))
    } else if (organizationId) {
      const filteredCanaryableServices = canaryableServices?.filter(
        (service) => service.organization_id === organizationId
      )
      if (filteredCanaryableServices?.length! <= 0 || canaries?.length! <= 0) {
        dispatch(setNoDataRequiredAvailableModalOpen(true))
      } else {
        dispatch(setNoDataRequiredAvailableModalOpen(false))
        setCreateModalOpen(true)
      }
    }
  }

  const handleCreateModalClose = () => {
    setCreateModalOpen(false)
  }

  return (
    <ServiceSettingsGroupContext.Provider
      value={{
        page,
        rowCount,
        rowsPerPage,
        areServiceSettingsGroupsLoading,
        filteredServiceSettingsGroups,
        canaries,
        createModalOpen,
        providersOptions,
        selectedProvider,
        setSelectedProvider,
        onRowsPerPageChange,
        deleteSettingsGroup,
        handleChangePage,
        fetchServiceSettingsGroups,
        handleCreateModalOpen,
        handleCreateModalClose
      }}
    >
      {children}
    </ServiceSettingsGroupContext.Provider>
  )
}
