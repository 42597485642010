import styled from 'styled-components'

export const SearchContainer = styled.div<{
  $width: number
  $isMatchingMD: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ $width, $isMatchingMD }) =>
    $isMatchingMD ? '100%' : `${$width}px`};
  height: 36px;
  padding: 4px 4px 4px 14px;
  border-radius: 15px;
  box-shadow: 0px 1px 3px rgb(150, 150, 150);

  > :last-child {
    margin: 0 2px 0 0;
    flex-basis: 20px;
    padding: 0;
    border-radius: 50%;
    transition: background-color 0.15s;

    cursor: pointer;
    &:hover {
      background-color: #eee;
    }
  }

  > :first-child {
    flex-grow: 1;
    border: none;
    font-size: 11pt;
    :focus {
      outline: none;
    }
  }
`
