import { useContext, useEffect, useState } from 'react'
import { AuthContext } from 'contexts/AuthContext'
import { ApiVersion } from 'lib/CloudCanariesRestfulAPI'

const pkg = require('../../../package.json')

export default function VersionsPage() {
  const { unAuthenticatedAPIService } = useContext(AuthContext)
  const [apiVersion, setAPIVersion] = useState<ApiVersion>({} as ApiVersion)

  useEffect(() => {
    async function getCurrentVersion() {
      const id = 'current'
      try {
        await unAuthenticatedAPIService
          .retrieveApiVersion(id)
          .then((json) => setAPIVersion(json.data))
      } catch {
        setAPIVersion({ api_version: 'error', container_version: 'error' })
      }
    }
    getCurrentVersion()
  }, [unAuthenticatedAPIService])

  return (
    <div>
      <h1>Front end</h1>
      <h2>PKG: {pkg.version}</h2>
      <h2>CNT: {pkg.containerVersion}</h2>
      <h1>Back end</h1>
      <h2>API: {apiVersion.api_version}</h2>
      <h2>CNT: {apiVersion.container_version}</h2>
    </div>
  )
}
