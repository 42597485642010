import { StyleSheet, View } from '@react-pdf/renderer'
import { ReportLevel } from 'constants/formconfig/ArtifactConfigs'
import { PDFFileData } from 'interfaces/PDFFileData'
import ReportHeader from './ReportHeader'
import ReportTableRow from './ReportTableRow'

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24
  }
})

interface ReportTableProps {
  pdfFileData: PDFFileData
}

export default function ReportTable(props: ReportTableProps) {
  const { pdfFileData } = props

  const reportLevel = ReportLevel[pdfFileData.formData.report_level]
  // const period = pdfFileData.formData.period

  return (
    <View style={styles.tableContainer}>
      <ReportHeader reportLevel={reportLevel} />
      <ReportTableRow reportLevel={reportLevel} pdfFileData={pdfFileData} />
      {/* <InvoiceTableRow items={invoice.items} />
        <InvoiceTableBlankSpace rowsCount={ tableRowsCount - invoice.items.length} />
        <InvoiceTableFooter items={invoice.items} /> */}
    </View>
  )
}
