import { useContext } from 'react'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { Theme } from '@mui/material/styles'
import { createStyles, makeStyles } from '@mui/styles'
import { ServiceSettingsGroupContext } from 'contexts/ServiceSettingsGroupContext'

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    root: {
      padding: spacing(1),
      paddingLeft: spacing(0),
      marginBottom: spacing(1)
    },
    select: {
      padding: spacing(1)
    }
  })
)

export default function ProvidersFilter() {
  const classes = useStyles()
  const { providersOptions, selectedProvider, setSelectedProvider } =
    useContext(ServiceSettingsGroupContext)

  const onChange = (event: SelectChangeEvent) => {
    setSelectedProvider(String(event.target.value))
  }

  if (!providersOptions || providersOptions.length <= 0) return <></>
  return (
    <FormControl className={classes.root} size="small">
      <InputLabel id="select-helper-label-filter-service-provider">
        {Boolean(selectedProvider) ? 'Provider' : null}
      </InputLabel>
      <Select
        style={{ minWidth: 240 }}
        labelId="select-helper-label-filter-service-provider"
        id="select-helper-filter-service-provider"
        value={selectedProvider}
        onChange={onChange}
        autoWidth
        displayEmpty
        label={Boolean(selectedProvider) ? 'Provider' : null}
        classes={{
          select: classes.select
        }}
      >
        <MenuItem value="">
          <em>All Providers</em>
        </MenuItem>
        {providersOptions.map((o) => (
          <MenuItem key={o.value} value={o.value}>
            {o.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
