import {
  ChangeEvent,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { OrganizationAPIKey } from 'lib/CloudCanariesRestfulAPI'
import { APIServiceContext } from './APIServiceContext'
import { OrganizationsContext } from './OrganizationsContext'

interface APIKeysContextProviderProps {
  children: ReactNode
}

interface APIKeysContextProps {
  areApiKeysLoading: boolean
  apiKeys: OrganizationAPIKey[]
  nonRevokedAPIKeys: OrganizationAPIKey[]
  rowsPerPage: number
  page: number
  rowCount: number
  fetchAPIKeys: () => Promise<void>
  onRowsPerPageChange: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void
  handleChangePage: (event: any, newPage: number) => void
}
export const APIKeysContext = createContext<APIKeysContextProps>(null as any)

export default function APIKeysContextProvider(
  props: APIKeysContextProviderProps
) {
  const { children } = props

  const { apiService } = useContext(APIServiceContext)
  const { organizationId } = useContext(OrganizationsContext)

  const [page, setPage] = useState<number>(0)
  const [rowCount, setRowCount] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [areApiKeysLoading, setAreApiKeysLoading] = useState<boolean>(true)
  const [apiKeys, setApiKeys] = useState<OrganizationAPIKey[]>([])
  const [nonRevokedAPIKeys, setNonRevokedAPIKeys] = useState<
    OrganizationAPIKey[]
  >([])

  useEffect(() => {
    setRowCount(0)
  }, [organizationId])

  const fetchAPIKeys = useCallback(async () => {
    setRowCount(0)
    const requestedPage = page + 1

    setAreApiKeysLoading(true)
    await apiService
      .listOrganizationAPIKeys(requestedPage, rowsPerPage, organizationId)
      .then((json) => {
        setApiKeys(json.data.results ?? [])
        setNonRevokedAPIKeys(
          json.data.results
            ? json.data.results.filter((item) => item.revoked! !== true)
            : []
        )
        setRowCount(json.data.count ?? 0)
        setAreApiKeysLoading(false)
      })
  }, [apiService, organizationId, page, rowsPerPage])

  useEffect(() => {
    fetchAPIKeys()
  }, [fetchAPIKeys])

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage)
  }

  const onRowsPerPageChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <APIKeysContext.Provider
      value={{
        areApiKeysLoading,
        apiKeys,
        nonRevokedAPIKeys,
        page,
        rowCount,
        rowsPerPage,
        fetchAPIKeys,
        handleChangePage,
        onRowsPerPageChange
      }}
    >
      {children}
    </APIKeysContext.Provider>
  )
}
