import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { uniqueId } from 'lodash'
import { PDFFileData } from 'interfaces/PDFFileData'
import { secondsToHhMmSs } from 'services/utils'

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 24,
    textAlign: 'center',
    fontStyle: 'bold',
    flexGrow: 1
  },
  service: {
    width: '16%'
  },
  provider: {
    width: '16%'
  },
  startDate: {
    width: '15%'
  },
  rest: {
    width: '10%'
  },
  total: {
    width: '16%',
    fontStyle: 'bold'
  },
  totalRow: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 24,
    textAlign: 'center',
    fontFamily: 'Helvetica-Bold',
    fontStyle: 'bold',
    flexGrow: 1,
    marginTop: 4
  }
})

interface ReportTableRowProps {
  reportLevel: string
  pdfFileData: PDFFileData
}

export default function ReportTableRow(props: ReportTableRowProps) {
  const { pdfFileData } = props

  const items =
    pdfFileData.servicesByOrganization &&
    'services' in pdfFileData.servicesByOrganization! &&
    Array.isArray(pdfFileData.servicesByOrganization!.services) &&
    pdfFileData.servicesByOrganization!.services.map((item) => (
      <View style={styles.row} key={item.serviceName.toString()}>
        <Text style={styles.service}>{item.serviceName}</Text>
        <Text style={styles.provider}>{item.provider}</Text>
        <Text style={styles.rest}>{item.canaries}</Text>
        <Text style={styles.rest}>{item.errors}</Text>
        <Text style={styles.rest}>
          {secondsToHhMmSs(item.meantimeBetweenErrors)}
        </Text>
        <Text style={styles.rest}>{item.incidents}</Text>
        <Text style={styles.rest}>
          {secondsToHhMmSs(item.meantimeBetweenIncidents)}
        </Text>
        <Text style={styles.rest}>{secondsToHhMmSs(item.downtime)}</Text>
        <Text style={styles.rest}>{item.sla}</Text>
        <Text style={styles.rest}>{item.compliant ? 'Yes' : 'No'}</Text>
        <Text style={styles.startDate}>{item.startDate}</Text>
      </View>
    ))
  pdfFileData.servicesByOrganization &&
    'servicesByOrganization' in pdfFileData &&
    items &&
    items.push(
      <View style={styles.totalRow} key={uniqueId(pdfFileData.reporterName)}>
        <Text style={styles.service}></Text>
        <Text style={styles.total}>Total</Text>
        <Text style={styles.rest}>
          {'totalCanaries' in pdfFileData.servicesByOrganization
            ? pdfFileData.servicesByOrganization.totalCanaries
            : 0}
        </Text>
        <Text style={styles.rest}>
          {'totalErrors' in pdfFileData.servicesByOrganization
            ? pdfFileData.servicesByOrganization.totalErrors
            : 0}
        </Text>
        <Text style={styles.rest}></Text>
        <Text style={styles.rest}>
          {'totalIncidents' in pdfFileData.servicesByOrganization
            ? pdfFileData.servicesByOrganization.totalIncidents
            : 0}
        </Text>
        <Text style={styles.rest}></Text>
        <Text style={styles.rest}>
          {secondsToHhMmSs(
            'totalDowntime' in pdfFileData.servicesByOrganization
              ? pdfFileData.servicesByOrganization.totalDowntime
              : 0
          )}
        </Text>
        <Text style={styles.rest}>
          {'totalSLA' in pdfFileData.servicesByOrganization
            ? pdfFileData.servicesByOrganization.totalSLA
            : 0}
        </Text>
        <Text style={styles.rest}>
          {'totalCompliant' in pdfFileData.servicesByOrganization
            ? pdfFileData.servicesByOrganization.totalCompliant
              ? 'Yes'
              : 'No'
            : null}
        </Text>
        <Text style={styles.startDate}></Text>
      </View>
    )
  return <>{items}</>
}
