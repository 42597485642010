import { useContext, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import DialogModal from 'components/Modals/DialogModal'
import AuoLoadCanaryTemplatesLoader from 'components/common/Loaders/AuoLoadCanaryTemplatesLoader'
import { ToastErrors } from 'constants/toast_errors'
import { ToastSuccessMessages } from 'constants/toast_success_messages'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { AuthContext } from 'contexts/AuthContext'
import { AutoLoadCanaryTemplatesContext } from 'contexts/AutoLoadCanaryTemplatesContext'
import { OrganizationsContext } from 'contexts/OrganizationsContext'
import CanaryTemplatesDataGrid from './CanaryTemplatesDataGrid'
import SkipAutoLoadCanaryTemplatesButton from './SkipAutoLoadCanaryTemplatesButton'

export default function AutoLoadCanaryTemplatesModal() {
  const { userId, getIsFirstTimeUser, setIsFirstTimeUser } =
    useContext(AuthContext)
  const { apiService } = useContext(APIServiceContext)
  const {
    organizationId,
    organization,
    allOrganizations,
    setUserSelectedOrganization
  } = useContext(OrganizationsContext)
  const { selectedCanaryTemplates } = useContext(AutoLoadCanaryTemplatesContext)

  const [open, setOpen] = useState<boolean>(false)
  const [isLoadingCanaryTemplates, setIsLoadingCanaryTemplates] =
    useState<boolean>(false)

  const skipButtonRef = useRef<{
    setSkipConfirmOpen: (open: boolean) => void
  }>(null)

  const provider: string = 'Cloud Canaries'

  useEffect(() => {
    if (getIsFirstTimeUser()) {
      setUserSelectedOrganization(allOrganizations[0])
      setOpen(true)
    }
  }, [allOrganizations, getIsFirstTimeUser, setUserSelectedOrganization])

  const handleClose = async (skipCancel: boolean) => {
    if (!skipCancel && skipButtonRef.current) {
      skipButtonRef.current.setSkipConfirmOpen(true)
      return
    } else {
      try {
        const requestBody: any = { is_first_time_user: false }
        await apiService.partialUpdateUser(userId, requestBody).then(() => {
          setIsFirstTimeUser(false)
          setOpen(false)
        })
      } catch (e) {
        toast.error(ToastErrors.ERROR_SKIP_AUTOLOAD_CANARY_TEMPLATES, {
          position: 'top-right'
        })
      }
    }
  }
  const delay = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms))

  const onLoadCanaryTemplateLibraries = async () => {
    if (selectedCanaryTemplates.length > 0) {
      setIsLoadingCanaryTemplates(true)
      let canariesAutoLoaded = 0

      await Promise.all(
        selectedCanaryTemplates.map(async (template, index) => {
          await delay(index * 750)
          await apiService
            .loadTemplateLibrariesStoreCanary(template.toString(), {
              id: template.toString(),
              organization_id: organizationId
            })
            .then((json) => {
              if (json.status === 200) {
                canariesAutoLoaded += 1
              }
            })
        })
      )
      if (canariesAutoLoaded === selectedCanaryTemplates.length) {
        try {
          const requestBody: any = { is_first_time_user: false }
          await apiService.partialUpdateUser(userId, requestBody).then(() => {
            setIsFirstTimeUser(false)
            setIsLoadingCanaryTemplates(false)
            setOpen(false)
          })
          toast.success(
            ToastSuccessMessages.SUCCESS_AUTO_LOAD_CANARY_TEMPLATE_LIBRARIES,
            {
              position: 'top-right'
            }
          )
        } catch (e) {
          toast.error(ToastErrors.ERROR_SKIP_AUTOLOAD_CANARY_TEMPLATES, {
            position: 'top-right'
          })
        }
      } else {
        toast.error(ToastErrors.ERROR_AUTOLOAD_CANARY_TEMPLATES, {
          position: 'top-right'
        })
      }
    } else {
      toast.warn(ToastErrors.WARN_SELECT_CANARY_TEMPLATES, {
        position: 'top-right'
      })
    }
  }

  return (
    <DialogModal
      maxWidth="xl"
      open={open}
      sx={{
        p: 3,
        '.MuiDialog-paper': {
          width: '60vw'
        }
      }}
      onClose={() => handleClose(false)}
      dialogTitle="Auto load sample Canary Template Libraries"
      dialogContent={
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              pr: 3,
              pl: 1
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                mb: 2,
                justifyContent: 'space-between'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <Typography fontSize={20} fontWeight={500} sx={{ mr: 1 }}>
                  Organization:
                </Typography>
                <Typography fontSize={20} fontWeight={500}>
                  {organization?.name}
                </Typography>
              </Box>
              <Box>
                <Typography fontSize={20} fontWeight={500}>
                  Provider: {provider}
                </Typography>
              </Box>
            </Box>
            <Typography fontSize={20} fontWeight={500}>
              Recommended!
            </Typography>
          </Box>
          {isLoadingCanaryTemplates ? (
            <AuoLoadCanaryTemplatesLoader />
          ) : (
            <CanaryTemplatesDataGrid />
          )}
        </>
      }
      dialogActions={
        <>
          <SkipAutoLoadCanaryTemplatesButton
            afterSubmit={handleClose}
            ref={skipButtonRef}
            skip={false}
          />
          <Button
            onClick={onLoadCanaryTemplateLibraries}
            variant="outlined"
            autoFocus
            sx={{
              textTransform: 'capitalize'
            }}
          >
            Load
          </Button>
        </>
      }
    />
  )
}
