import styled from 'styled-components'

export const Container = styled.div<{ $width?: number }>`
  position: relative;
  margin: 20px auto;
  width: ${({ $width }) => $width ?? 120}px;
  height: ${({ $width }) => $width ?? 120}px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid black;

  .inner {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 75%;
    height: 75%;

    transform: translateX(-50%) translateY(-50%);
    background-color: #294d86;
    border-radius: 50%;
  }

  .content {
    position: absolute;
    left: 50%;
    top: 50%;

    transform: translateX(-50%) translateY(-50%);
    text-align: center;
    font-size: 14pt;
    font-weight: 600;
    // line-height: 15pt;
    color: white;
  }
`

export const SegmentArc = styled.div<{
  $color: string
  $degrees: number
  $startDegree: number
}>`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  background: ${({ $color }) => $color};

  transform-origin: 0 0;
  transform: ${({ $degrees, $startDegree }) =>
    `rotate(${$startDegree - 90}deg) skew(${90 - $degrees}deg)`};
`
