import styled from 'styled-components'

export const EditorContainer = styled.div<{ $weekly: boolean }>`
  text-align: center;

  .subheading {
    font-size: 1.5rem;
    font-weight: 700;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .basis-selection {
    display: flex;
    justify-content: space-evenly;
    width: 500px;
  }

  .heading2 {
    display: inline-block;
    margin: 0;
    color: #aaa;
    border-bottom: 1px solid transparent;
    font-size: 1.25em;
    font-weight: 500;
    user-select: none;
    margin-bottom: 12px;
    cursor: pointer;

    &.selected {
      color: black;
    }

    &:not(.selected):hover {
      box-sizing: content-box;
      border-bottom: 1px solid #bbb;
    }
  }

  .underline {
    display: inline-block;
    position: relative;
    top: -18px;
    left: ${({ $weekly }) => ($weekly ? '-93px' : '97px')};
    width: ${({ $weekly }) => ($weekly ? '73px' : '65px')};
    border-bottom: 1px solid black;
    transition: 0.2s;
  }

  .buttons {
    height: 50px;
  }

  // dynamic components

  .schedule-selection {
    height: 330px;
  }

  .calendar-selection {
    display: grid;
    grid-template-columns: repeat(7, 33px);
    gap: 8px 10px;
  }

  .time-selection {
    margin-top: -4px;
    text-align: left;

    span:first-child {
      color: #b9b9b9;
      font-style: italic;
      font-size: 10pt;
    }

    select {
      border-width: 0 0 1px;
      margin: 0 6px;
    }
  }

  .frequency-selection {
    text-align: left;

    input {
      border-width: 0 0 1px;
      margin: 0 6px;
    }
  }

  .field {
    width: 450px;
    display: flex;
    align-items: flex-start;
    margin: 5px 0 30px 0;
    user-select: none;

    label {
      flex-basis: 120px;
      margin-right: 30px;
      font-size: 1.1em;
      text-align: right;
    }

    > :nth-child(2) {
      flex-basis: 300px;
      font-size: 10pt;

      input,
      select {
        font-size: 12pt;
      }
    }

    &:nth-child(2) {
      margin-bottom: 15px;
    }
  }

  .desc {
    height: 50px;
    margin-bottom: 10px;
    color: gray;
    font-style: italic;
    font-size: 11pt;
    white-space: pre-line;
  }
`

export const CircleButton = styled.div<{ $selected: boolean }>`
  width: 30px;
  height: 30px;
  background-color: ${({ $selected }) => ($selected ? '#009f00' : '#a9a9a9')};
  color: ${({ $selected }) => ($selected ? '#FFF' : '#000')};
  border-radius: 50%;
  cursor: pointer;

  font-size: 1rem;
  font-weight: ${({ $selected }) => ($selected ? '600' : '400')};
  text-align: center;
  line-height: 2;
  vertical-align: middle;
  user-select: none;

  :hover {
    filter: brightness(92%);
  }

  :active {
    filter: brightness(100%);
  }
`

export const ActionsContainer = styled.div<{ $cancel: boolean }>`
  margin: 0 20px 25px 0;

  > span:first-child {
    color: #b9b9b9;
    visibility: ${({ $cancel }) => ($cancel ? 'visible' : 'hidden')};
  }

  > :nth-child(2) {
    margin-right: 1rem;
  }
`
