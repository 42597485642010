import { useContext, useEffect, useMemo, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import { IChangeEvent } from '@rjsf/core'
import MuiForm from '@rjsf/mui'
import {
  ErrorSchema,
  ErrorSchemaBuilder,
  RJSFSchema,
  UiSchema
} from '@rjsf/utils'
import validator from '@rjsf/validator-ajv8'
import { AxiosError, isAxiosError } from 'axios'
import Icon, { IconType } from 'components/Icons'
import DialogModal from 'components/Modals/DialogModal'
import FormsUpdateCancelButtons from 'components/common/FormsUpdateCancelButtons'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { ServicesContext } from 'contexts/ServicesContext'
import { TemplateLibrariesContext } from 'contexts/TemplateLibrariesContext'
import {
  CanaryServiceProvider,
  CanaryTemplateLibrary
} from 'lib/CloudCanariesRestfulAPI'

interface TemplateProviderNameEditModalProps {
  templateLibrary: CanaryTemplateLibrary
}

export default function TemplateProviderNameEditModal(
  props: TemplateProviderNameEditModalProps
) {
  const { apiService } = useContext(APIServiceContext)
  const { getTemplateLibraries } = useContext(TemplateLibrariesContext)

  const { templateLibrary } = props
  const providerName = templateLibrary.generated_provider_name!

  const { serviceProviders, fetchServiceProviders } =
    useContext(ServicesContext)

  const [editModalOpen, setEditModalOpen] = useState<boolean>(false)
  const [localServiceProviders, setLocalServiceProviders] = useState<
    CanaryServiceProvider[] | null | undefined
  >(serviceProviders)
  const [formData, setFormData] = useState<any>({})
  const [extraErrors, setExtraErrors] = useState<ErrorSchema>({})

  useEffect(() => {
    const filteredServiceProviders = serviceProviders?.filter(
      (provider) => provider.organization_id === templateLibrary.organization_id
    )
    setLocalServiceProviders(filteredServiceProviders)
  }, [serviceProviders, templateLibrary.organization_id])

  const selectedServiceProvider = useMemo<string | undefined>(() => {
    let providerId = undefined
    if (localServiceProviders && localServiceProviders.length > 0) {
      const found = localServiceProviders?.filter(
        (provider) => provider.name === templateLibrary.generated_provider_name
      )
      if (found.length > 0) {
        providerId = found[0].id
      }
    }
    return providerId
  }, [localServiceProviders, templateLibrary.generated_provider_name])

  useEffect(() => {
    const newFormData: any = {
      generated_provider_name: selectedServiceProvider,
      generated_service_name: templateLibrary.generated_service_name,
      organization_id: templateLibrary.organization_id
    }
    setFormData(newFormData)
  }, [selectedServiceProvider, templateLibrary])

  const handleClickOpen = () => {
    setEditModalOpen(true)
  }

  const handleClose = () => {
    setExtraErrors({})
    setEditModalOpen(false)
  }

  const onSubmit = async (
    data: IChangeEvent<any, RJSFSchema, any>,
    event: React.FormEvent<any>
  ) => {
    let requestBody = JSON.parse(JSON.stringify(data.formData))
    let isNewProvider = false

    // if the user selects existing generated provider, we pass it to the backend to choose it instead of creating a new one.
    if (requestBody.generated_provider_name !== 'New Provider') {
      const provider = localServiceProviders?.filter(
        (provider) => provider.id === requestBody.generated_provider_name
      )
      if (provider) {
        requestBody.generated_provider_name = provider[0].id
      }
    } else {
      isNewProvider = true
      requestBody.generated_provider_name = requestBody.new_provider_name
    }
    requestBody.is_processed = false
    try {
      await apiService
        .partialUpdateCanaryTemplateLibrary(
          templateLibrary.id!,
          templateLibrary.organization_id!,
          requestBody
        )
        .then(async (json) => {
          if (json.status === 200) {
            handleClose()
            await fetchServiceProviders()
            await getTemplateLibraries()
          }
        })
    } catch (e: any) {
      if (isAxiosError(e)) {
        const err = e as AxiosError
        if (err.response?.status === 400) {
          const errors = err.response?.data as {}
          const builder = new ErrorSchemaBuilder()
          Object.entries(errors).forEach(([key, value]) => {
            if (isNewProvider && key === 'generated_provider_name') {
              builder.addErrors(value as string, 'new_provider_name')
            } else {
              builder.addErrors(value as string, key)
            }
          })
          setExtraErrors(builder.ErrorSchema)
        }
      }
    }
  }

  const serviceProvidersEnumNames: string[] | undefined = localServiceProviders
    ?.concat({ name: 'New Provider' })
    ?.flatMap((o) => {
      return o.name ?? ''
    })
  const serviceProvidersEnumValues: string[] | undefined = localServiceProviders
    ?.concat({ id: 'New Provider' })
    ?.flatMap((o) => {
      return o.id
    })
    .flatMap((f) => (f ? [f] : []))

  const schema = {
    type: 'object',
    properties: {
      generated_provider_name: {
        title: 'Provider Name',
        enum: serviceProvidersEnumValues,
        enumNames: serviceProvidersEnumNames
      },
      generated_service_name: {
        type: 'string',
        title: 'Service Name'
      },
      organization_id: { type: 'string' }
    },
    allOf: [
      {
        if: {
          properties: {
            generated_provider_name: {
              const: 'New Provider'
            }
          }
        },
        then: {
          properties: {
            new_provider_name: {
              type: 'string',
              title: 'New Provider'
            }
          },
          required: ['generated_provider_name', 'new_provider_name']
        }
      },
      {
        if: {
          properties: {
            generated_provider_name: {
              const: serviceProvidersEnumValues?.filter(
                (item) => item === selectedServiceProvider
              )['0']
            }
          }
        },
        then: {
          properties: {
            generated_provider_name: {
              type: 'string',
              title: 'Provider Name',
              enum: serviceProvidersEnumValues,
              enumNames: serviceProvidersEnumNames
            }
          },
          required: ['generated_provider_name']
        }
      },
      {
        required: ['generated_provider_name', 'generated_service_name']
      }
    ]
  } as RJSFSchema

  const uiSchema: UiSchema = {
    organization_id: { 'ui:widget': 'hidden' },
    'ui:order': [
      'generated_provider_name',
      'new_provider_name',
      'generated_service_name',
      'organization_id'
    ]
  }

  return (
    <>
      <IconButton
        onClick={handleClickOpen}
        color="primary"
        size="small"
        style={{ marginRight: '0.5rem' }}
      >
        <Icon name={IconType.Edit} />
      </IconButton>
      {providerName}
      <DialogModal
        open={editModalOpen}
        onClose={handleClose}
        dialogTitle="Edit Provider Name"
        dialogContent={
          <MuiForm
            schema={schema}
            onSubmit={onSubmit}
            formData={formData}
            extraErrors={extraErrors}
            uiSchema={uiSchema}
            validator={validator}
          >
            <FormsUpdateCancelButtons cancelButtonOnClick={handleClose} />
          </MuiForm>
        }
      />
    </>
  )
}
