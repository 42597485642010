import {
  PeriodSelection,
  ReportLevel
} from 'constants/formconfig/ArtifactConfigs'
import { CanaryStatusEnum } from 'lib/CloudCanariesRestfulAPI'
import { getQuartersPeriods } from 'services/ArtifactReportsPeriodAndDatesService'

export interface servicesByOrganization {
  totalCanaries: number
  totalErrors: number
  totalIncidents: number
  totalDowntime: number
  totalSLA: number
  totalCompliant: boolean
  services: {
    serviceName: string
    provider: string
    canaries: number
    errors: number
    meantimeBetweenErrors: number
    incidents: number
    meantimeBetweenIncidents: number
    downtime: number
    sla: number
    compliant: boolean
    startDate: string
  }[]
}

export interface canariesByOrganization {
  totalErrors: number
  totalIncidents: number
  totalDowntime: number
  totalDays: number
  canaries: {
    canaryName: string
    provider: string
    service: string
    type: string
    errors: number
    meantimeBetweenErrors: number
    incidents: number
    meantimeBetweenIncidents: number
    downtime: number
    startDate: string
    remainingDays: number
    status: CanaryStatusEnum
  }[]
}
export interface canariesByServices {
  serviceName: string
  totalErrors: number
  totalIncidents: number
  totalDowntime: number
  totalActiveDays: number
  canaries: {
    canaryName: string
    provider: string
    service: string
    type: string
    errors: number
    meantimeBetweenErrors: number
    incidents: number
    meantimeBetweenIncidents: number
    downtime: number
    startDate: string
    status: CanaryStatusEnum
  }[]
}

export declare type ReportData =
  | servicesByOrganization
  | canariesByOrganization
  | canariesByServices[]
  | undefined
export interface InitialFormData {
  report_name: string
  period_selection: string
  period: string | number
  report_level: number
}

export const initialFormData: InitialFormData = {
  report_name: ReportLevel[0],
  period_selection: PeriodSelection[1],
  period: getQuartersPeriods()[0],
  report_level: 0
}

export interface PDFFileData {
  formData: InitialFormData
  companyName: string
  organizationName: string
  reporterName: string
  reporterEmail: string
  reportStartDate: string
  reportEndDate: string
  periodEnded: boolean
  remainingDays: number
  servicesByOrganization?: ReportData
  canariesByOrganization?: ReportData
  canariesByServices?: ReportData
}

export const initialPDFFileData: PDFFileData = {
  formData: initialFormData,
  companyName: '',
  organizationName: '',
  reporterName: '',
  reporterEmail: '',
  reportStartDate: '',
  reportEndDate: '',
  periodEnded: true,
  remainingDays: 0
}
