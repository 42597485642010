import {
  selectOrganizationRequiredModalMessage,
  selectOrganizationRequiredModalOpen,
  setOrganizationRequiredModalOpen
} from 'client_side_state/slices/app'
import { useAppDispatch, useAppSelector } from 'client_side_state/store'
import Button from '@mui/material/Button'
import DialogContentText from '@mui/material/DialogContentText'
import DialogModal from 'components/Modals/DialogModal'

export enum OrganizationRequiredMessage {
  TemplateLibraryCreate = 'Please select an organization before you create a Canary Template Library',
  TemplateLibraryEdit = 'Please select an organization before you edit a Canary Template Library',
  TemplateLibraryAutoCanaryLoader = 'Please select an organization before you auto load canary templates',
  RestfulLibraryCreate = 'Please select an organization before you create a Custom Schema',
  CanaryCreate = 'Please select an organization before you create a Canary',
  ClusterCreate = 'Please select an organization before you create a Cluster',
  ClusterAssign = 'Please select an organization before you assign a Cluster',
  APIKeyCreate = 'Please select an organization before you create an API key',
  ArtifactReportGenerate = 'Please select an organization before you can generate a Artifact Report',
  ServiceSettingsGroupCreate = 'Please select an organization before you create a Service Settings Group',
  CanaryNotifications = 'Please select an organization before you access canary notifications',
  ServiceComplianceStatusColors = 'Please select an organization before you update Compliance Status Colors',
  ServiceHealthStatusColors = 'Please select an organization before you update Health Status Colors',
  WalletSettings = 'Please select an organization before you create Wallet Settings',
  SnowflakeForecastModels = 'Please select an organization before you create Snowflake Forecast Models'
}

export default function OrganizationRequiredDialog() {
  const dispatch = useAppDispatch()

  const open = useAppSelector(selectOrganizationRequiredModalOpen)
  const selectedMessage = useAppSelector(selectOrganizationRequiredModalMessage)

  const handleClose = () => {
    dispatch(setOrganizationRequiredModalOpen(false))
  }

  return (
    <DialogModal
      onClose={handleClose}
      open={open}
      fullWidth
      dialogTitle="Organization Required"
      dialogContent={
        <DialogContentText id="alert-dialog-description">
          {selectedMessage}
        </DialogContentText>
      }
      dialogActions={<Button onClick={handleClose}>OK</Button>}
    />
  )
}
