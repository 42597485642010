import styled from 'styled-components'

export const ButtonContainer = styled.button<{
  color: string
  $small: boolean
  $round: boolean
  disabled: boolean
}>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: ${({ $small }) => ($small ? '2px 5px' : '1px 10px')};
  margin: ${({ $small }) => ($small ? '0px 5px' : '0px 10px')};
  height: ${({ $small }) => ($small ? 24 : 32)}px;
  border-radius: ${({ $small, $round }) => (!$round ? 5 : $small ? 12 : 16)}px;

  background-color: ${({ color }) => color};
  border: none;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  box-shadow: ${({ $round }) =>
    $round ? '0px 1px 3px rgb(150, 150, 150);' : 'none'};

  font-size: ${({ $small }) => ($small ? 11 : 13)}pt;
  line-height: ${({ $small }) => ($small ? 20 : 30)}px;
  text-align: center;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  user-select: none;
  transition: filter 0.1s;

  &:hover {
    filter: brightness(${({ disabled }) => (disabled ? 100 : 96)}%);
  }

  &:active {
    filter: brightness(${({ disabled }) => (disabled ? 100 : 92)}%);
  }

  span {
    margin: 0 4px;
  }
`
