import useDocumentTitle from 'hooks/useDocumentTitle'
import { useHistory } from 'react-router-dom'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import Box from '@mui/system/Box'
import BackButton from 'components/common/BackButton'
import { PublicPageContainer } from 'styles/pages/common/Page.styled'

const useStyles = makeStyles({
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 0
  },
  dialogContent: {
    padding: '20px',
    paddingTop: 0,
    backgroundColor: 'white'
  },
  container: {
    padding: '1rem',
    margin: '0.5rem'
  },
  termsText: {
    padding: '0.25rem',
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: 1.5
  }
})

export default function PrivacyPolicy() {
  useDocumentTitle('Privacy Policy')
  const history = useHistory()
  const classes = useStyles()

  return (
    <PublicPageContainer>
      <DialogTitle className={classes.dialogTitle}>
        <h2>Privacy Policy</h2>
        <BackButton
          action={() => {
            history.goBack()
          }}
        />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container className={classes.container}>
          <Typography variant="h5">CLOUD CANARIES</Typography>
          <Box component="div" className={classes.termsText}>
            <br />
            <br />
            Protecting your private information is our priority. This Statement
            of Privacy applies to CloudCanaries.ai, and Cloud Canaries Inc and
            governs data collection and usage. For the purposes of this Privacy
            Policy, unless otherwise noted, all references to Cloud Canaries Inc
            include CloudCanaries.ai. The Cloud Canaries Inc website is a Cloud
            Canaries are low cost. lightweight nimble apps that test workloads
            on the Cloud and Internet to identify, notify, quantify,and predict
            risk. Cloud Canaries Inc. provides the only platform to migrate,
            create, deploy and manage canaries; as well as harvest data for
            analysis, visualization, notification, crowdsourcing, and export.
            site. By using the Cloud Canaries Inc website, you consent to the
            data practices described in this statement.
            <br />
            <br />
            <b>Collection of your Personal Information</b>
            <br />
            Tobetter provide you with products and services offered, Cloud
            Canaries Inc.may collect personally identifiable information, such
            as your:
            <ul>
              <li>First and Last Name</li>
              <li>E-mail Address</li>
              <li>Phone Number</li>
              <li>Employer</li>
              <li>Job Title</li>
            </ul>
            <br />
            We do not collect any personal information about you unless you
            voluntarily provide it to us.However, you may be required to provide
            certain personal information to us when you elect to use certain
            products or services. These may include: (a) registering for an
            account; (b) entering a sweepstakes or contest sponsored by usor one
            of our partners; (c) signing up for special offers from selected
            third parties; (d) sending us an email message; (e) submitting your
            credit card or other payment information when ordering and
            purchasing products and services. To wit, we will use your
            information for, but not limited to, communicating with you in
            relation to services and/or products you have requested from us. We
            also may gather additional personal or non-personal information in
            the future.
            <br />
            <br />
            <b>Use of your Personal Information</b>
            <br />
            Cloud Canaries Inc collects and uses your personal information to
            operate and deliver the services you have requested.
            <br />
            Cloud Canaries Inc may also use your personally identifiable
            information to inform you of other products or services available
            from Cloud Canaries Inc and its affiliates.
            <br />
            <br />
            <b>Sharing Information with Third Parties</b>
            <br />
            Cloud Canaries Inc does not sell, rent or lease its customer lists
            to third parties.
            <br />
            <br />
            Cloud Canaries Inc may share data with trusted partners to help
            perform statistical analysis, send you email or postal mail, provide
            customer support. or arrange for deliveries. All such third
            partiesare prohibited from using your personal information except to
            provide these services to Cloud Canaries Inc, and they are required
            to maintain the confidentiality of your information.
            <br />
            Cloud Canaries Inc may disclose your personal information, without
            notice, if required to do so by law or in the good faith belief that
            such action is necessary to: (a) conform to the edicts of the law or
            comply with legal process served on Cloud Canaries Inc or the site;
            (b) protect and defend the rights or property of Cloud Canaries Inc;
            and/or (c) act under exigent circumstances to protect the personal
            safety of users of Cloud Canaries Inc, or the public.
            <br />
            <br />
            <b>Tracking User Behavior</b>
            <br />
            Cloud Canaries Inc may keep track of the websites and pages our
            users visit within Cloud Canaries Inc, in order to determine what
            Cloud Canaries Inc services are the most popular. This data is used
            to deliver customized content and advertising within Cloud Canaries
            Inc to customers whose behavior indicates that they are interested
            in a particular subject area.
            <br />
            <br />
            <b>Automatically Collected Information</b>
            <br />
            Information about your computer hardware and software may be
            automatically collected by Cloud Canaries Inc. This information can
            include: your IP address, browser type, domain names, access times
            and referring website addresses. This information is used for the
            operation of the service, to maintain quality of the service, and to
            provide general statistics regarding use of the Cloud Canaries Inc
            website.
            <br />
            <br />
            <b>Right to Deletion</b>
            <br />
            Subject to certain exceptions set out below, on receipt of a
            verifiable request from you, we will:
            <ul>
              <li>Delete your personal information from our records; and</li>
              <li>
                Direct any service providers to delete your personal information
                from their records.
              </li>
            </ul>
            <br />
            Please note that we may not be able to comply with requests to
            delete your personal information if it is necessary to:
            <ul>
              <li>
                Complete the transaction for which the personal information was
                collected, fulfill the terms of a written warranty or product
                recall conducted in accordance with federal law, provide a good
                or service requested by you, or reasonably anticipated within
                the context of our ongoing business relationship with you, or
                otherwise perform a contract between you and us;
              </li>
              <li>
                Detect security incidents, protect against malicious, deceptive,
                fraudulent, or illegal activity; or prosecute those responsible
                for that activity;
              </li>
              <li>
                Debug to identify and repair errors that impair existing
                intended functionality;
              </li>
              <li>
                Exercise free speech, ensure the right of another consumer to
                exercise his or her right of free speech, or exercise another
                right provided for by law;
              </li>
              <li>
                Comply with the California Electronic Communications Privacy
                Act;
              </li>
              <li>
                Engage in public or peer-reviewed scientific, historical,
                orstatistical research in the public interest that adheres to
                all other applicable ethics and privacy laws, when our deletion
                of the information is likely to render impossible or seriously
                impair the achievement of such research, provided we have
                obtained your informed consent;
              </li>
              <li>
                Enable solely internal uses that are reasonably aligned with
                your expectations based on your relationship with us;
              </li>
              <li>Comply with an existing legal obligation; or</li>
              <li>
                Otherwise use your personal information, internally, in a lawful
                manner that is compatible with the context in which you provided
                the information.
              </li>
            </ul>
            <br />
            <br />
            <b>Children Under Thirteen</b>
            <br />
            Cloud Canaries Inc does not knowingly collect personally
            identifiable information from children under the age of thirteen. If
            you are under the age of thirteen, you must ask your parent or
            guardian for permission to use this website.
            <br />
            <br />
            <b>E-mail Communications</b>
            <br />
            From time to time, Cloud Canaries Inc may contact you via email for
            the purpose of providing announcements, promotional offers, alerts,
            confirmations, surveys, and/or other general communication.
            <br />
            <br />
            <b>Changes to this Statement</b>
            <br />
            Cloud Canaries Inc reserves the right to change this Privacy Policy
            from time to time. We will notify you about significant changes in
            the way we treat personal information by sending a notice tothe
            primary email address specified in your account, by placing a
            prominent notice on our website, and/or by updating any privacy
            information. Your continued use of the website and/or Services
            available after such modifications will constitute your: (a)
            acknowledgment of the modified Privacy Policy; and (b) agreement to
            abide and be bound by that Policy.
            <br />
            <br />
            <b>Contact Information</b>
            <br />
            Cloud Canaries Inc.welcomes your questions or comments regarding
            this Statement of Privacy. If you believe that Cloud Canaries Inc
            hasnot adhered to this Statement, please contact Cloud Canaries Inc
            at:
            <br />
            Cloud Canaries Inc
            <br />
            8 Merrill Street
            <br />
            Cambridge, Massachusetts 02139
            <br />
            <br />
            <b>Email Address:</b>
            <br />
            <a href="mailto:info@cloudcanaries.ai">Info@CloudCanaries.ai</a>
            <br />
            <br />
            <b>Telephone number:</b>
            <br />
            617-710-1533
            <br />
            <br />
            Effective as of July 15, 2023
            <br />
            <br />
            <br />
            <br />© {new Date().getFullYear()} Cloud Canaries Inc.
          </Box>
        </Grid>
      </DialogContent>
    </PublicPageContainer>
  )
}
