import useDocumentTitle from 'hooks/useDocumentTitle'
import { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import { AdminPagesTitles } from 'components/MyAdminPages/Constants'
import MyAdminPagesCommonHeader from 'components/MyAdminPages/MyAdminPagesCommonHeader'
import NoDataFound from 'components/MyAdminPages/NoDataFound'
import OrganizationEditModal from 'components/UserOrganization/OrganizationEditModal'
import UserOrganizationsCreate from 'components/UserOrganization/UserOrganizationsCreate'
import PagesSkeletonLoader from 'components/common/Loaders/PagesSkeletonLoader'
import { rowsPerPageOptions } from 'constants/AppConfig'
import { OrganizationsContext } from 'contexts/OrganizationsContext'
import adminPagesSharedStyles from 'pages/AdminPages/adminPagesSharedStyles'
import RoutesService from 'services/routes'

export default function UserOrganizationsList() {
  const classes = adminPagesSharedStyles()
  useDocumentTitle('Organizations')

  const {
    userOrganizations,
    onRowsPerPageChange,
    areUserOrganizationsLoading,
    fetchUserOrganizations,
    fetchAllOrganizations,
    handleChangePage,
    page,
    rowsPerPage,
    rowCount
  } = useContext(OrganizationsContext)

  useEffect(() => {
    fetchUserOrganizations()
  }, [fetchUserOrganizations])

  const fetchOrganizations = async () => {
    await fetchUserOrganizations()
    await fetchAllOrganizations()
  }

  return (
    <Container maxWidth="xl" className={classes.mainContainer}>
      <MyAdminPagesCommonHeader />
      <h1 className={classes.headerRow}>
        {AdminPagesTitles.UserOrganizations}
        <UserOrganizationsCreate afterSubmit={fetchOrganizations} />
      </h1>
      {areUserOrganizationsLoading ? (
        <PagesSkeletonLoader />
      ) : (
        <>
          <TableContainer component={Paper} elevation={3}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Members</TableCell>
                  <TableCell>API Keys</TableCell>
                  <TableCell>Template Libraries</TableCell>
                  <TableCell>Canaries</TableCell>
                  <TableCell>Max Active Canaries</TableCell>
                  <TableCell>Active Canaries</TableCell>
                  <TableCell>Usage (hours)</TableCell>
                  <TableCell>Remaining Usage (hours)</TableCell>
                  <TableCell>Usage (Percentage)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userOrganizations.length > 0 ? (
                  userOrganizations.map((organization) => {
                    const organizationRemainingHoursUsage =
                      168.0 - organization.usage_hour_count
                    return (
                      <TableRow key={organization.id}>
                        <TableCell>
                          <OrganizationEditModal organization={organization} />
                          <Link
                            aria-label="Go to Organization's management page"
                            to={RoutesService.userOrganizationManage(
                              organization.id
                            )}
                          >
                            {organization.name}
                          </Link>
                        </TableCell>
                        <TableCell>{organization.user_count}</TableCell>
                        <TableCell>{organization.api_key_count}</TableCell>
                        <TableCell>
                          {organization.template_library_count}
                        </TableCell>
                        <TableCell>{organization.canary_count}</TableCell>
                        <TableCell>
                          {organization.max_active_canaries}
                        </TableCell>
                        <TableCell>
                          {organization.get_organiztion_active_canaries_count}
                        </TableCell>
                        <TableCell>{organization.usage_hour_count}</TableCell>
                        <TableCell>{organizationRemainingHoursUsage}</TableCell>
                        <TableCell>{organization.usage_percentage} %</TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <NoDataFound pageName="Organizations" colSpan={7} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={rowCount}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </>
      )}
    </Container>
  )
}
