import { useContext, useState } from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/system/Box'
import { IChangeEvent } from '@rjsf/core'
import MuiForm from '@rjsf/mui'
import { RJSFSchema } from '@rjsf/utils'
import validator from '@rjsf/validator-ajv8'
import Icon, { IconType } from 'components/Icons'
import DialogModal from 'components/Modals/DialogModal'
import { configKubernetesCluster } from 'constants/formconfig/KubernetesCluster'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { ClustersContext } from 'contexts/ClustersContext'
import { OrganizationsContext } from 'contexts/OrganizationsContext'
import { KubernetesCluster } from 'lib/CloudCanariesRestfulAPI'

const schema = configKubernetesCluster.schema

interface UserOrganizationClusterEditModalProps {
  cluster: KubernetesCluster
}

export default function UserOrganizationClusterEditModal(
  props: UserOrganizationClusterEditModalProps
) {
  const { cluster } = props

  const { apiService } = useContext(APIServiceContext)
  const { organizationId } = useContext(OrganizationsContext)
  const { fetchUserClusters } = useContext(ClustersContext)

  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const onSubmit = async (
    data: IChangeEvent<any, RJSFSchema, any>,
    event: React.FormEvent<any>
  ) => {
    const name = JSON.parse(JSON.stringify(data.formData)).name

    const kubernetesCluster = {
      name
    } as KubernetesCluster

    const clusterId = cluster.id!
    try {
      await apiService
        .partialUpdateKubernetesCluster(
          clusterId,
          organizationId,
          kubernetesCluster
        )
        .then(async (json) => {
          handleClose()
          await fetchUserClusters()
        })
    } catch {
      handleClose()
      console.log('error contacting api')
    }
  }

  if (cluster.is_internal) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>{cluster.name}</Box>
    )
  }

  return (
    <div>
      <Button onClick={handleClickOpen} color={'primary'}>
        <Icon name={IconType.Edit} />
      </Button>
      {cluster.name}
      <DialogModal
        open={open}
        onClose={handleClose}
        dialogTitle="Edit Cluster Name"
        dialogContent={
          <MuiForm
            formData={cluster}
            schema={schema}
            onSubmit={onSubmit}
            validator={validator}
          />
        }
      />
    </div>
  )
}
