import { ReactNode } from 'react'
import { ButtonContainer } from 'styles/components/common/ButtonContainer.styled'

interface ButtonProps {
  label?: string
  leftIcon?: ReactNode
  rightIcon?: ReactNode
  backgroundColor?: string
  small?: boolean
  round?: boolean
  disabled?: boolean
  className?: string
  style?: { [property: string]: any }
  action?: () => void
}

export default function Button(props: ButtonProps) {
  const {
    label = '',
    action = () => {},
    leftIcon = '',
    rightIcon = '',
    backgroundColor = '#afd2ff',
    small = false,
    round = false,
    disabled = false,
    className = '',
    style
  } = props
  const _label = label ? <span>{label}</span> : null
  return (
    <ButtonContainer
      onClick={() => {
        if (!disabled) action()
      }}
      color={backgroundColor}
      $small={small}
      $round={round}
      disabled={disabled}
      className={className}
      style={style}
    >
      {leftIcon}
      {_label}
      {rightIcon}
    </ButtonContainer>
  )
}
