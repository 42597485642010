import {
  setOrganizationRequiredModalMessage,
  setOrganizationRequiredModalOpen
} from 'client_side_state/slices/app'
import { useAppDispatch } from 'client_side_state/store'
import { useContext } from 'react'
import AssessmentIcon from '@mui/icons-material/Assessment'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import MuiForm from '@rjsf/mui'
import validator from '@rjsf/validator-ajv8'
import modalsSharedStyles from 'components/DashboardsModals/modalsSharedStyles'
import newModalSharedSxProps from 'components/DashboardsModals/newModalSharedSxProps'
import DialogModal from 'components/Modals/DialogModal'
import OrganizationRequiredDialog, {
  OrganizationRequiredMessage
} from 'components/UserOrganization/OrganizationRequiredDialog'
import { canaryArtifactConfigs } from 'constants/formconfig/ArtifactConfigs'
import { ArtifactsContext } from 'contexts/ArtifactsContext'
import { OrganizationsContext } from 'contexts/OrganizationsContext'
import PDFViewerDialog from './PDFViewerDialog'

export default function ComplianceArtifactsModal() {
  const classes = modalsSharedStyles()

  const dispatch = useAppDispatch()

  const { organizationId, organization } = useContext(OrganizationsContext)
  const {
    artifactsDialogOpen,
    toggleArtifactsDialogOpen,
    selectedFormSchema,
    artifactFormData,
    viewPDFModalOpen,
    handleFormSubmit,
    handleFormChange
  } = useContext(ArtifactsContext)

  const handleClickOpen = () => {
    if (!organizationId) {
      dispatch(
        setOrganizationRequiredModalMessage(
          OrganizationRequiredMessage.ArtifactReportGenerate
        )
      )
      dispatch(setOrganizationRequiredModalOpen(true))
    } else {
      toggleArtifactsDialogOpen()
    }
  }

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        sx={{
          ...newModalSharedSxProps.mainButton,
          mr: 1.5
        }}
      >
        <AssessmentIcon fontSize="medium" style={{ marginRight: '0.25rem' }} />
        Artifacts
      </Button>
      {organization && organization.id ? (
        <DialogModal
          dialogTitle="Artifact Report"
          children={
            <DialogTitle
              sx={newModalSharedSxProps.dialogTitle}
              id={`modal-dialog-title`}
            >
              For {organization.name} Org
            </DialogTitle>
          }
          open={artifactsDialogOpen}
          onClose={toggleArtifactsDialogOpen}
          dialogContent={
            <MuiForm
              schema={selectedFormSchema}
              uiSchema={canaryArtifactConfigs.uiSchema}
              formData={artifactFormData}
              onSubmit={handleFormSubmit}
              onChange={handleFormChange}
              className={classes.artifactsForm}
              validator={validator}
            >
              <DialogActions sx={newModalSharedSxProps.formActionsButtons}>
                <Button onClick={toggleArtifactsDialogOpen} variant="outlined">
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  sx={newModalSharedSxProps.publishButton}
                  type="submit"
                >
                  Publish
                </Button>
              </DialogActions>
            </MuiForm>
          }
          extraContent={viewPDFModalOpen && <PDFViewerDialog />}
        />
      ) : (
        <OrganizationRequiredDialog />
      )}
    </>
  )
}
