import { Theme } from '@mui/material/styles'
import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles(({ palette, spacing, shadows }: Theme) =>
  createStyles({
    container: {
      padding: spacing(3),
      borderRadius: spacing(1),
      marginBottom: spacing(3),
      boxShadow: shadows[3]
    },
    section: {
      padding: spacing(2, 0)
    },
    label: {
      fontWeight: 'bold'
    },
    value: {
      color: palette.text.secondary,
      marginTop: spacing(1)
    },
    button: {
      marginTop: spacing(2)
    },
    loading: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: spacing(2)
    }
  })
)
