// import SwaggerEditor from 'swagger-editor-dist'
// import 'swagger-editor/dist/swagger-editor.css'
import SwaggerUI from 'swagger-ui-react'
import 'swagger-ui-react/swagger-ui.css'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
// import pick from 'lodash/pick'
import Box from '@mui/system/Box'
import { RJSFSchema } from '@rjsf/utils'
import DialogModal from 'components/Modals/DialogModal'
import BackButton from 'components/common/BackButton'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { GenericIDMatchProps } from 'interfaces/GenericIDMatchProps'

export default function CustomRestfulSDKSchemaEditor(
  props: GenericIDMatchProps
) {
  const {
    match: {
      params: { id: schemaId }
    }
  } = props

  const history = useHistory()
  const { apiService } = useContext(APIServiceContext)
  const [schema, setSchema] = useState<RJSFSchema | undefined>({} as RJSFSchema)
  const [schemaRetrieved, setSchemaRetrieved] = useState<boolean>(false)
  // let _window = window as any

  // const swaggerPluginToHideInfo = () => {
  //   return {
  //     components: {
  //       info: () => null
  //     }
  //   }
  // }

  // const swaggerPluginToHideOperations = () => {
  //   return {
  //     components: {
  //       operations: () => null
  //     }
  //   }
  // }

  const getschema = useCallback(async () => {
    if (schemaId) {
      await apiService.retrieveOpenAPIRestfulLibrary(schemaId).then((json) => {
        const data: RJSFSchema | undefined = json.data.processed_schema
        setSchema(data)
        setSchemaRetrieved(true)
        window.localStorage.setItem(
          'swagger-editor-content',
          JSON.stringify(data, null, 4)
        )
        // _window.editor = SwaggerEditor({
        //   dom_id: '#swagger-editor',
        //   layout: 'EditorLayout',
        //   plugins: [swaggerPluginToHideInfo]
        // })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiService, schemaId])

  useEffect(() => {
    getschema()
  }, [getschema])

  return schemaId && schemaRetrieved ? (
    <DialogModal
      fullScreen
      open={schemaRetrieved}
      onClose={() => history.goBack()}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          py: 3,
          px: 3
        }}
      >
        {/* <SwaggerUI
          spec={pick(schema, ['info', 'openapi'])}
          plugins={[swaggerPluginToHideOperations]}
        /> */}
        <BackButton />
      </Box>
      {/* <div id="swagger-editor"></div> */}

      <SwaggerUI spec={schema} />
    </DialogModal>
  ) : null
}
