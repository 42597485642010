import { useState } from 'react'
import IconButton from '@mui/material/IconButton'
import Icon, { IconType } from 'components/Icons'
import DialogModal from 'components/Modals/DialogModal'

interface Props {
  container_url: string
}

export default function ViewImageURLModal(props: Props) {
  const { container_url } = props
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <IconButton
        onClick={handleClickOpen}
        color="primary"
        size="small"
        style={{ marginRight: '0.5rem' }}
      >
        <Icon name={IconType.SearchRounded} />
      </IconButton>
      <DialogModal
        open={open}
        onClose={handleClose}
        dialogTitle="Container URL"
        dialogContent={container_url}
      />
    </>
  )
}
