import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    paperWidthSm: {
      maxWidth: 750
    },
    dialogContent: {
      padding: '1rem 1.5rem'
    },
    muiForm: {
      '& label.MuiFormLabel-root': {
        fontSize: '1rem'
      },
      '& span.MuiFormControlLabel-label': {
        fontSize: '1rem'
      },
      '& input[type="file" i]': {
        fontSize: '1rem'
      }
    }
  })
)
