import Tooltip from '@mui/material/Tooltip'
import { createStyles, makeStyles } from '@mui/styles'
import Box from '@mui/system/Box'
import CutomTooltipChip from 'components/common/CustomTooltipChip'
import { CanaryReadWriteMinimal } from 'lib/CloudCanariesRestfulAPI'
import { getMappedAlarmTypes } from 'services/utils'

const useStyles = makeStyles(() =>
  createStyles({
    tooltip: {
      backgroundColor: 'transparent !important'
    },
    tooltipPopper: { marginTop: '-6px !important' }
  })
)

interface AlarmingColumnProps {
  canary: CanaryReadWriteMinimal
}

export default function AlarmingColumn(props: AlarmingColumnProps) {
  const classes = useStyles()
  const { canary } = props

  const chipLabel = getMappedAlarmTypes(canary.canary_alarms_alarming)

  return canary.has_alarms_alarming ? (
    chipLabel && chipLabel.length > 1 ? (
      <Tooltip
        title={
          <CutomTooltipChip
            chipLabel={
              chipLabel?.length > 1 ? chipLabel.splice(0, 1) : chipLabel
            }
          />
        }
        arrow
        classes={{
          tooltip: classes.tooltip,
          popper: classes.tooltipPopper
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: chipLabel.some((label) =>
              label.includes('Forecast')
            )
              ? '#ff9831'
              : 'unset'
          }}
        >
          {chipLabel[0]} +
        </Box>
      </Tooltip>
    ) : (
      <Box
        sx={{
          borderRadius: 1,
          p: 0.25,
          backgroundColor: chipLabel!.some((label) =>
            label.includes('Forecast')
          )
            ? '#ff9831'
            : 'unset'
        }}
      >
        {chipLabel![0]}
      </Box>
    )
  ) : (
    <>OK</>
  )
}
