import { useContext, useState } from 'react'
import Button from '@mui/material/Button'
import DialogContentText from '@mui/material/DialogContentText'
import IconButton from '@mui/material/IconButton'
import Icon, { IconType } from 'components/Icons'
import DialogModal from 'components/Modals/DialogModal'
import { APIServiceContext } from 'contexts/APIServiceContext'

interface DeleteNotificationConfirmationDialogProps {
  notificationId: string
  onClose: () => void
}

export default function DeleteNotificationConfirmationDialog(
  props: DeleteNotificationConfirmationDialogProps
) {
  const { notificationId, onClose } = props

  const { apiService } = useContext(APIServiceContext)

  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = async () => {
    const id = notificationId
    try {
      await apiService.destroyNotification(id)
      onClose()
    } catch {
      console.log('error contacting api')
    }
    handleClose()
    return false
  }

  return (
    <>
      <IconButton size="small" onClick={handleClickOpen}>
        <Icon name={IconType.Trash} color="error" />
      </IconButton>
      <DialogModal
        onClose={handleClose}
        open={open}
        fullWidth
        dialogTitle="Delete this notification?"
        dialogContent={
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this notification?
          </DialogContentText>
        }
        dialogActions={
          <>
            <Button onClick={handleClose}>No</Button>
            <Button onClick={handleSubmit} autoFocus>
              Yes
            </Button>
          </>
        }
      />
    </>
  )
}
