import { useRef } from 'react'
import MuiForm from '@rjsf/mui'
import validator from '@rjsf/validator-ajv8'
import BackButton from 'components/common/BackButton'
import SubmitButton from 'components/common/FormsCreateButton'
import useOutsideAlerter from 'components/common/outsideAlerter'
import { configResetPassword } from 'constants/formconfig/ResetPassword'
import { ResetPasswordStyle } from 'styles/pages/User/ResetPasswordModal.styled'

interface ResetPasswordModalProps {
  showModal: boolean
  setShowModal: (value: boolean) => void
}

export default function ResetPasswordModal(props: ResetPasswordModalProps) {
  const { showModal, setShowModal } = props
  const resetPasswordSchema = configResetPassword.schema

  const outsideClickRef = useRef(null)
  useOutsideAlerter(outsideClickRef, () => setShowModal(false))

  if (showModal) {
    return (
      <ResetPasswordStyle>
        <div className="Background" />
        <div className="Spread">
          <div className="Content" ref={outsideClickRef}>
            <div className="closeBtn">
              <BackButton action={() => setShowModal(false)} />
            </div>
            <MuiForm schema={resetPasswordSchema} validator={validator}>
              <SubmitButton>Submit</SubmitButton>
            </MuiForm>
          </div>
        </div>
      </ResetPasswordStyle>
    )
  } else {
    return <></>
  }
}
