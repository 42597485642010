import {
  retrieveAuthUserProfile,
  userImpersonateSetup,
  userLogout
} from 'client_side_state/slices/auth'
import type { RootState } from 'client_side_state/store'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import {
  Organization,
  User,
  UserOrganization
} from 'lib/CloudCanariesRestfulAPI'

export interface UserState {
  profile: User
  organization: Organization | null
  userOrganization: UserOrganization | null
  lastSelectedOrganization: UserOrganization | null
  organizations: UserOrganization[]
  allOrganizations: UserOrganization[]
}

export const initialState: UserState = {
  profile: {} as User,
  organization: {} as Organization,
  userOrganization: {} as UserOrganization,
  lastSelectedOrganization: {} as UserOrganization,
  organizations: [],
  allOrganizations: []
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetUserState: (state) => {
      state.profile = {} as User
      state.organization = {} as Organization
      state.organizations = []
    },
    setOrganizations: (
      state,
      action: PayloadAction<UserOrganization[] | []>
    ) => {
      state.organizations = action.payload
    },
    setAllOrganizations: (
      state,
      action: PayloadAction<UserOrganization[] | []>
    ) => {
      state.allOrganizations = action.payload
    },
    setSelectedUserOrganization: (
      state,
      action: PayloadAction<Organization | null>
    ) => {
      state.organization = action.payload
      const userOrganization = state.organizations.find(
        (org) => org.id === action.payload?.id
      )
      if (userOrganization) {
        state.userOrganization = userOrganization
      } else {
        state.userOrganization = null
      }
    },
    setLastSelectedOrganization: (
      state,
      action: PayloadAction<Organization | null>
    ) => {
      state.organization = action.payload
      const userOrganization = state.organizations.find(
        (org) => org.id === action.payload?.id
      )
      if (userOrganization) {
        state.lastSelectedOrganization = userOrganization
      } else {
        state.lastSelectedOrganization = null
      }
    },
    clearUserOrganization: (state) => {
      state.organization = {} as Organization
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      retrieveAuthUserProfile.fulfilled,
      (state, action: PayloadAction<User>) => {
        state.profile = action.payload
      }
    )
    // builder.addCase(postLoginRequest.rejected, (state, action) => {
    //   toast.error(ToastErrors.WRONG_AUTH_CREDENTIALS, {
    //     position: 'top-right'
    //   })
    // })
    builder.addCase(userLogout, (state, action) => {
      state.profile = {} as User
      state.organization = {} as Organization
      state.organizations = []
      state.userOrganization = {} as UserOrganization
      state.lastSelectedOrganization = {} as UserOrganization
      state.allOrganizations = []
    })
    builder.addCase(userImpersonateSetup, (state, action) => {
      state.profile = {} as User
      state.organization = {} as Organization
      state.organizations = []
      state.userOrganization = {} as UserOrganization
      state.lastSelectedOrganization = {} as UserOrganization
      state.allOrganizations = []
    })
  }
})

export const {
  resetUserState,
  setOrganizations,
  setAllOrganizations,
  setSelectedUserOrganization,
  setLastSelectedOrganization,
  clearUserOrganization
} = userSlice.actions

export const selectOrganizationID = (state: RootState) =>
  state.user.organization ? state.user.organization.id : undefined

export const selectOrganizations = (state: RootState) =>
  state.user.organizations

export const selectAllOrganizations = (state: RootState) =>
  state.user.allOrganizations

export const selectUserOrganization = (state: RootState) =>
  state.user.userOrganization

export const selectLastSeletedOrganization = (state: RootState) =>
  state.user.lastSelectedOrganization

export const selectOrganization = (state: RootState) => state.user.organization

export const selectUserProfile = (state: RootState) => state.user.profile

export const selectUserIsStaff = (state: RootState) =>
  state.user.profile.is_staff || false

export default userSlice.reducer
