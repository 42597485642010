import {
  Ref,
  createRef,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { APIServiceContext } from 'contexts/APIServiceContext'
import {
  CanaryPrototypeSource,
  CanaryRestfulLibSourceFile
} from 'lib/CloudCanariesRestfulAPI'

export enum SourceFileType {
  RestfulLib,
  CanaryTaskPrototype
}

interface SourceFileEditorProps {
  sourceFileType: SourceFileType
  schemaId: string
  codeFileID: string
  saving?: boolean
  setSaving: (value: boolean) => void
  setHasUpdate: (value: boolean) => void
}

export default function SourceFileEditor(props: SourceFileEditorProps) {
  const {
    sourceFileType,
    schemaId,
    codeFileID,
    setHasUpdate,
    saving,
    setSaving
  } = props

  const { apiService } = useContext(APIServiceContext)

  const [codeString, setCodeString] = useState<string>('')
  const [sourceFile, setSourceFile] = useState<
    CanaryRestfulLibSourceFile | CanaryPrototypeSource
  >({})
  const ref: Ref<any> = createRef()

  const getSourceFile = useCallback(async () => {
    if (codeFileID) {
      if (sourceFileType === SourceFileType.RestfulLib) {
        await apiService
          .retrieveCanaryRestfulLibSourceCode(codeFileID)
          .then((json) => {
            setSourceFile(json.data ?? {})
            setCodeString(json.data.file_contents ?? '')
          })
      } else if (sourceFileType === SourceFileType.CanaryTaskPrototype) {
        await apiService
          .retrieveCanaryPrototypeSourceCode(codeFileID)
          .then((json) => {
            setSourceFile(json.data ?? {})
            setCodeString(json.data.file_contents ?? '')
          })
      }
    }
  }, [apiService, codeFileID, sourceFileType])

  useEffect(() => {
    getSourceFile()
  }, [getSourceFile])

  useEffect(() => {
    if (saving) {
      setSaving(false)
      setHasUpdate(false)

      onSubmit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saving])

  const onSubmit = async () => {
    if (ref && ref.current) {
      const editedFile = { file_contents: ref.current.innerText }
      if (sourceFileType === SourceFileType.RestfulLib) {
        await apiService.partialUpdateCanaryRestfulLibSourceCode(
          codeFileID,
          schemaId,
          editedFile
        )
      } else if (sourceFileType === SourceFileType.CanaryTaskPrototype) {
        await apiService.partialUpdateCanaryPrototypeSourceCode(
          codeFileID,
          schemaId,
          editedFile
        )
      }
    }
  }

  const onHandleInput = () => {
    if (ref && ref.current) {
      if (codeString !== ref.current.innerText) {
        setHasUpdate(true)
      } else {
        setHasUpdate(false)
      }
    }
  }

  return codeFileID ? (
    <div className={'filecontents'}>
      <div className={'filecontents-controls'}>
        <h2>{sourceFile.relative_path}</h2>
      </div>
      <div ref={ref} onInput={onHandleInput} className={'filecontents-wrapper'}>
        <SyntaxHighlighter
          language="python"
          showLineNumbers={false}
          contentEditable={true}
          suppressContentEditableWarning={true}
        >
          {codeString}
        </SyntaxHighlighter>
      </div>
    </div>
  ) : (
    <div className={'filecontents'}>
      <h3>Select a file to continue</h3>
    </div>
  )
}
