import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import SharedStyles from './SharedStyles'

export default function UserPlansDetailsSkeletonLoader() {
  const classes = SharedStyles()
  return (
    <Box className={classes.loadingSkeletons} sx={{ pb: 4, pt: 2 }}>
      {Array.from([1, 2]).map((element) => (
        <Skeleton
          key={element}
          variant="rectangular"
          width="100%"
          height={350}
          sx={{
            my: 3,
            mx: 0,
            borderRadius: 2,
            backgroundColor: 'rgba(0, 0, 0, 0.08)'
          }}
        />
      ))}
    </Box>
  )
}
