import { FormConfigTypes } from './FormConfigsTypes'

export const configLogin: FormConfigTypes = {
  schema: {
    type: 'object',
    required: ['email', 'password'],
    properties: {
      email: { type: 'string', title: 'Email' },
      password: { type: 'string', title: 'Password' }
    }
  },
  uiSchema: {
    password: {
      'ui:widget': 'password'
    }
  }
}
