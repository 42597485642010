import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles(() =>
  createStyles({
    muiForm: {
      '& textarea.MuiInputBase-input': {
        // minWidth: 500
      }
    }
  })
)
