import { useContext } from 'react'
import Button from '@mui/material/Button'
import { makeStyles } from '@mui/styles'
import { APIServiceContext } from 'contexts/APIServiceContext'
import DateTimeService from 'services/DateTimeService'

const useStyles = makeStyles({
  exportBtn: {
    background: 'cyan !important',
    border: 'cyan !important',
    color: 'black !important',
    fontWeight: 700,
    marginInline: 8,
    paddingTop: 8,
    paddingBottom: 8
  }
})

const defaultDateTimes = DateTimeService.getForecastDefaultDateTimes()
const dateStart = defaultDateTimes.dateStart
const dateStop = defaultDateTimes.dateStop

interface ExportCanaryDailyForecastButtonProps {
  canaryId: string
}

export default function ExportCanaryDailyForecastButton(
  props: ExportCanaryDailyForecastButtonProps
) {
  const { canaryId } = props
  const classes = useStyles()

  const { apiService } = useContext(APIServiceContext)

  async function exportCanaryDailyForecastData() {
    await apiService
      .listNeuralProphetCanaryDailyForecastDataExports(
        dateStart,
        dateStop,
        canaryId
      )
      .then((response) => {
        if (response) {
          const blob = new Blob([response.data.toString()], {
            type: 'text/csv;charset=utf-8;'
          })
          const link = document.createElement('a')
          const url = URL.createObjectURL(blob)
          link.setAttribute('href', url)
          link.setAttribute('download', `export.csv`)
          link.style.visibility = 'hidden'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      })
  }

  return (
    <Button
      variant="outlined"
      size="small"
      className={classes.exportBtn}
      onClick={() => exportCanaryDailyForecastData()}
    >
      Export
    </Button>
  )
}
