import { selectUserProfile } from 'client_side_state/slices/user'
import { useAppSelector } from 'client_side_state/store'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { createStyles, makeStyles } from '@mui/styles'
import AsOfDateTimeSpan from 'components/common/AsOfDateTimeSpan'

export const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    companyName: {
      fontSize: '1.75rem',
      fontWeight: 700,
      letterSpacing: '0.02em'
    },
    pageName: {
      fontSize: '1.5rem',
      fontWeight: 700,
      letterSpacing: '0.02em',
      margin: '1rem 0 1.5rem',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'end'
    },
    subHeader: {
      fontSize: '0.9rem',
      fontWeight: 400,
      color: '#00000099',
      marginLeft: '0.5rem'
    }
  })
)
interface DashboardsCommonHeaderProps {
  pageName: string
  subHeader?: string
}

export default function DashboardsCommonHeader(
  props: DashboardsCommonHeaderProps
) {
  const classes = useStyles()

  const { pageName, subHeader } = props

  const userData = useAppSelector(selectUserProfile)
  return (
    <Grid container justifyContent="center">
      <Typography variant="h1" align="center" className={classes.companyName}>
        {userData?.company_name || 'Company Name'}
      </Typography>
      <Grid container className={classes.container}>
        <Grid item xs={12} md={3}>
          <Typography variant="h1" className={classes.pageName}>
            {pageName}{' '}
            {subHeader && (
              <Typography variant="body1" className={classes.subHeader}>
                {subHeader}
              </Typography>
            )}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={2}
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <AsOfDateTimeSpan />
        </Grid>
      </Grid>
    </Grid>
  )
}
