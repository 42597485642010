import {
  selectCardId,
  setSelectedCardId
} from 'client_side_state/slices/forecastPage'
import {
  selectServiceProviderSelected,
  setServiceProviderOptions
} from 'client_side_state/slices/pageFilter'
import { useAppDispatch, useAppSelector } from 'client_side_state/store'
import useDocumentTitle from 'hooks/useDocumentTitle'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Grid from '@mui/material/Grid'
import uniqueId from 'lodash/uniqueId'
import DashboardsCommonHeader from 'components/Dashboards/DashboardsCommonHeader'
import CardDailyCompositeForecast from 'components/Forecast/CardDailyCompositeForecast'
import DailyServiceForecastsSection from 'components/Forecast/DailyServiceForecastsSection'
import NoCompositeForcastEmptyGrid from 'components/Forecast/NoCompositeForcastEmptyGrid'
import ForecastDashboardLoader from 'components/common/Loaders/ForecastDashboardLoader'
import PageFilterServiceProvider from 'components/common/PageFilterServiceProvider'
import { queryParamPageSizeJumbo } from 'constants/AppConfig'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { OrganizationsContext } from 'contexts/OrganizationsContext'
import {
  NeuralCompositeDailyForecast,
  NeuralServiceDailyForecast,
  SnowflakeCompositeDailyForecast,
  SnowflakeServiceDailyForecast
} from 'lib/CloudCanariesRestfulAPI'
import DateTimeService from 'services/DateTimeService'
import { deDuplicateServiceProviderOptions } from 'services/utils'
import { DashboardContainer } from 'styles/components/CanaryDashboard/DashboardContainer.styled'

export default function DashboardServicesForecast() {
  useDocumentTitle('Service Forecast Dashboard')
  const defaultDateTimes = DateTimeService.getForecastDefaultDateTimes()
  const dateStart = defaultDateTimes.dateStart
  const dateStop = defaultDateTimes.dateStop

  const dispatch = useAppDispatch()

  const selectedProviderID = useAppSelector(selectServiceProviderSelected)

  const selectedCardId = useAppSelector(selectCardId)

  const { apiService } = useContext(APIServiceContext)

  const { organization, allOrganizations, organizationId } =
    useContext(OrganizationsContext)

  const [areServiceForecastLoading, setAreServiceForecastLoading] =
    useState<boolean>(true)
  const [selectedForecastDate, setSelectedForecastDate] =
    useState<string>(selectedCardId)

  const [neuralServiceDailyForecasts, setNeuralServiceDailyForecasts] =
    useState<NeuralServiceDailyForecast[]>([])
  const [neuralCompositeDailyForecasts, setNeuralCompositeDailyForecasts] =
    useState<NeuralCompositeDailyForecast[]>([])

  const isUsingSnowflakeCortex = useMemo<boolean>(
    () => organization?.use_cortex_forecast_model!,
    [organization?.use_cortex_forecast_model]
  )
  const [areSnowflakeForecastsLoading, setAreSnowflakeForecastsLoading] =
    useState<boolean>(true)
  const [snowflakeServiceDailyForecasts, setSnowflakeServiceDailyForecasts] =
    useState<SnowflakeServiceDailyForecast[]>([])
  const [
    snowflakeCompositeDailyForecasts,
    setSnowflakeCompositeDailyForecasts
  ] = useState<SnowflakeCompositeDailyForecast[]>([])

  const fetchNeuralForecastData = useCallback(async () => {
    if (!isUsingSnowflakeCortex) {
      setAreSnowflakeForecastsLoading(false)
      setAreServiceForecastLoading(true)
      await Promise.all([
        apiService.listNeuralProphetServiceDailyForecasts(
          1,
          queryParamPageSizeJumbo,
          dateStart,
          dateStop,
          organizationId
        ),
        apiService.listNeuralProphetCompositeDailyForecasts(
          1,
          queryParamPageSizeJumbo,
          dateStart,
          dateStop,
          organizationId
        )
      ]).then(([neuralServiceDailyForecast, neuralCompositeDailyForecast]) => {
        setNeuralServiceDailyForecasts(
          neuralServiceDailyForecast.data.results ?? []
        )
        setNeuralCompositeDailyForecasts(
          neuralCompositeDailyForecast.data.results ?? []
        )
        setAreServiceForecastLoading(false)
      })
    }
  }, [apiService, dateStart, dateStop, isUsingSnowflakeCortex, organizationId])

  const fetchSnowflakeForecasts = useCallback(async () => {
    if (isUsingSnowflakeCortex) {
      setAreServiceForecastLoading(false)
      setAreSnowflakeForecastsLoading(true)
      await Promise.all([
        apiService.listSnowflakeServiceDailyForecasts(
          1,
          queryParamPageSizeJumbo,
          dateStart,
          dateStop,
          organizationId
        ),
        apiService.listSnowflakeCompositeDailyForecasts(
          1,
          queryParamPageSizeJumbo,
          dateStart,
          dateStop,
          organizationId
        )
      ]).then(
        ([snowflakeServiceDailyForecast, snowflakeCompositeDailyForecast]) => {
          setSnowflakeServiceDailyForecasts(
            snowflakeServiceDailyForecast.data.results ?? []
          )
          setSnowflakeCompositeDailyForecasts(
            snowflakeCompositeDailyForecast.data.results ?? []
          )
          setAreSnowflakeForecastsLoading(false)
        }
      )
    }
  }, [apiService, dateStart, dateStop, isUsingSnowflakeCortex, organizationId])

  useEffect(() => {
    setSelectedForecastDate('')
  }, [organizationId])

  useEffect(() => {
    setSelectedForecastDate('')
  }, [selectedProviderID])

  useEffect(() => {
    setSelectedForecastDate(selectedCardId)
  }, [selectedCardId])

  useEffect(() => {
    fetchNeuralForecastData()
  }, [fetchNeuralForecastData])

  useEffect(() => {
    fetchSnowflakeForecasts()
  }, [fetchSnowflakeForecasts])

  useEffect(() => {
    if (!isUsingSnowflakeCortex) {
      const providerOptions = deDuplicateServiceProviderOptions(
        neuralServiceDailyForecasts
      )
      dispatch(setServiceProviderOptions(providerOptions))
    } else {
      const providerOptions = deDuplicateServiceProviderOptions(
        snowflakeServiceDailyForecasts
      )
      dispatch(setServiceProviderOptions(providerOptions))
    }
  }, [
    dispatch,
    isUsingSnowflakeCortex,
    neuralServiceDailyForecasts,
    snowflakeServiceDailyForecasts
  ])

  const filteredServiceDailyForecasts = useMemo(() => {
    if (!isUsingSnowflakeCortex) {
      let _filteredServiceDailyForecasts = neuralServiceDailyForecasts

      if (selectedForecastDate) {
        _filteredServiceDailyForecasts = _filteredServiceDailyForecasts.filter(
          (o) => o.for_date === selectedForecastDate
        )
      }

      if (selectedProviderID) {
        _filteredServiceDailyForecasts = _filteredServiceDailyForecasts.filter(
          (o) => o.provider_id === selectedProviderID
        )
      }

      return _filteredServiceDailyForecasts
    } else {
      let _filteredServiceDailyForecasts = snowflakeServiceDailyForecasts

      if (selectedForecastDate) {
        _filteredServiceDailyForecasts = _filteredServiceDailyForecasts.filter(
          (o) => o.for_date === selectedForecastDate
        )
      }

      if (selectedProviderID) {
        _filteredServiceDailyForecasts = _filteredServiceDailyForecasts.filter(
          (o) => o.provider_id === selectedProviderID
        )
      }

      return _filteredServiceDailyForecasts
    }
  }, [
    isUsingSnowflakeCortex,
    neuralServiceDailyForecasts,
    selectedForecastDate,
    selectedProviderID,
    snowflakeServiceDailyForecasts
  ])

  let dateDays = useMemo(() => {
    return new Map<string, string>()
  }, [])
  let _dates = new Array<string>()

  if (!isUsingSnowflakeCortex) {
    neuralServiceDailyForecasts.forEach((forecast) => {
      if (forecast.for_date && forecast.day_of_week) {
        dateDays.set(forecast.for_date, forecast.day_of_week)
        _dates.push(forecast.for_date)
      }
    })
  } else {
    snowflakeServiceDailyForecasts.forEach((forecast) => {
      if (forecast.for_date && forecast.day_of_week) {
        dateDays.set(forecast.for_date, forecast.day_of_week)
        _dates.push(forecast.for_date)
      }
    })
  }

  const datesSet = new Set(_dates)
  const dates = Array.from(datesSet.values())

  const compositeDailyForecastInfos = useMemo(() => {
    if (!isUsingSnowflakeCortex) {
      let result = []
      for (let dateOfForecast of dates) {
        const dayOfWeek = dateDays.get(dateOfForecast)
        const compositeForecasts = neuralCompositeDailyForecasts.filter((f) => {
          return f.for_date === dateOfForecast
        })
        const hasPrediction =
          compositeForecasts &&
          compositeForecasts.some(
            (compositeForecast) => compositeForecast.has_prediction
          )
            ? true
            : false
        const predictableCompositeForecastsLength = compositeForecasts.filter(
          (obj) => obj.has_prediction === true
        )
        const aggregateChanceOfIncident =
          compositeForecasts &&
          Number(
            (
              compositeForecasts.reduce(
                (sum, obj) => sum + obj.percentage_chance_of_incident!,
                0
              ) / predictableCompositeForecastsLength.length
            ).toFixed(2)
          )
        const resultedAggregateChanceOfIncident = isNaN(
          aggregateChanceOfIncident
        )
          ? 0
          : aggregateChanceOfIncident
        if (dayOfWeek) {
          result.push({
            dateOfForecast,
            dayOfWeek,
            resultedAggregateChanceOfIncident,
            hasPrediction
          })
        }
      }
      return result
    } else {
      let result = []
      for (let dateOfForecast of dates) {
        const dayOfWeek = dateDays.get(dateOfForecast)
        const compositeForecasts = snowflakeCompositeDailyForecasts.filter(
          (f) => {
            return f.for_date === dateOfForecast
          }
        )
        const hasPrediction =
          compositeForecasts &&
          compositeForecasts.some(
            (compositeForecast) => compositeForecast.has_prediction
          )
            ? true
            : false
        const predictableCompositeForecastsLength = compositeForecasts.filter(
          (obj) => obj.has_prediction === true
        )
        const aggregateChanceOfIncident =
          compositeForecasts &&
          Number(
            (
              compositeForecasts.reduce(
                (sum, obj) => sum + obj.percentage_chance_of_incident!,
                0
              ) / predictableCompositeForecastsLength.length
            ).toFixed(2)
          )
        const resultedAggregateChanceOfIncident = isNaN(
          aggregateChanceOfIncident
        )
          ? 0
          : aggregateChanceOfIncident
        if (dayOfWeek) {
          result.push({
            dateOfForecast,
            dayOfWeek,
            resultedAggregateChanceOfIncident,
            hasPrediction
          })
        }
      }
      return result
    }
  }, [
    dateDays,
    dates,
    isUsingSnowflakeCortex,
    neuralCompositeDailyForecasts,
    snowflakeCompositeDailyForecasts
  ])

  const generatedModel = useMemo<string>(() => {
    if (organizationId) {
      if (isUsingSnowflakeCortex) {
        return 'Cortex AI Generated'
      }
    } else if (
      allOrganizations.some(
        (organization) => organization.use_cortex_forecast_model
      )
    ) {
      return 'Aviary and Cortex AI Generated'
    }
    return 'Aviary AI Generated'
  }, [organizationId, allOrganizations, isUsingSnowflakeCortex])

  return (
    <DashboardContainer>
      {(
        isUsingSnowflakeCortex
          ? areSnowflakeForecastsLoading
          : areServiceForecastLoading
      ) ? (
        <ForecastDashboardLoader />
      ) : (
        <>
          <DashboardsCommonHeader
            pageName="Composite Forecast"
            subHeader={generatedModel}
          />
          <Grid
            container
            spacing={2}
            style={{
              marginBottom: '1.5rem',
              justifyContent: 'space-between'
            }}
          >
            <Grid item xs={12} md={2}>
              <PageFilterServiceProvider />
            </Grid>
            <Grid item xs={12} md={8}>
              <h4 style={{ textAlign: 'center' }}>
                {dateStart} To {dateStop}
              </h4>
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            />
          </Grid>
          <Grid container spacing={2}>
            {compositeDailyForecastInfos.length <= 0 ? (
              <NoCompositeForcastEmptyGrid
                compositeDailyForecasts={
                  isUsingSnowflakeCortex
                    ? snowflakeCompositeDailyForecasts
                    : neuralCompositeDailyForecasts
                }
              />
            ) : (
              compositeDailyForecastInfos.map((compositeDailyForecastInfo) => {
                return (
                  <Grid
                    key={uniqueId(compositeDailyForecastInfo.dateOfForecast)}
                    item
                    style={{
                      width: '300px',
                      marginBottom: '24px'
                    }}
                  >
                    <CardDailyCompositeForecast
                      selected={
                        selectedForecastDate ===
                        compositeDailyForecastInfo.dateOfForecast
                      }
                      handleCardClick={() => {
                        if (
                          selectedForecastDate ===
                          compositeDailyForecastInfo.dateOfForecast
                        ) {
                          setSelectedForecastDate('')
                          dispatch(setSelectedCardId(''))
                        } else {
                          setSelectedForecastDate(
                            compositeDailyForecastInfo.dateOfForecast
                          )
                          dispatch(
                            setSelectedCardId(
                              compositeDailyForecastInfo.dateOfForecast
                            )
                          )
                        }
                      }}
                      dayOfWeek={compositeDailyForecastInfo.dayOfWeek}
                      dateOfForecast={compositeDailyForecastInfo.dateOfForecast}
                      aggregateChanceOfIncident={
                        compositeDailyForecastInfo.resultedAggregateChanceOfIncident
                      }
                      selectedProviderId={selectedProviderID}
                      hasPrediction={compositeDailyForecastInfo.hasPrediction}
                    />
                  </Grid>
                )
              })
            )}
          </Grid>
          {selectedForecastDate ? (
            <DailyServiceForecastsSection
              dayOfWeek={selectedForecastDate}
              serviceDailyForecasts={filteredServiceDailyForecasts}
              selectedProviderId={selectedProviderID}
            />
          ) : null}
        </>
      )}
    </DashboardContainer>
  )
}
