import styled from 'styled-components'
import Button from '@mui/material/Button'

export const CreateBtn = styled(Button)`
  background: #dfedf7;
  color: black;
  padding: 6px 16px;
  font-weight: 700;
  letter-spacing: 1px;
  margin-left: 1rem;
  &:hover {
    background: #3d89ff;
    color: white;
    opacity: 0.8;
  }
`
