import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { makeStyles } from '@mui/styles'
import { FuturePeriodOption } from 'services/FuturePeriodService'

const useStyles = makeStyles({
  formControl: {
    marginInline: 8
  },
  select: {
    color: '#3d89ff',
    backgroundColor: '#fff',
    textAlign: 'center',
    borderRadius: 4,
    border: '1px solid #3d89ff',
    paddingTop: 7.5,
    paddingBottom: 7.5,
    '&:focus': {
      borderRadius: 4
    }
  },
  outlinedSelect: {},
  icon: {
    color: '#3d89ff'
  }
})

interface FuturePeriodSelectionProps {
  selectedFuturePeriod: FuturePeriodOption
  handleFuturePeriodChange: (event: SelectChangeEvent) => void
  futurePeriodsOptions: FuturePeriodOption[]
}

export default function FuturePeriodSelection(
  props: FuturePeriodSelectionProps
) {
  const classes = useStyles()
  const {
    selectedFuturePeriod,
    handleFuturePeriodChange,
    futurePeriodsOptions
  } = props
  return (
    <FormControl className={classes.formControl} size="small">
      <Select
        labelId="last-periods-select-helper-label"
        id="last-periods-select-helper"
        value={selectedFuturePeriod.value}
        onChange={handleFuturePeriodChange}
        classes={{
          select: classes.select,
          icon: classes.icon
        }}
        inputProps={{ 'aria-label': 'Time Period', disableunderline: 'true' }}
      >
        {futurePeriodsOptions.map((period) => (
          <MenuItem key={period.value} value={period.value}>
            {period.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
