import {
  ChangeEvent,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { SnowflakeForecastModel } from 'lib/CloudCanariesRestfulAPI'
import { APIServiceContext } from './APIServiceContext'
import { OrganizationsContext } from './OrganizationsContext'

interface SnowflakeForecastModelsContextProviderProps {
  children: ReactNode
}

interface SnowflakeForecastModelsContextProps {
  areForecastModelsLoading: boolean
  forecastModels: SnowflakeForecastModel[] | null
  rowsPerPage: number
  page: number
  rowCount: number
  selectedForecastModelsIds: readonly string[]
  fetchForecastModels: () => Promise<void>
  setSelectedForecastModelsIds: (selected: string[]) => void
  setSelectedModel: () => Promise<void>
  onRowsPerPageChange: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void
  handleChangePage: (event: any, newPage: number) => void
  onChangeSelectRow: (
    event: ChangeEvent<HTMLInputElement>,
    forecast_model_id: string
  ) => void
}
export const SnowflakeForecastModelsContext =
  createContext<SnowflakeForecastModelsContextProps>(null as any)

export default function SnowflakeForecastModelsContextProvider(
  props: SnowflakeForecastModelsContextProviderProps
) {
  const { children } = props

  const { apiService } = useContext(APIServiceContext)
  const { organizationId } = useContext(OrganizationsContext)

  const [page, setPage] = useState<number>(0)
  const [rowCount, setRowCount] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [areForecastModelsLoading, setAreForecastModelsLoading] =
    useState<boolean>(true)
  const [forecastModels, setFecastModels] = useState<SnowflakeForecastModel[]>(
    []
  )
  const [selectedForecastModelsIds, setSelectedForecastModelsIds] = useState<
    readonly string[]
  >([])

  useEffect(() => {
    setRowCount(0)
  }, [organizationId])

  const fetchForecastModels = useCallback(async () => {
    setRowCount(0)
    const requestedPage = page + 1

    setAreForecastModelsLoading(true)
    await apiService
      .listSnowflakeForecastModels(requestedPage, rowsPerPage, organizationId)
      .then((json) => {
        setFecastModels(json.data.results ?? [])
        setRowCount(json.data.count ?? 0)
        setAreForecastModelsLoading(false)
      })
  }, [apiService, organizationId, page, rowsPerPage])

  useEffect(() => {
    fetchForecastModels()
  }, [fetchForecastModels])

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage)
  }

  const setSelectedModel = async () => {
    if (selectedForecastModelsIds.length > 0) {
      let forecastModel = forecastModels.find(
        (model) => model.id === selectedForecastModelsIds[0]
      )
      if (forecastModel) {
        forecastModel!.is_selected = true
        await apiService
          .partialUpdateSnowflakeForecastModel(
            selectedForecastModelsIds[0],
            organizationId,
            forecastModel
          )
          .then(async (json) => {
            if (json.status === 200) {
              await fetchForecastModels()
            }
          })
      }
    }
  }

  const onRowsPerPageChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const onChangeSelectRow = (
    event: ChangeEvent<HTMLInputElement>,
    forecast_model_id: string
  ) => {
    if (event.target.checked) {
      setSelectedForecastModelsIds((oldSelected) => {
        return oldSelected.concat([forecast_model_id])
      })
    } else {
      setSelectedForecastModelsIds((oldSelected) => {
        return oldSelected.filter((value) => {
          return value !== forecast_model_id
        })
      })
    }
  }

  return (
    <SnowflakeForecastModelsContext.Provider
      value={{
        areForecastModelsLoading,
        forecastModels,
        page,
        rowCount,
        rowsPerPage,
        selectedForecastModelsIds,
        fetchForecastModels,
        setSelectedForecastModelsIds,
        setSelectedModel,
        handleChangePage,
        onRowsPerPageChange,
        onChangeSelectRow
      }}
    >
      {children}
    </SnowflakeForecastModelsContext.Provider>
  )
}
