import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { GridRowSelectionModel } from '@mui/x-data-grid'
import {
  StoreCanaryCloudReadOnly,
  StoreCanaryComponentReadOnly,
  StoreCanaryReadOnly
} from 'lib/CloudCanariesRestfulAPI'
import { APIServiceContext } from './APIServiceContext'
import { AuthContext } from './AuthContext'

interface AutoLoadCanaryTemplatesContextProviderProps {
  children: ReactNode
}

interface AutoLoadCanaryTemplatesContextProps {
  areCanaryTemplatesLoading: boolean
  canaryTemplates: StoreCanaryReadOnly[]
  storeCanaryClouds: StoreCanaryCloudReadOnly[] | null
  storeCanaryComponents: StoreCanaryComponentReadOnly[] | null
  selectedComponent: string
  selectedCloud: string
  pageSize: number
  page: number
  rowCount: number
  selectedCanaryTemplates: GridRowSelectionModel
  fetchCanaryTemplates: () => Promise<void>
  setSelectedComponent: (value: string) => void
  setSelectedCloud: (value: string) => void
  onPageSizeChange: (pageSize: number) => void
  handleChangePage: (newPage: number) => void
  setSelectedCanaryTemplates: (rows: GridRowSelectionModel) => void
}
export const AutoLoadCanaryTemplatesContext =
  createContext<AutoLoadCanaryTemplatesContextProps>(null as any)

export default function AutoLoadCanaryTemplatesContextProvider(
  props: AutoLoadCanaryTemplatesContextProviderProps
) {
  const { children } = props

  const { companyId } = useContext(AuthContext)
  const { apiService } = useContext(APIServiceContext)

  const [page, setPage] = useState<number>(0)
  const [rowCount, setRowCount] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(10)
  const [areCanaryTemplatesLoading, setAreCanaryTemplatesLoading] =
    useState<boolean>(true)
  const [canaryTemplates, setCanaryTemplates] = useState<StoreCanaryReadOnly[]>(
    []
  )
  const [selectedCanaryTemplates, setSelectedCanaryTemplates] =
    useState<GridRowSelectionModel>([])
  const [storeCanaryClouds, setStoreCanaryClouds] = useState<
    StoreCanaryCloudReadOnly[] | null
  >(null)
  const [storeCanaryComponents, setStoreCanaryComponents] = useState<
    StoreCanaryComponentReadOnly[] | null
  >(null)
  const [selectedComponent, setSelectedComponent] = useState<string>('')
  const [selectedCloud, setSelectedCloud] = useState<string>('')

  const fetchStoreCanariesClouds = useCallback(async () => {
    await apiService.listStoreCanaryClouds().then((json) => {
      setStoreCanaryClouds(json.data.results ?? null)
    })
  }, [apiService])

  const fetchStoreCanariesComponents = useCallback(async () => {
    await apiService.listStoreCanaryComponents().then((json) => {
      setStoreCanaryComponents(json.data.results ?? null)
    })
  }, [apiService])

  const fetchCanaryTemplates = useCallback(async () => {
    const requestedPage = page + 1

    setAreCanaryTemplatesLoading(true)
    await apiService
      .listStoreCanaries(
        requestedPage,
        pageSize,
        selectedCloud,
        selectedComponent,
        'Approved',
        undefined,
        'false',
        undefined,
        companyId
      )
      .then((json) => {
        setCanaryTemplates(json.data.results ?? [])
        setRowCount(json.data.count ?? 0)
        setAreCanaryTemplatesLoading(false)
      })
  }, [apiService, companyId, page, pageSize, selectedCloud, selectedComponent])

  useEffect(() => {
    fetchStoreCanariesClouds()
  }, [fetchStoreCanariesClouds])

  useEffect(() => {
    fetchStoreCanariesComponents()
  }, [fetchStoreCanariesComponents])

  useEffect(() => {
    fetchCanaryTemplates()
  }, [fetchCanaryTemplates])

  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }

  const onPageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize)
    setPage(0)
  }

  return (
    <AutoLoadCanaryTemplatesContext.Provider
      value={{
        areCanaryTemplatesLoading,
        canaryTemplates,
        storeCanaryClouds,
        storeCanaryComponents,
        selectedCloud,
        selectedComponent,
        page,
        rowCount,
        pageSize,
        selectedCanaryTemplates,
        fetchCanaryTemplates,
        setSelectedCloud,
        setSelectedComponent,
        handleChangePage,
        onPageSizeChange,
        setSelectedCanaryTemplates
      }}
    >
      {children}
    </AutoLoadCanaryTemplatesContext.Provider>
  )
}
