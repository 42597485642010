import {
  setOrganizationRequiredModalMessage,
  setOrganizationRequiredModalOpen
} from 'client_side_state/slices/app'
import { useAppDispatch } from 'client_side_state/store'
import { useContext, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { IChangeEvent } from '@rjsf/core'
import MuiForm from '@rjsf/mui'
import {
  ErrorSchema,
  ErrorSchemaBuilder,
  RJSFSchema,
  UiSchema
} from '@rjsf/utils'
import validator from '@rjsf/validator-ajv8'
import { AxiosError, isAxiosError } from 'axios'
import DialogModal from 'components/Modals/DialogModal'
import OrganizationRequiredDialog, {
  OrganizationRequiredMessage
} from 'components/UserOrganization/OrganizationRequiredDialog'
import CreateButton from 'components/common/CreateButton'
import FormsUpdateCancelButtons from 'components/common/FormsUpdateCancelButtons'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { CanariesContext } from 'contexts/CanariesContext'
import { OrganizationsContext } from 'contexts/OrganizationsContext'
import { SnowflakeForecastModelsContext } from 'contexts/SnowflakeForecastModelsContext'
import { WalletContext } from 'contexts/WalletContext'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  muiForm: {
    '& label.MuiFormLabel-root': {
      fontSize: '1.2rem'
    },
    '& span.MuiFormControlLabel-label ': {
      fontSize: '1.2rem'
    },
    '& input[type="file" i]': {
      fontSize: '1.2rem'
    }
  }
})

export default function ForecastModelsCreateModal() {
  const classes = useStyles()

  const dispatch = useAppDispatch()

  const { apiService } = useContext(APIServiceContext)
  const { organizationId, organization } = useContext(OrganizationsContext)
  const { canaries } = useContext(CanariesContext)
  const { wallets } = useContext(WalletContext)
  const { fetchForecastModels } = useContext(SnowflakeForecastModelsContext)

  const [open, setOpen] = useState(false)
  const [extraErrors, setExtraErrors] = useState<ErrorSchema>({})

  const handleClickOpen = () => {
    if (!organizationId) {
      dispatch(
        setOrganizationRequiredModalMessage(
          OrganizationRequiredMessage.SnowflakeForecastModels
        )
      )
      dispatch(setOrganizationRequiredModalOpen(true))
    } else {
      setOpen(true)
    }
  }

  const handleClose = () => {
    setExtraErrors({})
    setOpen(false)
  }

  const schema = {
    type: 'object',
    required: ['name', 'data_start_date', 'data_end_date'],
    properties: {
      name: { type: 'string', title: 'Model Name' },
      data_start_date: {
        type: 'string',
        title: 'Data Start Date',
        format: 'date-time'
      },
      data_end_date: {
        type: 'string',
        title: 'Data End Date',
        format: 'date-time'
      },
      excluded_canaries: {
        type: 'array',
        uniqueItems: true,
        title: 'Excluded canaries from Snowflake forecasts',
        items: {
          type: 'string',
          enum: canaries?.map((canary) => canary.id || ''),
          enumNames: canaries?.map((canary) => canary.name || '')
        }
      },
      estimated_model_build_time: {
        title: 'Estimated model build time',
        description: 'Estimated model build time',
        type: 'null'
      },
      estimated_model_availability: {
        title: 'Estimated model availability',
        description: 'Estimated model availability',
        type: 'null'
      }
    }
  } as RJSFSchema

  const uiSchema: UiSchema = {
    excluded_canaries: {
      // 'ui:widget': 'checkboxes',
      // 'ui:options': {
      //   inline: true,
      // }
    }
  }

  const onSubmit = async (
    data: IChangeEvent<any, RJSFSchema, any>,
    event: React.FormEvent<any>
  ) => {
    let requestBody = JSON.parse(JSON.stringify(data.formData))
    requestBody.organization_id = organizationId
    try {
      await apiService
        .createSnowflakeForecastModel(requestBody)
        .then(async (json) => {
          if (json.status === 201) {
            handleClose()
            await fetchForecastModels()
          }
        })
    } catch (e: any) {
      if (isAxiosError(e)) {
        const err = e as AxiosError
        if (err.response?.status === 400) {
          const errors = err.response?.data as {}
          const builder = new ErrorSchemaBuilder()
          Object.entries(errors).forEach(([key, value]) => {
            builder.addErrors(value as string, key)
          })
          setExtraErrors(builder.ErrorSchema)
        }
      }
    }
  }

  return (
    <div>
      <CreateButton
        text="Create"
        onClick={handleClickOpen}
        disabled={wallets.length === 0}
      />
      {organization && organization.id ? (
        <DialogModal
          open={open}
          onClose={handleClose}
          dialogTitle="Create Forecast Model"
          dialogContent={
            <MuiForm
              schema={schema}
              onSubmit={onSubmit}
              uiSchema={uiSchema}
              extraErrors={extraErrors}
              className={classes.muiForm}
              validator={validator}
            >
              <FormsUpdateCancelButtons
                cancelButtonOnClick={handleClose}
                rightButtonText="Build"
              />
            </MuiForm>
          }
        />
      ) : (
        <OrganizationRequiredDialog />
      )}
    </div>
  )
}
