import { FormConfigTypes } from './FormConfigsTypes'

export const configEditTemplateLibraryDockerfile: FormConfigTypes = {
  schema: {
    type: 'object',
    required: ['dockerfile_contents'],
    properties: {
      dockerfile_contents: { type: 'string', title: 'DockerfileContents' }
    }
  },
  uiSchema: {
    dockerfile_contents: {
      'ui:options': {
        widget: 'textarea',
        rows: 15
      }
    }
  }
}
