import { FormConfigTypes } from './FormConfigsTypes'

export const configResetPassword: FormConfigTypes = {
  schema: {
    type: 'object',
    required: ['token', 'new_password', 'new_password_repeat'],
    properties: {
      token: { type: 'string', title: '' },
      new_password: { type: 'string', title: 'New Password', minLength: 8 },
      new_password_repeat: {
        type: 'string',
        title: 'Repeat New Password',
        minLength: 8
      }
    }
  },
  uiSchema: {
    token: {
      'ui:widget': 'hidden'
    },
    new_password: {
      'ui:widget': 'password'
    },
    new_password_repeat: {
      'ui:widget': 'password'
    }
  }
}
