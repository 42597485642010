import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import { Theme } from '@mui/material/styles'
import { createStyles, makeStyles } from '@mui/styles'
import TabPanel from 'components/common/MUITabPanel'
import PageLoader from 'components/common/PageLoader'
import { configEditAlarmConfigurationCombined } from 'constants/formconfig/AlarmConfigurationCombined'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { TaskUpdates } from 'interfaces/CanaryEditor'
import {
  AbstractMetricBoundaryCondition,
  SimpleAlarm,
  SimpleAlarmComparisonOperatorEnum
} from 'lib/CloudCanariesRestfulAPI'
import CanaryTaskUpdateService, {
  MetricUpdateTypes
} from 'services/CanaryTaskUpdateService'
import { TabsNamesProps } from './VariablesPanel'

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      '& .MuiTextField-root': {
        margin: spacing(1)
      }
    },
    formControl: {
      margin: spacing(1),
      minWidth: 120
    },
    selectEmpty: {
      marginTop: spacing(2)
    }
  })
)

const schema = configEditAlarmConfigurationCombined.schema

const defaultSimpleAlarm = {
  metric_id: '',
  canary_id: '',
  task_id: '',
  boundary_condition: ''
} as SimpleAlarm

const defaultBoundaryCondition = {} as AbstractMetricBoundaryCondition

type metricUpdateTypes =
  | MetricUpdateTypes.LATENCY
  | MetricUpdateTypes.RESPONSE_VALUE
  | MetricUpdateTypes.FORECASTED_LATENCY
  | MetricUpdateTypes.FORECASTED_RESPONSE_VALUE

enum DataFormatSelection {
  Integer = 'Integer',
  Decimal = 'Decimal',
  String = 'String',
  Boolean = 'Boolean',
  JSON = 'JSON'
}

type FormDataCombined = Omit<
  SimpleAlarm & AbstractMetricBoundaryCondition,
  'name'
> & {
  name: string
}

interface TabPanelAbstractMetricProps {
  alarmId: string
  taskId: string
  metricUpdateType: metricUpdateTypes
  selectedTab: TabsNamesProps
  tabIndex: number
  tasksUpdates: Map<string, TaskUpdates>
  setTasksUpdates: (data: Map<string, TaskUpdates>) => void
}

export default function TabPanelAbstractMetric(
  props: TabPanelAbstractMetricProps
) {
  const classes = useStyles()
  const {
    alarmId,
    taskId,
    metricUpdateType,
    selectedTab,
    tabIndex,
    tasksUpdates,
    setTasksUpdates
  } = props
  const { apiService } = useContext(APIServiceContext)

  const [abstractMetricsLoading, setAbstractMetricsLoading] =
    useState<boolean>(true)

  const [selectedFormat, setSelectedFormat] = useState<DataFormatSelection>(
    DataFormatSelection.Integer
  )

  const [selectedComparisonOperator, setSelectedComparisonOperator] =
    useState<SimpleAlarmComparisonOperatorEnum>(
      SimpleAlarmComparisonOperatorEnum.EqualTo
    )

  const [alarmMeta, setAlarmMeta] = useState<SimpleAlarm>(defaultSimpleAlarm)
  const [alarmBoundaryCondition, setAlarmBoundaryCondition] =
    useState<AbstractMetricBoundaryCondition>(defaultBoundaryCondition)

  const [isLatencySelected, setIsLatencySelected] = useState<boolean>(false)
  const [isResponseSelected, setIsResponseSelected] = useState<boolean>(false)
  const [isForecastedLatencySelected, setIsForecastedLatencySelected] =
    useState<boolean>(false)
  const [isForecastedResponseSelected, setIsForecastedResponseSelected] =
    useState<boolean>(false)

  const getAlarmMeta = useCallback(async () => {
    await apiService.retrieveSimpleAlarm(alarmId).then((json) => {
      setAlarmMeta(json.data)
      if (selectedTab.name === 'Latency') {
        setIsLatencySelected(json.data.included_as_downtime_alarm || false)
      } else if (selectedTab.name === 'Response') {
        setIsResponseSelected(json.data.included_as_downtime_alarm || false)
      } else if (selectedTab.name === 'Forecast Latency') {
        if (json.data.enabled) {
          setIsForecastedLatencySelected(
            json.data.included_as_downtime_alarm || false
          )
        }
      } else if (selectedTab.name === 'Forecast Response') {
        if (json.data.enabled) {
          setIsForecastedResponseSelected(
            json.data.included_as_downtime_alarm || false
          )
        }
      }
    })
  }, [alarmId, apiService, selectedTab.name])

  const getAlarmBoundaryCondition = useCallback(async () => {
    if (alarmMeta && alarmMeta.boundary_condition) {
      await apiService
        .retrieveAbstractMetricBoundaryCondition(alarmMeta.boundary_condition)
        .then((json) => {
          setAlarmBoundaryCondition(json.data)
          setAbstractMetricsLoading(false)
        })
    }
  }, [alarmMeta, apiService])

  useEffect(() => {
    getAlarmMeta()
  }, [getAlarmMeta])

  useEffect(() => {
    getAlarmBoundaryCondition()
  }, [getAlarmBoundaryCondition])

  useEffect(() => {
    if (alarmBoundaryCondition.is_boolean) {
      setSelectedFormat(DataFormatSelection.Boolean)
    } else if (alarmBoundaryCondition.is_decimal) {
      setSelectedFormat(DataFormatSelection.Decimal)
    } else if (alarmBoundaryCondition.is_string) {
      setSelectedFormat(DataFormatSelection.String)
    } else if (alarmBoundaryCondition.is_json) {
      setSelectedFormat(DataFormatSelection.JSON)
    }
  }, [alarmBoundaryCondition])

  useEffect(() => {
    if (alarmMeta && alarmMeta.comparison_operator) {
      setSelectedComparisonOperator(alarmMeta.comparison_operator)
    }
  }, [alarmMeta])

  const handleComparisonOperatorChange = (event: SelectChangeEvent) => {
    const comparisonOperator = event.target
      .value as SimpleAlarmComparisonOperatorEnum

    const newName = formData.name.replace(/value (\S+)/g, () => {
      return `value ${comparisonOperator}`
    })

    setSelectedComparisonOperator(comparisonOperator)
    let formVariables: FormDataCombined = {
      ...formData,
      comparison_operator: comparisonOperator,
      name: newName
    }

    if (
      comparisonOperator ===
        SimpleAlarmComparisonOperatorEnum.StringContainsCaseSensitive ||
      comparisonOperator ===
        SimpleAlarmComparisonOperatorEnum.StringContainsNotCaseSensitive
    ) {
      formVariables = {
        ...formVariables,
        boundary_string_value_contains_case_sensitive: '',
        boundary_string_value_contains_not_case_sensitive: ''
      }
    }

    const mergedTaskUpdates = CanaryTaskUpdateService.mergeTaskMetricUpdate(
      taskId,
      metricUpdateType,
      schema,
      formVariables,
      alarmMeta,
      alarmBoundaryCondition,
      tasksUpdates
    )
    setTasksUpdates(mergedTaskUpdates)
  }

  const minAndMaxValueFieldType = useMemo<string>(() => {
    if (selectedFormat === DataFormatSelection.Integer) {
      return 'number'
    } else if (selectedFormat === DataFormatSelection.Decimal) {
      return 'number'
    } else if (selectedFormat === DataFormatSelection.String) {
      return 'text'
    } else if (selectedFormat === DataFormatSelection.JSON) {
      return 'text'
    }
    return 'number'
  }, [selectedFormat])

  const minAndMaxValueFieldNames = useMemo<{
    maxValueName?: string
    minValueName?: string
    containsStringCaseSensitive?: string
    containsStringNoneCaseSensitive?: string
  }>(() => {
    if (selectedFormat === DataFormatSelection.Integer) {
      return {
        minValueName: 'boundary_integer_value_min',
        maxValueName: 'boundary_integer_value_max'
      }
    } else if (selectedFormat === DataFormatSelection.Decimal) {
      return {
        minValueName: 'boundary_decimal_value_min',
        maxValueName: 'boundary_decimal_value_max'
      }
    } else if (selectedFormat === DataFormatSelection.String) {
      return {
        containsStringCaseSensitive:
          'boundary_string_value_contains_case_sensitive',
        containsStringNoneCaseSensitive:
          'boundary_string_value_contains_not_case_sensitive'
      }
    } else if (selectedFormat === DataFormatSelection.Boolean) {
      return { maxValueName: 'boundary_boolean_value' }
    }
    return {
      minValueName: 'boundary_integer_value_min',
      maxValueName: 'boundary_integer_value_max'
    }
  }, [selectedFormat])

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    // if (event.target.name === 'enabled') {

    // }
    if (event.target.name === 'included_as_downtime_alarm') {
      if (selectedTab.name === 'Latency') {
        setIsLatencySelected(event.target.checked)
      } else if (selectedTab.name === 'Response') {
        setIsResponseSelected(event.target.checked)
      } else if (selectedTab.name === 'Forecast Latency') {
        if (formData.enabled) {
          setIsForecastedLatencySelected(event.target.checked)
        }
      } else if (selectedTab.name === 'Forecast Response') {
        if (formData.enabled) {
          setIsForecastedResponseSelected(event.target.checked)
        }
      }
    }

    let newName = formData.name
    if (event.target.name === 'boundary_boolean_value') {
      newName = formData.name.replace(
        formData.boundary_boolean_value! ? 'True' : 'False',
        event.target.checked! ? 'True' : 'False'
      )
    } else if (event.target.name !== 'boundary_boolean_value') {
      if (event.target.name.includes('max')) {
        if (selectedFormat === DataFormatSelection.Integer) {
          newName = formData.name!.replace(
            /(Min: (\d+)?)?, (Max: (\d+)?)/g,
            (match, minPart) => {
              return `${minPart}, Max: ${event.target.value}`
              // return `${minPart}${formData.boundary_integer_value_min}, ${maxPart}${event.target.value}`
            }
          )

          // newName = formData.name!.replace(
          //   /(Min: )\d+, (Max: )\d+/g,
          //   (match, minPart, maxPart) => {
          //     console.log("minPart, maxPart", minPart, maxPart)
          //     return `${minPart}${formData.boundary_integer_value_min}, ${maxPart}${event.target.value}`
          //   }
          // )
        } else if (selectedFormat === DataFormatSelection.Decimal) {
          newName = formData.name!.replace(
            /(Min: (\d+)?)?, (Max: (\d+)?)/g,
            (match, minPart) => {
              return `${minPart}$, Max: ${event.target.value}`
            }
          )
        }
      } else if (event.target.name.includes('min')) {
        if (selectedFormat === DataFormatSelection.Integer) {
          newName = formData.name!.replace(
            /(Min: (\d+)?)?, (Max: (\d+)?)/g,
            (match, minPart, minNumber, maxPart) => {
              return `Min: ${event.target.value}, ${maxPart}`
            }
          )
        } else if (selectedFormat === DataFormatSelection.Decimal) {
          newName = formData.name!.replace(
            /(Min: (\d+)?)?, (Max: (\d+)?)/g,
            (match, minPart, minNumber, maxPart) => {
              return `Min: ${event.target.value}, ${maxPart}`
            }
          )
        }
      } else if (event.target.name.includes('string')) {
        if (formData.boundary_string_value_contains_case_sensitive !== '') {
          newName = formData.name.replace(
            formData.boundary_string_value_contains_case_sensitive!,
            event.target.value
          )
        } else if (
          formData.boundary_string_value_contains_not_case_sensitive !== ''
        ) {
          newName = formData.name.replace(
            formData.boundary_string_value_contains_not_case_sensitive!,
            event.target.value
          )
        }
      }
    }

    const formVariables: FormDataCombined = {
      ...formData,
      name: newName,
      [event.target.name]:
        event.target.name === 'boundary_boolean_value' ||
        event.target.name === 'included_as_downtime_alarm' ||
        event.target.name === 'enabled'
          ? event.target.checked
          : event.target.value
    }

    const mergedTaskUpdates = CanaryTaskUpdateService.mergeTaskMetricUpdate(
      taskId,
      metricUpdateType,
      schema,
      formVariables,
      alarmMeta,
      alarmBoundaryCondition,
      tasksUpdates
    )
    setTasksUpdates(mergedTaskUpdates)
  }

  const formData = useMemo<FormDataCombined>(() => {
    return CanaryTaskUpdateService.getCurrentMetricFormData(
      taskId,
      metricUpdateType,
      tasksUpdates,
      alarmMeta,
      alarmBoundaryCondition
    )
  }, [
    alarmMeta,
    metricUpdateType,
    taskId,
    tasksUpdates,
    alarmBoundaryCondition
  ])

  const minAndMaxValueFieldValues = useMemo<{
    maxFieldValue?: number | boolean | string | undefined
    minFieldValue?: number | string | undefined
    containsFieldValueCaseSensitive?: string | undefined
    containsFieldValueNoneCaseSensitive?: string | undefined
  }>(() => {
    if (selectedFormat === DataFormatSelection.Integer) {
      return {
        maxFieldValue: formData.boundary_integer_value_max ?? 0,
        minFieldValue: formData.boundary_integer_value_min ?? 0
      }
    } else if (selectedFormat === DataFormatSelection.Decimal) {
      return {
        maxFieldValue: formData.boundary_decimal_value_max ?? '',
        minFieldValue: formData.boundary_decimal_value_min ?? ''
      }
    } else if (selectedFormat === DataFormatSelection.String) {
      return {
        containsFieldValueCaseSensitive:
          formData.boundary_string_value_contains_case_sensitive ?? '',
        containsFieldValueNoneCaseSensitive:
          formData.boundary_string_value_contains_not_case_sensitive ?? ''
      }
    } else if (selectedFormat === DataFormatSelection.Boolean) {
      return { maxFieldValue: formData.boundary_boolean_value! }
    }
    return {
      maxFieldValue: formData.boundary_integer_value_max ?? 0,
      minFieldValue: formData.boundary_integer_value_min ?? 0
    }
  }, [selectedFormat, formData])

  const isAlarmEbabled = useMemo(() => {
    return formData.enabled
  }, [formData.enabled])

  const isIncludedAsDowntimeCheckBoxValue = useMemo(() => {
    if (selectedTab.id === 1) {
      return isLatencySelected
    } else if (selectedTab.id === 2) {
      return isResponseSelected
    } else if (selectedTab.id === 3) {
      if (formData.enabled) {
        return isForecastedLatencySelected
      }
    } else if (selectedTab.id === 4) {
      if (formData.enabled) {
        return isForecastedResponseSelected
      }
    }
    return false
  }, [
    formData.enabled,
    isForecastedLatencySelected,
    isForecastedResponseSelected,
    isLatencySelected,
    isResponseSelected,
    selectedTab.id
  ])

  const filteredComparisonOperators: SimpleAlarmComparisonOperatorEnum[] | [] =
    useMemo(() => {
      if (selectedFormat === DataFormatSelection.String) {
        return Object.values(SimpleAlarmComparisonOperatorEnum).filter(
          (value) =>
            value ===
              SimpleAlarmComparisonOperatorEnum.StringContainsCaseSensitive ||
            value ===
              SimpleAlarmComparisonOperatorEnum.StringContainsNotCaseSensitive
        )
      }
      if (selectedFormat === DataFormatSelection.JSON) {
        return Object.values(SimpleAlarmComparisonOperatorEnum).filter(
          (value) => value === SimpleAlarmComparisonOperatorEnum.ParsableJson
        )
      }
      if (selectedFormat === DataFormatSelection.Boolean) {
        return Object.values(SimpleAlarmComparisonOperatorEnum).filter(
          (value) =>
            value === SimpleAlarmComparisonOperatorEnum.EqualTo ||
            value === SimpleAlarmComparisonOperatorEnum.NotEqualTo
        )
      }
      return Object.values(SimpleAlarmComparisonOperatorEnum).filter(
        (value) =>
          value !==
            SimpleAlarmComparisonOperatorEnum.StringContainsCaseSensitive &&
          value !==
            SimpleAlarmComparisonOperatorEnum.StringContainsNotCaseSensitive &&
          value !== SimpleAlarmComparisonOperatorEnum.ParsableJson
      )
    }, [selectedFormat])

  if (abstractMetricsLoading) {
    return <PageLoader />
  }

  return (
    <TabPanel value={selectedTab.id} index={tabIndex}>
      <Grid container style={{ width: '100%' }}>
        <Grid item xs={12} md={11}>
          <form className={classes.root} noValidate autoComplete="off">
            {selectedTab.id > 0 && (
              // TODO: Add check box for enabling the alarms for id 3 and 4
              <>
                {selectedTab.id > 2 && (
                  <FormControlLabel
                    sx={{ justifyContent: 'flex-end', m: 0 }}
                    control={
                      <Checkbox
                        name="enabled"
                        onChange={onChange}
                        checked={isAlarmEbabled}
                        color="primary"
                      />
                    }
                    label={`${selectedTab.name} Alarms`}
                  />
                )}
                {selectedTab.id < 3 && (
                  <FormControlLabel
                    sx={{ justifyContent: 'flex-end', m: 0 }}
                    control={
                      <Checkbox
                        name="included_as_downtime_alarm"
                        onChange={onChange}
                        checked={isIncludedAsDowntimeCheckBoxValue}
                        color="primary"
                        disabled={selectedTab.id === 3 || selectedTab.id === 4}
                      />
                    }
                    label={`Include ${selectedTab.name} Alarm as Downtime`}
                  />
                )}
              </>
            )}
          </form>
          <form className={classes.root} noValidate autoComplete="off">
            <TextField
              id="outlined-name"
              label="Name"
              name="name"
              value={formData.name}
              onChange={onChange}
              variant="outlined"
              fullWidth
              required
              disabled={!isAlarmEbabled}
            />
            <TextField
              id="outlined-format"
              label="Format"
              name="format"
              value={selectedFormat}
              variant="outlined"
              fullWidth
              disabled
              sx={{
                width: '100%',
                m: 1,
                minWidth: 120
              }}
            />
            <TextField
              id="filled-data-point-to-trigger"
              label="Data Points to Trigger"
              name="data_points_to_trigger"
              value={formData.data_points_to_trigger}
              onChange={onChange}
              variant="outlined"
              type="number"
              inputProps={{
                min: 0
              }}
              fullWidth
              required
              disabled={!isAlarmEbabled}
            />
          </form>
          <form
            className={classes.root}
            noValidate
            autoComplete="off"
            style={{ marginTop: '5rem' }}
          >
            <FormControl
              variant="outlined"
              sx={{
                width: '100%',
                m: 1,
                minWidth: 120
              }}
              required
              disabled={!isAlarmEbabled}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Comparison Operator
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={selectedComparisonOperator}
                onChange={handleComparisonOperatorChange}
                label="Comparison Operator"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {filteredComparisonOperators.map((value) => (
                  <MenuItem key={value} value={value}>
                    {value.replaceAll('_', ' ')}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {selectedFormat !== DataFormatSelection.Boolean ? (
              selectedFormat === DataFormatSelection.String ? (
                <>
                  <TextField
                    id="filled-contains-string-value-case-sensitive"
                    label="String contains with case sensitivity"
                    name={minAndMaxValueFieldNames.containsStringCaseSensitive}
                    value={
                      minAndMaxValueFieldValues.containsFieldValueCaseSensitive
                    }
                    onChange={onChange}
                    variant="outlined"
                    type={minAndMaxValueFieldType}
                    fullWidth
                    disabled={
                      selectedComparisonOperator ===
                        SimpleAlarmComparisonOperatorEnum.StringContainsNotCaseSensitive ||
                      !isAlarmEbabled
                    }
                  />
                  <TextField
                    id="filled-contains-string-value-none-case-sensitive"
                    label="String contains with no case sensitivity"
                    name={
                      minAndMaxValueFieldNames.containsStringNoneCaseSensitive
                    }
                    value={
                      minAndMaxValueFieldValues.containsFieldValueNoneCaseSensitive
                    }
                    onChange={onChange}
                    variant="outlined"
                    type={minAndMaxValueFieldType}
                    fullWidth
                    disabled={
                      selectedComparisonOperator ===
                        SimpleAlarmComparisonOperatorEnum.StringContainsCaseSensitive ||
                      !isAlarmEbabled
                    }
                  />
                </>
              ) : (
                selectedFormat !== DataFormatSelection.JSON && (
                  <>
                    <TextField
                      id="filled-max-threshold-value"
                      label="Max Threshold Value"
                      name={minAndMaxValueFieldNames.maxValueName}
                      value={minAndMaxValueFieldValues.maxFieldValue}
                      onChange={onChange}
                      variant="outlined"
                      inputProps={{
                        min: 0
                      }}
                      type={minAndMaxValueFieldType}
                      fullWidth
                      disabled={!isAlarmEbabled}
                    />
                    <TextField
                      id="filled-min-threshold-value"
                      label="Min Threshold Value"
                      name={minAndMaxValueFieldNames.minValueName}
                      value={minAndMaxValueFieldValues.minFieldValue}
                      onChange={onChange}
                      variant="outlined"
                      inputProps={{
                        min: 0
                      }}
                      type={minAndMaxValueFieldType}
                      fullWidth
                      disabled={!isAlarmEbabled}
                    />
                  </>
                )
              )
            ) : (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.boundary_boolean_value!}
                    onChange={onChange}
                    name={minAndMaxValueFieldNames.maxValueName}
                    color="primary"
                    disabled={!isAlarmEbabled}
                  />
                }
                label="Boolean boundary value"
              />
            )}
          </form>
        </Grid>
      </Grid>
    </TabPanel>
  )
}
