import { selectEditingLabel } from 'client_side_state/slices/pageFilter'
import { useAppSelector } from 'client_side_state/store'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import DialogModal from 'components/Modals/DialogModal'
import BackButton from 'components/common/BackButton'
import Button from 'components/common/Button'
import { queryParamPageSizeJumbo } from 'constants/AppConfig'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { CanaryRestfulLibSourceFileMinimalReadOnly } from 'lib/CloudCanariesRestfulAPI'
import { Container } from 'styles/components/CanaryEditor/EditorMenu.styled'
import { PageContainer } from 'styles/pages/CanaryManage.styled'
import SourceFileEditor, { SourceFileType } from './SourceFileEditor'

const useStyles = makeStyles({
  active: {
    color: 'black',
    background: 'lightgray',
    padding: '5px'
  }
})

interface SourceLibraryEditorProps {
  sourceFileType: SourceFileType
  schemaId: string
}

export default function SourceLibraryEditor(props: SourceLibraryEditorProps) {
  const classes = useStyles()

  const { sourceFileType, schemaId } = props
  const history = useHistory()

  const { apiService } = useContext(APIServiceContext)

  const [codeFiles, setCodeFiles] = useState<
    CanaryRestfulLibSourceFileMinimalReadOnly[]
  >([])
  const [codeFileID, setCodeFileID] = useState<string>('')
  const [saving, setSaving] = useState<boolean>(false)
  const [hasUpdate, setHasUpdate] = useState<boolean>(false)
  const [codeFilesRetrieved, setCodeFilesRetrieved] = useState<boolean>(false)

  const schemaName = useAppSelector(selectEditingLabel)

  const checkUnsavedChanges = (): boolean =>
    !hasUpdate ||
    window.confirm('You have unsaved changes. Are you sure you want to leave?')

  const getCodeFiles = useCallback(async () => {
    if (sourceFileType === SourceFileType.RestfulLib) {
      await apiService
        .listCanaryRestfulLibSourceCodes(
          undefined,
          queryParamPageSizeJumbo,
          schemaId
        )
        .then((json) => {
          setCodeFiles(json.data.results ?? [])
          setCodeFilesRetrieved(true)
        })
    } else if (sourceFileType === SourceFileType.CanaryTaskPrototype) {
      await apiService
        .listCanaryPrototypeSourceCodes(
          undefined,
          queryParamPageSizeJumbo,
          schemaId
        )
        .then((json) => {
          setCodeFiles(json.data.results ?? [])
          setCodeFilesRetrieved(true)
        })
    }
  }, [sourceFileType, apiService, schemaId])

  useEffect(() => {
    getCodeFiles()
  }, [getCodeFiles])

  const onSave = () => {
    setSaving(true)
  }

  return (
    <DialogModal
      fullScreen
      open={codeFilesRetrieved}
      onClose={() => {
        if (!checkUnsavedChanges()) return
        history.goBack()
      }}
    >
      <PageContainer>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <h1 style={{ marginLeft: '3rem' }}>{schemaName}</h1>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {hasUpdate ? (
              <Container
                style={{
                  flex: '0 0 303px',
                  height: '30px',
                  borderBottomWidth: 0
                }}
              >
                <span className="info" style={{ marginBottom: 0 }}>
                  {saving ? 'Saving...' : 'You have unsaved changes'}
                </span>
                <Button label="Save" disabled={saving} action={onSave} />
              </Container>
            ) : null}
            <BackButton
              action={() => {
                if (!checkUnsavedChanges()) return
                history.goBack()
              }}
            />
          </div>
        </div>

        <div className={'SourceEditorContainer'}>
          <div className="file-filter">
            <ul>
              {codeFiles.map((f) => {
                return (
                  <li
                    key={f.id!}
                    className={`${f.id === codeFileID ? classes.active : ''}`}
                    onClick={() => {
                      if (!checkUnsavedChanges()) return
                      setHasUpdate(false)
                      setCodeFileID(f.id!)
                    }}
                  >
                    {f.relative_path}
                  </li>
                )
              })}
            </ul>
          </div>
          <SourceFileEditor
            codeFileID={codeFileID}
            schemaId={schemaId}
            sourceFileType={sourceFileType}
            saving={saving}
            setSaving={setSaving}
            setHasUpdate={setHasUpdate}
          />
        </div>
      </PageContainer>
    </DialogModal>
  )
}
