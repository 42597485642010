import Skeleton from '@mui/material/Skeleton'
import SharedStyles from './SharedStyles'

export default function PagesSkeletonLoader() {
  const classes = SharedStyles()
  return (
    <div className={classes.loadingSkeletons}>
      <Skeleton
        variant="rectangular"
        width="100%"
        height={60}
        className={classes.skeleton}
        classes={{
          root: classes.skeletonRoot
        }}
      />
      {Array.from([1, 2, 3, 4]).map((element) => (
        <Skeleton
          key={element}
          variant="rectangular"
          width="100%"
          height={60}
          className={classes.skeleton}
        />
      ))}
    </div>
  )
}
