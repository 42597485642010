import Button from '@mui/material/Button'
import Box from '@mui/system/Box'

interface FormsUpdateCancelButtonsProps {
  cancelButtonOnClick: () => void
  leftButtonText?: string
  rightButtonText?: string
}

export default function FormsUpdateCancelButtons(
  props: FormsUpdateCancelButtonsProps
) {
  const {
    cancelButtonOnClick,
    leftButtonText = 'Cancel',
    rightButtonText = 'Update'
  } = props
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        mt: 1.25
      }}
    >
      <Button
        variant="contained"
        color="error"
        onClick={cancelButtonOnClick}
        sx={{ mr: 1, textTransform: 'capitalize' }}
      >
        {leftButtonText}
      </Button>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={{ textTransform: 'capitalize' }}
      >
        {rightButtonText}
      </Button>
    </Box>
  )
}
