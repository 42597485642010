import { useContext, useState } from 'react'
import Button from '@mui/material/Button'
import { makeStyles } from '@mui/styles'
import { IChangeEvent } from '@rjsf/core'
import MuiForm from '@rjsf/mui'
import { RJSFSchema } from '@rjsf/utils'
import validator from '@rjsf/validator-ajv8'
import Icon, { IconType } from 'components/Icons'
import DialogModal from 'components/Modals/DialogModal'
import FormsUpdateCancelButtons from 'components/common/FormsUpdateCancelButtons'
import { configKubernetesClusterKubeConfig } from 'constants/formconfig/KubernetesCluster'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { ClustersContext } from 'contexts/ClustersContext'
import { OrganizationsContext } from 'contexts/OrganizationsContext'
import { KubernetesCluster } from 'lib/CloudCanariesRestfulAPI'

const useStyles = makeStyles({
  MuiForm: {
    '& .MuiInputBase-inputMultiline': {
      // minHeight: 100
    }
  }
})

interface UserOrganizationClusterKubeConfigModalProps {
  cluster: KubernetesCluster
}

export default function UserOrganizationClusterKubeConfigModal(
  props: UserOrganizationClusterKubeConfigModalProps
) {
  const { cluster } = props
  const classes = useStyles()

  const { apiService } = useContext(APIServiceContext)
  const { organizationId } = useContext(OrganizationsContext)
  const { fetchUserClusters } = useContext(ClustersContext)

  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const onSubmit = async (
    data: IChangeEvent<any, RJSFSchema, any>,
    event: React.FormEvent<any>
  ) => {
    const kube_config = JSON.parse(JSON.stringify(data.formData)).kube_config

    const kubernetesCluster = {
      kube_config
    } as KubernetesCluster

    const clusterId = cluster.id!
    try {
      await apiService
        .partialUpdateKubernetesCluster(
          clusterId,
          organizationId,
          kubernetesCluster
        )
        .then(async (json) => {
          handleClose()
          await fetchUserClusters()
        })
    } catch {
      handleClose()
      console.log('error contacting api')
    }
  }

  if (cluster.is_internal) {
    return <Icon name={IconType.Lock} color="disabled" />
  }

  return (
    <div>
      <Button onClick={handleClickOpen} color={'primary'}>
        <Icon name={IconType.Edit} style={{ marginRight: '0.5rem' }} /> Edit
      </Button>
      <DialogModal
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        dialogTitle="Edit Kube Config"
        dialogContent={
          <MuiForm
            formData={cluster}
            schema={configKubernetesClusterKubeConfig.schema}
            uiSchema={configKubernetesClusterKubeConfig.uiSchema}
            onSubmit={onSubmit}
            className={classes.MuiForm}
            validator={validator}
          >
            <FormsUpdateCancelButtons cancelButtonOnClick={handleClose} />
          </MuiForm>
        }
      />
    </div>
  )
}
