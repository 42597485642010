import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { BillingDetail } from 'lib/CloudCanariesRestfulAPI'
import { APIServiceContext } from './APIServiceContext'
import { AuthContext } from './AuthContext'

interface BillingContextProviderProps {
  children: ReactNode
}

interface BillingContextProps {
  areBillingDetailsLoading: boolean
  billingDetails: BillingDetail[]
  hasMore: boolean
  loadMoreSubscriptions: () => Promise<void>
  nextPage: () => void
  prevPage: () => void
}
export const BillingContext = createContext<BillingContextProps>(null as any)

export default function BillingContextProvider(
  props: BillingContextProviderProps
) {
  const { children } = props

  const { apiService } = useContext(APIServiceContext)
  const { userId } = useContext(AuthContext)

  const [page, setPage] = useState<number>(1)
  const [areBillingDetailsLoading, setAreBillingDetailsLoading] =
    useState(false)
  const [billingDetails, setBillingDetails] = useState<BillingDetail[]>([])
  const [hasMore, setHasMore] = useState<boolean>(true)

  const fetchBillingDetails = useCallback(
    async (pageNumber: number) => {
      setAreBillingDetailsLoading(true)
      try {
        const response = await apiService.listBillingDetails(
          pageNumber,
          10,
          userId
        )
        setBillingDetails(response.data.results)
        setHasMore(response.data.next !== null)
      } catch (error) {
        console.error('Error fetching subscriptions:', error)
      }
      setAreBillingDetailsLoading(false)
    },
    [apiService, userId]
  )

  const loadMoreSubscriptions = useCallback(async () => {
    await fetchBillingDetails(page)
  }, [fetchBillingDetails, page])

  const nextPage = () => {
    setPage((prev) => prev + 1)
  }

  const prevPage = () => {
    setPage((prev) => (prev > 1 ? prev - 1 : 1))
  }

  useEffect(() => {
    fetchBillingDetails(page)
  }, [fetchBillingDetails, page])

  return (
    <BillingContext.Provider
      value={{
        areBillingDetailsLoading,
        billingDetails,
        hasMore,
        loadMoreSubscriptions,
        nextPage,
        prevPage
      }}
    >
      {children}
    </BillingContext.Provider>
  )
}
