import { FormConfigTypes } from './FormConfigsTypes'

export const configKubernetesCluster: FormConfigTypes = {
  schema: {
    type: 'object',
    required: ['name'],
    properties: {
      name: { type: 'string', title: 'Cluster Name' }
    }
  }
}

export const configKubernetesClusterKubeConfig: FormConfigTypes = {
  schema: {
    type: 'object',
    required: ['kube_config'],
    properties: {
      kube_config: { type: 'string', title: 'Kube Config' }
    }
  },
  uiSchema: {
    kube_config: {
      'ui:options': {
        widget: 'textarea',
        rows: 15
      }
    }
  }
}
