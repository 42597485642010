import Tooltip from '@mui/material/Tooltip'
import {
  Container,
  SegmentArc
} from 'styles/components/common/CircularPercentages.styled'

export type Segment = {
  color: string
  percentage: number
  startPercentage: number
  name: string
}

interface CircularPercentagesProps {
  segments: Segment[]
  label?: string
}

export default function CircularPercentages(props: CircularPercentagesProps) {
  const { segments, label } = props
  return (
    <Container>
      <div className="content">{label}</div>
      {segments.map((segment, index) => (
        <Tooltip title={segment.name} arrow key={index}>
          <SegmentArc
            $color={segment.color}
            $degrees={segment.percentage * 3.6}
            $startDegree={segment.startPercentage * 3.6}
          />
        </Tooltip>
      ))}
      <div className="inner" />
      <div className="content">{label}</div>
    </Container>
  )
}
