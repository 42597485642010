import parser from 'cron-parser'
import { range } from 'lib/util'

export const canaryDisplaySchedule = function (schedule: string) {
  const fields = parser.parseExpression(schedule!).fields
  const days = range(0, 6) as typeof fields.dayOfWeek
  const frequency = fields.minute.length
  const displaySchedule = days.map((day_int) =>
    fields.dayOfWeek.includes(day_int)
  )
  return { displaySchedule, frequency }
}
