import {
  selectDateFrom,
  selectDateTo,
  selectSelectedTaskGraph,
  selectTimeFrom,
  selectTimeTo
} from 'client_side_state/slices/pageFilter'
import { useAppSelector } from 'client_side_state/store'
import { useMemo } from 'react'
import GraphiteGraph from 'components/common/GraphiteGraph'
import { CanaryDetailTaskReadOnlyMinimal } from 'lib/CloudCanariesRestfulAPI'
import DateTimeService from 'services/DateTimeService'
import { GraphContainer } from 'styles/pages/CanaryManage.styled'

interface CanaryTaskGraphProps {
  canaryTask: CanaryDetailTaskReadOnlyMinimal
}

const datetimeService: DateTimeService = new DateTimeService()

export default function CanaryTaskGraph(props: CanaryTaskGraphProps) {
  const { canaryTask } = props

  const pageDateFromFilter = useAppSelector(selectDateFrom)
  const pageDateToFilter = useAppSelector(selectDateTo)
  const pageTimeFromFilter = useAppSelector(selectTimeFrom)
  const pageTimeToFilter = useAppSelector(selectTimeTo)

  const _graphiteFrom = useMemo(
    () =>
      datetimeService.graphiteDateTimeValue(
        datetimeService.datetimeformatted(
          pageDateFromFilter,
          pageTimeFromFilter
        )
      ),
    [pageDateFromFilter, pageTimeFromFilter]
  )

  const _graphiteUntil = useMemo(
    () =>
      datetimeService.graphiteDateTimeValue(
        datetimeService.datetimeformatted(pageDateToFilter, pageTimeToFilter)
      ),
    [pageDateToFilter, pageTimeToFilter]
  )

  const selected = useAppSelector(selectSelectedTaskGraph)

  return (
    <GraphContainer>
      {canaryTask && selected && (
        <GraphiteGraph
          canaryTask={canaryTask}
          metricName={selected}
          graphiteFrom={_graphiteFrom}
          graphiteUntil={_graphiteUntil}
          width={100}
        />
      )}
    </GraphContainer>
  )
}
