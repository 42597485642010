import { selectUserProfile } from 'client_side_state/slices/user'
import { useAppSelector } from 'client_side_state/store'
import { MouseEvent, useEffect, useMemo, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import AppBar from '@mui/material/AppBar'
import Button from '@mui/material/Button'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import Toolbar from '@mui/material/Toolbar'
import Box from '@mui/system/Box'
import ComplianceHistoryNavButton from 'components/ComplianceHistory/ComplianceHistoryNavButton'
import Icon, { IconType } from 'components/Icons'
import IncidentsNavButton from 'components/Incidents/IncidentsNavButton'
import Organization from 'components/Organization'
import RecursiveMenu from './RecursiveMenu'
import { DROPDOWN_ROUTES, NAV_ROUTES } from './constants'
import sharedNavBarStyles from './sharedNavBarStyles'

const getPathName = (location: string) => {
  const parts = location.split('/')
  return parts.slice(1, parts.length).join('/')
}

export default function LoggedInNavBar() {
  const classes = sharedNavBarStyles()
  const location = useLocation()

  const initialLocation = useMemo(
    () => getPathName(location.pathname),
    [location]
  )
  const userData = useAppSelector(selectUserProfile)
  const [nav, setNav] = useState(initialLocation)
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null)
  const anchorRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const newLocation = getPathName(location.pathname)
    setNav(newLocation)
  }, [initialLocation, location.pathname])

  const isActiveLink = (nav: string, itemName: string): boolean =>
    '/' + nav === itemName

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    setOpen((previousOpen) => !previousOpen)
  }

  const handleClose = (event: any) => {
    setAnchorEl(null)
    setOpen(false)
  }
  const canBeOpen = open && Boolean(anchorEl)
  const id = canBeOpen ? 'transition-popper' : undefined

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        classes={{
          colorPrimary: classes.appBarRoot
        }}
        elevation={0}
      >
        <Toolbar>
          <div className={classes.linkContainer}>
            <img
              className={classes.logo}
              src="/nav_bar_logo.png"
              alt="Cloud Canaries"
            />
            {/* <span className={classes.companyName}>Cloud Canaries</span> */}
            {NAV_ROUTES.map((item) => (
              <Link
                key={`ni-${item.id}`}
                to={item.to}
                className={`${classes.button} ${
                  isActiveLink(nav, item.to) ? classes.activeLink : ''
                }`}
              >
                {item.label}
              </Link>
            ))}
          </div>
          <Box
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
          >
            <IncidentsNavButton />
            <ComplianceHistoryNavButton />
            <Organization />
            <Button
              size="small"
              variant="outlined"
              aria-describedby={id}
              aria-controls={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <span className={classes.userName}>
                {userData?.first_name || 'User Name'}
              </span>
              <Icon name={IconType.User} />
            </Button>
            <Popper
              open={open}
              anchorEl={anchorEl}
              role={undefined}
              transition
              disablePortal
              id={'nav-logged-in-popper'}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom' ? 'center top' : 'center bottom'
                  }}
                >
                  <Paper id={'nav-logged-in-paper'}>
                    <RecursiveMenu
                      ref={anchorRef}
                      routes={DROPDOWN_ROUTES}
                      handleClose={handleClose}
                      classes={classes}
                      nav={nav}
                    />
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  )
}
