import styled from 'styled-components'

export const ColorPicker = styled.input`
  width: 250px;
  height: 25px;
  border: none;
  padding: 0;
  margin: 2px 0;
`
export const PropertyName = styled.h3`
  display: inline;
  margin: 0;
`
