import { useHistory } from 'react-router-dom'
import Icon, { IconType } from 'components/Icons'
import CustomButton from 'components/common/Button'

interface BackButtonProps {
  action?: () => void
}

export default function BackButton(props: BackButtonProps) {
  const { action } = props
  const history = useHistory()
  return (
    <CustomButton
      className="CustomButton"
      leftIcon={<Icon name={IconType.X} />}
      backgroundColor="#48A2F4"
      style={{ color: 'black', fontWeight: 'bold', fontSize: '24px' }}
      action={() => (action ? action() : history.goBack())}
    />
  )
}
