/* returns a name with _ replaced by space, camel cased, stripped of "CustomCanary"
 *
 *  NOTE: only to be used until proper operationID field is differentiated from human readable task name
 * */
export const cleanHumanReadableTaskName = (task_name: string) => {
  return underScoreStringToFirstLetterCapital(
    task_name.replace('CustomCanary', '').toLowerCase()
  )
}

interface serviceProviderOption {
  provider_id?: string | undefined
  provider_name?: string | undefined
}

/* return a deduplicated list of {label, value} options from choices which have provider_id, provider_name
 *
 * */
export const deDuplicateServiceProviderOptions = (
  options: serviceProviderOption[]
) => {
  let uniqueProviders = new Map<string, string>()

  options.forEach((s) => {
    uniqueProviders.set(s.provider_id!, s.provider_name!)
  })

  return Array.from(uniqueProviders.entries()).flatMap((o) => {
    return { value: String(o[0]!), label: String(o[1]!) }
  })
}

interface servicesOption {
  service?: string | undefined
  service_name?: string | undefined
}

/* return a deduplicated list of {label, value} options from choices which have service, service_name
 *
 * */
export const deDuplicateServicesOptions = (options: servicesOption[]) => {
  let uniqueServices = new Map<string, string>()

  options.forEach((s) => {
    uniqueServices.set(s.service!, s.service_name!)
  })

  return Array.from(uniqueServices.entries()).flatMap((o) => {
    return { value: String(o[0]!), label: String(o[1]!) }
  })
}

export const stringToFirstLetterCapital = (string: string) =>
  string.replace(/(^|\s)\S/g, (c) => c.toUpperCase())

/*
 * Takes a string and return the first letter to capital letter
 */
export const underScoreStringToFirstLetterCapital = (string: string) =>
  stringToFirstLetterCapital(string.replaceAll('_', ' '))

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

export function splitByCapitalLetters(
  str: string,
  keepCapitalLetters: boolean = false
): string {
  const regex = /([A-Z][a-z]*)/g
  const result = str.match(regex) || []
  return keepCapitalLetters ? result.join(' ') : result.join(' ').toLowerCase()
}

export function secondsToHhMmSs(seconds: number): string {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const remainingSeconds = seconds % 60

  const formattedHours = hours.toString().padStart(2, '0')
  const formattedMinutes = minutes.toString().padStart(2, '0')
  const formattedSeconds = remainingSeconds.toString().padStart(2, '0')

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
}

enum AlarmTypes {
  CanaryTaskErrorParsingResponse = 'Error Parsing',
  CanaryTaskErrorObtainingResponse = 'Error Obtaining',
  CanaryTaskResponseValue = 'Response',
  CanaryTaskLatency = 'Latency',
  CanaryDowntimeGeneratingFailure = 'Downtime',
  CanaryTaskForecastedLatency = 'Forecast Latency',
  CanaryTaskForecastedResponseValue = 'Forecast Reponse'
}

export function getMappedAlarmTypes(
  alarms: string | undefined
): string[] | undefined {
  if (!alarms || alarms.length === 0) {
    return undefined
  }
  const canary_alarms = alarms as unknown as string[]

  let alarmTypes: string[] = []

  Object.keys(AlarmTypes).forEach((alarmType) => {
    const alarms = canary_alarms.forEach((alarm) => {
      if (alarm.toLocaleLowerCase().includes(alarmType.toLocaleLowerCase())) {
        alarmTypes.push(`${AlarmTypes[alarmType as keyof typeof AlarmTypes]}\n`)
      }
    })
    return alarms
  })
  return alarmTypes
}

export function getCurrentDate(): string {
  const now = new Date()
  const year = now.getFullYear().toString().padStart(4, '0')
  const month = (now.getMonth() + 1).toString().padStart(2, '0') // Months are zero-indexed, so we add 1.
  const day = now.getDate().toString().padStart(2, '0')
  return `${year}-${month}-${day}`
}

export function isDateBetween(
  targetDate: string,
  startDate: string,
  endDate: string
): boolean {
  const targetTimestamp = new Date(targetDate).getTime()
  const startTimestamp = new Date(startDate).getTime()
  const endTimestamp = new Date(endDate).getTime()

  return targetTimestamp >= startTimestamp && targetTimestamp <= endTimestamp
}

export function formatDateTimeShort(dateTimeString: string): string {
  const date = new Date(dateTimeString)

  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timeZone: 'UTC'
  }

  return date.toLocaleDateString('en-US', options)
}

export function formatDateShort(dateString: string): string {
  const date = new Date(dateString)

  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: '2-digit',
    timeZone: 'UTC'
  }

  return date.toLocaleDateString('en-US', options)
}

export function formatDate(dateString: string): string {
  const date = new Date(dateString)
  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  }
  return date.toLocaleDateString('en-US', options)
}
