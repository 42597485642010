import { history } from 'client_side_state/history'
import { ReactNode, createContext, useCallback, useState } from 'react'
import { toast } from 'react-toastify'
import { AxiosError, AxiosResponse } from 'axios'
import {
  companyIdKey,
  usePlatformClustersIdKey,
  userIdKey,
  userIsFirstTime,
  userIsTempPassword,
  userTokenExpirationKey,
  userTokenExpirationMinutes,
  userTokenKey
} from 'constants/AppConfig'
import { ToastErrors } from 'constants/toast_errors'
import {
  AuthToken,
  Configuration,
  DefaultApi,
  User
} from 'lib/CloudCanariesRestfulAPI'
import RoutesService from 'services/routes'

interface Creds {
  email: string
  password: string
}

interface AuthContextProviderProps {
  children: ReactNode
}

interface AuthContextProps {
  unAuthenticatedAPIService: DefaultApi
  userId: string
  companyId: string
  getUsePlatformClusters: () => boolean
  getIsFirstTimeUser: () => boolean
  setIsFirstTimeUser: (isFirstTimeUser: boolean) => void
  isAuthenticated: () => boolean
  loginUser: (creds: Creds) => Promise<boolean>
  handleLogout: () => void
  getUserToken: () => string | null
  getTokenExpiration: () => number
  handleLoginSuccess: (response: AxiosResponse) => void
  handleLoginFail: (error?: AxiosError) => void
  getIsTempPassword: () => boolean
  registerUser: (params: any) => Promise<AxiosResponse<User, any>>
  impersonateUser: (
    userId: string,
    userToken: string,
    companyId: string,
    isFirstTimeUser: string,
    usePlatformClusters: string,
    isTempPassword: string
  ) => void
  setLocalToken: (token: string) => void
}

export const AuthContext = createContext<AuthContextProps>(null as any)

export default function AuthContextProvider(props: AuthContextProviderProps) {
  const { children } = props

  const APIConfiguration = new Configuration()
  const unAuthenticatedAPIService = new DefaultApi(APIConfiguration)

  const [userToken, setSetUserToken] = useState<string>(
    localStorage.getItem(userTokenKey) || ''
  )
  const [userId, setUserId] = useState<string>(
    localStorage.getItem(userIdKey) || ''
  )
  const [companyId, setCompanyId] = useState<string>(
    localStorage.getItem(companyIdKey) || ''
  )
  const isAuthenticated = useCallback((): boolean => {
    return userToken ? true : false
  }, [userToken])

  const setLocalToken = (newToken: string): void => {
    if (newToken !== localStorage.getItem(userTokenKey) || '') {
      localStorage.setItem(userTokenKey, newToken)
    }
    if (newToken !== userToken) {
      setSetUserToken(newToken)
    }
  }
  const setLocalUserId = (newUserId: string): void => {
    if (newUserId !== localStorage.getItem(userIdKey) || '') {
      localStorage.setItem(userIdKey, newUserId)
    }
    if (newUserId !== userId) {
      setUserId(newUserId)
    }
  }
  const setLocalCompanyId = (newCompanyId: string): void => {
    if (newCompanyId !== localStorage.getItem(companyIdKey) || '') {
      localStorage.setItem(companyIdKey, newCompanyId)
    }
    if (newCompanyId !== userId) {
      setCompanyId(newCompanyId)
    }
  }

  // user tokens
  const getUserToken = () => {
    const userToken = localStorage.getItem(userTokenKey)
    return userToken
  }

  const getIsTempPassword = () => {
    const isTempPassword = localStorage.getItem(userIsTempPassword)
    return isTempPassword === 'true' ? true : false
  }

  const setIsTempPassword = (isTempPassword: boolean) => {
    localStorage.setItem(userIsTempPassword, String(isTempPassword))
  }

  const getIsFirstTimeUser = () => {
    const isFirstTimeUser = localStorage.getItem(userIsFirstTime)
    return isFirstTimeUser === 'true' ? true : false
  }

  const setIsFirstTimeUser = (isFirstTimeUser: boolean) => {
    localStorage.setItem(userIsFirstTime, String(isFirstTimeUser))
  }
  const getUsePlatformClusters = () => {
    const usePlatformClusters = localStorage.getItem(usePlatformClustersIdKey)
    return usePlatformClusters === 'true' ? true : false
  }

  const setUsePlatformClusters = (usePlatformClusters: boolean) => {
    localStorage.setItem(usePlatformClustersIdKey, String(usePlatformClusters))
  }

  const getTokenExpiration = () => {
    const expiration = localStorage.getItem(userTokenExpirationKey)
    const e = expiration ? expiration : ''
    return Number(e)
  }

  const setTokenExpiration = () => {
    const now = new Date()
    const tokenExpirationDate = new Date(
      now.getTime() + userTokenExpirationMinutes * 60000
    ).toUTCString()
    const tokenExpiration = Date.parse(tokenExpirationDate).toString()

    localStorage.setItem(userTokenExpirationKey, tokenExpiration)
  }

  // app methods methods

  const setUserLoginData = (data: AuthToken) => {
    if (data) {
      setLocalToken(data.token ?? '')
      setIsTempPassword(data.is_temp_password ?? false)
      setIsFirstTimeUser(data.is_first_time_user ?? false)
      setTokenExpiration()
      setLocalUserId(data.user_id ?? '')
      setLocalCompanyId(data.company ?? '')
      setUsePlatformClusters(data.use_platform_clusters ?? false)
    }
  }

  const handleLoginSuccess = (response: AxiosResponse<AuthToken, any>) => {
    if (response.status === 200) {
      if (response.data.is_temp_password) {
        redirectToChangeTempPassword()
      } else {
        setUserLoginData(response.data)
      }
    }
  }

  const forgetAuthToken = () => {
    setLocalToken('')
    localStorage.setItem(userIdKey, '')
    localStorage.setItem(companyIdKey, '')
    localStorage.setItem(userTokenExpirationKey, '')
    localStorage.setItem(userIsTempPassword, '')
    localStorage.setItem(userIsFirstTime, '')
    localStorage.setItem(usePlatformClustersIdKey, '')
  }

  const handleLogout = () => {
    forgetAuthToken()
    redirectToLogin()
  }

  const handleLoginFail = (error?: AxiosError) => {
    forgetAuthToken()
    redirectToLogin()
  }

  const loginUser = async (creds: Creds) => {
    const userCredentials = { username: creds.email, password: creds.password }
    try {
      const response =
        await unAuthenticatedAPIService.createAuthToken(userCredentials)
      if (response.status === 200) {
        handleLoginSuccess(response)
        history.push(RoutesService.userLoggedInInterstitial())
        return true
      } else {
        toast.error(ToastErrors.WRONG_AUTH_CREDENTIALS, {
          position: 'top-right'
        })
        setLocalToken('')
        return false
      }
    } catch (e) {
      toast.error(ToastErrors.WRONG_AUTH_CREDENTIALS, {
        position: 'top-right'
      })
      setLocalToken('')
      return false
    }
  }
  const registerUser = async (params: any) => {
    return await unAuthenticatedAPIService.createUser(params)
  }

  const impersonateUser = (
    userId: string,
    userToken: string,
    companyId: string,
    isFirstTimeUser: string,
    usePlatformClusters: string,
    isTempPassword: string
  ) => {
    forgetAuthToken()
    setLocalUserId(userId)
    setLocalCompanyId(companyId)
    setLocalToken(userToken)
    setUsePlatformClusters(usePlatformClusters === 'True')
    setIsFirstTimeUser(isFirstTimeUser === 'True')
    setIsTempPassword(isTempPassword === 'True')
    setTokenExpiration()
  }

  const redirectToLogin = () => {
    const url = RoutesService.userLogin()
    window.location.href = url
  }
  const redirectToChangeTempPassword = () => {
    const url = RoutesService.userChangeTempPassword()
    window.location.href = url
  }

  return (
    <AuthContext.Provider
      value={{
        unAuthenticatedAPIService,
        userId,
        companyId,
        getUsePlatformClusters,
        getIsFirstTimeUser,
        setIsFirstTimeUser,
        isAuthenticated,
        loginUser,
        handleLogout,
        getUserToken,
        getTokenExpiration,
        handleLoginSuccess,
        handleLoginFail,
        getIsTempPassword,
        registerUser,
        impersonateUser,
        setLocalToken
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
