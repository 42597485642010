import SettingsGroupContextProvider from 'contexts/ServiceSettingsGroupContext'
import ServicesContextProvider from 'contexts/ServicesContext'
import SettingsGroup from 'pages/AdminPages/SettingsGroup'

export default function SettingsGroupAdminPage() {
  return (
    <ServicesContextProvider>
      <SettingsGroupContextProvider>
        <SettingsGroup />
      </SettingsGroupContextProvider>
    </ServicesContextProvider>
  )
}
