import { useContext, useState } from 'react'
import { IChangeEvent } from '@rjsf/core'
import MuiForm from '@rjsf/mui'
import { ErrorSchema, ErrorSchemaBuilder, RJSFSchema } from '@rjsf/utils'
import validator from '@rjsf/validator-ajv8'
import { AxiosError, isAxiosError } from 'axios'
import DialogModal from 'components/Modals/DialogModal'
import CreateButton from 'components/common/CreateButton'
import FormsCreateButton from 'components/common/FormsCreateButton'
import { configOrganizationCreateUser } from 'constants/formconfig/Organizations'
import { APIServiceContext } from 'contexts/APIServiceContext'
import useStyles from './useStyles'

const schema = configOrganizationCreateUser.schema

interface UserOrganizationsCreateProps {
  afterSubmit: () => void
}

export default function UserOrganizationsCreate(
  props: UserOrganizationsCreateProps
) {
  const classes = useStyles()

  const { afterSubmit } = props

  const { apiService } = useContext(APIServiceContext)

  const [open, setOpen] = useState(false)
  const [extraErrors, setExtraErrors] = useState<ErrorSchema>({})

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = async (
    data: IChangeEvent<any, RJSFSchema, any>,
    event: React.FormEvent<any>
  ) => {
    const requestBody = JSON.parse(JSON.stringify(data.formData))
    try {
      await apiService.createOrganization(requestBody).then((json) => {
        if (json.status === 201) {
          afterSubmit()
          handleClose()
        }
      })
    } catch (e: any) {
      if (isAxiosError(e)) {
        const err = e as AxiosError
        if (err.response?.status === 400) {
          const errors = err.response?.data as {}
          const builder = new ErrorSchemaBuilder()
          Object.entries(errors).forEach(([key, value]) => {
            builder.addErrors(value as string, key)
          })
          setExtraErrors(builder.ErrorSchema)
        }
      }
    }
  }

  return (
    <>
      <CreateButton text="Create" onClick={handleClickOpen} />
      <DialogModal
        open={open}
        onClose={handleClose}
        dialogTitle="Create Organization"
        dialogContent={
          <MuiForm
            schema={schema}
            onSubmit={handleSubmit}
            extraErrors={extraErrors}
            className={classes.muiForm}
            validator={validator}
          >
            <FormsCreateButton>Create</FormsCreateButton>
          </MuiForm>
        }
      />
    </>
  )
}
