import { useCallback, useContext, useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Icon, { IconType } from 'components/Icons'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { OrganizationsContext } from 'contexts/OrganizationsContext'
import { OrganizationUsersInner } from 'lib/CloudCanariesRestfulAPI'

interface UserOrganizationManageMembersProps {
  organizationId: string
}

export default function UserOrganizationManageMembers(
  props: UserOrganizationManageMembersProps
) {
  const { organizationId } = props

  const { apiService } = useContext(APIServiceContext)
  const { setUserSelectedOrganization } = useContext(OrganizationsContext)

  const [organizationMembers, setOrganizationMembers] = useState<
    OrganizationUsersInner[]
  >([])

  const getOrganizationMembers = useCallback(async () => {
    await apiService.retrieveOrganization(organizationId).then((json) => {
      setOrganizationMembers(json.data.users ?? [])
      if (json.data.users?.length! <= 0) {
        setUserSelectedOrganization(null)
      }
    })
  }, [apiService, organizationId, setUserSelectedOrganization])

  const removeUser = async (userId: string) => {
    await apiService
      .removeMemberOrganization(organizationId, { id: userId })
      .then(() => {
        getOrganizationMembers()
      })
  }

  useEffect(() => {
    getOrganizationMembers()
  }, [getOrganizationMembers])

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Last Name</TableCell>
          <TableCell>First Name</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>Remove User</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {organizationMembers &&
          organizationMembers.map((orgMember) => {
            const memberID = orgMember.id ? orgMember.id : ''
            return (
              <TableRow key={orgMember.id}>
                <TableCell>{orgMember.first_name}</TableCell>
                <TableCell>{orgMember.last_name}</TableCell>
                <TableCell>{orgMember.email}</TableCell>
                <TableCell>
                  <Button
                    onClick={() => {
                      removeUser(memberID)
                    }}
                  >
                    <Icon name={IconType.Trash} />
                  </Button>
                </TableCell>
              </TableRow>
            )
          })}
      </TableBody>
    </Table>
  )
}
