import {
  selectRowsPerPageNumber,
  selectSearchText,
  selectedCanaryStatus,
  setRowsPerPageNumber,
  setSearchText,
  setSelectedCanaryStatus
} from 'client_side_state/slices/canaryDashboard'
import { useAppDispatch, useAppSelector } from 'client_side_state/store'
import {
  ChangeEvent,
  MouseEvent,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import {
  CanaryReadWriteMinimal,
  CanaryStatusEnum,
  KubernetesCluster
} from 'lib/CloudCanariesRestfulAPI'
import { APIServiceContext } from './APIServiceContext'
import { OrganizationsContext } from './OrganizationsContext'

interface CanariesContextProviderProps {
  children: ReactNode
}

interface CanariesContextProps {
  canaryStatusValueSelected: CanaryStatusEnum | string
  areCanariesLoading: boolean
  checkAllBoxSelected: boolean
  rowsPerPage: number
  rowsPerPageOptions: number[]
  page: number
  rowCount: number
  canaries: CanaryReadWriteMinimal[]
  userClusters: KubernetesCluster[]
  selectedCanaryIds: readonly string[]
  ordering: orderingChoices
  searchInputText: string
  getCanaries: () => Promise<void>
  updateSearchText: (searchText: string) => void
  changeCanaryStatusValueSelected: (status: CanaryStatusEnum) => void
  setSelectedCanaryIds: (selected: string[]) => void
  selectAll: () => void
  onRowsPerPageChange: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void
  handleChangePage: (event: any, newPage: number) => void
  onChangeSort: (
    event: MouseEvent<HTMLSpanElement>,
    field_name: orderingFieldNames
  ) => void
  onChangeSelectRow: (
    event: ChangeEvent<HTMLInputElement>,
    canary_id: string
  ) => void
}
export const CanariesContext = createContext<CanariesContextProps>(null as any)

type orderingChoices =
  | 'name'
  | '-name'
  | 'template_canary__name'
  | '-template_canary__name'
  | 'status'
  | '-status'
  | 'cluster__name'
  | '-cluster__name'
  | 'template_canary__provider__name'
  | '-template_canary__provider__name'
  | 'template_canary__service__name'
  | '-template_canary__service__name'
  | undefined
type orderingFieldNames = Exclude<orderingChoices, undefined>

export default function CanariesContextProvider(
  props: CanariesContextProviderProps
) {
  const { children } = props
  const dispatch = useAppDispatch()

  const getSelectedCanaryStatus = useAppSelector(selectedCanaryStatus)
  const searchText = useAppSelector(selectSearchText)
  const rowsPerPageNumber = useAppSelector(selectRowsPerPageNumber)

  const { apiService } = useContext(APIServiceContext)
  const { organizationId } = useContext(OrganizationsContext)

  const rowsPerPageOptions = [10, 50, 100]

  const [canaryStatusValueSelected, setCanaryStatusValueSelected] = useState<
    CanaryStatusEnum | ''
  >(getSelectedCanaryStatus)
  const [areCanariesLoading, setAreCanariesLoading] = useState<boolean>(true)
  const [page, setPage] = useState<number>(0)
  const [rowCount, setRowCount] = useState<number>(0)
  const [canaries, setCanaries] = useState<CanaryReadWriteMinimal[]>([])
  const [rowsPerPage, setRowsPerPage] = useState<number>(rowsPerPageNumber)
  const [selectedCanaryIds, setSelectedCanaryIds] = useState<readonly string[]>(
    []
  )
  const [ordering, setOrdering] = useState<orderingChoices>(undefined)
  const [searchInputText, setSearchInputText] = useState<string>(searchText)
  const [userClusters, setUserClusters] = useState<KubernetesCluster[]>([])

  useEffect(() => {
    setSelectedCanaryIds([])
    setRowCount(0)
  }, [])

  useEffect(() => {
    setSelectedCanaryIds([])
    setRowCount(0)
  }, [organizationId])

  useEffect(() => {
    setSelectedCanaryIds([])
    setRowCount(0)
  }, [searchInputText])

  const getCanaries = useCallback(async () => {
    setRowCount(0)
    const requestedPage = page + 1
    const _organizationId = organizationId ? organizationId : undefined
    const search = searchInputText ? searchInputText : undefined
    const _status = canaryStatusValueSelected
      ? canaryStatusValueSelected
      : undefined

    setAreCanariesLoading(true)
    apiService
      .listCanaries(
        requestedPage,
        rowsPerPage,
        _organizationId,
        _status,
        ordering,
        search
      )
      .then((json) => {
        setCanaries(json.data.results ?? [])
        setRowCount(json.data.count ?? 0)
        setAreCanariesLoading(false)
      })
  }, [
    page,
    organizationId,
    searchInputText,
    canaryStatusValueSelected,
    apiService,
    rowsPerPage,
    ordering
  ])

  useEffect(() => {
    getCanaries()
  }, [getCanaries])

  const getUserClusters = useCallback(async () => {
    apiService
      .listKubernetesClusters(undefined, undefined, organizationId)
      .then((json) => setUserClusters(json.data.results ?? []))
  }, [apiService, organizationId])

  useEffect(() => {
    getUserClusters()
  }, [getUserClusters])

  const changeCanaryStatusValueSelected = (status: CanaryStatusEnum) => {
    setCanaryStatusValueSelected(status)
    dispatch(setSelectedCanaryStatus(status))
  }

  const selectAll = useCallback(() => {
    const newSelecteds = canaries.map((n) => n.id!)
    setSelectedCanaryIds(newSelecteds)
  }, [canaries])

  const onChangeSelectRow = (
    event: ChangeEvent<HTMLInputElement>,
    canary_id: string
  ) => {
    if (event.target.checked) {
      setSelectedCanaryIds((oldSelected) => {
        return oldSelected.concat([canary_id])
      })
    } else {
      setSelectedCanaryIds((oldSelected) => {
        return oldSelected.filter((value) => {
          return value !== canary_id
        })
      })
    }
  }
  const onChangeSort = (
    event: MouseEvent<HTMLSpanElement>,
    field_name: orderingFieldNames
  ) => {
    setOrdering((oldOrdering) => {
      let new_ordering = field_name
      if (oldOrdering) {
        if (oldOrdering.indexOf(field_name) === 0) {
          new_ordering = ('-' + field_name) as orderingFieldNames
        } else if (oldOrdering.indexOf(field_name) === 1) {
          new_ordering = field_name
        }
      }
      return new_ordering
    })
  }
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage)
  }

  const onRowsPerPageChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const rowsPerPage = parseInt(event.target.value, 10)
    setRowsPerPage(rowsPerPage)
    setPage(0)
    dispatch(setRowsPerPageNumber(rowsPerPage))
  }

  const updateSearchText = (searchText: string) => {
    setSearchInputText(searchText)
    dispatch(setSearchText(searchText))
  }

  const checkAllBoxSelected = useMemo(() => {
    return (
      selectedCanaryIds.length !== 0 &&
      selectedCanaryIds.length === canaries.length
    )
  }, [canaries, selectedCanaryIds])

  return (
    <CanariesContext.Provider
      value={{
        canaryStatusValueSelected,
        changeCanaryStatusValueSelected,
        areCanariesLoading,
        page,
        rowCount,
        rowsPerPageOptions,
        canaries,
        getCanaries,
        userClusters,
        checkAllBoxSelected,
        selectedCanaryIds,
        rowsPerPage,
        ordering,
        searchInputText,
        updateSearchText,
        setSelectedCanaryIds,
        selectAll,
        onRowsPerPageChange,
        handleChangePage,
        onChangeSelectRow,
        onChangeSort
      }}
    >
      {children}
    </CanariesContext.Provider>
  )
}
