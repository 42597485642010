import { ChangeEvent, useContext, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import Button from '@mui/material/Button'
import { isAxiosError } from 'axios'
import Icon, { IconType } from 'components/Icons'
import DialogModal from 'components/Modals/DialogModal'
import { ToastErrors } from 'constants/toast_errors'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { CanariesContext } from 'contexts/CanariesContext'
import { CanaryErrors } from 'interfaces/Canary'
import { DAYS_OF_WEEK, DAY_OPTIONS, HOUR_OPTIONS } from 'lib/util'
import {
  ActionsContainer,
  CircleButton,
  EditorContainer
} from 'styles/components/CanaryDashboard/EditorContainer.styled'

export default function ModalEditCanarySchedule() {
  const { apiService } = useContext(APIServiceContext)
  const { canaries, getCanaries, selectedCanaryIds } =
    useContext(CanariesContext)

  const [open, setOpen] = useState(false)

  const isDisabled = !selectedCanaryIds || selectedCanaryIds.length === 0

  const [basis, setBasis] = useState<'monthly' | 'weekly'>('weekly')
  const [cronExp, setCronExp] = useState<string>('*/5 * * * *')
  const [confirmCancel, setConfirmCancel] = useState<boolean>(false)
  const [trueCronExp, setTrueCronExp] = useState<string>('*/5 * * * *')
  const [selectedDays, setSelectedDays] = useState<boolean[]>([])
  const [subHeading, setSubHeading] = useState<string>('')
  const [scheduleDescription, setScheduleDescription] = useState<string>('')
  const [selectedFrequency, setSelectedFrequency] = useState<number>(1)
  const isMonthlyBasis = useMemo<boolean>(() => basis === 'monthly', [basis])

  useEffect(() => {
    /* NOTE: USE THIS FOR API CALLS
    (ensures that one of the day of the month or day of the week fields will be '*')
    (cronExp saves the information for both to enable switching between monthly/weekly */
    let fields = cronExp.split(' ')
    fields[isMonthlyBasis ? 4 : 2] = '*'
    setTrueCronExp(fields.join(' '))
  }, [cronExp, isMonthlyBasis])

  // reset confirm cancel if state changes
  useEffect(() => {
    if (open) {
      setConfirmCancel(false)
    }
  }, [canaries, basis, cronExp, selectedCanaryIds, open])

  // update state when props (which canaries) change
  useEffect(() => {
    if (open) {
      let selectedCronExp = '*/5 * * * *'
      if (selectedCanaryIds.length > 0) {
        if (selectedCanaryIds.length > 1) {
          selectedCronExp = canaries[0]?.schedule!
        } else {
          const selectedCanarySchedule = canaries.find(
            (canary) => canary.id === selectedCanaryIds[0]
          )?.schedule!
          selectedCronExp = selectedCanarySchedule!
        }
      }
      setCronExp(selectedCronExp)
    }
    return () => setCronExp('*/5 * * * *')
  }, [canaries, open, selectedCanaryIds])

  // which days are selected based on current state
  useEffect(() => {
    const tokens = cronExp.split(' ')[isMonthlyBasis ? 2 : 4].split(',')

    let selected: boolean[] = Array(DAY_OPTIONS[basis].length).fill(false)
    if (tokens[0] === '*')
      selected = Array(DAY_OPTIONS[basis].length).fill(true)

    // format of each token is a single number, no ranges
    tokens.forEach(
      (token) => (selected[parseInt(token) - (isMonthlyBasis ? 1 : 0)] = true)
    )
    setSelectedDays(selected)

    return () => {
      setSelectedDays(Array(DAY_OPTIONS[basis].length).fill(false))
    }
  }, [basis, cronExp, isMonthlyBasis])

  // subheading describing which canaries are selected
  useEffect(() => {
    if (open) {
      let str = ''

      const selectedCanaries = canaries.filter((item) =>
        selectedCanaryIds.includes(item.id!)
      )

      if (selectedCanaries.length > 0) {
        str += selectedCanaries[0].name
        if (selectedCanaries.length > 1) {
          str += `, ${selectedCanaries[1].name}`
        }
        if (selectedCanaries.length > 2) {
          str += `, and ${selectedCanaries.length - 2} other`
        }
        if (selectedCanaries.length > 3) {
          str += 's'
        }
      }
      setSubHeading(str)
    }
  }, [canaries, open, selectedCanaryIds])

  // description of schedule/cron expression
  useEffect(() => {
    // calculate ranges of days for easier displaying
    let description = ''
    const days = selectedDays.flatMap((selected, index) =>
      selected ? [index] : []
    )
    if (days.length === 0) {
      description = 'Never active'
    }

    if (days.length > 0) {
      // display for which recurring days
      let line1 = ''
      if (days.length === selectedDays.length) {
        line1 = 'Every day'
      } else {
        const ranges = [[days[0], days[0]]]
        for (let day of days) {
          const lastRange = ranges[ranges.length - 1]
          if (day <= lastRange[1] + 1) {
            lastRange[1] = day
          } else {
            ranges.push([day, day])
          }
        }
        if (basis === 'monthly') {
          const tokens = ranges.map((range) =>
            range[0] === range[1]
              ? (range[0] + 1).toString()
              : `${range[0] + 1}-${range[1] + 1}`
          )
          line1 += `On days ${tokens.join(', ')} of every month`
        } else {
          const tokens = ranges.map((range) =>
            range[0] === range[1]
              ? DAYS_OF_WEEK[range[0]]
              : `${DAYS_OF_WEEK[range[0]]} thru ${DAYS_OF_WEEK[range[1]]}`
          )
          line1 += `On every ${tokens.join(', ')}`
        }
      }

      // display for time and frequency
      let line2 = ''
      const fields = cronExp.split(' ')
      if (fields[0] === '*') {
        line2 += 'every minute'
      } else {
        line2 += `every ${fields[0].split('/')[1]} minutes`
      }

      if (fields[1] === '*') {
        line2 += ', every hour'
      } else {
        let [start, end] = fields[1].split('-').map((str) => parseInt(str))
        end += 1
        line2 += ` from ${start % 12 === 0 ? 12 : start % 12} ${
          start < 12 ? 'AM' : 'PM'
        }`
        line2 += ` to ${end % 12 === 0 ? 12 : end % 12} ${
          (end + 1) % 24 < 12 ? 'AM' : 'PM'
        }`
      }

      description = `${line1}\n${line2}`
    }
    setScheduleDescription(description)
  }, [basis, cronExp, selectedDays])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (open) {
      let frequency = 1
      const selectedCanaries = canaries?.filter((item) =>
        selectedCanaryIds.includes(item.id!)
      )
      if (selectedCanaryIds.length === 1) {
        frequency = Number(
          selectedCanaries[0].schedule!.split(' ')[0].split('/')[1]
        )
      } else if (selectedCanaryIds.length > 1) {
        frequency = Number(
          selectedCanaries[0].schedule!.split(' ')[0].split('/')[1]
        )
      }
      setSelectedFrequency(frequency)
    }
  }, [canaries, open, selectedCanaryIds])

  // modify fields regarding days in cron expression
  const updateCronExpDays = (index: number) => {
    // NOTE: the month field ranges from 1 to 31, the day-of-week field ranges from 0 to 6
    const indexAdjustment = isMonthlyBasis ? 1 : 0

    // get the current field as an array of strings
    let fields = cronExp.split(' ')
    let tokens = fields[isMonthlyBasis ? 2 : 4].split(',')
    if (tokens[0] === '*') {
      tokens = Array(DAY_OPTIONS[basis].length)
        .fill('')
        .map((_, index) => (index + indexAdjustment).toString())
    }

    // modify the array accordingly
    const indexOf = tokens.indexOf((index + indexAdjustment).toString())
    if (indexOf !== -1) {
      tokens.splice(indexOf, 1)
    } else {
      tokens.push((index + indexAdjustment).toString())
      if (tokens.length === DAY_OPTIONS[basis].length) {
        tokens = ['*']
      }
    }

    // rewrite updated cron expression to state
    fields[isMonthlyBasis ? 2 : 4] = tokens.join(',')
    setCronExp(fields.join(' '))
  }

  // modify field regarding range of hours in cron expression
  const updateCronExpTime = (option: 'start' | 'end', index: number) => {
    const fields = cronExp.split(' ')
    let tokens = fields[1] === '*' ? ['0', '23'] : fields[1].split('-')
    tokens[option === 'start' ? 0 : 1] = index.toString()
    if (parseInt(tokens[1]) < parseInt(tokens[0])) {
      tokens[1] = tokens[0]
    }
    if (tokens[0] === '0' && tokens[1] === '23') {
      fields[1] = '*'
    } else {
      fields[1] = tokens.join('-')
    }
    setCronExp(fields.join(' '))
  }

  // modify field regarding frequency by minutes in cron expression
  const updateCronExpFreq = (event: ChangeEvent<HTMLInputElement>) => {
    let newValue = Number(event.target.value)
    if (isNaN(newValue)) {
      newValue = 0
    } else if (newValue > 60) {
      newValue = 60
    } else if (newValue < 1) {
      newValue = 1
    }
    let fields = cronExp.split(' ')
    fields[0] = `*/${newValue}`
    setCronExp(fields.join(' '))
    setSelectedFrequency(newValue)
  }

  // ask to confirm cancelling if there are any unsaved changes
  const handleCloseEvent = () => {
    const selectedCanaries = canaries?.filter((item) =>
      selectedCanaryIds.includes(item.id!)
    )
    if (
      confirmCancel ||
      selectedCanaries.some((item) => item.schedule === cronExp)
    ) {
      handleClose()
    } else {
      setConfirmCancel(true)
    }
  }

  const handleAction = async () => {
    let canaryErrors: CanaryErrors[] = []
    await Promise.all(
      selectedCanaryIds.map(async (canaryId) => {
        try {
          await apiService.scheduleCanary(canaryId, { schedule: trueCronExp })
        } catch (err: any) {
          if (isAxiosError(err)) {
            if (err && err.response && err.response.data) {
              const erroringCanary: CanaryErrors = {
                canaryName: canaries.find((canary) => canary.id === canaryId)
                  ?.name!,
                error: true
              }
              canaryErrors.push(erroringCanary)
            }
          }
        }
      })
    )
    if (canaryErrors.some((canary) => canary.error)) {
      canaryErrors.forEach((error) => {
        toast.error(
          `${ToastErrors.ERROR_UPDATING_CANARIES_SCHEDULE} "${error.canaryName}"`,
          {
            position: 'top-right'
          }
        )
      })
    }
    setOpen(false)
    await getCanaries()
  }

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        color={'primary'}
        disabled={isDisabled}
      >
        <Icon name={IconType.Clock} /> Schedule
      </Button>
      <DialogModal
        open={open}
        onClose={handleCloseEvent}
        dialogTitle="Edit Selected Canaries Schedule"
        dialogContent={
          <EditorContainer $weekly={basis === 'weekly'}>
            <div className="subheading">{subHeading}</div>

            <div className="basis-selection">
              <h5
                className={`heading2 ${basis === 'weekly' && 'selected'}`}
                onClick={() => setBasis('weekly')}
              >
                Weekly
              </h5>
              <h5
                className={`heading2 ${basis === 'monthly' && 'selected'}`}
                onClick={() => setBasis('monthly')}
              >
                Monthly
              </h5>
            </div>
            <div className="underline" />

            <div className="schedule-selection">
              <div className="field">
                <label>Schedule:</label>
                <div className="calendar-selection">
                  {DAY_OPTIONS[basis].map((str, index) => (
                    <CircleButton
                      $selected={selectedDays[index]}
                      onClick={() => updateCronExpDays(index)}
                      key={index}
                    >
                      {str}
                    </CircleButton>
                  ))}
                </div>
              </div>

              <div className="field">
                <label style={{ marginTop: '12px' }}>Daily:</label>
                <div className="time-selection">
                  <span>on every active day, tests will run...</span>
                  <br />
                  <span>
                    from
                    <select
                      onChange={(event: any) =>
                        updateCronExpTime('start', event.target.value as number)
                      }
                    >
                      {HOUR_OPTIONS.map((option, index) => (
                        <option
                          key={index}
                          value={index}
                          selected={[`${index}`].includes(
                            cronExp.split(' ')[1].split('-')[0]
                          )}
                        >
                          {option}
                        </option>
                      ))}
                    </select>
                    to
                    <select
                      onChange={(event: any) =>
                        updateCronExpTime('end', event.target.value as number)
                      }
                    >
                      {Array(24)
                        .fill('')
                        .map((_, index) => (
                          <option
                            key={index}
                            value={index}
                            selected={[`${index}`, undefined].includes(
                              cronExp.split(' ')[1].split('-')[1]
                            )}
                          >
                            {HOUR_OPTIONS[(index + 1) % 24]}
                          </option>
                        ))
                        .filter((_, index) => {
                          const token = cronExp.split(' ')[1].split('-')[0]
                          return token === '*' || parseInt(token) <= index
                        })}
                    </select>
                  </span>
                </div>
              </div>

              <div className="field">
                <label>Frequency:</label>
                <div className="frequency-selection">
                  every
                  <input
                    type="number"
                    value={selectedFrequency}
                    min="1"
                    max="60"
                    onChange={updateCronExpFreq}
                  />
                  minutes
                </div>
              </div>
            </div>

            <div className="desc">{scheduleDescription}</div>
          </EditorContainer>
        }
        dialogActions={
          <ActionsContainer $cancel={confirmCancel}>
            <span>Click again to confirm cancel.</span>
            <Button
              variant="outlined"
              onClick={handleCloseEvent}
              color="primary"
            >
              <Icon name={IconType.Stop} /> Cancel
            </Button>
            <Button variant="outlined" onClick={handleAction} color="primary">
              <Icon name={IconType.Check} /> Apply
            </Button>
          </ActionsContainer>
        }
      />
    </>
  )
}
