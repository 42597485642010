import { useContext, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Button from '@mui/material/Button'
import Skeleton from '@mui/material/Skeleton'
import Icon, { IconType } from 'components/Icons'
import { IncidentsContext } from 'contexts/IncidentsContext'
import { ServiceHealthIncidentUrgencyEnum } from 'lib/CloudCanariesRestfulAPI'
import RoutesService from 'services/routes'

export default function IncidentsNavButton() {
  const location = useLocation()
  const history = useHistory()

  const { incidentsLevel, incidentsExist, areIncidentsLoading } =
    useContext(IncidentsContext)

  const [currentPath, setCurrentPath] = useState<string>('')
  const [previousPath, setPreviousPath] = useState<string>('')

  /**
   * We use this useEffect to maintain the current and previous paths
   * so we can get the previous path in order to pass it to Link component.
   */
  useEffect(() => {
    if (location.pathname !== currentPath) {
      setPreviousPath(currentPath)
      setCurrentPath(location.pathname)
    }
  }, [currentPath, location.pathname])

  let iconType: IconType = IconType.StateClear
  switch (incidentsLevel) {
    case ServiceHealthIncidentUrgencyEnum.High:
      iconType = IconType.Error
      break
    case ServiceHealthIncidentUrgencyEnum.Medium:
    case ServiceHealthIncidentUrgencyEnum.Low:
      iconType = IconType.Warning
      break
    case ServiceHealthIncidentUrgencyEnum.Ok:
      iconType = IconType.StateClear
      break
  }

  /**
   * We use this useMemo to detect changes in current and previous paths
   * and set the link that should be next for Link component.
   */
  const incidentsPageToLink = useMemo<string>(() => {
    const dashboardIncidentsLink = RoutesService.dashboardIncidents()
    if (currentPath === dashboardIncidentsLink) {
      return previousPath
    } else {
      return dashboardIncidentsLink
    }
  }, [currentPath, previousPath])

  const isCompliancePageActive = useMemo(() => {
    const complianceHistoryLink = RoutesService.complianceHistory()
    return currentPath === complianceHistoryLink
  }, [currentPath])

  return areIncidentsLoading ? (
    <Skeleton
      animation="wave"
      variant="rectangular"
      width={125}
      height={32}
      sx={{
        borderRadius: 1
      }}
    />
  ) : (
    <Button
      disabled={isCompliancePageActive}
      onClick={() => {
        history.push(incidentsPageToLink)
      }}
      sx={{
        backgroundColor: areIncidentsLoading
          ? 'rgba(0, 0, 0, 0.08)'
          : incidentsExist &&
              incidentsLevel === ServiceHealthIncidentUrgencyEnum.High
            ? '#f44336cc'
            : incidentsLevel === ServiceHealthIncidentUrgencyEnum.Medium
              ? '#ff9800cc'
              : '#81c784cc',
        color:
          incidentsLevel === ServiceHealthIncidentUrgencyEnum.Ok
            ? '#000000'
            : '#FFFFFF',
        '&:hover': {
          backgroundColor: areIncidentsLoading
            ? 'rgba(0, 0, 0, 0.08)'
            : incidentsExist &&
                incidentsLevel === ServiceHealthIncidentUrgencyEnum.High
              ? '#f4433680'
              : incidentsLevel === ServiceHealthIncidentUrgencyEnum.Medium
                ? '#ff980080'
                : '#81c78480'
        },
        textTransform: 'capitalize',
        fontSize: '1rem',
        lineHeight: '1.1876em',
        height: 32,
        padding: areIncidentsLoading ? 0 : 1,
        ml: 1,
        borderRadius: 1,
        alignItems: 'center',
        boxShadow: 2,
        minWidth: 64,
        border: 0
      }}
    >
      <Icon
        name={incidentsExist ? iconType : IconType.StateClear}
        color="inherit"
        fontSize="medium"
        style={{ marginRight: '0.5rem' }}
      />
      Incidents
    </Button>
  )
}
