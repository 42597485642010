import { useEffect, useRef } from 'react'
import { InitialFormData, PDFFileData } from 'interfaces/PDFFileData'

export default function usePrevious(
  value: any
): PDFFileData | InitialFormData | undefined {
  const ref = useRef<PDFFileData | InitialFormData>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}
