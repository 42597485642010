import { ReactNode, useEffect, useRef } from 'react'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import BackButton from 'components/common/BackButton'
import ModalSharedStyles from './ModalSharedStyles'

interface DialogModalProps {
  onClose: () => void
  useScrollPaper?: boolean
  dialogTitle?: ReactNode
  dialogTitleExtra?: ReactNode
  dialogContent?: ReactNode
  dialogActions?: ReactNode
  extraContent?: ReactNode
  children?: ReactNode
}

export default function DialogModal(
  props: DialogModalProps & Omit<DialogProps, keyof DialogModalProps>
) {
  const classes = ModalSharedStyles()
  const {
    onClose,
    useScrollPaper = false,
    dialogTitle,
    dialogTitleExtra,
    dialogContent,
    dialogActions,
    extraContent,
    children,
    open,
    ...other
  } = props

  const descriptionElementRef = useRef<HTMLElement>(null)

  useEffect(() => {
    if (open && useScrollPaper) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open, useScrollPaper])

  return (
    <Dialog
      open={open}
      keepMounted={false}
      classes={{ paper: !props.maxWidth ? classes.paperWidthSm : '' }}
      id={`${dialogTitle}-modal-dialog`}
      scroll={useScrollPaper ? 'paper' : 'body'}
      sx={{
        '& .MuiDialogContent-root': {
          padding: 2
        },
        '& .MuiDialogActions-root': {
          padding: 1
        }
      }}
      {...other}
    >
      {dialogTitle && (
        <DialogTitle
          className={classes.dialogTitle}
          id={`${dialogTitle}-modal-dialog-title`}
        >
          <b>{dialogTitle}</b>
          {dialogTitleExtra && dialogTitleExtra}
          <BackButton action={onClose} />
        </DialogTitle>
      )}
      {children && children}
      {dialogContent && (
        <DialogContent
          id={`${dialogTitle}-modal-dialog-content`}
          style={{
            padding: '20px'
          }}
          dividers={useScrollPaper}
        >
          {useScrollPaper ? (
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              {dialogContent}
            </DialogContentText>
          ) : (
            dialogContent
          )}
        </DialogContent>
      )}
      {dialogActions && (
        <DialogActions className={classes.dialogActions}>
          {dialogActions}
        </DialogActions>
      )}
      {extraContent && extraContent}
    </Dialog>
  )
}
