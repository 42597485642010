export interface LastPeriodOption {
  label: string
  value: string
}

interface SelectedPeriodDateTimes {
  startDate: string
  startTime: string
  stopDate: string
  stopTime: string
}

export default class LastPeriodService {
  private __selectedPeriod: LastPeriodOption =
    LastPeriodService.lastPeriodsOptions[0]

  static lastPeriodsOptions: LastPeriodOption[] = [
    { label: 'Time Period', value: '""' },
    { label: 'Last 24 hours', value: '24hrs' },
    { label: 'Last 3 days', value: '3dys' },
    { label: 'Last 2 weeks', value: '2wks' },
    { label: 'Last Month', value: 'mnth' }
  ]

  get selectedPeriod(): LastPeriodOption {
    return this.__selectedPeriod
  }

  set selectedPeriod(period: LastPeriodOption) {
    this.__selectedPeriod = period
  }

  private getStartDateTimeFromSelectedPeriod = (): {
    startDate: string
    startTime: string
  } => {
    let d = new Date()
    if (this.selectedPeriod) {
      switch (this.selectedPeriod?.value) {
        case '24hrs':
          d.setDate(d.getDate() - 1)
          break
        case '3dys':
          d.setDate(d.getDate() - 3)
          break
        case '2wks':
          d.setDate(d.getDate() - 14)
          break
        case 'mnth':
          d.setDate(d.getDate() - 30)
          break
      }
    }
    const startDate = d.toISOString().slice(0, 10)
    const startTime = `${d.getUTCHours().toString().padStart(2, '0')}:${d
      .getUTCMinutes()
      .toString()
      .padStart(2, '0')}`
    return { startDate, startTime }
  }

  private getStopDateTimeFromSelectedPeriod = (): {
    stopDate: string
    stopTime: string
  } => {
    let d = new Date()
    const stopDate = d.toISOString().slice(0, 10)
    const stopTime = `${d.getUTCHours().toString().padStart(2, '0')}:${d
      .getUTCMinutes()
      .toString()
      .padStart(2, '0')}`
    return { stopDate, stopTime }
  }

  findAndSetSelectedPeriod = (value: string): LastPeriodOption => {
    const selectedPeriod = LastPeriodService.lastPeriodsOptions.find(
      (item) => item.value === value
    )
    this.__selectedPeriod = selectedPeriod!
    return selectedPeriod!
  }

  getSelectedPeriodDateTimes = (): SelectedPeriodDateTimes => {
    const { startDate, startTime } = this.getStartDateTimeFromSelectedPeriod()
    const { stopDate, stopTime } = this.getStopDateTimeFromSelectedPeriod()
    return { startDate, startTime, stopDate, stopTime }
  }
}
