export type FuturePeriodOption = {
  label:
    | 'Next 24 hours'
    | 'Next 2 days'
    | 'Next 3 days'
    | 'Next 4 days'
    | 'Next 5 days'
  value: '24hrs' | '2days' | '3dys' | '4dys' | '5dys'
}

interface SelectedPeriodDateTimes {
  startDate: string
  stopDate: string
}

export default class FuturePeriodService {
  private __selectedPeriod: FuturePeriodOption =
    FuturePeriodService.futurePeriodsOptions[4]

  static futurePeriodsOptions: FuturePeriodOption[] = [
    { label: 'Next 24 hours', value: '24hrs' },
    { label: 'Next 2 days', value: '2days' },
    { label: 'Next 3 days', value: '3dys' },
    { label: 'Next 4 days', value: '4dys' },
    { label: 'Next 5 days', value: '5dys' }
  ]

  get selectedPeriod(): FuturePeriodOption {
    return this.__selectedPeriod
  }

  set selectedPeriod(period: FuturePeriodOption) {
    this.__selectedPeriod = period
  }

  private getStopDateFromSelectedPeriod = (): {
    stopDate: string
  } => {
    const defaultStart = new Date()
    let defaultStop = new Date(defaultStart.getTime() * 24 * 60 * 60 * 1000)

    if (this.selectedPeriod) {
      switch (this.selectedPeriod?.value) {
        case '24hrs':
          defaultStop = new Date(
            defaultStart.getTime() + 0 * 24 * 60 * 60 * 1000
          )
          break
        case '2days':
          defaultStop = new Date(
            defaultStart.getTime() + 1 * 24 * 60 * 60 * 1000
          )
          break
        case '3dys':
          defaultStop = new Date(
            defaultStart.getTime() + 2 * 24 * 60 * 60 * 1000
          )
          break
        case '4dys':
          defaultStop = new Date(
            defaultStart.getTime() + 3 * 24 * 60 * 60 * 1000
          )
          break
        case '5dys':
          defaultStop = new Date(
            defaultStart.getTime() + 4 * 24 * 60 * 60 * 1000
          )
          break
      }
    }
    const isoStop = defaultStop.toISOString()
    const stopDate = isoStop.slice(0, 10)
    return { stopDate }
  }

  private getStopDateTimeFromSelectedPeriod = (): {
    stopDate: string
  } => {
    const defaultStart = new Date()
    defaultStart.setHours(0, 0, 0, 0)
    let defaultStop = new Date(defaultStart.getTime() * 24 * 60 * 60 * 1000)

    if (this.selectedPeriod) {
      switch (this.selectedPeriod?.value) {
        case '24hrs':
          defaultStop = new Date(
            defaultStart.getTime() + 0 * 24 * 60 * 60 * 1000
          )
          break
        case '2days':
          defaultStop = new Date(
            defaultStart.getTime() + 1 * 24 * 60 * 60 * 1000
          )
          break
        case '3dys':
          defaultStop = new Date(
            defaultStart.getTime() + 2 * 24 * 60 * 60 * 1000
          )
          break
        case '4dys':
          defaultStop = new Date(
            defaultStart.getTime() + 3 * 24 * 60 * 60 * 1000
          )
          break
        case '5dys':
          defaultStop = new Date(
            defaultStart.getTime() + 4 * 24 * 60 * 60 * 1000
          )
          break
      }
    }
    const isoStop = defaultStop.toISOString()
    const stopDate = isoStop.slice(0, 16)
    return { stopDate }
  }

  private getStartDateFromSelectedPeriod = (): {
    startDate: string
  } => {
    const defaultStart = new Date()
    const startDate = defaultStart.toISOString().slice(0, 10)
    return { startDate }
  }

  private getStartDateTimeFromSelectedPeriod = (): {
    startDate: string
  } => {
    const defaultStart = new Date()
    defaultStart.setHours(0, 0, 0, 0)
    // const startDate = defaultStart.toISOString().slice(0, 16)
    const year = defaultStart.getFullYear()
    const month = String(defaultStart.getMonth() + 1).padStart(2, '0')
    const day = String(defaultStart.getDate()).padStart(2, '0')

    const startDate = `${year}-${month}-${day}T00:00`
    return { startDate }
  }

  findAndSetSelectedPeriod = (value: string): FuturePeriodOption => {
    const selectedPeriod = FuturePeriodService.futurePeriodsOptions.find(
      (item) => item.value === value
    )
    this.__selectedPeriod = selectedPeriod!
    return selectedPeriod!
  }

  getSelectedPeriodDates = (): SelectedPeriodDateTimes => {
    const { startDate } = this.getStartDateFromSelectedPeriod()
    const { stopDate } = this.getStopDateFromSelectedPeriod()
    return { startDate, stopDate }
  }

  getSelectedPeriodDateTimes = (): SelectedPeriodDateTimes => {
    const { startDate } = this.getStartDateTimeFromSelectedPeriod()
    const { stopDate } = this.getStopDateTimeFromSelectedPeriod()
    return { startDate, stopDate }
  }
}
