import useDocumentTitle from 'hooks/useDocumentTitle'
import { useContext, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Box from '@mui/system/Box'
import { IChangeEvent } from '@rjsf/core'
import MuiForm from '@rjsf/mui'
import { FormValidation, RJSFSchema } from '@rjsf/utils'
import validator from '@rjsf/validator-ajv8'
import { AxiosError, AxiosResponse, isAxiosError } from 'axios'
import CustomBaseInputTemplate from 'components/common/RJSF/MUI/CustomBaseInputTemplate'
import { configChangePassword } from 'constants/formconfig/ChangePassword'
import { ToastErrors } from 'constants/toast_errors'
import { AuthContext } from 'contexts/AuthContext'
import RoutesService from 'services/routes'
import useStyles from './styles'

const schema = configChangePassword.schema

export default function ChangeTempPassword() {
  const classes = useStyles()
  const history = useHistory()
  useDocumentTitle('Change Temporary Password')

  const { unAuthenticatedAPIService } = useContext(AuthContext)

  const { search } = history.location

  const email: string | null = useMemo(() => {
    const searchParams = new URLSearchParams(search)
    return searchParams.get('email')
  }, [search])

  const [passwordResetSuccessful, setPasswordResetSuccessful] =
    useState<boolean>(false)

  function validate(formData: any, errors: FormValidation) {
    if (formData.new_password !== formData.new_password_repeat) {
      errors.new_password_repeat!.addError("Passwords don't match")
    }
    return errors
  }

  const handleSubmit = async (
    data: IChangeEvent<any, RJSFSchema, any>,
    event: React.FormEvent<any>
  ) => {
    const requestBody = JSON.parse(JSON.stringify(data.formData))

    try {
      const response: AxiosResponse =
        await unAuthenticatedAPIService.changeTempPasswordAuthViewSet(
          requestBody
        )
      if (response.status === 200 && response.data.detail === 'Password set.') {
        setPasswordResetSuccessful(true)
      } else {
        setPasswordResetSuccessful(false)
      }
    } catch (e) {
      if (isAxiosError(e)) {
        const err = e as AxiosError
        if (err.response?.status === 400) {
          const errors = err.response?.data as {}
          if (
            'detail' in errors &&
            errors.detail === 'Temp password does not match.'
          ) {
            toast.error(ToastErrors.NO_PASSWORDS_MATCH, {
              position: 'top-right'
            })
          }
        }
      }
    }
  }

  return (
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      {passwordResetSuccessful ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
          }}
        >
          <Typography
            variant="h5"
            component="h1"
            sx={{
              mt: 1,
              color: '#4eb020',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <CheckCircleOutlineRoundedIcon sx={{ mr: 1 }} />
            Password Reset Successful. Please Sign In.
          </Typography>

          <Link
            to={RoutesService.userLogin()}
            className={`${classes.button} ${classes.forgotPasswordButton}`}
          >
            Sign In
          </Link>
        </Box>
      ) : (
        <>
          <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Set New Password
          </Typography>
          <MuiForm
            schema={schema}
            formData={{ ...{ email: email } }}
            onSubmit={handleSubmit}
            customValidate={validate}
            uiSchema={configChangePassword.uiSchema}
            validator={validator}
            templates={{ BaseInputTemplate: CustomBaseInputTemplate }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="outlined"
                color="primary"
                className={`${classes.button} ${classes.forgotPasswordButton}`}
                type="submit"
              >
                Update Password
              </Button>
            </Box>
          </MuiForm>
        </>
      )}
    </Box>
  )
}
