import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { IChangeEvent } from '@rjsf/core'
import MuiForm from '@rjsf/mui'
import { ErrorSchema, ErrorSchemaBuilder, RJSFSchema } from '@rjsf/utils'
import validator from '@rjsf/validator-ajv8'
import { AxiosError, isAxiosError } from 'axios'
import FormsCreateButton from 'components/common/FormsCreateButton'
import { queryParamPageSizeJumbo } from 'constants/AppConfig'
import { APIKeysContext } from 'contexts/APIKeysContext'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { OrganizationsContext } from 'contexts/OrganizationsContext'
import {
  Canary,
  CanaryServiceProvider,
  CanaryServiceProviderAvailabilityZone,
  CanaryServiceProviderRegion,
  CanaryableService,
  TemplateCanaryReadOnlyListItem
} from 'lib/CloudCanariesRestfulAPI'
import CanarySchemaService from 'services/CanarySchemaService'
import TopBar from './TopBar'

const canaryTemplateLibraryId = undefined

interface CanaryCreatorProps {
  onClose: () => Promise<void>
}

export default function CanaryCreator(props: CanaryCreatorProps) {
  const { onClose } = props

  const { organizationId, organization } = useContext(OrganizationsContext)

  const { apiService } = useContext(APIServiceContext)
  const { nonRevokedAPIKeys } = useContext(APIKeysContext)

  const schemaService = useMemo<CanarySchemaService>(
    () => new CanarySchemaService(),
    []
  )

  const [saving, setSaving] = useState<boolean>(false)
  const [canSetService, setCanSetService] = useState<boolean>(false)
  const [canSetCanaryType, setCanSetCanaryType] = useState<boolean>(false)
  const [canSetRegion, setCanSetRegion] = useState<boolean>(false)
  const [canSetAvailabilityZone, setCanSetAvailabilityZone] =
    useState<boolean>(false)

  const [optionsServiceProvider, setOptionsServiceProvider] = useState<
    CanaryServiceProvider[]
  >([])
  const [optionsService, setOptionsService] = useState<CanaryableService[]>([])
  const [optionsCanaryTypes, setOptionsCanaryTypes] = useState<
    TemplateCanaryReadOnlyListItem[]
  >([])
  const [optionsRegion, setOptionsRegion] = useState<
    CanaryServiceProviderRegion[]
  >([])
  const [optionsAvailabilityZone, setOptionsAvailabilityZone] = useState<
    CanaryServiceProviderAvailabilityZone[]
  >([])
  const [isCanaryAgent, setIsCanaryAgent] = useState<boolean>(false)

  const [canaryName, setCanaryName] = useState<string>('')
  const [selectedServiceProviderId, setSelectedServiceProviderId] =
    useState<string>('')
  const [selectedServiceId, setSelectedServiceId] = useState<string>('')
  const [selectedCanaryTypeId, setSelectedCanaryTypeId] = useState<string>('')
  const [selectedRegionId, setSelectedRegionId] = useState<string>('')
  const [selectedAvailabilityZoneId, setSelectedAvailabilityZoneId] =
    useState<string>('')
  const [selectedAPIKey, setSelectedAPIKey] = useState<string>('')

  const [extraErrors, setExtraErrors] = useState<ErrorSchema>({})

  const canCreate = useMemo<boolean>(
    () =>
      !saving &&
      Boolean(canaryName) &&
      Boolean(selectedServiceProviderId) &&
      Boolean(selectedServiceId) &&
      Boolean(selectedCanaryTypeId) &&
      Boolean(selectedRegionId) &&
      Boolean(selectedAvailabilityZoneId) &&
      Boolean(selectedAPIKey),
    [
      saving,
      canaryName,
      selectedServiceProviderId,
      selectedServiceId,
      selectedCanaryTypeId,
      selectedRegionId,
      selectedAvailabilityZoneId,
      selectedAPIKey
    ]
  )

  const hasUnsavedData = useMemo<boolean>(
    () =>
      Boolean(canaryName) ||
      Boolean(selectedServiceProviderId) ||
      Boolean(selectedServiceId) ||
      Boolean(selectedCanaryTypeId) ||
      Boolean(selectedRegionId) ||
      Boolean(selectedAvailabilityZoneId),
    [
      canaryName,
      selectedServiceProviderId,
      selectedServiceId,
      selectedCanaryTypeId,
      selectedRegionId,
      selectedAvailabilityZoneId
    ]
  )

  const getServiceProviders = useCallback(async () => {
    await apiService
      .listServiceProviders(undefined, queryParamPageSizeJumbo, organizationId)
      .then((json) => {
        setOptionsServiceProvider(json.data.results ?? [])
      })
  }, [apiService, organizationId])

  useEffect(() => {
    getServiceProviders()
  }, [getServiceProviders])

  const getCanaryableServices = useCallback(async () => {
    await apiService
      .listCanaryableServices(
        undefined,
        queryParamPageSizeJumbo,
        selectedServiceProviderId
      )
      .then((json) => {
        setOptionsService(json.data.results ?? [])
        setCanSetService(true)
      })
  }, [apiService, selectedServiceProviderId])

  useEffect(() => {
    if (selectedServiceProviderId) {
      getCanaryableServices()
    }
  }, [selectedServiceProviderId, getCanaryableServices])

  const getCanaryTypes = useCallback(async () => {
    await apiService
      .listTemplateCanaries(
        undefined,
        queryParamPageSizeJumbo,
        canaryTemplateLibraryId,
        selectedServiceProviderId,
        selectedServiceId
      )
      .then((json) => {
        setOptionsCanaryTypes(json.data.results ?? [])
        setCanSetCanaryType(true)
      })
  }, [apiService, selectedServiceProviderId, selectedServiceId])

  useEffect(() => {
    if (selectedServiceId) {
      getCanaryTypes()
    }
  }, [selectedServiceId, getCanaryTypes])

  const getRegions = useCallback(async () => {
    await apiService
      .listServiceProviderRegions(
        undefined,
        queryParamPageSizeJumbo,
        selectedServiceProviderId
      )
      .then((json) => {
        setOptionsRegion(json.data.results ?? [])
        setCanSetRegion(true)
      })
  }, [apiService, selectedServiceProviderId])

  useEffect(() => {
    if (selectedCanaryTypeId) {
      getRegions()
    }
  }, [selectedCanaryTypeId, getRegions])

  const getAvailabilityZones = useCallback(async () => {
    await apiService
      .listServiceProviderAvailabilityZones(
        undefined,
        queryParamPageSizeJumbo,
        selectedServiceProviderId,
        selectedRegionId
      )
      .then((json) => {
        setOptionsAvailabilityZone(json.data.results ?? [])
        setCanSetAvailabilityZone(true)
      })
  }, [apiService, selectedServiceProviderId, selectedRegionId])

  useEffect(() => {
    if (selectedRegionId) {
      getAvailabilityZones()
    }
  }, [selectedRegionId, getAvailabilityZones])

  const handleCreate = useCallback(async () => {
    setSaving(true)
    const canary = {
      name: canaryName,
      organization: organizationId,
      template_canary: selectedCanaryTypeId,
      env_vars: { api_key: selectedAPIKey },
      region: selectedRegionId,
      availability_zone: selectedAvailabilityZoneId,
      is_agent_canary: isCanaryAgent
    } as Canary

    try {
      await apiService.createCanary(canary).then(async (json) => {
        if (json.status === 201) {
          setSaving(false)
          onClose()
        }
      })
    } catch (e: any) {
      if (isAxiosError(e)) {
        const err = e as AxiosError
        if (err.response?.status === 400) {
          const errors = err.response?.data as {}
          const builder = new ErrorSchemaBuilder()
          Object.entries(errors).forEach(([key, value]) => {
            if (
              (value as any[])[0] ===
              'The fields name, organization must make a unique set.'
            ) {
              value = `"${canaryName}" canary name already exists for Organization!`
            }
            builder.addErrors(value as string)
          })
          setSaving(false)
          setExtraErrors(builder.ErrorSchema)
        }
      }
    }
  }, [
    canaryName,
    organizationId,
    selectedCanaryTypeId,
    selectedAPIKey,
    selectedRegionId,
    selectedAvailabilityZoneId,
    isCanaryAgent,
    apiService,
    onClose
  ])

  const onServiceProviderChange = (serviceProviderID: string) => {
    setCanSetService(false)
    setCanSetCanaryType(false)
    setCanSetRegion(false)
    setCanSetAvailabilityZone(false)

    setOptionsService([])
    setOptionsCanaryTypes([])
    setOptionsRegion([])
    setOptionsAvailabilityZone([])

    setSelectedServiceProviderId(serviceProviderID)
    setSelectedCanaryTypeId('')
    setSelectedServiceId('')
    setSelectedRegionId('')
    setSelectedAvailabilityZoneId('')
  }

  const onServiceChange = (serviceId: string) => {
    setCanSetCanaryType(false)
    setCanSetRegion(false)
    setCanSetAvailabilityZone(false)

    setOptionsCanaryTypes([])
    setOptionsRegion([])
    setOptionsAvailabilityZone([])

    setSelectedCanaryTypeId('')
    setSelectedRegionId('')
    setSelectedAvailabilityZoneId('')

    setSelectedServiceId(serviceId)
  }

  const onCanaryTypeChange = (canaryTypeId: string) => {
    setCanSetRegion(false)
    setCanSetAvailabilityZone(false)

    setOptionsRegion([])
    setOptionsAvailabilityZone([])

    setSelectedRegionId('')
    setSelectedAvailabilityZoneId('')

    setSelectedCanaryTypeId(canaryTypeId)
  }

  const onRegionChange = (regionId: string) => {
    setCanSetAvailabilityZone(false)
    setOptionsAvailabilityZone([])
    setSelectedAvailabilityZoneId('')

    setSelectedRegionId(regionId)
  }

  const onChange = (
    event: IChangeEvent<any, RJSFSchema, any>,
    id?: string | undefined
  ) => {
    const newValues = JSON.parse(JSON.stringify(event.formData))

    if (newValues.service_provider !== selectedServiceProviderId) {
      onServiceProviderChange(newValues.service_provider)
    }

    if (newValues.service !== selectedServiceId) {
      onServiceChange(newValues.service)
    }

    if (newValues.canary_type !== selectedCanaryTypeId) {
      onCanaryTypeChange(newValues.canary_type)
    }

    if (newValues.region !== selectedRegionId) {
      onRegionChange(newValues.region)
    }

    if (newValues.availability_zone !== selectedAvailabilityZoneId) {
      setSelectedAvailabilityZoneId(newValues.availability_zone)
    }

    if (newValues.is_canary_agent !== isCanaryAgent) {
      setIsCanaryAgent(newValues.is_canary_agent)
    }

    if (newValues.name !== canaryName) {
      setCanaryName(newValues.name)
    }

    if (newValues.api_key !== selectedAPIKey) {
      setSelectedAPIKey(newValues.api_key)
    }
  }

  const schema = schemaService.generateCanaryMetaSchema(
    optionsServiceProvider,
    optionsService,
    optionsCanaryTypes,
    optionsRegion,
    optionsAvailabilityZone,
    nonRevokedAPIKeys,
    canSetService,
    canSetCanaryType,
    canSetRegion,
    canSetAvailabilityZone,
    ''
  )

  const formData = useMemo(() => {
    return {
      service_provider: selectedServiceProviderId,
      service: selectedServiceId,
      canary_type: selectedCanaryTypeId,
      region: selectedRegionId,
      api_key: selectedAPIKey,
      availability_zone: selectedAvailabilityZoneId,
      is_canary_agent: isCanaryAgent,
      name: canaryName
    }
  }, [
    selectedServiceProviderId,
    selectedServiceId,
    selectedCanaryTypeId,
    selectedRegionId,
    selectedAPIKey,
    selectedAvailabilityZoneId,
    isCanaryAgent,
    canaryName
  ])

  const OrganizationName = useMemo(() => {
    if (organization) {
      return organization.name
    }
    return ''
  }, [organization])

  return (
    <div>
      <TopBar hasUnsavedData={hasUnsavedData} onClose={onClose} />
      <div className="main">
        <div className={'properties loaded'} style={{ padding: 20 }}>
          <p>Organization: {OrganizationName}</p>
          <MuiForm
            schema={schema}
            onChange={onChange}
            formData={formData}
            onSubmit={handleCreate}
            extraErrors={extraErrors}
            validator={validator}
          >
            <FormsCreateButton disabled={!canCreate}>Create</FormsCreateButton>
          </MuiForm>
        </div>
      </div>
    </div>
  )
}
