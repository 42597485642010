import { MouseEvent, forwardRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { ClickAwayListener } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import Popover from '@mui/material/Popover'
import Icon, { IconType } from 'components/Icons'
import { DROPDOWNROUTESTYPE } from './constants'

interface RecursiveMenuProps {
  routes: DROPDOWNROUTESTYPE
  handleClose: (event: any) => void
  classes: any
  nav: string
}

const RecursiveMenu = forwardRef<HTMLDivElement, RecursiveMenuProps>(
  (props, ref) => {
    const { routes, handleClose, classes, nav } = props
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    const [subMenuItems, setSubMenuItems] = useState<DROPDOWNROUTESTYPE>([])

    const handlePopoverOpen = (
      event: MouseEvent<HTMLElement>,
      subMenu: DROPDOWNROUTESTYPE
    ) => {
      setAnchorEl(event.currentTarget)
      setSubMenuItems(subMenu)
    }

    const handlePopoverClose = () => {
      setAnchorEl(null)
      setSubMenuItems([])
    }
    const open = Boolean(anchorEl)
    const id = open ? 'mouse-over-popover' : undefined

    const renderMenuItems = (routes: DROPDOWNROUTESTYPE) => {
      return routes.map((route) => {
        if (route.to) {
          return (
            <Link
              key={route.id}
              to={route.to}
              className={classes.menuLink}
              onClick={handleClose}
            >
              <MenuItem
                selected={route.to === `/${nav}`}
                classes={{ selected: classes.menuLinkSelected }}
              >
                {route.label}
              </MenuItem>
            </Link>
          )
        } else if (route.subMenu && route.subMenu.length > 0) {
          return (
            <div key={route.id}>
              <MenuItem
                className={classes.menuLink}
                aria-owns={anchorEl ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                aria-describedby={id}
                // onMouseEnter={(e) => handlePopoverOpen(e, route.subMenu!)}
                onClick={(e) => handlePopoverOpen(e, route.subMenu!)}
                // onMouseOut={(e) => handlePopoverClose()}
              >
                <Icon
                  name={IconType.ChevronLeftIcon}
                  fontSize="small"
                  style={{ marginLeft: '-19px' }}
                />
                {route.label}
              </MenuItem>
            </div>
          )
        } else {
          return null
        }
      })
    }

    return (
      <ClickAwayListener onClickAway={handleClose}>
        <div ref={ref}>
          <MenuList id="menu-list-grow" sx={{ px: 1 }}>
            {renderMenuItems(routes)}
          </MenuList>
          <Popover
            id={id}
            className={classes.popover}
            classes={{ paper: classes.paper }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            onClose={handlePopoverClose}
          >
            <MenuList>{renderMenuItems(subMenuItems)}</MenuList>
          </Popover>
        </div>
      </ClickAwayListener>
    )
  }
)

export default RecursiveMenu
