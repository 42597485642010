import useIsMatchingMD from 'hooks/useIsMatchingMD'
import Skeleton from '@mui/material/Skeleton'
import SharedStyles from './SharedStyles'

export default function ForecastDashboardLoader() {
  const classes = SharedStyles()
  const isMatchingMD = useIsMatchingMD()

  return (
    <div className={classes.loadingSkeletons}>
      <div className={classes.centerRow}>
        <Skeleton
          variant="text"
          width="15%"
          height={55}
          className={classes.skeleton}
          animation="wave"
        />
      </div>
      <div className={classes.skeletonsRow}>
        {Array.from([1, 2, 3]).map((element) => (
          <Skeleton
            key={element}
            variant="rectangular"
            width="15%"
            height={element === 2 ? 30 : 50}
            className={classes.skeleton}
            animation="wave"
          />
        ))}
      </div>
      <div className={`${classes.skeletonsRow} ${classes.forecastCardsRow}`}>
        {[1, 2, 3, 4, 5].map((item) => (
          <Skeleton
            key={item}
            variant="rectangular"
            width={285}
            height={490}
            className={`${classes.skeleton} ${classes.forecastCard}`}
          />
        ))}
      </div>
      <div className={classes.centerRow}>
        <Skeleton
          variant="text"
          width="30%"
          height={70}
          className={classes.skeleton}
          animation="wave"
          style={{ marginTop: '1rem' }}
        />
      </div>
      <div
        className={classes.skeletonsRow}
        style={{ justifyContent: 'flex-start' }}
      >
        {[1, 2, 3].map((item) => (
          <Skeleton
            key={item}
            variant="rectangular"
            width={300}
            height={400}
            className={classes.skeleton}
            style={{ margin: '0 0.5rem' }}
          />
        ))}
      </div>
      <div className={classes.centerRow}>
        <Skeleton
          variant="text"
          width="25%"
          height={60}
          className={classes.skeleton}
          animation="wave"
          style={{ marginTop: '1rem' }}
        />
      </div>
      <div
        className={classes.cardsContainer}
        style={{ justifyContent: 'flex-start' }}
      >
        {Array.from(isMatchingMD ? [1] : [1, 2, 3, 4]).map((element) => (
          <Skeleton
            key={element}
            variant="rectangular"
            width={300}
            height={300}
            className={classes.skeleton}
            classes={{
              root: `${classes.skeletonRoot} ${classes.card}`
            }}
          />
        ))}
      </div>
    </div>
  )
}
