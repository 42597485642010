import {
  ChangeEvent,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { GenericSelectItemOption } from 'interfaces/PageFilters'
import { ServiceComplianceHistory } from 'lib/CloudCanariesRestfulAPI'
import {
  deDuplicateServiceProviderOptions,
  deDuplicateServicesOptions
} from 'services/utils'
import { APIServiceContext } from './APIServiceContext'
import { AuthContext } from './AuthContext'
import { OrganizationsContext } from './OrganizationsContext'

const refreshInterval = 5
interface ComplianceHistoryContextProviderProps {
  children: ReactNode
}

interface ComplianceHistoryContextProps {
  areComplianceHistoriesLoading: boolean
  filteredComplianceHistories: ServiceComplianceHistory[]
  providersOptions: GenericSelectItemOption[]
  servicessOptions: GenericSelectItemOption[]
  selectedProvider: string
  selectedService: string
  rowsPerPage: number
  page: number
  rowCount: number
  setSelectedProvider: (value: string) => void
  setSelectedService: (value: string) => void
  fetchComplianceHistories: () => Promise<void>
  onRowsPerPageChange: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void
  handleChangePage: (event: any, newPage: number) => void
}
export const ComplianceHistoryContext =
  createContext<ComplianceHistoryContextProps>(null as any)

export default function ComplianceHistoryContextProvider(
  props: ComplianceHistoryContextProviderProps
) {
  const { children } = props
  const { isAuthenticated } = useContext(AuthContext)

  const { organizationId } = useContext(OrganizationsContext)

  const { apiService } = useContext(APIServiceContext)

  const [page, setPage] = useState<number>(0)
  const [rowCount, setRowCount] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)

  const [areComplianceHistoriesLoading, setAreComplianceHistoriesLoading] =
    useState(true)

  const [complianceHistories, setComplianceHistories] = useState<
    ServiceComplianceHistory[]
  >([])
  const [filteredComplianceHistories, setFilteredComplianceHistories] =
    useState<ServiceComplianceHistory[]>([])
  const [providersOptions, setProvidersOptions] = useState<
    GenericSelectItemOption[]
  >([])
  const [servicessOptions, setServicesOptions] = useState<
    GenericSelectItemOption[]
  >([])
  const [selectedProvider, setSelectedProvider] = useState<string>('')
  const [selectedService, setSelectedService] = useState<string>('')

  useEffect(() => {
    setRowCount(0)
  }, [organizationId])

  const fetchComplianceHistories = useCallback(async () => {
    if (isAuthenticated()) {
      setRowCount(0)
      const requestedPage = page + 1

      setAreComplianceHistoriesLoading(true)
      await apiService
        .listServiceComplianceHistories(
          requestedPage,
          rowsPerPage,
          organizationId
        )
        .then((json) => {
          setComplianceHistories(json.data.results ?? [])
          setRowCount(json.data.count ?? 0)
          setAreComplianceHistoriesLoading(false)
        })
    }
  }, [apiService, isAuthenticated, organizationId, page, rowsPerPage])

  useEffect(() => {
    fetchComplianceHistories()
    const sync = setInterval(
      () => {
        if (isAuthenticated()) {
          fetchComplianceHistories()
        }
      },
      60 * 1000 * refreshInterval
    )

    return () => clearInterval(sync)
  }, [fetchComplianceHistories, isAuthenticated])

  useEffect(() => {
    const providers = deDuplicateServiceProviderOptions(complianceHistories)
    setProvidersOptions(providers)
  }, [complianceHistories])

  useEffect(() => {
    const services = deDuplicateServicesOptions(complianceHistories)
    setServicesOptions(services)
  }, [complianceHistories])

  useEffect(() => {
    setSelectedProvider('')
    setSelectedService('')
  }, [organizationId])

  useEffect(() => {
    let filteredComplianceHistories = [...complianceHistories]
    if (selectedProvider !== '') {
      filteredComplianceHistories = filteredComplianceHistories.filter(
        (history) => history.provider_id === selectedProvider
      )
    }
    if (selectedService !== '') {
      filteredComplianceHistories = filteredComplianceHistories.filter(
        (history) => history.service === selectedService
      )
    }
    setFilteredComplianceHistories(filteredComplianceHistories)
  }, [complianceHistories, selectedProvider, selectedService])

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage)
  }

  const onRowsPerPageChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <ComplianceHistoryContext.Provider
      value={{
        areComplianceHistoriesLoading,
        filteredComplianceHistories,
        providersOptions,
        servicessOptions,
        selectedProvider,
        selectedService,
        page,
        rowCount,
        rowsPerPage,
        setSelectedProvider,
        setSelectedService,
        fetchComplianceHistories,
        handleChangePage,
        onRowsPerPageChange
      }}
    >
      {children}
    </ComplianceHistoryContext.Provider>
  )
}
