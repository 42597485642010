import { useCallback, useContext, useEffect, useState } from 'react'
import Divider from '@mui/material/Divider'
import { makeStyles } from '@mui/styles'
import UserOrganizationManageMembers from 'components/UserOrganization/ManageMembers'
import CustomModal from 'components/common/CustomModal'
import PageHeader from 'components/common/PageHeader'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { OrganizationsContext } from 'contexts/OrganizationsContext'
import { GenericIDMatchProps } from 'interfaces/GenericIDMatchProps'
import { CreateBtn } from 'styles/components/common/Buttons.styled'
import { PageContainer } from 'styles/pages/CanaryManage.styled'
import UserOrganizationAddMember from './UserOrganizationAddMember'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center'
  }
})

export default function UserOrganizationManage(props: GenericIDMatchProps) {
  const {
    match: {
      params: { id: organizationId }
    }
  } = props

  const classes = useStyles()

  const { apiService } = useContext(APIServiceContext)
  const { userOrganizations, setUserSelectedOrganization } =
    useContext(OrganizationsContext)
  const [organizationName, setOrganizationName] = useState<string>('')
  const [showModal, setShowModal] = useState(false)

  const getOrganizationDetails = useCallback(async () => {
    await apiService
      .retrieveOrganization(organizationId)
      .then((json) => setOrganizationName(json.data.name ?? ''))
  }, [apiService, organizationId])

  useEffect(() => {
    const org = userOrganizations.find((o) => o.id === organizationId)
    if (org) {
      setUserSelectedOrganization(org)
    }
    getOrganizationDetails()
  }, [
    userOrganizations,
    organizationId,
    getOrganizationDetails,
    setUserSelectedOrganization
  ])
  return (
    <PageContainer>
      <PageHeader title="Editing Organization" />
      <div className={classes.root}>
        <h2>{organizationName}</h2>
        <CreateBtn onClick={() => setShowModal(true)}>+ Add Member</CreateBtn>
      </div>
      <CustomModal
        width="35%"
        showModal={showModal}
        setShowModal={setShowModal}
      >
        <UserOrganizationAddMember id={organizationId} />
      </CustomModal>
      <Divider
        style={{
          height: '5px'
        }}
      />
      <UserOrganizationManageMembers organizationId={organizationId} />
    </PageContainer>
  )
}
