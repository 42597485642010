import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { queryParamPageSizeJumbo } from 'constants/AppConfig'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { OrganizationsContext } from 'contexts/OrganizationsContext'
import {
  NeuralCanaryDailyForecast,
  SnowflakeCanaryDailyForecast
} from 'lib/CloudCanariesRestfulAPI'
import DateTimeService from 'services/DateTimeService'
import ForecastCanaryGridItem from './ForecastCanaryGridItem'

export interface CanaryDailyForecasts {
  id?: string
  for_date?: string[]
  day_of_week?: string[]
  percentage_chance_of_incident?: number[]
  latency_issues_predicted?: number[]
  service_id?: string
  service_name?: string
  provider_id?: string
  provider_name?: string
  canary_name?: string
  canary?: string
  has_prediction?: boolean[]
  task_name?: string
  template_canary_name?: string
  predictedResponseValue?: string
  responseLatency?: number
}

const page = undefined

const defaultDateTimes = DateTimeService.getForecastDefaultDateTimes()
const dateStart = defaultDateTimes.dateStart
const dateStop = defaultDateTimes.dateStop
interface IndividualCanaryForecastsSectionProps {
  serviceId: string
  selectedProviderId: string
}

export default function IndividualCanaryForecastsSection(
  props: IndividualCanaryForecastsSectionProps
) {
  const { serviceId, selectedProviderId } = props

  const { apiService } = useContext(APIServiceContext)
  const { organization, organizationId } = useContext(OrganizationsContext)

  const isUsingSnowflakeCortex = useMemo<boolean>(
    () => organization?.use_cortex_forecast_model!,
    [organization?.use_cortex_forecast_model]
  )
  const [
    neuralProphetCanaryDailyForecasts,
    setNeuralProphetCanaryDailyForecasts
  ] = useState<NeuralCanaryDailyForecast[]>([])
  const [snowflakeCanaryDailyForecasts, setSnowflakeCanaryDailyForecasts] =
    useState<SnowflakeCanaryDailyForecast[]>([])

  const fetchNeuralProphetCanaryDailyForecasts = useCallback(async () => {
    if (!isUsingSnowflakeCortex) {
      await apiService
        .listNeuralProphetCanaryDailyForecasts(
          page,
          queryParamPageSizeJumbo,
          dateStart,
          dateStop,
          organizationId
        )
        .then((json) => {
          setNeuralProphetCanaryDailyForecasts(json.data.results ?? [])
        })
    } else {
      await apiService
        .listSnowflakeCanaryDailyForecasts(
          page,
          queryParamPageSizeJumbo,
          dateStart,
          dateStop,
          organizationId
        )
        .then((json) => {
          setSnowflakeCanaryDailyForecasts(json.data.results ?? [])
        })
    }
  }, [apiService, isUsingSnowflakeCortex, organizationId])

  useEffect(() => {
    fetchNeuralProphetCanaryDailyForecasts()
  }, [fetchNeuralProphetCanaryDailyForecasts])

  const filteredCanariesForecasts = useMemo(() => {
    let _filteredCanaries = isUsingSnowflakeCortex
      ? snowflakeCanaryDailyForecasts
      : neuralProphetCanaryDailyForecasts

    if (serviceId) {
      _filteredCanaries = _filteredCanaries.filter(
        (o) => o.service_id === serviceId
      )
    }

    if (selectedProviderId) {
      _filteredCanaries = _filteredCanaries.filter(
        (o) => o.provider_id === selectedProviderId
      )
    }

    return _filteredCanaries
  }, [
    isUsingSnowflakeCortex,
    neuralProphetCanaryDailyForecasts,
    selectedProviderId,
    serviceId,
    snowflakeCanaryDailyForecasts
  ])

  const sortedReducedCanaryDailyForecasts = useMemo<
    CanaryDailyForecasts[]
  >(() => {
    const data = filteredCanariesForecasts.reduce(
      (result: CanaryDailyForecasts[], current) => {
        const existingEntry = result.find(
          (item) => item.canary_name === current.canary_name
        )!

        if (existingEntry) {
          existingEntry.percentage_chance_of_incident?.push(
            current.percentage_chance_of_incident!
          )
          existingEntry.latency_issues_predicted?.push(
            current.latency_issues_predicted!
          )
          existingEntry.day_of_week?.push(current.day_of_week!)
          existingEntry.for_date?.push(current.for_date!)
          existingEntry.has_prediction?.push(current.has_prediction!)
        } else {
          const newEntry: CanaryDailyForecasts = {
            canary_name: current.canary_name!,
            percentage_chance_of_incident: [
              current.percentage_chance_of_incident!
            ],
            latency_issues_predicted: [current.latency_issues_predicted!],
            id: current.id!,
            canary: current.canary,
            day_of_week: [current.day_of_week!],
            for_date: [current.for_date!],
            has_prediction: [current.has_prediction!],
            provider_id: current.provider_id,
            provider_name: current.provider_name,
            service_id: current.service_id,
            service_name: current.service_name,
            task_name: current.task_name,
            template_canary_name: current.template_canary_name
          }
          result.push(newEntry)
        }

        return result
      },
      []
    )
    return data
  }, [filteredCanariesForecasts])

  const hasVisibleCanariesForecasts =
    neuralProphetCanaryDailyForecasts.length > 0 ||
    snowflakeCanaryDailyForecasts.length > 0

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} style={{ alignContent: 'center' }}>
          <Typography
            gutterBottom
            variant="h4"
            component="div"
            align={'center'}
            style={{
              marginTop: '32px',
              marginBottom: '32px'
            }}
          >
            Individual Canary Forecasts
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        justifyContent={hasVisibleCanariesForecasts ? 'flex-start' : 'center'}
      >
        {hasVisibleCanariesForecasts ? (
          sortedReducedCanaryDailyForecasts.map((canaryDailyForecast) => (
            <Grid key={canaryDailyForecast.canary} item xs={12} md={3} lg={2}>
              <ForecastCanaryGridItem
                canaryDailyForecast={canaryDailyForecast}
              />
            </Grid>
          ))
        ) : (
          <Typography variant="h3">No canaries found...</Typography>
        )}
      </Grid>
    </>
  )
}
