import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import SharedStyles from './SharedStyles'

export default function SnowFlakeSettingLoader() {
  const classes = SharedStyles()
  return (
    <Box className={classes.loadingSkeletons}>
      {Array.from([1, 2, 3, 4, 5, 6]).map((element) => (
        <Box
          key={element}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: '0.25rem 0',
            alignItems: 'center'
          }}
        >
          <Skeleton
            variant="rectangular"
            width="95%"
            height={50}
            sx={{
              margin: '0.25rem 0',
              borderRadius: 4,
              backgroundColor: 'rgba(0, 0, 0, 0.08)',
              mr: '0.25rem'
            }}
          />
          <Skeleton
            variant="rectangular"
            width="95%"
            height={50}
            sx={{
              margin: '0.25rem 0',
              borderRadius: 4,
              backgroundColor: 'rgba(0, 0, 0, 0.08)',
              ml: '0.25rem'
            }}
          />
        </Box>
      ))}
    </Box>
  )
}
