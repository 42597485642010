import {
  selectDateFrom,
  selectDateTo,
  selectTimeFrom,
  selectTimeTo,
  setDateFrom,
  setDateTo,
  setTimeFrom,
  setTimeTo
} from 'client_side_state/slices/pageFilter'
import { useAppDispatch } from 'client_side_state/store'
import { useAppSelector } from 'client_side_state/store'
import dayjs, { Dayjs } from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { SelectChangeEvent } from '@mui/material/Select'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import DateTimeService from 'services/DateTimeService'
import LastPeriodService, { LastPeriodOption } from 'services/LastPeriodService'
import LastPeriodSelection from './LastPeriodSelection'

dayjs.extend(utc)
dayjs.extend(timezone)

const datetimeService: DateTimeService = new DateTimeService()
const lastPeriodService = new LastPeriodService()
const lastPeriodsOptions = LastPeriodService.lastPeriodsOptions

export default function DateTimeFilter() {
  const history = useHistory()
  const dispatch = useAppDispatch()

  const { search } = history.location

  const [selectedLastPeriod, setSelectedLastPeriod] =
    useState<LastPeriodOption>(lastPeriodsOptions[1])

  const pageDateFromFilter = useAppSelector(selectDateFrom)
  const pageDateToFilter = useAppSelector(selectDateTo)
  const pageTimeFromFilter = useAppSelector(selectTimeFrom)
  const pageTimeToFilter = useAppSelector(selectTimeTo)

  useEffect(() => {
    lastPeriodService.findAndSetSelectedPeriod(lastPeriodsOptions[1].value)
  }, [])

  useEffect(() => {
    if (selectedLastPeriod !== lastPeriodsOptions[0]) {
      const { startDate, startTime, stopDate, stopTime } =
        lastPeriodService.getSelectedPeriodDateTimes()
      dispatch(setDateFrom(startDate))
      dispatch(setDateTo(stopDate))
      dispatch(setTimeFrom(startTime))
      dispatch(setTimeTo(stopTime))
    }
  }, [dispatch, selectedLastPeriod])

  useEffect(() => {
    const filter_start = datetimeService.datetimeformatted(
      pageDateFromFilter,
      pageTimeFromFilter
    )
    const filter_stop = datetimeService.datetimeformatted(
      pageDateToFilter,
      pageTimeToFilter
    )
    const _search = `?date_start=${filter_start}&date_end=${filter_stop}`

    if (_search !== search) {
      history.replace({ search: _search })
    }
  }, [
    search,
    pageDateFromFilter,
    pageTimeFromFilter,
    pageDateToFilter,
    pageTimeToFilter,
    history
  ])

  useEffect(() => {
    const searchParams: any = new URLSearchParams(search)
    const start: string = searchParams['?date_start']
    const end: string = searchParams['date_end']

    if (start) {
      const _date_start =
        datetimeService.dateStringFromParsedDateTimeString(start)
      const _time_start =
        datetimeService.timeStringFromParsedDateTimeString(start)
      if (_date_start !== pageDateFromFilter) {
        const _start =
          datetimeService.calendarDateInputToDjangoString(_date_start)
        dispatch(setDateFrom(_start))
      }
      if (_time_start !== pageTimeFromFilter) {
        dispatch(setTimeFrom(_time_start))
      }
    }
    if (end) {
      const _date_stop = datetimeService.dateStringFromParsedDateTimeString(end)
      const _time_stop = datetimeService.timeStringFromParsedDateTimeString(end)
      if (_date_stop !== pageDateToFilter) {
        const _stop =
          datetimeService.calendarDateInputToDjangoString(_date_stop)
        dispatch(setDateTo(_stop))
      }
      if (_time_stop !== pageTimeToFilter) {
        dispatch(setTimeTo(_time_stop))
      }
    }
  }, [
    search,
    pageDateFromFilter,
    pageTimeFromFilter,
    pageDateToFilter,
    pageTimeToFilter,
    dispatch
  ])

  const handleLastPeriodChange = (event: SelectChangeEvent) => {
    const selectedValue = lastPeriodService.findAndSetSelectedPeriod(
      event.target.value as string
    )
    if (selectedValue) {
      setSelectedLastPeriod(selectedValue)
    }
  }

  function resetSelectedPeriod() {
    setSelectedLastPeriod(lastPeriodsOptions[0])
  }

  const handleDateStartAccept = (selectedDayStart: Dayjs | null) => {
    const _start = datetimeService.calendarDateInputToDjangoString(
      selectedDayStart?.toString()!
    )
    dispatch(setDateFrom(_start))
    dispatch(setTimeFrom(selectedDayStart?.toISOString()?.slice(11, 16)!))
    resetSelectedPeriod()
  }

  const handleDateStopAccept = (selectedDayEnd: Dayjs | null) => {
    const _stop = datetimeService.calendarDateInputToDjangoString(
      selectedDayEnd?.toString()!
    )
    dispatch(setDateTo(_stop))
    dispatch(setTimeTo(selectedDayEnd?.toISOString()?.slice(11, 16)!))
    resetSelectedPeriod()
  }

  const dateTimeFrom = useMemo(
    () =>
      dayjs.utc(
        datetimeService.datetimeformatted(
          pageDateFromFilter,
          pageTimeFromFilter
        )
      ),
    [pageDateFromFilter, pageTimeFromFilter]
  )

  const dateTimeTo = useMemo(
    () =>
      dayjs.utc(
        datetimeService.datetimeformatted(pageDateToFilter, pageTimeToFilter)
      ),
    [pageDateToFilter, pageTimeToFilter]
  )

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div>
        <div className="DateModify">
          <span className="FromTo">From </span>
          <DateTimePicker
            value={dateTimeFrom}
            onAccept={handleDateStartAccept}
            timezone="UTC"
            disableFuture
            timeSteps={{ minutes: 1 }}
            ampm={false}
            slotProps={{
              textField: {
                size: 'small'
              }
            }}
            sx={{
              px: '0.5em'
            }}
          />
          <span className="FromTo">To </span>
          <DateTimePicker
            value={dateTimeTo}
            onAccept={handleDateStopAccept}
            timezone="UTC"
            disableFuture
            timeSteps={{ minutes: 1 }}
            ampm={false}
            slotProps={{
              textField: {
                size: 'small'
              }
            }}
            sx={{
              px: '0.5em'
            }}
          />
          <div className="TimePicker">
            <LastPeriodSelection
              selectedLastPeriod={selectedLastPeriod}
              lastPeriodsOptions={lastPeriodsOptions}
              handleLastPeriodChange={handleLastPeriodChange}
            />
          </div>
        </div>
      </div>
    </LocalizationProvider>
  )
}
