import Typography from '@mui/material/Typography'
import logo from 'assets/logo.png'
import Icon, { IconType } from 'components/Icons'
import Button from 'components/common/Button'
import { Container } from 'styles/components/CanaryEditor/EditorMenu.styled'

interface TopBarProps {
  hasUnsavedData: boolean
  onClose: () => void
}

export default function TopBar(props: TopBarProps) {
  const { hasUnsavedData, onClose } = props

  const checkUnsavedChanges = (): boolean =>
    !hasUnsavedData ||
    window.confirm('You have unsaved changes. Are you sure you want to leave?')

  return (
    <Container>
      <img src={logo} width="45" height="45" alt="logo" />
      <Typography variant="h5" style={{ fontWeight: 700 }} className="left-end">
        Create Canary - Properties
      </Typography>
      <Button
        label={''}
        leftIcon={<Icon name={IconType.X} />}
        action={() => {
          if (!checkUnsavedChanges()) return
          onClose()
        }}
      />
    </Container>
  )
}
