import { CanaryStatusEnum } from 'lib/CloudCanariesRestfulAPI'

interface CanaryStatusButtonProps {
  status: CanaryStatusEnum
}

export default function CanaryStatusButton(props: CanaryStatusButtonProps) {
  const { status } = props
  return <span className={status.toLocaleLowerCase()}>{status}</span>
}
