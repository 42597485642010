import APIKeysContextProvider from 'contexts/APIKeysContext'
import CanariesContextProvider from 'contexts/CanariesContext'
import Canaries from 'pages/Dashboards/Canaries'

export default function DashboardCanaries() {
  return (
    <APIKeysContextProvider>
      <CanariesContextProvider>
        <Canaries />
      </CanariesContextProvider>
    </APIKeysContextProvider>
  )
}
