import { useState } from 'react'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Icon, { IconType } from 'components/Icons'
import DialogModal from 'components/Modals/DialogModal'
import ModalSharedStyles from 'components/Modals/ModalSharedStyles'

type ForecastModelsDeleteModalProps = {
  currentName: string
  afterSubmit: () => void
}

export default function ForecastModelsDeleteModal(
  props: ForecastModelsDeleteModalProps
) {
  const { currentName, afterSubmit } = props
  const classes = ModalSharedStyles()

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)

  const handleClickOpen = () => {
    setDeleteConfirmOpen(true)
  }

  const handleClose = () => {
    setDeleteConfirmOpen(false)
  }

  const confirmDelete = () => {
    afterSubmit()
  }

  return (
    <>
      <IconButton size="small" onClick={handleClickOpen}>
        <Icon name={IconType.Trash} color="error" />
      </IconButton>
      <DialogModal
        open={deleteConfirmOpen}
        onClose={handleClose}
        dialogTitle="Delete Forecast Model"
        dialogContent={
          <Typography variant="body1" align="center">
            {`Do you really want to delete "${currentName}"?`}
          </Typography>
        }
        dialogActions={
          <>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              onClick={confirmDelete}
              variant="contained"
              className={classes.deleteButton}
              autoFocus
            >
              Delete
            </Button>
          </>
        }
      />
    </>
  )
}
