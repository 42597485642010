import {
  selectDateFrom,
  selectDateTo,
  selectTimeFrom,
  selectTimeTo
} from 'client_side_state/slices/pageFilter'
import { useAppSelector } from 'client_side_state/store'
import { useCallback, useContext, useEffect, useState } from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TabPanel from 'components/common/MUITabPanel'
import { queryParamPageSizeDefault } from 'constants/AppConfig'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { SimpleAlarmEvaluation } from 'lib/CloudCanariesRestfulAPI'
import DateTimeService from 'services/DateTimeService'

const datetimeService: DateTimeService = new DateTimeService()

interface PerAlarmAlarmEvaluationsTabPanelProps {
  alarmId: string
  selectedTab: number
  tabIndex: number
}

export default function PerAlarmAlarmEvaluationsTabPanel({
  alarmId,
  selectedTab,
  tabIndex
}: PerAlarmAlarmEvaluationsTabPanelProps) {
  const { apiService } = useContext(APIServiceContext)

  const isSelected = selectedTab === tabIndex
  const rowsPerPage = queryParamPageSizeDefault
  const rowsPerPageOptions = [rowsPerPage]
  const [page, setPage] = useState<number>(0)
  const [rowCount, setRowCount] = useState<number>(0)
  const [alarmEvaluations, setAlarmEvaluations] = useState<
    SimpleAlarmEvaluation[]
  >([])

  const pageDateFromFilter = useAppSelector(selectDateFrom)
  const pageDateToFilter = useAppSelector(selectDateTo)
  const pageTimeFromFilter = useAppSelector(selectTimeFrom)
  const pageTimeToFilter = useAppSelector(selectTimeTo)

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage)
  }

  const getEvaluations = useCallback(async () => {
    if (isSelected) {
      const requestedPage = page + 1
      const start = datetimeService.datetimeformatted(
        pageDateFromFilter,
        pageTimeFromFilter
      )
      const stop = datetimeService.datetimeformatted(
        pageDateToFilter,
        pageTimeToFilter
      )
      const djangoDateTimeStart = datetimeService.djangoDateTimeValue(start)
      const djangoDateTimeStop = datetimeService.djangoDateTimeValue(stop)

      await apiService
        .listSimpleAlarmEvaluations(
          requestedPage,
          queryParamPageSizeDefault,
          alarmId,
          djangoDateTimeStart,
          djangoDateTimeStop
        )
        .then((json) => {
          setRowCount(json.data.count ?? 0)
          setAlarmEvaluations(json.data.results ?? [])
        })
    }
  }, [
    isSelected,
    page,
    pageDateFromFilter,
    pageTimeFromFilter,
    pageDateToFilter,
    pageTimeToFilter,
    apiService,
    alarmId
  ])

  useEffect(() => {
    getEvaluations()
  }, [getEvaluations])

  return (
    <TabPanel value={selectedTab} index={tabIndex}>
      <TablePagination
        component="div"
        count={rowCount}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        page={page}
        onPageChange={handleChangePage}
      />
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">From</TableCell>
              <TableCell align="center">To</TableCell>
              <TableCell align="center">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {alarmEvaluations.map((alarmEvaluation) => (
              <TableRow key={alarmEvaluation.id}>
                <TableCell align="center">
                  {alarmEvaluation.period_start}
                </TableCell>
                <TableCell align="center">
                  {alarmEvaluation.period_stop}
                </TableCell>
                <TableCell align="center">{alarmEvaluation.status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={rowCount}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        page={page}
        onPageChange={handleChangePage}
      />
    </TabPanel>
  )
}
