import useIsMatchingMD from 'hooks/useIsMatchingMD'
import Skeleton from '@mui/material/Skeleton'
import SharedStyles from './SharedStyles'

export default function CanariesGridLoader() {
  const classes = SharedStyles()
  const isMatchingMD = useIsMatchingMD()

  return (
    <div className={classes.loadingSkeletons}>
      <div className={classes.centerRow}>
        <Skeleton
          variant="text"
          width="10%"
          height={50}
          className={classes.skeleton}
          animation="wave"
        />
      </div>
      <div className={classes.skeletonsRow}>
        <Skeleton
          variant="rectangular"
          width="45%"
          height={40}
          className={classes.skeleton}
        />
        <Skeleton
          variant="rectangular"
          width="15%"
          height={40}
          className={classes.skeleton}
        />
      </div>
      <div className={classes.cardsContainer}>
        {Array.from(isMatchingMD ? [1] : [1, 2, 3, 4]).map((element) => (
          <Skeleton
            key={element}
            variant="rectangular"
            width={300}
            height={300}
            className={classes.skeleton}
            classes={{
              root: `${classes.skeletonRoot} ${classes.card}`
            }}
          />
        ))}
      </div>
    </div>
  )
}
