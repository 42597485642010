import { combineReducers } from 'redux'
import appReducer from './app'
import canaryAlarmPageReducer from './canaryAlarmPage'
import canaryDashboardReducer from './canaryDashboard'
import forecastManagePageReducer from './forecastManagePage'
import forecastPageReducer from './forecastPage'
import pageFilterReducer from './pageFilter'
import serviceComplianceReducer from './serviceCompliance'
import serviceHealthReducer from './serviceHealth'
import userReducer from './user'

const rootReducer = combineReducers({
  app: appReducer,
  user: userReducer,
  page: pageFilterReducer,
  serviceHealth: serviceHealthReducer,
  serviceCompliance: serviceComplianceReducer,
  canaryAlarmPage: canaryAlarmPageReducer,
  forecastPage: forecastPageReducer,
  forecastManagePage: forecastManagePageReducer,
  canaryDashboard: canaryDashboardReducer
})

export default rootReducer
