export enum ToastErrors {
  WRONG_AUTH_CREDENTIALS = 'Oops, username or password is wrong!',
  NO_PASSWORDS_MATCH = 'Sorry, wrong temp password or passwords do not match!',
  ERROR_RETRIEVING_AUTH_PROFILE = 'Error Retrieving User Profile',
  ERROR_UPDATING_CANARY_IMAGE = 'Error updating Canary image',
  ERROR_DELETING_CANARIES = 'Error deleting Canary',
  ERROR_UPDATING_CANARIES_CLUSTER = 'Error updating Canary cluster',
  ERROR_STARTING_CANARIES = 'Error starting Canary',
  ERROR_STOPPING_CANARIES = 'Error stopping Canary',
  ERROR_UPDATING_CANARIES_SCHEDULE = 'Error updating Canary schedule:',
  ERROR_ASSIGNING_CANARY_MAX_EXCEEDED = 'Error assigning canaries because limit exceeded!',
  ERROR_SUBTRACTING_CANARY = 'Error subtracting canaries!',
  ERROR_SKIP_AUTOLOAD_CANARY_TEMPLATES = 'Error skipping auto load canary template libraries. Please try again',
  ERROR_AUTOLOAD_CANARY_TEMPLATES = 'Error auto loading canary template libraries. Please try again',
  WARN_SELECT_CANARY_TEMPLATES = 'Please select at least one Canary Template Library before autoloading'
}
