import { history } from 'client_side_state/history'
import { configureAppStore } from 'client_side_state/store'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { Helmet } from 'react-helmet'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import CssBaseline from '@mui/material/CssBaseline'
import { StyledEngineProvider, createTheme } from '@mui/material/styles'
import { ThemeProvider } from '@mui/styles'
import APIServiceContextProvider, {
  useAPIServiceContext
} from 'contexts/APIServiceContext'
import AuthContextProvider from 'contexts/AuthContext'
import 'styles/globals.scss'
import App from 'App'

const pkg = require('../package.json')

const prodEnv = pkg.environment === 'prod'

const theme = createTheme()
const rootElement = document.getElementById('root')
const root = createRoot(rootElement!)

const WrappedApp: React.FC = () => {
  const apiServiceContextValue = useAPIServiceContext()
  const { store, persistor } = configureAppStore(apiServiceContextValue)

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router history={history}>
          <App />
        </Router>
      </PersistGate>
    </Provider>
  )
}

root.render(
  <StrictMode>
    {!prodEnv && (
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
    )}
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AuthContextProvider>
          <APIServiceContextProvider>
            <WrappedApp />
          </APIServiceContextProvider>
        </AuthContextProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </StrictMode>
)
