import {
  selectServiceProviderOptions,
  selectServiceProviderSelected,
  setServiceProviderOptionValueSelected
} from 'client_side_state/slices/pageFilter'
import { useAppDispatch, useAppSelector } from 'client_side_state/store'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'

export default function PageFilterServiceProvider() {
  const dispatch = useAppDispatch()

  const selectedValue = useAppSelector(selectServiceProviderSelected)
  const options = useAppSelector(selectServiceProviderOptions)

  const onChange = (event: SelectChangeEvent) => {
    dispatch(setServiceProviderOptionValueSelected(String(event.target.value)))
  }

  if (!options || options.length <= 0) return <></>
  return (
    <FormControl size="small">
      <InputLabel id="select-helper-label-page-filter-service-provider">
        {Boolean(selectedValue) ? 'Service Provider' : null}
      </InputLabel>
      <Select
        style={{ minWidth: 240 }}
        labelId="select-helper-label-page-filter-service-provider"
        id="select-helper-page-filter-service-provider"
        value={selectedValue}
        onChange={onChange}
        autoWidth
        displayEmpty={true}
        label={Boolean(selectedValue) ? 'Service Provider' : null}
      >
        <MenuItem value="">
          <em>All Providers</em>
        </MenuItem>
        {options.map((o) => (
          <MenuItem key={o.value} value={o.value}>
            {o.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
