import { useContext, useState } from 'react'
import { toast } from 'react-toastify'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Icon, { IconType } from 'components/Icons'
import DialogModal from 'components/Modals/DialogModal'
import ModalSharedStyles from 'components/Modals/ModalSharedStyles'
import Toast from 'components/Toast'
import { APIKeysContext } from 'contexts/APIKeysContext'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { OrganizationsContext } from 'contexts/OrganizationsContext'
import { OrganizationAPIKey } from 'lib/CloudCanariesRestfulAPI'

interface DeleteModalProps {
  apiKey: OrganizationAPIKey
}

export default function DeleteModal(props: DeleteModalProps) {
  const { apiKey } = props
  const classes = ModalSharedStyles()

  const { apiService } = useContext(APIServiceContext)
  const { organizationId } = useContext(OrganizationsContext)
  const { fetchAPIKeys } = useContext(APIKeysContext)

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)

  const handleClickOpen = () => {
    setDeleteConfirmOpen(true)
  }

  const handleClose = () => {
    setDeleteConfirmOpen(false)
  }

  async function deleteAPIKey() {
    if (apiKey?.prefix) {
      await apiService
        .destroyOrganizationAPIKey(apiKey.prefix, organizationId)
        .then((response) => {
          if (response.status === 204) {
            toast.success(
              <Toast
                title="Success"
                text={`API Key "${apiKey.name}" is deleted successfully!`}
              />,
              {
                position: 'top-right'
              }
            )
          } else {
            toast.error(
              <Toast
                title="Error"
                text="Something went wrong, please try again!"
              />,
              {
                position: 'top-right'
              }
            )
          }
          fetchAPIKeys()
        })
    }
  }

  const confirmDelete = () => {
    deleteAPIKey()
    handleClose()
  }

  return (
    <>
      <IconButton
        size="small"
        onClick={handleClickOpen}
        disabled={!apiKey.revoked}
      >
        <Icon
          name={IconType.Trash}
          color={!apiKey.revoked ? 'disabled' : 'error'}
        />
      </IconButton>
      <DialogModal
        dialogTitle="Delete API Key"
        open={deleteConfirmOpen}
        onClose={handleClose}
        dialogContent={
          <Typography variant="body1" align="center">
            Do you really want to delete key "{apiKey.name}"?
          </Typography>
        }
        dialogActions={
          <>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              onClick={confirmDelete}
              variant="contained"
              className={classes.deleteButton}
              autoFocus
            >
              Delete
            </Button>
          </>
        }
      />
    </>
  )
}
