interface ToastProps {
  title: string
  text: string
}

export default function Toast(props: ToastProps) {
  const { title, text } = props
  return (
    <div className="cc-toast">
      <div className="head">{title}</div>
      <div className="text">{text}</div>
    </div>
  )
}
