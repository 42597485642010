import { FormConfigTypes } from './FormConfigsTypes'

export const configCreateWallet: FormConfigTypes = {
  schema: {
    type: 'object',
    required: [
      'username',
      'password',
      'organization_id',
      'account_id',
      'warehouse',
      'database',
      'schema'
    ],
    properties: {
      username: { type: 'string', title: 'Snowflake Username' },
      password: { type: 'string', title: 'Snowflake Password' },
      organization_id: { type: 'string', title: 'Snowflake Organization ID' },
      account_id: { type: 'string', title: 'Snowflake Account ID' },
      warehouse: { type: 'string', title: 'Snowflake Warehouse Name' },
      database: { type: 'string', title: 'Snowflake Database name' },
      schema: { type: 'string', title: 'Snowflake Schema' }
    }
  },
  uiSchema: {
    password: {
      'ui:widget': 'password'
    }
  }
}
