import useDocumentTitle from 'hooks/useDocumentTitle'
import { useContext } from 'react'
import { toast } from 'react-toastify'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import { AdminPagesTitles } from 'components/MyAdminPages/Constants'
import MyAdminPagesCommonHeader from 'components/MyAdminPages/MyAdminPagesCommonHeader'
import NoDataFound from 'components/MyAdminPages/NoDataFound'
import Toast from 'components/Toast'
import UserOrganizationClusterCreateModal from 'components/UserOrganizationCluster/UserOrganizationClusterCreateModal'
import UserOrganizationClusterDeleteModal from 'components/UserOrganizationCluster/UserOrganizationClusterDeleteModal'
import UserOrganizationClusterEditModal from 'components/UserOrganizationCluster/UserOrganizationClusterEditModal'
import UserOrganizationClusterKubeConfigModal from 'components/UserOrganizationCluster/UserOrganizationClusterKubeConfigModal'
import UserOrganizationClusterViewSecretModal from 'components/UserOrganizationCluster/UserOrganizationClusterViewSecretModal'
import PagesSkeletonLoader from 'components/common/Loaders/PagesSkeletonLoader'
import { rowsPerPageOptions } from 'constants/AppConfig'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { ClustersContext } from 'contexts/ClustersContext'
import { OrganizationsContext } from 'contexts/OrganizationsContext'
import { KubernetesCluster } from 'lib/CloudCanariesRestfulAPI'
import adminPagesSharedStyles from 'pages/AdminPages/adminPagesSharedStyles'

export default function UserClusters() {
  const classes = adminPagesSharedStyles()

  useDocumentTitle('Clusters')

  const { apiService } = useContext(APIServiceContext)
  const { organizationId } = useContext(OrganizationsContext)

  const {
    areUserClustersLoading,
    userClusters,
    page,
    rowsPerPage,
    rowCount,
    onRowsPerPageChange,
    handleChangePage,
    fetchUserClusters
  } = useContext(ClustersContext)

  const deleteUserCluster = async (cluster: KubernetesCluster) => {
    await apiService
      .destroyKubernetesCluster(cluster.id!, organizationId)
      .then(async (response) => {
        if (response.status === 204) {
          toast.success(
            <Toast
              title="Success"
              text={`Cluster "${cluster.name}" is deleted successfully!`}
            />,
            {
              position: 'top-right'
            }
          )
        } else {
          toast.error(
            <Toast
              title="Error"
              text="Something went wrong, please try again!"
            />,
            {
              position: 'top-right'
            }
          )
        }
        await fetchUserClusters()
      })
  }

  return (
    <Container maxWidth="xl" className={classes.mainContainer}>
      <MyAdminPagesCommonHeader />
      <h1 className={classes.headerRow}>
        {AdminPagesTitles.Clusters}
        <UserOrganizationClusterCreateModal />
      </h1>
      {areUserClustersLoading ? (
        <PagesSkeletonLoader />
      ) : (
        <>
          <TableContainer component={Paper} elevation={3}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Kube Config</TableCell>
                  <TableCell>Image Registry Secret</TableCell>
                  <TableCell className={classes.firstCell}>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userClusters.length > 0 ? (
                  userClusters.map((userCluster) => {
                    return (
                      <TableRow key={userCluster.id}>
                        <TableCell>
                          <UserOrganizationClusterEditModal
                            cluster={userCluster}
                          />
                        </TableCell>
                        <TableCell>
                          <UserOrganizationClusterKubeConfigModal
                            cluster={userCluster}
                          />
                        </TableCell>
                        <TableCell>
                          <UserOrganizationClusterViewSecretModal
                            cluster={userCluster}
                          />
                        </TableCell>
                        <TableCell className={classes.firstCell}>
                          <UserOrganizationClusterDeleteModal
                            cluster={userCluster}
                            afterSubmit={() => deleteUserCluster(userCluster)}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <NoDataFound pageName="Clusters" colSpan={3} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={rowCount}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </>
      )}
    </Container>
  )
}
