import Box from '@mui/material/Box'
import Icon, { IconType } from 'components/Icons'

export default function CustomNoRowsOverlay() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
      }}
    >
      <Icon
        name={IconType.LocalLibraryIcon}
        fontSize="small"
        style={{ width: 240, height: 200, flexShrink: 0 }}
      />
      <Box sx={{ mt: 1 }}>No Canary Template Libraries</Box>
    </Box>
  )
}
