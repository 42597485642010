import useDocumentTitle from 'hooks/useDocumentTitle'
import { ChangeEvent, Key, useContext } from 'react'
import { Link } from 'react-router-dom'
import Checkbox from '@mui/material/Checkbox'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import { RawAxiosRequestConfig } from 'axios'
import AutoLoadCanaryTemplatesModal from 'components/AutoLoadCanaryTemplatesModal/AutoLoadCanaryTemplatesModal'
import CanaryCreateModal from 'components/CanaryCreator/CanaryCreateModal'
import AlarmingColumn from 'components/CanaryDashboard/AlarmingColumn'
import ModalConfirmDownloadAgentCanary from 'components/CanaryDashboard/Modals/ModalConfirmDownloadAgentCanary'
import ModalDeleteCanary from 'components/CanaryDashboard/Modals/ModalDeleteCanary'
import ModalEditCanary from 'components/CanaryDashboard/Modals/ModalEditCanary'
import ModalEditCanaryCluster from 'components/CanaryDashboard/Modals/ModalEditCanaryCluster'
import ModalEditCanaryImage from 'components/CanaryDashboard/Modals/ModalEditCanaryImage'
import ModalEditCanarySchedule from 'components/CanaryDashboard/Modals/ModalEditCanarySchedule'
import ModalStartCanary from 'components/CanaryDashboard/Modals/ModalStartCanary'
import ModalStopCanary from 'components/CanaryDashboard/Modals/ModalStopCanary'
import DashboardsCommonHeader from 'components/Dashboards/DashboardsCommonHeader'
import CanariesDashboardLoader from 'components/common/Loaders/CanariesDashboardLoader'
import PageFilterCanaryStatus from 'components/common/PageFilterCanaryStatus'
import SearchField from 'components/common/SearchField'
import { APIServiceContext } from 'contexts/APIServiceContext'
import AutoLoadCanaryTemplatesContextProvider from 'contexts/AutoLoadCanaryTemplatesContext'
import { CanariesContext } from 'contexts/CanariesContext'
import { CanaryReadWriteMinimalStatusEnum } from 'lib/CloudCanariesRestfulAPI'
import MetricsPageNavigation from 'pages/CustomPages/MetricsPageNavigation'
import { canaryDisplaySchedule } from 'services/Cron'
import RoutesService from 'services/routes'
import { cleanHumanReadableTaskName } from 'services/utils'
import {
  ControlsContainerLeft,
  ControlsContainerRight
} from 'styles/components/CanaryDashboard/ControlsContainer.styled'
import { DashboardContainer } from 'styles/components/CanaryDashboard/DashboardContainer.styled'

export default function DashboardCanaries() {
  useDocumentTitle('Canaries Dashboard')

  const { apiService } = useContext(APIServiceContext)
  const {
    canaries,
    page,
    rowCount,
    rowsPerPage,
    rowsPerPageOptions,
    areCanariesLoading,
    checkAllBoxSelected,
    selectedCanaryIds,
    ordering,
    searchInputText,
    setSelectedCanaryIds,
    updateSearchText,
    selectAll,
    onChangeSort,
    onChangeSelectRow,
    handleChangePage,
    onRowsPerPageChange
  } = useContext(CanariesContext)

  const onChangeSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      selectAll()
      return
    }
    setSelectedCanaryIds([])
  }

  const getSortDirection = (
    ordering: string | undefined,
    field_name: string
  ) => {
    if (!ordering) {
      return 'asc'
    } else {
      return ordering.indexOf(field_name) === 0 ? 'asc' : 'desc'
    }
  }

  const isSortColumnActive = (
    ordering: string | undefined,
    field_name: string
  ) => {
    if (!ordering) {
      return false
    } else {
      return (
        ordering.indexOf(field_name) === 0 || ordering.indexOf(field_name) === 1
      )
    }
  }
  async function downloadZip(canaryId: string, name?: string) {
    const options: RawAxiosRequestConfig = { responseType: 'blob' }

    await apiService
      .generateAndDownloadCanaryAgentCanary(canaryId, options)
      .then((response) => {
        if (response) {
          const blob = new Blob([response.data])
          const link = document.createElement('a')
          const url = URL.createObjectURL(blob)
          link.setAttribute('href', url)
          const zipFileName = name
            ? `${name}.zip`
            : `CanaryAgent-${canaryId}.zip`
          link.setAttribute('download', zipFileName)
          link.style.visibility = 'hidden'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      })
  }

  return (
    <DashboardContainer>
      {areCanariesLoading ? (
        <CanariesDashboardLoader />
      ) : (
        <>
          <AutoLoadCanaryTemplatesContextProvider>
            <AutoLoadCanaryTemplatesModal />
          </AutoLoadCanaryTemplatesContextProvider>
          <DashboardsCommonHeader pageName="Canaries" />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <ControlsContainerLeft>
                <CanaryCreateModal />
                <SearchField
                  searchText={searchInputText}
                  handleSubmit={(text) => updateSearchText(text)}
                />
                <PageFilterCanaryStatus />
              </ControlsContainerLeft>
            </Grid>
            <Grid item xs={12} md={6}>
              <ControlsContainerRight>
                <ModalDeleteCanary />
                <ModalEditCanarySchedule />
                <ModalEditCanaryImage />
                <ModalEditCanaryCluster />
                <ModalStartCanary />
                <ModalStopCanary />
              </ControlsContainerRight>
            </Grid>
          </Grid>

          <TableContainer component={Paper}>
            <Table
              aria-label="simple table"
              style={{ minHeight: areCanariesLoading ? 300 : 0 }}
            >
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      onChange={onChangeSelectAll}
                      checked={checkAllBoxSelected}
                      disabled={canaries.length === 0}
                    />
                  </TableCell>
                  <TableCell
                    sortDirection={getSortDirection(ordering, 'name')}
                    align="left"
                  >
                    <TableSortLabel
                      active={isSortColumnActive(ordering, 'name')}
                      direction={getSortDirection(ordering, 'name')}
                      onClick={(event) => onChangeSort(event, 'name')}
                    >
                      Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">Organization</TableCell>
                  <TableCell
                    sortDirection={getSortDirection(
                      ordering,
                      'template_canary__provider__name'
                    )}
                    align="left"
                  >
                    <TableSortLabel
                      active={isSortColumnActive(
                        ordering,
                        'template_canary__provider__name'
                      )}
                      direction={getSortDirection(
                        ordering,
                        'template_canary__provider__name'
                      )}
                      onClick={(event) =>
                        onChangeSort(event, 'template_canary__provider__name')
                      }
                    >
                      Provider
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    sortDirection={getSortDirection(
                      ordering,
                      'template_canary__service__name'
                    )}
                    align="left"
                  >
                    <TableSortLabel
                      active={isSortColumnActive(
                        ordering,
                        'template_canary__service__name'
                      )}
                      direction={getSortDirection(
                        ordering,
                        'template_canary__service__name'
                      )}
                      onClick={(event) =>
                        onChangeSort(event, 'template_canary__service__name')
                      }
                    >
                      Service
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    sortDirection={getSortDirection(
                      ordering,
                      'template_canary__name'
                    )}
                    align="left"
                  >
                    <TableSortLabel
                      active={isSortColumnActive(
                        ordering,
                        'template_canary__name'
                      )}
                      direction={getSortDirection(
                        ordering,
                        'template_canary__name'
                      )}
                      onClick={(event) =>
                        onChangeSort(event, 'template_canary__name')
                      }
                    >
                      Type
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    sortDirection={getSortDirection(ordering, 'cluster_name')}
                    align="left"
                  >
                    <TableSortLabel
                      active={isSortColumnActive(ordering, 'cluster_name')}
                      direction={getSortDirection(ordering, 'cluster_name')}
                      onClick={(event) => onChangeSort(event, 'cluster__name')}
                    >
                      Deploy Model
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    sortDirection={getSortDirection(ordering, 'status')}
                    align="center"
                  >
                    <TableSortLabel
                      active={isSortColumnActive(ordering, 'status')}
                      direction={getSortDirection(ordering, 'status')}
                      onClick={(event) => onChangeSort(event, 'status')}
                    >
                      Status
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">Schedule</TableCell>
                  <TableCell align="center">Frequency</TableCell>
                  <TableCell align="center">Alarms</TableCell>
                  <TableCell align="center">Notifications</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {canaries.map((canary) => {
                  return (
                    <TableRow key={canary.id}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={selectedCanaryIds.includes(canary.id!)}
                          onChange={(event) => {
                            onChangeSelectRow(event, canary.id!)
                          }}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <ModalEditCanary canaryId={canary.id} />
                        {canary.status ===
                          CanaryReadWriteMinimalStatusEnum.New &&
                        !canary.is_agent_canary ? (
                          <span style={{ color: 'black' }}>{canary.name}</span>
                        ) : (
                          <Link to={RoutesService.canaryManage(canary.id)}>
                            {canary.name}
                          </Link>
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {canary.organization_name}
                      </TableCell>
                      <TableCell align="left">{canary.provider_name}</TableCell>
                      <TableCell align="left">{canary.service_name}</TableCell>
                      <TableCell align="left">
                        {cleanHumanReadableTaskName(
                          canary.template_canary_name!
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {canary.is_agent_canary ? (
                          <ModalConfirmDownloadAgentCanary
                            canary={canary}
                            afterSubmit={() =>
                              downloadZip(canary.id!, canary.name)
                            }
                          />
                        ) : (
                          canary.cluster_name
                        )}
                      </TableCell>
                      <TableCell align="center">{canary.status}</TableCell>
                      <TableCell align="center">
                        {canaryDisplaySchedule(
                          canary.schedule!
                        ).displaySchedule.map(
                          (bool: boolean, index: Key | null | undefined) => (
                            <span className={`dot ${bool}`} key={index} />
                          )
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {canaryDisplaySchedule(canary.schedule!).frequency}
                      </TableCell>
                      <TableCell align="center">
                        <AlarmingColumn canary={canary} />
                      </TableCell>
                      <TableCell align="center">
                        <MetricsPageNavigation canary={canary} />
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={rowCount}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </>
      )}
    </DashboardContainer>
  )
}
