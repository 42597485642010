import { useState } from 'react'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Icon, { IconType } from 'components/Icons'
import DialogModal from 'components/Modals/DialogModal'
import { KubernetesCluster } from 'lib/CloudCanariesRestfulAPI'

interface UserOrganizationClusterDeleteModalProps {
  cluster: KubernetesCluster
  afterSubmit: () => void
}

export default function UserOrganizationClusterDeleteModal(
  props: UserOrganizationClusterDeleteModalProps
) {
  const { cluster, afterSubmit } = props

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)

  const handleClickOpen = () => {
    setDeleteConfirmOpen(true)
  }

  const handleClose = () => {
    setDeleteConfirmOpen(false)
  }

  const confirmDelete = () => {
    afterSubmit()
  }

  return (
    <>
      <IconButton
        size="small"
        onClick={handleClickOpen}
        disabled={cluster.is_internal}
        title={cluster.is_internal ? "Can't delete system cluster" : 'Delete'}
      >
        <Icon
          name={IconType.Trash}
          color={cluster.is_internal ? 'disabled' : 'error'}
        />
      </IconButton>
      <DialogModal
        open={deleteConfirmOpen}
        onClose={handleClose}
        dialogTitle="Delete Cluster"
        dialogContent={
          <Typography variant="body1" align="center">
            {`Do you really want to delete "${cluster.name}"?`}
          </Typography>
        }
        dialogActions={
          <>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              onClick={confirmDelete}
              variant="contained"
              sx={{
                color: '#ffffff',
                backgroundColor: '#d32f2f',
                '&:hover': {
                  backgroundColor: '#d32f3f'
                }
              }}
              autoFocus
            >
              Delete
            </Button>
          </>
        }
      />
    </>
  )
}
