import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

export default function NoDataFound() {
  return (
    <Box
      sx={{
        textAlign: 'center',
        marginTop: 3
      }}
    >
      <Typography variant="h3">No Subscription Plans Found</Typography>
    </Box>
  )
}
