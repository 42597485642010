import ServiceHealthContextProvider from 'contexts/ServiceHealthContext'
import ServiceHealthDashboard from 'pages/Dashboards/ServiceHealth'

export default function DashboardServiceHealth() {
  return (
    <ServiceHealthContextProvider>
      <ServiceHealthDashboard />
    </ServiceHealthContextProvider>
  )
}
