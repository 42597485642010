import Button, { ButtonProps } from '@mui/material/Button'
import { makeStyles } from '@mui/styles'
import Icon, { IconType } from 'components/Icons'

const useStyles = makeStyles({
  button: {
    color: '#000000',
    marginLeft: '1rem',
    backgroundColor: '#dfedf7',
    fontWeight: 700,
    letterSpacing: '0.05rem',
    textTransform: 'capitalize',
    '&:hover': {
      background: '#3d89ff',
      color: 'white',
      opacity: '0.8'
    }
  },
  icon: {
    marginRight: '0.5rem'
  }
})

interface CreateButtonProps extends ButtonProps {
  text: string
  icon?: IconType
}

export default function CreateButton(props: CreateButtonProps) {
  const { text, icon = IconType.Add, ...others } = props
  const classes = useStyles()
  return (
    <Button classes={{ root: classes.button }} variant="contained" {...others}>
      <Icon name={icon ?? IconType.Upload} className={classes.icon} /> {text}
    </Button>
  )
}
