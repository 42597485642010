import { selectUserProfile } from 'client_side_state/slices/user'
import { useAppSelector } from 'client_side_state/store'
import useDocumentTitle from 'hooks/useDocumentTitle'
import { useContext } from 'react'
import { toast } from 'react-toastify'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import FormControlLabel from '@mui/material/FormControlLabel'
import Paper from '@mui/material/Paper'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import { AxiosError, isAxiosError } from 'axios'
import { AdminPagesTitles } from 'components/MyAdminPages/Constants'
import MyAdminPagesCommonHeader from 'components/MyAdminPages/MyAdminPagesCommonHeader'
import AddOrganizationsModal from 'components/UserPlansDetails/AddOrganizations'
import NoDataFound from 'components/UserPlansDetails/NoDataFound'
import SubscriptionCancelModal from 'components/UserPlansDetails/SubscriptionCancelModal'
import UserPlansDetailsSkeletonLoader from 'components/common/Loaders/UserPlansDetailsSkeletonLoader'
import { rowsPerPageOptions } from 'constants/AppConfig'
import { ToastErrors } from 'constants/toast_errors'
import { ToastSuccessMessages } from 'constants/toast_success_messages'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { PlansDetailsContext } from 'contexts/PlansDetailsContext'
import {
  SubscriptionCancelRequest,
  UserSubscribedPlan
} from 'lib/CloudCanariesRestfulAPI'
import useStyles from './styles'
import adminPagesSharedStyles from '../adminPagesSharedStyles'

export default function UserPlansDetails() {
  const adminPagesClasses = adminPagesSharedStyles()
  const classes = useStyles()
  useDocumentTitle('Plans Page')

  const userData = useAppSelector(selectUserProfile)

  const { apiService } = useContext(APIServiceContext)
  const {
    areUserSubscribedPlansLoading,
    userSubscribedPlans,
    selectedOrganization,
    selectedSubscriptionPlan,
    organizations,
    page,
    rowCount,
    rowsPerPage,
    setSelectedOrganization,
    changeSelectedPlan,
    fetchUserSubscribedPlans,
    handleChangePage,
    onRowsPerPageChange
  } = useContext(PlansDetailsContext)

  const handleSubscriptionChange = (subscription: UserSubscribedPlan) => {
    changeSelectedPlan(subscription)
  }

  const handleOrganizationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const foundOrganizations = organizations?.filter(
      (org) => org.id === event.target.value
    )
    if (foundOrganizations?.length! > 0) {
      const organization = foundOrganizations![0]
      setSelectedOrganization(organization)
    }
  }

  const addToOrganization = async (plan_id: string) => {
    try {
      await apiService
        .assignCanaryUserSubscribedPlan(plan_id, {
          organizations: [{ id: selectedOrganization!.id }]
        })
        .then(async (json) => {
          if (json.status === 200) await fetchUserSubscribedPlans()
        })
    } catch (e: any) {
      if (isAxiosError(e)) {
        const err = e as AxiosError
        if (err.response?.status === 400) {
          const errors = err.response?.data as {}
          Object.entries(errors).forEach(([key, value]) => {
            if (value === 'Max active canaries exceeded') {
              toast.error(ToastErrors.ERROR_ASSIGNING_CANARY_MAX_EXCEEDED, {
                position: 'top-right'
              })
            }
          })
        }
      }
    }
  }

  const subtractFromOrganization = async (plan_id: string) => {
    try {
      await apiService
        .subtractCanaryUserSubscribedPlan(plan_id, {
          organizations: [{ id: selectedOrganization!.id }]
        })
        .then(async (json) => {
          if (json.status === 200) await fetchUserSubscribedPlans()
        })
    } catch (e: any) {
      if (isAxiosError(e)) {
        const err = e as AxiosError
        if (err.response?.status === 400) {
          const errors = err.response?.data as {}
          Object.entries(errors).forEach(([key, value]) => {
            if (value === 'Max active canaries exceeded') {
              toast.error(ToastErrors.ERROR_SUBTRACTING_CANARY, {
                position: 'top-right'
              })
            }
          })
        }
      }
    }
  }

  const cancelSubscription = async (subscription: UserSubscribedPlan) => {
    try {
      const subscriptionCancelRequest: SubscriptionCancelRequest = {
        subscription: subscription.id!,
        user: subscription.user!
      }
      await apiService
        .createSubscriptionCancelRequest(subscriptionCancelRequest)
        .then(async (json) => {
          if (json.status === 201) {
            await fetchUserSubscribedPlans()
            toast.success(
              ToastSuccessMessages.SUCCESSFUL_SUBSCRIPTION_CANCELATION,
              {
                position: 'top-right'
              }
            )
          }
        })
    } catch (e: any) {
      if (isAxiosError(e)) {
        const err = e as AxiosError
        if (err.response?.status === 400) {
          const errors = err.response?.data as {}
          Object.entries(errors).forEach(([key, value]) => {
            if (value === 'Max active canaries exceeded') {
              toast.error(ToastErrors.ERROR_SUBTRACTING_CANARY, {
                position: 'top-right'
              })
            }
          })
        }
      }
    }
  }

  return (
    <Container maxWidth="xl" className={adminPagesClasses.mainContainer}>
      <MyAdminPagesCommonHeader />
      <h1 className={adminPagesClasses.headerRow}>
        {AdminPagesTitles.PlansDetails}
      </h1>
      {areUserSubscribedPlansLoading ? (
        <UserPlansDetailsSkeletonLoader />
      ) : (
        <Container maxWidth="xl" className={classes.container} disableGutters>
          <Typography variant="h5" gutterBottom>
            {userData.first_name} {userData.last_name}
          </Typography>
          {userSubscribedPlans.length > 0 ? (
            <>
              <Box className={classes.section}>
                <TableContainer component={Paper} elevation={3}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          Owned Subscriptions
                        </TableCell>
                        <TableCell align="center">Start Date</TableCell>
                        <TableCell align="center">Canary Licenses</TableCell>
                        <TableCell align="center">Assigned</TableCell>
                        <TableCell align="center">Add to Org</TableCell>
                        <TableCell align="center">Subtract from Org</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userSubscribedPlans?.map((subscription) => (
                        <TableRow key={subscription.id}>
                          <TableCell align="center">
                            <RadioGroup
                              value={selectedSubscriptionPlan?.id || 0}
                              onChange={() =>
                                handleSubscriptionChange(subscription)
                              }
                            >
                              <FormControlLabel
                                value={subscription.id}
                                control={<Radio />}
                                label={subscription.plan_name}
                              />
                            </RadioGroup>
                          </TableCell>
                          <TableCell align="center">
                            {new Date(
                              subscription.plan_creation_date!
                            ).toLocaleDateString()}
                          </TableCell>
                          <TableCell align="center">
                            {subscription.max_active_canaries}
                          </TableCell>
                          <TableCell align="center">
                            {subscription.assigned_canaries_count}
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              variant="text"
                              size="small"
                              sx={{
                                color: '#000',
                                fontWeight: 900,
                                fontSize: 20
                              }}
                              onClick={() =>
                                addToOrganization(subscription.id!)
                              }
                              disabled={
                                selectedSubscriptionPlan?.id !==
                                  subscription.id ||
                                selectedSubscriptionPlan?.assigned_canaries_count! >=
                                  selectedSubscriptionPlan?.max_active_canaries!
                              }
                            >
                              +
                            </Button>
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              variant="text"
                              size="small"
                              sx={{
                                color: '#000',
                                fontWeight: 900,
                                fontSize: 20
                              }}
                              onClick={() =>
                                subtractFromOrganization(subscription.id!)
                              }
                              disabled={
                                selectedSubscriptionPlan?.id !==
                                  subscription.id ||
                                selectedOrganization?.max_active_canaries === 0
                              }
                            >
                              -
                            </Button>
                          </TableCell>
                          <TableCell align="center">
                            <SubscriptionCancelModal
                              selectedSubscriptionPlan={
                                selectedSubscriptionPlan
                              }
                              subscription={subscription}
                              afterSubmit={() =>
                                cancelSubscription(subscription)
                              }
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  component="div"
                  count={rowCount}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={rowsPerPageOptions}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={onRowsPerPageChange}
                />
              </Box>

              <Box className={classes.section}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="h6" gutterBottom>
                    Organizations using the "
                    {selectedSubscriptionPlan?.plan_name} Subscription"
                  </Typography>
                  <AddOrganizationsModal
                    plan_id={selectedSubscriptionPlan?.id}
                    organizations={organizations}
                  />
                </Box>
                <TableContainer component={Paper} elevation={3}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Organizations</TableCell>
                        <TableCell align="center">Start Date</TableCell>
                        <TableCell align="center">
                          Total Canary Licenses
                        </TableCell>
                        <TableCell align="center">Assigned</TableCell>
                        <TableCell align="center">Available</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {organizations?.map((organization) => (
                        <TableRow key={organization.id}>
                          <TableCell align="center">
                            <RadioGroup
                              value={selectedOrganization?.id || ''}
                              onChange={handleOrganizationChange}
                            >
                              <FormControlLabel
                                value={organization.id}
                                control={<Radio />}
                                label={organization.name}
                              />
                            </RadioGroup>
                          </TableCell>
                          <TableCell align="center">
                            {new Date(
                              selectedSubscriptionPlan?.plan_creation_date!
                            ).toLocaleDateString()}
                          </TableCell>
                          <TableCell align="center">
                            {organization.get_canary_count}
                          </TableCell>
                          <TableCell align="center">
                            {organization.max_active_canaries}
                          </TableCell>
                          <TableCell align="center">
                            {selectedSubscriptionPlan?.max_active_canaries! -
                              selectedSubscriptionPlan?.assigned_canaries_count!}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </>
          ) : (
            <NoDataFound />
          )}
        </Container>
      )}
    </Container>
  )
}
