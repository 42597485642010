export enum ColorMeanings {
  GoodMeetingOrHasMetSLATarget = 'Good. Meeting or has met SLA target',
  WarningPossibleRiskToOutOfSLAComplianceWithin5 = 'Warning. Possible risk to out of SLA Compliance. (within 5%)',
  AlarmOutOfCompliance = 'Alarm. Out of Compliance'
}

export function getMappedColorNames(
  colorMeaning: string | undefined
): string | undefined {
  if (!colorMeaning) {
    return undefined
  }
  let returnedColorMeaning: string | undefined = ''

  Object.keys(ColorMeanings).forEach((meaning) => {
    if (colorMeaning === meaning) {
      returnedColorMeaning =
        ColorMeanings[meaning as keyof typeof ColorMeanings]
    }
  })
  return returnedColorMeaning
}
