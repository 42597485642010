import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { userLogout } from './auth'
import type { RootState } from '../store'

export interface ForecastPageState {
  selectedCardId: string
}

const initialState: ForecastPageState = {
  selectedCardId: ''
}

export const forecastPageSlice = createSlice({
  name: 'forecastPage',
  initialState,
  reducers: {
    setSelectedCardId: (state, action: PayloadAction<string>) => {
      state.selectedCardId = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(userLogout, (state, action) => {
      state.selectedCardId = ''
    })
  }
})

// Action creators are generated for each case reducer function
export const { setSelectedCardId } = forecastPageSlice.actions

export default forecastPageSlice.reducer

export const selectCardId = (state: RootState) =>
  state.forecastPage.selectedCardId
