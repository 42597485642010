import {
  ChangeEvent,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import {
  StoreCanaryCloudReadOnly,
  StoreCanaryComponentReadOnly,
  StoreCanaryReadOnly
} from 'lib/CloudCanariesRestfulAPI'
import { APIServiceContext } from './APIServiceContext'
import { AuthContext } from './AuthContext'

interface SharedCanaryTemplateLibrariesContextProviderProps {
  children: ReactNode
}

interface SharedCanaryTemplateLibrariesContextProps {
  areSharedTemplateLibrariesLoading: boolean
  sharedTemplateLibraries: StoreCanaryReadOnly[]
  storeCanaryClouds: StoreCanaryCloudReadOnly[] | null
  storeCanaryComponents: StoreCanaryComponentReadOnly[] | null
  selectedComponent: string
  rowsPerPage: number
  page: number
  rowCount: number
  getSharedTemplateLibraries: () => Promise<void>
  setSelectedComponent: (value: string) => void
  onRowsPerPageChange: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void
  handleChangePage: (event: any, newPage: number) => void
}
export const SharedCanaryTemplateLibrariesContext =
  createContext<SharedCanaryTemplateLibrariesContextProps>(null as any)

export default function SharedCanaryTemplateLibrariesContextProvider(
  props: SharedCanaryTemplateLibrariesContextProviderProps
) {
  const { children } = props

  const { userId } = useContext(AuthContext)
  const { apiService } = useContext(APIServiceContext)

  const [page, setPage] = useState<number>(0)
  const [rowCount, setRowCount] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [
    areSharedTemplateLibrariesLoading,
    setAreSharedTemplateLibrariesLoading
  ] = useState<boolean>(true)
  const [sharedTemplateLibraries, setSharedTemplateLibraries] = useState<
    StoreCanaryReadOnly[]
  >([])
  const [storeCanaryClouds, setStoreCanaryClouds] = useState<
    StoreCanaryCloudReadOnly[] | null
  >(null)
  const [storeCanaryComponents, setStoreCanaryComponents] = useState<
    StoreCanaryComponentReadOnly[] | null
  >(null)
  const [selectedComponent, setSelectedComponent] = useState<string>('')

  const fetchStoreCanariesClouds = useCallback(async () => {
    await apiService.listStoreCanaryClouds().then((json) => {
      setStoreCanaryClouds(json.data.results ?? null)
    })
  }, [apiService])

  const fetchStoreCanariesComponents = useCallback(async () => {
    await apiService.listStoreCanaryComponents().then((json) => {
      setStoreCanaryComponents(json.data.results ?? null)
    })
  }, [apiService])

  const getSharedTemplateLibraries = useCallback(async () => {
    setRowCount(0)
    const requestedPage = page + 1

    setAreSharedTemplateLibrariesLoading(true)
    await apiService
      .listStoreCanaries(
        requestedPage,
        rowsPerPage,
        undefined,
        selectedComponent,
        undefined,
        undefined,
        undefined,
        userId
      )
      .then((json) => {
        setSharedTemplateLibraries(json.data.results ?? [])
        setRowCount(json.data.count ?? 0)
        setAreSharedTemplateLibrariesLoading(false)
      })
  }, [apiService, page, rowsPerPage, selectedComponent, userId])

  useEffect(() => {
    fetchStoreCanariesClouds()
  }, [fetchStoreCanariesClouds])

  useEffect(() => {
    fetchStoreCanariesComponents()
  }, [fetchStoreCanariesComponents])

  useEffect(() => {
    getSharedTemplateLibraries()
  }, [getSharedTemplateLibraries])

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage)
  }

  const onRowsPerPageChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <SharedCanaryTemplateLibrariesContext.Provider
      value={{
        areSharedTemplateLibrariesLoading,
        sharedTemplateLibraries,
        storeCanaryClouds,
        storeCanaryComponents,
        selectedComponent,
        page,
        rowCount,
        rowsPerPage,
        getSharedTemplateLibraries,
        setSelectedComponent,
        handleChangePage,
        onRowsPerPageChange
      }}
    >
      {children}
    </SharedCanaryTemplateLibrariesContext.Provider>
  )
}
