import { useContext } from 'react'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { IncidentsContext } from 'contexts/IncidentsContext'
import { ServiceHealthIncidentIncidentStatusEnum } from 'lib/CloudCanariesRestfulAPI'
import { underScoreStringToFirstLetterCapital } from 'services/utils'

const statusOptions = Array.from(
  Object.values(ServiceHealthIncidentIncidentStatusEnum).filter(
    (status) => status !== ServiceHealthIncidentIncidentStatusEnum.Ok
  )
)

export default function IncidentsStateFilter() {
  const { incidentStatusValueSelected, setIncidentStatusValueSelected } =
    useContext(IncidentsContext)

  const onChange = (event: SelectChangeEvent) => {
    const newlySelectedValue = String(
      event.target.value
    ) as ServiceHealthIncidentIncidentStatusEnum
    setIncidentStatusValueSelected(newlySelectedValue)
  }

  return (
    <FormControl size="small" sx={{ pl: 0, mb: 1, minWidth: '150px' }}>
      <InputLabel id="select-helper-label-page-filter-incident-status">
        {Boolean(incidentStatusValueSelected) ? null : null}
      </InputLabel>
      <Select
        sx={{ minWidth: '150px' }}
        labelId="select-helper-label-page-filter-incident-status"
        id="select-helper-page-filter-incident-status"
        value={incidentStatusValueSelected}
        onChange={onChange}
        autoWidth
        displayEmpty
      >
        <MenuItem value="">
          <em>All Statuses</em>
        </MenuItem>
        {statusOptions.map((status) => {
          return (
            <MenuItem key={status} value={String(status)}>
              {underScoreStringToFirstLetterCapital(status)}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}
