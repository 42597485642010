import { useContext } from 'react'
import LinearProgress from '@mui/material/LinearProgress'
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowSelectionModel,
  GridSlots,
  GridToolbarContainer,
  GridToolbarFilterButton
} from '@mui/x-data-grid'
import { rowsPerPageOptions } from 'constants/AppConfig'
import { AutoLoadCanaryTemplatesContext } from 'contexts/AutoLoadCanaryTemplatesContext'
import { StoreCanaryReadOnly } from 'lib/CloudCanariesRestfulAPI'
import CustomNoRowsOverlay from './NoRowsOverlay'

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  )
}

export default function CanaryTemplatesDataGrid() {
  const {
    canaryTemplates,
    page,
    pageSize,
    onPageSizeChange,
    areCanaryTemplatesLoading,
    setSelectedCanaryTemplates,
    handleChangePage,
    rowCount,
    storeCanaryClouds,
    storeCanaryComponents
  } = useContext(AutoLoadCanaryTemplatesContext)

  const columns: GridColDef<StoreCanaryReadOnly>[] = [
    {
      field: 'id',
      sortable: false,
      filterable: false
    },
    {
      field: 'name',
      headerName: 'Name',
      type: 'string',
      sortable: false,
      width: 130
    },
    {
      field: 'version',
      headerName: 'Ver',
      type: 'string',
      sortable: false,
      width: 35
    },
    {
      field: 'cloud',
      headerName: 'Cloud',
      type: 'string',
      sortable: false,
      valueGetter: (value) => {
        return storeCanaryClouds?.find((cloud) => cloud.id === value)?.name
      },
      width: 80
    },
    {
      field: 'component',
      headerName: 'Category',
      type: 'string',
      valueGetter: (value) => {
        return storeCanaryComponents?.find(
          (component) => component.id === value
        )?.name
      },
      sortable: false,
      width: 80
    },
    {
      field: 'canary_templates_count',
      headerAlign: 'center',
      headerName: 'Canaries',
      type: 'number',
      sortable: false,
      width: 80,
      align: 'center'
    },
    {
      field: 'service_name',
      headerName: 'Services',
      type: 'string',
      sortable: false,
      width: 130
    },
    {
      field: 'description',
      headerName: 'Description',
      type: 'string',
      sortable: false,
      flex: 1
    }
  ]

  const onRowSelectionModelChange = (
    newRowSelectionModel: GridRowSelectionModel
  ) => {
    setSelectedCanaryTemplates(newRowSelectionModel)
  }

  const onPaginationModelChange = (model: GridPaginationModel) => {
    const { page: newPage, pageSize: newPageSize } = model
    if (newPageSize !== pageSize) {
      onPageSizeChange(newPageSize)
    }
    handleChangePage(newPage)
  }

  return (
    <DataGrid
      autoHeight
      rows={canaryTemplates}
      columns={columns}
      rowCount={rowCount}
      initialState={{
        columns: {
          columnVisibilityModel: {
            id: false
          }
        }
      }}
      loading={areCanaryTemplatesLoading}
      disableAutosize
      disableColumnSelector
      disableDensitySelector
      disableColumnMenu
      disableColumnSorting
      paginationMode="server"
      paginationModel={{
        page: page,
        pageSize: pageSize
      }}
      onPaginationModelChange={onPaginationModelChange}
      pageSizeOptions={rowsPerPageOptions}
      slots={{
        noRowsOverlay: CustomNoRowsOverlay,
        loadingOverlay: LinearProgress as GridSlots['loadingOverlay'],
        toolbar: CustomToolbar
      }}
      sx={{ '--DataGrid-overlayHeight': '250px' }}
      checkboxSelection
      disableRowSelectionOnClick
      onRowSelectionModelChange={(newRowSelectionModel) =>
        onRowSelectionModelChange(newRowSelectionModel)
      }
    />
  )
}
