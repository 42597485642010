import { useContext } from 'react'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { OrganizationsContext } from 'contexts/OrganizationsContext'
import { Organization } from 'lib/CloudCanariesRestfulAPI'

export default function OrganizationFilter() {
  const {
    allOrganizations,
    clearOrganization,
    organization,
    setUserSelectedOrganization
  } = useContext(OrganizationsContext)

  const handleClick = (e: Organization) => {
    setUserSelectedOrganization(e)
  }

  const handleClear = () => {
    clearOrganization()
  }

  return (
    <FormControl sx={{ px: '1em' }} size="small">
      <Select
        value={organization?.id || ''}
        displayEmpty
        SelectDisplayProps={{
          style: {
            borderRadius: 4,
            paddingTop: 5,
            paddingBottom: 5,
            paddingRight: 32,
            paddingLeft: 16,
            background: '#3D89FF',
            color: '#FFFFFF'
          }
        }}
        inputProps={{ 'aria-label': 'Organizations', disableunderline: 'true' }}
      >
        <MenuItem value="" onClick={() => handleClear()}>
          <em style={{ fontSize: '1em' }}>All Organizations</em>
        </MenuItem>
        {allOrganizations.map((o: Organization) => (
          <MenuItem key={o.id} value={o.id} onClick={() => handleClick(o)}>
            {o.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
