import { forwardRef, useImperativeHandle, useState } from 'react'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import DialogModal from 'components/Modals/DialogModal'

type SkipAutoLoadCanaryTemplatesButtonProps = {
  afterSubmit: (skipCancel: boolean) => Promise<void>
  skip?: boolean
}

const SkipAutoLoadCanaryTemplatesButton = forwardRef(
  (props: SkipAutoLoadCanaryTemplatesButtonProps, ref) => {
    const { afterSubmit, skip } = props

    const [skipConfirmOpen, setSkipConfirmOpen] = useState<boolean>(false)

    const handleClickOpen = () => {
      if (!skip) {
        setSkipConfirmOpen(true)
      } else {
        confirmSkip()
      }
    }
    const handleClickClose = () => {
      setSkipConfirmOpen(false)
    }

    const confirmSkip = () => {
      afterSubmit(true)
      setSkipConfirmOpen(false)
    }

    useImperativeHandle(
      ref,
      () => ({
        setSkipConfirmOpen
      }),
      []
    )
    return (
      <>
        <Button
          variant="outlined"
          sx={{
            textTransform: 'capitalize'
          }}
          onClick={handleClickOpen}
        >
          Skip
        </Button>

        <DialogModal
          open={skipConfirmOpen}
          onClose={handleClickClose}
          dialogTitle="Skip auto loading Canary Template Libraries"
          dialogContent={
            <Typography variant="body1" align="center">
              It is recommended to load at least 1 Canary Template Library to
              get started quickly.
              <br />
              Are you sure you want to skip?
            </Typography>
          }
          dialogActions={
            <>
              <Button onClick={handleClickClose}>Cancel</Button>
              <Button onClick={confirmSkip} variant="contained" autoFocus>
                Confirm
              </Button>
            </>
          }
        />
      </>
    )
  }
)

export default SkipAutoLoadCanaryTemplatesButton
