import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { isAxiosError } from 'axios'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { TaskUpdates } from 'interfaces/CanaryEditor'
import {
  Canary,
  CanaryTask,
  CanaryTasksInner,
  SimpleAlarm
} from 'lib/CloudCanariesRestfulAPI'
import { Container } from 'styles/pages/CanaryEditor.styled'
import EditorMenu from './EditorMenu'
import SidebarProperties from './SidebarProperties'
import VariablesPanel from './VariablesPanel'

const defaultTaskUpdates = new Map<string, TaskUpdates>()

interface AbstractCanaryEditorProps {
  canaryId: string
  onClose: () => void
}

export default function AbstractCanaryEditor(props: AbstractCanaryEditorProps) {
  const { canaryId, onClose } = props

  const { apiService } = useContext(APIServiceContext)

  const [selectedTaskId, setSelectedTaskId] = useState<string>('')
  const [nameChanged, setNameChanged] = useState<boolean>(false)
  const [canaryMeta, setCanaryMeta] = useState<Canary>({} as Canary)
  const [canaryTasks, setCanaryTasks] = useState<CanaryTasksInner[]>([])
  const [canaryName, setName] = useState<string>('')
  const [saving, setSaving] = useState<boolean>(false)

  const [tasksUpdates, setTasksUpdates] =
    useState<Map<string, TaskUpdates>>(defaultTaskUpdates)
  const [canaryNameDuplicateError, setCanaryNameDuplicateError] =
    useState<boolean>(false)

  const getCanaryMeta = useCallback(async () => {
    if (canaryId) {
      await apiService.retrieveCanary(canaryId).then((json) => {
        setCanaryMeta(json.data)
        setName(json.data.name ?? 'Canary Name')
        setCanaryTasks(json.data.tasks ?? [])
        setSelectedTaskId(json.data.tasks![0]!.id!)
      })
    }
  }, [apiService, canaryId])

  useEffect(() => {
    getCanaryMeta()
  }, [getCanaryMeta])

  const hasNoTaskUpdates = (tasksUpdates: Map<string, TaskUpdates>) => {
    let _no_updates = true
    tasksUpdates.forEach((v, k, m) => {
      if (
        v.env_vars_updates ||
        v.latency_alarm_meta_updates ||
        v.latency_alarm_boundary_condition_updates ||
        v.response_value_alarm_meta_updates ||
        v.response_value_alarm_boundary_condition_updates ||
        v.forecasted_latency_alarm_meta_updates ||
        v.forecasted_latency_alarm_boundary_condition_updates ||
        v.forecasted_response_value_alarm_meta_updates ||
        v.forecasted_response_value_alarm_boundary_condition_updates
      ) {
        _no_updates = false
      }
    })
    return _no_updates
  }

  const hasUnsavedChanges = useMemo<boolean>(
    () => nameChanged || !hasNoTaskUpdates(tasksUpdates),
    [nameChanged, tasksUpdates]
  )

  const isNotEmptyUpdate = (obj: TaskUpdates | Partial<SimpleAlarm>) =>
    obj && Object.getOwnPropertyNames(obj).length !== 0

  const handleSave = useCallback(async () => {
    setSaving(true)
    tasksUpdates.forEach(async (v, taskId) => {
      const task = canaryTasks.find((t) => t.id === taskId)
      if (task) {
        if (isNotEmptyUpdate(v.env_vars_updates)) {
          console.trace(v.env_vars_updates)

          const canaryTask = { env_vars: v.env_vars_updates } as CanaryTask

          await apiService.partialUpdateCanaryTaskEnvVars(taskId, canaryTask)
        }
        // Actual Data alarms updates
        if (isNotEmptyUpdate(v.response_value_alarm_meta_updates)) {
          console.trace(v.response_value_alarm_meta_updates)

          const metricId = task.response_value_metric_id
          const alarmId = task.response_value_metric_alarm_id!
          const updates = v.response_value_alarm_meta_updates as SimpleAlarm
          await apiService.partialUpdateSimpleAlarm(
            alarmId,
            metricId,
            canaryId,
            taskId,
            updates
          )
        }
        if (isNotEmptyUpdate(v.latency_alarm_meta_updates)) {
          console.trace(v.latency_alarm_meta_updates)

          const metricId = task.latency_metric_id
          const alarmId = task.latency_metric_alarm_id!
          const updates = v.latency_alarm_meta_updates as SimpleAlarm
          await apiService.partialUpdateSimpleAlarm(
            alarmId,
            metricId,
            canaryId,
            taskId,
            updates
          )
        }

        // Forecast Data alarms updates
        if (isNotEmptyUpdate(v.forecasted_response_value_alarm_meta_updates)) {
          console.trace(v.forecasted_response_value_alarm_meta_updates)

          const metricId = task.forecasted_response_value_metric_id
          const alarmId = task.forecasted_response_value_metric_alarm_id!
          const updates =
            v.forecasted_response_value_alarm_meta_updates as SimpleAlarm
          await apiService.partialUpdateSimpleAlarm(
            alarmId,
            metricId,
            canaryId,
            taskId,
            updates
          )
        }
        if (isNotEmptyUpdate(v.forecasted_latency_alarm_meta_updates)) {
          console.trace(v.forecasted_latency_alarm_meta_updates)

          const metricId = task.forecasted_latency_metric_id
          const alarmId = task.forecasted_latency_metric_alarm_id!
          const updates = v.forecasted_latency_alarm_meta_updates as SimpleAlarm
          await apiService.partialUpdateSimpleAlarm(
            alarmId,
            metricId,
            canaryId,
            taskId,
            updates
          )
        }

        // Actual Data alarms boundary conditions
        if (
          isNotEmptyUpdate(v.response_value_alarm_boundary_condition_updates)
        ) {
          console.trace(v.response_value_alarm_boundary_condition_updates)

          const boundaryConditionId =
            task.response_value_metric_boundary_condition_id!
          const metricId = task.response_value_metric_id
          const updates = v.response_value_alarm_boundary_condition_updates
          await apiService.partialUpdateAbstractMetricBoundaryCondition(
            boundaryConditionId,
            metricId,
            canaryId,
            taskId,
            updates
          )
        }
        if (isNotEmptyUpdate(v.latency_alarm_boundary_condition_updates)) {
          console.trace(v.latency_alarm_boundary_condition_updates)

          const boundaryConditionId = task.latency_metric_boundary_condition_id!
          const metricId = task.latency_metric_id
          const updates = v.latency_alarm_boundary_condition_updates
          await apiService.partialUpdateAbstractMetricBoundaryCondition(
            boundaryConditionId,
            metricId,
            canaryId,
            taskId,
            updates
          )
        }

        // Forecast Data alarms boundary conditions
        if (
          isNotEmptyUpdate(
            v.forecasted_response_value_alarm_boundary_condition_updates
          )
        ) {
          console.trace(
            v.forecasted_response_value_alarm_boundary_condition_updates
          )

          const boundaryConditionId =
            task.forecasted_response_value_metric_boundary_condition_id!
          const metricId = task.forecasted_response_value_metric_id
          const updates =
            v.forecasted_response_value_alarm_boundary_condition_updates
          await apiService.partialUpdateAbstractMetricBoundaryCondition(
            boundaryConditionId,
            metricId,
            canaryId,
            taskId,
            updates
          )
        }
        if (
          isNotEmptyUpdate(
            v.forecasted_latency_alarm_boundary_condition_updates
          )
        ) {
          console.trace(v.forecasted_latency_alarm_boundary_condition_updates)

          const boundaryConditionId =
            task.forecasted_latency_metric_boundary_condition_id!
          const metricId = task.forecasted_latency_metric_id
          const updates = v.forecasted_latency_alarm_boundary_condition_updates
          await apiService.partialUpdateAbstractMetricBoundaryCondition(
            boundaryConditionId,
            metricId,
            canaryId,
            taskId,
            updates
          )
        }
      }
    })
    if (nameChanged) {
      const _canary = { name: canaryName } as Canary
      const organizationId = undefined
      const ordering = undefined
      const search = undefined
      const status = undefined

      try {
        await apiService
          .partialUpdateCanary(
            canaryId,
            organizationId,
            status,
            ordering,
            search,
            _canary
          )
          .then(async (json) => {
            if (json.status === 200) {
              setSaving(false)
              setCanaryNameDuplicateError(false)
            }
          })
      } catch (err: any) {
        if (isAxiosError(err)) {
          if (err && err.response && err.response.data) {
            const data = err.response.data as { [key: string]: string }
            Object.keys(data).forEach((key) => {
              if (
                data[key][0] ===
                'The fields name, organization must make a unique set.'
              ) {
                setCanaryNameDuplicateError(true)
              }
            })
          }
        }
      }
    }
    setTasksUpdates(defaultTaskUpdates)
    setSelectedTaskId('')
    setNameChanged(false)
    getCanaryMeta()
    setSaving(false)
  }, [
    tasksUpdates,
    nameChanged,
    getCanaryMeta,
    canaryTasks,
    apiService,
    canaryId,
    canaryName
  ])

  return (
    <Container $createMode={false}>
      <EditorMenu
        canaryName={canaryName}
        hasUpdates={hasUnsavedChanges}
        canaryNameDuplicateError={canaryNameDuplicateError}
        setName={(name: string) => {
          setName(name)
          setNameChanged(true)
          setCanaryNameDuplicateError(false)
        }}
        saving={saving}
        onSave={handleSave}
        onClose={onClose}
      />
      <div className="main">
        <SidebarProperties canaryMeta={canaryMeta} />
        <VariablesPanel
          selectedTaskId={selectedTaskId}
          tasksUpdates={tasksUpdates}
          setTasksUpdates={setTasksUpdates}
        />
      </div>
    </Container>
  )
}
