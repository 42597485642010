import {
  setOrganizationRequiredModalMessage,
  setOrganizationRequiredModalOpen
} from 'client_side_state/slices/app'
import { useAppDispatch } from 'client_side_state/store'
import { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import { isAxiosError } from 'axios'
import logo from 'assets/logo.png'
import Icon, { IconType } from 'components/Icons'
import DialogModal from 'components/Modals/DialogModal'
import ModalSharedStyles from 'components/Modals/ModalSharedStyles'
import OrganizationRequiredDialog, {
  OrganizationRequiredMessage
} from 'components/UserOrganization/OrganizationRequiredDialog'
import { ToastErrors } from 'constants/toast_errors'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { AuthContext } from 'contexts/AuthContext'
import { CanariesContext } from 'contexts/CanariesContext'
import { OrganizationsContext } from 'contexts/OrganizationsContext'
import { CanaryErrors } from 'interfaces/Canary'

export default function ModalEditCanaryCluster() {
  const classes = ModalSharedStyles()

  const dispatch = useAppDispatch()

  const { getUsePlatformClusters } = useContext(AuthContext)
  const { apiService } = useContext(APIServiceContext)
  const { organizationId, userOrganization } = useContext(OrganizationsContext)

  const { canaries, selectedCanaryIds, userClusters, getCanaries } =
    useContext(CanariesContext)

  const [open, setOpen] = useState(false)
  const [selectedClusterId, setSelectedClusterId] = useState('')
  const [isDisabledForCanaryAgent, setIsDisabledForCanaryAgent] =
    useState<boolean>(false)

  const [isInternalClusterModalOpen, setIsInternalClusterModalOpen] =
    useState<boolean>(false)
  const [isConfirmChooseInternalCluster, setIsConfirmChooseInternalCluster] =
    useState<boolean>(false)
  const [selectedInternalClusterId, setSelectedInternalClusterId] =
    useState<string>('')
  const [canSelectInternalCluster, setCanSelectInternalCluster] =
    useState<boolean>(true)
  const [
    canSelectInternalClusterModalOpen,
    setCanSelectInternalClusterModalOpen
  ] = useState<boolean>(false)
  const [clusterUsageMessage, setClusterUsageMessage] = useState<string>(
    'The Cloud Canaries Canary cluster you want to select will be available for 7 days and 23 hours'
  )

  useEffect(() => {
    if (open) {
      if (userClusters.length > 0 && canaries.length > 0) {
        if (selectedCanaryIds.length > 1) {
          setSelectedClusterId('')
        } else if (selectedCanaryIds.length === 1) {
          const selectedClusterId =
            canaries.filter((canary) => canary.id === selectedCanaryIds[0])[0]
              .cluster_id || ''
          setSelectedClusterId(selectedClusterId)
        } else {
          setSelectedClusterId('')
        }
      } else {
        setSelectedClusterId('')
      }
    }
  }, [canaries, open, selectedCanaryIds, userClusters.length])

  useEffect(() => {
    const selectedCanaries = canaries.filter((canary) =>
      selectedCanaryIds.includes(canary.id!)
    )
    if (selectedCanaries.find((canary) => canary.is_agent_canary)) {
      setIsDisabledForCanaryAgent(true)
    } else {
      setIsDisabledForCanaryAgent(false)
    }
  }, [canaries, selectedCanaryIds])

  const handleClickOpen = () => {
    if (!organizationId) {
      dispatch(
        setOrganizationRequiredModalMessage(
          OrganizationRequiredMessage.ClusterAssign
        )
      )
      dispatch(setOrganizationRequiredModalOpen(true))
    } else {
      setOpen(true)
    }
  }

  function handleClose() {
    setOpen(false)
  }

  function handleInternalClusterModalClose() {
    setIsConfirmChooseInternalCluster(false)
    setIsInternalClusterModalOpen(false)
    setSelectedInternalClusterId('')
  }

  async function handleAction() {
    let canaryErrors: CanaryErrors[] = []
    const canaryClusterDeployment = {
      cluster: selectedClusterId
    }
    if (getUsePlatformClusters()) {
      await Promise.all(
        selectedCanaryIds.map(async (canaryId) => {
          try {
            await apiService.partialUpdateCanaryClusterDeployment(
              canaryId,
              canaryClusterDeployment
            )
          } catch (err: any) {
            if (isAxiosError(err)) {
              if (err && err.response && err.response.data) {
                const erroringCanary: CanaryErrors = {
                  canaryName: canaries.find((canary) => canary.id === canaryId)
                    ?.name!,
                  error: true
                }
                canaryErrors.push(erroringCanary)
              }
            }
          }
        })
      )
      if (canaryErrors.some((canary) => canary.error)) {
        canaryErrors.forEach((error) => {
          toast.error(
            `${ToastErrors.ERROR_UPDATING_CANARIES_CLUSTER} "${error.canaryName}"`,
            {
              position: 'top-right'
            }
          )
        })
      }
      setOpen(false)
      await getCanaries()
    }
  }

  function setClusterUsage(seconds: number) {
    const usageSeconds = 168 * 3600 - seconds
    const days = Math.floor(usageSeconds / (3600 * 24))
    const hours = Math.floor((usageSeconds % (3600 * 24)) / 3600)
    const daysDisplay =
      days > 0 || (days === 0 && hours === 0)
        ? days + (days === 1 ? ' day ' : ' days ')
        : ''
    const hoursDisplay =
      hours > 0 ? hours + (hours === 1 ? ' hour ' : ' hours ') : ''
    setClusterUsageMessage(
      `The Cloud Canaries Canary cluster you want to select will be available for ${daysDisplay}${
        hours > 0 && days > 0 ? 'and ' : ''
      }${hours > 0 ? hoursDisplay : ''}`
    )
  }

  const onSelectedClusterIdChange = (event: SelectChangeEvent) => {
    const value = event.target.value! as string
    if (getUsePlatformClusters()) {
      if (userClusters.find((cluster) => cluster.id === value)?.is_internal) {
        setClusterUsage(userOrganization?.usage_seconds_count ?? 0)
        if (userOrganization?.usage_hour_count! >= 168) {
          setCanSelectInternalCluster(false)
          setCanSelectInternalClusterModalOpen(true)
        } else {
          setSelectedInternalClusterId(value)
          setIsInternalClusterModalOpen(true)
          setCanSelectInternalClusterModalOpen(false)
        }
      } else {
        setIsInternalClusterModalOpen(false)
        setIsConfirmChooseInternalCluster(false)
        setSelectedClusterId(value)
      }
    }
  }

  useEffect(() => {
    if (isConfirmChooseInternalCluster) {
      setSelectedClusterId(selectedInternalClusterId)
      setIsInternalClusterModalOpen(false)
    }
  }, [isConfirmChooseInternalCluster, open, selectedInternalClusterId])

  const isDisabled =
    !selectedCanaryIds ||
    selectedCanaryIds.length === 0 ||
    isDisabledForCanaryAgent

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        color={'primary'}
        disabled={isDisabled}
      >
        <Icon name={IconType.ClusterIcon} style={{ marginRight: '0.25rem' }} />{' '}
        Cluster
      </Button>
      {organizationId ? (
        <DialogModal
          open={open}
          onClose={handleClose}
          dialogTitle="Edit Selected Canaries"
          dialogContent={
            <>
              <FormControl size="small">
                <InputLabel id="canaries-cluster-select-helper-label">
                  {selectedClusterId === '' ? 'None' : 'Cluster'}
                </InputLabel>
                <Select
                  style={{ minWidth: '400px' }}
                  labelId="canaries-cluster-select-helper-label"
                  id="simple-select-helper-cluster"
                  value={selectedClusterId}
                  label="Cluter"
                  onChange={onSelectedClusterIdChange}
                  autoWidth
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {userClusters.map((cluster) => {
                    return cluster.is_internal ? (
                      <MenuItem
                        disabled={!getUsePlatformClusters()}
                        key={cluster.id}
                        value={cluster.id}
                      >
                        <img
                          src={logo}
                          className={classes.logo}
                          alt={cluster.name}
                        />
                        {cluster.name}
                      </MenuItem>
                    ) : (
                      <MenuItem key={cluster.id} value={cluster.id}>
                        {cluster.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
              <DialogModal
                open={canSelectInternalClusterModalOpen}
                onClose={() => {
                  setCanSelectInternalClusterModalOpen(false)
                }}
                dialogTitle="Can't Select Cloud Canaries Canary Cluster"
                dialogContent={
                  <>
                    <Typography variant="body1" align="center">
                      Cloud Canaries Canary Cluster trial period expired.
                    </Typography>
                    <Typography
                      variant="body1"
                      align="center"
                      style={{ marginTop: '0.5rem' }}
                    >
                      Contact Cloud Canaries Support.
                    </Typography>
                  </>
                }
              />

              <DialogModal
                open={isInternalClusterModalOpen}
                onClose={handleInternalClusterModalClose}
                dialogTitle="Confirm Selecting Cloud Canaries Canary Cluster"
                dialogContent={
                  <>
                    <Typography variant="body1" align="center">
                      {clusterUsageMessage}
                    </Typography>
                    <Typography
                      variant="body1"
                      align="center"
                      style={{ marginTop: '0.5rem' }}
                    >
                      Do you want to continue?
                    </Typography>
                  </>
                }
                dialogActions={
                  <>
                    <Button onClick={handleInternalClusterModalClose}>
                      No
                    </Button>
                    <Button
                      onClick={() => setIsConfirmChooseInternalCluster(true)}
                      disabled={!canSelectInternalCluster}
                      variant="contained"
                      className={classes.yesButton}
                      autoFocus
                    >
                      Yes
                    </Button>
                  </>
                }
              />
            </>
          }
          dialogActions={
            <>
              <Button variant="outlined" onClick={handleClose} color="primary">
                <Icon name={IconType.Stop} /> Cancel
              </Button>
              <Button variant="outlined" onClick={handleAction} color="primary">
                <Icon name={IconType.Check} /> Apply
              </Button>
            </>
          }
        />
      ) : (
        <OrganizationRequiredDialog />
      )}
    </>
  )
}
