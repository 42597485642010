import { userLogout } from 'client_side_state/slices/auth'
import { useAppDispatch } from 'client_side_state/store'
import { useCallback, useContext, useEffect } from 'react'
import { APIServiceContext } from 'contexts/APIServiceContext'

export default function UserLogout() {
  const dispatch = useAppDispatch()
  const { logoutUser } = useContext(APIServiceContext)

  const handleUserLogout = useCallback(async () => {
    await logoutUser()
    dispatch(userLogout())
  }, [dispatch, logoutUser])

  useEffect(() => {
    handleUserLogout()
  }, [handleUserLogout])

  return <></>
}
