/* tslint:disable */
/* eslint-disable */
/**
 * CloudCanaries Rest API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.484
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AbstractMetric
 */
export interface AbstractMetric {
    /**
     * 
     * @type {string}
     * @memberof AbstractMetric
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AbstractMetric
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AbstractMetric
     */
    'canary'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AbstractMetric
     */
    'task'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AbstractMetric
     */
    'category'?: string;
    /**
     * 
     * @type {string}
     * @memberof AbstractMetric
     */
    'data_type'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AbstractMetric
     */
    'configurable'?: boolean;
}
/**
 * 
 * @export
 * @interface AbstractMetricBoundaryCondition
 */
export interface AbstractMetricBoundaryCondition {
    /**
     * 
     * @type {string}
     * @memberof AbstractMetricBoundaryCondition
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AbstractMetricBoundaryCondition
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AbstractMetricBoundaryCondition
     */
    'metric'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AbstractMetricBoundaryCondition
     */
    'boundary_boolean_value'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof AbstractMetricBoundaryCondition
     */
    'boundary_integer_value_min'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AbstractMetricBoundaryCondition
     */
    'boundary_integer_value_max'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AbstractMetricBoundaryCondition
     */
    'boundary_decimal_value_min'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AbstractMetricBoundaryCondition
     */
    'boundary_decimal_value_max'?: string | null;
    /**
     * Match the string with case sensitive if it is found in the long sentence.
     * @type {string}
     * @memberof AbstractMetricBoundaryCondition
     */
    'boundary_string_value_contains_case_sensitive'?: string | null;
    /**
     * Match the string without case sensitive if it is found in the long sentence.
     * @type {string}
     * @memberof AbstractMetricBoundaryCondition
     */
    'boundary_string_value_contains_not_case_sensitive'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AbstractMetricBoundaryCondition
     */
    'is_boolean'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AbstractMetricBoundaryCondition
     */
    'is_integer'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AbstractMetricBoundaryCondition
     */
    'is_decimal'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AbstractMetricBoundaryCondition
     */
    'is_string'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AbstractMetricBoundaryCondition
     */
    'is_json'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiVersion
 */
export interface ApiVersion {
    /**
     * 
     * @type {string}
     * @memberof ApiVersion
     */
    'api_version'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiVersion
     */
    'container_version'?: string;
}
/**
 * 
 * @export
 * @interface AuthToken
 */
export interface AuthToken {
    /**
     * 
     * @type {string}
     * @memberof AuthToken
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof AuthToken
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof AuthToken
     */
    'token'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthToken
     */
    'user_id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AuthToken
     */
    'is_temp_password'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AuthToken
     */
    'is_first_time_user'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AuthToken
     */
    'company'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AuthToken
     */
    'use_platform_clusters'?: boolean;
}
/**
 * 
 * @export
 * @interface BillingDetail
 */
export interface BillingDetail {
    /**
     * 
     * @type {string}
     * @memberof BillingDetail
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingDetail
     */
    'hubspot_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingDetail
     */
    'last_payment_date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BillingDetail
     */
    'next_payment_due_date'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BillingDetail
     */
    'next_payment_amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof BillingDetail
     */
    'plan_price'?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingDetail
     */
    'billing_frequency'?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingDetail
     */
    'payment_method'?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingDetail
     */
    'card_holder'?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingDetail
     */
    'plan_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingDetail
     */
    'status'?: BillingDetailStatusEnum;
    /**
     * 
     * @type {Array<BillingDetailInvoicesInner>}
     * @memberof BillingDetail
     */
    'invoices': Array<BillingDetailInvoicesInner>;
}

export const BillingDetailStatusEnum = {
    New: 'new',
    Active: 'active',
    Unpaid: 'unpaid',
    PastDue: 'past_due',
    Canceled: 'canceled',
    Expired: 'expired',
    Scheduled: 'scheduled'
} as const;

export type BillingDetailStatusEnum = typeof BillingDetailStatusEnum[keyof typeof BillingDetailStatusEnum];

/**
 * 
 * @export
 * @interface BillingDetailInvoicesInner
 */
export interface BillingDetailInvoicesInner {
    /**
     * 
     * @type {string}
     * @memberof BillingDetailInvoicesInner
     */
    'plan_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingDetailInvoicesInner
     */
    'hs_object_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingDetailInvoicesInner
     */
    'invoice_url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BillingDetailInvoicesInner
     */
    'invoice_status'?: BillingDetailInvoicesInnerInvoiceStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof BillingDetailInvoicesInner
     */
    'invoice_amount_billed': string;
    /**
     * 
     * @type {string}
     * @memberof BillingDetailInvoicesInner
     */
    'invoice_payment_date'?: string | null;
}

export const BillingDetailInvoicesInnerInvoiceStatusEnum = {
    Draft: 'draft',
    Open: 'open',
    Paid: 'paid',
    Voided: 'voided'
} as const;

export type BillingDetailInvoicesInnerInvoiceStatusEnum = typeof BillingDetailInvoicesInnerInvoiceStatusEnum[keyof typeof BillingDetailInvoicesInnerInvoiceStatusEnum];

/**
 * 
 * @export
 * @interface Canary
 */
export interface Canary {
    /**
     * 
     * @type {string}
     * @memberof Canary
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Canary
     */
    'organization': string;
    /**
     * 
     * @type {string}
     * @memberof Canary
     */
    'organization_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Canary
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Canary
     */
    'image'?: string;
    /**
     * 
     * @type {string}
     * @memberof Canary
     */
    'cluster_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Canary
     */
    'cluster_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Canary
     */
    'schedule'?: string;
    /**
     * 
     * @type {string}
     * @memberof Canary
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof Canary
     */
    'status'?: CanaryStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Canary
     */
    'is_agent_canary'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Canary
     */
    'has_alarms_alarming'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Canary
     */
    'ordered_task_ids'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Canary
     */
    'template_canary': string;
    /**
     * 
     * @type {string}
     * @memberof Canary
     */
    'template_canary_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Canary
     */
    'provider'?: string;
    /**
     * 
     * @type {string}
     * @memberof Canary
     */
    'provider_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Canary
     */
    'service'?: string;
    /**
     * 
     * @type {string}
     * @memberof Canary
     */
    'service_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Canary
     */
    'region': string;
    /**
     * 
     * @type {string}
     * @memberof Canary
     */
    'region_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Canary
     */
    'availability_zone': string;
    /**
     * 
     * @type {string}
     * @memberof Canary
     */
    'availability_zone_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Canary
     */
    'csv_data_export_url'?: string;
    /**
     * 
     * @type {Array<CanaryTasksInner>}
     * @memberof Canary
     */
    'tasks'?: Array<CanaryTasksInner>;
    /**
     * 
     * @type {object}
     * @memberof Canary
     */
    'env_vars': object;
}

export const CanaryStatusEnum = {
    Running: 'RUNNING',
    Stopped: 'STOPPED',
    Error: 'ERROR',
    New: 'NEW'
} as const;

export type CanaryStatusEnum = typeof CanaryStatusEnum[keyof typeof CanaryStatusEnum];

/**
 * 
 * @export
 * @interface CanaryClusterDeployment
 */
export interface CanaryClusterDeployment {
    /**
     * 
     * @type {string}
     * @memberof CanaryClusterDeployment
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryClusterDeployment
     */
    'image'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CanaryClusterDeployment
     */
    'cluster'?: string | null;
}
/**
 * 
 * @export
 * @interface CanaryDailyForecast
 */
export interface CanaryDailyForecast {
    /**
     * 
     * @type {string}
     * @memberof CanaryDailyForecast
     */
    'for_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryDailyForecast
     */
    'day_of_week'?: string;
    /**
     * 
     * @type {number}
     * @memberof CanaryDailyForecast
     */
    'percentage_chance_of_incident'?: number;
    /**
     * 
     * @type {number}
     * @memberof CanaryDailyForecast
     */
    'latency_issues_predicted'?: number;
    /**
     * 
     * @type {string}
     * @memberof CanaryDailyForecast
     */
    'service_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryDailyForecast
     */
    'canary_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryDailyForecast
     */
    'canary'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CanaryDailyForecast
     */
    'has_prediction'?: boolean;
}
/**
 * 
 * @export
 * @interface CanaryDetailTaskReadOnlyMinimal
 */
export interface CanaryDetailTaskReadOnlyMinimal {
    /**
     * 
     * @type {string}
     * @memberof CanaryDetailTaskReadOnlyMinimal
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryDetailTaskReadOnlyMinimal
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryDetailTaskReadOnlyMinimal
     */
    'graph_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryDetailTaskReadOnlyMinimal
     */
    'return_data_type'?: CanaryDetailTaskReadOnlyMinimalReturnDataTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CanaryDetailTaskReadOnlyMinimal
     */
    'latency_metric_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryDetailTaskReadOnlyMinimal
     */
    'latency_metric_boundary_condition_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryDetailTaskReadOnlyMinimal
     */
    'latency_metric_alarm_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryDetailTaskReadOnlyMinimal
     */
    'response_value_metric_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryDetailTaskReadOnlyMinimal
     */
    'response_value_metric_boundary_condition_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryDetailTaskReadOnlyMinimal
     */
    'response_value_metric_alarm_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryDetailTaskReadOnlyMinimal
     */
    'forecasted_latency_metric_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryDetailTaskReadOnlyMinimal
     */
    'forecasted_latency_metric_boundary_condition_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryDetailTaskReadOnlyMinimal
     */
    'forecasted_latency_metric_alarm_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryDetailTaskReadOnlyMinimal
     */
    'forecasted_response_value_metric_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryDetailTaskReadOnlyMinimal
     */
    'forecasted_response_value_metric_boundary_condition_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryDetailTaskReadOnlyMinimal
     */
    'forecasted_response_value_metric_alarm_id'?: string;
}

export const CanaryDetailTaskReadOnlyMinimalReturnDataTypeEnum = {
    Boolean: 'boolean',
    String: 'string',
    Integer: 'integer',
    Decimal: 'decimal',
    Json: 'json'
} as const;

export type CanaryDetailTaskReadOnlyMinimalReturnDataTypeEnum = typeof CanaryDetailTaskReadOnlyMinimalReturnDataTypeEnum[keyof typeof CanaryDetailTaskReadOnlyMinimalReturnDataTypeEnum];

/**
 * 
 * @export
 * @interface CanaryLists
 */
export interface CanaryLists {
    /**
     * 
     * @type {string}
     * @memberof CanaryLists
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryLists
     */
    'organization_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryLists
     */
    'organization_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryLists
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryLists
     */
    'service_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryLists
     */
    'service_name'?: string;
}
/**
 * 
 * @export
 * @interface CanaryPartnerReadOnly
 */
export interface CanaryPartnerReadOnly {
    /**
     * 
     * @type {string}
     * @memberof CanaryPartnerReadOnly
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryPartnerReadOnly
     */
    'image'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryPartnerReadOnly
     */
    'url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CanaryPartnerReadOnly
     */
    'is_active'?: boolean;
}
/**
 * 
 * @export
 * @interface CanaryPrototypeSource
 */
export interface CanaryPrototypeSource {
    /**
     * 
     * @type {string}
     * @memberof CanaryPrototypeSource
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryPrototypeSource
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryPrototypeSource
     */
    'relative_path'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryPrototypeSource
     */
    'file_contents'?: string | null;
}
/**
 * 
 * @export
 * @interface CanaryPrototypeSourceFileMinimalReadOnly
 */
export interface CanaryPrototypeSourceFileMinimalReadOnly {
    /**
     * 
     * @type {string}
     * @memberof CanaryPrototypeSourceFileMinimalReadOnly
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryPrototypeSourceFileMinimalReadOnly
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryPrototypeSourceFileMinimalReadOnly
     */
    'relative_path'?: string;
}
/**
 * 
 * @export
 * @interface CanaryReadWriteMinimal
 */
export interface CanaryReadWriteMinimal {
    /**
     * 
     * @type {string}
     * @memberof CanaryReadWriteMinimal
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryReadWriteMinimal
     */
    'organization': string;
    /**
     * 
     * @type {string}
     * @memberof CanaryReadWriteMinimal
     */
    'organization_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryReadWriteMinimal
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryReadWriteMinimal
     */
    'image'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryReadWriteMinimal
     */
    'cluster_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryReadWriteMinimal
     */
    'cluster_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryReadWriteMinimal
     */
    'schedule'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryReadWriteMinimal
     */
    'status'?: CanaryReadWriteMinimalStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CanaryReadWriteMinimal
     */
    'has_alarms_alarming'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryReadWriteMinimal
     */
    'template_canary': string;
    /**
     * 
     * @type {string}
     * @memberof CanaryReadWriteMinimal
     */
    'template_canary_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryReadWriteMinimal
     */
    'availability_zone': string;
    /**
     * 
     * @type {string}
     * @memberof CanaryReadWriteMinimal
     */
    'provider'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryReadWriteMinimal
     */
    'provider_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryReadWriteMinimal
     */
    'service'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryReadWriteMinimal
     */
    'service_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryReadWriteMinimal
     */
    'region': string;
    /**
     * 
     * @type {string}
     * @memberof CanaryReadWriteMinimal
     */
    'region_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryReadWriteMinimal
     */
    'availability_zone_name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CanaryReadWriteMinimal
     */
    'is_agent_canary'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CanaryReadWriteMinimal
     */
    'canary_alarms_alarming'?: string;
}

export const CanaryReadWriteMinimalStatusEnum = {
    Running: 'RUNNING',
    Stopped: 'STOPPED',
    Error: 'ERROR',
    New: 'NEW'
} as const;

export type CanaryReadWriteMinimalStatusEnum = typeof CanaryReadWriteMinimalStatusEnum[keyof typeof CanaryReadWriteMinimalStatusEnum];

/**
 * 
 * @export
 * @interface CanaryRestfulLibSourceFile
 */
export interface CanaryRestfulLibSourceFile {
    /**
     * 
     * @type {string}
     * @memberof CanaryRestfulLibSourceFile
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryRestfulLibSourceFile
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryRestfulLibSourceFile
     */
    'relative_path'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryRestfulLibSourceFile
     */
    'file_contents'?: string | null;
}
/**
 * 
 * @export
 * @interface CanaryRestfulLibSourceFileMinimalReadOnly
 */
export interface CanaryRestfulLibSourceFileMinimalReadOnly {
    /**
     * 
     * @type {string}
     * @memberof CanaryRestfulLibSourceFileMinimalReadOnly
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryRestfulLibSourceFileMinimalReadOnly
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryRestfulLibSourceFileMinimalReadOnly
     */
    'relative_path'?: string;
}
/**
 * 
 * @export
 * @interface CanarySchedule
 */
export interface CanarySchedule {
    /**
     * 
     * @type {string}
     * @memberof CanarySchedule
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanarySchedule
     */
    'schedule'?: string;
}
/**
 * 
 * @export
 * @interface CanaryServiceProvider
 */
export interface CanaryServiceProvider {
    /**
     * 
     * @type {string}
     * @memberof CanaryServiceProvider
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryServiceProvider
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryServiceProvider
     */
    'organization_id'?: string;
}
/**
 * 
 * @export
 * @interface CanaryServiceProviderAvailabilityZone
 */
export interface CanaryServiceProviderAvailabilityZone {
    /**
     * 
     * @type {string}
     * @memberof CanaryServiceProviderAvailabilityZone
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryServiceProviderAvailabilityZone
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface CanaryServiceProviderListReadOnly
 */
export interface CanaryServiceProviderListReadOnly {
    /**
     * 
     * @type {string}
     * @memberof CanaryServiceProviderListReadOnly
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryServiceProviderListReadOnly
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryServiceProviderListReadOnly
     */
    'organization_id'?: string;
}
/**
 * 
 * @export
 * @interface CanaryServiceProviderRegion
 */
export interface CanaryServiceProviderRegion {
    /**
     * 
     * @type {string}
     * @memberof CanaryServiceProviderRegion
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryServiceProviderRegion
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface CanaryStatus
 */
export interface CanaryStatus {
    /**
     * 
     * @type {string}
     * @memberof CanaryStatus
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryStatus
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryStatus
     */
    'status'?: CanaryStatusStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CanaryStatus
     */
    'service_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryStatus
     */
    'service_name'?: string;
}

export const CanaryStatusStatusEnum = {
    Running: 'RUNNING',
    Stopped: 'STOPPED',
    Error: 'ERROR',
    New: 'NEW'
} as const;

export type CanaryStatusStatusEnum = typeof CanaryStatusStatusEnum[keyof typeof CanaryStatusStatusEnum];

/**
 * 
 * @export
 * @interface CanaryTask
 */
export interface CanaryTask {
    /**
     * 
     * @type {string}
     * @memberof CanaryTask
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryTask
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CanaryTask
     */
    'graph_url'?: string;
    /**
     * 
     * @type {object}
     * @memberof CanaryTask
     */
    'env_vars_schema'?: object;
    /**
     * 
     * @type {object}
     * @memberof CanaryTask
     */
    'env_vars_ui_schema'?: object;
    /**
     * 
     * @type {object}
     * @memberof CanaryTask
     */
    'env_vars'?: object;
    /**
     * 
     * @type {string}
     * @memberof CanaryTask
     */
    'return_data_type'?: CanaryTaskReturnDataTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CanaryTask
     */
    'latency_metric_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryTask
     */
    'latency_metric_boundary_condition_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryTask
     */
    'latency_metric_alarm_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryTask
     */
    'response_value_metric_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryTask
     */
    'response_value_metric_boundary_condition_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryTask
     */
    'response_value_metric_alarm_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryTask
     */
    'forecasted_latency_metric_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryTask
     */
    'forecasted_latency_metric_boundary_condition_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryTask
     */
    'forecasted_latency_metric_alarm_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryTask
     */
    'forecasted_response_value_metric_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryTask
     */
    'forecasted_response_value_metric_boundary_condition_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryTask
     */
    'forecasted_response_value_metric_alarm_id'?: string;
}

export const CanaryTaskReturnDataTypeEnum = {
    Boolean: 'boolean',
    String: 'string',
    Integer: 'integer',
    Decimal: 'decimal',
    Json: 'json'
} as const;

export type CanaryTaskReturnDataTypeEnum = typeof CanaryTaskReturnDataTypeEnum[keyof typeof CanaryTaskReturnDataTypeEnum];

/**
 * 
 * @export
 * @interface CanaryTaskEnvVars
 */
export interface CanaryTaskEnvVars {
    /**
     * 
     * @type {object}
     * @memberof CanaryTaskEnvVars
     */
    'env_vars'?: object;
}
/**
 * 
 * @export
 * @interface CanaryTaskMetricData
 */
export interface CanaryTaskMetricData {
    /**
     * 
     * @type {string}
     * @memberof CanaryTaskMetricData
     */
    'measured'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryTaskMetricData
     */
    'metric'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryTaskMetricData
     */
    'canary'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryTaskMetricData
     */
    'task'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryTaskMetricData
     */
    'normalized_value': string;
}
/**
 * 
 * @export
 * @interface CanaryTaskRunDataExportReadOnly
 */
export interface CanaryTaskRunDataExportReadOnly {
    /**
     * 
     * @type {string}
     * @memberof CanaryTaskRunDataExportReadOnly
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryTaskRunDataExportReadOnly
     */
    'measured'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CanaryTaskRunDataExportReadOnly
     */
    'error_get'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CanaryTaskRunDataExportReadOnly
     */
    'error_parse'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CanaryTaskRunDataExportReadOnly
     */
    'response_latency'?: number;
    /**
     * 
     * @type {string}
     * @memberof CanaryTaskRunDataExportReadOnly
     */
    'latency_value_alarm_status'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryTaskRunDataExportReadOnly
     */
    'normalized_value'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryTaskRunDataExportReadOnly
     */
    'response_value_alarm_status'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CanaryTaskRunDataExportReadOnly
     */
    'is_boolean'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CanaryTaskRunDataExportReadOnly
     */
    'is_integer'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CanaryTaskRunDataExportReadOnly
     */
    'is_decimal'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CanaryTaskRunDataExportReadOnly
     */
    'is_string'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CanaryTaskRunDataExportReadOnly
     */
    'is_json'?: boolean;
}
/**
 * 
 * @export
 * @interface CanaryTaskRunDataWriteOnly
 */
export interface CanaryTaskRunDataWriteOnly {
    /**
     * 
     * @type {string}
     * @memberof CanaryTaskRunDataWriteOnly
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryTaskRunDataWriteOnly
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryTaskRunDataWriteOnly
     */
    'run_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CanaryTaskRunDataWriteOnly
     */
    'dag_id'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CanaryTaskRunDataWriteOnly
     */
    'try_number'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CanaryTaskRunDataWriteOnly
     */
    'measured': string;
    /**
     * 
     * @type {object}
     * @memberof CanaryTaskRunDataWriteOnly
     */
    'raw_data'?: object | null;
    /**
     * 
     * @type {string}
     * @memberof CanaryTaskRunDataWriteOnly
     */
    'canary': string;
    /**
     * 
     * @type {string}
     * @memberof CanaryTaskRunDataWriteOnly
     */
    'task': string;
}
/**
 * 
 * @export
 * @interface CanaryTasksInner
 */
export interface CanaryTasksInner {
    /**
     * 
     * @type {string}
     * @memberof CanaryTasksInner
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryTasksInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryTasksInner
     */
    'graph_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryTasksInner
     */
    'return_data_type'?: CanaryTasksInnerReturnDataTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CanaryTasksInner
     */
    'latency_metric_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryTasksInner
     */
    'latency_metric_boundary_condition_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryTasksInner
     */
    'latency_metric_alarm_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryTasksInner
     */
    'response_value_metric_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryTasksInner
     */
    'response_value_metric_boundary_condition_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryTasksInner
     */
    'response_value_metric_alarm_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryTasksInner
     */
    'forecasted_latency_metric_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryTasksInner
     */
    'forecasted_latency_metric_boundary_condition_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryTasksInner
     */
    'forecasted_latency_metric_alarm_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryTasksInner
     */
    'forecasted_response_value_metric_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryTasksInner
     */
    'forecasted_response_value_metric_boundary_condition_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryTasksInner
     */
    'forecasted_response_value_metric_alarm_id'?: string;
}

export const CanaryTasksInnerReturnDataTypeEnum = {
    Boolean: 'boolean',
    String: 'string',
    Integer: 'integer',
    Decimal: 'decimal',
    Json: 'json'
} as const;

export type CanaryTasksInnerReturnDataTypeEnum = typeof CanaryTasksInnerReturnDataTypeEnum[keyof typeof CanaryTasksInnerReturnDataTypeEnum];

/**
 * 
 * @export
 * @interface CanaryTemplateLibrary
 */
export interface CanaryTemplateLibrary {
    /**
     * 
     * @type {string}
     * @memberof CanaryTemplateLibrary
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryTemplateLibrary
     */
    'created'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CanaryTemplateLibrary
     */
    'is_processed'?: boolean;
    /**
     * 
     * @type {File}
     * @memberof CanaryTemplateLibrary
     */
    'uploaded_file'?: File;
    /**
     * 
     * @type {boolean}
     * @memberof CanaryTemplateLibrary
     */
    'canaries_generated'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CanaryTemplateLibrary
     */
    'templates_populated'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CanaryTemplateLibrary
     */
    'generated_provider_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CanaryTemplateLibrary
     */
    'generated_service_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CanaryTemplateLibrary
     */
    'organization_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryTemplateLibrary
     */
    'organization_name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CanaryTemplateLibrary
     */
    'has_existing_canaries'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CanaryTemplateLibrary
     */
    'template_tasks_available_count'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CanaryTemplateLibrary
     */
    'image_built'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CanaryTemplateLibrary
     */
    'dockerfile_contents'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryTemplateLibrary
     */
    'container_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryTemplateLibrary
     */
    'provider_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryTemplateLibrary
     */
    'provider_name'?: string;
}
/**
 * 
 * @export
 * @interface CanaryUserReadOnly
 */
export interface CanaryUserReadOnly {
    /**
     * 
     * @type {string}
     * @memberof CanaryUserReadOnly
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryUserReadOnly
     */
    'image'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryUserReadOnly
     */
    'url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CanaryUserReadOnly
     */
    'is_active'?: boolean;
}
/**
 * 
 * @export
 * @interface CanaryableService
 */
export interface CanaryableService {
    /**
     * 
     * @type {string}
     * @memberof CanaryableService
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryableService
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryableService
     */
    'organization_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CanaryableService
     */
    'provider_id'?: string;
}
/**
 * 
 * @export
 * @interface CompositeDailyForecast
 */
export interface CompositeDailyForecast {
    /**
     * 
     * @type {string}
     * @memberof CompositeDailyForecast
     */
    'organization'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompositeDailyForecast
     */
    'for_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompositeDailyForecast
     */
    'day_of_week'?: string;
    /**
     * 
     * @type {number}
     * @memberof CompositeDailyForecast
     */
    'percentage_chance_of_incident'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CompositeDailyForecast
     */
    'has_prediction'?: boolean;
}
/**
 * 
 * @export
 * @interface ContactRequestWriteOnly
 */
export interface ContactRequestWriteOnly {
    /**
     * 
     * @type {string}
     * @memberof ContactRequestWriteOnly
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof ContactRequestWriteOnly
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof ContactRequestWriteOnly
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactRequestWriteOnly
     */
    'phone_number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactRequestWriteOnly
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ContactRequestWriteOnly
     */
    'company'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactRequestWriteOnly
     */
    'subject': string;
    /**
     * 
     * @type {string}
     * @memberof ContactRequestWriteOnly
     */
    'question': string;
    /**
     * 
     * @type {number}
     * @memberof ContactRequestWriteOnly
     */
    'role'?: number | null;
}
/**
 * 
 * @export
 * @interface IncidentProbabilityReadOnly
 */
export interface IncidentProbabilityReadOnly {
    /**
     * 
     * @type {string}
     * @memberof IncidentProbabilityReadOnly
     */
    'organization'?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentProbabilityReadOnly
     */
    'for_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof IncidentProbabilityReadOnly
     */
    'day_of_week'?: string;
    /**
     * 
     * @type {number}
     * @memberof IncidentProbabilityReadOnly
     */
    'percentage_chance_of_incident'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof IncidentProbabilityReadOnly
     */
    'has_prediction'?: boolean;
}
/**
 * 
 * @export
 * @interface KubernetesCluster
 */
export interface KubernetesCluster {
    /**
     * 
     * @type {string}
     * @memberof KubernetesCluster
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof KubernetesCluster
     */
    'organization'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KubernetesCluster
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof KubernetesCluster
     */
    'kube_config'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof KubernetesCluster
     */
    'is_internal'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof KubernetesCluster
     */
    'kube_image_secret_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof KubernetesCluster
     */
    'kube_image_server'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KubernetesCluster
     */
    'kube_image_username'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KubernetesCluster
     */
    'kube_image_email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KubernetesCluster
     */
    'kube_image_password'?: string | null;
}
/**
 * 
 * @export
 * @interface LandingPageVideoReadOnly
 */
export interface LandingPageVideoReadOnly {
    /**
     * 
     * @type {string}
     * @memberof LandingPageVideoReadOnly
     */
    'name'?: string;
    /**
     * Video title for the first video.
     * @type {string}
     * @memberof LandingPageVideoReadOnly
     */
    'first_video_title'?: string | null;
    /**
     * Link for the first video.
     * @type {string}
     * @memberof LandingPageVideoReadOnly
     */
    'video'?: string | null;
    /**
     * Video title for the second video.
     * @type {string}
     * @memberof LandingPageVideoReadOnly
     */
    'second_video_title'?: string | null;
    /**
     * Link for the second video.
     * @type {string}
     * @memberof LandingPageVideoReadOnly
     */
    'video2'?: string | null;
    /**
     * Video title for the third video.
     * @type {string}
     * @memberof LandingPageVideoReadOnly
     */
    'third_video_title'?: string | null;
    /**
     * Link for the third video.
     * @type {string}
     * @memberof LandingPageVideoReadOnly
     */
    'video3'?: string | null;
    /**
     * Video title for the fourth video.
     * @type {string}
     * @memberof LandingPageVideoReadOnly
     */
    'fourth_video_title'?: string | null;
    /**
     * Link for the fourth video.
     * @type {string}
     * @memberof LandingPageVideoReadOnly
     */
    'video4'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LandingPageVideoReadOnly
     */
    'is_active'?: boolean;
}
/**
 * 
 * @export
 * @interface ListAbstractMetricBoundaryConditions200Response
 */
export interface ListAbstractMetricBoundaryConditions200Response {
    /**
     * 
     * @type {number}
     * @memberof ListAbstractMetricBoundaryConditions200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListAbstractMetricBoundaryConditions200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListAbstractMetricBoundaryConditions200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<AbstractMetricBoundaryCondition>}
     * @memberof ListAbstractMetricBoundaryConditions200Response
     */
    'results': Array<AbstractMetricBoundaryCondition>;
}
/**
 * 
 * @export
 * @interface ListAbstractMetrics200Response
 */
export interface ListAbstractMetrics200Response {
    /**
     * 
     * @type {number}
     * @memberof ListAbstractMetrics200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListAbstractMetrics200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListAbstractMetrics200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<AbstractMetric>}
     * @memberof ListAbstractMetrics200Response
     */
    'results': Array<AbstractMetric>;
}
/**
 * 
 * @export
 * @interface ListBillingDetails200Response
 */
export interface ListBillingDetails200Response {
    /**
     * 
     * @type {number}
     * @memberof ListBillingDetails200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListBillingDetails200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListBillingDetails200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<BillingDetail>}
     * @memberof ListBillingDetails200Response
     */
    'results': Array<BillingDetail>;
}
/**
 * 
 * @export
 * @interface ListCanaries200Response
 */
export interface ListCanaries200Response {
    /**
     * 
     * @type {number}
     * @memberof ListCanaries200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListCanaries200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListCanaries200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<CanaryReadWriteMinimal>}
     * @memberof ListCanaries200Response
     */
    'results': Array<CanaryReadWriteMinimal>;
}
/**
 * 
 * @export
 * @interface ListCanaryDailyForecasts200Response
 */
export interface ListCanaryDailyForecasts200Response {
    /**
     * 
     * @type {number}
     * @memberof ListCanaryDailyForecasts200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListCanaryDailyForecasts200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListCanaryDailyForecasts200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<CanaryDailyForecast>}
     * @memberof ListCanaryDailyForecasts200Response
     */
    'results': Array<CanaryDailyForecast>;
}
/**
 * 
 * @export
 * @interface ListCanaryPartners200Response
 */
export interface ListCanaryPartners200Response {
    /**
     * 
     * @type {number}
     * @memberof ListCanaryPartners200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListCanaryPartners200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListCanaryPartners200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<CanaryPartnerReadOnly>}
     * @memberof ListCanaryPartners200Response
     */
    'results': Array<CanaryPartnerReadOnly>;
}
/**
 * 
 * @export
 * @interface ListCanaryPrototypeSourceCodes200Response
 */
export interface ListCanaryPrototypeSourceCodes200Response {
    /**
     * 
     * @type {number}
     * @memberof ListCanaryPrototypeSourceCodes200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListCanaryPrototypeSourceCodes200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListCanaryPrototypeSourceCodes200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<CanaryPrototypeSourceFileMinimalReadOnly>}
     * @memberof ListCanaryPrototypeSourceCodes200Response
     */
    'results': Array<CanaryPrototypeSourceFileMinimalReadOnly>;
}
/**
 * 
 * @export
 * @interface ListCanaryRestfulLibSourceCodes200Response
 */
export interface ListCanaryRestfulLibSourceCodes200Response {
    /**
     * 
     * @type {number}
     * @memberof ListCanaryRestfulLibSourceCodes200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListCanaryRestfulLibSourceCodes200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListCanaryRestfulLibSourceCodes200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<CanaryRestfulLibSourceFileMinimalReadOnly>}
     * @memberof ListCanaryRestfulLibSourceCodes200Response
     */
    'results': Array<CanaryRestfulLibSourceFileMinimalReadOnly>;
}
/**
 * 
 * @export
 * @interface ListCanaryTaskMetricData200Response
 */
export interface ListCanaryTaskMetricData200Response {
    /**
     * 
     * @type {number}
     * @memberof ListCanaryTaskMetricData200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListCanaryTaskMetricData200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListCanaryTaskMetricData200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<CanaryTaskMetricData>}
     * @memberof ListCanaryTaskMetricData200Response
     */
    'results': Array<CanaryTaskMetricData>;
}
/**
 * 
 * @export
 * @interface ListCanaryTaskRunDataExports200Response
 */
export interface ListCanaryTaskRunDataExports200Response {
    /**
     * 
     * @type {number}
     * @memberof ListCanaryTaskRunDataExports200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListCanaryTaskRunDataExports200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListCanaryTaskRunDataExports200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<CanaryTaskRunDataExportReadOnly>}
     * @memberof ListCanaryTaskRunDataExports200Response
     */
    'results': Array<CanaryTaskRunDataExportReadOnly>;
}
/**
 * 
 * @export
 * @interface ListCanaryTasks200Response
 */
export interface ListCanaryTasks200Response {
    /**
     * 
     * @type {number}
     * @memberof ListCanaryTasks200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListCanaryTasks200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListCanaryTasks200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<CanaryDetailTaskReadOnlyMinimal>}
     * @memberof ListCanaryTasks200Response
     */
    'results': Array<CanaryDetailTaskReadOnlyMinimal>;
}
/**
 * 
 * @export
 * @interface ListCanaryTemplateLibraries200Response
 */
export interface ListCanaryTemplateLibraries200Response {
    /**
     * 
     * @type {number}
     * @memberof ListCanaryTemplateLibraries200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListCanaryTemplateLibraries200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListCanaryTemplateLibraries200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<CanaryTemplateLibrary>}
     * @memberof ListCanaryTemplateLibraries200Response
     */
    'results': Array<CanaryTemplateLibrary>;
}
/**
 * 
 * @export
 * @interface ListCanaryUsers200Response
 */
export interface ListCanaryUsers200Response {
    /**
     * 
     * @type {number}
     * @memberof ListCanaryUsers200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListCanaryUsers200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListCanaryUsers200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<CanaryUserReadOnly>}
     * @memberof ListCanaryUsers200Response
     */
    'results': Array<CanaryUserReadOnly>;
}
/**
 * 
 * @export
 * @interface ListCanaryableServices200Response
 */
export interface ListCanaryableServices200Response {
    /**
     * 
     * @type {number}
     * @memberof ListCanaryableServices200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListCanaryableServices200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListCanaryableServices200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<CanaryableService>}
     * @memberof ListCanaryableServices200Response
     */
    'results': Array<CanaryableService>;
}
/**
 * 
 * @export
 * @interface ListCompositeDailyForecasts200Response
 */
export interface ListCompositeDailyForecasts200Response {
    /**
     * 
     * @type {number}
     * @memberof ListCompositeDailyForecasts200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListCompositeDailyForecasts200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListCompositeDailyForecasts200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<CompositeDailyForecast>}
     * @memberof ListCompositeDailyForecasts200Response
     */
    'results': Array<CompositeDailyForecast>;
}
/**
 * 
 * @export
 * @interface ListCustomUserRoles200Response
 */
export interface ListCustomUserRoles200Response {
    /**
     * 
     * @type {number}
     * @memberof ListCustomUserRoles200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListCustomUserRoles200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListCustomUserRoles200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<UserRoleReadOnly>}
     * @memberof ListCustomUserRoles200Response
     */
    'results': Array<UserRoleReadOnly>;
}
/**
 * 
 * @export
 * @interface ListKubernetesClusters200Response
 */
export interface ListKubernetesClusters200Response {
    /**
     * 
     * @type {number}
     * @memberof ListKubernetesClusters200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListKubernetesClusters200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListKubernetesClusters200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<KubernetesCluster>}
     * @memberof ListKubernetesClusters200Response
     */
    'results': Array<KubernetesCluster>;
}
/**
 * 
 * @export
 * @interface ListLandingPageVideos200Response
 */
export interface ListLandingPageVideos200Response {
    /**
     * 
     * @type {number}
     * @memberof ListLandingPageVideos200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListLandingPageVideos200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListLandingPageVideos200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<LandingPageVideoReadOnly>}
     * @memberof ListLandingPageVideos200Response
     */
    'results': Array<LandingPageVideoReadOnly>;
}
/**
 * 
 * @export
 * @interface ListNeuralProphetCanaryDailyForecastData200Response
 */
export interface ListNeuralProphetCanaryDailyForecastData200Response {
    /**
     * 
     * @type {number}
     * @memberof ListNeuralProphetCanaryDailyForecastData200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListNeuralProphetCanaryDailyForecastData200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListNeuralProphetCanaryDailyForecastData200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<NeuralCanaryDailyForecastData>}
     * @memberof ListNeuralProphetCanaryDailyForecastData200Response
     */
    'results': Array<NeuralCanaryDailyForecastData>;
}
/**
 * 
 * @export
 * @interface ListNeuralProphetCanaryDailyForecasts200Response
 */
export interface ListNeuralProphetCanaryDailyForecasts200Response {
    /**
     * 
     * @type {number}
     * @memberof ListNeuralProphetCanaryDailyForecasts200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListNeuralProphetCanaryDailyForecasts200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListNeuralProphetCanaryDailyForecasts200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<NeuralCanaryDailyForecast>}
     * @memberof ListNeuralProphetCanaryDailyForecasts200Response
     */
    'results': Array<NeuralCanaryDailyForecast>;
}
/**
 * 
 * @export
 * @interface ListNeuralProphetCompositeDailyForecasts200Response
 */
export interface ListNeuralProphetCompositeDailyForecasts200Response {
    /**
     * 
     * @type {number}
     * @memberof ListNeuralProphetCompositeDailyForecasts200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListNeuralProphetCompositeDailyForecasts200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListNeuralProphetCompositeDailyForecasts200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<NeuralCompositeDailyForecast>}
     * @memberof ListNeuralProphetCompositeDailyForecasts200Response
     */
    'results': Array<NeuralCompositeDailyForecast>;
}
/**
 * 
 * @export
 * @interface ListNeuralProphetServiceDailyForecasts200Response
 */
export interface ListNeuralProphetServiceDailyForecasts200Response {
    /**
     * 
     * @type {number}
     * @memberof ListNeuralProphetServiceDailyForecasts200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListNeuralProphetServiceDailyForecasts200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListNeuralProphetServiceDailyForecasts200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<NeuralServiceDailyForecast>}
     * @memberof ListNeuralProphetServiceDailyForecasts200Response
     */
    'results': Array<NeuralServiceDailyForecast>;
}
/**
 * 
 * @export
 * @interface ListNotificationChannelSettings200Response
 */
export interface ListNotificationChannelSettings200Response {
    /**
     * 
     * @type {number}
     * @memberof ListNotificationChannelSettings200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListNotificationChannelSettings200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListNotificationChannelSettings200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<NotificationChannelSetting>}
     * @memberof ListNotificationChannelSettings200Response
     */
    'results': Array<NotificationChannelSetting>;
}
/**
 * 
 * @export
 * @interface ListNotificationHistories200Response
 */
export interface ListNotificationHistories200Response {
    /**
     * 
     * @type {number}
     * @memberof ListNotificationHistories200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListNotificationHistories200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListNotificationHistories200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<NotificationHistory>}
     * @memberof ListNotificationHistories200Response
     */
    'results': Array<NotificationHistory>;
}
/**
 * 
 * @export
 * @interface ListNotifications200Response
 */
export interface ListNotifications200Response {
    /**
     * 
     * @type {number}
     * @memberof ListNotifications200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListNotifications200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListNotifications200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<Notification>}
     * @memberof ListNotifications200Response
     */
    'results': Array<Notification>;
}
/**
 * 
 * @export
 * @interface ListOpenAPIRestfulLibraries200Response
 */
export interface ListOpenAPIRestfulLibraries200Response {
    /**
     * 
     * @type {number}
     * @memberof ListOpenAPIRestfulLibraries200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListOpenAPIRestfulLibraries200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListOpenAPIRestfulLibraries200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<OpenAPIRestfulLibrary>}
     * @memberof ListOpenAPIRestfulLibraries200Response
     */
    'results': Array<OpenAPIRestfulLibrary>;
}
/**
 * 
 * @export
 * @interface ListOrganizationAPIKeys200Response
 */
export interface ListOrganizationAPIKeys200Response {
    /**
     * 
     * @type {number}
     * @memberof ListOrganizationAPIKeys200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListOrganizationAPIKeys200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListOrganizationAPIKeys200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<OrganizationAPIKey>}
     * @memberof ListOrganizationAPIKeys200Response
     */
    'results': Array<OrganizationAPIKey>;
}
/**
 * 
 * @export
 * @interface ListOrganizations200Response
 */
export interface ListOrganizations200Response {
    /**
     * 
     * @type {number}
     * @memberof ListOrganizations200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListOrganizations200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListOrganizations200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<UserOrganization>}
     * @memberof ListOrganizations200Response
     */
    'results': Array<UserOrganization>;
}
/**
 * 
 * @export
 * @interface ListPublicAPICanaries200Response
 */
export interface ListPublicAPICanaries200Response {
    /**
     * 
     * @type {number}
     * @memberof ListPublicAPICanaries200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListPublicAPICanaries200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListPublicAPICanaries200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<CanaryLists>}
     * @memberof ListPublicAPICanaries200Response
     */
    'results': Array<CanaryLists>;
}
/**
 * 
 * @export
 * @interface ListPublicAPICanaryableServices200Response
 */
export interface ListPublicAPICanaryableServices200Response {
    /**
     * 
     * @type {number}
     * @memberof ListPublicAPICanaryableServices200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListPublicAPICanaryableServices200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListPublicAPICanaryableServices200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<OrganizationServicesListReadOnly>}
     * @memberof ListPublicAPICanaryableServices200Response
     */
    'results': Array<OrganizationServicesListReadOnly>;
}
/**
 * 
 * @export
 * @interface ListPublicAPINeuralProphetCompositeDailyForecasts200Response
 */
export interface ListPublicAPINeuralProphetCompositeDailyForecasts200Response {
    /**
     * 
     * @type {number}
     * @memberof ListPublicAPINeuralProphetCompositeDailyForecasts200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListPublicAPINeuralProphetCompositeDailyForecasts200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListPublicAPINeuralProphetCompositeDailyForecasts200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<IncidentProbabilityReadOnly>}
     * @memberof ListPublicAPINeuralProphetCompositeDailyForecasts200Response
     */
    'results': Array<IncidentProbabilityReadOnly>;
}
/**
 * 
 * @export
 * @interface ListPublicAPIOrganizations200Response
 */
export interface ListPublicAPIOrganizations200Response {
    /**
     * 
     * @type {number}
     * @memberof ListPublicAPIOrganizations200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListPublicAPIOrganizations200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListPublicAPIOrganizations200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<OrganizationsListReadOnly>}
     * @memberof ListPublicAPIOrganizations200Response
     */
    'results': Array<OrganizationsListReadOnly>;
}
/**
 * 
 * @export
 * @interface ListPublicAPIServiceComplianceSummaries200Response
 */
export interface ListPublicAPIServiceComplianceSummaries200Response {
    /**
     * 
     * @type {number}
     * @memberof ListPublicAPIServiceComplianceSummaries200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListPublicAPIServiceComplianceSummaries200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListPublicAPIServiceComplianceSummaries200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<ServiceComplianceSummaryListReadOnly>}
     * @memberof ListPublicAPIServiceComplianceSummaries200Response
     */
    'results': Array<ServiceComplianceSummaryListReadOnly>;
}
/**
 * 
 * @export
 * @interface ListPublicAPIServiceHealths200Response
 */
export interface ListPublicAPIServiceHealths200Response {
    /**
     * 
     * @type {number}
     * @memberof ListPublicAPIServiceHealths200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListPublicAPIServiceHealths200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListPublicAPIServiceHealths200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<ServiceHealthStatusList>}
     * @memberof ListPublicAPIServiceHealths200Response
     */
    'results': Array<ServiceHealthStatusList>;
}
/**
 * 
 * @export
 * @interface ListPublicAPIServiceProviders200Response
 */
export interface ListPublicAPIServiceProviders200Response {
    /**
     * 
     * @type {number}
     * @memberof ListPublicAPIServiceProviders200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListPublicAPIServiceProviders200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListPublicAPIServiceProviders200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<CanaryServiceProviderListReadOnly>}
     * @memberof ListPublicAPIServiceProviders200Response
     */
    'results': Array<CanaryServiceProviderListReadOnly>;
}
/**
 * 
 * @export
 * @interface ListServiceComplianceHistories200Response
 */
export interface ListServiceComplianceHistories200Response {
    /**
     * 
     * @type {number}
     * @memberof ListServiceComplianceHistories200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListServiceComplianceHistories200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListServiceComplianceHistories200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<ServiceComplianceHistory>}
     * @memberof ListServiceComplianceHistories200Response
     */
    'results': Array<ServiceComplianceHistory>;
}
/**
 * 
 * @export
 * @interface ListServiceComplianceSummaries200Response
 */
export interface ListServiceComplianceSummaries200Response {
    /**
     * 
     * @type {number}
     * @memberof ListServiceComplianceSummaries200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListServiceComplianceSummaries200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListServiceComplianceSummaries200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<ServiceComplianceSummary>}
     * @memberof ListServiceComplianceSummaries200Response
     */
    'results': Array<ServiceComplianceSummary>;
}
/**
 * 
 * @export
 * @interface ListServiceDailyForecasts200Response
 */
export interface ListServiceDailyForecasts200Response {
    /**
     * 
     * @type {number}
     * @memberof ListServiceDailyForecasts200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListServiceDailyForecasts200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListServiceDailyForecasts200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<ServiceDailyForecast>}
     * @memberof ListServiceDailyForecasts200Response
     */
    'results': Array<ServiceDailyForecast>;
}
/**
 * 
 * @export
 * @interface ListServiceHealthEvaluationSettingsGroups200Response
 */
export interface ListServiceHealthEvaluationSettingsGroups200Response {
    /**
     * 
     * @type {number}
     * @memberof ListServiceHealthEvaluationSettingsGroups200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListServiceHealthEvaluationSettingsGroups200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListServiceHealthEvaluationSettingsGroups200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<ServiceHealthEvaluationSettingsGroup>}
     * @memberof ListServiceHealthEvaluationSettingsGroups200Response
     */
    'results': Array<ServiceHealthEvaluationSettingsGroup>;
}
/**
 * 
 * @export
 * @interface ListServiceHealthIncidents200Response
 */
export interface ListServiceHealthIncidents200Response {
    /**
     * 
     * @type {number}
     * @memberof ListServiceHealthIncidents200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListServiceHealthIncidents200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListServiceHealthIncidents200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<ServiceHealthIncident>}
     * @memberof ListServiceHealthIncidents200Response
     */
    'results': Array<ServiceHealthIncident>;
}
/**
 * 
 * @export
 * @interface ListServiceHealths200Response
 */
export interface ListServiceHealths200Response {
    /**
     * 
     * @type {number}
     * @memberof ListServiceHealths200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListServiceHealths200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListServiceHealths200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<ServiceHealth>}
     * @memberof ListServiceHealths200Response
     */
    'results': Array<ServiceHealth>;
}
/**
 * 
 * @export
 * @interface ListServiceProviderAvailabilityZones200Response
 */
export interface ListServiceProviderAvailabilityZones200Response {
    /**
     * 
     * @type {number}
     * @memberof ListServiceProviderAvailabilityZones200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListServiceProviderAvailabilityZones200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListServiceProviderAvailabilityZones200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<CanaryServiceProviderAvailabilityZone>}
     * @memberof ListServiceProviderAvailabilityZones200Response
     */
    'results': Array<CanaryServiceProviderAvailabilityZone>;
}
/**
 * 
 * @export
 * @interface ListServiceProviderRegions200Response
 */
export interface ListServiceProviderRegions200Response {
    /**
     * 
     * @type {number}
     * @memberof ListServiceProviderRegions200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListServiceProviderRegions200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListServiceProviderRegions200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<CanaryServiceProviderRegion>}
     * @memberof ListServiceProviderRegions200Response
     */
    'results': Array<CanaryServiceProviderRegion>;
}
/**
 * 
 * @export
 * @interface ListServiceProviders200Response
 */
export interface ListServiceProviders200Response {
    /**
     * 
     * @type {number}
     * @memberof ListServiceProviders200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListServiceProviders200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListServiceProviders200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<CanaryServiceProvider>}
     * @memberof ListServiceProviders200Response
     */
    'results': Array<CanaryServiceProvider>;
}
/**
 * 
 * @export
 * @interface ListSimpleAlarmEvaluations200Response
 */
export interface ListSimpleAlarmEvaluations200Response {
    /**
     * 
     * @type {number}
     * @memberof ListSimpleAlarmEvaluations200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListSimpleAlarmEvaluations200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListSimpleAlarmEvaluations200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<SimpleAlarmEvaluation>}
     * @memberof ListSimpleAlarmEvaluations200Response
     */
    'results': Array<SimpleAlarmEvaluation>;
}
/**
 * 
 * @export
 * @interface ListSimpleAlarmHistories200Response
 */
export interface ListSimpleAlarmHistories200Response {
    /**
     * 
     * @type {number}
     * @memberof ListSimpleAlarmHistories200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListSimpleAlarmHistories200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListSimpleAlarmHistories200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<SimpleAlarmHistory>}
     * @memberof ListSimpleAlarmHistories200Response
     */
    'results': Array<SimpleAlarmHistory>;
}
/**
 * 
 * @export
 * @interface ListSimpleAlarms200Response
 */
export interface ListSimpleAlarms200Response {
    /**
     * 
     * @type {number}
     * @memberof ListSimpleAlarms200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListSimpleAlarms200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListSimpleAlarms200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<SimpleAlarm>}
     * @memberof ListSimpleAlarms200Response
     */
    'results': Array<SimpleAlarm>;
}
/**
 * 
 * @export
 * @interface ListSnowflakeCanaryDailyForecastData200Response
 */
export interface ListSnowflakeCanaryDailyForecastData200Response {
    /**
     * 
     * @type {number}
     * @memberof ListSnowflakeCanaryDailyForecastData200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListSnowflakeCanaryDailyForecastData200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListSnowflakeCanaryDailyForecastData200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<SnowflakeCanaryDailyForecastData>}
     * @memberof ListSnowflakeCanaryDailyForecastData200Response
     */
    'results': Array<SnowflakeCanaryDailyForecastData>;
}
/**
 * 
 * @export
 * @interface ListSnowflakeCanaryDailyForecasts200Response
 */
export interface ListSnowflakeCanaryDailyForecasts200Response {
    /**
     * 
     * @type {number}
     * @memberof ListSnowflakeCanaryDailyForecasts200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListSnowflakeCanaryDailyForecasts200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListSnowflakeCanaryDailyForecasts200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<SnowflakeCanaryDailyForecast>}
     * @memberof ListSnowflakeCanaryDailyForecasts200Response
     */
    'results': Array<SnowflakeCanaryDailyForecast>;
}
/**
 * 
 * @export
 * @interface ListSnowflakeCompositeDailyForecasts200Response
 */
export interface ListSnowflakeCompositeDailyForecasts200Response {
    /**
     * 
     * @type {number}
     * @memberof ListSnowflakeCompositeDailyForecasts200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListSnowflakeCompositeDailyForecasts200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListSnowflakeCompositeDailyForecasts200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<SnowflakeCompositeDailyForecast>}
     * @memberof ListSnowflakeCompositeDailyForecasts200Response
     */
    'results': Array<SnowflakeCompositeDailyForecast>;
}
/**
 * 
 * @export
 * @interface ListSnowflakeForecastModels200Response
 */
export interface ListSnowflakeForecastModels200Response {
    /**
     * 
     * @type {number}
     * @memberof ListSnowflakeForecastModels200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListSnowflakeForecastModels200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListSnowflakeForecastModels200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<SnowflakeForecastModel>}
     * @memberof ListSnowflakeForecastModels200Response
     */
    'results': Array<SnowflakeForecastModel>;
}
/**
 * 
 * @export
 * @interface ListSnowflakeServiceDailyForecasts200Response
 */
export interface ListSnowflakeServiceDailyForecasts200Response {
    /**
     * 
     * @type {number}
     * @memberof ListSnowflakeServiceDailyForecasts200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListSnowflakeServiceDailyForecasts200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListSnowflakeServiceDailyForecasts200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<SnowflakeServiceDailyForecast>}
     * @memberof ListSnowflakeServiceDailyForecasts200Response
     */
    'results': Array<SnowflakeServiceDailyForecast>;
}
/**
 * 
 * @export
 * @interface ListSnowflakes200Response
 */
export interface ListSnowflakes200Response {
    /**
     * 
     * @type {number}
     * @memberof ListSnowflakes200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListSnowflakes200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListSnowflakes200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<Snowflake>}
     * @memberof ListSnowflakes200Response
     */
    'results': Array<Snowflake>;
}
/**
 * 
 * @export
 * @interface ListStoreCanaries200Response
 */
export interface ListStoreCanaries200Response {
    /**
     * 
     * @type {number}
     * @memberof ListStoreCanaries200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListStoreCanaries200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListStoreCanaries200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<StoreCanaryReadOnly>}
     * @memberof ListStoreCanaries200Response
     */
    'results': Array<StoreCanaryReadOnly>;
}
/**
 * 
 * @export
 * @interface ListStoreCanaryClouds200Response
 */
export interface ListStoreCanaryClouds200Response {
    /**
     * 
     * @type {number}
     * @memberof ListStoreCanaryClouds200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListStoreCanaryClouds200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListStoreCanaryClouds200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<StoreCanaryCloudReadOnly>}
     * @memberof ListStoreCanaryClouds200Response
     */
    'results': Array<StoreCanaryCloudReadOnly>;
}
/**
 * 
 * @export
 * @interface ListStoreCanaryComponents200Response
 */
export interface ListStoreCanaryComponents200Response {
    /**
     * 
     * @type {number}
     * @memberof ListStoreCanaryComponents200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListStoreCanaryComponents200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListStoreCanaryComponents200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<StoreCanaryComponentReadOnly>}
     * @memberof ListStoreCanaryComponents200Response
     */
    'results': Array<StoreCanaryComponentReadOnly>;
}
/**
 * 
 * @export
 * @interface ListStoreCanaryProducts200Response
 */
export interface ListStoreCanaryProducts200Response {
    /**
     * 
     * @type {number}
     * @memberof ListStoreCanaryProducts200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListStoreCanaryProducts200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListStoreCanaryProducts200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<StoreCanaryProductReadOnly>}
     * @memberof ListStoreCanaryProducts200Response
     */
    'results': Array<StoreCanaryProductReadOnly>;
}
/**
 * 
 * @export
 * @interface ListSubscriptionCancelRequests200Response
 */
export interface ListSubscriptionCancelRequests200Response {
    /**
     * 
     * @type {number}
     * @memberof ListSubscriptionCancelRequests200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListSubscriptionCancelRequests200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListSubscriptionCancelRequests200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<SubscriptionCancelRequest>}
     * @memberof ListSubscriptionCancelRequests200Response
     */
    'results': Array<SubscriptionCancelRequest>;
}
/**
 * 
 * @export
 * @interface ListTemplateCanaries200Response
 */
export interface ListTemplateCanaries200Response {
    /**
     * 
     * @type {number}
     * @memberof ListTemplateCanaries200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListTemplateCanaries200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListTemplateCanaries200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<TemplateCanaryReadOnlyListItem>}
     * @memberof ListTemplateCanaries200Response
     */
    'results': Array<TemplateCanaryReadOnlyListItem>;
}
/**
 * 
 * @export
 * @interface ListTemplateTasks200Response
 */
export interface ListTemplateTasks200Response {
    /**
     * 
     * @type {number}
     * @memberof ListTemplateTasks200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListTemplateTasks200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListTemplateTasks200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<TemplateTaskReadOnly>}
     * @memberof ListTemplateTasks200Response
     */
    'results': Array<TemplateTaskReadOnly>;
}
/**
 * 
 * @export
 * @interface ListUserGatedContentDownloadRequests200Response
 */
export interface ListUserGatedContentDownloadRequests200Response {
    /**
     * 
     * @type {number}
     * @memberof ListUserGatedContentDownloadRequests200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListUserGatedContentDownloadRequests200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListUserGatedContentDownloadRequests200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<UserGatedContentDownloadRequest>}
     * @memberof ListUserGatedContentDownloadRequests200Response
     */
    'results': Array<UserGatedContentDownloadRequest>;
}
/**
 * 
 * @export
 * @interface ListUserSubscribedPlans200Response
 */
export interface ListUserSubscribedPlans200Response {
    /**
     * 
     * @type {number}
     * @memberof ListUserSubscribedPlans200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListUserSubscribedPlans200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListUserSubscribedPlans200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<UserSubscribedPlan>}
     * @memberof ListUserSubscribedPlans200Response
     */
    'results': Array<UserSubscribedPlan>;
}
/**
 * 
 * @export
 * @interface ListUsers200Response
 */
export interface ListUsers200Response {
    /**
     * 
     * @type {number}
     * @memberof ListUsers200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<User>}
     * @memberof ListUsers200Response
     */
    'results': Array<User>;
}
/**
 * 
 * @export
 * @interface NeuralCanaryDailyForecast
 */
export interface NeuralCanaryDailyForecast {
    /**
     * 
     * @type {string}
     * @memberof NeuralCanaryDailyForecast
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NeuralCanaryDailyForecast
     */
    'for_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof NeuralCanaryDailyForecast
     */
    'day_of_week'?: string;
    /**
     * 
     * @type {number}
     * @memberof NeuralCanaryDailyForecast
     */
    'percentage_chance_of_incident'?: number;
    /**
     * 
     * @type {number}
     * @memberof NeuralCanaryDailyForecast
     */
    'latency_issues_predicted'?: number;
    /**
     * 
     * @type {string}
     * @memberof NeuralCanaryDailyForecast
     */
    'service_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NeuralCanaryDailyForecast
     */
    'service_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof NeuralCanaryDailyForecast
     */
    'provider_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NeuralCanaryDailyForecast
     */
    'provider_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof NeuralCanaryDailyForecast
     */
    'canary_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof NeuralCanaryDailyForecast
     */
    'canary'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NeuralCanaryDailyForecast
     */
    'has_prediction'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NeuralCanaryDailyForecast
     */
    'task_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof NeuralCanaryDailyForecast
     */
    'template_canary_name'?: string;
}
/**
 * 
 * @export
 * @interface NeuralCanaryDailyForecastData
 */
export interface NeuralCanaryDailyForecastData {
    /**
     * 
     * @type {string}
     * @memberof NeuralCanaryDailyForecastData
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NeuralCanaryDailyForecastData
     */
    'for_timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof NeuralCanaryDailyForecastData
     */
    'day_of_week'?: string;
    /**
     * 
     * @type {string}
     * @memberof NeuralCanaryDailyForecastData
     */
    'forecast_normalized_value'?: string;
    /**
     * 
     * @type {number}
     * @memberof NeuralCanaryDailyForecastData
     */
    'response_latency'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof NeuralCanaryDailyForecastData
     */
    'service_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NeuralCanaryDailyForecastData
     */
    'service_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof NeuralCanaryDailyForecastData
     */
    'provider_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NeuralCanaryDailyForecastData
     */
    'provider_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof NeuralCanaryDailyForecastData
     */
    'canary_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof NeuralCanaryDailyForecastData
     */
    'canary'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NeuralCanaryDailyForecastData
     */
    'has_prediction'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NeuralCanaryDailyForecastData
     */
    'task_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof NeuralCanaryDailyForecastData
     */
    'template_canary_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof NeuralCanaryDailyForecastData
     */
    'canary_return_data_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof NeuralCanaryDailyForecastData
     */
    'abstract_metric_condition_boundary_value'?: string;
}
/**
 * 
 * @export
 * @interface NeuralCompositeDailyForecast
 */
export interface NeuralCompositeDailyForecast {
    /**
     * 
     * @type {string}
     * @memberof NeuralCompositeDailyForecast
     */
    'organization'?: string;
    /**
     * 
     * @type {string}
     * @memberof NeuralCompositeDailyForecast
     */
    'for_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof NeuralCompositeDailyForecast
     */
    'day_of_week'?: string;
    /**
     * 
     * @type {number}
     * @memberof NeuralCompositeDailyForecast
     */
    'percentage_chance_of_incident'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NeuralCompositeDailyForecast
     */
    'has_prediction'?: boolean;
}
/**
 * 
 * @export
 * @interface NeuralServiceDailyForecast
 */
export interface NeuralServiceDailyForecast {
    /**
     * 
     * @type {string}
     * @memberof NeuralServiceDailyForecast
     */
    'organization': string;
    /**
     * 
     * @type {string}
     * @memberof NeuralServiceDailyForecast
     */
    'for_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof NeuralServiceDailyForecast
     */
    'day_of_week'?: string;
    /**
     * 
     * @type {number}
     * @memberof NeuralServiceDailyForecast
     */
    'percentage_chance_of_incident'?: number;
    /**
     * 
     * @type {number}
     * @memberof NeuralServiceDailyForecast
     */
    'latency_issues_predicted'?: number;
    /**
     * 
     * @type {string}
     * @memberof NeuralServiceDailyForecast
     */
    'service_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NeuralServiceDailyForecast
     */
    'service_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof NeuralServiceDailyForecast
     */
    'provider_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NeuralServiceDailyForecast
     */
    'provider_name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NeuralServiceDailyForecast
     */
    'has_prediction'?: boolean;
}
/**
 * 
 * @export
 * @interface Notification
 */
export interface Notification {
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Notification
     */
    'channels'?: Array<NotificationChannelsEnum> | null;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'alarm': string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'transition_from'?: NotificationTransitionFromEnum;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'transition_to'?: NotificationTransitionToEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Notification
     */
    'is_system_notification'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'summary'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'impact'?: NotificationImpactEnum | null;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'severity'?: NotificationSeverityEnum | null;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'urgency'?: NotificationUrgencyEnum | null;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'priority'?: NotificationPriorityEnum | null;
}

export const NotificationChannelsEnum = {
    CloudCanaries: 'CloudCanaries',
    Slack: 'Slack',
    Jira: 'Jira',
    ServiceNow: 'ServiceNow',
    PagerDuty: 'PagerDuty'
} as const;

export type NotificationChannelsEnum = typeof NotificationChannelsEnum[keyof typeof NotificationChannelsEnum];
export const NotificationTransitionFromEnum = {
    NoData: 'NO_DATA',
    Alarming: 'ALARMING',
    Ok: 'OK'
} as const;

export type NotificationTransitionFromEnum = typeof NotificationTransitionFromEnum[keyof typeof NotificationTransitionFromEnum];
export const NotificationTransitionToEnum = {
    NoData: 'NO_DATA',
    Alarming: 'ALARMING',
    Ok: 'OK'
} as const;

export type NotificationTransitionToEnum = typeof NotificationTransitionToEnum[keyof typeof NotificationTransitionToEnum];
export const NotificationImpactEnum = {
    Highest: 'Highest',
    High: 'High',
    Medium: 'Medium',
    Low: 'Low',
    Lowest: 'Lowest'
} as const;

export type NotificationImpactEnum = typeof NotificationImpactEnum[keyof typeof NotificationImpactEnum];
export const NotificationSeverityEnum = {
    High: 'High',
    Medium: 'Medium',
    Low: 'Low'
} as const;

export type NotificationSeverityEnum = typeof NotificationSeverityEnum[keyof typeof NotificationSeverityEnum];
export const NotificationUrgencyEnum = {
    Critical: 'Critical',
    High: 'High',
    Medium: 'Medium',
    Low: 'Low'
} as const;

export type NotificationUrgencyEnum = typeof NotificationUrgencyEnum[keyof typeof NotificationUrgencyEnum];
export const NotificationPriorityEnum = {
    Highest: 'Highest',
    High: 'High',
    Medium: 'Medium',
    Low: 'Low',
    Lowest: 'Lowest'
} as const;

export type NotificationPriorityEnum = typeof NotificationPriorityEnum[keyof typeof NotificationPriorityEnum];

/**
 * 
 * @export
 * @interface NotificationChannelSetting
 */
export interface NotificationChannelSetting {
    /**
     * 
     * @type {string}
     * @memberof NotificationChannelSetting
     */
    'id'?: string;
    /**
     * The user email required by the channel to communicate with the API
     * @type {string}
     * @memberof NotificationChannelSetting
     */
    'user_email': string;
    /**
     * The auth token to use for authorizing communications with the API
     * @type {string}
     * @memberof NotificationChannelSetting
     */
    'auth_token': string;
    /**
     * 
     * @type {string}
     * @memberof NotificationChannelSetting
     */
    'notification_channel'?: NotificationChannelSettingNotificationChannelEnum;
    /**
     * 
     * @type {string}
     * @memberof NotificationChannelSetting
     */
    'channel_identifier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationChannelSetting
     */
    'organization_id'?: string;
    /**
     * The service desk id is the project id, which can be obtained from Project Settings > Portal Settings > Portal URL, it will be the number at the end.
     * @type {string}
     * @memberof NotificationChannelSetting
     */
    'jira_service_desk_id'?: string | null;
    /**
     * The customer request id, which can be obtained from Project Settings > Request Types > Incidents, click the request type and get the id from the url, it should be after /request-type/
     * @type {string}
     * @memberof NotificationChannelSetting
     */
    'jira_request_type_id'?: string | null;
    /**
     * The Resolve transition ID that will be performed when the alarm transits to OK, can be obtained from Project Settings > Workflows, choose the workflow related to the request type and click the edit icon under the actions column, view the workflow as a diagram, click the transition arrow, select Show transition in the customer portal, configure it to Set issue resolution to Done, then publish the worflow.
     * @type {string}
     * @memberof NotificationChannelSetting
     */
    'jira_ticket_resolve_transition_id'?: string | null;
    /**
     * The NO_DATA transition ID that will be performed when the alarm transits to NO_DATA, this step \"NO_DATA\" and transition from \"Open\" to \"NO_DATA\" and transition from \"Resolved\" to \"NO_DATA\" need to be added in the workflow and configured to be Shown in the customer portal and Set issue resolution needs to be \"Open\" so they can be available for the requests.
     * @type {string}
     * @memberof NotificationChannelSetting
     */
    'jira_ticket_no_data_transition_id'?: string | null;
}

export const NotificationChannelSettingNotificationChannelEnum = {
    CloudCanaries: 'CloudCanaries',
    Slack: 'Slack',
    Jira: 'Jira',
    ServiceNow: 'ServiceNow',
    PagerDuty: 'PagerDuty'
} as const;

export type NotificationChannelSettingNotificationChannelEnum = typeof NotificationChannelSettingNotificationChannelEnum[keyof typeof NotificationChannelSettingNotificationChannelEnum];

/**
 * 
 * @export
 * @interface NotificationHistory
 */
export interface NotificationHistory {
    /**
     * 
     * @type {string}
     * @memberof NotificationHistory
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationHistory
     */
    'notification'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationHistory
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationHistory
     */
    'notification_name'?: string;
}
/**
 * 
 * @export
 * @interface OpenAPIRestfulLibrary
 */
export interface OpenAPIRestfulLibrary {
    /**
     * 
     * @type {string}
     * @memberof OpenAPIRestfulLibrary
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OpenAPIRestfulLibrary
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof OpenAPIRestfulLibrary
     */
    'name'?: string;
    /**
     * 
     * @type {object}
     * @memberof OpenAPIRestfulLibrary
     */
    'processed_schema'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof OpenAPIRestfulLibrary
     */
    'is_generated'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OpenAPIRestfulLibrary
     */
    'is_stored'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OpenAPIRestfulLibrary
     */
    'organization_id'?: string;
    /**
     * 
     * @type {File}
     * @memberof OpenAPIRestfulLibrary
     */
    'input_file': File;
    /**
     * 
     * @type {File}
     * @memberof OpenAPIRestfulLibrary
     */
    'output_file'?: File;
    /**
     * 
     * @type {File}
     * @memberof OpenAPIRestfulLibrary
     */
    'template_library'?: File;
}
/**
 * 
 * @export
 * @interface Organization
 */
export interface Organization {
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'name': string;
    /**
     * 
     * @type {Array<OrganizationUsersInner>}
     * @memberof Organization
     */
    'users'?: Array<OrganizationUsersInner>;
    /**
     * 
     * @type {number}
     * @memberof Organization
     */
    'max_active_canaries'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Organization
     */
    'send_data_to_snowflake'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Organization
     */
    'use_cortex_forecast_model'?: boolean;
}
/**
 * 
 * @export
 * @interface OrganizationAPIKey
 */
export interface OrganizationAPIKey {
    /**
     * 
     * @type {string}
     * @memberof OrganizationAPIKey
     */
    'id'?: string;
    /**
     * A free-form name for the API key. Need not be unique. 50 characters max.
     * @type {string}
     * @memberof OrganizationAPIKey
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAPIKey
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAPIKey
     */
    'prefix'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAPIKey
     */
    'organization': string;
    /**
     * If the API key is revoked, clients cannot use it anymore. (This cannot be undone.)
     * @type {boolean}
     * @memberof OrganizationAPIKey
     */
    'revoked'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAPIKey
     */
    'organization_name'?: string;
}
/**
 * 
 * @export
 * @interface OrganizationAPIKeyContents
 */
export interface OrganizationAPIKeyContents {
    /**
     * 
     * @type {string}
     * @memberof OrganizationAPIKeyContents
     */
    'key_contents'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAPIKeyContents
     */
    'organization': string;
}
/**
 * 
 * @export
 * @interface OrganizationAPIKeyUpdate
 */
export interface OrganizationAPIKeyUpdate {
    /**
     * 
     * @type {string}
     * @memberof OrganizationAPIKeyUpdate
     */
    'prefix'?: string;
    /**
     * A free-form name for the API key. Need not be unique. 50 characters max.
     * @type {string}
     * @memberof OrganizationAPIKeyUpdate
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAPIKeyUpdate
     */
    'organization': string;
    /**
     * If the API key is revoked, clients cannot use it anymore. (This cannot be undone.)
     * @type {boolean}
     * @memberof OrganizationAPIKeyUpdate
     */
    'revoked'?: boolean;
}
/**
 * 
 * @export
 * @interface OrganizationMember
 */
export interface OrganizationMember {
    /**
     * 
     * @type {string}
     * @memberof OrganizationMember
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationMember
     */
    'email'?: string | null;
}
/**
 * 
 * @export
 * @interface OrganizationServicesListReadOnly
 */
export interface OrganizationServicesListReadOnly {
    /**
     * 
     * @type {string}
     * @memberof OrganizationServicesListReadOnly
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationServicesListReadOnly
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationServicesListReadOnly
     */
    'organization_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationServicesListReadOnly
     */
    'provider_id'?: string;
}
/**
 * 
 * @export
 * @interface OrganizationUpdate
 */
export interface OrganizationUpdate {
    /**
     * 
     * @type {string}
     * @memberof OrganizationUpdate
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUpdate
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationUpdate
     */
    'send_data_to_snowflake': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationUpdate
     */
    'use_cortex_forecast_model': boolean;
}
/**
 * 
 * @export
 * @interface OrganizationUsersInner
 */
export interface OrganizationUsersInner {
    /**
     * 
     * @type {string}
     * @memberof OrganizationUsersInner
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUsersInner
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUsersInner
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUsersInner
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUsersInner
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUsersInner
     */
    'last_name'?: string;
    /**
     * Designates whether the user can log into this admin site.
     * @type {boolean}
     * @memberof OrganizationUsersInner
     */
    'is_staff'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof OrganizationUsersInner
     */
    'role'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUsersInner
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUsersInner
     */
    'phone_number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUsersInner
     */
    'company_name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationUsersInner
     */
    'terms_and_conditions_accepted': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationUsersInner
     */
    'privacy_policy_accepted': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationUsersInner
     */
    'cookie_policy_accepted': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationUsersInner
     */
    'is_first_time_user'?: boolean;
}
/**
 * 
 * @export
 * @interface OrganizationsListReadOnly
 */
export interface OrganizationsListReadOnly {
    /**
     * 
     * @type {string}
     * @memberof OrganizationsListReadOnly
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationsListReadOnly
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface PublicAPINeuralCanaryDailyForecast
 */
export interface PublicAPINeuralCanaryDailyForecast {
    /**
     * 
     * @type {string}
     * @memberof PublicAPINeuralCanaryDailyForecast
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicAPINeuralCanaryDailyForecast
     */
    'for_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicAPINeuralCanaryDailyForecast
     */
    'day_of_week'?: string;
    /**
     * 
     * @type {number}
     * @memberof PublicAPINeuralCanaryDailyForecast
     */
    'percentage_chance_of_incident'?: number;
    /**
     * 
     * @type {number}
     * @memberof PublicAPINeuralCanaryDailyForecast
     */
    'latency_issues_predicted'?: number;
    /**
     * 
     * @type {string}
     * @memberof PublicAPINeuralCanaryDailyForecast
     */
    'service_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicAPINeuralCanaryDailyForecast
     */
    'canary_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicAPINeuralCanaryDailyForecast
     */
    'canary'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PublicAPINeuralCanaryDailyForecast
     */
    'has_prediction'?: boolean;
}
/**
 * 
 * @export
 * @interface ServiceComplianceColors
 */
export interface ServiceComplianceColors {
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceColors
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceColors
     */
    'organization_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceColors
     */
    'bar_meeting_sla'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceColors
     */
    'bar_missing_sla_with_less_50_period_completed'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceColors
     */
    'bar_missing_sla_with_more_50_period_completed'?: string;
}
/**
 * 
 * @export
 * @interface ServiceComplianceHistory
 */
export interface ServiceComplianceHistory {
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceHistory
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceHistory
     */
    'organization'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceHistory
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceHistory
     */
    'service'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceHistory
     */
    'service_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceHistory
     */
    'provider_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceHistory
     */
    'provider_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceHistory
     */
    'organization_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceHistory
     */
    'organization_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceHistory
     */
    'period_category'?: ServiceComplianceHistoryPeriodCategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceHistory
     */
    'period_from'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceHistory
     */
    'period_to'?: string;
    /**
     * 
     * @type {number}
     * @memberof ServiceComplianceHistory
     */
    'downtime_seconds_calculated'?: number;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceHistory
     */
    'downtime_seconds_allowed'?: string;
    /**
     * 
     * @type {number}
     * @memberof ServiceComplianceHistory
     */
    'downtime_seconds_used_percentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceComplianceHistory
     */
    'mean_time_between_system_incidents_seconds_calculated'?: number;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceHistory
     */
    'mean_time_between_system_incidents_seconds_allowed'?: string;
    /**
     * 
     * @type {number}
     * @memberof ServiceComplianceHistory
     */
    'mean_time_between_system_incidents_seconds_used_percentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceComplianceHistory
     */
    'mean_time_recovery_system_seconds_calculated'?: number;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceHistory
     */
    'mean_time_recovery_system_seconds_allowed'?: string;
    /**
     * 
     * @type {number}
     * @memberof ServiceComplianceHistory
     */
    'mean_time_recovery_system_seconds_used_percentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceComplianceHistory
     */
    'sla_target_goal': number;
    /**
     * 
     * @type {number}
     * @memberof ServiceComplianceHistory
     */
    'canary_coverage_percentage'?: number;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceHistory
     */
    'current_period_days'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceHistory
     */
    'days_remaining_current'?: string;
    /**
     * 
     * @type {number}
     * @memberof ServiceComplianceHistory
     */
    'total_incidents'?: number;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceHistory
     */
    'service_start_date'?: string;
    /**
     * 
     * @type {number}
     * @memberof ServiceComplianceHistory
     */
    'sla_seconds_percentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceComplianceHistory
     */
    'total_canaries_during_period': number;
}

export const ServiceComplianceHistoryPeriodCategoryEnum = {
    Yearly: 'yearly',
    Quarterly: 'quarterly',
    Monthly: 'monthly',
    Weekly: 'weekly'
} as const;

export type ServiceComplianceHistoryPeriodCategoryEnum = typeof ServiceComplianceHistoryPeriodCategoryEnum[keyof typeof ServiceComplianceHistoryPeriodCategoryEnum];

/**
 * 
 * @export
 * @interface ServiceComplianceSummary
 */
export interface ServiceComplianceSummary {
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceSummary
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceSummary
     */
    'organization'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceSummary
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceSummary
     */
    'service'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceSummary
     */
    'service_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceSummary
     */
    'provider_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceSummary
     */
    'provider_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceSummary
     */
    'organization_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceSummary
     */
    'organization_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceSummary
     */
    'period_category'?: ServiceComplianceSummaryPeriodCategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceSummary
     */
    'period_from'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceSummary
     */
    'period_to'?: string;
    /**
     * 
     * @type {number}
     * @memberof ServiceComplianceSummary
     */
    'downtime_seconds_calculated'?: number;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceSummary
     */
    'downtime_seconds_allowed'?: string;
    /**
     * 
     * @type {number}
     * @memberof ServiceComplianceSummary
     */
    'downtime_seconds_used_percentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceComplianceSummary
     */
    'mean_time_between_system_incidents_seconds_calculated'?: number;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceSummary
     */
    'mean_time_between_system_incidents_seconds_allowed'?: string;
    /**
     * 
     * @type {number}
     * @memberof ServiceComplianceSummary
     */
    'mean_time_between_system_incidents_seconds_used_percentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceComplianceSummary
     */
    'mean_time_recovery_system_seconds_calculated'?: number;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceSummary
     */
    'mean_time_recovery_system_seconds_allowed'?: string;
    /**
     * 
     * @type {number}
     * @memberof ServiceComplianceSummary
     */
    'mean_time_recovery_system_seconds_used_percentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceComplianceSummary
     */
    'sla_target_goal': number;
    /**
     * 
     * @type {number}
     * @memberof ServiceComplianceSummary
     */
    'canary_coverage_percentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceComplianceSummary
     */
    'current_period_days': number;
    /**
     * 
     * @type {number}
     * @memberof ServiceComplianceSummary
     */
    'days_remaining_current': number;
    /**
     * 
     * @type {number}
     * @memberof ServiceComplianceSummary
     */
    'total_incidents'?: number;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceSummary
     */
    'service_start_date'?: string;
    /**
     * 
     * @type {number}
     * @memberof ServiceComplianceSummary
     */
    'sla_seconds_percentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceComplianceSummary
     */
    'total_canaries_during_period': number;
}

export const ServiceComplianceSummaryPeriodCategoryEnum = {
    Yearly: 'yearly',
    Quarterly: 'quarterly',
    Monthly: 'monthly',
    Weekly: 'weekly'
} as const;

export type ServiceComplianceSummaryPeriodCategoryEnum = typeof ServiceComplianceSummaryPeriodCategoryEnum[keyof typeof ServiceComplianceSummaryPeriodCategoryEnum];

/**
 * 
 * @export
 * @interface ServiceComplianceSummaryListReadOnly
 */
export interface ServiceComplianceSummaryListReadOnly {
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceSummaryListReadOnly
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceSummaryListReadOnly
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceSummaryListReadOnly
     */
    'service_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceSummaryListReadOnly
     */
    'service_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceSummaryListReadOnly
     */
    'organization_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceSummaryListReadOnly
     */
    'organization_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceSummaryListReadOnly
     */
    'period_category'?: ServiceComplianceSummaryListReadOnlyPeriodCategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceSummaryListReadOnly
     */
    'period_from'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceSummaryListReadOnly
     */
    'period_to'?: string;
}

export const ServiceComplianceSummaryListReadOnlyPeriodCategoryEnum = {
    Yearly: 'yearly',
    Quarterly: 'quarterly',
    Monthly: 'monthly',
    Weekly: 'weekly'
} as const;

export type ServiceComplianceSummaryListReadOnlyPeriodCategoryEnum = typeof ServiceComplianceSummaryListReadOnlyPeriodCategoryEnum[keyof typeof ServiceComplianceSummaryListReadOnlyPeriodCategoryEnum];

/**
 * 
 * @export
 * @interface ServiceComplianceSummaryReadOnly
 */
export interface ServiceComplianceSummaryReadOnly {
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceSummaryReadOnly
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceSummaryReadOnly
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceSummaryReadOnly
     */
    'service_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceSummaryReadOnly
     */
    'service_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceSummaryReadOnly
     */
    'organization_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceSummaryReadOnly
     */
    'organization_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceSummaryReadOnly
     */
    'period_category'?: ServiceComplianceSummaryReadOnlyPeriodCategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceSummaryReadOnly
     */
    'period_from'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceComplianceSummaryReadOnly
     */
    'period_to'?: string;
    /**
     * 
     * @type {number}
     * @memberof ServiceComplianceSummaryReadOnly
     */
    'sla_target_goal': number;
    /**
     * 
     * @type {number}
     * @memberof ServiceComplianceSummaryReadOnly
     */
    'sla_seconds_percentage'?: number;
}

export const ServiceComplianceSummaryReadOnlyPeriodCategoryEnum = {
    Yearly: 'yearly',
    Quarterly: 'quarterly',
    Monthly: 'monthly',
    Weekly: 'weekly'
} as const;

export type ServiceComplianceSummaryReadOnlyPeriodCategoryEnum = typeof ServiceComplianceSummaryReadOnlyPeriodCategoryEnum[keyof typeof ServiceComplianceSummaryReadOnlyPeriodCategoryEnum];

/**
 * 
 * @export
 * @interface ServiceDailyForecast
 */
export interface ServiceDailyForecast {
    /**
     * 
     * @type {string}
     * @memberof ServiceDailyForecast
     */
    'organization': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceDailyForecast
     */
    'for_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceDailyForecast
     */
    'day_of_week'?: string;
    /**
     * 
     * @type {number}
     * @memberof ServiceDailyForecast
     */
    'percentage_chance_of_incident'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceDailyForecast
     */
    'latency_issues_predicted'?: number;
    /**
     * 
     * @type {string}
     * @memberof ServiceDailyForecast
     */
    'service_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceDailyForecast
     */
    'service_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceDailyForecast
     */
    'provider_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceDailyForecast
     */
    'provider_name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceDailyForecast
     */
    'has_prediction'?: boolean;
}
/**
 * 
 * @export
 * @interface ServiceHealth
 */
export interface ServiceHealth {
    /**
     * 
     * @type {string}
     * @memberof ServiceHealth
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceHealth
     */
    'provider_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceHealth
     */
    'provider_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceHealth
     */
    'service_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceHealth
     */
    'service_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceHealth
     */
    'organization_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceHealth
     */
    'organization_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceHealth
     */
    'healthy': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceHealth
     */
    'warning': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceHealth
     */
    'unhealthy': string;
    /**
     * 
     * @type {number}
     * @memberof ServiceHealth
     */
    'total_runs'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceHealth
     */
    'canaries_total'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceHealth
     */
    'canaries_with_alarms_alarming'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceHealth
     */
    'canaries_erroring_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceHealth
     */
    'open_tickets_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceHealth
     */
    'total_active_incidents_in_current_period_count'?: number;
    /**
     * 
     * @type {string}
     * @memberof ServiceHealth
     */
    'incidents_level_in_current_period'?: string;
}
/**
 * 
 * @export
 * @interface ServiceHealthColors
 */
export interface ServiceHealthColors {
    /**
     * 
     * @type {string}
     * @memberof ServiceHealthColors
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceHealthColors
     */
    'organization_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceHealthColors
     */
    'severity_bar_high_color'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceHealthColors
     */
    'severity_bar_medium_color'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceHealthColors
     */
    'severity_bar_low_color'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceHealthColors
     */
    'severity_bar_none_color'?: string;
    /**
     * This is the indicator color for Running Canaries with no Alarms.
     * @type {string}
     * @memberof ServiceHealthColors
     */
    'alert_dial_color_running_canaries_no_alarms'?: string;
    /**
     * This is the indicator color for Running Canaries with Alarms.
     * @type {string}
     * @memberof ServiceHealthColors
     */
    'alert_dial_color_running_canaries_with_alarms'?: string;
    /**
     * This is the indicator color for Stopped Canaries.
     * @type {string}
     * @memberof ServiceHealthColors
     */
    'alert_dial_color_stopped_canaries'?: string;
    /**
     * This is the indicator color for Erroring Canaries.
     * @type {string}
     * @memberof ServiceHealthColors
     */
    'alert_dial_color_erroring_canaries'?: string;
    /**
     * This is the indicator color for New Canaries.
     * @type {string}
     * @memberof ServiceHealthColors
     */
    'alert_dial_color_new_canaries'?: string;
}
/**
 * 
 * @export
 * @interface ServiceHealthEvaluationSettingsGroup
 */
export interface ServiceHealthEvaluationSettingsGroup {
    /**
     * 
     * @type {string}
     * @memberof ServiceHealthEvaluationSettingsGroup
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceHealthEvaluationSettingsGroup
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceHealthEvaluationSettingsGroup
     */
    'created'?: string;
    /**
     * 
     * @type {number}
     * @memberof ServiceHealthEvaluationSettingsGroup
     */
    'concurrent_alarms_alarming_to_start_incident'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceHealthEvaluationSettingsGroup
     */
    'concurrent_alarms_healthy_to_stop_incident'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceHealthEvaluationSettingsGroup
     */
    'coverage_denominator'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceHealthEvaluationSettingsGroup
     */
    'downtime_seconds_allowed'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceHealthEvaluationSettingsGroup
     */
    'events_per_high_impact'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceHealthEvaluationSettingsGroup
     */
    'events_per_medium_impact'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceHealthEvaluationSettingsGroup
     */
    'events_per_low_impact'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceHealthEvaluationSettingsGroup
     */
    'events_per_high_urgency'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceHealthEvaluationSettingsGroup
     */
    'events_per_medium_urgency'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceHealthEvaluationSettingsGroup
     */
    'events_per_low_urgency'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceHealthEvaluationSettingsGroup
     */
    'is_default'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ServiceHealthEvaluationSettingsGroup
     */
    'period_category'?: ServiceHealthEvaluationSettingsGroupPeriodCategoryEnum;
    /**
     * 
     * @type {number}
     * @memberof ServiceHealthEvaluationSettingsGroup
     */
    'mean_time_between_system_incidents_seconds_allowed'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceHealthEvaluationSettingsGroup
     */
    'mean_time_recovery_system_seconds_allowed'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceHealthEvaluationSettingsGroup
     */
    'sla_target_goal': number;
    /**
     * 
     * @type {string}
     * @memberof ServiceHealthEvaluationSettingsGroup
     */
    'organization': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceHealthEvaluationSettingsGroup
     */
    'service': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceHealthEvaluationSettingsGroup
     */
    'canaries': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ServiceHealthEvaluationSettingsGroup
     */
    'provider_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceHealthEvaluationSettingsGroup
     */
    'provider_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceHealthEvaluationSettingsGroup
     */
    'service_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceHealthEvaluationSettingsGroup
     */
    'organization_name'?: string;
}

export const ServiceHealthEvaluationSettingsGroupPeriodCategoryEnum = {
    Yearly: 'yearly',
    Quarterly: 'quarterly',
    Monthly: 'monthly',
    Weekly: 'weekly'
} as const;

export type ServiceHealthEvaluationSettingsGroupPeriodCategoryEnum = typeof ServiceHealthEvaluationSettingsGroupPeriodCategoryEnum[keyof typeof ServiceHealthEvaluationSettingsGroupPeriodCategoryEnum];

/**
 * 
 * @export
 * @interface ServiceHealthIncident
 */
export interface ServiceHealthIncident {
    /**
     * 
     * @type {string}
     * @memberof ServiceHealthIncident
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceHealthIncident
     */
    'service_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceHealthIncident
     */
    'service_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceHealthIncident
     */
    'provider_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceHealthIncident
     */
    'settings_group_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceHealthIncident
     */
    'settings_group_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceHealthIncident
     */
    'start'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceHealthIncident
     */
    'stop'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ServiceHealthIncident
     */
    'downtime'?: number;
    /**
     * 
     * @type {string}
     * @memberof ServiceHealthIncident
     */
    'incident_status'?: ServiceHealthIncidentIncidentStatusEnum | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceHealthIncident
     */
    'impact'?: ServiceHealthIncidentImpactEnum;
    /**
     * 
     * @type {string}
     * @memberof ServiceHealthIncident
     */
    'urgency'?: ServiceHealthIncidentUrgencyEnum;
}

export const ServiceHealthIncidentIncidentStatusEnum = {
    Ok: 'OK',
    Started: 'STARTED',
    ResolvedByUser: 'RESOLVED_BY_USER',
    Resolved: 'RESOLVED'
} as const;

export type ServiceHealthIncidentIncidentStatusEnum = typeof ServiceHealthIncidentIncidentStatusEnum[keyof typeof ServiceHealthIncidentIncidentStatusEnum];
export const ServiceHealthIncidentImpactEnum = {
    Ok: 'OK',
    High: 'High',
    Medium: 'Medium',
    Low: 'Low'
} as const;

export type ServiceHealthIncidentImpactEnum = typeof ServiceHealthIncidentImpactEnum[keyof typeof ServiceHealthIncidentImpactEnum];
export const ServiceHealthIncidentUrgencyEnum = {
    Ok: 'OK',
    High: 'High',
    Medium: 'Medium',
    Low: 'Low'
} as const;

export type ServiceHealthIncidentUrgencyEnum = typeof ServiceHealthIncidentUrgencyEnum[keyof typeof ServiceHealthIncidentUrgencyEnum];

/**
 * 
 * @export
 * @interface ServiceHealthStatusList
 */
export interface ServiceHealthStatusList {
    /**
     * 
     * @type {string}
     * @memberof ServiceHealthStatusList
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceHealthStatusList
     */
    'service_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceHealthStatusList
     */
    'service_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceHealthStatusList
     */
    'organization_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceHealthStatusList
     */
    'organization_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceHealthStatusList
     */
    'healthy': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceHealthStatusList
     */
    'warning': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceHealthStatusList
     */
    'unhealthy': string;
}
/**
 * 
 * @export
 * @interface ServiceHealthStatusRetrieve
 */
export interface ServiceHealthStatusRetrieve {
    /**
     * 
     * @type {string}
     * @memberof ServiceHealthStatusRetrieve
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceHealthStatusRetrieve
     */
    'service_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceHealthStatusRetrieve
     */
    'service_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceHealthStatusRetrieve
     */
    'organization_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceHealthStatusRetrieve
     */
    'organization_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceHealthStatusRetrieve
     */
    'healthy': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceHealthStatusRetrieve
     */
    'warning': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceHealthStatusRetrieve
     */
    'unhealthy': string;
    /**
     * 
     * @type {number}
     * @memberof ServiceHealthStatusRetrieve
     */
    'total_runs'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceHealthStatusRetrieve
     */
    'canaries_total'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceHealthStatusRetrieve
     */
    'canaries_with_alarms_alarming'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceHealthStatusRetrieve
     */
    'canaries_erroring_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceHealthStatusRetrieve
     */
    'total_active_incidents_in_current_period_count'?: number;
    /**
     * 
     * @type {string}
     * @memberof ServiceHealthStatusRetrieve
     */
    'incidents_level_in_current_period'?: string;
}
/**
 * 
 * @export
 * @interface SimpleAlarm
 */
export interface SimpleAlarm {
    /**
     * 
     * @type {string}
     * @memberof SimpleAlarm
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleAlarm
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SimpleAlarm
     */
    'metric_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleAlarm
     */
    'boundary_condition'?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleAlarm
     */
    'canary_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleAlarm
     */
    'task_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleAlarm
     */
    'latest_status'?: SimpleAlarmLatestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof SimpleAlarm
     */
    'comparison_operator'?: SimpleAlarmComparisonOperatorEnum;
    /**
     * 
     * @type {string}
     * @memberof SimpleAlarm
     */
    'comparison_true_status'?: SimpleAlarmComparisonTrueStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof SimpleAlarm
     */
    'no_data_status'?: SimpleAlarmNoDataStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof SimpleAlarm
     */
    'period'?: number;
    /**
     * 
     * @type {number}
     * @memberof SimpleAlarm
     */
    'data_points_to_trigger'?: number;
    /**
     * 
     * @type {string}
     * @memberof SimpleAlarm
     */
    'alarm_type'?: SimpleAlarmAlarmTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof SimpleAlarm
     */
    'notifications_count'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SimpleAlarm
     */
    'included_as_downtime_alarm'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SimpleAlarm
     */
    'enabled'?: boolean;
}

export const SimpleAlarmLatestStatusEnum = {
    NoData: 'NO_DATA',
    Alarming: 'ALARMING',
    Ok: 'OK'
} as const;

export type SimpleAlarmLatestStatusEnum = typeof SimpleAlarmLatestStatusEnum[keyof typeof SimpleAlarmLatestStatusEnum];
export const SimpleAlarmComparisonOperatorEnum = {
    EqualTo: 'EqualTo',
    NotEqualTo: 'NotEqualTo',
    LessThanMinThreshold: 'LessThanMinThreshold',
    LessThanOrEqualToMinThreshold: 'LessThanOrEqualToMinThreshold',
    GreaterThanMinThreshold: 'GreaterThanMinThreshold',
    GreaterThanOrEqualToMinThreshold: 'GreaterThanOrEqualToMinThreshold',
    LessThanMaxThreshold: 'LessThanMaxThreshold',
    LessThanOrEqualToMaxThreshold: 'LessThanOrEqualToMaxThreshold',
    GreaterThanMaxThreshold: 'GreaterThanMaxThreshold',
    GreaterThanOrEqualToMaxThreshold: 'GreaterThanOrEqualToMaxThreshold',
    LessThanMinThresholdOrGreaterThanMaxThreshold: 'LessThanMinThreshold_OR_GreaterThanMaxThreshold',
    LessThanMinThresholdOrGreaterThanOrEqualToMaxThreshold: 'LessThanMinThreshold_OR_GreaterThanOrEqualToMaxThreshold',
    LessThanOrEqualToMinThresholdOrGreaterThanMaxThreshold: 'LessThanOrEqualToMinThreshold_OR_GreaterThanMaxThreshold',
    LessThanOrEqualToMinThresholdOrGreaterThanOrEqualToMaxThreshold: 'LessThanOrEqualToMinThreshold_OR_GreaterThanOrEqualToMaxThreshold',
    GreaterThanMinThresholdAndLessThanMaxThreshold: 'GreaterThanMinThreshold_AND_LessThanMaxThreshold',
    GreaterThanOrEqualToMinThresholdAndLessThanMaxThreshold: 'GreaterThanOrEqualToMinThreshold_AND_LessThanMaxThreshold',
    GreaterThanMinThresholdAndLessThanOrEqualToMaxThreshold: 'GreaterThanMinThreshold_AND_LessThanOrEqualToMaxThreshold',
    GreaterThanOrEqualToMinThresholdAndLessThanOrEqualToMaxThreshold: 'GreaterThanOrEqualToMinThreshold_AND_LessThanOrEqualToMaxThreshold',
    StringContainsCaseSensitive: 'StringContainsCaseSensitive',
    StringContainsNotCaseSensitive: 'StringContainsNotCaseSensitive',
    ParsableJson: 'ParsableJSON'
} as const;

export type SimpleAlarmComparisonOperatorEnum = typeof SimpleAlarmComparisonOperatorEnum[keyof typeof SimpleAlarmComparisonOperatorEnum];
export const SimpleAlarmComparisonTrueStatusEnum = {
    NoData: 'NO_DATA',
    Alarming: 'ALARMING',
    Ok: 'OK'
} as const;

export type SimpleAlarmComparisonTrueStatusEnum = typeof SimpleAlarmComparisonTrueStatusEnum[keyof typeof SimpleAlarmComparisonTrueStatusEnum];
export const SimpleAlarmNoDataStatusEnum = {
    NoData: 'NO_DATA',
    Alarming: 'ALARMING',
    Ok: 'OK'
} as const;

export type SimpleAlarmNoDataStatusEnum = typeof SimpleAlarmNoDataStatusEnum[keyof typeof SimpleAlarmNoDataStatusEnum];
export const SimpleAlarmAlarmTypeEnum = {
    SimpleAlarm: 'SimpleAlarm',
    CanaryTaskLatency: 'CanaryTaskLatency',
    CanaryTaskErrorObtainingResponse: 'CanaryTaskErrorObtainingResponse',
    CanaryTaskErrorParsingResponse: 'CanaryTaskErrorParsingResponse',
    CanaryTaskResponseValue: 'CanaryTaskResponseValue',
    CanaryHealth: 'CanaryHealth',
    CanaryDowntimeGeneratingFailure: 'CanaryDowntimeGeneratingFailure',
    CanaryTaskForecastedResponseValue: 'CanaryTaskForecastedResponseValue',
    CanaryTaskForecastedLatency: 'CanaryTaskForecastedLatency'
} as const;

export type SimpleAlarmAlarmTypeEnum = typeof SimpleAlarmAlarmTypeEnum[keyof typeof SimpleAlarmAlarmTypeEnum];

/**
 * 
 * @export
 * @interface SimpleAlarmEvaluation
 */
export interface SimpleAlarmEvaluation {
    /**
     * 
     * @type {string}
     * @memberof SimpleAlarmEvaluation
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleAlarmEvaluation
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleAlarmEvaluation
     */
    'alarm'?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleAlarmEvaluation
     */
    'period_start'?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleAlarmEvaluation
     */
    'period_stop'?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleAlarmEvaluation
     */
    'status'?: SimpleAlarmEvaluationStatusEnum;
}

export const SimpleAlarmEvaluationStatusEnum = {
    NoData: 'NO_DATA',
    Alarming: 'ALARMING',
    Ok: 'OK'
} as const;

export type SimpleAlarmEvaluationStatusEnum = typeof SimpleAlarmEvaluationStatusEnum[keyof typeof SimpleAlarmEvaluationStatusEnum];

/**
 * 
 * @export
 * @interface SimpleAlarmHistory
 */
export interface SimpleAlarmHistory {
    /**
     * 
     * @type {string}
     * @memberof SimpleAlarmHistory
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleAlarmHistory
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleAlarmHistory
     */
    'alarm'?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleAlarmHistory
     */
    'status_from'?: SimpleAlarmHistoryStatusFromEnum;
    /**
     * 
     * @type {string}
     * @memberof SimpleAlarmHistory
     */
    'status_to'?: SimpleAlarmHistoryStatusToEnum;
    /**
     * 
     * @type {string}
     * @memberof SimpleAlarmHistory
     */
    'evaluation'?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleAlarmHistory
     */
    'evaluation_period_start'?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleAlarmHistory
     */
    'evaluation_period_stop'?: string;
}

export const SimpleAlarmHistoryStatusFromEnum = {
    NoData: 'NO_DATA',
    Alarming: 'ALARMING',
    Ok: 'OK'
} as const;

export type SimpleAlarmHistoryStatusFromEnum = typeof SimpleAlarmHistoryStatusFromEnum[keyof typeof SimpleAlarmHistoryStatusFromEnum];
export const SimpleAlarmHistoryStatusToEnum = {
    NoData: 'NO_DATA',
    Alarming: 'ALARMING',
    Ok: 'OK'
} as const;

export type SimpleAlarmHistoryStatusToEnum = typeof SimpleAlarmHistoryStatusToEnum[keyof typeof SimpleAlarmHistoryStatusToEnum];

/**
 * 
 * @export
 * @interface Snowflake
 */
export interface Snowflake {
    /**
     * 
     * @type {string}
     * @memberof Snowflake
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Snowflake
     */
    'organization': string;
    /**
     * 
     * @type {string}
     * @memberof Snowflake
     */
    'organization_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Snowflake
     */
    'organization_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Snowflake
     */
    'related_k8s_secret_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Snowflake
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof Snowflake
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof Snowflake
     */
    'account_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Snowflake
     */
    'warehouse'?: string;
    /**
     * 
     * @type {string}
     * @memberof Snowflake
     */
    'database'?: string;
    /**
     * 
     * @type {string}
     * @memberof Snowflake
     */
    'schema'?: string;
}
/**
 * 
 * @export
 * @interface SnowflakeCanaryDailyForecast
 */
export interface SnowflakeCanaryDailyForecast {
    /**
     * 
     * @type {string}
     * @memberof SnowflakeCanaryDailyForecast
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SnowflakeCanaryDailyForecast
     */
    'for_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof SnowflakeCanaryDailyForecast
     */
    'day_of_week'?: string;
    /**
     * 
     * @type {number}
     * @memberof SnowflakeCanaryDailyForecast
     */
    'percentage_chance_of_incident'?: number;
    /**
     * 
     * @type {number}
     * @memberof SnowflakeCanaryDailyForecast
     */
    'latency_issues_predicted'?: number;
    /**
     * 
     * @type {number}
     * @memberof SnowflakeCanaryDailyForecast
     */
    'forecasted_response_value'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SnowflakeCanaryDailyForecast
     */
    'forecasted_response_latency'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SnowflakeCanaryDailyForecast
     */
    'service_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SnowflakeCanaryDailyForecast
     */
    'service_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SnowflakeCanaryDailyForecast
     */
    'provider_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SnowflakeCanaryDailyForecast
     */
    'provider_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SnowflakeCanaryDailyForecast
     */
    'canary_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SnowflakeCanaryDailyForecast
     */
    'canary'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SnowflakeCanaryDailyForecast
     */
    'has_prediction'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SnowflakeCanaryDailyForecast
     */
    'task_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SnowflakeCanaryDailyForecast
     */
    'template_canary_name'?: string;
}
/**
 * 
 * @export
 * @interface SnowflakeCanaryDailyForecastData
 */
export interface SnowflakeCanaryDailyForecastData {
    /**
     * 
     * @type {string}
     * @memberof SnowflakeCanaryDailyForecastData
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SnowflakeCanaryDailyForecastData
     */
    'for_timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof SnowflakeCanaryDailyForecastData
     */
    'day_of_week'?: string;
    /**
     * 
     * @type {number}
     * @memberof SnowflakeCanaryDailyForecastData
     */
    'percentage_chance_of_incident'?: number;
    /**
     * 
     * @type {number}
     * @memberof SnowflakeCanaryDailyForecastData
     */
    'latency_issues_predicted'?: number;
    /**
     * 
     * @type {number}
     * @memberof SnowflakeCanaryDailyForecastData
     */
    'forecasted_response_value'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SnowflakeCanaryDailyForecastData
     */
    'forecasted_response_latency'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SnowflakeCanaryDailyForecastData
     */
    'service_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SnowflakeCanaryDailyForecastData
     */
    'service_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SnowflakeCanaryDailyForecastData
     */
    'provider_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SnowflakeCanaryDailyForecastData
     */
    'provider_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SnowflakeCanaryDailyForecastData
     */
    'canary_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SnowflakeCanaryDailyForecastData
     */
    'canary'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SnowflakeCanaryDailyForecastData
     */
    'has_prediction'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SnowflakeCanaryDailyForecastData
     */
    'task_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SnowflakeCanaryDailyForecastData
     */
    'template_canary_name'?: string;
}
/**
 * 
 * @export
 * @interface SnowflakeCompositeDailyForecast
 */
export interface SnowflakeCompositeDailyForecast {
    /**
     * 
     * @type {string}
     * @memberof SnowflakeCompositeDailyForecast
     */
    'organization'?: string;
    /**
     * 
     * @type {string}
     * @memberof SnowflakeCompositeDailyForecast
     */
    'for_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof SnowflakeCompositeDailyForecast
     */
    'day_of_week'?: string;
    /**
     * 
     * @type {number}
     * @memberof SnowflakeCompositeDailyForecast
     */
    'percentage_chance_of_incident'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SnowflakeCompositeDailyForecast
     */
    'has_prediction'?: boolean;
}
/**
 * 
 * @export
 * @interface SnowflakeForecastModel
 */
export interface SnowflakeForecastModel {
    /**
     * 
     * @type {string}
     * @memberof SnowflakeForecastModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SnowflakeForecastModel
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SnowflakeForecastModel
     */
    'status'?: SnowflakeForecastModelStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof SnowflakeForecastModel
     */
    'created'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SnowflakeForecastModel
     */
    'fine_tuned'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SnowflakeForecastModel
     */
    'is_selected'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SnowflakeForecastModel
     */
    'data_start_date': string;
    /**
     * 
     * @type {string}
     * @memberof SnowflakeForecastModel
     */
    'data_end_date': string;
    /**
     * 
     * @type {number}
     * @memberof SnowflakeForecastModel
     */
    'estimated_model_build_time'?: number;
    /**
     * 
     * @type {number}
     * @memberof SnowflakeForecastModel
     */
    'estimated_model_availability'?: number;
    /**
     * 
     * @type {string}
     * @memberof SnowflakeForecastModel
     */
    'organization_id'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SnowflakeForecastModel
     */
    'excluded_canaries'?: Array<string>;
}

export const SnowflakeForecastModelStatusEnum = {
    Default: 'DEFAULT',
    Available: 'AVAILABLE',
    Active: 'ACTIVE',
    Building: 'BUILDING'
} as const;

export type SnowflakeForecastModelStatusEnum = typeof SnowflakeForecastModelStatusEnum[keyof typeof SnowflakeForecastModelStatusEnum];

/**
 * 
 * @export
 * @interface SnowflakeServiceDailyForecast
 */
export interface SnowflakeServiceDailyForecast {
    /**
     * 
     * @type {string}
     * @memberof SnowflakeServiceDailyForecast
     */
    'organization': string;
    /**
     * 
     * @type {string}
     * @memberof SnowflakeServiceDailyForecast
     */
    'for_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof SnowflakeServiceDailyForecast
     */
    'day_of_week'?: string;
    /**
     * 
     * @type {number}
     * @memberof SnowflakeServiceDailyForecast
     */
    'percentage_chance_of_incident'?: number;
    /**
     * 
     * @type {number}
     * @memberof SnowflakeServiceDailyForecast
     */
    'latency_issues_predicted'?: number;
    /**
     * 
     * @type {string}
     * @memberof SnowflakeServiceDailyForecast
     */
    'service_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SnowflakeServiceDailyForecast
     */
    'service_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SnowflakeServiceDailyForecast
     */
    'provider_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SnowflakeServiceDailyForecast
     */
    'provider_name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SnowflakeServiceDailyForecast
     */
    'has_prediction'?: boolean;
}
/**
 * 
 * @export
 * @interface StoreCanary
 */
export interface StoreCanary {
    /**
     * 
     * @type {string}
     * @memberof StoreCanary
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreCanary
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreCanary
     */
    'download_link'?: string;
}
/**
 * 
 * @export
 * @interface StoreCanaryAutoLoadCanaryTemplateLibraryReadOnly
 */
export interface StoreCanaryAutoLoadCanaryTemplateLibraryReadOnly {
    /**
     * 
     * @type {string}
     * @memberof StoreCanaryAutoLoadCanaryTemplateLibraryReadOnly
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreCanaryAutoLoadCanaryTemplateLibraryReadOnly
     */
    'organization_id'?: string;
}
/**
 * 
 * @export
 * @interface StoreCanaryCloudReadOnly
 */
export interface StoreCanaryCloudReadOnly {
    /**
     * 
     * @type {string}
     * @memberof StoreCanaryCloudReadOnly
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreCanaryCloudReadOnly
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface StoreCanaryComponentReadOnly
 */
export interface StoreCanaryComponentReadOnly {
    /**
     * 
     * @type {string}
     * @memberof StoreCanaryComponentReadOnly
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreCanaryComponentReadOnly
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface StoreCanaryProductReadOnly
 */
export interface StoreCanaryProductReadOnly {
    /**
     * 
     * @type {string}
     * @memberof StoreCanaryProductReadOnly
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreCanaryProductReadOnly
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface StoreCanaryReadOnly
 */
export interface StoreCanaryReadOnly {
    /**
     * 
     * @type {string}
     * @memberof StoreCanaryReadOnly
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreCanaryReadOnly
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreCanaryReadOnly
     */
    'name'?: string;
    /**
     * After changing this to Approved, the file will be uploaded to the Github repo, otherwise it will not appear on the store.
     * @type {string}
     * @memberof StoreCanaryReadOnly
     */
    'status'?: StoreCanaryReadOnlyStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof StoreCanaryReadOnly
     */
    'uploaded_user'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreCanaryReadOnly
     */
    'company'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreCanaryReadOnly
     */
    'cost'?: StoreCanaryReadOnlyCostEnum;
    /**
     * 
     * @type {number}
     * @memberof StoreCanaryReadOnly
     */
    'price'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StoreCanaryReadOnly
     */
    'canary_templates_count'?: number;
    /**
     * 
     * @type {string}
     * @memberof StoreCanaryReadOnly
     */
    'version'?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreCanaryReadOnly
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreCanaryReadOnly
     */
    'cloud'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreCanaryReadOnly
     */
    'component'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreCanaryReadOnly
     */
    'provider_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreCanaryReadOnly
     */
    'service_name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StoreCanaryReadOnly
     */
    'is_pinned_canary'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StoreCanaryReadOnly
     */
    'is_private'?: boolean;
}

export const StoreCanaryReadOnlyStatusEnum = {
    InReview: 'In Review',
    Rejected: 'Rejected',
    Approved: 'Approved'
} as const;

export type StoreCanaryReadOnlyStatusEnum = typeof StoreCanaryReadOnlyStatusEnum[keyof typeof StoreCanaryReadOnlyStatusEnum];
export const StoreCanaryReadOnlyCostEnum = {
    Free: 'Free',
    Paid: 'PAID'
} as const;

export type StoreCanaryReadOnlyCostEnum = typeof StoreCanaryReadOnlyCostEnum[keyof typeof StoreCanaryReadOnlyCostEnum];

/**
 * 
 * @export
 * @interface StoreCanaryWrite
 */
export interface StoreCanaryWrite {
    /**
     * 
     * @type {string}
     * @memberof StoreCanaryWrite
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof StoreCanaryWrite
     */
    'company'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StoreCanaryWrite
     */
    'canary_templates_count': number;
    /**
     * 
     * @type {string}
     * @memberof StoreCanaryWrite
     */
    'version': string;
    /**
     * 
     * @type {string}
     * @memberof StoreCanaryWrite
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof StoreCanaryWrite
     */
    'cloud': string;
    /**
     * 
     * @type {string}
     * @memberof StoreCanaryWrite
     */
    'component': string;
    /**
     * 
     * @type {string}
     * @memberof StoreCanaryWrite
     */
    'provider_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreCanaryWrite
     */
    'service_name'?: string | null;
    /**
     * 
     * @type {File}
     * @memberof StoreCanaryWrite
     */
    'zip_file': File;
    /**
     * 
     * @type {boolean}
     * @memberof StoreCanaryWrite
     */
    'is_pinned_canary'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StoreCanaryWrite
     */
    'is_private'?: boolean;
}
/**
 * 
 * @export
 * @interface SubscriptionCancelRequest
 */
export interface SubscriptionCancelRequest {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionCancelRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionCancelRequest
     */
    'subscription': string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionCancelRequest
     */
    'user': string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionCancelRequest
     */
    'status'?: SubscriptionCancelRequestStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionCancelRequest
     */
    'is_processed'?: boolean;
}

export const SubscriptionCancelRequestStatusEnum = {
    Created: 'Created',
    Processing: 'Processing',
    Processed: 'Processed'
} as const;

export type SubscriptionCancelRequestStatusEnum = typeof SubscriptionCancelRequestStatusEnum[keyof typeof SubscriptionCancelRequestStatusEnum];

/**
 * 
 * @export
 * @interface TemplateCanaryReadOnlyListItem
 */
export interface TemplateCanaryReadOnlyListItem {
    /**
     * 
     * @type {string}
     * @memberof TemplateCanaryReadOnlyListItem
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateCanaryReadOnlyListItem
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface TemplateCanaryReadOnlyRetrieveItem
 */
export interface TemplateCanaryReadOnlyRetrieveItem {
    /**
     * 
     * @type {string}
     * @memberof TemplateCanaryReadOnlyRetrieveItem
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateCanaryReadOnlyRetrieveItem
     */
    'canary_template_library'?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateCanaryReadOnlyRetrieveItem
     */
    'name'?: string;
    /**
     * 
     * @type {Array<TemplateCanaryReadOnlyRetrieveItemNodesInner>}
     * @memberof TemplateCanaryReadOnlyRetrieveItem
     */
    'nodes': Array<TemplateCanaryReadOnlyRetrieveItemNodesInner>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TemplateCanaryReadOnlyRetrieveItem
     */
    'ordered_task_ids'?: Array<string>;
}
/**
 * 
 * @export
 * @interface TemplateCanaryReadOnlyRetrieveItemNodesInner
 */
export interface TemplateCanaryReadOnlyRetrieveItemNodesInner {
    /**
     * 
     * @type {string}
     * @memberof TemplateCanaryReadOnlyRetrieveItemNodesInner
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateCanaryReadOnlyRetrieveItemNodesInner
     */
    'canary_template_library'?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateCanaryReadOnlyRetrieveItemNodesInner
     */
    'template_canary'?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateCanaryReadOnlyRetrieveItemNodesInner
     */
    'template_task'?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateCanaryReadOnlyRetrieveItemNodesInner
     */
    'template_task_name'?: string;
}
/**
 * 
 * @export
 * @interface TemplateCanaryWritable
 */
export interface TemplateCanaryWritable {
    /**
     * 
     * @type {string}
     * @memberof TemplateCanaryWritable
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateCanaryWritable
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TemplateCanaryWritable
     */
    'canary_template_library': string;
    /**
     * 
     * @type {Array<TemplateCanaryWritableNodesInner>}
     * @memberof TemplateCanaryWritable
     */
    'nodes': Array<TemplateCanaryWritableNodesInner>;
    /**
     * 
     * @type {string}
     * @memberof TemplateCanaryWritable
     */
    'provider_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateCanaryWritable
     */
    'service_id'?: string;
}
/**
 * 
 * @export
 * @interface TemplateCanaryWritableNodesInner
 */
export interface TemplateCanaryWritableNodesInner {
    /**
     * 
     * @type {string}
     * @memberof TemplateCanaryWritableNodesInner
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateCanaryWritableNodesInner
     */
    'canary_template_library'?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateCanaryWritableNodesInner
     */
    'template_canary'?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateCanaryWritableNodesInner
     */
    'template_task': string;
    /**
     * 
     * @type {string}
     * @memberof TemplateCanaryWritableNodesInner
     */
    'template_task_name'?: string;
}
/**
 * 
 * @export
 * @interface TemplateTaskReadOnly
 */
export interface TemplateTaskReadOnly {
    /**
     * 
     * @type {string}
     * @memberof TemplateTaskReadOnly
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateTaskReadOnly
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateTaskReadOnly
     */
    'canary_template_library'?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateTaskReadOnly
     */
    'return_data_type'?: TemplateTaskReadOnlyReturnDataTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TemplateTaskReadOnly
     */
    'provider_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateTaskReadOnly
     */
    'service_id'?: string;
}

export const TemplateTaskReadOnlyReturnDataTypeEnum = {
    Boolean: 'boolean',
    String: 'string',
    Integer: 'integer',
    Decimal: 'decimal',
    Json: 'json'
} as const;

export type TemplateTaskReadOnlyReturnDataTypeEnum = typeof TemplateTaskReadOnlyReturnDataTypeEnum[keyof typeof TemplateTaskReadOnlyReturnDataTypeEnum];

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'last_name'?: string;
    /**
     * Designates whether the user can log into this admin site.
     * @type {boolean}
     * @memberof User
     */
    'is_staff'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'role'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'phone_number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'company_name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'terms_and_conditions_accepted': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'privacy_policy_accepted': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'cookie_policy_accepted': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'is_first_time_user'?: boolean;
}
/**
 * 
 * @export
 * @interface UserGatedContentDownloadRequest
 */
export interface UserGatedContentDownloadRequest {
    /**
     * 
     * @type {string}
     * @memberof UserGatedContentDownloadRequest
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof UserGatedContentDownloadRequest
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof UserGatedContentDownloadRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserGatedContentDownloadRequest
     */
    'company_website': string;
    /**
     * 
     * @type {string}
     * @memberof UserGatedContentDownloadRequest
     */
    'job_title': string;
    /**
     * 
     * @type {string}
     * @memberof UserGatedContentDownloadRequest
     */
    'country': UserGatedContentDownloadRequestCountryEnum;
    /**
     * 
     * @type {string}
     * @memberof UserGatedContentDownloadRequest
     */
    'countries'?: UserGatedContentDownloadRequestCountriesEnum;
}

export const UserGatedContentDownloadRequestCountryEnum = {
    Af: 'AF',
    Ax: 'AX',
    Al: 'AL',
    Dz: 'DZ',
    As: 'AS',
    Ad: 'AD',
    Ao: 'AO',
    Ai: 'AI',
    Aq: 'AQ',
    Ag: 'AG',
    Ar: 'AR',
    Am: 'AM',
    Aw: 'AW',
    Au: 'AU',
    At: 'AT',
    Az: 'AZ',
    Bs: 'BS',
    Bh: 'BH',
    Bd: 'BD',
    Bb: 'BB',
    By: 'BY',
    Be: 'BE',
    Bz: 'BZ',
    Bj: 'BJ',
    Bm: 'BM',
    Bt: 'BT',
    Bo: 'BO',
    Bq: 'BQ',
    Ba: 'BA',
    Bw: 'BW',
    Bv: 'BV',
    Br: 'BR',
    Io: 'IO',
    Bn: 'BN',
    Bg: 'BG',
    Bf: 'BF',
    Bi: 'BI',
    Cv: 'CV',
    Kh: 'KH',
    Cm: 'CM',
    Ca: 'CA',
    Ky: 'KY',
    Cf: 'CF',
    Td: 'TD',
    Cl: 'CL',
    Cn: 'CN',
    Cx: 'CX',
    Cc: 'CC',
    Co: 'CO',
    Km: 'KM',
    Cg: 'CG',
    Cd: 'CD',
    Ck: 'CK',
    Cr: 'CR',
    Ci: 'CI',
    Hr: 'HR',
    Cu: 'CU',
    Cw: 'CW',
    Cy: 'CY',
    Cz: 'CZ',
    Dk: 'DK',
    Dj: 'DJ',
    Dm: 'DM',
    Do: 'DO',
    Ec: 'EC',
    Eg: 'EG',
    Sv: 'SV',
    Gq: 'GQ',
    Er: 'ER',
    Ee: 'EE',
    Sz: 'SZ',
    Et: 'ET',
    Fk: 'FK',
    Fo: 'FO',
    Fj: 'FJ',
    Fi: 'FI',
    Fr: 'FR',
    Gf: 'GF',
    Pf: 'PF',
    Tf: 'TF',
    Ga: 'GA',
    Gm: 'GM',
    Ge: 'GE',
    De: 'DE',
    Gh: 'GH',
    Gi: 'GI',
    Gr: 'GR',
    Gl: 'GL',
    Gd: 'GD',
    Gp: 'GP',
    Gu: 'GU',
    Gt: 'GT',
    Gg: 'GG',
    Gn: 'GN',
    Gw: 'GW',
    Gy: 'GY',
    Ht: 'HT',
    Hm: 'HM',
    Va: 'VA',
    Hn: 'HN',
    Hk: 'HK',
    Hu: 'HU',
    Is: 'IS',
    In: 'IN',
    Id: 'ID',
    Ir: 'IR',
    Iq: 'IQ',
    Ie: 'IE',
    Im: 'IM',
    Il: 'IL',
    It: 'IT',
    Jm: 'JM',
    Jp: 'JP',
    Je: 'JE',
    Jo: 'JO',
    Kz: 'KZ',
    Ke: 'KE',
    Ki: 'KI',
    Kw: 'KW',
    Kg: 'KG',
    La: 'LA',
    Lv: 'LV',
    Lb: 'LB',
    Ls: 'LS',
    Lr: 'LR',
    Ly: 'LY',
    Li: 'LI',
    Lt: 'LT',
    Lu: 'LU',
    Mo: 'MO',
    Mg: 'MG',
    Mw: 'MW',
    My: 'MY',
    Mv: 'MV',
    Ml: 'ML',
    Mt: 'MT',
    Mh: 'MH',
    Mq: 'MQ',
    Mr: 'MR',
    Mu: 'MU',
    Yt: 'YT',
    Mx: 'MX',
    Fm: 'FM',
    Md: 'MD',
    Mc: 'MC',
    Mn: 'MN',
    Me: 'ME',
    Ms: 'MS',
    Ma: 'MA',
    Mz: 'MZ',
    Mm: 'MM',
    Na: 'NA',
    Nr: 'NR',
    Np: 'NP',
    Nl: 'NL',
    Nc: 'NC',
    Nz: 'NZ',
    Ni: 'NI',
    Ne: 'NE',
    Ng: 'NG',
    Nu: 'NU',
    Nf: 'NF',
    Kp: 'KP',
    Mk: 'MK',
    Mp: 'MP',
    No: 'NO',
    Om: 'OM',
    Pk: 'PK',
    Pw: 'PW',
    Ps: 'PS',
    Pa: 'PA',
    Pg: 'PG',
    Py: 'PY',
    Pe: 'PE',
    Ph: 'PH',
    Pn: 'PN',
    Pl: 'PL',
    Pt: 'PT',
    Pr: 'PR',
    Qa: 'QA',
    Re: 'RE',
    Ro: 'RO',
    Ru: 'RU',
    Rw: 'RW',
    Bl: 'BL',
    Sh: 'SH',
    Kn: 'KN',
    Lc: 'LC',
    Mf: 'MF',
    Pm: 'PM',
    Vc: 'VC',
    Ws: 'WS',
    Sm: 'SM',
    St: 'ST',
    Sa: 'SA',
    Sn: 'SN',
    Rs: 'RS',
    Sc: 'SC',
    Sl: 'SL',
    Sg: 'SG',
    Sx: 'SX',
    Sk: 'SK',
    Si: 'SI',
    Sb: 'SB',
    So: 'SO',
    Za: 'ZA',
    Gs: 'GS',
    Kr: 'KR',
    Ss: 'SS',
    Es: 'ES',
    Lk: 'LK',
    Sd: 'SD',
    Sr: 'SR',
    Sj: 'SJ',
    Se: 'SE',
    Ch: 'CH',
    Sy: 'SY',
    Tw: 'TW',
    Tj: 'TJ',
    Tz: 'TZ',
    Th: 'TH',
    Tl: 'TL',
    Tg: 'TG',
    Tk: 'TK',
    To: 'TO',
    Tt: 'TT',
    Tn: 'TN',
    Tr: 'TR',
    Tm: 'TM',
    Tc: 'TC',
    Tv: 'TV',
    Ug: 'UG',
    Ua: 'UA',
    Ae: 'AE',
    Gb: 'GB',
    Um: 'UM',
    Us: 'US',
    Uy: 'UY',
    Uz: 'UZ',
    Vu: 'VU',
    Ve: 'VE',
    Vn: 'VN',
    Vg: 'VG',
    Vi: 'VI',
    Wf: 'WF',
    Eh: 'EH',
    Ye: 'YE',
    Zm: 'ZM',
    Zw: 'ZW'
} as const;

export type UserGatedContentDownloadRequestCountryEnum = typeof UserGatedContentDownloadRequestCountryEnum[keyof typeof UserGatedContentDownloadRequestCountryEnum];
export const UserGatedContentDownloadRequestCountriesEnum = {
    Af: 'AF',
    Ax: 'AX',
    Al: 'AL',
    Dz: 'DZ',
    As: 'AS',
    Ad: 'AD',
    Ao: 'AO',
    Ai: 'AI',
    Aq: 'AQ',
    Ag: 'AG',
    Ar: 'AR',
    Am: 'AM',
    Aw: 'AW',
    Au: 'AU',
    At: 'AT',
    Az: 'AZ',
    Bs: 'BS',
    Bh: 'BH',
    Bd: 'BD',
    Bb: 'BB',
    By: 'BY',
    Be: 'BE',
    Bz: 'BZ',
    Bj: 'BJ',
    Bm: 'BM',
    Bt: 'BT',
    Bo: 'BO',
    Bq: 'BQ',
    Ba: 'BA',
    Bw: 'BW',
    Bv: 'BV',
    Br: 'BR',
    Io: 'IO',
    Bn: 'BN',
    Bg: 'BG',
    Bf: 'BF',
    Bi: 'BI',
    Cv: 'CV',
    Kh: 'KH',
    Cm: 'CM',
    Ca: 'CA',
    Ky: 'KY',
    Cf: 'CF',
    Td: 'TD',
    Cl: 'CL',
    Cn: 'CN',
    Cx: 'CX',
    Cc: 'CC',
    Co: 'CO',
    Km: 'KM',
    Cg: 'CG',
    Cd: 'CD',
    Ck: 'CK',
    Cr: 'CR',
    Ci: 'CI',
    Hr: 'HR',
    Cu: 'CU',
    Cw: 'CW',
    Cy: 'CY',
    Cz: 'CZ',
    Dk: 'DK',
    Dj: 'DJ',
    Dm: 'DM',
    Do: 'DO',
    Ec: 'EC',
    Eg: 'EG',
    Sv: 'SV',
    Gq: 'GQ',
    Er: 'ER',
    Ee: 'EE',
    Sz: 'SZ',
    Et: 'ET',
    Fk: 'FK',
    Fo: 'FO',
    Fj: 'FJ',
    Fi: 'FI',
    Fr: 'FR',
    Gf: 'GF',
    Pf: 'PF',
    Tf: 'TF',
    Ga: 'GA',
    Gm: 'GM',
    Ge: 'GE',
    De: 'DE',
    Gh: 'GH',
    Gi: 'GI',
    Gr: 'GR',
    Gl: 'GL',
    Gd: 'GD',
    Gp: 'GP',
    Gu: 'GU',
    Gt: 'GT',
    Gg: 'GG',
    Gn: 'GN',
    Gw: 'GW',
    Gy: 'GY',
    Ht: 'HT',
    Hm: 'HM',
    Va: 'VA',
    Hn: 'HN',
    Hk: 'HK',
    Hu: 'HU',
    Is: 'IS',
    In: 'IN',
    Id: 'ID',
    Ir: 'IR',
    Iq: 'IQ',
    Ie: 'IE',
    Im: 'IM',
    Il: 'IL',
    It: 'IT',
    Jm: 'JM',
    Jp: 'JP',
    Je: 'JE',
    Jo: 'JO',
    Kz: 'KZ',
    Ke: 'KE',
    Ki: 'KI',
    Kw: 'KW',
    Kg: 'KG',
    La: 'LA',
    Lv: 'LV',
    Lb: 'LB',
    Ls: 'LS',
    Lr: 'LR',
    Ly: 'LY',
    Li: 'LI',
    Lt: 'LT',
    Lu: 'LU',
    Mo: 'MO',
    Mg: 'MG',
    Mw: 'MW',
    My: 'MY',
    Mv: 'MV',
    Ml: 'ML',
    Mt: 'MT',
    Mh: 'MH',
    Mq: 'MQ',
    Mr: 'MR',
    Mu: 'MU',
    Yt: 'YT',
    Mx: 'MX',
    Fm: 'FM',
    Md: 'MD',
    Mc: 'MC',
    Mn: 'MN',
    Me: 'ME',
    Ms: 'MS',
    Ma: 'MA',
    Mz: 'MZ',
    Mm: 'MM',
    Na: 'NA',
    Nr: 'NR',
    Np: 'NP',
    Nl: 'NL',
    Nc: 'NC',
    Nz: 'NZ',
    Ni: 'NI',
    Ne: 'NE',
    Ng: 'NG',
    Nu: 'NU',
    Nf: 'NF',
    Kp: 'KP',
    Mk: 'MK',
    Mp: 'MP',
    No: 'NO',
    Om: 'OM',
    Pk: 'PK',
    Pw: 'PW',
    Ps: 'PS',
    Pa: 'PA',
    Pg: 'PG',
    Py: 'PY',
    Pe: 'PE',
    Ph: 'PH',
    Pn: 'PN',
    Pl: 'PL',
    Pt: 'PT',
    Pr: 'PR',
    Qa: 'QA',
    Re: 'RE',
    Ro: 'RO',
    Ru: 'RU',
    Rw: 'RW',
    Bl: 'BL',
    Sh: 'SH',
    Kn: 'KN',
    Lc: 'LC',
    Mf: 'MF',
    Pm: 'PM',
    Vc: 'VC',
    Ws: 'WS',
    Sm: 'SM',
    St: 'ST',
    Sa: 'SA',
    Sn: 'SN',
    Rs: 'RS',
    Sc: 'SC',
    Sl: 'SL',
    Sg: 'SG',
    Sx: 'SX',
    Sk: 'SK',
    Si: 'SI',
    Sb: 'SB',
    So: 'SO',
    Za: 'ZA',
    Gs: 'GS',
    Kr: 'KR',
    Ss: 'SS',
    Es: 'ES',
    Lk: 'LK',
    Sd: 'SD',
    Sr: 'SR',
    Sj: 'SJ',
    Se: 'SE',
    Ch: 'CH',
    Sy: 'SY',
    Tw: 'TW',
    Tj: 'TJ',
    Tz: 'TZ',
    Th: 'TH',
    Tl: 'TL',
    Tg: 'TG',
    Tk: 'TK',
    To: 'TO',
    Tt: 'TT',
    Tn: 'TN',
    Tr: 'TR',
    Tm: 'TM',
    Tc: 'TC',
    Tv: 'TV',
    Ug: 'UG',
    Ua: 'UA',
    Ae: 'AE',
    Gb: 'GB',
    Um: 'UM',
    Us: 'US',
    Uy: 'UY',
    Uz: 'UZ',
    Vu: 'VU',
    Ve: 'VE',
    Vn: 'VN',
    Vg: 'VG',
    Vi: 'VI',
    Wf: 'WF',
    Eh: 'EH',
    Ye: 'YE',
    Zm: 'ZM',
    Zw: 'ZW'
} as const;

export type UserGatedContentDownloadRequestCountriesEnum = typeof UserGatedContentDownloadRequestCountriesEnum[keyof typeof UserGatedContentDownloadRequestCountriesEnum];

/**
 * 
 * @export
 * @interface UserOrganization
 */
export interface UserOrganization {
    /**
     * 
     * @type {string}
     * @memberof UserOrganization
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserOrganization
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof UserOrganization
     */
    'user_count': number;
    /**
     * 
     * @type {number}
     * @memberof UserOrganization
     */
    'api_key_count': number;
    /**
     * 
     * @type {number}
     * @memberof UserOrganization
     */
    'template_library_count': number;
    /**
     * 
     * @type {number}
     * @memberof UserOrganization
     */
    'canary_count': number;
    /**
     * 
     * @type {number}
     * @memberof UserOrganization
     */
    'usage_hour_count': number;
    /**
     * 
     * @type {number}
     * @memberof UserOrganization
     */
    'usage_percentage': number;
    /**
     * 
     * @type {number}
     * @memberof UserOrganization
     */
    'usage_seconds_count'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserOrganization
     */
    'get_organiztion_active_canaries_count'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserOrganization
     */
    'max_active_canaries': number;
    /**
     * 
     * @type {boolean}
     * @memberof UserOrganization
     */
    'send_data_to_snowflake': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOrganization
     */
    'use_cortex_forecast_model': boolean;
}
/**
 * 
 * @export
 * @interface UserRoleReadOnly
 */
export interface UserRoleReadOnly {
    /**
     * 
     * @type {number}
     * @memberof UserRoleReadOnly
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserRoleReadOnly
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface UserSubscribedPlan
 */
export interface UserSubscribedPlan {
    /**
     * 
     * @type {string}
     * @memberof UserSubscribedPlan
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSubscribedPlan
     */
    'hubspot_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSubscribedPlan
     */
    'last_payment_date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSubscribedPlan
     */
    'next_payment_due_date'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserSubscribedPlan
     */
    'next_payment_amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserSubscribedPlan
     */
    'plan_price'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserSubscribedPlan
     */
    'plan_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSubscribedPlan
     */
    'plan_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSubscribedPlan
     */
    'billing_frequency'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSubscribedPlan
     */
    'payment_method'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSubscribedPlan
     */
    'card_holder'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSubscribedPlan
     */
    'plan_creation_date'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserSubscribedPlan
     */
    'max_active_canaries'?: number;
    /**
     * 
     * @type {Array<UserSubscribedPlanOrganizationsInner>}
     * @memberof UserSubscribedPlan
     */
    'organizations': Array<UserSubscribedPlanOrganizationsInner>;
    /**
     * 
     * @type {number}
     * @memberof UserSubscribedPlan
     */
    'assigned_canaries_count': number;
    /**
     * 
     * @type {string}
     * @memberof UserSubscribedPlan
     */
    'user'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserSubscribedPlan
     */
    'platform_active'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserSubscribedPlan
     */
    'active'?: boolean;
}
/**
 * 
 * @export
 * @interface UserSubscribedPlanAddOrganizations
 */
export interface UserSubscribedPlanAddOrganizations {
    /**
     * 
     * @type {string}
     * @memberof UserSubscribedPlanAddOrganizations
     */
    'hubspot_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSubscribedPlanAddOrganizations
     */
    'plan_id'?: string;
    /**
     * 
     * @type {Array<UserSubscribedPlanOrganizationsInner>}
     * @memberof UserSubscribedPlanAddOrganizations
     */
    'organizations': Array<UserSubscribedPlanOrganizationsInner>;
}
/**
 * 
 * @export
 * @interface UserSubscribedPlanAssignSubtractCanary
 */
export interface UserSubscribedPlanAssignSubtractCanary {
    /**
     * 
     * @type {string}
     * @memberof UserSubscribedPlanAssignSubtractCanary
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSubscribedPlanAssignSubtractCanary
     */
    'plan_id'?: string;
    /**
     * 
     * @type {Array<UserSubscribedPlanAssignSubtractCanaryOrganizationsInner>}
     * @memberof UserSubscribedPlanAssignSubtractCanary
     */
    'organizations': Array<UserSubscribedPlanAssignSubtractCanaryOrganizationsInner>;
}
/**
 * 
 * @export
 * @interface UserSubscribedPlanAssignSubtractCanaryOrganizationsInner
 */
export interface UserSubscribedPlanAssignSubtractCanaryOrganizationsInner {
    /**
     * 
     * @type {string}
     * @memberof UserSubscribedPlanAssignSubtractCanaryOrganizationsInner
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface UserSubscribedPlanOrganizationsInner
 */
export interface UserSubscribedPlanOrganizationsInner {
    /**
     * 
     * @type {string}
     * @memberof UserSubscribedPlanOrganizationsInner
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSubscribedPlanOrganizationsInner
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof UserSubscribedPlanOrganizationsInner
     */
    'max_active_canaries'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserSubscribedPlanOrganizationsInner
     */
    'get_organiztion_active_canaries_count'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSubscribedPlanOrganizationsInner
     */
    'get_canary_count'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSubscribedPlanOrganizationsInner
     */
    'created'?: string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id A UUID string identifying this Organization.
         * @param {OrganizationMember} [organizationMember] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMemberOrganization: async (id: string, organizationMember?: OrganizationMember, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addMemberOrganization', 'id', id)
            const localVarPath = `/organizations/{id}/add_member/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationMember, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this User Subscribed Plan.
         * @param {UserSubscribedPlanAddOrganizations} [userSubscribedPlanAddOrganizations] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrganizationUserSubscribedPlan: async (id: string, userSubscribedPlanAddOrganizations?: UserSubscribedPlanAddOrganizations, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addOrganizationUserSubscribedPlan', 'id', id)
            const localVarPath = `/user-subscribed-plans/{id}/add_organization/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userSubscribedPlanAddOrganizations, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this User Subscribed Plan.
         * @param {UserSubscribedPlanAssignSubtractCanary} [userSubscribedPlanAssignSubtractCanary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignCanaryUserSubscribedPlan: async (id: string, userSubscribedPlanAssignSubtractCanary?: UserSubscribedPlanAssignSubtractCanary, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assignCanaryUserSubscribedPlan', 'id', id)
            const localVarPath = `/user-subscribed-plans/{id}/assign_canary/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userSubscribedPlanAssignSubtractCanary, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint that manages Canarys
         * @param {string} id A UUID string identifying this Canary.
         * @param {CanarySchedule} [canarySchedule] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canaryAgentStatusCanary: async (id: string, canarySchedule?: CanarySchedule, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('canaryAgentStatusCanary', 'id', id)
            const localVarPath = `/canary/{id}/canary_agent_status/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(canarySchedule, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeTempPasswordAuthViewSet: async (body?: any, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/change_temp_password/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AbstractMetricBoundaryCondition} [abstractMetricBoundaryCondition] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAbstractMetricBoundaryCondition: async (abstractMetricBoundaryCondition?: AbstractMetricBoundaryCondition, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/abstractmetricboundaryconditions/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(abstractMetricBoundaryCondition, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuthToken} [authToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAuthToken: async (authToken?: AuthToken, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api-token/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authToken, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BillingDetail} [billingDetail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBillingDetail: async (billingDetail?: BillingDetail, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/billing-details/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingDetail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint that manages Canarys
         * @param {Canary} [canary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCanary: async (canary?: Canary, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/canary/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(canary, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint to manipulate CustomCanaryPrototypeSource
         * @param {CanaryPrototypeSource} [canaryPrototypeSource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCanaryPrototypeSourceCode: async (canaryPrototypeSource?: CanaryPrototypeSource, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/template-library-prototype/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(canaryPrototypeSource, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint to manipulate CustomCanaryRestfulLibSource
         * @param {CanaryRestfulLibSourceFile} [canaryRestfulLibSourceFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCanaryRestfulLibSourceCode: async (canaryRestfulLibSourceFile?: CanaryRestfulLibSourceFile, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/restful-lib-source-code/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(canaryRestfulLibSourceFile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get or update a single CanaryTask
         * @param {CanaryTask} [canaryTask] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCanaryTask: async (canaryTask?: CanaryTask, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/canary-task/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(canaryTask, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint that writes Canary data
         * @param {CanaryTaskRunDataWriteOnly} [canaryTaskRunDataWriteOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCanaryTaskRawData: async (canaryTaskRunDataWriteOnly?: CanaryTaskRunDataWriteOnly, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/canarydata/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(canaryTaskRunDataWriteOnly, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint to manipulate CanaryTemplateLibrary
         * @param {CanaryTemplateLibrary} [canaryTemplateLibrary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCanaryTemplateLibrary: async (canaryTemplateLibrary?: CanaryTemplateLibrary, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/template-library/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(canaryTemplateLibrary, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ContactRequestWriteOnly} [contactRequestWriteOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContactRequest: async (contactRequestWriteOnly?: ContactRequestWriteOnly, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/crm/contact_request/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactRequestWriteOnly, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Expose clusters to users  - important because it shares a security boundary with users - we must allow users to read all platform wide clusters (e.g. ours), but not change anything
         * @param {KubernetesCluster} [kubernetesCluster] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createKubernetesCluster: async (kubernetesCluster?: KubernetesCluster, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/kubernetes-cluster/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(kubernetesCluster, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Notification} [notification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNotification: async (notification?: Notification, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notifications/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notification, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NotificationChannelSetting} [notificationChannelSetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNotificationChannelSetting: async (notificationChannelSetting?: NotificationChannelSetting, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notifications-channels-configurations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationChannelSetting, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint to manipulate CustomCanaryRestfulLibSource
         * @param {OpenAPIRestfulLibrary} [openAPIRestfulLibrary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOpenAPIRestfulLibrary: async (openAPIRestfulLibrary?: OpenAPIRestfulLibrary, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/restful-lib/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(openAPIRestfulLibrary, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Organization} [organization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganization: async (organization?: Organization, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/organizations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organization, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OrganizationAPIKey} [organizationAPIKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganizationAPIKey: async (organizationAPIKey?: OrganizationAPIKey, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apikeys/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationAPIKey, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * View to manipulate ServiceComplianceColors for an organization
         * @param {ServiceComplianceColors} [serviceComplianceColors] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createServiceComplianceColors: async (serviceComplianceColors?: ServiceComplianceColors, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/service-compliance-colors/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(serviceComplianceColors, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * View to manipulate ServiceHealthColors for an organization
         * @param {ServiceHealthColors} [serviceHealthColors] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createServiceHealthColors: async (serviceHealthColors?: ServiceHealthColors, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/service-health-colors/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(serviceHealthColors, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * View to get ServiceHealthEvaluationSettingsGroup for an organization
         * @param {ServiceHealthEvaluationSettingsGroup} [serviceHealthEvaluationSettingsGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createServiceHealthEvaluationSettingsGroup: async (serviceHealthEvaluationSettingsGroup?: ServiceHealthEvaluationSettingsGroup, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/service-health-evaluation-settings-group/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(serviceHealthEvaluationSettingsGroup, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SimpleAlarm} [simpleAlarm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSimpleAlarm: async (simpleAlarm?: SimpleAlarm, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/metricalarm/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(simpleAlarm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Snowflake} [snowflake] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSnowflake: async (snowflake?: Snowflake, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/snowflakes/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(snowflake, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SnowflakeForecastModel} [snowflakeForecastModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSnowflakeForecastModel: async (snowflakeForecastModel?: SnowflakeForecastModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/snowflake-forecast-models/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(snowflakeForecastModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint to manipulate StoreCanary
         * @param {StoreCanaryWrite} [storeCanaryWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStoreCanary: async (storeCanaryWrite?: StoreCanaryWrite, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/canary-store-add/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storeCanaryWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SubscriptionCancelRequest} [subscriptionCancelRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscriptionCancelRequest: async (subscriptionCancelRequest?: SubscriptionCancelRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/subscription-cancel/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subscriptionCancelRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint to manipulate TemplateCanary
         * @param {TemplateCanaryWritable} [templateCanaryWritable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTemplateCanary: async (templateCanaryWritable?: TemplateCanaryWritable, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/template-canaries/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(templateCanaryWritable, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint to manipulate TemplateTask
         * @param {TemplateTaskReadOnly} [templateTaskReadOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTemplateTask: async (templateTaskReadOnly?: TemplateTaskReadOnly, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/template-canary-task/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(templateTaskReadOnly, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint that allows authenticated clients to view or edit users
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (user?: User, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserGatedContentDownloadRequest} [userGatedContentDownloadRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserGatedContentDownloadRequest: async (userGatedContentDownloadRequest?: UserGatedContentDownloadRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/crm/create_user_gated_content_download_request/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userGatedContentDownloadRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserSubscribedPlan} [userSubscribedPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserSubscribedPlan: async (userSubscribedPlan?: UserSubscribedPlan, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user-subscribed-plans/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userSubscribedPlan, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Abstract Metric Condition.
         * @param {string} [metricId] metric_id
         * @param {string} [canaryId] canary_id
         * @param {string} [taskId] task_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyAbstractMetricBoundaryCondition: async (id: string, metricId?: string, canaryId?: string, taskId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('destroyAbstractMetricBoundaryCondition', 'id', id)
            const localVarPath = `/abstractmetricboundaryconditions/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (metricId !== undefined) {
                localVarQueryParameter['metric_id'] = metricId;
            }

            if (canaryId !== undefined) {
                localVarQueryParameter['canary_id'] = canaryId;
            }

            if (taskId !== undefined) {
                localVarQueryParameter['task_id'] = taskId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this billing detail.
         * @param {string} [userId] user_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyBillingDetail: async (id: string, userId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('destroyBillingDetail', 'id', id)
            const localVarPath = `/billing-details/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint that manages Canarys
         * @param {string} id A UUID string identifying this Canary.
         * @param {string} [organizationId] organization_id
         * @param {DestroyCanaryStatusEnum} [status] status
         * @param {DestroyCanaryOrderingEnum} [ordering] Ordering
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyCanary: async (id: string, organizationId?: string, status?: DestroyCanaryStatusEnum, ordering?: DestroyCanaryOrderingEnum, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('destroyCanary', 'id', id)
            const localVarPath = `/canary/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint to manipulate CustomCanaryPrototypeSource
         * @param {string} id A UUID string identifying this Canary Prototype Source Code.
         * @param {string} [canaryTemplateLibraryId] canary_template_library_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyCanaryPrototypeSourceCode: async (id: string, canaryTemplateLibraryId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('destroyCanaryPrototypeSourceCode', 'id', id)
            const localVarPath = `/template-library-prototype/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (canaryTemplateLibraryId !== undefined) {
                localVarQueryParameter['canary_template_library_id'] = canaryTemplateLibraryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint to manipulate CustomCanaryRestfulLibSource
         * @param {string} id A UUID string identifying this Canary Restful Library Source Code.
         * @param {string} [restfulLibraryId] restful_library_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyCanaryRestfulLibSourceCode: async (id: string, restfulLibraryId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('destroyCanaryRestfulLibSourceCode', 'id', id)
            const localVarPath = `/restful-lib-source-code/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (restfulLibraryId !== undefined) {
                localVarQueryParameter['restful_library_id'] = restfulLibraryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get or update a single CanaryTask
         * @param {string} id A UUID string identifying this Canary Task.
         * @param {string} [canaryId] canary_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyCanaryTask: async (id: string, canaryId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('destroyCanaryTask', 'id', id)
            const localVarPath = `/canary-task/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (canaryId !== undefined) {
                localVarQueryParameter['canary_id'] = canaryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint to manipulate CanaryTemplateLibrary
         * @param {string} id A UUID string identifying this Canary Template Library.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyCanaryTemplateLibrary: async (id: string, organizationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('destroyCanaryTemplateLibrary', 'id', id)
            const localVarPath = `/template-library/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Expose clusters to users  - important because it shares a security boundary with users - we must allow users to read all platform wide clusters (e.g. ours), but not change anything
         * @param {string} id A UUID string identifying this Kubernetes Cluster.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyKubernetesCluster: async (id: string, organizationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('destroyKubernetesCluster', 'id', id)
            const localVarPath = `/kubernetes-cluster/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Notification.
         * @param {string} [alarmId] alarm_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyNotification: async (id: string, alarmId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('destroyNotification', 'id', id)
            const localVarPath = `/notifications/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (alarmId !== undefined) {
                localVarQueryParameter['alarm_id'] = alarmId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Notification Channel Setting.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyNotificationChannelSetting: async (id: string, organizationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('destroyNotificationChannelSetting', 'id', id)
            const localVarPath = `/notifications-channels-configurations/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint to manipulate CustomCanaryRestfulLibSource
         * @param {string} id A UUID string identifying this OpenAPI Restful Library.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyOpenAPIRestfulLibrary: async (id: string, organizationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('destroyOpenAPIRestfulLibrary', 'id', id)
            const localVarPath = `/restful-lib/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Organization.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyOrganization: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('destroyOrganization', 'id', id)
            const localVarPath = `/organizations/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} prefix 
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyOrganizationAPIKey: async (prefix: string, organizationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prefix' is not null or undefined
            assertParamExists('destroyOrganizationAPIKey', 'prefix', prefix)
            const localVarPath = `/apikeys/{prefix}/`
                .replace(`{${"prefix"}}`, encodeURIComponent(String(prefix)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * View to manipulate ServiceComplianceColors for an organization
         * @param {string} id A UUID string identifying this Service Compliance Color.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyServiceComplianceColors: async (id: string, organizationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('destroyServiceComplianceColors', 'id', id)
            const localVarPath = `/service-compliance-colors/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * View to manipulate ServiceHealthColors for an organization
         * @param {string} id A UUID string identifying this Service Health Color.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyServiceHealthColors: async (id: string, organizationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('destroyServiceHealthColors', 'id', id)
            const localVarPath = `/service-health-colors/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * View to get ServiceHealthEvaluationSettingsGroup for an organization
         * @param {string} id A UUID string identifying this Service Health Evaluation Settings Group.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyServiceHealthEvaluationSettingsGroup: async (id: string, organizationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('destroyServiceHealthEvaluationSettingsGroup', 'id', id)
            const localVarPath = `/service-health-evaluation-settings-group/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Simple Alarm.
         * @param {string} [metricId] metric_id
         * @param {string} [canaryId] canary_id
         * @param {string} [taskId] task_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroySimpleAlarm: async (id: string, metricId?: string, canaryId?: string, taskId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('destroySimpleAlarm', 'id', id)
            const localVarPath = `/metricalarm/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (metricId !== undefined) {
                localVarQueryParameter['metric_id'] = metricId;
            }

            if (canaryId !== undefined) {
                localVarQueryParameter['canary_id'] = canaryId;
            }

            if (taskId !== undefined) {
                localVarQueryParameter['task_id'] = taskId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Snowflake Setting.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroySnowflake: async (id: string, organizationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('destroySnowflake', 'id', id)
            const localVarPath = `/snowflakes/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Snowflake Forecast Model.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroySnowflakeForecastModel: async (id: string, organizationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('destroySnowflakeForecastModel', 'id', id)
            const localVarPath = `/snowflake-forecast-models/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint to manipulate StoreCanary
         * @param {string} id A UUID string identifying this Store Canary.
         * @param {string} [cloud] cloud
         * @param {string} [component] component
         * @param {string} [status] status
         * @param {string} [isPrivate] is_private
         * @param {string} [isPinnedCanary] is_pinned_canary
         * @param {string} [uploadedUser] uploaded_user
         * @param {string} [company] company
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyStoreCanary: async (id: string, cloud?: string, component?: string, status?: string, isPrivate?: string, isPinnedCanary?: string, uploadedUser?: string, company?: string, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('destroyStoreCanary', 'id', id)
            const localVarPath = `/canary-store/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (cloud !== undefined) {
                localVarQueryParameter['cloud'] = cloud;
            }

            if (component !== undefined) {
                localVarQueryParameter['component'] = component;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (isPrivate !== undefined) {
                localVarQueryParameter['is_private'] = isPrivate;
            }

            if (isPinnedCanary !== undefined) {
                localVarQueryParameter['is_pinned_canary'] = isPinnedCanary;
            }

            if (uploadedUser !== undefined) {
                localVarQueryParameter['uploaded_user'] = uploadedUser;
            }

            if (company !== undefined) {
                localVarQueryParameter['company'] = company;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Subscription Cancel Request.
         * @param {string} [subscriptionId] subscription_id
         * @param {string} [subscriptionUserId] subscription_user_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroySubscriptionCancelRequest: async (id: string, subscriptionId?: string, subscriptionUserId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('destroySubscriptionCancelRequest', 'id', id)
            const localVarPath = `/subscription-cancel/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subscriptionId !== undefined) {
                localVarQueryParameter['subscription_id'] = subscriptionId;
            }

            if (subscriptionUserId !== undefined) {
                localVarQueryParameter['subscription_user_id'] = subscriptionUserId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint to manipulate TemplateCanary
         * @param {string} id A UUID string identifying this Template Canary.
         * @param {string} [canaryTemplateLibraryId] canary_template_library_id
         * @param {string} [providerId] provider_id
         * @param {string} [serviceId] service_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyTemplateCanary: async (id: string, canaryTemplateLibraryId?: string, providerId?: string, serviceId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('destroyTemplateCanary', 'id', id)
            const localVarPath = `/template-canaries/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (canaryTemplateLibraryId !== undefined) {
                localVarQueryParameter['canary_template_library_id'] = canaryTemplateLibraryId;
            }

            if (providerId !== undefined) {
                localVarQueryParameter['provider_id'] = providerId;
            }

            if (serviceId !== undefined) {
                localVarQueryParameter['service_id'] = serviceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint to manipulate TemplateTask
         * @param {string} id A UUID string identifying this Template Task.
         * @param {string} [canaryTemplateLibraryId] canary_template_library_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyTemplateTask: async (id: string, canaryTemplateLibraryId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('destroyTemplateTask', 'id', id)
            const localVarPath = `/template-canary-task/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (canaryTemplateLibraryId !== undefined) {
                localVarQueryParameter['canary_template_library_id'] = canaryTemplateLibraryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint that allows authenticated clients to view or edit users
         * @param {string} id A UUID string identifying this user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyUser: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('destroyUser', 'id', id)
            const localVarPath = `/users/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this User Subscribed Plan.
         * @param {string} [userId] user_id
         * @param {string} [platformActive] platform_active
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyUserSubscribedPlan: async (id: string, userId?: string, platformActive?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('destroyUserSubscribedPlan', 'id', id)
            const localVarPath = `/user-subscribed-plans/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (platformActive !== undefined) {
                localVarQueryParameter['platform_active'] = platformActive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCloudCanariesArticleUserGatedContentDownloadFileReadOnly: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/crm/download_cloud_canaries_article`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint to manipulate CustomCanaryRestfulLibSource
         * @param {string} id A UUID string identifying this OpenAPI Restful Library.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadRestfulZipOpenAPIRestfulLibrary: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadRestfulZipOpenAPIRestfulLibrary', 'id', id)
            const localVarPath = `/restful-lib/{id}/download_restful_zip/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint to manipulate CustomCanaryRestfulLibSource
         * @param {string} id A UUID string identifying this OpenAPI Restful Library.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTemplateZipOpenAPIRestfulLibrary: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadTemplateZipOpenAPIRestfulLibrary', 'id', id)
            const localVarPath = `/restful-lib/{id}/download_template_zip/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint that manages Canarys
         * @param {string} id A UUID string identifying this Canary.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAndDownloadCanaryAgentCanary: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('generateAndDownloadCanaryAgentCanary', 'id', id)
            const localVarPath = `/canary/{id}/generate_and_download_canary_agent/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OrganizationAPIKeyContents} [organizationAPIKeyContents] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateKeyOrganizationAPIKey: async (organizationAPIKeyContents?: OrganizationAPIKeyContents, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apikeys/generate_key/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationAPIKeyContents, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [metricId] metric_id
         * @param {string} [canaryId] canary_id
         * @param {string} [taskId] task_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAbstractMetricBoundaryConditions: async (page?: number, pageSize?: number, metricId?: string, canaryId?: string, taskId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/abstractmetricboundaryconditions/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (metricId !== undefined) {
                localVarQueryParameter['metric_id'] = metricId;
            }

            if (canaryId !== undefined) {
                localVarQueryParameter['canary_id'] = canaryId;
            }

            if (taskId !== undefined) {
                localVarQueryParameter['task_id'] = taskId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [canaryId] canary_id
         * @param {string} [taskId] task_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAbstractMetrics: async (page?: number, pageSize?: number, canaryId?: string, taskId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/abstractmetrics/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (canaryId !== undefined) {
                localVarQueryParameter['canary_id'] = canaryId;
            }

            if (taskId !== undefined) {
                localVarQueryParameter['task_id'] = taskId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [userId] user_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBillingDetails: async (page?: number, pageSize?: number, userId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/billing-details/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint that manages Canarys
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] organization_id
         * @param {ListCanariesStatusEnum} [status] status
         * @param {ListCanariesOrderingEnum} [ordering] Ordering
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCanaries: async (page?: number, pageSize?: number, organizationId?: string, status?: ListCanariesStatusEnum, ordering?: ListCanariesOrderingEnum, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/canary/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A readonly ViewSet for viewing CanaryDailyForecast\'s
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCanaryDailyForecasts: async (page?: number, pageSize?: number, start?: string, stop?: string, organizationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/canarydailyforecast/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (stop !== undefined) {
                localVarQueryParameter['stop'] = stop;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [isActive] is_active
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCanaryPartners: async (page?: number, pageSize?: number, isActive?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/crm/cloud_canaries_partners/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['is_active'] = isActive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint to manipulate CustomCanaryPrototypeSource
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [canaryTemplateLibraryId] canary_template_library_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCanaryPrototypeSourceCodes: async (page?: number, pageSize?: number, canaryTemplateLibraryId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/template-library-prototype/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (canaryTemplateLibraryId !== undefined) {
                localVarQueryParameter['canary_template_library_id'] = canaryTemplateLibraryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint to manipulate CustomCanaryRestfulLibSource
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [restfulLibraryId] restful_library_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCanaryRestfulLibSourceCodes: async (page?: number, pageSize?: number, restfulLibraryId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/restful-lib-source-code/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (restfulLibraryId !== undefined) {
                localVarQueryParameter['restful_library_id'] = restfulLibraryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [measuredFrom] measured_from
         * @param {string} [measuredUntil] measured_until
         * @param {string} [metricId] metric_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCanaryTaskMetricData: async (page?: number, pageSize?: number, measuredFrom?: string, measuredUntil?: string, metricId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/canary-task-metric-data/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (measuredFrom !== undefined) {
                localVarQueryParameter['measured_from'] = measuredFrom;
            }

            if (measuredUntil !== undefined) {
                localVarQueryParameter['measured_until'] = measuredUntil;
            }

            if (metricId !== undefined) {
                localVarQueryParameter['metric_id'] = metricId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [measuredFrom] The Start date of the data export period, should be in this format: YYYY-MM-DD+HH%3AMM, note that %3A means \&#39;:\&#39; so don\&#39;t remove it.
         * @param {string} [measuredUntil] The End date of the data export period, should be in this format: YYYY-MM-DD+HH%3AMM, note that %3A means \&#39;:\&#39; so don\&#39;t remove it.
         * @param {string} [canaryId] The Canary id to get the exported data for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCanaryTaskRunDataExports: async (page?: number, pageSize?: number, measuredFrom?: string, measuredUntil?: string, canaryId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/canary-task-run-data/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (measuredFrom !== undefined) {
                localVarQueryParameter['measured_from'] = measuredFrom;
            }

            if (measuredUntil !== undefined) {
                localVarQueryParameter['measured_until'] = measuredUntil;
            }

            if (canaryId !== undefined) {
                localVarQueryParameter['canary_id'] = canaryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * View to get CanaryTaskData as a CSV Export  * Requires token authentication.
         * @param {string} [measuredFrom] The Start date of the data export period, should be in this format: YYYY-MM-DD+HH%3AMM, note that %3A means \&#39;:\&#39; so don\&#39;t remove it.
         * @param {string} [measuredUntil] The End date of the data export period, should be in this format: YYYY-MM-DD+HH%3AMM, note that %3A means \&#39;:\&#39; so don\&#39;t remove it.
         * @param {string} [canaryId] The Canary id to get the exported data for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCanaryTaskRunDataExports_1: async (measuredFrom?: string, measuredUntil?: string, canaryId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/canary-data-export/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (measuredFrom !== undefined) {
                localVarQueryParameter['measured_from'] = measuredFrom;
            }

            if (measuredUntil !== undefined) {
                localVarQueryParameter['measured_until'] = measuredUntil;
            }

            if (canaryId !== undefined) {
                localVarQueryParameter['canary_id'] = canaryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get or update a single CanaryTask
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [canaryId] canary_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCanaryTasks: async (page?: number, pageSize?: number, canaryId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/canary-task/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (canaryId !== undefined) {
                localVarQueryParameter['canary_id'] = canaryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint to manipulate CanaryTemplateLibrary
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCanaryTemplateLibraries: async (page?: number, pageSize?: number, organizationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/template-library/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [isActive] is_active
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCanaryUsers: async (page?: number, pageSize?: number, isActive?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/crm/cloud_canaries_users/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['is_active'] = isActive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A readonly ViewSet for viewing Canaryable Services.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [providerId] provider_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCanaryableServices: async (page?: number, pageSize?: number, providerId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/canaryableservice/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (providerId !== undefined) {
                localVarQueryParameter['provider_id'] = providerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A readonly ViewSet for viewing CompositeDailyForecasts Services.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCompositeDailyForecasts: async (page?: number, pageSize?: number, start?: string, stop?: string, organizationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/compositedailyforecast/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (stop !== undefined) {
                localVarQueryParameter['stop'] = stop;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCustomUserRoles: async (page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user-roles/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Expose clusters to users  - important because it shares a security boundary with users - we must allow users to read all platform wide clusters (e.g. ours), but not change anything
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listKubernetesClusters: async (page?: number, pageSize?: number, organizationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/kubernetes-cluster/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [isActive] is_active
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLandingPageVideos: async (page?: number, pageSize?: number, isActive?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/crm/cloud_canaries_videos/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['is_active'] = isActive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A readonly ViewSet for viewing NeuralProphetCanaryDailyForecastData\'s
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {string} [canaryId] canary_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNeuralProphetCanaryDailyForecastData: async (page?: number, pageSize?: number, start?: string, stop?: string, organizationId?: string, canaryId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/neuralcanarydailyforecastdata/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (stop !== undefined) {
                localVarQueryParameter['stop'] = stop;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }

            if (canaryId !== undefined) {
                localVarQueryParameter['canary_id'] = canaryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * View to get NeuralServiceDailyForecast as a CSV Export  * Requires token authentication.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [canaryId] canary_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNeuralProphetCanaryDailyForecastDataExports: async (start?: string, stop?: string, canaryId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/canary-daily-forecast-data-export/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (stop !== undefined) {
                localVarQueryParameter['stop'] = stop;
            }

            if (canaryId !== undefined) {
                localVarQueryParameter['canary_id'] = canaryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A readonly ViewSet for viewing NeuralProphetCanaryDailyForecast\'s
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {string} [canaryId] canary_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNeuralProphetCanaryDailyForecasts: async (page?: number, pageSize?: number, start?: string, stop?: string, organizationId?: string, canaryId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/neuralcanarydailyforecast/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (stop !== undefined) {
                localVarQueryParameter['stop'] = stop;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }

            if (canaryId !== undefined) {
                localVarQueryParameter['canary_id'] = canaryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A readonly ViewSet for viewing NeuralProphetCompositeDailyForecast Services.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNeuralProphetCompositeDailyForecasts: async (page?: number, pageSize?: number, start?: string, stop?: string, organizationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/neuralcompositedailyforecast/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (stop !== undefined) {
                localVarQueryParameter['stop'] = stop;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A readonly ViewSet for viewing NeuralProphetServiceDailyForecast
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNeuralProphetServiceDailyForecasts: async (page?: number, pageSize?: number, start?: string, stop?: string, organizationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/neuralservicedailyforecast/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (stop !== undefined) {
                localVarQueryParameter['stop'] = stop;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNotificationChannelSettings: async (page?: number, pageSize?: number, organizationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notifications-channels-configurations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [alarmId] alarm_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNotificationHistories: async (page?: number, pageSize?: number, alarmId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notifications-history/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (alarmId !== undefined) {
                localVarQueryParameter['alarm_id'] = alarmId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [alarmId] alarm_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNotifications: async (page?: number, pageSize?: number, alarmId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notifications/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (alarmId !== undefined) {
                localVarQueryParameter['alarm_id'] = alarmId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint to manipulate CustomCanaryRestfulLibSource
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOpenAPIRestfulLibraries: async (page?: number, pageSize?: number, organizationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/restful-lib/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrganizationAPIKeys: async (page?: number, pageSize?: number, organizationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apikeys/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrganizations: async (page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/organizations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint that gets the Canaries * Requires token authentication.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] The Organization id to filter with
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPublicAPICanaries: async (page?: number, pageSize?: number, organizationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public-apis/list-canaries/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * View to get CanaryTaskData as a CSV Export  * Requires token authentication.
         * @param {string} [measuredFrom] The Start date of the data export period, should be in this format: YYYY-MM-DD+HH%3AMM, note that %3A means \&#39;:\&#39; so don\&#39;t remove it.
         * @param {string} [measuredUntil] The End date of the data export period, should be in this format: YYYY-MM-DD+HH%3AMM, note that %3A means \&#39;:\&#39; so don\&#39;t remove it.
         * @param {string} [canaryId] The Canary id to get the exported data for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPublicAPICanaryTaskRunDataExports: async (measuredFrom?: string, measuredUntil?: string, canaryId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public-apis/export-canary-run-data/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (measuredFrom !== undefined) {
                localVarQueryParameter['measured_from'] = measuredFrom;
            }

            if (measuredUntil !== undefined) {
                localVarQueryParameter['measured_until'] = measuredUntil;
            }

            if (canaryId !== undefined) {
                localVarQueryParameter['canary_id'] = canaryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A readonly ViewSet for viewing Canaryable Services.  * Requires token authentication.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [providerId] The Provider id to filter with
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPublicAPICanaryableServices: async (page?: number, pageSize?: number, providerId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public-apis/list-organization-services/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (providerId !== undefined) {
                localVarQueryParameter['provider_id'] = providerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A readonly ViewSet for viewing NeuralProphetCanaryDailyForecast\'s
         * @param {string} [canaryId] The Canary ID to filter with
         * @param {string} [organizationId] The Organization ID to filter with
         * @param {string} [start] The Start date of the forecasting period, should be the next day date and in this format: YYYY-MM-DD
         * @param {string} [stop] The End date of the forecasting period, should be after 5 days and in this format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPublicAPINeuralProphetCanaryDailyForecasts: async (canaryId?: string, organizationId?: string, start?: string, stop?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public-apis/export-neural-profit-canary-daily-forecast-data/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (canaryId !== undefined) {
                localVarQueryParameter['canary_id'] = canaryId;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (stop !== undefined) {
                localVarQueryParameter['stop'] = stop;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A readonly ViewSet for viewing Incident probability in the next 24 hours, should return 1 entry for the next day entered date.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] The Organization ID to filter with
         * @param {string} [start] The Start date of the forecasting period, should be the next day date and in this format: YYYY-MM-DD
         * @param {string} [stop] The End date of the forecasting period, should be the next day date and in this format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPublicAPINeuralProphetCompositeDailyForecasts: async (page?: number, pageSize?: number, organizationId?: string, start?: string, stop?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public-apis/incident-probability/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (stop !== undefined) {
                localVarQueryParameter['stop'] = stop;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * View to get Organizations for a user  * Requires token authentication.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPublicAPIOrganizations: async (page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public-apis/list-user-organizations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A readonly ViewSet for viewing ServiceCompliance Summaries.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] The Organization id to filter with
         * @param {ListPublicAPIServiceComplianceSummariesPeriodCategoryEnum} [periodCategory] The Period Category to filter with, options are: \&#39;weekly\&#39;, \&#39;monthly\&#39;, \&#39;quarterly\&#39;, \&#39;yearly\&#39;
         * @param {string} [start] The Start date of the compliance period, should be in this format: YYYY-MM-DD
         * @param {string} [stop] The Stop date of the compliance period, should be in this format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPublicAPIServiceComplianceSummaries: async (page?: number, pageSize?: number, organizationId?: string, periodCategory?: ListPublicAPIServiceComplianceSummariesPeriodCategoryEnum, start?: string, stop?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public-apis/list-service-compliance-summaries/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }

            if (periodCategory !== undefined) {
                localVarQueryParameter['period_category'] = periodCategory;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (stop !== undefined) {
                localVarQueryParameter['stop'] = stop;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * View to get ServiceHealth of a group of canaries * Requires token authentication.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] The Organization id to filter with
         * @param {string} [serviceId] The Service id to filter with
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPublicAPIServiceHealths: async (page?: number, pageSize?: number, organizationId?: string, serviceId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public-apis/list-service-healths-status/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }

            if (serviceId !== undefined) {
                localVarQueryParameter['service_id'] = serviceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A readonly ViewSet for viewing ServiceProviders.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPublicAPIServiceProviders: async (page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public-apis/list-organization-providers/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * View to manipulate ServiceComplianceColors for an organization
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listServiceComplianceColors: async (organizationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/service-compliance-colors/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A readonly ViewSet for viewing ServiceCompliance Histories
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] organization_id
         * @param {string} [stop] stop
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listServiceComplianceHistories: async (page?: number, pageSize?: number, organizationId?: string, stop?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/service-compliance-history/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }

            if (stop !== undefined) {
                localVarQueryParameter['stop'] = stop;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A readonly ViewSet for viewing ServiceCompliance Summaries.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] organization_id
         * @param {ListServiceComplianceSummariesPeriodCategoryEnum} [periodCategory] period_category
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listServiceComplianceSummaries: async (page?: number, pageSize?: number, organizationId?: string, periodCategory?: ListServiceComplianceSummariesPeriodCategoryEnum, start?: string, stop?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/service-compliance-summary/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }

            if (periodCategory !== undefined) {
                localVarQueryParameter['period_category'] = periodCategory;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (stop !== undefined) {
                localVarQueryParameter['stop'] = stop;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A readonly ViewSet for viewing ServiceDailyForecasts
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listServiceDailyForecasts: async (page?: number, pageSize?: number, start?: string, stop?: string, organizationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/servicedailyforecast/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (stop !== undefined) {
                localVarQueryParameter['stop'] = stop;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * View to manipulate ServiceHealthColors for an organization
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listServiceHealthColors: async (organizationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/service-health-colors/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * View to get ServiceHealthEvaluationSettingsGroup for an organization
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listServiceHealthEvaluationSettingsGroups: async (page?: number, pageSize?: number, organizationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/service-health-evaluation-settings-group/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] organization_id
         * @param {string} [serviceId] service_id
         * @param {ListServiceHealthIncidentsIncidentStatusEnum} [incidentStatus] incident_status
         * @param {ListServiceHealthIncidentsOrderingEnum} [ordering] Ordering
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listServiceHealthIncidents: async (page?: number, pageSize?: number, organizationId?: string, serviceId?: string, incidentStatus?: ListServiceHealthIncidentsIncidentStatusEnum, ordering?: ListServiceHealthIncidentsOrderingEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/service-health-incident/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }

            if (serviceId !== undefined) {
                localVarQueryParameter['service_id'] = serviceId;
            }

            if (incidentStatus !== undefined) {
                localVarQueryParameter['incident_status'] = incidentStatus;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * View to get ServiceHealth of a group of canaries
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] organization_id
         * @param {string} [providerId] provider_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listServiceHealths: async (page?: number, pageSize?: number, organizationId?: string, providerId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/service-health/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }

            if (providerId !== undefined) {
                localVarQueryParameter['provider_id'] = providerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A readonly ViewSet for viewing ServiceProviderAvailabilityZone\'s.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [providerId] provider_id
         * @param {string} [regionId] region_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listServiceProviderAvailabilityZones: async (page?: number, pageSize?: number, providerId?: string, regionId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/canaryserviceprovideravailabilityzones/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (providerId !== undefined) {
                localVarQueryParameter['provider_id'] = providerId;
            }

            if (regionId !== undefined) {
                localVarQueryParameter['region_id'] = regionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A readonly ViewSet for viewing ServiceProviderRegion\'s.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [providerId] provider_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listServiceProviderRegions: async (page?: number, pageSize?: number, providerId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/canaryserviceproviderregionss/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (providerId !== undefined) {
                localVarQueryParameter['provider_id'] = providerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A readonly ViewSet for viewing ServiceProviders.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listServiceProviders: async (page?: number, pageSize?: number, organizationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/canaryserviceproviders/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [alarmId] alarm_id
         * @param {string} [periodStart] period_start
         * @param {string} [periodStop] period_stop
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSimpleAlarmEvaluations: async (page?: number, pageSize?: number, alarmId?: string, periodStart?: string, periodStop?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/alarm-evaluations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (alarmId !== undefined) {
                localVarQueryParameter['alarm_id'] = alarmId;
            }

            if (periodStart !== undefined) {
                localVarQueryParameter['period_start'] = periodStart;
            }

            if (periodStop !== undefined) {
                localVarQueryParameter['period_stop'] = periodStop;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [alarmId] alarm_id
         * @param {string} [evaluationPeriodStart] evaluation_period_start
         * @param {string} [evaluationPeriodStop] evaluation_period_stop
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSimpleAlarmHistories: async (page?: number, pageSize?: number, alarmId?: string, evaluationPeriodStart?: string, evaluationPeriodStop?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/alarm-history/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (alarmId !== undefined) {
                localVarQueryParameter['alarm_id'] = alarmId;
            }

            if (evaluationPeriodStart !== undefined) {
                localVarQueryParameter['evaluation_period_start'] = evaluationPeriodStart;
            }

            if (evaluationPeriodStop !== undefined) {
                localVarQueryParameter['evaluation_period_stop'] = evaluationPeriodStop;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [metricId] metric_id
         * @param {string} [canaryId] canary_id
         * @param {string} [taskId] task_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSimpleAlarms: async (page?: number, pageSize?: number, metricId?: string, canaryId?: string, taskId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/metricalarm/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (metricId !== undefined) {
                localVarQueryParameter['metric_id'] = metricId;
            }

            if (canaryId !== undefined) {
                localVarQueryParameter['canary_id'] = canaryId;
            }

            if (taskId !== undefined) {
                localVarQueryParameter['task_id'] = taskId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A readonly ViewSet for viewing SnowflakeCanaryDailyForecastData Services.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {string} [canaryId] canary_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSnowflakeCanaryDailyForecastData: async (page?: number, pageSize?: number, start?: string, stop?: string, organizationId?: string, canaryId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/snowflakecanarydailyforecastdata/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (stop !== undefined) {
                localVarQueryParameter['stop'] = stop;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }

            if (canaryId !== undefined) {
                localVarQueryParameter['canary_id'] = canaryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A readonly ViewSet for viewing SnowflakeCanaryDailyForecast\'s
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {string} [canaryId] canary_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSnowflakeCanaryDailyForecasts: async (page?: number, pageSize?: number, start?: string, stop?: string, organizationId?: string, canaryId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/snowflakecanarydailyforecast/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (stop !== undefined) {
                localVarQueryParameter['stop'] = stop;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }

            if (canaryId !== undefined) {
                localVarQueryParameter['canary_id'] = canaryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A readonly ViewSet for viewing SnowflakeCompositeDailyForecast Services.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSnowflakeCompositeDailyForecasts: async (page?: number, pageSize?: number, start?: string, stop?: string, organizationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/snowflakecompositedailyforecast/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (stop !== undefined) {
                localVarQueryParameter['stop'] = stop;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSnowflakeForecastModels: async (page?: number, pageSize?: number, organizationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/snowflake-forecast-models/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A readonly ViewSet for viewing SnowflakeServiceDailyForecast
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSnowflakeServiceDailyForecasts: async (page?: number, pageSize?: number, start?: string, stop?: string, organizationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/snowflakeservicedailyforecast/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (stop !== undefined) {
                localVarQueryParameter['stop'] = stop;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSnowflakes: async (page?: number, pageSize?: number, organizationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/snowflakes/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint to manipulate StoreCanary
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [cloud] cloud
         * @param {string} [component] component
         * @param {string} [status] status
         * @param {string} [isPrivate] is_private
         * @param {string} [isPinnedCanary] is_pinned_canary
         * @param {string} [uploadedUser] uploaded_user
         * @param {string} [company] company
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStoreCanaries: async (page?: number, pageSize?: number, cloud?: string, component?: string, status?: string, isPrivate?: string, isPinnedCanary?: string, uploadedUser?: string, company?: string, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/canary-store/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (cloud !== undefined) {
                localVarQueryParameter['cloud'] = cloud;
            }

            if (component !== undefined) {
                localVarQueryParameter['component'] = component;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (isPrivate !== undefined) {
                localVarQueryParameter['is_private'] = isPrivate;
            }

            if (isPinnedCanary !== undefined) {
                localVarQueryParameter['is_pinned_canary'] = isPinnedCanary;
            }

            if (uploadedUser !== undefined) {
                localVarQueryParameter['uploaded_user'] = uploadedUser;
            }

            if (company !== undefined) {
                localVarQueryParameter['company'] = company;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStoreCanaryClouds: async (page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/canary-store-clouds/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStoreCanaryComponents: async (page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/canary-store-components/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStoreCanaryProducts: async (page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/canary-store-products/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [subscriptionId] subscription_id
         * @param {string} [subscriptionUserId] subscription_user_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSubscriptionCancelRequests: async (page?: number, pageSize?: number, subscriptionId?: string, subscriptionUserId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/subscription-cancel/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (subscriptionId !== undefined) {
                localVarQueryParameter['subscription_id'] = subscriptionId;
            }

            if (subscriptionUserId !== undefined) {
                localVarQueryParameter['subscription_user_id'] = subscriptionUserId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint to manipulate TemplateCanary
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [canaryTemplateLibraryId] canary_template_library_id
         * @param {string} [providerId] provider_id
         * @param {string} [serviceId] service_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTemplateCanaries: async (page?: number, pageSize?: number, canaryTemplateLibraryId?: string, providerId?: string, serviceId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/template-canaries/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (canaryTemplateLibraryId !== undefined) {
                localVarQueryParameter['canary_template_library_id'] = canaryTemplateLibraryId;
            }

            if (providerId !== undefined) {
                localVarQueryParameter['provider_id'] = providerId;
            }

            if (serviceId !== undefined) {
                localVarQueryParameter['service_id'] = serviceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint to manipulate TemplateTask
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [canaryTemplateLibraryId] canary_template_library_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTemplateTasks: async (page?: number, pageSize?: number, canaryTemplateLibraryId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/template-canary-task/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (canaryTemplateLibraryId !== undefined) {
                localVarQueryParameter['canary_template_library_id'] = canaryTemplateLibraryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserGatedContentDownloadRequests: async (page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/crm/create_user_gated_content_download_request/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [userId] user_id
         * @param {string} [platformActive] platform_active
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserSubscribedPlans: async (page?: number, pageSize?: number, userId?: string, platformActive?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user-subscribed-plans/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (platformActive !== undefined) {
                localVarQueryParameter['platform_active'] = platformActive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint that allows authenticated clients to view or edit users
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsers: async (page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint to manipulate StoreCanary
         * @param {string} id A UUID string identifying this Store Canary.
         * @param {StoreCanaryAutoLoadCanaryTemplateLibraryReadOnly} [storeCanaryAutoLoadCanaryTemplateLibraryReadOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadTemplateLibrariesStoreCanary: async (id: string, storeCanaryAutoLoadCanaryTemplateLibraryReadOnly?: StoreCanaryAutoLoadCanaryTemplateLibraryReadOnly, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('loadTemplateLibrariesStoreCanary', 'id', id)
            const localVarPath = `/auto-load-canary-template-libraries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storeCanaryAutoLoadCanaryTemplateLibraryReadOnly, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutUserAuthViewSet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/logout_user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Abstract Metric Condition.
         * @param {string} [metricId] metric_id
         * @param {string} [canaryId] canary_id
         * @param {string} [taskId] task_id
         * @param {AbstractMetricBoundaryCondition} [abstractMetricBoundaryCondition] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateAbstractMetricBoundaryCondition: async (id: string, metricId?: string, canaryId?: string, taskId?: string, abstractMetricBoundaryCondition?: AbstractMetricBoundaryCondition, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateAbstractMetricBoundaryCondition', 'id', id)
            const localVarPath = `/abstractmetricboundaryconditions/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (metricId !== undefined) {
                localVarQueryParameter['metric_id'] = metricId;
            }

            if (canaryId !== undefined) {
                localVarQueryParameter['canary_id'] = canaryId;
            }

            if (taskId !== undefined) {
                localVarQueryParameter['task_id'] = taskId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(abstractMetricBoundaryCondition, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this billing detail.
         * @param {string} [userId] user_id
         * @param {BillingDetail} [billingDetail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateBillingDetail: async (id: string, userId?: string, billingDetail?: BillingDetail, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateBillingDetail', 'id', id)
            const localVarPath = `/billing-details/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingDetail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint that manages Canarys
         * @param {string} id A UUID string identifying this Canary.
         * @param {string} [organizationId] organization_id
         * @param {PartialUpdateCanaryStatusEnum} [status] status
         * @param {PartialUpdateCanaryOrderingEnum} [ordering] Ordering
         * @param {string} [search] A search term.
         * @param {Canary} [canary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateCanary: async (id: string, organizationId?: string, status?: PartialUpdateCanaryStatusEnum, ordering?: PartialUpdateCanaryOrderingEnum, search?: string, canary?: Canary, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateCanary', 'id', id)
            const localVarPath = `/canary/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(canary, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this canary cluster deployment.
         * @param {CanaryClusterDeployment} [canaryClusterDeployment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateCanaryClusterDeployment: async (id: string, canaryClusterDeployment?: CanaryClusterDeployment, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateCanaryClusterDeployment', 'id', id)
            const localVarPath = `/canary-deployment-update/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(canaryClusterDeployment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint to manipulate CustomCanaryPrototypeSource
         * @param {string} id A UUID string identifying this Canary Prototype Source Code.
         * @param {string} [canaryTemplateLibraryId] canary_template_library_id
         * @param {CanaryPrototypeSource} [canaryPrototypeSource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateCanaryPrototypeSourceCode: async (id: string, canaryTemplateLibraryId?: string, canaryPrototypeSource?: CanaryPrototypeSource, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateCanaryPrototypeSourceCode', 'id', id)
            const localVarPath = `/template-library-prototype/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (canaryTemplateLibraryId !== undefined) {
                localVarQueryParameter['canary_template_library_id'] = canaryTemplateLibraryId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(canaryPrototypeSource, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint to manipulate CustomCanaryRestfulLibSource
         * @param {string} id A UUID string identifying this Canary Restful Library Source Code.
         * @param {string} [restfulLibraryId] restful_library_id
         * @param {CanaryRestfulLibSourceFile} [canaryRestfulLibSourceFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateCanaryRestfulLibSourceCode: async (id: string, restfulLibraryId?: string, canaryRestfulLibSourceFile?: CanaryRestfulLibSourceFile, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateCanaryRestfulLibSourceCode', 'id', id)
            const localVarPath = `/restful-lib-source-code/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (restfulLibraryId !== undefined) {
                localVarQueryParameter['restful_library_id'] = restfulLibraryId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(canaryRestfulLibSourceFile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get or update a single CanaryTask
         * @param {string} id A UUID string identifying this Canary Task.
         * @param {string} [canaryId] canary_id
         * @param {CanaryTask} [canaryTask] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateCanaryTask: async (id: string, canaryId?: string, canaryTask?: CanaryTask, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateCanaryTask', 'id', id)
            const localVarPath = `/canary-task/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (canaryId !== undefined) {
                localVarQueryParameter['canary_id'] = canaryId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(canaryTask, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get or update a single CanaryTask
         * @param {string} id A UUID string identifying this canary task env vars.
         * @param {CanaryTaskEnvVars} [canaryTaskEnvVars] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateCanaryTaskEnvVars: async (id: string, canaryTaskEnvVars?: CanaryTaskEnvVars, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateCanaryTaskEnvVars', 'id', id)
            const localVarPath = `/canary-task-env/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(canaryTaskEnvVars, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint to manipulate CanaryTemplateLibrary
         * @param {string} id A UUID string identifying this Canary Template Library.
         * @param {string} [organizationId] organization_id
         * @param {CanaryTemplateLibrary} [canaryTemplateLibrary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateCanaryTemplateLibrary: async (id: string, organizationId?: string, canaryTemplateLibrary?: CanaryTemplateLibrary, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateCanaryTemplateLibrary', 'id', id)
            const localVarPath = `/template-library/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(canaryTemplateLibrary, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Expose clusters to users  - important because it shares a security boundary with users - we must allow users to read all platform wide clusters (e.g. ours), but not change anything
         * @param {string} id A UUID string identifying this Kubernetes Cluster.
         * @param {string} [organizationId] organization_id
         * @param {KubernetesCluster} [kubernetesCluster] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateKubernetesCluster: async (id: string, organizationId?: string, kubernetesCluster?: KubernetesCluster, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateKubernetesCluster', 'id', id)
            const localVarPath = `/kubernetes-cluster/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(kubernetesCluster, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Notification.
         * @param {string} [alarmId] alarm_id
         * @param {Notification} [notification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateNotification: async (id: string, alarmId?: string, notification?: Notification, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateNotification', 'id', id)
            const localVarPath = `/notifications/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (alarmId !== undefined) {
                localVarQueryParameter['alarm_id'] = alarmId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notification, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Notification Channel Setting.
         * @param {string} [organizationId] organization_id
         * @param {NotificationChannelSetting} [notificationChannelSetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateNotificationChannelSetting: async (id: string, organizationId?: string, notificationChannelSetting?: NotificationChannelSetting, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateNotificationChannelSetting', 'id', id)
            const localVarPath = `/notifications-channels-configurations/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationChannelSetting, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint to manipulate CustomCanaryRestfulLibSource
         * @param {string} id A UUID string identifying this OpenAPI Restful Library.
         * @param {string} [organizationId] organization_id
         * @param {OpenAPIRestfulLibrary} [openAPIRestfulLibrary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateOpenAPIRestfulLibrary: async (id: string, organizationId?: string, openAPIRestfulLibrary?: OpenAPIRestfulLibrary, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateOpenAPIRestfulLibrary', 'id', id)
            const localVarPath = `/restful-lib/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(openAPIRestfulLibrary, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Organization.
         * @param {OrganizationUpdate} [organizationUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateOrganization: async (id: string, organizationUpdate?: OrganizationUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateOrganization', 'id', id)
            const localVarPath = `/organizations/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} prefix 
         * @param {string} [organizationId] organization_id
         * @param {OrganizationAPIKey} [organizationAPIKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateOrganizationAPIKey: async (prefix: string, organizationId?: string, organizationAPIKey?: OrganizationAPIKey, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prefix' is not null or undefined
            assertParamExists('partialUpdateOrganizationAPIKey', 'prefix', prefix)
            const localVarPath = `/apikeys/{prefix}/`
                .replace(`{${"prefix"}}`, encodeURIComponent(String(prefix)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationAPIKey, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * View to manipulate ServiceComplianceColors for an organization
         * @param {string} id A UUID string identifying this Service Compliance Color.
         * @param {string} [organizationId] organization_id
         * @param {ServiceComplianceColors} [serviceComplianceColors] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateServiceComplianceColors: async (id: string, organizationId?: string, serviceComplianceColors?: ServiceComplianceColors, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateServiceComplianceColors', 'id', id)
            const localVarPath = `/service-compliance-colors/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(serviceComplianceColors, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * View to manipulate ServiceHealthColors for an organization
         * @param {string} id A UUID string identifying this Service Health Color.
         * @param {string} [organizationId] organization_id
         * @param {ServiceHealthColors} [serviceHealthColors] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateServiceHealthColors: async (id: string, organizationId?: string, serviceHealthColors?: ServiceHealthColors, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateServiceHealthColors', 'id', id)
            const localVarPath = `/service-health-colors/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(serviceHealthColors, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * View to get ServiceHealthEvaluationSettingsGroup for an organization
         * @param {string} id A UUID string identifying this Service Health Evaluation Settings Group.
         * @param {string} [organizationId] organization_id
         * @param {ServiceHealthEvaluationSettingsGroup} [serviceHealthEvaluationSettingsGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateServiceHealthEvaluationSettingsGroup: async (id: string, organizationId?: string, serviceHealthEvaluationSettingsGroup?: ServiceHealthEvaluationSettingsGroup, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateServiceHealthEvaluationSettingsGroup', 'id', id)
            const localVarPath = `/service-health-evaluation-settings-group/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(serviceHealthEvaluationSettingsGroup, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Simple Alarm.
         * @param {string} [metricId] metric_id
         * @param {string} [canaryId] canary_id
         * @param {string} [taskId] task_id
         * @param {SimpleAlarm} [simpleAlarm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateSimpleAlarm: async (id: string, metricId?: string, canaryId?: string, taskId?: string, simpleAlarm?: SimpleAlarm, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateSimpleAlarm', 'id', id)
            const localVarPath = `/metricalarm/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (metricId !== undefined) {
                localVarQueryParameter['metric_id'] = metricId;
            }

            if (canaryId !== undefined) {
                localVarQueryParameter['canary_id'] = canaryId;
            }

            if (taskId !== undefined) {
                localVarQueryParameter['task_id'] = taskId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(simpleAlarm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Snowflake Setting.
         * @param {string} [organizationId] organization_id
         * @param {Snowflake} [snowflake] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateSnowflake: async (id: string, organizationId?: string, snowflake?: Snowflake, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateSnowflake', 'id', id)
            const localVarPath = `/snowflakes/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(snowflake, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Snowflake Forecast Model.
         * @param {string} [organizationId] organization_id
         * @param {SnowflakeForecastModel} [snowflakeForecastModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateSnowflakeForecastModel: async (id: string, organizationId?: string, snowflakeForecastModel?: SnowflakeForecastModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateSnowflakeForecastModel', 'id', id)
            const localVarPath = `/snowflake-forecast-models/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(snowflakeForecastModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint to manipulate StoreCanary
         * @param {string} id A UUID string identifying this Store Canary.
         * @param {string} [cloud] cloud
         * @param {string} [component] component
         * @param {string} [status] status
         * @param {string} [isPrivate] is_private
         * @param {string} [isPinnedCanary] is_pinned_canary
         * @param {string} [uploadedUser] uploaded_user
         * @param {string} [company] company
         * @param {string} [search] A search term.
         * @param {StoreCanaryWrite} [storeCanaryWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateStoreCanary: async (id: string, cloud?: string, component?: string, status?: string, isPrivate?: string, isPinnedCanary?: string, uploadedUser?: string, company?: string, search?: string, storeCanaryWrite?: StoreCanaryWrite, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateStoreCanary', 'id', id)
            const localVarPath = `/canary-store/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (cloud !== undefined) {
                localVarQueryParameter['cloud'] = cloud;
            }

            if (component !== undefined) {
                localVarQueryParameter['component'] = component;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (isPrivate !== undefined) {
                localVarQueryParameter['is_private'] = isPrivate;
            }

            if (isPinnedCanary !== undefined) {
                localVarQueryParameter['is_pinned_canary'] = isPinnedCanary;
            }

            if (uploadedUser !== undefined) {
                localVarQueryParameter['uploaded_user'] = uploadedUser;
            }

            if (company !== undefined) {
                localVarQueryParameter['company'] = company;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storeCanaryWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Subscription Cancel Request.
         * @param {string} [subscriptionId] subscription_id
         * @param {string} [subscriptionUserId] subscription_user_id
         * @param {SubscriptionCancelRequest} [subscriptionCancelRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateSubscriptionCancelRequest: async (id: string, subscriptionId?: string, subscriptionUserId?: string, subscriptionCancelRequest?: SubscriptionCancelRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateSubscriptionCancelRequest', 'id', id)
            const localVarPath = `/subscription-cancel/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subscriptionId !== undefined) {
                localVarQueryParameter['subscription_id'] = subscriptionId;
            }

            if (subscriptionUserId !== undefined) {
                localVarQueryParameter['subscription_user_id'] = subscriptionUserId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subscriptionCancelRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint to manipulate TemplateCanary
         * @param {string} id A UUID string identifying this Template Canary.
         * @param {string} [canaryTemplateLibraryId] canary_template_library_id
         * @param {string} [providerId] provider_id
         * @param {string} [serviceId] service_id
         * @param {TemplateCanaryWritable} [templateCanaryWritable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateTemplateCanary: async (id: string, canaryTemplateLibraryId?: string, providerId?: string, serviceId?: string, templateCanaryWritable?: TemplateCanaryWritable, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateTemplateCanary', 'id', id)
            const localVarPath = `/template-canaries/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (canaryTemplateLibraryId !== undefined) {
                localVarQueryParameter['canary_template_library_id'] = canaryTemplateLibraryId;
            }

            if (providerId !== undefined) {
                localVarQueryParameter['provider_id'] = providerId;
            }

            if (serviceId !== undefined) {
                localVarQueryParameter['service_id'] = serviceId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(templateCanaryWritable, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint to manipulate TemplateTask
         * @param {string} id A UUID string identifying this Template Task.
         * @param {string} [canaryTemplateLibraryId] canary_template_library_id
         * @param {TemplateTaskReadOnly} [templateTaskReadOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateTemplateTask: async (id: string, canaryTemplateLibraryId?: string, templateTaskReadOnly?: TemplateTaskReadOnly, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateTemplateTask', 'id', id)
            const localVarPath = `/template-canary-task/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (canaryTemplateLibraryId !== undefined) {
                localVarQueryParameter['canary_template_library_id'] = canaryTemplateLibraryId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(templateTaskReadOnly, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint that allows authenticated clients to view or edit users
         * @param {string} id A UUID string identifying this user.
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateUser: async (id: string, user?: User, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateUser', 'id', id)
            const localVarPath = `/users/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this User Subscribed Plan.
         * @param {string} [userId] user_id
         * @param {string} [platformActive] platform_active
         * @param {UserSubscribedPlan} [userSubscribedPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateUserSubscribedPlan: async (id: string, userId?: string, platformActive?: string, userSubscribedPlan?: UserSubscribedPlan, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateUserSubscribedPlan', 'id', id)
            const localVarPath = `/user-subscribed-plans/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (platformActive !== undefined) {
                localVarQueryParameter['platform_active'] = platformActive;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userSubscribedPlan, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshAuthTokenAuthViewSet: async (body?: any, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/refresh_auth_token/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Organization.
         * @param {OrganizationMember} [organizationMember] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMemberOrganization: async (id: string, organizationMember?: OrganizationMember, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeMemberOrganization', 'id', id)
            const localVarPath = `/organizations/{id}/remove_member/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationMember, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestResetPasswordAuthViewSet: async (body?: any, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/request_reset_password/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordAuthViewSet: async (body?: any, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/reset_password/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Abstract Metric.
         * @param {string} [canaryId] canary_id
         * @param {string} [taskId] task_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveAbstractMetric: async (id: string, canaryId?: string, taskId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveAbstractMetric', 'id', id)
            const localVarPath = `/abstractmetrics/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (canaryId !== undefined) {
                localVarQueryParameter['canary_id'] = canaryId;
            }

            if (taskId !== undefined) {
                localVarQueryParameter['task_id'] = taskId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Abstract Metric Condition.
         * @param {string} [metricId] metric_id
         * @param {string} [canaryId] canary_id
         * @param {string} [taskId] task_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveAbstractMetricBoundaryCondition: async (id: string, metricId?: string, canaryId?: string, taskId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveAbstractMetricBoundaryCondition', 'id', id)
            const localVarPath = `/abstractmetricboundaryconditions/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (metricId !== undefined) {
                localVarQueryParameter['metric_id'] = metricId;
            }

            if (canaryId !== undefined) {
                localVarQueryParameter['canary_id'] = canaryId;
            }

            if (taskId !== undefined) {
                localVarQueryParameter['task_id'] = taskId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveApiVersion: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveApiVersion', 'id', id)
            const localVarPath = `/apimeta/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this billing detail.
         * @param {string} [userId] user_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveBillingDetail: async (id: string, userId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveBillingDetail', 'id', id)
            const localVarPath = `/billing-details/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint that manages Canarys
         * @param {string} id A UUID string identifying this Canary.
         * @param {string} [organizationId] organization_id
         * @param {RetrieveCanaryStatusEnum} [status] status
         * @param {RetrieveCanaryOrderingEnum} [ordering] Ordering
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCanary: async (id: string, organizationId?: string, status?: RetrieveCanaryStatusEnum, ordering?: RetrieveCanaryOrderingEnum, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveCanary', 'id', id)
            const localVarPath = `/canary/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A readonly ViewSet for viewing CanaryDailyForecast\'s
         * @param {string} id A UUID string identifying this Canary Daily Forecast.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCanaryDailyForecast: async (id: string, start?: string, stop?: string, organizationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveCanaryDailyForecast', 'id', id)
            const localVarPath = `/canarydailyforecast/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (stop !== undefined) {
                localVarQueryParameter['stop'] = stop;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint to manipulate CustomCanaryPrototypeSource
         * @param {string} id A UUID string identifying this Canary Prototype Source Code.
         * @param {string} [canaryTemplateLibraryId] canary_template_library_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCanaryPrototypeSourceCode: async (id: string, canaryTemplateLibraryId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveCanaryPrototypeSourceCode', 'id', id)
            const localVarPath = `/template-library-prototype/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (canaryTemplateLibraryId !== undefined) {
                localVarQueryParameter['canary_template_library_id'] = canaryTemplateLibraryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint to manipulate CustomCanaryRestfulLibSource
         * @param {string} id A UUID string identifying this Canary Restful Library Source Code.
         * @param {string} [restfulLibraryId] restful_library_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCanaryRestfulLibSourceCode: async (id: string, restfulLibraryId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveCanaryRestfulLibSourceCode', 'id', id)
            const localVarPath = `/restful-lib-source-code/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (restfulLibraryId !== undefined) {
                localVarQueryParameter['restful_library_id'] = restfulLibraryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get or update a single CanaryTask
         * @param {string} id A UUID string identifying this Canary Task.
         * @param {string} [canaryId] canary_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCanaryTask: async (id: string, canaryId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveCanaryTask', 'id', id)
            const localVarPath = `/canary-task/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (canaryId !== undefined) {
                localVarQueryParameter['canary_id'] = canaryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * View to get CanaryData as a graph  * Requires token authentication.
         * @param {string} id A UUID string identifying this canary task data graph.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCanaryTaskDataGraph: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveCanaryTaskDataGraph', 'id', id)
            const localVarPath = `/canary-task-data-graph/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get or update a single CanaryTask
         * @param {string} id A UUID string identifying this canary task env vars.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCanaryTaskEnvVars: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveCanaryTaskEnvVars', 'id', id)
            const localVarPath = `/canary-task-env/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Canary Task Metric Data.
         * @param {string} [measuredFrom] measured_from
         * @param {string} [measuredUntil] measured_until
         * @param {string} [metricId] metric_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCanaryTaskMetricData: async (id: string, measuredFrom?: string, measuredUntil?: string, metricId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveCanaryTaskMetricData', 'id', id)
            const localVarPath = `/canary-task-metric-data/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (measuredFrom !== undefined) {
                localVarQueryParameter['measured_from'] = measuredFrom;
            }

            if (measuredUntil !== undefined) {
                localVarQueryParameter['measured_until'] = measuredUntil;
            }

            if (metricId !== undefined) {
                localVarQueryParameter['metric_id'] = metricId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Canary Task Run Data Export.
         * @param {string} [measuredFrom] The Start date of the data export period, should be in this format: YYYY-MM-DD+HH%3AMM, note that %3A means \&#39;:\&#39; so don\&#39;t remove it.
         * @param {string} [measuredUntil] The End date of the data export period, should be in this format: YYYY-MM-DD+HH%3AMM, note that %3A means \&#39;:\&#39; so don\&#39;t remove it.
         * @param {string} [canaryId] The Canary id to get the exported data for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCanaryTaskRunDataExport: async (id: string, measuredFrom?: string, measuredUntil?: string, canaryId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveCanaryTaskRunDataExport', 'id', id)
            const localVarPath = `/canary-task-run-data/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (measuredFrom !== undefined) {
                localVarQueryParameter['measured_from'] = measuredFrom;
            }

            if (measuredUntil !== undefined) {
                localVarQueryParameter['measured_until'] = measuredUntil;
            }

            if (canaryId !== undefined) {
                localVarQueryParameter['canary_id'] = canaryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint to manipulate CanaryTemplateLibrary
         * @param {string} id A UUID string identifying this Canary Template Library.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCanaryTemplateLibrary: async (id: string, organizationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveCanaryTemplateLibrary', 'id', id)
            const localVarPath = `/template-library/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A readonly ViewSet for viewing Canaryable Services.
         * @param {string} id A UUID string identifying this Canaryable Service.
         * @param {string} [providerId] provider_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCanaryableService: async (id: string, providerId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveCanaryableService', 'id', id)
            const localVarPath = `/canaryableservice/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (providerId !== undefined) {
                localVarQueryParameter['provider_id'] = providerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A readonly ViewSet for viewing CompositeDailyForecasts Services.
         * @param {string} id A UUID string identifying this Composite Daily Forecast.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCompositeDailyForecast: async (id: string, start?: string, stop?: string, organizationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveCompositeDailyForecast', 'id', id)
            const localVarPath = `/compositedailyforecast/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (stop !== undefined) {
                localVarQueryParameter['stop'] = stop;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Expose clusters to users  - important because it shares a security boundary with users - we must allow users to read all platform wide clusters (e.g. ours), but not change anything
         * @param {string} id A UUID string identifying this Kubernetes Cluster.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveKubernetesCluster: async (id: string, organizationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveKubernetesCluster', 'id', id)
            const localVarPath = `/kubernetes-cluster/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A readonly ViewSet for viewing NeuralProphetCanaryDailyForecast\'s
         * @param {string} id A UUID string identifying this Neural Prophet Canary Daily Forecast.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {string} [canaryId] canary_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveNeuralProphetCanaryDailyForecast: async (id: string, start?: string, stop?: string, organizationId?: string, canaryId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveNeuralProphetCanaryDailyForecast', 'id', id)
            const localVarPath = `/neuralcanarydailyforecast/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (stop !== undefined) {
                localVarQueryParameter['stop'] = stop;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }

            if (canaryId !== undefined) {
                localVarQueryParameter['canary_id'] = canaryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A readonly ViewSet for viewing NeuralProphetCanaryDailyForecastData\'s
         * @param {string} id A UUID string identifying this Neural Prophet Canary Daily Forecast Data.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {string} [canaryId] canary_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveNeuralProphetCanaryDailyForecastData: async (id: string, start?: string, stop?: string, organizationId?: string, canaryId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveNeuralProphetCanaryDailyForecastData', 'id', id)
            const localVarPath = `/neuralcanarydailyforecastdata/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (stop !== undefined) {
                localVarQueryParameter['stop'] = stop;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }

            if (canaryId !== undefined) {
                localVarQueryParameter['canary_id'] = canaryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A readonly ViewSet for viewing NeuralProphetCompositeDailyForecast Services.
         * @param {string} id A UUID string identifying this Neural Prophet Composite Daily Forecast.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveNeuralProphetCompositeDailyForecast: async (id: string, start?: string, stop?: string, organizationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveNeuralProphetCompositeDailyForecast', 'id', id)
            const localVarPath = `/neuralcompositedailyforecast/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (stop !== undefined) {
                localVarQueryParameter['stop'] = stop;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A readonly ViewSet for viewing NeuralProphetServiceDailyForecast
         * @param {string} id A UUID string identifying this Neural Prophet Service Daily Forecast.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveNeuralProphetServiceDailyForecast: async (id: string, start?: string, stop?: string, organizationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveNeuralProphetServiceDailyForecast', 'id', id)
            const localVarPath = `/neuralservicedailyforecast/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (stop !== undefined) {
                localVarQueryParameter['stop'] = stop;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Notification.
         * @param {string} [alarmId] alarm_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveNotification: async (id: string, alarmId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveNotification', 'id', id)
            const localVarPath = `/notifications/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (alarmId !== undefined) {
                localVarQueryParameter['alarm_id'] = alarmId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Notification Channel Setting.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveNotificationChannelSetting: async (id: string, organizationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveNotificationChannelSetting', 'id', id)
            const localVarPath = `/notifications-channels-configurations/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Notification History.
         * @param {string} [alarmId] alarm_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveNotificationHistory: async (id: string, alarmId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveNotificationHistory', 'id', id)
            const localVarPath = `/notifications-history/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (alarmId !== undefined) {
                localVarQueryParameter['alarm_id'] = alarmId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint to manipulate CustomCanaryRestfulLibSource
         * @param {string} id A UUID string identifying this OpenAPI Restful Library.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveOpenAPIRestfulLibrary: async (id: string, organizationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveOpenAPIRestfulLibrary', 'id', id)
            const localVarPath = `/restful-lib/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Organization.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveOrganization: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveOrganization', 'id', id)
            const localVarPath = `/organizations/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} prefix 
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveOrganizationAPIKey: async (prefix: string, organizationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prefix' is not null or undefined
            assertParamExists('retrieveOrganizationAPIKey', 'prefix', prefix)
            const localVarPath = `/apikeys/{prefix}/`
                .replace(`{${"prefix"}}`, encodeURIComponent(String(prefix)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint that gets the status of the canary * Requires token authentication.
         * @param {string} id A UUID string identifying this public api canary.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePublicAPICanary: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrievePublicAPICanary', 'id', id)
            const localVarPath = `/public-apis/canary/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A readonly ViewSet for viewing ServiceCompliance Summaries.
         * @param {string} id A UUID string identifying this public api service compliance summary.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePublicAPIServiceComplianceSummary: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrievePublicAPIServiceComplianceSummary', 'id', id)
            const localVarPath = `/public-apis/service-compliance-summary/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * View to get ServiceHealth of a canary * Requires token authentication.
         * @param {string} id A UUID string identifying this public api service health.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePublicAPIServiceHealth: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrievePublicAPIServiceHealth', 'id', id)
            const localVarPath = `/public-apis/service-health-status/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * View to manipulate ServiceComplianceColors for an organization
         * @param {string} id A UUID string identifying this Service Compliance Color.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveServiceComplianceColors: async (id: string, organizationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveServiceComplianceColors', 'id', id)
            const localVarPath = `/service-compliance-colors/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A readonly ViewSet for viewing ServiceCompliance Histories
         * @param {string} id A UUID string identifying this service compliance histories.
         * @param {string} [organizationId] organization_id
         * @param {string} [stop] stop
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveServiceComplianceHistories: async (id: string, organizationId?: string, stop?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveServiceComplianceHistories', 'id', id)
            const localVarPath = `/service-compliance-history/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }

            if (stop !== undefined) {
                localVarQueryParameter['stop'] = stop;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A readonly ViewSet for viewing ServiceCompliance Summaries.
         * @param {string} id A UUID string identifying this Service Compliance Summary.
         * @param {string} [organizationId] organization_id
         * @param {RetrieveServiceComplianceSummaryPeriodCategoryEnum} [periodCategory] period_category
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveServiceComplianceSummary: async (id: string, organizationId?: string, periodCategory?: RetrieveServiceComplianceSummaryPeriodCategoryEnum, start?: string, stop?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveServiceComplianceSummary', 'id', id)
            const localVarPath = `/service-compliance-summary/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }

            if (periodCategory !== undefined) {
                localVarQueryParameter['period_category'] = periodCategory;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (stop !== undefined) {
                localVarQueryParameter['stop'] = stop;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A readonly ViewSet for viewing ServiceDailyForecasts
         * @param {string} id A UUID string identifying this Service Daily Forecast.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveServiceDailyForecast: async (id: string, start?: string, stop?: string, organizationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveServiceDailyForecast', 'id', id)
            const localVarPath = `/servicedailyforecast/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (stop !== undefined) {
                localVarQueryParameter['stop'] = stop;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * View to get ServiceHealth of a group of canaries
         * @param {string} id A UUID string identifying this Service Health.
         * @param {string} [organizationId] organization_id
         * @param {string} [providerId] provider_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveServiceHealth: async (id: string, organizationId?: string, providerId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveServiceHealth', 'id', id)
            const localVarPath = `/service-health/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }

            if (providerId !== undefined) {
                localVarQueryParameter['provider_id'] = providerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * View to manipulate ServiceHealthColors for an organization
         * @param {string} id A UUID string identifying this Service Health Color.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveServiceHealthColors: async (id: string, organizationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveServiceHealthColors', 'id', id)
            const localVarPath = `/service-health-colors/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * View to get ServiceHealthEvaluationSettingsGroup for an organization
         * @param {string} id A UUID string identifying this Service Health Evaluation Settings Group.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveServiceHealthEvaluationSettingsGroup: async (id: string, organizationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveServiceHealthEvaluationSettingsGroup', 'id', id)
            const localVarPath = `/service-health-evaluation-settings-group/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this service health incident.
         * @param {string} [organizationId] organization_id
         * @param {string} [serviceId] service_id
         * @param {RetrieveServiceHealthIncidentIncidentStatusEnum} [incidentStatus] incident_status
         * @param {RetrieveServiceHealthIncidentOrderingEnum} [ordering] Ordering
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveServiceHealthIncident: async (id: string, organizationId?: string, serviceId?: string, incidentStatus?: RetrieveServiceHealthIncidentIncidentStatusEnum, ordering?: RetrieveServiceHealthIncidentOrderingEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveServiceHealthIncident', 'id', id)
            const localVarPath = `/service-health-incident/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }

            if (serviceId !== undefined) {
                localVarQueryParameter['service_id'] = serviceId;
            }

            if (incidentStatus !== undefined) {
                localVarQueryParameter['incident_status'] = incidentStatus;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A readonly ViewSet for viewing ServiceProviders.
         * @param {string} id A UUID string identifying this Service Provider.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveServiceProvider: async (id: string, organizationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveServiceProvider', 'id', id)
            const localVarPath = `/canaryserviceproviders/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A readonly ViewSet for viewing ServiceProviderAvailabilityZone\'s.
         * @param {string} id A UUID string identifying this Service Provider Availability Zone.
         * @param {string} [providerId] provider_id
         * @param {string} [regionId] region_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveServiceProviderAvailabilityZone: async (id: string, providerId?: string, regionId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveServiceProviderAvailabilityZone', 'id', id)
            const localVarPath = `/canaryserviceprovideravailabilityzones/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (providerId !== undefined) {
                localVarQueryParameter['provider_id'] = providerId;
            }

            if (regionId !== undefined) {
                localVarQueryParameter['region_id'] = regionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A readonly ViewSet for viewing ServiceProviderRegion\'s.
         * @param {string} id A UUID string identifying this Service Provider Region.
         * @param {string} [providerId] provider_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveServiceProviderRegion: async (id: string, providerId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveServiceProviderRegion', 'id', id)
            const localVarPath = `/canaryserviceproviderregionss/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (providerId !== undefined) {
                localVarQueryParameter['provider_id'] = providerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Simple Alarm.
         * @param {string} [metricId] metric_id
         * @param {string} [canaryId] canary_id
         * @param {string} [taskId] task_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveSimpleAlarm: async (id: string, metricId?: string, canaryId?: string, taskId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveSimpleAlarm', 'id', id)
            const localVarPath = `/metricalarm/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (metricId !== undefined) {
                localVarQueryParameter['metric_id'] = metricId;
            }

            if (canaryId !== undefined) {
                localVarQueryParameter['canary_id'] = canaryId;
            }

            if (taskId !== undefined) {
                localVarQueryParameter['task_id'] = taskId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Simple Alarm Evaluation.
         * @param {string} [alarmId] alarm_id
         * @param {string} [periodStart] period_start
         * @param {string} [periodStop] period_stop
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveSimpleAlarmEvaluation: async (id: string, alarmId?: string, periodStart?: string, periodStop?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveSimpleAlarmEvaluation', 'id', id)
            const localVarPath = `/alarm-evaluations/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (alarmId !== undefined) {
                localVarQueryParameter['alarm_id'] = alarmId;
            }

            if (periodStart !== undefined) {
                localVarQueryParameter['period_start'] = periodStart;
            }

            if (periodStop !== undefined) {
                localVarQueryParameter['period_stop'] = periodStop;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Simple Alarm History.
         * @param {string} [alarmId] alarm_id
         * @param {string} [evaluationPeriodStart] evaluation_period_start
         * @param {string} [evaluationPeriodStop] evaluation_period_stop
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveSimpleAlarmHistory: async (id: string, alarmId?: string, evaluationPeriodStart?: string, evaluationPeriodStop?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveSimpleAlarmHistory', 'id', id)
            const localVarPath = `/alarm-history/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (alarmId !== undefined) {
                localVarQueryParameter['alarm_id'] = alarmId;
            }

            if (evaluationPeriodStart !== undefined) {
                localVarQueryParameter['evaluation_period_start'] = evaluationPeriodStart;
            }

            if (evaluationPeriodStop !== undefined) {
                localVarQueryParameter['evaluation_period_stop'] = evaluationPeriodStop;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Snowflake Setting.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveSnowflake: async (id: string, organizationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveSnowflake', 'id', id)
            const localVarPath = `/snowflakes/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A readonly ViewSet for viewing SnowflakeCanaryDailyForecast\'s
         * @param {string} id A UUID string identifying this Snowflake Canary Daily Forecast.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {string} [canaryId] canary_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveSnowflakeCanaryDailyForecast: async (id: string, start?: string, stop?: string, organizationId?: string, canaryId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveSnowflakeCanaryDailyForecast', 'id', id)
            const localVarPath = `/snowflakecanarydailyforecast/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (stop !== undefined) {
                localVarQueryParameter['stop'] = stop;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }

            if (canaryId !== undefined) {
                localVarQueryParameter['canary_id'] = canaryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A readonly ViewSet for viewing SnowflakeCanaryDailyForecastData Services.
         * @param {string} id A UUID string identifying this Snowflake Canary Daily Data Forecast.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {string} [canaryId] canary_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveSnowflakeCanaryDailyForecastData: async (id: string, start?: string, stop?: string, organizationId?: string, canaryId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveSnowflakeCanaryDailyForecastData', 'id', id)
            const localVarPath = `/snowflakecanarydailyforecastdata/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (stop !== undefined) {
                localVarQueryParameter['stop'] = stop;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }

            if (canaryId !== undefined) {
                localVarQueryParameter['canary_id'] = canaryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A readonly ViewSet for viewing SnowflakeCompositeDailyForecast Services.
         * @param {string} id A UUID string identifying this Snowflake Composite Daily Forecast.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveSnowflakeCompositeDailyForecast: async (id: string, start?: string, stop?: string, organizationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveSnowflakeCompositeDailyForecast', 'id', id)
            const localVarPath = `/snowflakecompositedailyforecast/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (stop !== undefined) {
                localVarQueryParameter['stop'] = stop;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Snowflake Forecast Model.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveSnowflakeForecastModel: async (id: string, organizationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveSnowflakeForecastModel', 'id', id)
            const localVarPath = `/snowflake-forecast-models/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A readonly ViewSet for viewing SnowflakeServiceDailyForecast
         * @param {string} id A UUID string identifying this Snowflake Service Daily Forecast.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveSnowflakeServiceDailyForecast: async (id: string, start?: string, stop?: string, organizationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveSnowflakeServiceDailyForecast', 'id', id)
            const localVarPath = `/snowflakeservicedailyforecast/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (stop !== undefined) {
                localVarQueryParameter['stop'] = stop;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint to manipulate StoreCanary
         * @param {string} id A UUID string identifying this Store Canary.
         * @param {string} [cloud] cloud
         * @param {string} [component] component
         * @param {string} [status] status
         * @param {string} [isPrivate] is_private
         * @param {string} [isPinnedCanary] is_pinned_canary
         * @param {string} [uploadedUser] uploaded_user
         * @param {string} [company] company
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveStoreCanary: async (id: string, cloud?: string, component?: string, status?: string, isPrivate?: string, isPinnedCanary?: string, uploadedUser?: string, company?: string, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveStoreCanary', 'id', id)
            const localVarPath = `/canary-store/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (cloud !== undefined) {
                localVarQueryParameter['cloud'] = cloud;
            }

            if (component !== undefined) {
                localVarQueryParameter['component'] = component;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (isPrivate !== undefined) {
                localVarQueryParameter['is_private'] = isPrivate;
            }

            if (isPinnedCanary !== undefined) {
                localVarQueryParameter['is_pinned_canary'] = isPinnedCanary;
            }

            if (uploadedUser !== undefined) {
                localVarQueryParameter['uploaded_user'] = uploadedUser;
            }

            if (company !== undefined) {
                localVarQueryParameter['company'] = company;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Subscription Cancel Request.
         * @param {string} [subscriptionId] subscription_id
         * @param {string} [subscriptionUserId] subscription_user_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveSubscriptionCancelRequest: async (id: string, subscriptionId?: string, subscriptionUserId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveSubscriptionCancelRequest', 'id', id)
            const localVarPath = `/subscription-cancel/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subscriptionId !== undefined) {
                localVarQueryParameter['subscription_id'] = subscriptionId;
            }

            if (subscriptionUserId !== undefined) {
                localVarQueryParameter['subscription_user_id'] = subscriptionUserId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint to manipulate TemplateCanary
         * @param {string} id A UUID string identifying this Template Canary.
         * @param {string} [canaryTemplateLibraryId] canary_template_library_id
         * @param {string} [providerId] provider_id
         * @param {string} [serviceId] service_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveTemplateCanary: async (id: string, canaryTemplateLibraryId?: string, providerId?: string, serviceId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveTemplateCanary', 'id', id)
            const localVarPath = `/template-canaries/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (canaryTemplateLibraryId !== undefined) {
                localVarQueryParameter['canary_template_library_id'] = canaryTemplateLibraryId;
            }

            if (providerId !== undefined) {
                localVarQueryParameter['provider_id'] = providerId;
            }

            if (serviceId !== undefined) {
                localVarQueryParameter['service_id'] = serviceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint to manipulate TemplateTask
         * @param {string} id A UUID string identifying this Template Task.
         * @param {string} [canaryTemplateLibraryId] canary_template_library_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveTemplateTask: async (id: string, canaryTemplateLibraryId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveTemplateTask', 'id', id)
            const localVarPath = `/template-canary-task/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (canaryTemplateLibraryId !== undefined) {
                localVarQueryParameter['canary_template_library_id'] = canaryTemplateLibraryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint that allows authenticated clients to view or edit users
         * @param {string} id A UUID string identifying this user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveUser: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveUser', 'id', id)
            const localVarPath = `/users/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this User Subscribed Plan.
         * @param {string} [userId] user_id
         * @param {string} [platformActive] platform_active
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveUserSubscribedPlan: async (id: string, userId?: string, platformActive?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveUserSubscribedPlan', 'id', id)
            const localVarPath = `/user-subscribed-plans/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (platformActive !== undefined) {
                localVarQueryParameter['platform_active'] = platformActive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint that manages Canarys
         * @param {string} id A UUID string identifying this Canary.
         * @param {CanarySchedule} [canarySchedule] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduleCanary: async (id: string, canarySchedule?: CanarySchedule, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('scheduleCanary', 'id', id)
            const localVarPath = `/canary/{id}/schedule/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(canarySchedule, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this User Subscribed Plan.
         * @param {UserSubscribedPlanAssignSubtractCanary} [userSubscribedPlanAssignSubtractCanary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subtractCanaryUserSubscribedPlan: async (id: string, userSubscribedPlanAssignSubtractCanary?: UserSubscribedPlanAssignSubtractCanary, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('subtractCanaryUserSubscribedPlan', 'id', id)
            const localVarPath = `/user-subscribed-plans/{id}/subtract_canary/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userSubscribedPlanAssignSubtractCanary, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Abstract Metric Condition.
         * @param {string} [metricId] metric_id
         * @param {string} [canaryId] canary_id
         * @param {string} [taskId] task_id
         * @param {AbstractMetricBoundaryCondition} [abstractMetricBoundaryCondition] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAbstractMetricBoundaryCondition: async (id: string, metricId?: string, canaryId?: string, taskId?: string, abstractMetricBoundaryCondition?: AbstractMetricBoundaryCondition, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAbstractMetricBoundaryCondition', 'id', id)
            const localVarPath = `/abstractmetricboundaryconditions/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (metricId !== undefined) {
                localVarQueryParameter['metric_id'] = metricId;
            }

            if (canaryId !== undefined) {
                localVarQueryParameter['canary_id'] = canaryId;
            }

            if (taskId !== undefined) {
                localVarQueryParameter['task_id'] = taskId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(abstractMetricBoundaryCondition, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this billing detail.
         * @param {string} [userId] user_id
         * @param {BillingDetail} [billingDetail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBillingDetail: async (id: string, userId?: string, billingDetail?: BillingDetail, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateBillingDetail', 'id', id)
            const localVarPath = `/billing-details/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingDetail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint that manages Canarys
         * @param {string} id A UUID string identifying this Canary.
         * @param {string} [organizationId] organization_id
         * @param {UpdateCanaryStatusEnum} [status] status
         * @param {UpdateCanaryOrderingEnum} [ordering] Ordering
         * @param {string} [search] A search term.
         * @param {Canary} [canary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCanary: async (id: string, organizationId?: string, status?: UpdateCanaryStatusEnum, ordering?: UpdateCanaryOrderingEnum, search?: string, canary?: Canary, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCanary', 'id', id)
            const localVarPath = `/canary/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(canary, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this canary cluster deployment.
         * @param {CanaryClusterDeployment} [canaryClusterDeployment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCanaryClusterDeployment: async (id: string, canaryClusterDeployment?: CanaryClusterDeployment, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCanaryClusterDeployment', 'id', id)
            const localVarPath = `/canary-deployment-update/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(canaryClusterDeployment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint to manipulate CustomCanaryPrototypeSource
         * @param {string} id A UUID string identifying this Canary Prototype Source Code.
         * @param {string} [canaryTemplateLibraryId] canary_template_library_id
         * @param {CanaryPrototypeSource} [canaryPrototypeSource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCanaryPrototypeSourceCode: async (id: string, canaryTemplateLibraryId?: string, canaryPrototypeSource?: CanaryPrototypeSource, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCanaryPrototypeSourceCode', 'id', id)
            const localVarPath = `/template-library-prototype/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (canaryTemplateLibraryId !== undefined) {
                localVarQueryParameter['canary_template_library_id'] = canaryTemplateLibraryId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(canaryPrototypeSource, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint to manipulate CustomCanaryRestfulLibSource
         * @param {string} id A UUID string identifying this Canary Restful Library Source Code.
         * @param {string} [restfulLibraryId] restful_library_id
         * @param {CanaryRestfulLibSourceFile} [canaryRestfulLibSourceFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCanaryRestfulLibSourceCode: async (id: string, restfulLibraryId?: string, canaryRestfulLibSourceFile?: CanaryRestfulLibSourceFile, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCanaryRestfulLibSourceCode', 'id', id)
            const localVarPath = `/restful-lib-source-code/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (restfulLibraryId !== undefined) {
                localVarQueryParameter['restful_library_id'] = restfulLibraryId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(canaryRestfulLibSourceFile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get or update a single CanaryTask
         * @param {string} id A UUID string identifying this Canary Task.
         * @param {string} [canaryId] canary_id
         * @param {CanaryTask} [canaryTask] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCanaryTask: async (id: string, canaryId?: string, canaryTask?: CanaryTask, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCanaryTask', 'id', id)
            const localVarPath = `/canary-task/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (canaryId !== undefined) {
                localVarQueryParameter['canary_id'] = canaryId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(canaryTask, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get or update a single CanaryTask
         * @param {string} id A UUID string identifying this canary task env vars.
         * @param {CanaryTaskEnvVars} [canaryTaskEnvVars] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCanaryTaskEnvVars: async (id: string, canaryTaskEnvVars?: CanaryTaskEnvVars, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCanaryTaskEnvVars', 'id', id)
            const localVarPath = `/canary-task-env/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(canaryTaskEnvVars, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint to manipulate CanaryTemplateLibrary
         * @param {string} id A UUID string identifying this Canary Template Library.
         * @param {string} [organizationId] organization_id
         * @param {CanaryTemplateLibrary} [canaryTemplateLibrary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCanaryTemplateLibrary: async (id: string, organizationId?: string, canaryTemplateLibrary?: CanaryTemplateLibrary, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCanaryTemplateLibrary', 'id', id)
            const localVarPath = `/template-library/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(canaryTemplateLibrary, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Expose clusters to users  - important because it shares a security boundary with users - we must allow users to read all platform wide clusters (e.g. ours), but not change anything
         * @param {string} id A UUID string identifying this Kubernetes Cluster.
         * @param {string} [organizationId] organization_id
         * @param {KubernetesCluster} [kubernetesCluster] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKubernetesCluster: async (id: string, organizationId?: string, kubernetesCluster?: KubernetesCluster, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateKubernetesCluster', 'id', id)
            const localVarPath = `/kubernetes-cluster/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(kubernetesCluster, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Notification.
         * @param {string} [alarmId] alarm_id
         * @param {Notification} [notification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotification: async (id: string, alarmId?: string, notification?: Notification, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateNotification', 'id', id)
            const localVarPath = `/notifications/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (alarmId !== undefined) {
                localVarQueryParameter['alarm_id'] = alarmId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notification, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Notification Channel Setting.
         * @param {string} [organizationId] organization_id
         * @param {NotificationChannelSetting} [notificationChannelSetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotificationChannelSetting: async (id: string, organizationId?: string, notificationChannelSetting?: NotificationChannelSetting, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateNotificationChannelSetting', 'id', id)
            const localVarPath = `/notifications-channels-configurations/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationChannelSetting, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint to manipulate CustomCanaryRestfulLibSource
         * @param {string} id A UUID string identifying this OpenAPI Restful Library.
         * @param {string} [organizationId] organization_id
         * @param {OpenAPIRestfulLibrary} [openAPIRestfulLibrary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOpenAPIRestfulLibrary: async (id: string, organizationId?: string, openAPIRestfulLibrary?: OpenAPIRestfulLibrary, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOpenAPIRestfulLibrary', 'id', id)
            const localVarPath = `/restful-lib/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(openAPIRestfulLibrary, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Organization.
         * @param {Organization} [organization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganization: async (id: string, organization?: Organization, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOrganization', 'id', id)
            const localVarPath = `/organizations/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organization, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} prefix 
         * @param {string} [organizationId] organization_id
         * @param {OrganizationAPIKeyUpdate} [organizationAPIKeyUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganizationAPIKey: async (prefix: string, organizationId?: string, organizationAPIKeyUpdate?: OrganizationAPIKeyUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prefix' is not null or undefined
            assertParamExists('updateOrganizationAPIKey', 'prefix', prefix)
            const localVarPath = `/apikeys/{prefix}/`
                .replace(`{${"prefix"}}`, encodeURIComponent(String(prefix)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationAPIKeyUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * View to manipulate ServiceComplianceColors for an organization
         * @param {string} id A UUID string identifying this Service Compliance Color.
         * @param {string} [organizationId] organization_id
         * @param {ServiceComplianceColors} [serviceComplianceColors] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateServiceComplianceColors: async (id: string, organizationId?: string, serviceComplianceColors?: ServiceComplianceColors, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateServiceComplianceColors', 'id', id)
            const localVarPath = `/service-compliance-colors/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(serviceComplianceColors, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * View to manipulate ServiceHealthColors for an organization
         * @param {string} id A UUID string identifying this Service Health Color.
         * @param {string} [organizationId] organization_id
         * @param {ServiceHealthColors} [serviceHealthColors] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateServiceHealthColors: async (id: string, organizationId?: string, serviceHealthColors?: ServiceHealthColors, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateServiceHealthColors', 'id', id)
            const localVarPath = `/service-health-colors/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(serviceHealthColors, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * View to get ServiceHealthEvaluationSettingsGroup for an organization
         * @param {string} id A UUID string identifying this Service Health Evaluation Settings Group.
         * @param {string} [organizationId] organization_id
         * @param {ServiceHealthEvaluationSettingsGroup} [serviceHealthEvaluationSettingsGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateServiceHealthEvaluationSettingsGroup: async (id: string, organizationId?: string, serviceHealthEvaluationSettingsGroup?: ServiceHealthEvaluationSettingsGroup, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateServiceHealthEvaluationSettingsGroup', 'id', id)
            const localVarPath = `/service-health-evaluation-settings-group/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(serviceHealthEvaluationSettingsGroup, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Simple Alarm.
         * @param {string} [metricId] metric_id
         * @param {string} [canaryId] canary_id
         * @param {string} [taskId] task_id
         * @param {SimpleAlarm} [simpleAlarm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSimpleAlarm: async (id: string, metricId?: string, canaryId?: string, taskId?: string, simpleAlarm?: SimpleAlarm, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSimpleAlarm', 'id', id)
            const localVarPath = `/metricalarm/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (metricId !== undefined) {
                localVarQueryParameter['metric_id'] = metricId;
            }

            if (canaryId !== undefined) {
                localVarQueryParameter['canary_id'] = canaryId;
            }

            if (taskId !== undefined) {
                localVarQueryParameter['task_id'] = taskId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(simpleAlarm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Snowflake Setting.
         * @param {string} [organizationId] organization_id
         * @param {Snowflake} [snowflake] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSnowflake: async (id: string, organizationId?: string, snowflake?: Snowflake, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSnowflake', 'id', id)
            const localVarPath = `/snowflakes/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(snowflake, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Snowflake Forecast Model.
         * @param {string} [organizationId] organization_id
         * @param {SnowflakeForecastModel} [snowflakeForecastModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSnowflakeForecastModel: async (id: string, organizationId?: string, snowflakeForecastModel?: SnowflakeForecastModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSnowflakeForecastModel', 'id', id)
            const localVarPath = `/snowflake-forecast-models/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(snowflakeForecastModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint to manipulate StoreCanary
         * @param {string} id A UUID string identifying this Store Canary.
         * @param {string} [cloud] cloud
         * @param {string} [component] component
         * @param {string} [status] status
         * @param {string} [isPrivate] is_private
         * @param {string} [isPinnedCanary] is_pinned_canary
         * @param {string} [uploadedUser] uploaded_user
         * @param {string} [company] company
         * @param {string} [search] A search term.
         * @param {StoreCanaryWrite} [storeCanaryWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStoreCanary: async (id: string, cloud?: string, component?: string, status?: string, isPrivate?: string, isPinnedCanary?: string, uploadedUser?: string, company?: string, search?: string, storeCanaryWrite?: StoreCanaryWrite, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateStoreCanary', 'id', id)
            const localVarPath = `/canary-store/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (cloud !== undefined) {
                localVarQueryParameter['cloud'] = cloud;
            }

            if (component !== undefined) {
                localVarQueryParameter['component'] = component;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (isPrivate !== undefined) {
                localVarQueryParameter['is_private'] = isPrivate;
            }

            if (isPinnedCanary !== undefined) {
                localVarQueryParameter['is_pinned_canary'] = isPinnedCanary;
            }

            if (uploadedUser !== undefined) {
                localVarQueryParameter['uploaded_user'] = uploadedUser;
            }

            if (company !== undefined) {
                localVarQueryParameter['company'] = company;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storeCanaryWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Subscription Cancel Request.
         * @param {string} [subscriptionId] subscription_id
         * @param {string} [subscriptionUserId] subscription_user_id
         * @param {SubscriptionCancelRequest} [subscriptionCancelRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubscriptionCancelRequest: async (id: string, subscriptionId?: string, subscriptionUserId?: string, subscriptionCancelRequest?: SubscriptionCancelRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSubscriptionCancelRequest', 'id', id)
            const localVarPath = `/subscription-cancel/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subscriptionId !== undefined) {
                localVarQueryParameter['subscription_id'] = subscriptionId;
            }

            if (subscriptionUserId !== undefined) {
                localVarQueryParameter['subscription_user_id'] = subscriptionUserId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subscriptionCancelRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint to manipulate TemplateCanary
         * @param {string} id A UUID string identifying this Template Canary.
         * @param {string} [canaryTemplateLibraryId] canary_template_library_id
         * @param {string} [providerId] provider_id
         * @param {string} [serviceId] service_id
         * @param {TemplateCanaryWritable} [templateCanaryWritable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTemplateCanary: async (id: string, canaryTemplateLibraryId?: string, providerId?: string, serviceId?: string, templateCanaryWritable?: TemplateCanaryWritable, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTemplateCanary', 'id', id)
            const localVarPath = `/template-canaries/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (canaryTemplateLibraryId !== undefined) {
                localVarQueryParameter['canary_template_library_id'] = canaryTemplateLibraryId;
            }

            if (providerId !== undefined) {
                localVarQueryParameter['provider_id'] = providerId;
            }

            if (serviceId !== undefined) {
                localVarQueryParameter['service_id'] = serviceId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(templateCanaryWritable, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint to manipulate TemplateTask
         * @param {string} id A UUID string identifying this Template Task.
         * @param {string} [canaryTemplateLibraryId] canary_template_library_id
         * @param {TemplateTaskReadOnly} [templateTaskReadOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTemplateTask: async (id: string, canaryTemplateLibraryId?: string, templateTaskReadOnly?: TemplateTaskReadOnly, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTemplateTask', 'id', id)
            const localVarPath = `/template-canary-task/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (canaryTemplateLibraryId !== undefined) {
                localVarQueryParameter['canary_template_library_id'] = canaryTemplateLibraryId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(templateTaskReadOnly, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint that allows authenticated clients to view or edit users
         * @param {string} id A UUID string identifying this user.
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (id: string, user?: User, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUser', 'id', id)
            const localVarPath = `/users/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPartialAuthViewSet: async (body?: any, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/update_user_partial/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this User Subscribed Plan.
         * @param {string} [userId] user_id
         * @param {string} [platformActive] platform_active
         * @param {UserSubscribedPlan} [userSubscribedPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserSubscribedPlan: async (id: string, userId?: string, platformActive?: string, userSubscribedPlan?: UserSubscribedPlan, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUserSubscribedPlan', 'id', id)
            const localVarPath = `/user-subscribed-plans/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (platformActive !== undefined) {
                localVarQueryParameter['platform_active'] = platformActive;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userSubscribedPlan, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userProfileAuthViewSet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/user_profile/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateResetTokenAuthViewSet: async (body?: any, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/validate_reset_token/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyEmailAuthViewSet: async (body?: any, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/verify_email/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id A UUID string identifying this Organization.
         * @param {OrganizationMember} [organizationMember] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMemberOrganization(id: string, organizationMember?: OrganizationMember, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationMember>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMemberOrganization(id, organizationMember, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.addMemberOrganization']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this User Subscribed Plan.
         * @param {UserSubscribedPlanAddOrganizations} [userSubscribedPlanAddOrganizations] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOrganizationUserSubscribedPlan(id: string, userSubscribedPlanAddOrganizations?: UserSubscribedPlanAddOrganizations, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSubscribedPlanAddOrganizations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOrganizationUserSubscribedPlan(id, userSubscribedPlanAddOrganizations, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.addOrganizationUserSubscribedPlan']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this User Subscribed Plan.
         * @param {UserSubscribedPlanAssignSubtractCanary} [userSubscribedPlanAssignSubtractCanary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignCanaryUserSubscribedPlan(id: string, userSubscribedPlanAssignSubtractCanary?: UserSubscribedPlanAssignSubtractCanary, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSubscribedPlanAssignSubtractCanary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignCanaryUserSubscribedPlan(id, userSubscribedPlanAssignSubtractCanary, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.assignCanaryUserSubscribedPlan']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint that manages Canarys
         * @param {string} id A UUID string identifying this Canary.
         * @param {CanarySchedule} [canarySchedule] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async canaryAgentStatusCanary(id: string, canarySchedule?: CanarySchedule, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanarySchedule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.canaryAgentStatusCanary(id, canarySchedule, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.canaryAgentStatusCanary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeTempPasswordAuthViewSet(body?: any, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeTempPasswordAuthViewSet(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.changeTempPasswordAuthViewSet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AbstractMetricBoundaryCondition} [abstractMetricBoundaryCondition] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAbstractMetricBoundaryCondition(abstractMetricBoundaryCondition?: AbstractMetricBoundaryCondition, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbstractMetricBoundaryCondition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAbstractMetricBoundaryCondition(abstractMetricBoundaryCondition, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createAbstractMetricBoundaryCondition']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AuthToken} [authToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAuthToken(authToken?: AuthToken, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAuthToken(authToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createAuthToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {BillingDetail} [billingDetail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBillingDetail(billingDetail?: BillingDetail, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBillingDetail(billingDetail, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createBillingDetail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint that manages Canarys
         * @param {Canary} [canary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCanary(canary?: Canary, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Canary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCanary(canary, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createCanary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint to manipulate CustomCanaryPrototypeSource
         * @param {CanaryPrototypeSource} [canaryPrototypeSource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCanaryPrototypeSourceCode(canaryPrototypeSource?: CanaryPrototypeSource, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanaryPrototypeSource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCanaryPrototypeSourceCode(canaryPrototypeSource, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createCanaryPrototypeSourceCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint to manipulate CustomCanaryRestfulLibSource
         * @param {CanaryRestfulLibSourceFile} [canaryRestfulLibSourceFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCanaryRestfulLibSourceCode(canaryRestfulLibSourceFile?: CanaryRestfulLibSourceFile, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanaryRestfulLibSourceFile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCanaryRestfulLibSourceCode(canaryRestfulLibSourceFile, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createCanaryRestfulLibSourceCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get or update a single CanaryTask
         * @param {CanaryTask} [canaryTask] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCanaryTask(canaryTask?: CanaryTask, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanaryTask>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCanaryTask(canaryTask, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createCanaryTask']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint that writes Canary data
         * @param {CanaryTaskRunDataWriteOnly} [canaryTaskRunDataWriteOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCanaryTaskRawData(canaryTaskRunDataWriteOnly?: CanaryTaskRunDataWriteOnly, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanaryTaskRunDataWriteOnly>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCanaryTaskRawData(canaryTaskRunDataWriteOnly, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createCanaryTaskRawData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint to manipulate CanaryTemplateLibrary
         * @param {CanaryTemplateLibrary} [canaryTemplateLibrary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCanaryTemplateLibrary(canaryTemplateLibrary?: CanaryTemplateLibrary, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanaryTemplateLibrary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCanaryTemplateLibrary(canaryTemplateLibrary, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createCanaryTemplateLibrary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ContactRequestWriteOnly} [contactRequestWriteOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createContactRequest(contactRequestWriteOnly?: ContactRequestWriteOnly, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactRequestWriteOnly>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createContactRequest(contactRequestWriteOnly, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createContactRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Expose clusters to users  - important because it shares a security boundary with users - we must allow users to read all platform wide clusters (e.g. ours), but not change anything
         * @param {KubernetesCluster} [kubernetesCluster] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createKubernetesCluster(kubernetesCluster?: KubernetesCluster, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KubernetesCluster>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createKubernetesCluster(kubernetesCluster, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createKubernetesCluster']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Notification} [notification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNotification(notification?: Notification, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Notification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNotification(notification, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createNotification']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {NotificationChannelSetting} [notificationChannelSetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNotificationChannelSetting(notificationChannelSetting?: NotificationChannelSetting, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationChannelSetting>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNotificationChannelSetting(notificationChannelSetting, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createNotificationChannelSetting']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint to manipulate CustomCanaryRestfulLibSource
         * @param {OpenAPIRestfulLibrary} [openAPIRestfulLibrary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOpenAPIRestfulLibrary(openAPIRestfulLibrary?: OpenAPIRestfulLibrary, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpenAPIRestfulLibrary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOpenAPIRestfulLibrary(openAPIRestfulLibrary, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createOpenAPIRestfulLibrary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Organization} [organization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrganization(organization?: Organization, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrganization(organization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createOrganization']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {OrganizationAPIKey} [organizationAPIKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrganizationAPIKey(organizationAPIKey?: OrganizationAPIKey, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationAPIKey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrganizationAPIKey(organizationAPIKey, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createOrganizationAPIKey']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * View to manipulate ServiceComplianceColors for an organization
         * @param {ServiceComplianceColors} [serviceComplianceColors] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createServiceComplianceColors(serviceComplianceColors?: ServiceComplianceColors, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceComplianceColors>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createServiceComplianceColors(serviceComplianceColors, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createServiceComplianceColors']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * View to manipulate ServiceHealthColors for an organization
         * @param {ServiceHealthColors} [serviceHealthColors] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createServiceHealthColors(serviceHealthColors?: ServiceHealthColors, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceHealthColors>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createServiceHealthColors(serviceHealthColors, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createServiceHealthColors']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * View to get ServiceHealthEvaluationSettingsGroup for an organization
         * @param {ServiceHealthEvaluationSettingsGroup} [serviceHealthEvaluationSettingsGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createServiceHealthEvaluationSettingsGroup(serviceHealthEvaluationSettingsGroup?: ServiceHealthEvaluationSettingsGroup, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceHealthEvaluationSettingsGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createServiceHealthEvaluationSettingsGroup(serviceHealthEvaluationSettingsGroup, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createServiceHealthEvaluationSettingsGroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SimpleAlarm} [simpleAlarm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSimpleAlarm(simpleAlarm?: SimpleAlarm, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimpleAlarm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSimpleAlarm(simpleAlarm, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createSimpleAlarm']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Snowflake} [snowflake] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSnowflake(snowflake?: Snowflake, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Snowflake>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSnowflake(snowflake, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createSnowflake']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SnowflakeForecastModel} [snowflakeForecastModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSnowflakeForecastModel(snowflakeForecastModel?: SnowflakeForecastModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SnowflakeForecastModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSnowflakeForecastModel(snowflakeForecastModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createSnowflakeForecastModel']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint to manipulate StoreCanary
         * @param {StoreCanaryWrite} [storeCanaryWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createStoreCanary(storeCanaryWrite?: StoreCanaryWrite, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreCanaryWrite>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createStoreCanary(storeCanaryWrite, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createStoreCanary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SubscriptionCancelRequest} [subscriptionCancelRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSubscriptionCancelRequest(subscriptionCancelRequest?: SubscriptionCancelRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionCancelRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSubscriptionCancelRequest(subscriptionCancelRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createSubscriptionCancelRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint to manipulate TemplateCanary
         * @param {TemplateCanaryWritable} [templateCanaryWritable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTemplateCanary(templateCanaryWritable?: TemplateCanaryWritable, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplateCanaryWritable>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTemplateCanary(templateCanaryWritable, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createTemplateCanary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint to manipulate TemplateTask
         * @param {TemplateTaskReadOnly} [templateTaskReadOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTemplateTask(templateTaskReadOnly?: TemplateTaskReadOnly, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplateTaskReadOnly>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTemplateTask(templateTaskReadOnly, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createTemplateTask']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint that allows authenticated clients to view or edit users
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(user?: User, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(user, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UserGatedContentDownloadRequest} [userGatedContentDownloadRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserGatedContentDownloadRequest(userGatedContentDownloadRequest?: UserGatedContentDownloadRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGatedContentDownloadRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserGatedContentDownloadRequest(userGatedContentDownloadRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createUserGatedContentDownloadRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UserSubscribedPlan} [userSubscribedPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserSubscribedPlan(userSubscribedPlan?: UserSubscribedPlan, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSubscribedPlan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserSubscribedPlan(userSubscribedPlan, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createUserSubscribedPlan']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Abstract Metric Condition.
         * @param {string} [metricId] metric_id
         * @param {string} [canaryId] canary_id
         * @param {string} [taskId] task_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroyAbstractMetricBoundaryCondition(id: string, metricId?: string, canaryId?: string, taskId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroyAbstractMetricBoundaryCondition(id, metricId, canaryId, taskId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.destroyAbstractMetricBoundaryCondition']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this billing detail.
         * @param {string} [userId] user_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroyBillingDetail(id: string, userId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroyBillingDetail(id, userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.destroyBillingDetail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint that manages Canarys
         * @param {string} id A UUID string identifying this Canary.
         * @param {string} [organizationId] organization_id
         * @param {DestroyCanaryStatusEnum} [status] status
         * @param {DestroyCanaryOrderingEnum} [ordering] Ordering
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroyCanary(id: string, organizationId?: string, status?: DestroyCanaryStatusEnum, ordering?: DestroyCanaryOrderingEnum, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroyCanary(id, organizationId, status, ordering, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.destroyCanary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint to manipulate CustomCanaryPrototypeSource
         * @param {string} id A UUID string identifying this Canary Prototype Source Code.
         * @param {string} [canaryTemplateLibraryId] canary_template_library_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroyCanaryPrototypeSourceCode(id: string, canaryTemplateLibraryId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroyCanaryPrototypeSourceCode(id, canaryTemplateLibraryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.destroyCanaryPrototypeSourceCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint to manipulate CustomCanaryRestfulLibSource
         * @param {string} id A UUID string identifying this Canary Restful Library Source Code.
         * @param {string} [restfulLibraryId] restful_library_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroyCanaryRestfulLibSourceCode(id: string, restfulLibraryId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroyCanaryRestfulLibSourceCode(id, restfulLibraryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.destroyCanaryRestfulLibSourceCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get or update a single CanaryTask
         * @param {string} id A UUID string identifying this Canary Task.
         * @param {string} [canaryId] canary_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroyCanaryTask(id: string, canaryId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroyCanaryTask(id, canaryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.destroyCanaryTask']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint to manipulate CanaryTemplateLibrary
         * @param {string} id A UUID string identifying this Canary Template Library.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroyCanaryTemplateLibrary(id: string, organizationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroyCanaryTemplateLibrary(id, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.destroyCanaryTemplateLibrary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Expose clusters to users  - important because it shares a security boundary with users - we must allow users to read all platform wide clusters (e.g. ours), but not change anything
         * @param {string} id A UUID string identifying this Kubernetes Cluster.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroyKubernetesCluster(id: string, organizationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroyKubernetesCluster(id, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.destroyKubernetesCluster']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Notification.
         * @param {string} [alarmId] alarm_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroyNotification(id: string, alarmId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroyNotification(id, alarmId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.destroyNotification']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Notification Channel Setting.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroyNotificationChannelSetting(id: string, organizationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroyNotificationChannelSetting(id, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.destroyNotificationChannelSetting']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint to manipulate CustomCanaryRestfulLibSource
         * @param {string} id A UUID string identifying this OpenAPI Restful Library.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroyOpenAPIRestfulLibrary(id: string, organizationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroyOpenAPIRestfulLibrary(id, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.destroyOpenAPIRestfulLibrary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Organization.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroyOrganization(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroyOrganization(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.destroyOrganization']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} prefix 
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroyOrganizationAPIKey(prefix: string, organizationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroyOrganizationAPIKey(prefix, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.destroyOrganizationAPIKey']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * View to manipulate ServiceComplianceColors for an organization
         * @param {string} id A UUID string identifying this Service Compliance Color.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroyServiceComplianceColors(id: string, organizationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroyServiceComplianceColors(id, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.destroyServiceComplianceColors']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * View to manipulate ServiceHealthColors for an organization
         * @param {string} id A UUID string identifying this Service Health Color.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroyServiceHealthColors(id: string, organizationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroyServiceHealthColors(id, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.destroyServiceHealthColors']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * View to get ServiceHealthEvaluationSettingsGroup for an organization
         * @param {string} id A UUID string identifying this Service Health Evaluation Settings Group.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroyServiceHealthEvaluationSettingsGroup(id: string, organizationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroyServiceHealthEvaluationSettingsGroup(id, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.destroyServiceHealthEvaluationSettingsGroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Simple Alarm.
         * @param {string} [metricId] metric_id
         * @param {string} [canaryId] canary_id
         * @param {string} [taskId] task_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroySimpleAlarm(id: string, metricId?: string, canaryId?: string, taskId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroySimpleAlarm(id, metricId, canaryId, taskId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.destroySimpleAlarm']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Snowflake Setting.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroySnowflake(id: string, organizationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroySnowflake(id, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.destroySnowflake']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Snowflake Forecast Model.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroySnowflakeForecastModel(id: string, organizationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroySnowflakeForecastModel(id, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.destroySnowflakeForecastModel']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint to manipulate StoreCanary
         * @param {string} id A UUID string identifying this Store Canary.
         * @param {string} [cloud] cloud
         * @param {string} [component] component
         * @param {string} [status] status
         * @param {string} [isPrivate] is_private
         * @param {string} [isPinnedCanary] is_pinned_canary
         * @param {string} [uploadedUser] uploaded_user
         * @param {string} [company] company
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroyStoreCanary(id: string, cloud?: string, component?: string, status?: string, isPrivate?: string, isPinnedCanary?: string, uploadedUser?: string, company?: string, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroyStoreCanary(id, cloud, component, status, isPrivate, isPinnedCanary, uploadedUser, company, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.destroyStoreCanary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Subscription Cancel Request.
         * @param {string} [subscriptionId] subscription_id
         * @param {string} [subscriptionUserId] subscription_user_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroySubscriptionCancelRequest(id: string, subscriptionId?: string, subscriptionUserId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroySubscriptionCancelRequest(id, subscriptionId, subscriptionUserId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.destroySubscriptionCancelRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint to manipulate TemplateCanary
         * @param {string} id A UUID string identifying this Template Canary.
         * @param {string} [canaryTemplateLibraryId] canary_template_library_id
         * @param {string} [providerId] provider_id
         * @param {string} [serviceId] service_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroyTemplateCanary(id: string, canaryTemplateLibraryId?: string, providerId?: string, serviceId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroyTemplateCanary(id, canaryTemplateLibraryId, providerId, serviceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.destroyTemplateCanary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint to manipulate TemplateTask
         * @param {string} id A UUID string identifying this Template Task.
         * @param {string} [canaryTemplateLibraryId] canary_template_library_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroyTemplateTask(id: string, canaryTemplateLibraryId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroyTemplateTask(id, canaryTemplateLibraryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.destroyTemplateTask']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint that allows authenticated clients to view or edit users
         * @param {string} id A UUID string identifying this user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroyUser(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroyUser(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.destroyUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this User Subscribed Plan.
         * @param {string} [userId] user_id
         * @param {string} [platformActive] platform_active
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroyUserSubscribedPlan(id: string, userId?: string, platformActive?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroyUserSubscribedPlan(id, userId, platformActive, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.destroyUserSubscribedPlan']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadCloudCanariesArticleUserGatedContentDownloadFileReadOnly(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadCloudCanariesArticleUserGatedContentDownloadFileReadOnly(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.downloadCloudCanariesArticleUserGatedContentDownloadFileReadOnly']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint to manipulate CustomCanaryRestfulLibSource
         * @param {string} id A UUID string identifying this OpenAPI Restful Library.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadRestfulZipOpenAPIRestfulLibrary(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadRestfulZipOpenAPIRestfulLibrary(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.downloadRestfulZipOpenAPIRestfulLibrary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint to manipulate CustomCanaryRestfulLibSource
         * @param {string} id A UUID string identifying this OpenAPI Restful Library.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadTemplateZipOpenAPIRestfulLibrary(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadTemplateZipOpenAPIRestfulLibrary(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.downloadTemplateZipOpenAPIRestfulLibrary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint that manages Canarys
         * @param {string} id A UUID string identifying this Canary.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateAndDownloadCanaryAgentCanary(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateAndDownloadCanaryAgentCanary(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.generateAndDownloadCanaryAgentCanary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {OrganizationAPIKeyContents} [organizationAPIKeyContents] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateKeyOrganizationAPIKey(organizationAPIKeyContents?: OrganizationAPIKeyContents, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationAPIKeyContents>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateKeyOrganizationAPIKey(organizationAPIKeyContents, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.generateKeyOrganizationAPIKey']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [metricId] metric_id
         * @param {string} [canaryId] canary_id
         * @param {string} [taskId] task_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAbstractMetricBoundaryConditions(page?: number, pageSize?: number, metricId?: string, canaryId?: string, taskId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAbstractMetricBoundaryConditions200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAbstractMetricBoundaryConditions(page, pageSize, metricId, canaryId, taskId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listAbstractMetricBoundaryConditions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [canaryId] canary_id
         * @param {string} [taskId] task_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAbstractMetrics(page?: number, pageSize?: number, canaryId?: string, taskId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAbstractMetrics200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAbstractMetrics(page, pageSize, canaryId, taskId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listAbstractMetrics']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [userId] user_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listBillingDetails(page?: number, pageSize?: number, userId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListBillingDetails200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listBillingDetails(page, pageSize, userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listBillingDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint that manages Canarys
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] organization_id
         * @param {ListCanariesStatusEnum} [status] status
         * @param {ListCanariesOrderingEnum} [ordering] Ordering
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCanaries(page?: number, pageSize?: number, organizationId?: string, status?: ListCanariesStatusEnum, ordering?: ListCanariesOrderingEnum, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCanaries200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCanaries(page, pageSize, organizationId, status, ordering, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listCanaries']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A readonly ViewSet for viewing CanaryDailyForecast\'s
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCanaryDailyForecasts(page?: number, pageSize?: number, start?: string, stop?: string, organizationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCanaryDailyForecasts200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCanaryDailyForecasts(page, pageSize, start, stop, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listCanaryDailyForecasts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [isActive] is_active
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCanaryPartners(page?: number, pageSize?: number, isActive?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCanaryPartners200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCanaryPartners(page, pageSize, isActive, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listCanaryPartners']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint to manipulate CustomCanaryPrototypeSource
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [canaryTemplateLibraryId] canary_template_library_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCanaryPrototypeSourceCodes(page?: number, pageSize?: number, canaryTemplateLibraryId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCanaryPrototypeSourceCodes200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCanaryPrototypeSourceCodes(page, pageSize, canaryTemplateLibraryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listCanaryPrototypeSourceCodes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint to manipulate CustomCanaryRestfulLibSource
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [restfulLibraryId] restful_library_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCanaryRestfulLibSourceCodes(page?: number, pageSize?: number, restfulLibraryId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCanaryRestfulLibSourceCodes200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCanaryRestfulLibSourceCodes(page, pageSize, restfulLibraryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listCanaryRestfulLibSourceCodes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [measuredFrom] measured_from
         * @param {string} [measuredUntil] measured_until
         * @param {string} [metricId] metric_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCanaryTaskMetricData(page?: number, pageSize?: number, measuredFrom?: string, measuredUntil?: string, metricId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCanaryTaskMetricData200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCanaryTaskMetricData(page, pageSize, measuredFrom, measuredUntil, metricId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listCanaryTaskMetricData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [measuredFrom] The Start date of the data export period, should be in this format: YYYY-MM-DD+HH%3AMM, note that %3A means \&#39;:\&#39; so don\&#39;t remove it.
         * @param {string} [measuredUntil] The End date of the data export period, should be in this format: YYYY-MM-DD+HH%3AMM, note that %3A means \&#39;:\&#39; so don\&#39;t remove it.
         * @param {string} [canaryId] The Canary id to get the exported data for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCanaryTaskRunDataExports(page?: number, pageSize?: number, measuredFrom?: string, measuredUntil?: string, canaryId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCanaryTaskRunDataExports200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCanaryTaskRunDataExports(page, pageSize, measuredFrom, measuredUntil, canaryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listCanaryTaskRunDataExports']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * View to get CanaryTaskData as a CSV Export  * Requires token authentication.
         * @param {string} [measuredFrom] The Start date of the data export period, should be in this format: YYYY-MM-DD+HH%3AMM, note that %3A means \&#39;:\&#39; so don\&#39;t remove it.
         * @param {string} [measuredUntil] The End date of the data export period, should be in this format: YYYY-MM-DD+HH%3AMM, note that %3A means \&#39;:\&#39; so don\&#39;t remove it.
         * @param {string} [canaryId] The Canary id to get the exported data for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCanaryTaskRunDataExports_1(measuredFrom?: string, measuredUntil?: string, canaryId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CanaryTaskRunDataExportReadOnly>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCanaryTaskRunDataExports_1(measuredFrom, measuredUntil, canaryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listCanaryTaskRunDataExports_1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get or update a single CanaryTask
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [canaryId] canary_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCanaryTasks(page?: number, pageSize?: number, canaryId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCanaryTasks200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCanaryTasks(page, pageSize, canaryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listCanaryTasks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint to manipulate CanaryTemplateLibrary
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCanaryTemplateLibraries(page?: number, pageSize?: number, organizationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCanaryTemplateLibraries200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCanaryTemplateLibraries(page, pageSize, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listCanaryTemplateLibraries']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [isActive] is_active
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCanaryUsers(page?: number, pageSize?: number, isActive?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCanaryUsers200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCanaryUsers(page, pageSize, isActive, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listCanaryUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A readonly ViewSet for viewing Canaryable Services.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [providerId] provider_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCanaryableServices(page?: number, pageSize?: number, providerId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCanaryableServices200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCanaryableServices(page, pageSize, providerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listCanaryableServices']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A readonly ViewSet for viewing CompositeDailyForecasts Services.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCompositeDailyForecasts(page?: number, pageSize?: number, start?: string, stop?: string, organizationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCompositeDailyForecasts200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCompositeDailyForecasts(page, pageSize, start, stop, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listCompositeDailyForecasts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCustomUserRoles(page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCustomUserRoles200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCustomUserRoles(page, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listCustomUserRoles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Expose clusters to users  - important because it shares a security boundary with users - we must allow users to read all platform wide clusters (e.g. ours), but not change anything
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listKubernetesClusters(page?: number, pageSize?: number, organizationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListKubernetesClusters200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listKubernetesClusters(page, pageSize, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listKubernetesClusters']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [isActive] is_active
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listLandingPageVideos(page?: number, pageSize?: number, isActive?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListLandingPageVideos200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listLandingPageVideos(page, pageSize, isActive, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listLandingPageVideos']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A readonly ViewSet for viewing NeuralProphetCanaryDailyForecastData\'s
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {string} [canaryId] canary_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listNeuralProphetCanaryDailyForecastData(page?: number, pageSize?: number, start?: string, stop?: string, organizationId?: string, canaryId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListNeuralProphetCanaryDailyForecastData200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listNeuralProphetCanaryDailyForecastData(page, pageSize, start, stop, organizationId, canaryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listNeuralProphetCanaryDailyForecastData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * View to get NeuralServiceDailyForecast as a CSV Export  * Requires token authentication.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [canaryId] canary_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listNeuralProphetCanaryDailyForecastDataExports(start?: string, stop?: string, canaryId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NeuralCanaryDailyForecast>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listNeuralProphetCanaryDailyForecastDataExports(start, stop, canaryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listNeuralProphetCanaryDailyForecastDataExports']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A readonly ViewSet for viewing NeuralProphetCanaryDailyForecast\'s
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {string} [canaryId] canary_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listNeuralProphetCanaryDailyForecasts(page?: number, pageSize?: number, start?: string, stop?: string, organizationId?: string, canaryId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListNeuralProphetCanaryDailyForecasts200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listNeuralProphetCanaryDailyForecasts(page, pageSize, start, stop, organizationId, canaryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listNeuralProphetCanaryDailyForecasts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A readonly ViewSet for viewing NeuralProphetCompositeDailyForecast Services.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listNeuralProphetCompositeDailyForecasts(page?: number, pageSize?: number, start?: string, stop?: string, organizationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListNeuralProphetCompositeDailyForecasts200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listNeuralProphetCompositeDailyForecasts(page, pageSize, start, stop, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listNeuralProphetCompositeDailyForecasts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A readonly ViewSet for viewing NeuralProphetServiceDailyForecast
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listNeuralProphetServiceDailyForecasts(page?: number, pageSize?: number, start?: string, stop?: string, organizationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListNeuralProphetServiceDailyForecasts200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listNeuralProphetServiceDailyForecasts(page, pageSize, start, stop, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listNeuralProphetServiceDailyForecasts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listNotificationChannelSettings(page?: number, pageSize?: number, organizationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListNotificationChannelSettings200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listNotificationChannelSettings(page, pageSize, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listNotificationChannelSettings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [alarmId] alarm_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listNotificationHistories(page?: number, pageSize?: number, alarmId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListNotificationHistories200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listNotificationHistories(page, pageSize, alarmId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listNotificationHistories']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [alarmId] alarm_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listNotifications(page?: number, pageSize?: number, alarmId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListNotifications200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listNotifications(page, pageSize, alarmId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listNotifications']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint to manipulate CustomCanaryRestfulLibSource
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listOpenAPIRestfulLibraries(page?: number, pageSize?: number, organizationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListOpenAPIRestfulLibraries200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listOpenAPIRestfulLibraries(page, pageSize, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listOpenAPIRestfulLibraries']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listOrganizationAPIKeys(page?: number, pageSize?: number, organizationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListOrganizationAPIKeys200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listOrganizationAPIKeys(page, pageSize, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listOrganizationAPIKeys']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listOrganizations(page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListOrganizations200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listOrganizations(page, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listOrganizations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint that gets the Canaries * Requires token authentication.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] The Organization id to filter with
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPublicAPICanaries(page?: number, pageSize?: number, organizationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListPublicAPICanaries200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPublicAPICanaries(page, pageSize, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listPublicAPICanaries']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * View to get CanaryTaskData as a CSV Export  * Requires token authentication.
         * @param {string} [measuredFrom] The Start date of the data export period, should be in this format: YYYY-MM-DD+HH%3AMM, note that %3A means \&#39;:\&#39; so don\&#39;t remove it.
         * @param {string} [measuredUntil] The End date of the data export period, should be in this format: YYYY-MM-DD+HH%3AMM, note that %3A means \&#39;:\&#39; so don\&#39;t remove it.
         * @param {string} [canaryId] The Canary id to get the exported data for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPublicAPICanaryTaskRunDataExports(measuredFrom?: string, measuredUntil?: string, canaryId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CanaryTaskRunDataExportReadOnly>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPublicAPICanaryTaskRunDataExports(measuredFrom, measuredUntil, canaryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listPublicAPICanaryTaskRunDataExports']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A readonly ViewSet for viewing Canaryable Services.  * Requires token authentication.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [providerId] The Provider id to filter with
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPublicAPICanaryableServices(page?: number, pageSize?: number, providerId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListPublicAPICanaryableServices200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPublicAPICanaryableServices(page, pageSize, providerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listPublicAPICanaryableServices']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A readonly ViewSet for viewing NeuralProphetCanaryDailyForecast\'s
         * @param {string} [canaryId] The Canary ID to filter with
         * @param {string} [organizationId] The Organization ID to filter with
         * @param {string} [start] The Start date of the forecasting period, should be the next day date and in this format: YYYY-MM-DD
         * @param {string} [stop] The End date of the forecasting period, should be after 5 days and in this format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPublicAPINeuralProphetCanaryDailyForecasts(canaryId?: string, organizationId?: string, start?: string, stop?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PublicAPINeuralCanaryDailyForecast>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPublicAPINeuralProphetCanaryDailyForecasts(canaryId, organizationId, start, stop, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listPublicAPINeuralProphetCanaryDailyForecasts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A readonly ViewSet for viewing Incident probability in the next 24 hours, should return 1 entry for the next day entered date.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] The Organization ID to filter with
         * @param {string} [start] The Start date of the forecasting period, should be the next day date and in this format: YYYY-MM-DD
         * @param {string} [stop] The End date of the forecasting period, should be the next day date and in this format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPublicAPINeuralProphetCompositeDailyForecasts(page?: number, pageSize?: number, organizationId?: string, start?: string, stop?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListPublicAPINeuralProphetCompositeDailyForecasts200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPublicAPINeuralProphetCompositeDailyForecasts(page, pageSize, organizationId, start, stop, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listPublicAPINeuralProphetCompositeDailyForecasts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * View to get Organizations for a user  * Requires token authentication.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPublicAPIOrganizations(page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListPublicAPIOrganizations200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPublicAPIOrganizations(page, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listPublicAPIOrganizations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A readonly ViewSet for viewing ServiceCompliance Summaries.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] The Organization id to filter with
         * @param {ListPublicAPIServiceComplianceSummariesPeriodCategoryEnum} [periodCategory] The Period Category to filter with, options are: \&#39;weekly\&#39;, \&#39;monthly\&#39;, \&#39;quarterly\&#39;, \&#39;yearly\&#39;
         * @param {string} [start] The Start date of the compliance period, should be in this format: YYYY-MM-DD
         * @param {string} [stop] The Stop date of the compliance period, should be in this format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPublicAPIServiceComplianceSummaries(page?: number, pageSize?: number, organizationId?: string, periodCategory?: ListPublicAPIServiceComplianceSummariesPeriodCategoryEnum, start?: string, stop?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListPublicAPIServiceComplianceSummaries200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPublicAPIServiceComplianceSummaries(page, pageSize, organizationId, periodCategory, start, stop, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listPublicAPIServiceComplianceSummaries']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * View to get ServiceHealth of a group of canaries * Requires token authentication.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] The Organization id to filter with
         * @param {string} [serviceId] The Service id to filter with
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPublicAPIServiceHealths(page?: number, pageSize?: number, organizationId?: string, serviceId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListPublicAPIServiceHealths200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPublicAPIServiceHealths(page, pageSize, organizationId, serviceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listPublicAPIServiceHealths']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A readonly ViewSet for viewing ServiceProviders.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPublicAPIServiceProviders(page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListPublicAPIServiceProviders200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPublicAPIServiceProviders(page, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listPublicAPIServiceProviders']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * View to manipulate ServiceComplianceColors for an organization
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listServiceComplianceColors(organizationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServiceComplianceColors>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listServiceComplianceColors(organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listServiceComplianceColors']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A readonly ViewSet for viewing ServiceCompliance Histories
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] organization_id
         * @param {string} [stop] stop
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listServiceComplianceHistories(page?: number, pageSize?: number, organizationId?: string, stop?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListServiceComplianceHistories200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listServiceComplianceHistories(page, pageSize, organizationId, stop, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listServiceComplianceHistories']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A readonly ViewSet for viewing ServiceCompliance Summaries.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] organization_id
         * @param {ListServiceComplianceSummariesPeriodCategoryEnum} [periodCategory] period_category
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listServiceComplianceSummaries(page?: number, pageSize?: number, organizationId?: string, periodCategory?: ListServiceComplianceSummariesPeriodCategoryEnum, start?: string, stop?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListServiceComplianceSummaries200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listServiceComplianceSummaries(page, pageSize, organizationId, periodCategory, start, stop, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listServiceComplianceSummaries']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A readonly ViewSet for viewing ServiceDailyForecasts
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listServiceDailyForecasts(page?: number, pageSize?: number, start?: string, stop?: string, organizationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListServiceDailyForecasts200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listServiceDailyForecasts(page, pageSize, start, stop, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listServiceDailyForecasts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * View to manipulate ServiceHealthColors for an organization
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listServiceHealthColors(organizationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServiceHealthColors>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listServiceHealthColors(organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listServiceHealthColors']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * View to get ServiceHealthEvaluationSettingsGroup for an organization
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listServiceHealthEvaluationSettingsGroups(page?: number, pageSize?: number, organizationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListServiceHealthEvaluationSettingsGroups200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listServiceHealthEvaluationSettingsGroups(page, pageSize, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listServiceHealthEvaluationSettingsGroups']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] organization_id
         * @param {string} [serviceId] service_id
         * @param {ListServiceHealthIncidentsIncidentStatusEnum} [incidentStatus] incident_status
         * @param {ListServiceHealthIncidentsOrderingEnum} [ordering] Ordering
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listServiceHealthIncidents(page?: number, pageSize?: number, organizationId?: string, serviceId?: string, incidentStatus?: ListServiceHealthIncidentsIncidentStatusEnum, ordering?: ListServiceHealthIncidentsOrderingEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListServiceHealthIncidents200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listServiceHealthIncidents(page, pageSize, organizationId, serviceId, incidentStatus, ordering, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listServiceHealthIncidents']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * View to get ServiceHealth of a group of canaries
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] organization_id
         * @param {string} [providerId] provider_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listServiceHealths(page?: number, pageSize?: number, organizationId?: string, providerId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListServiceHealths200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listServiceHealths(page, pageSize, organizationId, providerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listServiceHealths']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A readonly ViewSet for viewing ServiceProviderAvailabilityZone\'s.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [providerId] provider_id
         * @param {string} [regionId] region_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listServiceProviderAvailabilityZones(page?: number, pageSize?: number, providerId?: string, regionId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListServiceProviderAvailabilityZones200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listServiceProviderAvailabilityZones(page, pageSize, providerId, regionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listServiceProviderAvailabilityZones']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A readonly ViewSet for viewing ServiceProviderRegion\'s.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [providerId] provider_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listServiceProviderRegions(page?: number, pageSize?: number, providerId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListServiceProviderRegions200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listServiceProviderRegions(page, pageSize, providerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listServiceProviderRegions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A readonly ViewSet for viewing ServiceProviders.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listServiceProviders(page?: number, pageSize?: number, organizationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListServiceProviders200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listServiceProviders(page, pageSize, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listServiceProviders']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [alarmId] alarm_id
         * @param {string} [periodStart] period_start
         * @param {string} [periodStop] period_stop
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSimpleAlarmEvaluations(page?: number, pageSize?: number, alarmId?: string, periodStart?: string, periodStop?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListSimpleAlarmEvaluations200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSimpleAlarmEvaluations(page, pageSize, alarmId, periodStart, periodStop, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listSimpleAlarmEvaluations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [alarmId] alarm_id
         * @param {string} [evaluationPeriodStart] evaluation_period_start
         * @param {string} [evaluationPeriodStop] evaluation_period_stop
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSimpleAlarmHistories(page?: number, pageSize?: number, alarmId?: string, evaluationPeriodStart?: string, evaluationPeriodStop?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListSimpleAlarmHistories200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSimpleAlarmHistories(page, pageSize, alarmId, evaluationPeriodStart, evaluationPeriodStop, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listSimpleAlarmHistories']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [metricId] metric_id
         * @param {string} [canaryId] canary_id
         * @param {string} [taskId] task_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSimpleAlarms(page?: number, pageSize?: number, metricId?: string, canaryId?: string, taskId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListSimpleAlarms200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSimpleAlarms(page, pageSize, metricId, canaryId, taskId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listSimpleAlarms']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A readonly ViewSet for viewing SnowflakeCanaryDailyForecastData Services.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {string} [canaryId] canary_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSnowflakeCanaryDailyForecastData(page?: number, pageSize?: number, start?: string, stop?: string, organizationId?: string, canaryId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListSnowflakeCanaryDailyForecastData200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSnowflakeCanaryDailyForecastData(page, pageSize, start, stop, organizationId, canaryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listSnowflakeCanaryDailyForecastData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A readonly ViewSet for viewing SnowflakeCanaryDailyForecast\'s
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {string} [canaryId] canary_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSnowflakeCanaryDailyForecasts(page?: number, pageSize?: number, start?: string, stop?: string, organizationId?: string, canaryId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListSnowflakeCanaryDailyForecasts200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSnowflakeCanaryDailyForecasts(page, pageSize, start, stop, organizationId, canaryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listSnowflakeCanaryDailyForecasts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A readonly ViewSet for viewing SnowflakeCompositeDailyForecast Services.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSnowflakeCompositeDailyForecasts(page?: number, pageSize?: number, start?: string, stop?: string, organizationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListSnowflakeCompositeDailyForecasts200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSnowflakeCompositeDailyForecasts(page, pageSize, start, stop, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listSnowflakeCompositeDailyForecasts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSnowflakeForecastModels(page?: number, pageSize?: number, organizationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListSnowflakeForecastModels200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSnowflakeForecastModels(page, pageSize, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listSnowflakeForecastModels']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A readonly ViewSet for viewing SnowflakeServiceDailyForecast
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSnowflakeServiceDailyForecasts(page?: number, pageSize?: number, start?: string, stop?: string, organizationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListSnowflakeServiceDailyForecasts200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSnowflakeServiceDailyForecasts(page, pageSize, start, stop, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listSnowflakeServiceDailyForecasts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSnowflakes(page?: number, pageSize?: number, organizationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListSnowflakes200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSnowflakes(page, pageSize, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listSnowflakes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint to manipulate StoreCanary
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [cloud] cloud
         * @param {string} [component] component
         * @param {string} [status] status
         * @param {string} [isPrivate] is_private
         * @param {string} [isPinnedCanary] is_pinned_canary
         * @param {string} [uploadedUser] uploaded_user
         * @param {string} [company] company
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listStoreCanaries(page?: number, pageSize?: number, cloud?: string, component?: string, status?: string, isPrivate?: string, isPinnedCanary?: string, uploadedUser?: string, company?: string, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListStoreCanaries200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listStoreCanaries(page, pageSize, cloud, component, status, isPrivate, isPinnedCanary, uploadedUser, company, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listStoreCanaries']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listStoreCanaryClouds(page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListStoreCanaryClouds200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listStoreCanaryClouds(page, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listStoreCanaryClouds']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listStoreCanaryComponents(page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListStoreCanaryComponents200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listStoreCanaryComponents(page, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listStoreCanaryComponents']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listStoreCanaryProducts(page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListStoreCanaryProducts200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listStoreCanaryProducts(page, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listStoreCanaryProducts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [subscriptionId] subscription_id
         * @param {string} [subscriptionUserId] subscription_user_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSubscriptionCancelRequests(page?: number, pageSize?: number, subscriptionId?: string, subscriptionUserId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListSubscriptionCancelRequests200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSubscriptionCancelRequests(page, pageSize, subscriptionId, subscriptionUserId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listSubscriptionCancelRequests']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint to manipulate TemplateCanary
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [canaryTemplateLibraryId] canary_template_library_id
         * @param {string} [providerId] provider_id
         * @param {string} [serviceId] service_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTemplateCanaries(page?: number, pageSize?: number, canaryTemplateLibraryId?: string, providerId?: string, serviceId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListTemplateCanaries200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTemplateCanaries(page, pageSize, canaryTemplateLibraryId, providerId, serviceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listTemplateCanaries']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint to manipulate TemplateTask
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [canaryTemplateLibraryId] canary_template_library_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTemplateTasks(page?: number, pageSize?: number, canaryTemplateLibraryId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListTemplateTasks200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTemplateTasks(page, pageSize, canaryTemplateLibraryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listTemplateTasks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUserGatedContentDownloadRequests(page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListUserGatedContentDownloadRequests200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUserGatedContentDownloadRequests(page, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listUserGatedContentDownloadRequests']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [userId] user_id
         * @param {string} [platformActive] platform_active
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUserSubscribedPlans(page?: number, pageSize?: number, userId?: string, platformActive?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListUserSubscribedPlans200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUserSubscribedPlans(page, pageSize, userId, platformActive, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listUserSubscribedPlans']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint that allows authenticated clients to view or edit users
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUsers(page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListUsers200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUsers(page, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint to manipulate StoreCanary
         * @param {string} id A UUID string identifying this Store Canary.
         * @param {StoreCanaryAutoLoadCanaryTemplateLibraryReadOnly} [storeCanaryAutoLoadCanaryTemplateLibraryReadOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadTemplateLibrariesStoreCanary(id: string, storeCanaryAutoLoadCanaryTemplateLibraryReadOnly?: StoreCanaryAutoLoadCanaryTemplateLibraryReadOnly, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreCanaryAutoLoadCanaryTemplateLibraryReadOnly>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadTemplateLibrariesStoreCanary(id, storeCanaryAutoLoadCanaryTemplateLibraryReadOnly, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.loadTemplateLibrariesStoreCanary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logoutUserAuthViewSet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logoutUserAuthViewSet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.logoutUserAuthViewSet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Abstract Metric Condition.
         * @param {string} [metricId] metric_id
         * @param {string} [canaryId] canary_id
         * @param {string} [taskId] task_id
         * @param {AbstractMetricBoundaryCondition} [abstractMetricBoundaryCondition] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateAbstractMetricBoundaryCondition(id: string, metricId?: string, canaryId?: string, taskId?: string, abstractMetricBoundaryCondition?: AbstractMetricBoundaryCondition, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbstractMetricBoundaryCondition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateAbstractMetricBoundaryCondition(id, metricId, canaryId, taskId, abstractMetricBoundaryCondition, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.partialUpdateAbstractMetricBoundaryCondition']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this billing detail.
         * @param {string} [userId] user_id
         * @param {BillingDetail} [billingDetail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateBillingDetail(id: string, userId?: string, billingDetail?: BillingDetail, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateBillingDetail(id, userId, billingDetail, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.partialUpdateBillingDetail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint that manages Canarys
         * @param {string} id A UUID string identifying this Canary.
         * @param {string} [organizationId] organization_id
         * @param {PartialUpdateCanaryStatusEnum} [status] status
         * @param {PartialUpdateCanaryOrderingEnum} [ordering] Ordering
         * @param {string} [search] A search term.
         * @param {Canary} [canary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateCanary(id: string, organizationId?: string, status?: PartialUpdateCanaryStatusEnum, ordering?: PartialUpdateCanaryOrderingEnum, search?: string, canary?: Canary, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Canary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateCanary(id, organizationId, status, ordering, search, canary, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.partialUpdateCanary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this canary cluster deployment.
         * @param {CanaryClusterDeployment} [canaryClusterDeployment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateCanaryClusterDeployment(id: string, canaryClusterDeployment?: CanaryClusterDeployment, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanaryClusterDeployment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateCanaryClusterDeployment(id, canaryClusterDeployment, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.partialUpdateCanaryClusterDeployment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint to manipulate CustomCanaryPrototypeSource
         * @param {string} id A UUID string identifying this Canary Prototype Source Code.
         * @param {string} [canaryTemplateLibraryId] canary_template_library_id
         * @param {CanaryPrototypeSource} [canaryPrototypeSource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateCanaryPrototypeSourceCode(id: string, canaryTemplateLibraryId?: string, canaryPrototypeSource?: CanaryPrototypeSource, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanaryPrototypeSource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateCanaryPrototypeSourceCode(id, canaryTemplateLibraryId, canaryPrototypeSource, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.partialUpdateCanaryPrototypeSourceCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint to manipulate CustomCanaryRestfulLibSource
         * @param {string} id A UUID string identifying this Canary Restful Library Source Code.
         * @param {string} [restfulLibraryId] restful_library_id
         * @param {CanaryRestfulLibSourceFile} [canaryRestfulLibSourceFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateCanaryRestfulLibSourceCode(id: string, restfulLibraryId?: string, canaryRestfulLibSourceFile?: CanaryRestfulLibSourceFile, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanaryRestfulLibSourceFile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateCanaryRestfulLibSourceCode(id, restfulLibraryId, canaryRestfulLibSourceFile, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.partialUpdateCanaryRestfulLibSourceCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get or update a single CanaryTask
         * @param {string} id A UUID string identifying this Canary Task.
         * @param {string} [canaryId] canary_id
         * @param {CanaryTask} [canaryTask] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateCanaryTask(id: string, canaryId?: string, canaryTask?: CanaryTask, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanaryTask>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateCanaryTask(id, canaryId, canaryTask, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.partialUpdateCanaryTask']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get or update a single CanaryTask
         * @param {string} id A UUID string identifying this canary task env vars.
         * @param {CanaryTaskEnvVars} [canaryTaskEnvVars] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateCanaryTaskEnvVars(id: string, canaryTaskEnvVars?: CanaryTaskEnvVars, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanaryTaskEnvVars>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateCanaryTaskEnvVars(id, canaryTaskEnvVars, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.partialUpdateCanaryTaskEnvVars']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint to manipulate CanaryTemplateLibrary
         * @param {string} id A UUID string identifying this Canary Template Library.
         * @param {string} [organizationId] organization_id
         * @param {CanaryTemplateLibrary} [canaryTemplateLibrary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateCanaryTemplateLibrary(id: string, organizationId?: string, canaryTemplateLibrary?: CanaryTemplateLibrary, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanaryTemplateLibrary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateCanaryTemplateLibrary(id, organizationId, canaryTemplateLibrary, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.partialUpdateCanaryTemplateLibrary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Expose clusters to users  - important because it shares a security boundary with users - we must allow users to read all platform wide clusters (e.g. ours), but not change anything
         * @param {string} id A UUID string identifying this Kubernetes Cluster.
         * @param {string} [organizationId] organization_id
         * @param {KubernetesCluster} [kubernetesCluster] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateKubernetesCluster(id: string, organizationId?: string, kubernetesCluster?: KubernetesCluster, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KubernetesCluster>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateKubernetesCluster(id, organizationId, kubernetesCluster, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.partialUpdateKubernetesCluster']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Notification.
         * @param {string} [alarmId] alarm_id
         * @param {Notification} [notification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateNotification(id: string, alarmId?: string, notification?: Notification, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Notification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateNotification(id, alarmId, notification, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.partialUpdateNotification']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Notification Channel Setting.
         * @param {string} [organizationId] organization_id
         * @param {NotificationChannelSetting} [notificationChannelSetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateNotificationChannelSetting(id: string, organizationId?: string, notificationChannelSetting?: NotificationChannelSetting, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationChannelSetting>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateNotificationChannelSetting(id, organizationId, notificationChannelSetting, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.partialUpdateNotificationChannelSetting']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint to manipulate CustomCanaryRestfulLibSource
         * @param {string} id A UUID string identifying this OpenAPI Restful Library.
         * @param {string} [organizationId] organization_id
         * @param {OpenAPIRestfulLibrary} [openAPIRestfulLibrary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateOpenAPIRestfulLibrary(id: string, organizationId?: string, openAPIRestfulLibrary?: OpenAPIRestfulLibrary, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpenAPIRestfulLibrary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateOpenAPIRestfulLibrary(id, organizationId, openAPIRestfulLibrary, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.partialUpdateOpenAPIRestfulLibrary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Organization.
         * @param {OrganizationUpdate} [organizationUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateOrganization(id: string, organizationUpdate?: OrganizationUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationUpdate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateOrganization(id, organizationUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.partialUpdateOrganization']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} prefix 
         * @param {string} [organizationId] organization_id
         * @param {OrganizationAPIKey} [organizationAPIKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateOrganizationAPIKey(prefix: string, organizationId?: string, organizationAPIKey?: OrganizationAPIKey, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationAPIKey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateOrganizationAPIKey(prefix, organizationId, organizationAPIKey, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.partialUpdateOrganizationAPIKey']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * View to manipulate ServiceComplianceColors for an organization
         * @param {string} id A UUID string identifying this Service Compliance Color.
         * @param {string} [organizationId] organization_id
         * @param {ServiceComplianceColors} [serviceComplianceColors] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateServiceComplianceColors(id: string, organizationId?: string, serviceComplianceColors?: ServiceComplianceColors, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceComplianceColors>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateServiceComplianceColors(id, organizationId, serviceComplianceColors, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.partialUpdateServiceComplianceColors']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * View to manipulate ServiceHealthColors for an organization
         * @param {string} id A UUID string identifying this Service Health Color.
         * @param {string} [organizationId] organization_id
         * @param {ServiceHealthColors} [serviceHealthColors] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateServiceHealthColors(id: string, organizationId?: string, serviceHealthColors?: ServiceHealthColors, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceHealthColors>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateServiceHealthColors(id, organizationId, serviceHealthColors, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.partialUpdateServiceHealthColors']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * View to get ServiceHealthEvaluationSettingsGroup for an organization
         * @param {string} id A UUID string identifying this Service Health Evaluation Settings Group.
         * @param {string} [organizationId] organization_id
         * @param {ServiceHealthEvaluationSettingsGroup} [serviceHealthEvaluationSettingsGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateServiceHealthEvaluationSettingsGroup(id: string, organizationId?: string, serviceHealthEvaluationSettingsGroup?: ServiceHealthEvaluationSettingsGroup, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceHealthEvaluationSettingsGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateServiceHealthEvaluationSettingsGroup(id, organizationId, serviceHealthEvaluationSettingsGroup, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.partialUpdateServiceHealthEvaluationSettingsGroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Simple Alarm.
         * @param {string} [metricId] metric_id
         * @param {string} [canaryId] canary_id
         * @param {string} [taskId] task_id
         * @param {SimpleAlarm} [simpleAlarm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateSimpleAlarm(id: string, metricId?: string, canaryId?: string, taskId?: string, simpleAlarm?: SimpleAlarm, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimpleAlarm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateSimpleAlarm(id, metricId, canaryId, taskId, simpleAlarm, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.partialUpdateSimpleAlarm']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Snowflake Setting.
         * @param {string} [organizationId] organization_id
         * @param {Snowflake} [snowflake] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateSnowflake(id: string, organizationId?: string, snowflake?: Snowflake, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Snowflake>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateSnowflake(id, organizationId, snowflake, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.partialUpdateSnowflake']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Snowflake Forecast Model.
         * @param {string} [organizationId] organization_id
         * @param {SnowflakeForecastModel} [snowflakeForecastModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateSnowflakeForecastModel(id: string, organizationId?: string, snowflakeForecastModel?: SnowflakeForecastModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SnowflakeForecastModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateSnowflakeForecastModel(id, organizationId, snowflakeForecastModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.partialUpdateSnowflakeForecastModel']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint to manipulate StoreCanary
         * @param {string} id A UUID string identifying this Store Canary.
         * @param {string} [cloud] cloud
         * @param {string} [component] component
         * @param {string} [status] status
         * @param {string} [isPrivate] is_private
         * @param {string} [isPinnedCanary] is_pinned_canary
         * @param {string} [uploadedUser] uploaded_user
         * @param {string} [company] company
         * @param {string} [search] A search term.
         * @param {StoreCanaryWrite} [storeCanaryWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateStoreCanary(id: string, cloud?: string, component?: string, status?: string, isPrivate?: string, isPinnedCanary?: string, uploadedUser?: string, company?: string, search?: string, storeCanaryWrite?: StoreCanaryWrite, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreCanaryWrite>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateStoreCanary(id, cloud, component, status, isPrivate, isPinnedCanary, uploadedUser, company, search, storeCanaryWrite, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.partialUpdateStoreCanary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Subscription Cancel Request.
         * @param {string} [subscriptionId] subscription_id
         * @param {string} [subscriptionUserId] subscription_user_id
         * @param {SubscriptionCancelRequest} [subscriptionCancelRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateSubscriptionCancelRequest(id: string, subscriptionId?: string, subscriptionUserId?: string, subscriptionCancelRequest?: SubscriptionCancelRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionCancelRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateSubscriptionCancelRequest(id, subscriptionId, subscriptionUserId, subscriptionCancelRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.partialUpdateSubscriptionCancelRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint to manipulate TemplateCanary
         * @param {string} id A UUID string identifying this Template Canary.
         * @param {string} [canaryTemplateLibraryId] canary_template_library_id
         * @param {string} [providerId] provider_id
         * @param {string} [serviceId] service_id
         * @param {TemplateCanaryWritable} [templateCanaryWritable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateTemplateCanary(id: string, canaryTemplateLibraryId?: string, providerId?: string, serviceId?: string, templateCanaryWritable?: TemplateCanaryWritable, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplateCanaryWritable>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateTemplateCanary(id, canaryTemplateLibraryId, providerId, serviceId, templateCanaryWritable, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.partialUpdateTemplateCanary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint to manipulate TemplateTask
         * @param {string} id A UUID string identifying this Template Task.
         * @param {string} [canaryTemplateLibraryId] canary_template_library_id
         * @param {TemplateTaskReadOnly} [templateTaskReadOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateTemplateTask(id: string, canaryTemplateLibraryId?: string, templateTaskReadOnly?: TemplateTaskReadOnly, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplateTaskReadOnly>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateTemplateTask(id, canaryTemplateLibraryId, templateTaskReadOnly, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.partialUpdateTemplateTask']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint that allows authenticated clients to view or edit users
         * @param {string} id A UUID string identifying this user.
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateUser(id: string, user?: User, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateUser(id, user, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.partialUpdateUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this User Subscribed Plan.
         * @param {string} [userId] user_id
         * @param {string} [platformActive] platform_active
         * @param {UserSubscribedPlan} [userSubscribedPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateUserSubscribedPlan(id: string, userId?: string, platformActive?: string, userSubscribedPlan?: UserSubscribedPlan, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSubscribedPlan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateUserSubscribedPlan(id, userId, platformActive, userSubscribedPlan, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.partialUpdateUserSubscribedPlan']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshAuthTokenAuthViewSet(body?: any, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshAuthTokenAuthViewSet(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.refreshAuthTokenAuthViewSet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Organization.
         * @param {OrganizationMember} [organizationMember] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeMemberOrganization(id: string, organizationMember?: OrganizationMember, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationMember>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeMemberOrganization(id, organizationMember, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.removeMemberOrganization']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestResetPasswordAuthViewSet(body?: any, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestResetPasswordAuthViewSet(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.requestResetPasswordAuthViewSet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPasswordAuthViewSet(body?: any, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPasswordAuthViewSet(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.resetPasswordAuthViewSet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Abstract Metric.
         * @param {string} [canaryId] canary_id
         * @param {string} [taskId] task_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveAbstractMetric(id: string, canaryId?: string, taskId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbstractMetric>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveAbstractMetric(id, canaryId, taskId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveAbstractMetric']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Abstract Metric Condition.
         * @param {string} [metricId] metric_id
         * @param {string} [canaryId] canary_id
         * @param {string} [taskId] task_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveAbstractMetricBoundaryCondition(id: string, metricId?: string, canaryId?: string, taskId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbstractMetricBoundaryCondition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveAbstractMetricBoundaryCondition(id, metricId, canaryId, taskId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveAbstractMetricBoundaryCondition']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveApiVersion(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiVersion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveApiVersion(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveApiVersion']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this billing detail.
         * @param {string} [userId] user_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveBillingDetail(id: string, userId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveBillingDetail(id, userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveBillingDetail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint that manages Canarys
         * @param {string} id A UUID string identifying this Canary.
         * @param {string} [organizationId] organization_id
         * @param {RetrieveCanaryStatusEnum} [status] status
         * @param {RetrieveCanaryOrderingEnum} [ordering] Ordering
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveCanary(id: string, organizationId?: string, status?: RetrieveCanaryStatusEnum, ordering?: RetrieveCanaryOrderingEnum, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Canary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveCanary(id, organizationId, status, ordering, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveCanary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A readonly ViewSet for viewing CanaryDailyForecast\'s
         * @param {string} id A UUID string identifying this Canary Daily Forecast.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveCanaryDailyForecast(id: string, start?: string, stop?: string, organizationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanaryDailyForecast>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveCanaryDailyForecast(id, start, stop, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveCanaryDailyForecast']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint to manipulate CustomCanaryPrototypeSource
         * @param {string} id A UUID string identifying this Canary Prototype Source Code.
         * @param {string} [canaryTemplateLibraryId] canary_template_library_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveCanaryPrototypeSourceCode(id: string, canaryTemplateLibraryId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanaryPrototypeSource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveCanaryPrototypeSourceCode(id, canaryTemplateLibraryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveCanaryPrototypeSourceCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint to manipulate CustomCanaryRestfulLibSource
         * @param {string} id A UUID string identifying this Canary Restful Library Source Code.
         * @param {string} [restfulLibraryId] restful_library_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveCanaryRestfulLibSourceCode(id: string, restfulLibraryId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanaryRestfulLibSourceFile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveCanaryRestfulLibSourceCode(id, restfulLibraryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveCanaryRestfulLibSourceCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get or update a single CanaryTask
         * @param {string} id A UUID string identifying this Canary Task.
         * @param {string} [canaryId] canary_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveCanaryTask(id: string, canaryId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanaryTask>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveCanaryTask(id, canaryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveCanaryTask']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * View to get CanaryData as a graph  * Requires token authentication.
         * @param {string} id A UUID string identifying this canary task data graph.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveCanaryTaskDataGraph(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveCanaryTaskDataGraph(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveCanaryTaskDataGraph']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get or update a single CanaryTask
         * @param {string} id A UUID string identifying this canary task env vars.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveCanaryTaskEnvVars(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanaryTaskEnvVars>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveCanaryTaskEnvVars(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveCanaryTaskEnvVars']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Canary Task Metric Data.
         * @param {string} [measuredFrom] measured_from
         * @param {string} [measuredUntil] measured_until
         * @param {string} [metricId] metric_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveCanaryTaskMetricData(id: string, measuredFrom?: string, measuredUntil?: string, metricId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanaryTaskMetricData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveCanaryTaskMetricData(id, measuredFrom, measuredUntil, metricId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveCanaryTaskMetricData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Canary Task Run Data Export.
         * @param {string} [measuredFrom] The Start date of the data export period, should be in this format: YYYY-MM-DD+HH%3AMM, note that %3A means \&#39;:\&#39; so don\&#39;t remove it.
         * @param {string} [measuredUntil] The End date of the data export period, should be in this format: YYYY-MM-DD+HH%3AMM, note that %3A means \&#39;:\&#39; so don\&#39;t remove it.
         * @param {string} [canaryId] The Canary id to get the exported data for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveCanaryTaskRunDataExport(id: string, measuredFrom?: string, measuredUntil?: string, canaryId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanaryTaskRunDataExportReadOnly>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveCanaryTaskRunDataExport(id, measuredFrom, measuredUntil, canaryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveCanaryTaskRunDataExport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint to manipulate CanaryTemplateLibrary
         * @param {string} id A UUID string identifying this Canary Template Library.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveCanaryTemplateLibrary(id: string, organizationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanaryTemplateLibrary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveCanaryTemplateLibrary(id, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveCanaryTemplateLibrary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A readonly ViewSet for viewing Canaryable Services.
         * @param {string} id A UUID string identifying this Canaryable Service.
         * @param {string} [providerId] provider_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveCanaryableService(id: string, providerId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanaryableService>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveCanaryableService(id, providerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveCanaryableService']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A readonly ViewSet for viewing CompositeDailyForecasts Services.
         * @param {string} id A UUID string identifying this Composite Daily Forecast.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveCompositeDailyForecast(id: string, start?: string, stop?: string, organizationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompositeDailyForecast>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveCompositeDailyForecast(id, start, stop, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveCompositeDailyForecast']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Expose clusters to users  - important because it shares a security boundary with users - we must allow users to read all platform wide clusters (e.g. ours), but not change anything
         * @param {string} id A UUID string identifying this Kubernetes Cluster.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveKubernetesCluster(id: string, organizationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KubernetesCluster>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveKubernetesCluster(id, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveKubernetesCluster']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A readonly ViewSet for viewing NeuralProphetCanaryDailyForecast\'s
         * @param {string} id A UUID string identifying this Neural Prophet Canary Daily Forecast.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {string} [canaryId] canary_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveNeuralProphetCanaryDailyForecast(id: string, start?: string, stop?: string, organizationId?: string, canaryId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NeuralCanaryDailyForecast>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveNeuralProphetCanaryDailyForecast(id, start, stop, organizationId, canaryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveNeuralProphetCanaryDailyForecast']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A readonly ViewSet for viewing NeuralProphetCanaryDailyForecastData\'s
         * @param {string} id A UUID string identifying this Neural Prophet Canary Daily Forecast Data.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {string} [canaryId] canary_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveNeuralProphetCanaryDailyForecastData(id: string, start?: string, stop?: string, organizationId?: string, canaryId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NeuralCanaryDailyForecastData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveNeuralProphetCanaryDailyForecastData(id, start, stop, organizationId, canaryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveNeuralProphetCanaryDailyForecastData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A readonly ViewSet for viewing NeuralProphetCompositeDailyForecast Services.
         * @param {string} id A UUID string identifying this Neural Prophet Composite Daily Forecast.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveNeuralProphetCompositeDailyForecast(id: string, start?: string, stop?: string, organizationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NeuralCompositeDailyForecast>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveNeuralProphetCompositeDailyForecast(id, start, stop, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveNeuralProphetCompositeDailyForecast']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A readonly ViewSet for viewing NeuralProphetServiceDailyForecast
         * @param {string} id A UUID string identifying this Neural Prophet Service Daily Forecast.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveNeuralProphetServiceDailyForecast(id: string, start?: string, stop?: string, organizationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NeuralServiceDailyForecast>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveNeuralProphetServiceDailyForecast(id, start, stop, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveNeuralProphetServiceDailyForecast']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Notification.
         * @param {string} [alarmId] alarm_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveNotification(id: string, alarmId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Notification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveNotification(id, alarmId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveNotification']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Notification Channel Setting.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveNotificationChannelSetting(id: string, organizationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationChannelSetting>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveNotificationChannelSetting(id, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveNotificationChannelSetting']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Notification History.
         * @param {string} [alarmId] alarm_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveNotificationHistory(id: string, alarmId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationHistory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveNotificationHistory(id, alarmId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveNotificationHistory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint to manipulate CustomCanaryRestfulLibSource
         * @param {string} id A UUID string identifying this OpenAPI Restful Library.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveOpenAPIRestfulLibrary(id: string, organizationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpenAPIRestfulLibrary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveOpenAPIRestfulLibrary(id, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveOpenAPIRestfulLibrary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Organization.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveOrganization(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveOrganization(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveOrganization']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} prefix 
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveOrganizationAPIKey(prefix: string, organizationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationAPIKey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveOrganizationAPIKey(prefix, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveOrganizationAPIKey']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint that gets the status of the canary * Requires token authentication.
         * @param {string} id A UUID string identifying this public api canary.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrievePublicAPICanary(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanaryStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrievePublicAPICanary(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrievePublicAPICanary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A readonly ViewSet for viewing ServiceCompliance Summaries.
         * @param {string} id A UUID string identifying this public api service compliance summary.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrievePublicAPIServiceComplianceSummary(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceComplianceSummaryReadOnly>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrievePublicAPIServiceComplianceSummary(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrievePublicAPIServiceComplianceSummary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * View to get ServiceHealth of a canary * Requires token authentication.
         * @param {string} id A UUID string identifying this public api service health.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrievePublicAPIServiceHealth(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceHealthStatusRetrieve>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrievePublicAPIServiceHealth(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrievePublicAPIServiceHealth']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * View to manipulate ServiceComplianceColors for an organization
         * @param {string} id A UUID string identifying this Service Compliance Color.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveServiceComplianceColors(id: string, organizationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceComplianceColors>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveServiceComplianceColors(id, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveServiceComplianceColors']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A readonly ViewSet for viewing ServiceCompliance Histories
         * @param {string} id A UUID string identifying this service compliance histories.
         * @param {string} [organizationId] organization_id
         * @param {string} [stop] stop
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveServiceComplianceHistories(id: string, organizationId?: string, stop?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceComplianceHistory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveServiceComplianceHistories(id, organizationId, stop, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveServiceComplianceHistories']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A readonly ViewSet for viewing ServiceCompliance Summaries.
         * @param {string} id A UUID string identifying this Service Compliance Summary.
         * @param {string} [organizationId] organization_id
         * @param {RetrieveServiceComplianceSummaryPeriodCategoryEnum} [periodCategory] period_category
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveServiceComplianceSummary(id: string, organizationId?: string, periodCategory?: RetrieveServiceComplianceSummaryPeriodCategoryEnum, start?: string, stop?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceComplianceSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveServiceComplianceSummary(id, organizationId, periodCategory, start, stop, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveServiceComplianceSummary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A readonly ViewSet for viewing ServiceDailyForecasts
         * @param {string} id A UUID string identifying this Service Daily Forecast.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveServiceDailyForecast(id: string, start?: string, stop?: string, organizationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceDailyForecast>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveServiceDailyForecast(id, start, stop, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveServiceDailyForecast']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * View to get ServiceHealth of a group of canaries
         * @param {string} id A UUID string identifying this Service Health.
         * @param {string} [organizationId] organization_id
         * @param {string} [providerId] provider_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveServiceHealth(id: string, organizationId?: string, providerId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceHealth>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveServiceHealth(id, organizationId, providerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveServiceHealth']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * View to manipulate ServiceHealthColors for an organization
         * @param {string} id A UUID string identifying this Service Health Color.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveServiceHealthColors(id: string, organizationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceHealthColors>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveServiceHealthColors(id, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveServiceHealthColors']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * View to get ServiceHealthEvaluationSettingsGroup for an organization
         * @param {string} id A UUID string identifying this Service Health Evaluation Settings Group.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveServiceHealthEvaluationSettingsGroup(id: string, organizationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceHealthEvaluationSettingsGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveServiceHealthEvaluationSettingsGroup(id, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveServiceHealthEvaluationSettingsGroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this service health incident.
         * @param {string} [organizationId] organization_id
         * @param {string} [serviceId] service_id
         * @param {RetrieveServiceHealthIncidentIncidentStatusEnum} [incidentStatus] incident_status
         * @param {RetrieveServiceHealthIncidentOrderingEnum} [ordering] Ordering
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveServiceHealthIncident(id: string, organizationId?: string, serviceId?: string, incidentStatus?: RetrieveServiceHealthIncidentIncidentStatusEnum, ordering?: RetrieveServiceHealthIncidentOrderingEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceHealthIncident>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveServiceHealthIncident(id, organizationId, serviceId, incidentStatus, ordering, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveServiceHealthIncident']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A readonly ViewSet for viewing ServiceProviders.
         * @param {string} id A UUID string identifying this Service Provider.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveServiceProvider(id: string, organizationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanaryServiceProvider>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveServiceProvider(id, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveServiceProvider']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A readonly ViewSet for viewing ServiceProviderAvailabilityZone\'s.
         * @param {string} id A UUID string identifying this Service Provider Availability Zone.
         * @param {string} [providerId] provider_id
         * @param {string} [regionId] region_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveServiceProviderAvailabilityZone(id: string, providerId?: string, regionId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanaryServiceProviderAvailabilityZone>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveServiceProviderAvailabilityZone(id, providerId, regionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveServiceProviderAvailabilityZone']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A readonly ViewSet for viewing ServiceProviderRegion\'s.
         * @param {string} id A UUID string identifying this Service Provider Region.
         * @param {string} [providerId] provider_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveServiceProviderRegion(id: string, providerId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanaryServiceProviderRegion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveServiceProviderRegion(id, providerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveServiceProviderRegion']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Simple Alarm.
         * @param {string} [metricId] metric_id
         * @param {string} [canaryId] canary_id
         * @param {string} [taskId] task_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveSimpleAlarm(id: string, metricId?: string, canaryId?: string, taskId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimpleAlarm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveSimpleAlarm(id, metricId, canaryId, taskId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveSimpleAlarm']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Simple Alarm Evaluation.
         * @param {string} [alarmId] alarm_id
         * @param {string} [periodStart] period_start
         * @param {string} [periodStop] period_stop
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveSimpleAlarmEvaluation(id: string, alarmId?: string, periodStart?: string, periodStop?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimpleAlarmEvaluation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveSimpleAlarmEvaluation(id, alarmId, periodStart, periodStop, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveSimpleAlarmEvaluation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Simple Alarm History.
         * @param {string} [alarmId] alarm_id
         * @param {string} [evaluationPeriodStart] evaluation_period_start
         * @param {string} [evaluationPeriodStop] evaluation_period_stop
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveSimpleAlarmHistory(id: string, alarmId?: string, evaluationPeriodStart?: string, evaluationPeriodStop?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimpleAlarmHistory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveSimpleAlarmHistory(id, alarmId, evaluationPeriodStart, evaluationPeriodStop, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveSimpleAlarmHistory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Snowflake Setting.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveSnowflake(id: string, organizationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Snowflake>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveSnowflake(id, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveSnowflake']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A readonly ViewSet for viewing SnowflakeCanaryDailyForecast\'s
         * @param {string} id A UUID string identifying this Snowflake Canary Daily Forecast.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {string} [canaryId] canary_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveSnowflakeCanaryDailyForecast(id: string, start?: string, stop?: string, organizationId?: string, canaryId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SnowflakeCanaryDailyForecast>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveSnowflakeCanaryDailyForecast(id, start, stop, organizationId, canaryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveSnowflakeCanaryDailyForecast']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A readonly ViewSet for viewing SnowflakeCanaryDailyForecastData Services.
         * @param {string} id A UUID string identifying this Snowflake Canary Daily Data Forecast.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {string} [canaryId] canary_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveSnowflakeCanaryDailyForecastData(id: string, start?: string, stop?: string, organizationId?: string, canaryId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SnowflakeCanaryDailyForecastData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveSnowflakeCanaryDailyForecastData(id, start, stop, organizationId, canaryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveSnowflakeCanaryDailyForecastData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A readonly ViewSet for viewing SnowflakeCompositeDailyForecast Services.
         * @param {string} id A UUID string identifying this Snowflake Composite Daily Forecast.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveSnowflakeCompositeDailyForecast(id: string, start?: string, stop?: string, organizationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SnowflakeCompositeDailyForecast>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveSnowflakeCompositeDailyForecast(id, start, stop, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveSnowflakeCompositeDailyForecast']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Snowflake Forecast Model.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveSnowflakeForecastModel(id: string, organizationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SnowflakeForecastModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveSnowflakeForecastModel(id, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveSnowflakeForecastModel']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A readonly ViewSet for viewing SnowflakeServiceDailyForecast
         * @param {string} id A UUID string identifying this Snowflake Service Daily Forecast.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveSnowflakeServiceDailyForecast(id: string, start?: string, stop?: string, organizationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SnowflakeServiceDailyForecast>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveSnowflakeServiceDailyForecast(id, start, stop, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveSnowflakeServiceDailyForecast']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint to manipulate StoreCanary
         * @param {string} id A UUID string identifying this Store Canary.
         * @param {string} [cloud] cloud
         * @param {string} [component] component
         * @param {string} [status] status
         * @param {string} [isPrivate] is_private
         * @param {string} [isPinnedCanary] is_pinned_canary
         * @param {string} [uploadedUser] uploaded_user
         * @param {string} [company] company
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveStoreCanary(id: string, cloud?: string, component?: string, status?: string, isPrivate?: string, isPinnedCanary?: string, uploadedUser?: string, company?: string, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreCanary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveStoreCanary(id, cloud, component, status, isPrivate, isPinnedCanary, uploadedUser, company, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveStoreCanary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Subscription Cancel Request.
         * @param {string} [subscriptionId] subscription_id
         * @param {string} [subscriptionUserId] subscription_user_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveSubscriptionCancelRequest(id: string, subscriptionId?: string, subscriptionUserId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionCancelRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveSubscriptionCancelRequest(id, subscriptionId, subscriptionUserId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveSubscriptionCancelRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint to manipulate TemplateCanary
         * @param {string} id A UUID string identifying this Template Canary.
         * @param {string} [canaryTemplateLibraryId] canary_template_library_id
         * @param {string} [providerId] provider_id
         * @param {string} [serviceId] service_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveTemplateCanary(id: string, canaryTemplateLibraryId?: string, providerId?: string, serviceId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplateCanaryReadOnlyRetrieveItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveTemplateCanary(id, canaryTemplateLibraryId, providerId, serviceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveTemplateCanary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint to manipulate TemplateTask
         * @param {string} id A UUID string identifying this Template Task.
         * @param {string} [canaryTemplateLibraryId] canary_template_library_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveTemplateTask(id: string, canaryTemplateLibraryId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplateTaskReadOnly>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveTemplateTask(id, canaryTemplateLibraryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveTemplateTask']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint that allows authenticated clients to view or edit users
         * @param {string} id A UUID string identifying this user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveUser(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveUser(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this User Subscribed Plan.
         * @param {string} [userId] user_id
         * @param {string} [platformActive] platform_active
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveUserSubscribedPlan(id: string, userId?: string, platformActive?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSubscribedPlan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveUserSubscribedPlan(id, userId, platformActive, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.retrieveUserSubscribedPlan']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint that manages Canarys
         * @param {string} id A UUID string identifying this Canary.
         * @param {CanarySchedule} [canarySchedule] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scheduleCanary(id: string, canarySchedule?: CanarySchedule, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanarySchedule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scheduleCanary(id, canarySchedule, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.scheduleCanary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this User Subscribed Plan.
         * @param {UserSubscribedPlanAssignSubtractCanary} [userSubscribedPlanAssignSubtractCanary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subtractCanaryUserSubscribedPlan(id: string, userSubscribedPlanAssignSubtractCanary?: UserSubscribedPlanAssignSubtractCanary, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSubscribedPlanAssignSubtractCanary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subtractCanaryUserSubscribedPlan(id, userSubscribedPlanAssignSubtractCanary, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.subtractCanaryUserSubscribedPlan']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Abstract Metric Condition.
         * @param {string} [metricId] metric_id
         * @param {string} [canaryId] canary_id
         * @param {string} [taskId] task_id
         * @param {AbstractMetricBoundaryCondition} [abstractMetricBoundaryCondition] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAbstractMetricBoundaryCondition(id: string, metricId?: string, canaryId?: string, taskId?: string, abstractMetricBoundaryCondition?: AbstractMetricBoundaryCondition, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbstractMetricBoundaryCondition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAbstractMetricBoundaryCondition(id, metricId, canaryId, taskId, abstractMetricBoundaryCondition, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.updateAbstractMetricBoundaryCondition']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this billing detail.
         * @param {string} [userId] user_id
         * @param {BillingDetail} [billingDetail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBillingDetail(id: string, userId?: string, billingDetail?: BillingDetail, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBillingDetail(id, userId, billingDetail, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.updateBillingDetail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint that manages Canarys
         * @param {string} id A UUID string identifying this Canary.
         * @param {string} [organizationId] organization_id
         * @param {UpdateCanaryStatusEnum} [status] status
         * @param {UpdateCanaryOrderingEnum} [ordering] Ordering
         * @param {string} [search] A search term.
         * @param {Canary} [canary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCanary(id: string, organizationId?: string, status?: UpdateCanaryStatusEnum, ordering?: UpdateCanaryOrderingEnum, search?: string, canary?: Canary, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Canary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCanary(id, organizationId, status, ordering, search, canary, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.updateCanary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this canary cluster deployment.
         * @param {CanaryClusterDeployment} [canaryClusterDeployment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCanaryClusterDeployment(id: string, canaryClusterDeployment?: CanaryClusterDeployment, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanaryClusterDeployment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCanaryClusterDeployment(id, canaryClusterDeployment, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.updateCanaryClusterDeployment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint to manipulate CustomCanaryPrototypeSource
         * @param {string} id A UUID string identifying this Canary Prototype Source Code.
         * @param {string} [canaryTemplateLibraryId] canary_template_library_id
         * @param {CanaryPrototypeSource} [canaryPrototypeSource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCanaryPrototypeSourceCode(id: string, canaryTemplateLibraryId?: string, canaryPrototypeSource?: CanaryPrototypeSource, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanaryPrototypeSource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCanaryPrototypeSourceCode(id, canaryTemplateLibraryId, canaryPrototypeSource, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.updateCanaryPrototypeSourceCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint to manipulate CustomCanaryRestfulLibSource
         * @param {string} id A UUID string identifying this Canary Restful Library Source Code.
         * @param {string} [restfulLibraryId] restful_library_id
         * @param {CanaryRestfulLibSourceFile} [canaryRestfulLibSourceFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCanaryRestfulLibSourceCode(id: string, restfulLibraryId?: string, canaryRestfulLibSourceFile?: CanaryRestfulLibSourceFile, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanaryRestfulLibSourceFile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCanaryRestfulLibSourceCode(id, restfulLibraryId, canaryRestfulLibSourceFile, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.updateCanaryRestfulLibSourceCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get or update a single CanaryTask
         * @param {string} id A UUID string identifying this Canary Task.
         * @param {string} [canaryId] canary_id
         * @param {CanaryTask} [canaryTask] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCanaryTask(id: string, canaryId?: string, canaryTask?: CanaryTask, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanaryTask>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCanaryTask(id, canaryId, canaryTask, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.updateCanaryTask']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get or update a single CanaryTask
         * @param {string} id A UUID string identifying this canary task env vars.
         * @param {CanaryTaskEnvVars} [canaryTaskEnvVars] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCanaryTaskEnvVars(id: string, canaryTaskEnvVars?: CanaryTaskEnvVars, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanaryTaskEnvVars>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCanaryTaskEnvVars(id, canaryTaskEnvVars, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.updateCanaryTaskEnvVars']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint to manipulate CanaryTemplateLibrary
         * @param {string} id A UUID string identifying this Canary Template Library.
         * @param {string} [organizationId] organization_id
         * @param {CanaryTemplateLibrary} [canaryTemplateLibrary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCanaryTemplateLibrary(id: string, organizationId?: string, canaryTemplateLibrary?: CanaryTemplateLibrary, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanaryTemplateLibrary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCanaryTemplateLibrary(id, organizationId, canaryTemplateLibrary, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.updateCanaryTemplateLibrary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Expose clusters to users  - important because it shares a security boundary with users - we must allow users to read all platform wide clusters (e.g. ours), but not change anything
         * @param {string} id A UUID string identifying this Kubernetes Cluster.
         * @param {string} [organizationId] organization_id
         * @param {KubernetesCluster} [kubernetesCluster] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateKubernetesCluster(id: string, organizationId?: string, kubernetesCluster?: KubernetesCluster, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KubernetesCluster>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateKubernetesCluster(id, organizationId, kubernetesCluster, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.updateKubernetesCluster']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Notification.
         * @param {string} [alarmId] alarm_id
         * @param {Notification} [notification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNotification(id: string, alarmId?: string, notification?: Notification, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Notification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNotification(id, alarmId, notification, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.updateNotification']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Notification Channel Setting.
         * @param {string} [organizationId] organization_id
         * @param {NotificationChannelSetting} [notificationChannelSetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNotificationChannelSetting(id: string, organizationId?: string, notificationChannelSetting?: NotificationChannelSetting, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationChannelSetting>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNotificationChannelSetting(id, organizationId, notificationChannelSetting, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.updateNotificationChannelSetting']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint to manipulate CustomCanaryRestfulLibSource
         * @param {string} id A UUID string identifying this OpenAPI Restful Library.
         * @param {string} [organizationId] organization_id
         * @param {OpenAPIRestfulLibrary} [openAPIRestfulLibrary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOpenAPIRestfulLibrary(id: string, organizationId?: string, openAPIRestfulLibrary?: OpenAPIRestfulLibrary, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpenAPIRestfulLibrary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOpenAPIRestfulLibrary(id, organizationId, openAPIRestfulLibrary, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.updateOpenAPIRestfulLibrary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Organization.
         * @param {Organization} [organization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrganization(id: string, organization?: Organization, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrganization(id, organization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.updateOrganization']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} prefix 
         * @param {string} [organizationId] organization_id
         * @param {OrganizationAPIKeyUpdate} [organizationAPIKeyUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrganizationAPIKey(prefix: string, organizationId?: string, organizationAPIKeyUpdate?: OrganizationAPIKeyUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationAPIKeyUpdate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrganizationAPIKey(prefix, organizationId, organizationAPIKeyUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.updateOrganizationAPIKey']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * View to manipulate ServiceComplianceColors for an organization
         * @param {string} id A UUID string identifying this Service Compliance Color.
         * @param {string} [organizationId] organization_id
         * @param {ServiceComplianceColors} [serviceComplianceColors] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateServiceComplianceColors(id: string, organizationId?: string, serviceComplianceColors?: ServiceComplianceColors, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceComplianceColors>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateServiceComplianceColors(id, organizationId, serviceComplianceColors, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.updateServiceComplianceColors']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * View to manipulate ServiceHealthColors for an organization
         * @param {string} id A UUID string identifying this Service Health Color.
         * @param {string} [organizationId] organization_id
         * @param {ServiceHealthColors} [serviceHealthColors] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateServiceHealthColors(id: string, organizationId?: string, serviceHealthColors?: ServiceHealthColors, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceHealthColors>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateServiceHealthColors(id, organizationId, serviceHealthColors, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.updateServiceHealthColors']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * View to get ServiceHealthEvaluationSettingsGroup for an organization
         * @param {string} id A UUID string identifying this Service Health Evaluation Settings Group.
         * @param {string} [organizationId] organization_id
         * @param {ServiceHealthEvaluationSettingsGroup} [serviceHealthEvaluationSettingsGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateServiceHealthEvaluationSettingsGroup(id: string, organizationId?: string, serviceHealthEvaluationSettingsGroup?: ServiceHealthEvaluationSettingsGroup, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceHealthEvaluationSettingsGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateServiceHealthEvaluationSettingsGroup(id, organizationId, serviceHealthEvaluationSettingsGroup, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.updateServiceHealthEvaluationSettingsGroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Simple Alarm.
         * @param {string} [metricId] metric_id
         * @param {string} [canaryId] canary_id
         * @param {string} [taskId] task_id
         * @param {SimpleAlarm} [simpleAlarm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSimpleAlarm(id: string, metricId?: string, canaryId?: string, taskId?: string, simpleAlarm?: SimpleAlarm, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimpleAlarm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSimpleAlarm(id, metricId, canaryId, taskId, simpleAlarm, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.updateSimpleAlarm']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Snowflake Setting.
         * @param {string} [organizationId] organization_id
         * @param {Snowflake} [snowflake] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSnowflake(id: string, organizationId?: string, snowflake?: Snowflake, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Snowflake>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSnowflake(id, organizationId, snowflake, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.updateSnowflake']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Snowflake Forecast Model.
         * @param {string} [organizationId] organization_id
         * @param {SnowflakeForecastModel} [snowflakeForecastModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSnowflakeForecastModel(id: string, organizationId?: string, snowflakeForecastModel?: SnowflakeForecastModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SnowflakeForecastModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSnowflakeForecastModel(id, organizationId, snowflakeForecastModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.updateSnowflakeForecastModel']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint to manipulate StoreCanary
         * @param {string} id A UUID string identifying this Store Canary.
         * @param {string} [cloud] cloud
         * @param {string} [component] component
         * @param {string} [status] status
         * @param {string} [isPrivate] is_private
         * @param {string} [isPinnedCanary] is_pinned_canary
         * @param {string} [uploadedUser] uploaded_user
         * @param {string} [company] company
         * @param {string} [search] A search term.
         * @param {StoreCanaryWrite} [storeCanaryWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStoreCanary(id: string, cloud?: string, component?: string, status?: string, isPrivate?: string, isPinnedCanary?: string, uploadedUser?: string, company?: string, search?: string, storeCanaryWrite?: StoreCanaryWrite, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreCanaryWrite>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStoreCanary(id, cloud, component, status, isPrivate, isPinnedCanary, uploadedUser, company, search, storeCanaryWrite, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.updateStoreCanary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Subscription Cancel Request.
         * @param {string} [subscriptionId] subscription_id
         * @param {string} [subscriptionUserId] subscription_user_id
         * @param {SubscriptionCancelRequest} [subscriptionCancelRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSubscriptionCancelRequest(id: string, subscriptionId?: string, subscriptionUserId?: string, subscriptionCancelRequest?: SubscriptionCancelRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionCancelRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSubscriptionCancelRequest(id, subscriptionId, subscriptionUserId, subscriptionCancelRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.updateSubscriptionCancelRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint to manipulate TemplateCanary
         * @param {string} id A UUID string identifying this Template Canary.
         * @param {string} [canaryTemplateLibraryId] canary_template_library_id
         * @param {string} [providerId] provider_id
         * @param {string} [serviceId] service_id
         * @param {TemplateCanaryWritable} [templateCanaryWritable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTemplateCanary(id: string, canaryTemplateLibraryId?: string, providerId?: string, serviceId?: string, templateCanaryWritable?: TemplateCanaryWritable, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplateCanaryWritable>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTemplateCanary(id, canaryTemplateLibraryId, providerId, serviceId, templateCanaryWritable, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.updateTemplateCanary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint to manipulate TemplateTask
         * @param {string} id A UUID string identifying this Template Task.
         * @param {string} [canaryTemplateLibraryId] canary_template_library_id
         * @param {TemplateTaskReadOnly} [templateTaskReadOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTemplateTask(id: string, canaryTemplateLibraryId?: string, templateTaskReadOnly?: TemplateTaskReadOnly, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplateTaskReadOnly>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTemplateTask(id, canaryTemplateLibraryId, templateTaskReadOnly, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.updateTemplateTask']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * API endpoint that allows authenticated clients to view or edit users
         * @param {string} id A UUID string identifying this user.
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(id: string, user?: User, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(id, user, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.updateUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserPartialAuthViewSet(body?: any, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserPartialAuthViewSet(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.updateUserPartialAuthViewSet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this User Subscribed Plan.
         * @param {string} [userId] user_id
         * @param {string} [platformActive] platform_active
         * @param {UserSubscribedPlan} [userSubscribedPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserSubscribedPlan(id: string, userId?: string, platformActive?: string, userSubscribedPlan?: UserSubscribedPlan, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSubscribedPlan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserSubscribedPlan(id, userId, platformActive, userSubscribedPlan, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.updateUserSubscribedPlan']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userProfileAuthViewSet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userProfileAuthViewSet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.userProfileAuthViewSet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateResetTokenAuthViewSet(body?: any, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateResetTokenAuthViewSet(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.validateResetTokenAuthViewSet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyEmailAuthViewSet(body?: any, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyEmailAuthViewSet(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.verifyEmailAuthViewSet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id A UUID string identifying this Organization.
         * @param {OrganizationMember} [organizationMember] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMemberOrganization(id: string, organizationMember?: OrganizationMember, options?: any): AxiosPromise<OrganizationMember> {
            return localVarFp.addMemberOrganization(id, organizationMember, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this User Subscribed Plan.
         * @param {UserSubscribedPlanAddOrganizations} [userSubscribedPlanAddOrganizations] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrganizationUserSubscribedPlan(id: string, userSubscribedPlanAddOrganizations?: UserSubscribedPlanAddOrganizations, options?: any): AxiosPromise<UserSubscribedPlanAddOrganizations> {
            return localVarFp.addOrganizationUserSubscribedPlan(id, userSubscribedPlanAddOrganizations, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this User Subscribed Plan.
         * @param {UserSubscribedPlanAssignSubtractCanary} [userSubscribedPlanAssignSubtractCanary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignCanaryUserSubscribedPlan(id: string, userSubscribedPlanAssignSubtractCanary?: UserSubscribedPlanAssignSubtractCanary, options?: any): AxiosPromise<UserSubscribedPlanAssignSubtractCanary> {
            return localVarFp.assignCanaryUserSubscribedPlan(id, userSubscribedPlanAssignSubtractCanary, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint that manages Canarys
         * @param {string} id A UUID string identifying this Canary.
         * @param {CanarySchedule} [canarySchedule] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canaryAgentStatusCanary(id: string, canarySchedule?: CanarySchedule, options?: any): AxiosPromise<CanarySchedule> {
            return localVarFp.canaryAgentStatusCanary(id, canarySchedule, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeTempPasswordAuthViewSet(body?: any, options?: any): AxiosPromise<any> {
            return localVarFp.changeTempPasswordAuthViewSet(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AbstractMetricBoundaryCondition} [abstractMetricBoundaryCondition] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAbstractMetricBoundaryCondition(abstractMetricBoundaryCondition?: AbstractMetricBoundaryCondition, options?: any): AxiosPromise<AbstractMetricBoundaryCondition> {
            return localVarFp.createAbstractMetricBoundaryCondition(abstractMetricBoundaryCondition, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthToken} [authToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAuthToken(authToken?: AuthToken, options?: any): AxiosPromise<AuthToken> {
            return localVarFp.createAuthToken(authToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BillingDetail} [billingDetail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBillingDetail(billingDetail?: BillingDetail, options?: any): AxiosPromise<BillingDetail> {
            return localVarFp.createBillingDetail(billingDetail, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint that manages Canarys
         * @param {Canary} [canary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCanary(canary?: Canary, options?: any): AxiosPromise<Canary> {
            return localVarFp.createCanary(canary, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint to manipulate CustomCanaryPrototypeSource
         * @param {CanaryPrototypeSource} [canaryPrototypeSource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCanaryPrototypeSourceCode(canaryPrototypeSource?: CanaryPrototypeSource, options?: any): AxiosPromise<CanaryPrototypeSource> {
            return localVarFp.createCanaryPrototypeSourceCode(canaryPrototypeSource, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint to manipulate CustomCanaryRestfulLibSource
         * @param {CanaryRestfulLibSourceFile} [canaryRestfulLibSourceFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCanaryRestfulLibSourceCode(canaryRestfulLibSourceFile?: CanaryRestfulLibSourceFile, options?: any): AxiosPromise<CanaryRestfulLibSourceFile> {
            return localVarFp.createCanaryRestfulLibSourceCode(canaryRestfulLibSourceFile, options).then((request) => request(axios, basePath));
        },
        /**
         * Get or update a single CanaryTask
         * @param {CanaryTask} [canaryTask] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCanaryTask(canaryTask?: CanaryTask, options?: any): AxiosPromise<CanaryTask> {
            return localVarFp.createCanaryTask(canaryTask, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint that writes Canary data
         * @param {CanaryTaskRunDataWriteOnly} [canaryTaskRunDataWriteOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCanaryTaskRawData(canaryTaskRunDataWriteOnly?: CanaryTaskRunDataWriteOnly, options?: any): AxiosPromise<CanaryTaskRunDataWriteOnly> {
            return localVarFp.createCanaryTaskRawData(canaryTaskRunDataWriteOnly, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint to manipulate CanaryTemplateLibrary
         * @param {CanaryTemplateLibrary} [canaryTemplateLibrary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCanaryTemplateLibrary(canaryTemplateLibrary?: CanaryTemplateLibrary, options?: any): AxiosPromise<CanaryTemplateLibrary> {
            return localVarFp.createCanaryTemplateLibrary(canaryTemplateLibrary, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContactRequestWriteOnly} [contactRequestWriteOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContactRequest(contactRequestWriteOnly?: ContactRequestWriteOnly, options?: any): AxiosPromise<ContactRequestWriteOnly> {
            return localVarFp.createContactRequest(contactRequestWriteOnly, options).then((request) => request(axios, basePath));
        },
        /**
         * Expose clusters to users  - important because it shares a security boundary with users - we must allow users to read all platform wide clusters (e.g. ours), but not change anything
         * @param {KubernetesCluster} [kubernetesCluster] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createKubernetesCluster(kubernetesCluster?: KubernetesCluster, options?: any): AxiosPromise<KubernetesCluster> {
            return localVarFp.createKubernetesCluster(kubernetesCluster, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Notification} [notification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNotification(notification?: Notification, options?: any): AxiosPromise<Notification> {
            return localVarFp.createNotification(notification, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NotificationChannelSetting} [notificationChannelSetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNotificationChannelSetting(notificationChannelSetting?: NotificationChannelSetting, options?: any): AxiosPromise<NotificationChannelSetting> {
            return localVarFp.createNotificationChannelSetting(notificationChannelSetting, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint to manipulate CustomCanaryRestfulLibSource
         * @param {OpenAPIRestfulLibrary} [openAPIRestfulLibrary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOpenAPIRestfulLibrary(openAPIRestfulLibrary?: OpenAPIRestfulLibrary, options?: any): AxiosPromise<OpenAPIRestfulLibrary> {
            return localVarFp.createOpenAPIRestfulLibrary(openAPIRestfulLibrary, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Organization} [organization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganization(organization?: Organization, options?: any): AxiosPromise<Organization> {
            return localVarFp.createOrganization(organization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrganizationAPIKey} [organizationAPIKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganizationAPIKey(organizationAPIKey?: OrganizationAPIKey, options?: any): AxiosPromise<OrganizationAPIKey> {
            return localVarFp.createOrganizationAPIKey(organizationAPIKey, options).then((request) => request(axios, basePath));
        },
        /**
         * View to manipulate ServiceComplianceColors for an organization
         * @param {ServiceComplianceColors} [serviceComplianceColors] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createServiceComplianceColors(serviceComplianceColors?: ServiceComplianceColors, options?: any): AxiosPromise<ServiceComplianceColors> {
            return localVarFp.createServiceComplianceColors(serviceComplianceColors, options).then((request) => request(axios, basePath));
        },
        /**
         * View to manipulate ServiceHealthColors for an organization
         * @param {ServiceHealthColors} [serviceHealthColors] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createServiceHealthColors(serviceHealthColors?: ServiceHealthColors, options?: any): AxiosPromise<ServiceHealthColors> {
            return localVarFp.createServiceHealthColors(serviceHealthColors, options).then((request) => request(axios, basePath));
        },
        /**
         * View to get ServiceHealthEvaluationSettingsGroup for an organization
         * @param {ServiceHealthEvaluationSettingsGroup} [serviceHealthEvaluationSettingsGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createServiceHealthEvaluationSettingsGroup(serviceHealthEvaluationSettingsGroup?: ServiceHealthEvaluationSettingsGroup, options?: any): AxiosPromise<ServiceHealthEvaluationSettingsGroup> {
            return localVarFp.createServiceHealthEvaluationSettingsGroup(serviceHealthEvaluationSettingsGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SimpleAlarm} [simpleAlarm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSimpleAlarm(simpleAlarm?: SimpleAlarm, options?: any): AxiosPromise<SimpleAlarm> {
            return localVarFp.createSimpleAlarm(simpleAlarm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Snowflake} [snowflake] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSnowflake(snowflake?: Snowflake, options?: any): AxiosPromise<Snowflake> {
            return localVarFp.createSnowflake(snowflake, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SnowflakeForecastModel} [snowflakeForecastModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSnowflakeForecastModel(snowflakeForecastModel?: SnowflakeForecastModel, options?: any): AxiosPromise<SnowflakeForecastModel> {
            return localVarFp.createSnowflakeForecastModel(snowflakeForecastModel, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint to manipulate StoreCanary
         * @param {StoreCanaryWrite} [storeCanaryWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStoreCanary(storeCanaryWrite?: StoreCanaryWrite, options?: any): AxiosPromise<StoreCanaryWrite> {
            return localVarFp.createStoreCanary(storeCanaryWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SubscriptionCancelRequest} [subscriptionCancelRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscriptionCancelRequest(subscriptionCancelRequest?: SubscriptionCancelRequest, options?: any): AxiosPromise<SubscriptionCancelRequest> {
            return localVarFp.createSubscriptionCancelRequest(subscriptionCancelRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint to manipulate TemplateCanary
         * @param {TemplateCanaryWritable} [templateCanaryWritable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTemplateCanary(templateCanaryWritable?: TemplateCanaryWritable, options?: any): AxiosPromise<TemplateCanaryWritable> {
            return localVarFp.createTemplateCanary(templateCanaryWritable, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint to manipulate TemplateTask
         * @param {TemplateTaskReadOnly} [templateTaskReadOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTemplateTask(templateTaskReadOnly?: TemplateTaskReadOnly, options?: any): AxiosPromise<TemplateTaskReadOnly> {
            return localVarFp.createTemplateTask(templateTaskReadOnly, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint that allows authenticated clients to view or edit users
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(user?: User, options?: any): AxiosPromise<User> {
            return localVarFp.createUser(user, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserGatedContentDownloadRequest} [userGatedContentDownloadRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserGatedContentDownloadRequest(userGatedContentDownloadRequest?: UserGatedContentDownloadRequest, options?: any): AxiosPromise<UserGatedContentDownloadRequest> {
            return localVarFp.createUserGatedContentDownloadRequest(userGatedContentDownloadRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserSubscribedPlan} [userSubscribedPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserSubscribedPlan(userSubscribedPlan?: UserSubscribedPlan, options?: any): AxiosPromise<UserSubscribedPlan> {
            return localVarFp.createUserSubscribedPlan(userSubscribedPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Abstract Metric Condition.
         * @param {string} [metricId] metric_id
         * @param {string} [canaryId] canary_id
         * @param {string} [taskId] task_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyAbstractMetricBoundaryCondition(id: string, metricId?: string, canaryId?: string, taskId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.destroyAbstractMetricBoundaryCondition(id, metricId, canaryId, taskId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this billing detail.
         * @param {string} [userId] user_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyBillingDetail(id: string, userId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.destroyBillingDetail(id, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint that manages Canarys
         * @param {string} id A UUID string identifying this Canary.
         * @param {string} [organizationId] organization_id
         * @param {DestroyCanaryStatusEnum} [status] status
         * @param {DestroyCanaryOrderingEnum} [ordering] Ordering
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyCanary(id: string, organizationId?: string, status?: DestroyCanaryStatusEnum, ordering?: DestroyCanaryOrderingEnum, search?: string, options?: any): AxiosPromise<void> {
            return localVarFp.destroyCanary(id, organizationId, status, ordering, search, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint to manipulate CustomCanaryPrototypeSource
         * @param {string} id A UUID string identifying this Canary Prototype Source Code.
         * @param {string} [canaryTemplateLibraryId] canary_template_library_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyCanaryPrototypeSourceCode(id: string, canaryTemplateLibraryId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.destroyCanaryPrototypeSourceCode(id, canaryTemplateLibraryId, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint to manipulate CustomCanaryRestfulLibSource
         * @param {string} id A UUID string identifying this Canary Restful Library Source Code.
         * @param {string} [restfulLibraryId] restful_library_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyCanaryRestfulLibSourceCode(id: string, restfulLibraryId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.destroyCanaryRestfulLibSourceCode(id, restfulLibraryId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get or update a single CanaryTask
         * @param {string} id A UUID string identifying this Canary Task.
         * @param {string} [canaryId] canary_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyCanaryTask(id: string, canaryId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.destroyCanaryTask(id, canaryId, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint to manipulate CanaryTemplateLibrary
         * @param {string} id A UUID string identifying this Canary Template Library.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyCanaryTemplateLibrary(id: string, organizationId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.destroyCanaryTemplateLibrary(id, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Expose clusters to users  - important because it shares a security boundary with users - we must allow users to read all platform wide clusters (e.g. ours), but not change anything
         * @param {string} id A UUID string identifying this Kubernetes Cluster.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyKubernetesCluster(id: string, organizationId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.destroyKubernetesCluster(id, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Notification.
         * @param {string} [alarmId] alarm_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyNotification(id: string, alarmId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.destroyNotification(id, alarmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Notification Channel Setting.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyNotificationChannelSetting(id: string, organizationId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.destroyNotificationChannelSetting(id, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint to manipulate CustomCanaryRestfulLibSource
         * @param {string} id A UUID string identifying this OpenAPI Restful Library.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyOpenAPIRestfulLibrary(id: string, organizationId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.destroyOpenAPIRestfulLibrary(id, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Organization.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyOrganization(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.destroyOrganization(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} prefix 
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyOrganizationAPIKey(prefix: string, organizationId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.destroyOrganizationAPIKey(prefix, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * View to manipulate ServiceComplianceColors for an organization
         * @param {string} id A UUID string identifying this Service Compliance Color.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyServiceComplianceColors(id: string, organizationId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.destroyServiceComplianceColors(id, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * View to manipulate ServiceHealthColors for an organization
         * @param {string} id A UUID string identifying this Service Health Color.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyServiceHealthColors(id: string, organizationId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.destroyServiceHealthColors(id, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * View to get ServiceHealthEvaluationSettingsGroup for an organization
         * @param {string} id A UUID string identifying this Service Health Evaluation Settings Group.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyServiceHealthEvaluationSettingsGroup(id: string, organizationId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.destroyServiceHealthEvaluationSettingsGroup(id, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Simple Alarm.
         * @param {string} [metricId] metric_id
         * @param {string} [canaryId] canary_id
         * @param {string} [taskId] task_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroySimpleAlarm(id: string, metricId?: string, canaryId?: string, taskId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.destroySimpleAlarm(id, metricId, canaryId, taskId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Snowflake Setting.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroySnowflake(id: string, organizationId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.destroySnowflake(id, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Snowflake Forecast Model.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroySnowflakeForecastModel(id: string, organizationId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.destroySnowflakeForecastModel(id, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint to manipulate StoreCanary
         * @param {string} id A UUID string identifying this Store Canary.
         * @param {string} [cloud] cloud
         * @param {string} [component] component
         * @param {string} [status] status
         * @param {string} [isPrivate] is_private
         * @param {string} [isPinnedCanary] is_pinned_canary
         * @param {string} [uploadedUser] uploaded_user
         * @param {string} [company] company
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyStoreCanary(id: string, cloud?: string, component?: string, status?: string, isPrivate?: string, isPinnedCanary?: string, uploadedUser?: string, company?: string, search?: string, options?: any): AxiosPromise<void> {
            return localVarFp.destroyStoreCanary(id, cloud, component, status, isPrivate, isPinnedCanary, uploadedUser, company, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Subscription Cancel Request.
         * @param {string} [subscriptionId] subscription_id
         * @param {string} [subscriptionUserId] subscription_user_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroySubscriptionCancelRequest(id: string, subscriptionId?: string, subscriptionUserId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.destroySubscriptionCancelRequest(id, subscriptionId, subscriptionUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint to manipulate TemplateCanary
         * @param {string} id A UUID string identifying this Template Canary.
         * @param {string} [canaryTemplateLibraryId] canary_template_library_id
         * @param {string} [providerId] provider_id
         * @param {string} [serviceId] service_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyTemplateCanary(id: string, canaryTemplateLibraryId?: string, providerId?: string, serviceId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.destroyTemplateCanary(id, canaryTemplateLibraryId, providerId, serviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint to manipulate TemplateTask
         * @param {string} id A UUID string identifying this Template Task.
         * @param {string} [canaryTemplateLibraryId] canary_template_library_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyTemplateTask(id: string, canaryTemplateLibraryId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.destroyTemplateTask(id, canaryTemplateLibraryId, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint that allows authenticated clients to view or edit users
         * @param {string} id A UUID string identifying this user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyUser(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.destroyUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this User Subscribed Plan.
         * @param {string} [userId] user_id
         * @param {string} [platformActive] platform_active
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyUserSubscribedPlan(id: string, userId?: string, platformActive?: string, options?: any): AxiosPromise<void> {
            return localVarFp.destroyUserSubscribedPlan(id, userId, platformActive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCloudCanariesArticleUserGatedContentDownloadFileReadOnly(options?: any): AxiosPromise<any> {
            return localVarFp.downloadCloudCanariesArticleUserGatedContentDownloadFileReadOnly(options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint to manipulate CustomCanaryRestfulLibSource
         * @param {string} id A UUID string identifying this OpenAPI Restful Library.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadRestfulZipOpenAPIRestfulLibrary(id: string, options?: any): AxiosPromise<File> {
            return localVarFp.downloadRestfulZipOpenAPIRestfulLibrary(id, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint to manipulate CustomCanaryRestfulLibSource
         * @param {string} id A UUID string identifying this OpenAPI Restful Library.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTemplateZipOpenAPIRestfulLibrary(id: string, options?: any): AxiosPromise<File> {
            return localVarFp.downloadTemplateZipOpenAPIRestfulLibrary(id, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint that manages Canarys
         * @param {string} id A UUID string identifying this Canary.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAndDownloadCanaryAgentCanary(id: string, options?: any): AxiosPromise<File> {
            return localVarFp.generateAndDownloadCanaryAgentCanary(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrganizationAPIKeyContents} [organizationAPIKeyContents] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateKeyOrganizationAPIKey(organizationAPIKeyContents?: OrganizationAPIKeyContents, options?: any): AxiosPromise<OrganizationAPIKeyContents> {
            return localVarFp.generateKeyOrganizationAPIKey(organizationAPIKeyContents, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [metricId] metric_id
         * @param {string} [canaryId] canary_id
         * @param {string} [taskId] task_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAbstractMetricBoundaryConditions(page?: number, pageSize?: number, metricId?: string, canaryId?: string, taskId?: string, options?: any): AxiosPromise<ListAbstractMetricBoundaryConditions200Response> {
            return localVarFp.listAbstractMetricBoundaryConditions(page, pageSize, metricId, canaryId, taskId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [canaryId] canary_id
         * @param {string} [taskId] task_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAbstractMetrics(page?: number, pageSize?: number, canaryId?: string, taskId?: string, options?: any): AxiosPromise<ListAbstractMetrics200Response> {
            return localVarFp.listAbstractMetrics(page, pageSize, canaryId, taskId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [userId] user_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBillingDetails(page?: number, pageSize?: number, userId?: string, options?: any): AxiosPromise<ListBillingDetails200Response> {
            return localVarFp.listBillingDetails(page, pageSize, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint that manages Canarys
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] organization_id
         * @param {ListCanariesStatusEnum} [status] status
         * @param {ListCanariesOrderingEnum} [ordering] Ordering
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCanaries(page?: number, pageSize?: number, organizationId?: string, status?: ListCanariesStatusEnum, ordering?: ListCanariesOrderingEnum, search?: string, options?: any): AxiosPromise<ListCanaries200Response> {
            return localVarFp.listCanaries(page, pageSize, organizationId, status, ordering, search, options).then((request) => request(axios, basePath));
        },
        /**
         * A readonly ViewSet for viewing CanaryDailyForecast\'s
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCanaryDailyForecasts(page?: number, pageSize?: number, start?: string, stop?: string, organizationId?: string, options?: any): AxiosPromise<ListCanaryDailyForecasts200Response> {
            return localVarFp.listCanaryDailyForecasts(page, pageSize, start, stop, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [isActive] is_active
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCanaryPartners(page?: number, pageSize?: number, isActive?: string, options?: any): AxiosPromise<ListCanaryPartners200Response> {
            return localVarFp.listCanaryPartners(page, pageSize, isActive, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint to manipulate CustomCanaryPrototypeSource
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [canaryTemplateLibraryId] canary_template_library_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCanaryPrototypeSourceCodes(page?: number, pageSize?: number, canaryTemplateLibraryId?: string, options?: any): AxiosPromise<ListCanaryPrototypeSourceCodes200Response> {
            return localVarFp.listCanaryPrototypeSourceCodes(page, pageSize, canaryTemplateLibraryId, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint to manipulate CustomCanaryRestfulLibSource
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [restfulLibraryId] restful_library_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCanaryRestfulLibSourceCodes(page?: number, pageSize?: number, restfulLibraryId?: string, options?: any): AxiosPromise<ListCanaryRestfulLibSourceCodes200Response> {
            return localVarFp.listCanaryRestfulLibSourceCodes(page, pageSize, restfulLibraryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [measuredFrom] measured_from
         * @param {string} [measuredUntil] measured_until
         * @param {string} [metricId] metric_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCanaryTaskMetricData(page?: number, pageSize?: number, measuredFrom?: string, measuredUntil?: string, metricId?: string, options?: any): AxiosPromise<ListCanaryTaskMetricData200Response> {
            return localVarFp.listCanaryTaskMetricData(page, pageSize, measuredFrom, measuredUntil, metricId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [measuredFrom] The Start date of the data export period, should be in this format: YYYY-MM-DD+HH%3AMM, note that %3A means \&#39;:\&#39; so don\&#39;t remove it.
         * @param {string} [measuredUntil] The End date of the data export period, should be in this format: YYYY-MM-DD+HH%3AMM, note that %3A means \&#39;:\&#39; so don\&#39;t remove it.
         * @param {string} [canaryId] The Canary id to get the exported data for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCanaryTaskRunDataExports(page?: number, pageSize?: number, measuredFrom?: string, measuredUntil?: string, canaryId?: string, options?: any): AxiosPromise<ListCanaryTaskRunDataExports200Response> {
            return localVarFp.listCanaryTaskRunDataExports(page, pageSize, measuredFrom, measuredUntil, canaryId, options).then((request) => request(axios, basePath));
        },
        /**
         * View to get CanaryTaskData as a CSV Export  * Requires token authentication.
         * @param {string} [measuredFrom] The Start date of the data export period, should be in this format: YYYY-MM-DD+HH%3AMM, note that %3A means \&#39;:\&#39; so don\&#39;t remove it.
         * @param {string} [measuredUntil] The End date of the data export period, should be in this format: YYYY-MM-DD+HH%3AMM, note that %3A means \&#39;:\&#39; so don\&#39;t remove it.
         * @param {string} [canaryId] The Canary id to get the exported data for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCanaryTaskRunDataExports_1(measuredFrom?: string, measuredUntil?: string, canaryId?: string, options?: any): AxiosPromise<Array<CanaryTaskRunDataExportReadOnly>> {
            return localVarFp.listCanaryTaskRunDataExports_1(measuredFrom, measuredUntil, canaryId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get or update a single CanaryTask
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [canaryId] canary_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCanaryTasks(page?: number, pageSize?: number, canaryId?: string, options?: any): AxiosPromise<ListCanaryTasks200Response> {
            return localVarFp.listCanaryTasks(page, pageSize, canaryId, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint to manipulate CanaryTemplateLibrary
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCanaryTemplateLibraries(page?: number, pageSize?: number, organizationId?: string, options?: any): AxiosPromise<ListCanaryTemplateLibraries200Response> {
            return localVarFp.listCanaryTemplateLibraries(page, pageSize, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [isActive] is_active
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCanaryUsers(page?: number, pageSize?: number, isActive?: string, options?: any): AxiosPromise<ListCanaryUsers200Response> {
            return localVarFp.listCanaryUsers(page, pageSize, isActive, options).then((request) => request(axios, basePath));
        },
        /**
         * A readonly ViewSet for viewing Canaryable Services.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [providerId] provider_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCanaryableServices(page?: number, pageSize?: number, providerId?: string, options?: any): AxiosPromise<ListCanaryableServices200Response> {
            return localVarFp.listCanaryableServices(page, pageSize, providerId, options).then((request) => request(axios, basePath));
        },
        /**
         * A readonly ViewSet for viewing CompositeDailyForecasts Services.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCompositeDailyForecasts(page?: number, pageSize?: number, start?: string, stop?: string, organizationId?: string, options?: any): AxiosPromise<ListCompositeDailyForecasts200Response> {
            return localVarFp.listCompositeDailyForecasts(page, pageSize, start, stop, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCustomUserRoles(page?: number, pageSize?: number, options?: any): AxiosPromise<ListCustomUserRoles200Response> {
            return localVarFp.listCustomUserRoles(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * Expose clusters to users  - important because it shares a security boundary with users - we must allow users to read all platform wide clusters (e.g. ours), but not change anything
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listKubernetesClusters(page?: number, pageSize?: number, organizationId?: string, options?: any): AxiosPromise<ListKubernetesClusters200Response> {
            return localVarFp.listKubernetesClusters(page, pageSize, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [isActive] is_active
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLandingPageVideos(page?: number, pageSize?: number, isActive?: string, options?: any): AxiosPromise<ListLandingPageVideos200Response> {
            return localVarFp.listLandingPageVideos(page, pageSize, isActive, options).then((request) => request(axios, basePath));
        },
        /**
         * A readonly ViewSet for viewing NeuralProphetCanaryDailyForecastData\'s
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {string} [canaryId] canary_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNeuralProphetCanaryDailyForecastData(page?: number, pageSize?: number, start?: string, stop?: string, organizationId?: string, canaryId?: string, options?: any): AxiosPromise<ListNeuralProphetCanaryDailyForecastData200Response> {
            return localVarFp.listNeuralProphetCanaryDailyForecastData(page, pageSize, start, stop, organizationId, canaryId, options).then((request) => request(axios, basePath));
        },
        /**
         * View to get NeuralServiceDailyForecast as a CSV Export  * Requires token authentication.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [canaryId] canary_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNeuralProphetCanaryDailyForecastDataExports(start?: string, stop?: string, canaryId?: string, options?: any): AxiosPromise<Array<NeuralCanaryDailyForecast>> {
            return localVarFp.listNeuralProphetCanaryDailyForecastDataExports(start, stop, canaryId, options).then((request) => request(axios, basePath));
        },
        /**
         * A readonly ViewSet for viewing NeuralProphetCanaryDailyForecast\'s
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {string} [canaryId] canary_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNeuralProphetCanaryDailyForecasts(page?: number, pageSize?: number, start?: string, stop?: string, organizationId?: string, canaryId?: string, options?: any): AxiosPromise<ListNeuralProphetCanaryDailyForecasts200Response> {
            return localVarFp.listNeuralProphetCanaryDailyForecasts(page, pageSize, start, stop, organizationId, canaryId, options).then((request) => request(axios, basePath));
        },
        /**
         * A readonly ViewSet for viewing NeuralProphetCompositeDailyForecast Services.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNeuralProphetCompositeDailyForecasts(page?: number, pageSize?: number, start?: string, stop?: string, organizationId?: string, options?: any): AxiosPromise<ListNeuralProphetCompositeDailyForecasts200Response> {
            return localVarFp.listNeuralProphetCompositeDailyForecasts(page, pageSize, start, stop, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * A readonly ViewSet for viewing NeuralProphetServiceDailyForecast
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNeuralProphetServiceDailyForecasts(page?: number, pageSize?: number, start?: string, stop?: string, organizationId?: string, options?: any): AxiosPromise<ListNeuralProphetServiceDailyForecasts200Response> {
            return localVarFp.listNeuralProphetServiceDailyForecasts(page, pageSize, start, stop, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNotificationChannelSettings(page?: number, pageSize?: number, organizationId?: string, options?: any): AxiosPromise<ListNotificationChannelSettings200Response> {
            return localVarFp.listNotificationChannelSettings(page, pageSize, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [alarmId] alarm_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNotificationHistories(page?: number, pageSize?: number, alarmId?: string, options?: any): AxiosPromise<ListNotificationHistories200Response> {
            return localVarFp.listNotificationHistories(page, pageSize, alarmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [alarmId] alarm_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNotifications(page?: number, pageSize?: number, alarmId?: string, options?: any): AxiosPromise<ListNotifications200Response> {
            return localVarFp.listNotifications(page, pageSize, alarmId, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint to manipulate CustomCanaryRestfulLibSource
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOpenAPIRestfulLibraries(page?: number, pageSize?: number, organizationId?: string, options?: any): AxiosPromise<ListOpenAPIRestfulLibraries200Response> {
            return localVarFp.listOpenAPIRestfulLibraries(page, pageSize, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrganizationAPIKeys(page?: number, pageSize?: number, organizationId?: string, options?: any): AxiosPromise<ListOrganizationAPIKeys200Response> {
            return localVarFp.listOrganizationAPIKeys(page, pageSize, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrganizations(page?: number, pageSize?: number, options?: any): AxiosPromise<ListOrganizations200Response> {
            return localVarFp.listOrganizations(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint that gets the Canaries * Requires token authentication.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] The Organization id to filter with
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPublicAPICanaries(page?: number, pageSize?: number, organizationId?: string, options?: any): AxiosPromise<ListPublicAPICanaries200Response> {
            return localVarFp.listPublicAPICanaries(page, pageSize, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * View to get CanaryTaskData as a CSV Export  * Requires token authentication.
         * @param {string} [measuredFrom] The Start date of the data export period, should be in this format: YYYY-MM-DD+HH%3AMM, note that %3A means \&#39;:\&#39; so don\&#39;t remove it.
         * @param {string} [measuredUntil] The End date of the data export period, should be in this format: YYYY-MM-DD+HH%3AMM, note that %3A means \&#39;:\&#39; so don\&#39;t remove it.
         * @param {string} [canaryId] The Canary id to get the exported data for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPublicAPICanaryTaskRunDataExports(measuredFrom?: string, measuredUntil?: string, canaryId?: string, options?: any): AxiosPromise<Array<CanaryTaskRunDataExportReadOnly>> {
            return localVarFp.listPublicAPICanaryTaskRunDataExports(measuredFrom, measuredUntil, canaryId, options).then((request) => request(axios, basePath));
        },
        /**
         * A readonly ViewSet for viewing Canaryable Services.  * Requires token authentication.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [providerId] The Provider id to filter with
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPublicAPICanaryableServices(page?: number, pageSize?: number, providerId?: string, options?: any): AxiosPromise<ListPublicAPICanaryableServices200Response> {
            return localVarFp.listPublicAPICanaryableServices(page, pageSize, providerId, options).then((request) => request(axios, basePath));
        },
        /**
         * A readonly ViewSet for viewing NeuralProphetCanaryDailyForecast\'s
         * @param {string} [canaryId] The Canary ID to filter with
         * @param {string} [organizationId] The Organization ID to filter with
         * @param {string} [start] The Start date of the forecasting period, should be the next day date and in this format: YYYY-MM-DD
         * @param {string} [stop] The End date of the forecasting period, should be after 5 days and in this format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPublicAPINeuralProphetCanaryDailyForecasts(canaryId?: string, organizationId?: string, start?: string, stop?: string, options?: any): AxiosPromise<Array<PublicAPINeuralCanaryDailyForecast>> {
            return localVarFp.listPublicAPINeuralProphetCanaryDailyForecasts(canaryId, organizationId, start, stop, options).then((request) => request(axios, basePath));
        },
        /**
         * A readonly ViewSet for viewing Incident probability in the next 24 hours, should return 1 entry for the next day entered date.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] The Organization ID to filter with
         * @param {string} [start] The Start date of the forecasting period, should be the next day date and in this format: YYYY-MM-DD
         * @param {string} [stop] The End date of the forecasting period, should be the next day date and in this format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPublicAPINeuralProphetCompositeDailyForecasts(page?: number, pageSize?: number, organizationId?: string, start?: string, stop?: string, options?: any): AxiosPromise<ListPublicAPINeuralProphetCompositeDailyForecasts200Response> {
            return localVarFp.listPublicAPINeuralProphetCompositeDailyForecasts(page, pageSize, organizationId, start, stop, options).then((request) => request(axios, basePath));
        },
        /**
         * View to get Organizations for a user  * Requires token authentication.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPublicAPIOrganizations(page?: number, pageSize?: number, options?: any): AxiosPromise<ListPublicAPIOrganizations200Response> {
            return localVarFp.listPublicAPIOrganizations(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * A readonly ViewSet for viewing ServiceCompliance Summaries.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] The Organization id to filter with
         * @param {ListPublicAPIServiceComplianceSummariesPeriodCategoryEnum} [periodCategory] The Period Category to filter with, options are: \&#39;weekly\&#39;, \&#39;monthly\&#39;, \&#39;quarterly\&#39;, \&#39;yearly\&#39;
         * @param {string} [start] The Start date of the compliance period, should be in this format: YYYY-MM-DD
         * @param {string} [stop] The Stop date of the compliance period, should be in this format: YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPublicAPIServiceComplianceSummaries(page?: number, pageSize?: number, organizationId?: string, periodCategory?: ListPublicAPIServiceComplianceSummariesPeriodCategoryEnum, start?: string, stop?: string, options?: any): AxiosPromise<ListPublicAPIServiceComplianceSummaries200Response> {
            return localVarFp.listPublicAPIServiceComplianceSummaries(page, pageSize, organizationId, periodCategory, start, stop, options).then((request) => request(axios, basePath));
        },
        /**
         * View to get ServiceHealth of a group of canaries * Requires token authentication.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] The Organization id to filter with
         * @param {string} [serviceId] The Service id to filter with
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPublicAPIServiceHealths(page?: number, pageSize?: number, organizationId?: string, serviceId?: string, options?: any): AxiosPromise<ListPublicAPIServiceHealths200Response> {
            return localVarFp.listPublicAPIServiceHealths(page, pageSize, organizationId, serviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * A readonly ViewSet for viewing ServiceProviders.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPublicAPIServiceProviders(page?: number, pageSize?: number, options?: any): AxiosPromise<ListPublicAPIServiceProviders200Response> {
            return localVarFp.listPublicAPIServiceProviders(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * View to manipulate ServiceComplianceColors for an organization
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listServiceComplianceColors(organizationId?: string, options?: any): AxiosPromise<Array<ServiceComplianceColors>> {
            return localVarFp.listServiceComplianceColors(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * A readonly ViewSet for viewing ServiceCompliance Histories
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] organization_id
         * @param {string} [stop] stop
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listServiceComplianceHistories(page?: number, pageSize?: number, organizationId?: string, stop?: string, options?: any): AxiosPromise<ListServiceComplianceHistories200Response> {
            return localVarFp.listServiceComplianceHistories(page, pageSize, organizationId, stop, options).then((request) => request(axios, basePath));
        },
        /**
         * A readonly ViewSet for viewing ServiceCompliance Summaries.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] organization_id
         * @param {ListServiceComplianceSummariesPeriodCategoryEnum} [periodCategory] period_category
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listServiceComplianceSummaries(page?: number, pageSize?: number, organizationId?: string, periodCategory?: ListServiceComplianceSummariesPeriodCategoryEnum, start?: string, stop?: string, options?: any): AxiosPromise<ListServiceComplianceSummaries200Response> {
            return localVarFp.listServiceComplianceSummaries(page, pageSize, organizationId, periodCategory, start, stop, options).then((request) => request(axios, basePath));
        },
        /**
         * A readonly ViewSet for viewing ServiceDailyForecasts
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listServiceDailyForecasts(page?: number, pageSize?: number, start?: string, stop?: string, organizationId?: string, options?: any): AxiosPromise<ListServiceDailyForecasts200Response> {
            return localVarFp.listServiceDailyForecasts(page, pageSize, start, stop, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * View to manipulate ServiceHealthColors for an organization
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listServiceHealthColors(organizationId?: string, options?: any): AxiosPromise<Array<ServiceHealthColors>> {
            return localVarFp.listServiceHealthColors(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * View to get ServiceHealthEvaluationSettingsGroup for an organization
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listServiceHealthEvaluationSettingsGroups(page?: number, pageSize?: number, organizationId?: string, options?: any): AxiosPromise<ListServiceHealthEvaluationSettingsGroups200Response> {
            return localVarFp.listServiceHealthEvaluationSettingsGroups(page, pageSize, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] organization_id
         * @param {string} [serviceId] service_id
         * @param {ListServiceHealthIncidentsIncidentStatusEnum} [incidentStatus] incident_status
         * @param {ListServiceHealthIncidentsOrderingEnum} [ordering] Ordering
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listServiceHealthIncidents(page?: number, pageSize?: number, organizationId?: string, serviceId?: string, incidentStatus?: ListServiceHealthIncidentsIncidentStatusEnum, ordering?: ListServiceHealthIncidentsOrderingEnum, options?: any): AxiosPromise<ListServiceHealthIncidents200Response> {
            return localVarFp.listServiceHealthIncidents(page, pageSize, organizationId, serviceId, incidentStatus, ordering, options).then((request) => request(axios, basePath));
        },
        /**
         * View to get ServiceHealth of a group of canaries
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] organization_id
         * @param {string} [providerId] provider_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listServiceHealths(page?: number, pageSize?: number, organizationId?: string, providerId?: string, options?: any): AxiosPromise<ListServiceHealths200Response> {
            return localVarFp.listServiceHealths(page, pageSize, organizationId, providerId, options).then((request) => request(axios, basePath));
        },
        /**
         * A readonly ViewSet for viewing ServiceProviderAvailabilityZone\'s.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [providerId] provider_id
         * @param {string} [regionId] region_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listServiceProviderAvailabilityZones(page?: number, pageSize?: number, providerId?: string, regionId?: string, options?: any): AxiosPromise<ListServiceProviderAvailabilityZones200Response> {
            return localVarFp.listServiceProviderAvailabilityZones(page, pageSize, providerId, regionId, options).then((request) => request(axios, basePath));
        },
        /**
         * A readonly ViewSet for viewing ServiceProviderRegion\'s.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [providerId] provider_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listServiceProviderRegions(page?: number, pageSize?: number, providerId?: string, options?: any): AxiosPromise<ListServiceProviderRegions200Response> {
            return localVarFp.listServiceProviderRegions(page, pageSize, providerId, options).then((request) => request(axios, basePath));
        },
        /**
         * A readonly ViewSet for viewing ServiceProviders.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listServiceProviders(page?: number, pageSize?: number, organizationId?: string, options?: any): AxiosPromise<ListServiceProviders200Response> {
            return localVarFp.listServiceProviders(page, pageSize, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [alarmId] alarm_id
         * @param {string} [periodStart] period_start
         * @param {string} [periodStop] period_stop
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSimpleAlarmEvaluations(page?: number, pageSize?: number, alarmId?: string, periodStart?: string, periodStop?: string, options?: any): AxiosPromise<ListSimpleAlarmEvaluations200Response> {
            return localVarFp.listSimpleAlarmEvaluations(page, pageSize, alarmId, periodStart, periodStop, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [alarmId] alarm_id
         * @param {string} [evaluationPeriodStart] evaluation_period_start
         * @param {string} [evaluationPeriodStop] evaluation_period_stop
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSimpleAlarmHistories(page?: number, pageSize?: number, alarmId?: string, evaluationPeriodStart?: string, evaluationPeriodStop?: string, options?: any): AxiosPromise<ListSimpleAlarmHistories200Response> {
            return localVarFp.listSimpleAlarmHistories(page, pageSize, alarmId, evaluationPeriodStart, evaluationPeriodStop, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [metricId] metric_id
         * @param {string} [canaryId] canary_id
         * @param {string} [taskId] task_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSimpleAlarms(page?: number, pageSize?: number, metricId?: string, canaryId?: string, taskId?: string, options?: any): AxiosPromise<ListSimpleAlarms200Response> {
            return localVarFp.listSimpleAlarms(page, pageSize, metricId, canaryId, taskId, options).then((request) => request(axios, basePath));
        },
        /**
         * A readonly ViewSet for viewing SnowflakeCanaryDailyForecastData Services.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {string} [canaryId] canary_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSnowflakeCanaryDailyForecastData(page?: number, pageSize?: number, start?: string, stop?: string, organizationId?: string, canaryId?: string, options?: any): AxiosPromise<ListSnowflakeCanaryDailyForecastData200Response> {
            return localVarFp.listSnowflakeCanaryDailyForecastData(page, pageSize, start, stop, organizationId, canaryId, options).then((request) => request(axios, basePath));
        },
        /**
         * A readonly ViewSet for viewing SnowflakeCanaryDailyForecast\'s
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {string} [canaryId] canary_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSnowflakeCanaryDailyForecasts(page?: number, pageSize?: number, start?: string, stop?: string, organizationId?: string, canaryId?: string, options?: any): AxiosPromise<ListSnowflakeCanaryDailyForecasts200Response> {
            return localVarFp.listSnowflakeCanaryDailyForecasts(page, pageSize, start, stop, organizationId, canaryId, options).then((request) => request(axios, basePath));
        },
        /**
         * A readonly ViewSet for viewing SnowflakeCompositeDailyForecast Services.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSnowflakeCompositeDailyForecasts(page?: number, pageSize?: number, start?: string, stop?: string, organizationId?: string, options?: any): AxiosPromise<ListSnowflakeCompositeDailyForecasts200Response> {
            return localVarFp.listSnowflakeCompositeDailyForecasts(page, pageSize, start, stop, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSnowflakeForecastModels(page?: number, pageSize?: number, organizationId?: string, options?: any): AxiosPromise<ListSnowflakeForecastModels200Response> {
            return localVarFp.listSnowflakeForecastModels(page, pageSize, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * A readonly ViewSet for viewing SnowflakeServiceDailyForecast
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSnowflakeServiceDailyForecasts(page?: number, pageSize?: number, start?: string, stop?: string, organizationId?: string, options?: any): AxiosPromise<ListSnowflakeServiceDailyForecasts200Response> {
            return localVarFp.listSnowflakeServiceDailyForecasts(page, pageSize, start, stop, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSnowflakes(page?: number, pageSize?: number, organizationId?: string, options?: any): AxiosPromise<ListSnowflakes200Response> {
            return localVarFp.listSnowflakes(page, pageSize, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint to manipulate StoreCanary
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [cloud] cloud
         * @param {string} [component] component
         * @param {string} [status] status
         * @param {string} [isPrivate] is_private
         * @param {string} [isPinnedCanary] is_pinned_canary
         * @param {string} [uploadedUser] uploaded_user
         * @param {string} [company] company
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStoreCanaries(page?: number, pageSize?: number, cloud?: string, component?: string, status?: string, isPrivate?: string, isPinnedCanary?: string, uploadedUser?: string, company?: string, search?: string, options?: any): AxiosPromise<ListStoreCanaries200Response> {
            return localVarFp.listStoreCanaries(page, pageSize, cloud, component, status, isPrivate, isPinnedCanary, uploadedUser, company, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStoreCanaryClouds(page?: number, pageSize?: number, options?: any): AxiosPromise<ListStoreCanaryClouds200Response> {
            return localVarFp.listStoreCanaryClouds(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStoreCanaryComponents(page?: number, pageSize?: number, options?: any): AxiosPromise<ListStoreCanaryComponents200Response> {
            return localVarFp.listStoreCanaryComponents(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStoreCanaryProducts(page?: number, pageSize?: number, options?: any): AxiosPromise<ListStoreCanaryProducts200Response> {
            return localVarFp.listStoreCanaryProducts(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [subscriptionId] subscription_id
         * @param {string} [subscriptionUserId] subscription_user_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSubscriptionCancelRequests(page?: number, pageSize?: number, subscriptionId?: string, subscriptionUserId?: string, options?: any): AxiosPromise<ListSubscriptionCancelRequests200Response> {
            return localVarFp.listSubscriptionCancelRequests(page, pageSize, subscriptionId, subscriptionUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint to manipulate TemplateCanary
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [canaryTemplateLibraryId] canary_template_library_id
         * @param {string} [providerId] provider_id
         * @param {string} [serviceId] service_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTemplateCanaries(page?: number, pageSize?: number, canaryTemplateLibraryId?: string, providerId?: string, serviceId?: string, options?: any): AxiosPromise<ListTemplateCanaries200Response> {
            return localVarFp.listTemplateCanaries(page, pageSize, canaryTemplateLibraryId, providerId, serviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint to manipulate TemplateTask
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [canaryTemplateLibraryId] canary_template_library_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTemplateTasks(page?: number, pageSize?: number, canaryTemplateLibraryId?: string, options?: any): AxiosPromise<ListTemplateTasks200Response> {
            return localVarFp.listTemplateTasks(page, pageSize, canaryTemplateLibraryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserGatedContentDownloadRequests(page?: number, pageSize?: number, options?: any): AxiosPromise<ListUserGatedContentDownloadRequests200Response> {
            return localVarFp.listUserGatedContentDownloadRequests(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [userId] user_id
         * @param {string} [platformActive] platform_active
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserSubscribedPlans(page?: number, pageSize?: number, userId?: string, platformActive?: string, options?: any): AxiosPromise<ListUserSubscribedPlans200Response> {
            return localVarFp.listUserSubscribedPlans(page, pageSize, userId, platformActive, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint that allows authenticated clients to view or edit users
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsers(page?: number, pageSize?: number, options?: any): AxiosPromise<ListUsers200Response> {
            return localVarFp.listUsers(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint to manipulate StoreCanary
         * @param {string} id A UUID string identifying this Store Canary.
         * @param {StoreCanaryAutoLoadCanaryTemplateLibraryReadOnly} [storeCanaryAutoLoadCanaryTemplateLibraryReadOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadTemplateLibrariesStoreCanary(id: string, storeCanaryAutoLoadCanaryTemplateLibraryReadOnly?: StoreCanaryAutoLoadCanaryTemplateLibraryReadOnly, options?: any): AxiosPromise<StoreCanaryAutoLoadCanaryTemplateLibraryReadOnly> {
            return localVarFp.loadTemplateLibrariesStoreCanary(id, storeCanaryAutoLoadCanaryTemplateLibraryReadOnly, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutUserAuthViewSet(options?: any): AxiosPromise<any> {
            return localVarFp.logoutUserAuthViewSet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Abstract Metric Condition.
         * @param {string} [metricId] metric_id
         * @param {string} [canaryId] canary_id
         * @param {string} [taskId] task_id
         * @param {AbstractMetricBoundaryCondition} [abstractMetricBoundaryCondition] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateAbstractMetricBoundaryCondition(id: string, metricId?: string, canaryId?: string, taskId?: string, abstractMetricBoundaryCondition?: AbstractMetricBoundaryCondition, options?: any): AxiosPromise<AbstractMetricBoundaryCondition> {
            return localVarFp.partialUpdateAbstractMetricBoundaryCondition(id, metricId, canaryId, taskId, abstractMetricBoundaryCondition, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this billing detail.
         * @param {string} [userId] user_id
         * @param {BillingDetail} [billingDetail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateBillingDetail(id: string, userId?: string, billingDetail?: BillingDetail, options?: any): AxiosPromise<BillingDetail> {
            return localVarFp.partialUpdateBillingDetail(id, userId, billingDetail, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint that manages Canarys
         * @param {string} id A UUID string identifying this Canary.
         * @param {string} [organizationId] organization_id
         * @param {PartialUpdateCanaryStatusEnum} [status] status
         * @param {PartialUpdateCanaryOrderingEnum} [ordering] Ordering
         * @param {string} [search] A search term.
         * @param {Canary} [canary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateCanary(id: string, organizationId?: string, status?: PartialUpdateCanaryStatusEnum, ordering?: PartialUpdateCanaryOrderingEnum, search?: string, canary?: Canary, options?: any): AxiosPromise<Canary> {
            return localVarFp.partialUpdateCanary(id, organizationId, status, ordering, search, canary, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this canary cluster deployment.
         * @param {CanaryClusterDeployment} [canaryClusterDeployment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateCanaryClusterDeployment(id: string, canaryClusterDeployment?: CanaryClusterDeployment, options?: any): AxiosPromise<CanaryClusterDeployment> {
            return localVarFp.partialUpdateCanaryClusterDeployment(id, canaryClusterDeployment, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint to manipulate CustomCanaryPrototypeSource
         * @param {string} id A UUID string identifying this Canary Prototype Source Code.
         * @param {string} [canaryTemplateLibraryId] canary_template_library_id
         * @param {CanaryPrototypeSource} [canaryPrototypeSource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateCanaryPrototypeSourceCode(id: string, canaryTemplateLibraryId?: string, canaryPrototypeSource?: CanaryPrototypeSource, options?: any): AxiosPromise<CanaryPrototypeSource> {
            return localVarFp.partialUpdateCanaryPrototypeSourceCode(id, canaryTemplateLibraryId, canaryPrototypeSource, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint to manipulate CustomCanaryRestfulLibSource
         * @param {string} id A UUID string identifying this Canary Restful Library Source Code.
         * @param {string} [restfulLibraryId] restful_library_id
         * @param {CanaryRestfulLibSourceFile} [canaryRestfulLibSourceFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateCanaryRestfulLibSourceCode(id: string, restfulLibraryId?: string, canaryRestfulLibSourceFile?: CanaryRestfulLibSourceFile, options?: any): AxiosPromise<CanaryRestfulLibSourceFile> {
            return localVarFp.partialUpdateCanaryRestfulLibSourceCode(id, restfulLibraryId, canaryRestfulLibSourceFile, options).then((request) => request(axios, basePath));
        },
        /**
         * Get or update a single CanaryTask
         * @param {string} id A UUID string identifying this Canary Task.
         * @param {string} [canaryId] canary_id
         * @param {CanaryTask} [canaryTask] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateCanaryTask(id: string, canaryId?: string, canaryTask?: CanaryTask, options?: any): AxiosPromise<CanaryTask> {
            return localVarFp.partialUpdateCanaryTask(id, canaryId, canaryTask, options).then((request) => request(axios, basePath));
        },
        /**
         * Get or update a single CanaryTask
         * @param {string} id A UUID string identifying this canary task env vars.
         * @param {CanaryTaskEnvVars} [canaryTaskEnvVars] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateCanaryTaskEnvVars(id: string, canaryTaskEnvVars?: CanaryTaskEnvVars, options?: any): AxiosPromise<CanaryTaskEnvVars> {
            return localVarFp.partialUpdateCanaryTaskEnvVars(id, canaryTaskEnvVars, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint to manipulate CanaryTemplateLibrary
         * @param {string} id A UUID string identifying this Canary Template Library.
         * @param {string} [organizationId] organization_id
         * @param {CanaryTemplateLibrary} [canaryTemplateLibrary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateCanaryTemplateLibrary(id: string, organizationId?: string, canaryTemplateLibrary?: CanaryTemplateLibrary, options?: any): AxiosPromise<CanaryTemplateLibrary> {
            return localVarFp.partialUpdateCanaryTemplateLibrary(id, organizationId, canaryTemplateLibrary, options).then((request) => request(axios, basePath));
        },
        /**
         * Expose clusters to users  - important because it shares a security boundary with users - we must allow users to read all platform wide clusters (e.g. ours), but not change anything
         * @param {string} id A UUID string identifying this Kubernetes Cluster.
         * @param {string} [organizationId] organization_id
         * @param {KubernetesCluster} [kubernetesCluster] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateKubernetesCluster(id: string, organizationId?: string, kubernetesCluster?: KubernetesCluster, options?: any): AxiosPromise<KubernetesCluster> {
            return localVarFp.partialUpdateKubernetesCluster(id, organizationId, kubernetesCluster, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Notification.
         * @param {string} [alarmId] alarm_id
         * @param {Notification} [notification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateNotification(id: string, alarmId?: string, notification?: Notification, options?: any): AxiosPromise<Notification> {
            return localVarFp.partialUpdateNotification(id, alarmId, notification, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Notification Channel Setting.
         * @param {string} [organizationId] organization_id
         * @param {NotificationChannelSetting} [notificationChannelSetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateNotificationChannelSetting(id: string, organizationId?: string, notificationChannelSetting?: NotificationChannelSetting, options?: any): AxiosPromise<NotificationChannelSetting> {
            return localVarFp.partialUpdateNotificationChannelSetting(id, organizationId, notificationChannelSetting, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint to manipulate CustomCanaryRestfulLibSource
         * @param {string} id A UUID string identifying this OpenAPI Restful Library.
         * @param {string} [organizationId] organization_id
         * @param {OpenAPIRestfulLibrary} [openAPIRestfulLibrary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateOpenAPIRestfulLibrary(id: string, organizationId?: string, openAPIRestfulLibrary?: OpenAPIRestfulLibrary, options?: any): AxiosPromise<OpenAPIRestfulLibrary> {
            return localVarFp.partialUpdateOpenAPIRestfulLibrary(id, organizationId, openAPIRestfulLibrary, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Organization.
         * @param {OrganizationUpdate} [organizationUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateOrganization(id: string, organizationUpdate?: OrganizationUpdate, options?: any): AxiosPromise<OrganizationUpdate> {
            return localVarFp.partialUpdateOrganization(id, organizationUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} prefix 
         * @param {string} [organizationId] organization_id
         * @param {OrganizationAPIKey} [organizationAPIKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateOrganizationAPIKey(prefix: string, organizationId?: string, organizationAPIKey?: OrganizationAPIKey, options?: any): AxiosPromise<OrganizationAPIKey> {
            return localVarFp.partialUpdateOrganizationAPIKey(prefix, organizationId, organizationAPIKey, options).then((request) => request(axios, basePath));
        },
        /**
         * View to manipulate ServiceComplianceColors for an organization
         * @param {string} id A UUID string identifying this Service Compliance Color.
         * @param {string} [organizationId] organization_id
         * @param {ServiceComplianceColors} [serviceComplianceColors] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateServiceComplianceColors(id: string, organizationId?: string, serviceComplianceColors?: ServiceComplianceColors, options?: any): AxiosPromise<ServiceComplianceColors> {
            return localVarFp.partialUpdateServiceComplianceColors(id, organizationId, serviceComplianceColors, options).then((request) => request(axios, basePath));
        },
        /**
         * View to manipulate ServiceHealthColors for an organization
         * @param {string} id A UUID string identifying this Service Health Color.
         * @param {string} [organizationId] organization_id
         * @param {ServiceHealthColors} [serviceHealthColors] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateServiceHealthColors(id: string, organizationId?: string, serviceHealthColors?: ServiceHealthColors, options?: any): AxiosPromise<ServiceHealthColors> {
            return localVarFp.partialUpdateServiceHealthColors(id, organizationId, serviceHealthColors, options).then((request) => request(axios, basePath));
        },
        /**
         * View to get ServiceHealthEvaluationSettingsGroup for an organization
         * @param {string} id A UUID string identifying this Service Health Evaluation Settings Group.
         * @param {string} [organizationId] organization_id
         * @param {ServiceHealthEvaluationSettingsGroup} [serviceHealthEvaluationSettingsGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateServiceHealthEvaluationSettingsGroup(id: string, organizationId?: string, serviceHealthEvaluationSettingsGroup?: ServiceHealthEvaluationSettingsGroup, options?: any): AxiosPromise<ServiceHealthEvaluationSettingsGroup> {
            return localVarFp.partialUpdateServiceHealthEvaluationSettingsGroup(id, organizationId, serviceHealthEvaluationSettingsGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Simple Alarm.
         * @param {string} [metricId] metric_id
         * @param {string} [canaryId] canary_id
         * @param {string} [taskId] task_id
         * @param {SimpleAlarm} [simpleAlarm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateSimpleAlarm(id: string, metricId?: string, canaryId?: string, taskId?: string, simpleAlarm?: SimpleAlarm, options?: any): AxiosPromise<SimpleAlarm> {
            return localVarFp.partialUpdateSimpleAlarm(id, metricId, canaryId, taskId, simpleAlarm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Snowflake Setting.
         * @param {string} [organizationId] organization_id
         * @param {Snowflake} [snowflake] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateSnowflake(id: string, organizationId?: string, snowflake?: Snowflake, options?: any): AxiosPromise<Snowflake> {
            return localVarFp.partialUpdateSnowflake(id, organizationId, snowflake, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Snowflake Forecast Model.
         * @param {string} [organizationId] organization_id
         * @param {SnowflakeForecastModel} [snowflakeForecastModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateSnowflakeForecastModel(id: string, organizationId?: string, snowflakeForecastModel?: SnowflakeForecastModel, options?: any): AxiosPromise<SnowflakeForecastModel> {
            return localVarFp.partialUpdateSnowflakeForecastModel(id, organizationId, snowflakeForecastModel, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint to manipulate StoreCanary
         * @param {string} id A UUID string identifying this Store Canary.
         * @param {string} [cloud] cloud
         * @param {string} [component] component
         * @param {string} [status] status
         * @param {string} [isPrivate] is_private
         * @param {string} [isPinnedCanary] is_pinned_canary
         * @param {string} [uploadedUser] uploaded_user
         * @param {string} [company] company
         * @param {string} [search] A search term.
         * @param {StoreCanaryWrite} [storeCanaryWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateStoreCanary(id: string, cloud?: string, component?: string, status?: string, isPrivate?: string, isPinnedCanary?: string, uploadedUser?: string, company?: string, search?: string, storeCanaryWrite?: StoreCanaryWrite, options?: any): AxiosPromise<StoreCanaryWrite> {
            return localVarFp.partialUpdateStoreCanary(id, cloud, component, status, isPrivate, isPinnedCanary, uploadedUser, company, search, storeCanaryWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Subscription Cancel Request.
         * @param {string} [subscriptionId] subscription_id
         * @param {string} [subscriptionUserId] subscription_user_id
         * @param {SubscriptionCancelRequest} [subscriptionCancelRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateSubscriptionCancelRequest(id: string, subscriptionId?: string, subscriptionUserId?: string, subscriptionCancelRequest?: SubscriptionCancelRequest, options?: any): AxiosPromise<SubscriptionCancelRequest> {
            return localVarFp.partialUpdateSubscriptionCancelRequest(id, subscriptionId, subscriptionUserId, subscriptionCancelRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint to manipulate TemplateCanary
         * @param {string} id A UUID string identifying this Template Canary.
         * @param {string} [canaryTemplateLibraryId] canary_template_library_id
         * @param {string} [providerId] provider_id
         * @param {string} [serviceId] service_id
         * @param {TemplateCanaryWritable} [templateCanaryWritable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateTemplateCanary(id: string, canaryTemplateLibraryId?: string, providerId?: string, serviceId?: string, templateCanaryWritable?: TemplateCanaryWritable, options?: any): AxiosPromise<TemplateCanaryWritable> {
            return localVarFp.partialUpdateTemplateCanary(id, canaryTemplateLibraryId, providerId, serviceId, templateCanaryWritable, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint to manipulate TemplateTask
         * @param {string} id A UUID string identifying this Template Task.
         * @param {string} [canaryTemplateLibraryId] canary_template_library_id
         * @param {TemplateTaskReadOnly} [templateTaskReadOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateTemplateTask(id: string, canaryTemplateLibraryId?: string, templateTaskReadOnly?: TemplateTaskReadOnly, options?: any): AxiosPromise<TemplateTaskReadOnly> {
            return localVarFp.partialUpdateTemplateTask(id, canaryTemplateLibraryId, templateTaskReadOnly, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint that allows authenticated clients to view or edit users
         * @param {string} id A UUID string identifying this user.
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateUser(id: string, user?: User, options?: any): AxiosPromise<User> {
            return localVarFp.partialUpdateUser(id, user, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this User Subscribed Plan.
         * @param {string} [userId] user_id
         * @param {string} [platformActive] platform_active
         * @param {UserSubscribedPlan} [userSubscribedPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateUserSubscribedPlan(id: string, userId?: string, platformActive?: string, userSubscribedPlan?: UserSubscribedPlan, options?: any): AxiosPromise<UserSubscribedPlan> {
            return localVarFp.partialUpdateUserSubscribedPlan(id, userId, platformActive, userSubscribedPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshAuthTokenAuthViewSet(body?: any, options?: any): AxiosPromise<any> {
            return localVarFp.refreshAuthTokenAuthViewSet(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Organization.
         * @param {OrganizationMember} [organizationMember] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMemberOrganization(id: string, organizationMember?: OrganizationMember, options?: any): AxiosPromise<OrganizationMember> {
            return localVarFp.removeMemberOrganization(id, organizationMember, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestResetPasswordAuthViewSet(body?: any, options?: any): AxiosPromise<any> {
            return localVarFp.requestResetPasswordAuthViewSet(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordAuthViewSet(body?: any, options?: any): AxiosPromise<any> {
            return localVarFp.resetPasswordAuthViewSet(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Abstract Metric.
         * @param {string} [canaryId] canary_id
         * @param {string} [taskId] task_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveAbstractMetric(id: string, canaryId?: string, taskId?: string, options?: any): AxiosPromise<AbstractMetric> {
            return localVarFp.retrieveAbstractMetric(id, canaryId, taskId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Abstract Metric Condition.
         * @param {string} [metricId] metric_id
         * @param {string} [canaryId] canary_id
         * @param {string} [taskId] task_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveAbstractMetricBoundaryCondition(id: string, metricId?: string, canaryId?: string, taskId?: string, options?: any): AxiosPromise<AbstractMetricBoundaryCondition> {
            return localVarFp.retrieveAbstractMetricBoundaryCondition(id, metricId, canaryId, taskId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveApiVersion(id: string, options?: any): AxiosPromise<ApiVersion> {
            return localVarFp.retrieveApiVersion(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this billing detail.
         * @param {string} [userId] user_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveBillingDetail(id: string, userId?: string, options?: any): AxiosPromise<BillingDetail> {
            return localVarFp.retrieveBillingDetail(id, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint that manages Canarys
         * @param {string} id A UUID string identifying this Canary.
         * @param {string} [organizationId] organization_id
         * @param {RetrieveCanaryStatusEnum} [status] status
         * @param {RetrieveCanaryOrderingEnum} [ordering] Ordering
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCanary(id: string, organizationId?: string, status?: RetrieveCanaryStatusEnum, ordering?: RetrieveCanaryOrderingEnum, search?: string, options?: any): AxiosPromise<Canary> {
            return localVarFp.retrieveCanary(id, organizationId, status, ordering, search, options).then((request) => request(axios, basePath));
        },
        /**
         * A readonly ViewSet for viewing CanaryDailyForecast\'s
         * @param {string} id A UUID string identifying this Canary Daily Forecast.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCanaryDailyForecast(id: string, start?: string, stop?: string, organizationId?: string, options?: any): AxiosPromise<CanaryDailyForecast> {
            return localVarFp.retrieveCanaryDailyForecast(id, start, stop, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint to manipulate CustomCanaryPrototypeSource
         * @param {string} id A UUID string identifying this Canary Prototype Source Code.
         * @param {string} [canaryTemplateLibraryId] canary_template_library_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCanaryPrototypeSourceCode(id: string, canaryTemplateLibraryId?: string, options?: any): AxiosPromise<CanaryPrototypeSource> {
            return localVarFp.retrieveCanaryPrototypeSourceCode(id, canaryTemplateLibraryId, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint to manipulate CustomCanaryRestfulLibSource
         * @param {string} id A UUID string identifying this Canary Restful Library Source Code.
         * @param {string} [restfulLibraryId] restful_library_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCanaryRestfulLibSourceCode(id: string, restfulLibraryId?: string, options?: any): AxiosPromise<CanaryRestfulLibSourceFile> {
            return localVarFp.retrieveCanaryRestfulLibSourceCode(id, restfulLibraryId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get or update a single CanaryTask
         * @param {string} id A UUID string identifying this Canary Task.
         * @param {string} [canaryId] canary_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCanaryTask(id: string, canaryId?: string, options?: any): AxiosPromise<CanaryTask> {
            return localVarFp.retrieveCanaryTask(id, canaryId, options).then((request) => request(axios, basePath));
        },
        /**
         * View to get CanaryData as a graph  * Requires token authentication.
         * @param {string} id A UUID string identifying this canary task data graph.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCanaryTaskDataGraph(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.retrieveCanaryTaskDataGraph(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get or update a single CanaryTask
         * @param {string} id A UUID string identifying this canary task env vars.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCanaryTaskEnvVars(id: string, options?: any): AxiosPromise<CanaryTaskEnvVars> {
            return localVarFp.retrieveCanaryTaskEnvVars(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Canary Task Metric Data.
         * @param {string} [measuredFrom] measured_from
         * @param {string} [measuredUntil] measured_until
         * @param {string} [metricId] metric_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCanaryTaskMetricData(id: string, measuredFrom?: string, measuredUntil?: string, metricId?: string, options?: any): AxiosPromise<CanaryTaskMetricData> {
            return localVarFp.retrieveCanaryTaskMetricData(id, measuredFrom, measuredUntil, metricId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Canary Task Run Data Export.
         * @param {string} [measuredFrom] The Start date of the data export period, should be in this format: YYYY-MM-DD+HH%3AMM, note that %3A means \&#39;:\&#39; so don\&#39;t remove it.
         * @param {string} [measuredUntil] The End date of the data export period, should be in this format: YYYY-MM-DD+HH%3AMM, note that %3A means \&#39;:\&#39; so don\&#39;t remove it.
         * @param {string} [canaryId] The Canary id to get the exported data for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCanaryTaskRunDataExport(id: string, measuredFrom?: string, measuredUntil?: string, canaryId?: string, options?: any): AxiosPromise<CanaryTaskRunDataExportReadOnly> {
            return localVarFp.retrieveCanaryTaskRunDataExport(id, measuredFrom, measuredUntil, canaryId, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint to manipulate CanaryTemplateLibrary
         * @param {string} id A UUID string identifying this Canary Template Library.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCanaryTemplateLibrary(id: string, organizationId?: string, options?: any): AxiosPromise<CanaryTemplateLibrary> {
            return localVarFp.retrieveCanaryTemplateLibrary(id, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * A readonly ViewSet for viewing Canaryable Services.
         * @param {string} id A UUID string identifying this Canaryable Service.
         * @param {string} [providerId] provider_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCanaryableService(id: string, providerId?: string, options?: any): AxiosPromise<CanaryableService> {
            return localVarFp.retrieveCanaryableService(id, providerId, options).then((request) => request(axios, basePath));
        },
        /**
         * A readonly ViewSet for viewing CompositeDailyForecasts Services.
         * @param {string} id A UUID string identifying this Composite Daily Forecast.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCompositeDailyForecast(id: string, start?: string, stop?: string, organizationId?: string, options?: any): AxiosPromise<CompositeDailyForecast> {
            return localVarFp.retrieveCompositeDailyForecast(id, start, stop, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Expose clusters to users  - important because it shares a security boundary with users - we must allow users to read all platform wide clusters (e.g. ours), but not change anything
         * @param {string} id A UUID string identifying this Kubernetes Cluster.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveKubernetesCluster(id: string, organizationId?: string, options?: any): AxiosPromise<KubernetesCluster> {
            return localVarFp.retrieveKubernetesCluster(id, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * A readonly ViewSet for viewing NeuralProphetCanaryDailyForecast\'s
         * @param {string} id A UUID string identifying this Neural Prophet Canary Daily Forecast.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {string} [canaryId] canary_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveNeuralProphetCanaryDailyForecast(id: string, start?: string, stop?: string, organizationId?: string, canaryId?: string, options?: any): AxiosPromise<NeuralCanaryDailyForecast> {
            return localVarFp.retrieveNeuralProphetCanaryDailyForecast(id, start, stop, organizationId, canaryId, options).then((request) => request(axios, basePath));
        },
        /**
         * A readonly ViewSet for viewing NeuralProphetCanaryDailyForecastData\'s
         * @param {string} id A UUID string identifying this Neural Prophet Canary Daily Forecast Data.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {string} [canaryId] canary_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveNeuralProphetCanaryDailyForecastData(id: string, start?: string, stop?: string, organizationId?: string, canaryId?: string, options?: any): AxiosPromise<NeuralCanaryDailyForecastData> {
            return localVarFp.retrieveNeuralProphetCanaryDailyForecastData(id, start, stop, organizationId, canaryId, options).then((request) => request(axios, basePath));
        },
        /**
         * A readonly ViewSet for viewing NeuralProphetCompositeDailyForecast Services.
         * @param {string} id A UUID string identifying this Neural Prophet Composite Daily Forecast.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveNeuralProphetCompositeDailyForecast(id: string, start?: string, stop?: string, organizationId?: string, options?: any): AxiosPromise<NeuralCompositeDailyForecast> {
            return localVarFp.retrieveNeuralProphetCompositeDailyForecast(id, start, stop, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * A readonly ViewSet for viewing NeuralProphetServiceDailyForecast
         * @param {string} id A UUID string identifying this Neural Prophet Service Daily Forecast.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveNeuralProphetServiceDailyForecast(id: string, start?: string, stop?: string, organizationId?: string, options?: any): AxiosPromise<NeuralServiceDailyForecast> {
            return localVarFp.retrieveNeuralProphetServiceDailyForecast(id, start, stop, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Notification.
         * @param {string} [alarmId] alarm_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveNotification(id: string, alarmId?: string, options?: any): AxiosPromise<Notification> {
            return localVarFp.retrieveNotification(id, alarmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Notification Channel Setting.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveNotificationChannelSetting(id: string, organizationId?: string, options?: any): AxiosPromise<NotificationChannelSetting> {
            return localVarFp.retrieveNotificationChannelSetting(id, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Notification History.
         * @param {string} [alarmId] alarm_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveNotificationHistory(id: string, alarmId?: string, options?: any): AxiosPromise<NotificationHistory> {
            return localVarFp.retrieveNotificationHistory(id, alarmId, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint to manipulate CustomCanaryRestfulLibSource
         * @param {string} id A UUID string identifying this OpenAPI Restful Library.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveOpenAPIRestfulLibrary(id: string, organizationId?: string, options?: any): AxiosPromise<OpenAPIRestfulLibrary> {
            return localVarFp.retrieveOpenAPIRestfulLibrary(id, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Organization.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveOrganization(id: string, options?: any): AxiosPromise<Organization> {
            return localVarFp.retrieveOrganization(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} prefix 
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveOrganizationAPIKey(prefix: string, organizationId?: string, options?: any): AxiosPromise<OrganizationAPIKey> {
            return localVarFp.retrieveOrganizationAPIKey(prefix, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint that gets the status of the canary * Requires token authentication.
         * @param {string} id A UUID string identifying this public api canary.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePublicAPICanary(id: string, options?: any): AxiosPromise<CanaryStatus> {
            return localVarFp.retrievePublicAPICanary(id, options).then((request) => request(axios, basePath));
        },
        /**
         * A readonly ViewSet for viewing ServiceCompliance Summaries.
         * @param {string} id A UUID string identifying this public api service compliance summary.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePublicAPIServiceComplianceSummary(id: string, options?: any): AxiosPromise<ServiceComplianceSummaryReadOnly> {
            return localVarFp.retrievePublicAPIServiceComplianceSummary(id, options).then((request) => request(axios, basePath));
        },
        /**
         * View to get ServiceHealth of a canary * Requires token authentication.
         * @param {string} id A UUID string identifying this public api service health.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePublicAPIServiceHealth(id: string, options?: any): AxiosPromise<ServiceHealthStatusRetrieve> {
            return localVarFp.retrievePublicAPIServiceHealth(id, options).then((request) => request(axios, basePath));
        },
        /**
         * View to manipulate ServiceComplianceColors for an organization
         * @param {string} id A UUID string identifying this Service Compliance Color.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveServiceComplianceColors(id: string, organizationId?: string, options?: any): AxiosPromise<ServiceComplianceColors> {
            return localVarFp.retrieveServiceComplianceColors(id, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * A readonly ViewSet for viewing ServiceCompliance Histories
         * @param {string} id A UUID string identifying this service compliance histories.
         * @param {string} [organizationId] organization_id
         * @param {string} [stop] stop
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveServiceComplianceHistories(id: string, organizationId?: string, stop?: string, options?: any): AxiosPromise<ServiceComplianceHistory> {
            return localVarFp.retrieveServiceComplianceHistories(id, organizationId, stop, options).then((request) => request(axios, basePath));
        },
        /**
         * A readonly ViewSet for viewing ServiceCompliance Summaries.
         * @param {string} id A UUID string identifying this Service Compliance Summary.
         * @param {string} [organizationId] organization_id
         * @param {RetrieveServiceComplianceSummaryPeriodCategoryEnum} [periodCategory] period_category
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveServiceComplianceSummary(id: string, organizationId?: string, periodCategory?: RetrieveServiceComplianceSummaryPeriodCategoryEnum, start?: string, stop?: string, options?: any): AxiosPromise<ServiceComplianceSummary> {
            return localVarFp.retrieveServiceComplianceSummary(id, organizationId, periodCategory, start, stop, options).then((request) => request(axios, basePath));
        },
        /**
         * A readonly ViewSet for viewing ServiceDailyForecasts
         * @param {string} id A UUID string identifying this Service Daily Forecast.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveServiceDailyForecast(id: string, start?: string, stop?: string, organizationId?: string, options?: any): AxiosPromise<ServiceDailyForecast> {
            return localVarFp.retrieveServiceDailyForecast(id, start, stop, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * View to get ServiceHealth of a group of canaries
         * @param {string} id A UUID string identifying this Service Health.
         * @param {string} [organizationId] organization_id
         * @param {string} [providerId] provider_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveServiceHealth(id: string, organizationId?: string, providerId?: string, options?: any): AxiosPromise<ServiceHealth> {
            return localVarFp.retrieveServiceHealth(id, organizationId, providerId, options).then((request) => request(axios, basePath));
        },
        /**
         * View to manipulate ServiceHealthColors for an organization
         * @param {string} id A UUID string identifying this Service Health Color.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveServiceHealthColors(id: string, organizationId?: string, options?: any): AxiosPromise<ServiceHealthColors> {
            return localVarFp.retrieveServiceHealthColors(id, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * View to get ServiceHealthEvaluationSettingsGroup for an organization
         * @param {string} id A UUID string identifying this Service Health Evaluation Settings Group.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveServiceHealthEvaluationSettingsGroup(id: string, organizationId?: string, options?: any): AxiosPromise<ServiceHealthEvaluationSettingsGroup> {
            return localVarFp.retrieveServiceHealthEvaluationSettingsGroup(id, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this service health incident.
         * @param {string} [organizationId] organization_id
         * @param {string} [serviceId] service_id
         * @param {RetrieveServiceHealthIncidentIncidentStatusEnum} [incidentStatus] incident_status
         * @param {RetrieveServiceHealthIncidentOrderingEnum} [ordering] Ordering
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveServiceHealthIncident(id: string, organizationId?: string, serviceId?: string, incidentStatus?: RetrieveServiceHealthIncidentIncidentStatusEnum, ordering?: RetrieveServiceHealthIncidentOrderingEnum, options?: any): AxiosPromise<ServiceHealthIncident> {
            return localVarFp.retrieveServiceHealthIncident(id, organizationId, serviceId, incidentStatus, ordering, options).then((request) => request(axios, basePath));
        },
        /**
         * A readonly ViewSet for viewing ServiceProviders.
         * @param {string} id A UUID string identifying this Service Provider.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveServiceProvider(id: string, organizationId?: string, options?: any): AxiosPromise<CanaryServiceProvider> {
            return localVarFp.retrieveServiceProvider(id, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * A readonly ViewSet for viewing ServiceProviderAvailabilityZone\'s.
         * @param {string} id A UUID string identifying this Service Provider Availability Zone.
         * @param {string} [providerId] provider_id
         * @param {string} [regionId] region_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveServiceProviderAvailabilityZone(id: string, providerId?: string, regionId?: string, options?: any): AxiosPromise<CanaryServiceProviderAvailabilityZone> {
            return localVarFp.retrieveServiceProviderAvailabilityZone(id, providerId, regionId, options).then((request) => request(axios, basePath));
        },
        /**
         * A readonly ViewSet for viewing ServiceProviderRegion\'s.
         * @param {string} id A UUID string identifying this Service Provider Region.
         * @param {string} [providerId] provider_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveServiceProviderRegion(id: string, providerId?: string, options?: any): AxiosPromise<CanaryServiceProviderRegion> {
            return localVarFp.retrieveServiceProviderRegion(id, providerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Simple Alarm.
         * @param {string} [metricId] metric_id
         * @param {string} [canaryId] canary_id
         * @param {string} [taskId] task_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveSimpleAlarm(id: string, metricId?: string, canaryId?: string, taskId?: string, options?: any): AxiosPromise<SimpleAlarm> {
            return localVarFp.retrieveSimpleAlarm(id, metricId, canaryId, taskId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Simple Alarm Evaluation.
         * @param {string} [alarmId] alarm_id
         * @param {string} [periodStart] period_start
         * @param {string} [periodStop] period_stop
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveSimpleAlarmEvaluation(id: string, alarmId?: string, periodStart?: string, periodStop?: string, options?: any): AxiosPromise<SimpleAlarmEvaluation> {
            return localVarFp.retrieveSimpleAlarmEvaluation(id, alarmId, periodStart, periodStop, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Simple Alarm History.
         * @param {string} [alarmId] alarm_id
         * @param {string} [evaluationPeriodStart] evaluation_period_start
         * @param {string} [evaluationPeriodStop] evaluation_period_stop
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveSimpleAlarmHistory(id: string, alarmId?: string, evaluationPeriodStart?: string, evaluationPeriodStop?: string, options?: any): AxiosPromise<SimpleAlarmHistory> {
            return localVarFp.retrieveSimpleAlarmHistory(id, alarmId, evaluationPeriodStart, evaluationPeriodStop, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Snowflake Setting.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveSnowflake(id: string, organizationId?: string, options?: any): AxiosPromise<Snowflake> {
            return localVarFp.retrieveSnowflake(id, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * A readonly ViewSet for viewing SnowflakeCanaryDailyForecast\'s
         * @param {string} id A UUID string identifying this Snowflake Canary Daily Forecast.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {string} [canaryId] canary_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveSnowflakeCanaryDailyForecast(id: string, start?: string, stop?: string, organizationId?: string, canaryId?: string, options?: any): AxiosPromise<SnowflakeCanaryDailyForecast> {
            return localVarFp.retrieveSnowflakeCanaryDailyForecast(id, start, stop, organizationId, canaryId, options).then((request) => request(axios, basePath));
        },
        /**
         * A readonly ViewSet for viewing SnowflakeCanaryDailyForecastData Services.
         * @param {string} id A UUID string identifying this Snowflake Canary Daily Data Forecast.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {string} [canaryId] canary_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveSnowflakeCanaryDailyForecastData(id: string, start?: string, stop?: string, organizationId?: string, canaryId?: string, options?: any): AxiosPromise<SnowflakeCanaryDailyForecastData> {
            return localVarFp.retrieveSnowflakeCanaryDailyForecastData(id, start, stop, organizationId, canaryId, options).then((request) => request(axios, basePath));
        },
        /**
         * A readonly ViewSet for viewing SnowflakeCompositeDailyForecast Services.
         * @param {string} id A UUID string identifying this Snowflake Composite Daily Forecast.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveSnowflakeCompositeDailyForecast(id: string, start?: string, stop?: string, organizationId?: string, options?: any): AxiosPromise<SnowflakeCompositeDailyForecast> {
            return localVarFp.retrieveSnowflakeCompositeDailyForecast(id, start, stop, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Snowflake Forecast Model.
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveSnowflakeForecastModel(id: string, organizationId?: string, options?: any): AxiosPromise<SnowflakeForecastModel> {
            return localVarFp.retrieveSnowflakeForecastModel(id, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * A readonly ViewSet for viewing SnowflakeServiceDailyForecast
         * @param {string} id A UUID string identifying this Snowflake Service Daily Forecast.
         * @param {string} [start] start
         * @param {string} [stop] stop
         * @param {string} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveSnowflakeServiceDailyForecast(id: string, start?: string, stop?: string, organizationId?: string, options?: any): AxiosPromise<SnowflakeServiceDailyForecast> {
            return localVarFp.retrieveSnowflakeServiceDailyForecast(id, start, stop, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint to manipulate StoreCanary
         * @param {string} id A UUID string identifying this Store Canary.
         * @param {string} [cloud] cloud
         * @param {string} [component] component
         * @param {string} [status] status
         * @param {string} [isPrivate] is_private
         * @param {string} [isPinnedCanary] is_pinned_canary
         * @param {string} [uploadedUser] uploaded_user
         * @param {string} [company] company
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveStoreCanary(id: string, cloud?: string, component?: string, status?: string, isPrivate?: string, isPinnedCanary?: string, uploadedUser?: string, company?: string, search?: string, options?: any): AxiosPromise<StoreCanary> {
            return localVarFp.retrieveStoreCanary(id, cloud, component, status, isPrivate, isPinnedCanary, uploadedUser, company, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Subscription Cancel Request.
         * @param {string} [subscriptionId] subscription_id
         * @param {string} [subscriptionUserId] subscription_user_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveSubscriptionCancelRequest(id: string, subscriptionId?: string, subscriptionUserId?: string, options?: any): AxiosPromise<SubscriptionCancelRequest> {
            return localVarFp.retrieveSubscriptionCancelRequest(id, subscriptionId, subscriptionUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint to manipulate TemplateCanary
         * @param {string} id A UUID string identifying this Template Canary.
         * @param {string} [canaryTemplateLibraryId] canary_template_library_id
         * @param {string} [providerId] provider_id
         * @param {string} [serviceId] service_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveTemplateCanary(id: string, canaryTemplateLibraryId?: string, providerId?: string, serviceId?: string, options?: any): AxiosPromise<TemplateCanaryReadOnlyRetrieveItem> {
            return localVarFp.retrieveTemplateCanary(id, canaryTemplateLibraryId, providerId, serviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint to manipulate TemplateTask
         * @param {string} id A UUID string identifying this Template Task.
         * @param {string} [canaryTemplateLibraryId] canary_template_library_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveTemplateTask(id: string, canaryTemplateLibraryId?: string, options?: any): AxiosPromise<TemplateTaskReadOnly> {
            return localVarFp.retrieveTemplateTask(id, canaryTemplateLibraryId, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint that allows authenticated clients to view or edit users
         * @param {string} id A UUID string identifying this user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveUser(id: string, options?: any): AxiosPromise<User> {
            return localVarFp.retrieveUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this User Subscribed Plan.
         * @param {string} [userId] user_id
         * @param {string} [platformActive] platform_active
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveUserSubscribedPlan(id: string, userId?: string, platformActive?: string, options?: any): AxiosPromise<UserSubscribedPlan> {
            return localVarFp.retrieveUserSubscribedPlan(id, userId, platformActive, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint that manages Canarys
         * @param {string} id A UUID string identifying this Canary.
         * @param {CanarySchedule} [canarySchedule] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduleCanary(id: string, canarySchedule?: CanarySchedule, options?: any): AxiosPromise<CanarySchedule> {
            return localVarFp.scheduleCanary(id, canarySchedule, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this User Subscribed Plan.
         * @param {UserSubscribedPlanAssignSubtractCanary} [userSubscribedPlanAssignSubtractCanary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subtractCanaryUserSubscribedPlan(id: string, userSubscribedPlanAssignSubtractCanary?: UserSubscribedPlanAssignSubtractCanary, options?: any): AxiosPromise<UserSubscribedPlanAssignSubtractCanary> {
            return localVarFp.subtractCanaryUserSubscribedPlan(id, userSubscribedPlanAssignSubtractCanary, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Abstract Metric Condition.
         * @param {string} [metricId] metric_id
         * @param {string} [canaryId] canary_id
         * @param {string} [taskId] task_id
         * @param {AbstractMetricBoundaryCondition} [abstractMetricBoundaryCondition] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAbstractMetricBoundaryCondition(id: string, metricId?: string, canaryId?: string, taskId?: string, abstractMetricBoundaryCondition?: AbstractMetricBoundaryCondition, options?: any): AxiosPromise<AbstractMetricBoundaryCondition> {
            return localVarFp.updateAbstractMetricBoundaryCondition(id, metricId, canaryId, taskId, abstractMetricBoundaryCondition, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this billing detail.
         * @param {string} [userId] user_id
         * @param {BillingDetail} [billingDetail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBillingDetail(id: string, userId?: string, billingDetail?: BillingDetail, options?: any): AxiosPromise<BillingDetail> {
            return localVarFp.updateBillingDetail(id, userId, billingDetail, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint that manages Canarys
         * @param {string} id A UUID string identifying this Canary.
         * @param {string} [organizationId] organization_id
         * @param {UpdateCanaryStatusEnum} [status] status
         * @param {UpdateCanaryOrderingEnum} [ordering] Ordering
         * @param {string} [search] A search term.
         * @param {Canary} [canary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCanary(id: string, organizationId?: string, status?: UpdateCanaryStatusEnum, ordering?: UpdateCanaryOrderingEnum, search?: string, canary?: Canary, options?: any): AxiosPromise<Canary> {
            return localVarFp.updateCanary(id, organizationId, status, ordering, search, canary, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this canary cluster deployment.
         * @param {CanaryClusterDeployment} [canaryClusterDeployment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCanaryClusterDeployment(id: string, canaryClusterDeployment?: CanaryClusterDeployment, options?: any): AxiosPromise<CanaryClusterDeployment> {
            return localVarFp.updateCanaryClusterDeployment(id, canaryClusterDeployment, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint to manipulate CustomCanaryPrototypeSource
         * @param {string} id A UUID string identifying this Canary Prototype Source Code.
         * @param {string} [canaryTemplateLibraryId] canary_template_library_id
         * @param {CanaryPrototypeSource} [canaryPrototypeSource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCanaryPrototypeSourceCode(id: string, canaryTemplateLibraryId?: string, canaryPrototypeSource?: CanaryPrototypeSource, options?: any): AxiosPromise<CanaryPrototypeSource> {
            return localVarFp.updateCanaryPrototypeSourceCode(id, canaryTemplateLibraryId, canaryPrototypeSource, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint to manipulate CustomCanaryRestfulLibSource
         * @param {string} id A UUID string identifying this Canary Restful Library Source Code.
         * @param {string} [restfulLibraryId] restful_library_id
         * @param {CanaryRestfulLibSourceFile} [canaryRestfulLibSourceFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCanaryRestfulLibSourceCode(id: string, restfulLibraryId?: string, canaryRestfulLibSourceFile?: CanaryRestfulLibSourceFile, options?: any): AxiosPromise<CanaryRestfulLibSourceFile> {
            return localVarFp.updateCanaryRestfulLibSourceCode(id, restfulLibraryId, canaryRestfulLibSourceFile, options).then((request) => request(axios, basePath));
        },
        /**
         * Get or update a single CanaryTask
         * @param {string} id A UUID string identifying this Canary Task.
         * @param {string} [canaryId] canary_id
         * @param {CanaryTask} [canaryTask] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCanaryTask(id: string, canaryId?: string, canaryTask?: CanaryTask, options?: any): AxiosPromise<CanaryTask> {
            return localVarFp.updateCanaryTask(id, canaryId, canaryTask, options).then((request) => request(axios, basePath));
        },
        /**
         * Get or update a single CanaryTask
         * @param {string} id A UUID string identifying this canary task env vars.
         * @param {CanaryTaskEnvVars} [canaryTaskEnvVars] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCanaryTaskEnvVars(id: string, canaryTaskEnvVars?: CanaryTaskEnvVars, options?: any): AxiosPromise<CanaryTaskEnvVars> {
            return localVarFp.updateCanaryTaskEnvVars(id, canaryTaskEnvVars, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint to manipulate CanaryTemplateLibrary
         * @param {string} id A UUID string identifying this Canary Template Library.
         * @param {string} [organizationId] organization_id
         * @param {CanaryTemplateLibrary} [canaryTemplateLibrary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCanaryTemplateLibrary(id: string, organizationId?: string, canaryTemplateLibrary?: CanaryTemplateLibrary, options?: any): AxiosPromise<CanaryTemplateLibrary> {
            return localVarFp.updateCanaryTemplateLibrary(id, organizationId, canaryTemplateLibrary, options).then((request) => request(axios, basePath));
        },
        /**
         * Expose clusters to users  - important because it shares a security boundary with users - we must allow users to read all platform wide clusters (e.g. ours), but not change anything
         * @param {string} id A UUID string identifying this Kubernetes Cluster.
         * @param {string} [organizationId] organization_id
         * @param {KubernetesCluster} [kubernetesCluster] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKubernetesCluster(id: string, organizationId?: string, kubernetesCluster?: KubernetesCluster, options?: any): AxiosPromise<KubernetesCluster> {
            return localVarFp.updateKubernetesCluster(id, organizationId, kubernetesCluster, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Notification.
         * @param {string} [alarmId] alarm_id
         * @param {Notification} [notification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotification(id: string, alarmId?: string, notification?: Notification, options?: any): AxiosPromise<Notification> {
            return localVarFp.updateNotification(id, alarmId, notification, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Notification Channel Setting.
         * @param {string} [organizationId] organization_id
         * @param {NotificationChannelSetting} [notificationChannelSetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotificationChannelSetting(id: string, organizationId?: string, notificationChannelSetting?: NotificationChannelSetting, options?: any): AxiosPromise<NotificationChannelSetting> {
            return localVarFp.updateNotificationChannelSetting(id, organizationId, notificationChannelSetting, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint to manipulate CustomCanaryRestfulLibSource
         * @param {string} id A UUID string identifying this OpenAPI Restful Library.
         * @param {string} [organizationId] organization_id
         * @param {OpenAPIRestfulLibrary} [openAPIRestfulLibrary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOpenAPIRestfulLibrary(id: string, organizationId?: string, openAPIRestfulLibrary?: OpenAPIRestfulLibrary, options?: any): AxiosPromise<OpenAPIRestfulLibrary> {
            return localVarFp.updateOpenAPIRestfulLibrary(id, organizationId, openAPIRestfulLibrary, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Organization.
         * @param {Organization} [organization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganization(id: string, organization?: Organization, options?: any): AxiosPromise<Organization> {
            return localVarFp.updateOrganization(id, organization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} prefix 
         * @param {string} [organizationId] organization_id
         * @param {OrganizationAPIKeyUpdate} [organizationAPIKeyUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganizationAPIKey(prefix: string, organizationId?: string, organizationAPIKeyUpdate?: OrganizationAPIKeyUpdate, options?: any): AxiosPromise<OrganizationAPIKeyUpdate> {
            return localVarFp.updateOrganizationAPIKey(prefix, organizationId, organizationAPIKeyUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * View to manipulate ServiceComplianceColors for an organization
         * @param {string} id A UUID string identifying this Service Compliance Color.
         * @param {string} [organizationId] organization_id
         * @param {ServiceComplianceColors} [serviceComplianceColors] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateServiceComplianceColors(id: string, organizationId?: string, serviceComplianceColors?: ServiceComplianceColors, options?: any): AxiosPromise<ServiceComplianceColors> {
            return localVarFp.updateServiceComplianceColors(id, organizationId, serviceComplianceColors, options).then((request) => request(axios, basePath));
        },
        /**
         * View to manipulate ServiceHealthColors for an organization
         * @param {string} id A UUID string identifying this Service Health Color.
         * @param {string} [organizationId] organization_id
         * @param {ServiceHealthColors} [serviceHealthColors] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateServiceHealthColors(id: string, organizationId?: string, serviceHealthColors?: ServiceHealthColors, options?: any): AxiosPromise<ServiceHealthColors> {
            return localVarFp.updateServiceHealthColors(id, organizationId, serviceHealthColors, options).then((request) => request(axios, basePath));
        },
        /**
         * View to get ServiceHealthEvaluationSettingsGroup for an organization
         * @param {string} id A UUID string identifying this Service Health Evaluation Settings Group.
         * @param {string} [organizationId] organization_id
         * @param {ServiceHealthEvaluationSettingsGroup} [serviceHealthEvaluationSettingsGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateServiceHealthEvaluationSettingsGroup(id: string, organizationId?: string, serviceHealthEvaluationSettingsGroup?: ServiceHealthEvaluationSettingsGroup, options?: any): AxiosPromise<ServiceHealthEvaluationSettingsGroup> {
            return localVarFp.updateServiceHealthEvaluationSettingsGroup(id, organizationId, serviceHealthEvaluationSettingsGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Simple Alarm.
         * @param {string} [metricId] metric_id
         * @param {string} [canaryId] canary_id
         * @param {string} [taskId] task_id
         * @param {SimpleAlarm} [simpleAlarm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSimpleAlarm(id: string, metricId?: string, canaryId?: string, taskId?: string, simpleAlarm?: SimpleAlarm, options?: any): AxiosPromise<SimpleAlarm> {
            return localVarFp.updateSimpleAlarm(id, metricId, canaryId, taskId, simpleAlarm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Snowflake Setting.
         * @param {string} [organizationId] organization_id
         * @param {Snowflake} [snowflake] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSnowflake(id: string, organizationId?: string, snowflake?: Snowflake, options?: any): AxiosPromise<Snowflake> {
            return localVarFp.updateSnowflake(id, organizationId, snowflake, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Snowflake Forecast Model.
         * @param {string} [organizationId] organization_id
         * @param {SnowflakeForecastModel} [snowflakeForecastModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSnowflakeForecastModel(id: string, organizationId?: string, snowflakeForecastModel?: SnowflakeForecastModel, options?: any): AxiosPromise<SnowflakeForecastModel> {
            return localVarFp.updateSnowflakeForecastModel(id, organizationId, snowflakeForecastModel, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint to manipulate StoreCanary
         * @param {string} id A UUID string identifying this Store Canary.
         * @param {string} [cloud] cloud
         * @param {string} [component] component
         * @param {string} [status] status
         * @param {string} [isPrivate] is_private
         * @param {string} [isPinnedCanary] is_pinned_canary
         * @param {string} [uploadedUser] uploaded_user
         * @param {string} [company] company
         * @param {string} [search] A search term.
         * @param {StoreCanaryWrite} [storeCanaryWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStoreCanary(id: string, cloud?: string, component?: string, status?: string, isPrivate?: string, isPinnedCanary?: string, uploadedUser?: string, company?: string, search?: string, storeCanaryWrite?: StoreCanaryWrite, options?: any): AxiosPromise<StoreCanaryWrite> {
            return localVarFp.updateStoreCanary(id, cloud, component, status, isPrivate, isPinnedCanary, uploadedUser, company, search, storeCanaryWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Subscription Cancel Request.
         * @param {string} [subscriptionId] subscription_id
         * @param {string} [subscriptionUserId] subscription_user_id
         * @param {SubscriptionCancelRequest} [subscriptionCancelRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubscriptionCancelRequest(id: string, subscriptionId?: string, subscriptionUserId?: string, subscriptionCancelRequest?: SubscriptionCancelRequest, options?: any): AxiosPromise<SubscriptionCancelRequest> {
            return localVarFp.updateSubscriptionCancelRequest(id, subscriptionId, subscriptionUserId, subscriptionCancelRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint to manipulate TemplateCanary
         * @param {string} id A UUID string identifying this Template Canary.
         * @param {string} [canaryTemplateLibraryId] canary_template_library_id
         * @param {string} [providerId] provider_id
         * @param {string} [serviceId] service_id
         * @param {TemplateCanaryWritable} [templateCanaryWritable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTemplateCanary(id: string, canaryTemplateLibraryId?: string, providerId?: string, serviceId?: string, templateCanaryWritable?: TemplateCanaryWritable, options?: any): AxiosPromise<TemplateCanaryWritable> {
            return localVarFp.updateTemplateCanary(id, canaryTemplateLibraryId, providerId, serviceId, templateCanaryWritable, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint to manipulate TemplateTask
         * @param {string} id A UUID string identifying this Template Task.
         * @param {string} [canaryTemplateLibraryId] canary_template_library_id
         * @param {TemplateTaskReadOnly} [templateTaskReadOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTemplateTask(id: string, canaryTemplateLibraryId?: string, templateTaskReadOnly?: TemplateTaskReadOnly, options?: any): AxiosPromise<TemplateTaskReadOnly> {
            return localVarFp.updateTemplateTask(id, canaryTemplateLibraryId, templateTaskReadOnly, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint that allows authenticated clients to view or edit users
         * @param {string} id A UUID string identifying this user.
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(id: string, user?: User, options?: any): AxiosPromise<User> {
            return localVarFp.updateUser(id, user, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPartialAuthViewSet(body?: any, options?: any): AxiosPromise<any> {
            return localVarFp.updateUserPartialAuthViewSet(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this User Subscribed Plan.
         * @param {string} [userId] user_id
         * @param {string} [platformActive] platform_active
         * @param {UserSubscribedPlan} [userSubscribedPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserSubscribedPlan(id: string, userId?: string, platformActive?: string, userSubscribedPlan?: UserSubscribedPlan, options?: any): AxiosPromise<UserSubscribedPlan> {
            return localVarFp.updateUserSubscribedPlan(id, userId, platformActive, userSubscribedPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userProfileAuthViewSet(options?: any): AxiosPromise<any> {
            return localVarFp.userProfileAuthViewSet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateResetTokenAuthViewSet(body?: any, options?: any): AxiosPromise<any> {
            return localVarFp.validateResetTokenAuthViewSet(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyEmailAuthViewSet(body?: any, options?: any): AxiosPromise<any> {
            return localVarFp.verifyEmailAuthViewSet(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {string} id A UUID string identifying this Organization.
     * @param {OrganizationMember} [organizationMember] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addMemberOrganization(id: string, organizationMember?: OrganizationMember, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).addMemberOrganization(id, organizationMember, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this User Subscribed Plan.
     * @param {UserSubscribedPlanAddOrganizations} [userSubscribedPlanAddOrganizations] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addOrganizationUserSubscribedPlan(id: string, userSubscribedPlanAddOrganizations?: UserSubscribedPlanAddOrganizations, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).addOrganizationUserSubscribedPlan(id, userSubscribedPlanAddOrganizations, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this User Subscribed Plan.
     * @param {UserSubscribedPlanAssignSubtractCanary} [userSubscribedPlanAssignSubtractCanary] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public assignCanaryUserSubscribedPlan(id: string, userSubscribedPlanAssignSubtractCanary?: UserSubscribedPlanAssignSubtractCanary, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).assignCanaryUserSubscribedPlan(id, userSubscribedPlanAssignSubtractCanary, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint that manages Canarys
     * @param {string} id A UUID string identifying this Canary.
     * @param {CanarySchedule} [canarySchedule] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public canaryAgentStatusCanary(id: string, canarySchedule?: CanarySchedule, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).canaryAgentStatusCanary(id, canarySchedule, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public changeTempPasswordAuthViewSet(body?: any, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).changeTempPasswordAuthViewSet(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AbstractMetricBoundaryCondition} [abstractMetricBoundaryCondition] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createAbstractMetricBoundaryCondition(abstractMetricBoundaryCondition?: AbstractMetricBoundaryCondition, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createAbstractMetricBoundaryCondition(abstractMetricBoundaryCondition, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthToken} [authToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createAuthToken(authToken?: AuthToken, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createAuthToken(authToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BillingDetail} [billingDetail] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createBillingDetail(billingDetail?: BillingDetail, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createBillingDetail(billingDetail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint that manages Canarys
     * @param {Canary} [canary] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createCanary(canary?: Canary, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createCanary(canary, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint to manipulate CustomCanaryPrototypeSource
     * @param {CanaryPrototypeSource} [canaryPrototypeSource] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createCanaryPrototypeSourceCode(canaryPrototypeSource?: CanaryPrototypeSource, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createCanaryPrototypeSourceCode(canaryPrototypeSource, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint to manipulate CustomCanaryRestfulLibSource
     * @param {CanaryRestfulLibSourceFile} [canaryRestfulLibSourceFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createCanaryRestfulLibSourceCode(canaryRestfulLibSourceFile?: CanaryRestfulLibSourceFile, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createCanaryRestfulLibSourceCode(canaryRestfulLibSourceFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get or update a single CanaryTask
     * @param {CanaryTask} [canaryTask] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createCanaryTask(canaryTask?: CanaryTask, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createCanaryTask(canaryTask, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint that writes Canary data
     * @param {CanaryTaskRunDataWriteOnly} [canaryTaskRunDataWriteOnly] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createCanaryTaskRawData(canaryTaskRunDataWriteOnly?: CanaryTaskRunDataWriteOnly, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createCanaryTaskRawData(canaryTaskRunDataWriteOnly, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint to manipulate CanaryTemplateLibrary
     * @param {CanaryTemplateLibrary} [canaryTemplateLibrary] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createCanaryTemplateLibrary(canaryTemplateLibrary?: CanaryTemplateLibrary, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createCanaryTemplateLibrary(canaryTemplateLibrary, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContactRequestWriteOnly} [contactRequestWriteOnly] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createContactRequest(contactRequestWriteOnly?: ContactRequestWriteOnly, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createContactRequest(contactRequestWriteOnly, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Expose clusters to users  - important because it shares a security boundary with users - we must allow users to read all platform wide clusters (e.g. ours), but not change anything
     * @param {KubernetesCluster} [kubernetesCluster] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createKubernetesCluster(kubernetesCluster?: KubernetesCluster, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createKubernetesCluster(kubernetesCluster, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Notification} [notification] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createNotification(notification?: Notification, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createNotification(notification, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NotificationChannelSetting} [notificationChannelSetting] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createNotificationChannelSetting(notificationChannelSetting?: NotificationChannelSetting, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createNotificationChannelSetting(notificationChannelSetting, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint to manipulate CustomCanaryRestfulLibSource
     * @param {OpenAPIRestfulLibrary} [openAPIRestfulLibrary] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createOpenAPIRestfulLibrary(openAPIRestfulLibrary?: OpenAPIRestfulLibrary, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createOpenAPIRestfulLibrary(openAPIRestfulLibrary, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Organization} [organization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createOrganization(organization?: Organization, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createOrganization(organization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrganizationAPIKey} [organizationAPIKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createOrganizationAPIKey(organizationAPIKey?: OrganizationAPIKey, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createOrganizationAPIKey(organizationAPIKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * View to manipulate ServiceComplianceColors for an organization
     * @param {ServiceComplianceColors} [serviceComplianceColors] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createServiceComplianceColors(serviceComplianceColors?: ServiceComplianceColors, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createServiceComplianceColors(serviceComplianceColors, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * View to manipulate ServiceHealthColors for an organization
     * @param {ServiceHealthColors} [serviceHealthColors] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createServiceHealthColors(serviceHealthColors?: ServiceHealthColors, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createServiceHealthColors(serviceHealthColors, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * View to get ServiceHealthEvaluationSettingsGroup for an organization
     * @param {ServiceHealthEvaluationSettingsGroup} [serviceHealthEvaluationSettingsGroup] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createServiceHealthEvaluationSettingsGroup(serviceHealthEvaluationSettingsGroup?: ServiceHealthEvaluationSettingsGroup, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createServiceHealthEvaluationSettingsGroup(serviceHealthEvaluationSettingsGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SimpleAlarm} [simpleAlarm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createSimpleAlarm(simpleAlarm?: SimpleAlarm, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createSimpleAlarm(simpleAlarm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Snowflake} [snowflake] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createSnowflake(snowflake?: Snowflake, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createSnowflake(snowflake, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SnowflakeForecastModel} [snowflakeForecastModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createSnowflakeForecastModel(snowflakeForecastModel?: SnowflakeForecastModel, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createSnowflakeForecastModel(snowflakeForecastModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint to manipulate StoreCanary
     * @param {StoreCanaryWrite} [storeCanaryWrite] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createStoreCanary(storeCanaryWrite?: StoreCanaryWrite, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createStoreCanary(storeCanaryWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SubscriptionCancelRequest} [subscriptionCancelRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createSubscriptionCancelRequest(subscriptionCancelRequest?: SubscriptionCancelRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createSubscriptionCancelRequest(subscriptionCancelRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint to manipulate TemplateCanary
     * @param {TemplateCanaryWritable} [templateCanaryWritable] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createTemplateCanary(templateCanaryWritable?: TemplateCanaryWritable, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createTemplateCanary(templateCanaryWritable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint to manipulate TemplateTask
     * @param {TemplateTaskReadOnly} [templateTaskReadOnly] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createTemplateTask(templateTaskReadOnly?: TemplateTaskReadOnly, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createTemplateTask(templateTaskReadOnly, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint that allows authenticated clients to view or edit users
     * @param {User} [user] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createUser(user?: User, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createUser(user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserGatedContentDownloadRequest} [userGatedContentDownloadRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createUserGatedContentDownloadRequest(userGatedContentDownloadRequest?: UserGatedContentDownloadRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createUserGatedContentDownloadRequest(userGatedContentDownloadRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserSubscribedPlan} [userSubscribedPlan] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createUserSubscribedPlan(userSubscribedPlan?: UserSubscribedPlan, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createUserSubscribedPlan(userSubscribedPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this Abstract Metric Condition.
     * @param {string} [metricId] metric_id
     * @param {string} [canaryId] canary_id
     * @param {string} [taskId] task_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public destroyAbstractMetricBoundaryCondition(id: string, metricId?: string, canaryId?: string, taskId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).destroyAbstractMetricBoundaryCondition(id, metricId, canaryId, taskId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this billing detail.
     * @param {string} [userId] user_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public destroyBillingDetail(id: string, userId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).destroyBillingDetail(id, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint that manages Canarys
     * @param {string} id A UUID string identifying this Canary.
     * @param {string} [organizationId] organization_id
     * @param {DestroyCanaryStatusEnum} [status] status
     * @param {DestroyCanaryOrderingEnum} [ordering] Ordering
     * @param {string} [search] A search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public destroyCanary(id: string, organizationId?: string, status?: DestroyCanaryStatusEnum, ordering?: DestroyCanaryOrderingEnum, search?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).destroyCanary(id, organizationId, status, ordering, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint to manipulate CustomCanaryPrototypeSource
     * @param {string} id A UUID string identifying this Canary Prototype Source Code.
     * @param {string} [canaryTemplateLibraryId] canary_template_library_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public destroyCanaryPrototypeSourceCode(id: string, canaryTemplateLibraryId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).destroyCanaryPrototypeSourceCode(id, canaryTemplateLibraryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint to manipulate CustomCanaryRestfulLibSource
     * @param {string} id A UUID string identifying this Canary Restful Library Source Code.
     * @param {string} [restfulLibraryId] restful_library_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public destroyCanaryRestfulLibSourceCode(id: string, restfulLibraryId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).destroyCanaryRestfulLibSourceCode(id, restfulLibraryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get or update a single CanaryTask
     * @param {string} id A UUID string identifying this Canary Task.
     * @param {string} [canaryId] canary_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public destroyCanaryTask(id: string, canaryId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).destroyCanaryTask(id, canaryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint to manipulate CanaryTemplateLibrary
     * @param {string} id A UUID string identifying this Canary Template Library.
     * @param {string} [organizationId] organization_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public destroyCanaryTemplateLibrary(id: string, organizationId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).destroyCanaryTemplateLibrary(id, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Expose clusters to users  - important because it shares a security boundary with users - we must allow users to read all platform wide clusters (e.g. ours), but not change anything
     * @param {string} id A UUID string identifying this Kubernetes Cluster.
     * @param {string} [organizationId] organization_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public destroyKubernetesCluster(id: string, organizationId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).destroyKubernetesCluster(id, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this Notification.
     * @param {string} [alarmId] alarm_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public destroyNotification(id: string, alarmId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).destroyNotification(id, alarmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this Notification Channel Setting.
     * @param {string} [organizationId] organization_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public destroyNotificationChannelSetting(id: string, organizationId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).destroyNotificationChannelSetting(id, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint to manipulate CustomCanaryRestfulLibSource
     * @param {string} id A UUID string identifying this OpenAPI Restful Library.
     * @param {string} [organizationId] organization_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public destroyOpenAPIRestfulLibrary(id: string, organizationId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).destroyOpenAPIRestfulLibrary(id, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this Organization.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public destroyOrganization(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).destroyOrganization(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} prefix 
     * @param {string} [organizationId] organization_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public destroyOrganizationAPIKey(prefix: string, organizationId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).destroyOrganizationAPIKey(prefix, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * View to manipulate ServiceComplianceColors for an organization
     * @param {string} id A UUID string identifying this Service Compliance Color.
     * @param {string} [organizationId] organization_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public destroyServiceComplianceColors(id: string, organizationId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).destroyServiceComplianceColors(id, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * View to manipulate ServiceHealthColors for an organization
     * @param {string} id A UUID string identifying this Service Health Color.
     * @param {string} [organizationId] organization_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public destroyServiceHealthColors(id: string, organizationId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).destroyServiceHealthColors(id, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * View to get ServiceHealthEvaluationSettingsGroup for an organization
     * @param {string} id A UUID string identifying this Service Health Evaluation Settings Group.
     * @param {string} [organizationId] organization_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public destroyServiceHealthEvaluationSettingsGroup(id: string, organizationId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).destroyServiceHealthEvaluationSettingsGroup(id, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this Simple Alarm.
     * @param {string} [metricId] metric_id
     * @param {string} [canaryId] canary_id
     * @param {string} [taskId] task_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public destroySimpleAlarm(id: string, metricId?: string, canaryId?: string, taskId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).destroySimpleAlarm(id, metricId, canaryId, taskId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this Snowflake Setting.
     * @param {string} [organizationId] organization_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public destroySnowflake(id: string, organizationId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).destroySnowflake(id, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this Snowflake Forecast Model.
     * @param {string} [organizationId] organization_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public destroySnowflakeForecastModel(id: string, organizationId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).destroySnowflakeForecastModel(id, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint to manipulate StoreCanary
     * @param {string} id A UUID string identifying this Store Canary.
     * @param {string} [cloud] cloud
     * @param {string} [component] component
     * @param {string} [status] status
     * @param {string} [isPrivate] is_private
     * @param {string} [isPinnedCanary] is_pinned_canary
     * @param {string} [uploadedUser] uploaded_user
     * @param {string} [company] company
     * @param {string} [search] A search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public destroyStoreCanary(id: string, cloud?: string, component?: string, status?: string, isPrivate?: string, isPinnedCanary?: string, uploadedUser?: string, company?: string, search?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).destroyStoreCanary(id, cloud, component, status, isPrivate, isPinnedCanary, uploadedUser, company, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this Subscription Cancel Request.
     * @param {string} [subscriptionId] subscription_id
     * @param {string} [subscriptionUserId] subscription_user_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public destroySubscriptionCancelRequest(id: string, subscriptionId?: string, subscriptionUserId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).destroySubscriptionCancelRequest(id, subscriptionId, subscriptionUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint to manipulate TemplateCanary
     * @param {string} id A UUID string identifying this Template Canary.
     * @param {string} [canaryTemplateLibraryId] canary_template_library_id
     * @param {string} [providerId] provider_id
     * @param {string} [serviceId] service_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public destroyTemplateCanary(id: string, canaryTemplateLibraryId?: string, providerId?: string, serviceId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).destroyTemplateCanary(id, canaryTemplateLibraryId, providerId, serviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint to manipulate TemplateTask
     * @param {string} id A UUID string identifying this Template Task.
     * @param {string} [canaryTemplateLibraryId] canary_template_library_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public destroyTemplateTask(id: string, canaryTemplateLibraryId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).destroyTemplateTask(id, canaryTemplateLibraryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint that allows authenticated clients to view or edit users
     * @param {string} id A UUID string identifying this user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public destroyUser(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).destroyUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this User Subscribed Plan.
     * @param {string} [userId] user_id
     * @param {string} [platformActive] platform_active
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public destroyUserSubscribedPlan(id: string, userId?: string, platformActive?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).destroyUserSubscribedPlan(id, userId, platformActive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public downloadCloudCanariesArticleUserGatedContentDownloadFileReadOnly(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).downloadCloudCanariesArticleUserGatedContentDownloadFileReadOnly(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint to manipulate CustomCanaryRestfulLibSource
     * @param {string} id A UUID string identifying this OpenAPI Restful Library.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public downloadRestfulZipOpenAPIRestfulLibrary(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).downloadRestfulZipOpenAPIRestfulLibrary(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint to manipulate CustomCanaryRestfulLibSource
     * @param {string} id A UUID string identifying this OpenAPI Restful Library.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public downloadTemplateZipOpenAPIRestfulLibrary(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).downloadTemplateZipOpenAPIRestfulLibrary(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint that manages Canarys
     * @param {string} id A UUID string identifying this Canary.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public generateAndDownloadCanaryAgentCanary(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).generateAndDownloadCanaryAgentCanary(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrganizationAPIKeyContents} [organizationAPIKeyContents] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public generateKeyOrganizationAPIKey(organizationAPIKeyContents?: OrganizationAPIKeyContents, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).generateKeyOrganizationAPIKey(organizationAPIKeyContents, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [metricId] metric_id
     * @param {string} [canaryId] canary_id
     * @param {string} [taskId] task_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listAbstractMetricBoundaryConditions(page?: number, pageSize?: number, metricId?: string, canaryId?: string, taskId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listAbstractMetricBoundaryConditions(page, pageSize, metricId, canaryId, taskId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [canaryId] canary_id
     * @param {string} [taskId] task_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listAbstractMetrics(page?: number, pageSize?: number, canaryId?: string, taskId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listAbstractMetrics(page, pageSize, canaryId, taskId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [userId] user_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listBillingDetails(page?: number, pageSize?: number, userId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listBillingDetails(page, pageSize, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint that manages Canarys
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [organizationId] organization_id
     * @param {ListCanariesStatusEnum} [status] status
     * @param {ListCanariesOrderingEnum} [ordering] Ordering
     * @param {string} [search] A search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listCanaries(page?: number, pageSize?: number, organizationId?: string, status?: ListCanariesStatusEnum, ordering?: ListCanariesOrderingEnum, search?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listCanaries(page, pageSize, organizationId, status, ordering, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A readonly ViewSet for viewing CanaryDailyForecast\'s
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [start] start
     * @param {string} [stop] stop
     * @param {string} [organizationId] organization_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listCanaryDailyForecasts(page?: number, pageSize?: number, start?: string, stop?: string, organizationId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listCanaryDailyForecasts(page, pageSize, start, stop, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [isActive] is_active
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listCanaryPartners(page?: number, pageSize?: number, isActive?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listCanaryPartners(page, pageSize, isActive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint to manipulate CustomCanaryPrototypeSource
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [canaryTemplateLibraryId] canary_template_library_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listCanaryPrototypeSourceCodes(page?: number, pageSize?: number, canaryTemplateLibraryId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listCanaryPrototypeSourceCodes(page, pageSize, canaryTemplateLibraryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint to manipulate CustomCanaryRestfulLibSource
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [restfulLibraryId] restful_library_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listCanaryRestfulLibSourceCodes(page?: number, pageSize?: number, restfulLibraryId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listCanaryRestfulLibSourceCodes(page, pageSize, restfulLibraryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [measuredFrom] measured_from
     * @param {string} [measuredUntil] measured_until
     * @param {string} [metricId] metric_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listCanaryTaskMetricData(page?: number, pageSize?: number, measuredFrom?: string, measuredUntil?: string, metricId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listCanaryTaskMetricData(page, pageSize, measuredFrom, measuredUntil, metricId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [measuredFrom] The Start date of the data export period, should be in this format: YYYY-MM-DD+HH%3AMM, note that %3A means \&#39;:\&#39; so don\&#39;t remove it.
     * @param {string} [measuredUntil] The End date of the data export period, should be in this format: YYYY-MM-DD+HH%3AMM, note that %3A means \&#39;:\&#39; so don\&#39;t remove it.
     * @param {string} [canaryId] The Canary id to get the exported data for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listCanaryTaskRunDataExports(page?: number, pageSize?: number, measuredFrom?: string, measuredUntil?: string, canaryId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listCanaryTaskRunDataExports(page, pageSize, measuredFrom, measuredUntil, canaryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * View to get CanaryTaskData as a CSV Export  * Requires token authentication.
     * @param {string} [measuredFrom] The Start date of the data export period, should be in this format: YYYY-MM-DD+HH%3AMM, note that %3A means \&#39;:\&#39; so don\&#39;t remove it.
     * @param {string} [measuredUntil] The End date of the data export period, should be in this format: YYYY-MM-DD+HH%3AMM, note that %3A means \&#39;:\&#39; so don\&#39;t remove it.
     * @param {string} [canaryId] The Canary id to get the exported data for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listCanaryTaskRunDataExports_1(measuredFrom?: string, measuredUntil?: string, canaryId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listCanaryTaskRunDataExports_1(measuredFrom, measuredUntil, canaryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get or update a single CanaryTask
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [canaryId] canary_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listCanaryTasks(page?: number, pageSize?: number, canaryId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listCanaryTasks(page, pageSize, canaryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint to manipulate CanaryTemplateLibrary
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [organizationId] organization_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listCanaryTemplateLibraries(page?: number, pageSize?: number, organizationId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listCanaryTemplateLibraries(page, pageSize, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [isActive] is_active
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listCanaryUsers(page?: number, pageSize?: number, isActive?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listCanaryUsers(page, pageSize, isActive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A readonly ViewSet for viewing Canaryable Services.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [providerId] provider_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listCanaryableServices(page?: number, pageSize?: number, providerId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listCanaryableServices(page, pageSize, providerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A readonly ViewSet for viewing CompositeDailyForecasts Services.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [start] start
     * @param {string} [stop] stop
     * @param {string} [organizationId] organization_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listCompositeDailyForecasts(page?: number, pageSize?: number, start?: string, stop?: string, organizationId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listCompositeDailyForecasts(page, pageSize, start, stop, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listCustomUserRoles(page?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listCustomUserRoles(page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Expose clusters to users  - important because it shares a security boundary with users - we must allow users to read all platform wide clusters (e.g. ours), but not change anything
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [organizationId] organization_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listKubernetesClusters(page?: number, pageSize?: number, organizationId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listKubernetesClusters(page, pageSize, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [isActive] is_active
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listLandingPageVideos(page?: number, pageSize?: number, isActive?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listLandingPageVideos(page, pageSize, isActive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A readonly ViewSet for viewing NeuralProphetCanaryDailyForecastData\'s
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [start] start
     * @param {string} [stop] stop
     * @param {string} [organizationId] organization_id
     * @param {string} [canaryId] canary_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listNeuralProphetCanaryDailyForecastData(page?: number, pageSize?: number, start?: string, stop?: string, organizationId?: string, canaryId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listNeuralProphetCanaryDailyForecastData(page, pageSize, start, stop, organizationId, canaryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * View to get NeuralServiceDailyForecast as a CSV Export  * Requires token authentication.
     * @param {string} [start] start
     * @param {string} [stop] stop
     * @param {string} [canaryId] canary_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listNeuralProphetCanaryDailyForecastDataExports(start?: string, stop?: string, canaryId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listNeuralProphetCanaryDailyForecastDataExports(start, stop, canaryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A readonly ViewSet for viewing NeuralProphetCanaryDailyForecast\'s
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [start] start
     * @param {string} [stop] stop
     * @param {string} [organizationId] organization_id
     * @param {string} [canaryId] canary_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listNeuralProphetCanaryDailyForecasts(page?: number, pageSize?: number, start?: string, stop?: string, organizationId?: string, canaryId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listNeuralProphetCanaryDailyForecasts(page, pageSize, start, stop, organizationId, canaryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A readonly ViewSet for viewing NeuralProphetCompositeDailyForecast Services.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [start] start
     * @param {string} [stop] stop
     * @param {string} [organizationId] organization_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listNeuralProphetCompositeDailyForecasts(page?: number, pageSize?: number, start?: string, stop?: string, organizationId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listNeuralProphetCompositeDailyForecasts(page, pageSize, start, stop, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A readonly ViewSet for viewing NeuralProphetServiceDailyForecast
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [start] start
     * @param {string} [stop] stop
     * @param {string} [organizationId] organization_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listNeuralProphetServiceDailyForecasts(page?: number, pageSize?: number, start?: string, stop?: string, organizationId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listNeuralProphetServiceDailyForecasts(page, pageSize, start, stop, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [organizationId] organization_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listNotificationChannelSettings(page?: number, pageSize?: number, organizationId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listNotificationChannelSettings(page, pageSize, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [alarmId] alarm_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listNotificationHistories(page?: number, pageSize?: number, alarmId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listNotificationHistories(page, pageSize, alarmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [alarmId] alarm_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listNotifications(page?: number, pageSize?: number, alarmId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listNotifications(page, pageSize, alarmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint to manipulate CustomCanaryRestfulLibSource
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [organizationId] organization_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listOpenAPIRestfulLibraries(page?: number, pageSize?: number, organizationId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listOpenAPIRestfulLibraries(page, pageSize, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [organizationId] organization_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listOrganizationAPIKeys(page?: number, pageSize?: number, organizationId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listOrganizationAPIKeys(page, pageSize, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listOrganizations(page?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listOrganizations(page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint that gets the Canaries * Requires token authentication.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [organizationId] The Organization id to filter with
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listPublicAPICanaries(page?: number, pageSize?: number, organizationId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listPublicAPICanaries(page, pageSize, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * View to get CanaryTaskData as a CSV Export  * Requires token authentication.
     * @param {string} [measuredFrom] The Start date of the data export period, should be in this format: YYYY-MM-DD+HH%3AMM, note that %3A means \&#39;:\&#39; so don\&#39;t remove it.
     * @param {string} [measuredUntil] The End date of the data export period, should be in this format: YYYY-MM-DD+HH%3AMM, note that %3A means \&#39;:\&#39; so don\&#39;t remove it.
     * @param {string} [canaryId] The Canary id to get the exported data for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listPublicAPICanaryTaskRunDataExports(measuredFrom?: string, measuredUntil?: string, canaryId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listPublicAPICanaryTaskRunDataExports(measuredFrom, measuredUntil, canaryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A readonly ViewSet for viewing Canaryable Services.  * Requires token authentication.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [providerId] The Provider id to filter with
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listPublicAPICanaryableServices(page?: number, pageSize?: number, providerId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listPublicAPICanaryableServices(page, pageSize, providerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A readonly ViewSet for viewing NeuralProphetCanaryDailyForecast\'s
     * @param {string} [canaryId] The Canary ID to filter with
     * @param {string} [organizationId] The Organization ID to filter with
     * @param {string} [start] The Start date of the forecasting period, should be the next day date and in this format: YYYY-MM-DD
     * @param {string} [stop] The End date of the forecasting period, should be after 5 days and in this format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listPublicAPINeuralProphetCanaryDailyForecasts(canaryId?: string, organizationId?: string, start?: string, stop?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listPublicAPINeuralProphetCanaryDailyForecasts(canaryId, organizationId, start, stop, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A readonly ViewSet for viewing Incident probability in the next 24 hours, should return 1 entry for the next day entered date.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [organizationId] The Organization ID to filter with
     * @param {string} [start] The Start date of the forecasting period, should be the next day date and in this format: YYYY-MM-DD
     * @param {string} [stop] The End date of the forecasting period, should be the next day date and in this format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listPublicAPINeuralProphetCompositeDailyForecasts(page?: number, pageSize?: number, organizationId?: string, start?: string, stop?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listPublicAPINeuralProphetCompositeDailyForecasts(page, pageSize, organizationId, start, stop, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * View to get Organizations for a user  * Requires token authentication.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listPublicAPIOrganizations(page?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listPublicAPIOrganizations(page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A readonly ViewSet for viewing ServiceCompliance Summaries.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [organizationId] The Organization id to filter with
     * @param {ListPublicAPIServiceComplianceSummariesPeriodCategoryEnum} [periodCategory] The Period Category to filter with, options are: \&#39;weekly\&#39;, \&#39;monthly\&#39;, \&#39;quarterly\&#39;, \&#39;yearly\&#39;
     * @param {string} [start] The Start date of the compliance period, should be in this format: YYYY-MM-DD
     * @param {string} [stop] The Stop date of the compliance period, should be in this format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listPublicAPIServiceComplianceSummaries(page?: number, pageSize?: number, organizationId?: string, periodCategory?: ListPublicAPIServiceComplianceSummariesPeriodCategoryEnum, start?: string, stop?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listPublicAPIServiceComplianceSummaries(page, pageSize, organizationId, periodCategory, start, stop, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * View to get ServiceHealth of a group of canaries * Requires token authentication.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [organizationId] The Organization id to filter with
     * @param {string} [serviceId] The Service id to filter with
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listPublicAPIServiceHealths(page?: number, pageSize?: number, organizationId?: string, serviceId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listPublicAPIServiceHealths(page, pageSize, organizationId, serviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A readonly ViewSet for viewing ServiceProviders.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listPublicAPIServiceProviders(page?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listPublicAPIServiceProviders(page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * View to manipulate ServiceComplianceColors for an organization
     * @param {string} [organizationId] organization_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listServiceComplianceColors(organizationId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listServiceComplianceColors(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A readonly ViewSet for viewing ServiceCompliance Histories
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [organizationId] organization_id
     * @param {string} [stop] stop
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listServiceComplianceHistories(page?: number, pageSize?: number, organizationId?: string, stop?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listServiceComplianceHistories(page, pageSize, organizationId, stop, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A readonly ViewSet for viewing ServiceCompliance Summaries.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [organizationId] organization_id
     * @param {ListServiceComplianceSummariesPeriodCategoryEnum} [periodCategory] period_category
     * @param {string} [start] start
     * @param {string} [stop] stop
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listServiceComplianceSummaries(page?: number, pageSize?: number, organizationId?: string, periodCategory?: ListServiceComplianceSummariesPeriodCategoryEnum, start?: string, stop?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listServiceComplianceSummaries(page, pageSize, organizationId, periodCategory, start, stop, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A readonly ViewSet for viewing ServiceDailyForecasts
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [start] start
     * @param {string} [stop] stop
     * @param {string} [organizationId] organization_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listServiceDailyForecasts(page?: number, pageSize?: number, start?: string, stop?: string, organizationId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listServiceDailyForecasts(page, pageSize, start, stop, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * View to manipulate ServiceHealthColors for an organization
     * @param {string} [organizationId] organization_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listServiceHealthColors(organizationId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listServiceHealthColors(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * View to get ServiceHealthEvaluationSettingsGroup for an organization
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [organizationId] organization_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listServiceHealthEvaluationSettingsGroups(page?: number, pageSize?: number, organizationId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listServiceHealthEvaluationSettingsGroups(page, pageSize, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [organizationId] organization_id
     * @param {string} [serviceId] service_id
     * @param {ListServiceHealthIncidentsIncidentStatusEnum} [incidentStatus] incident_status
     * @param {ListServiceHealthIncidentsOrderingEnum} [ordering] Ordering
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listServiceHealthIncidents(page?: number, pageSize?: number, organizationId?: string, serviceId?: string, incidentStatus?: ListServiceHealthIncidentsIncidentStatusEnum, ordering?: ListServiceHealthIncidentsOrderingEnum, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listServiceHealthIncidents(page, pageSize, organizationId, serviceId, incidentStatus, ordering, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * View to get ServiceHealth of a group of canaries
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [organizationId] organization_id
     * @param {string} [providerId] provider_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listServiceHealths(page?: number, pageSize?: number, organizationId?: string, providerId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listServiceHealths(page, pageSize, organizationId, providerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A readonly ViewSet for viewing ServiceProviderAvailabilityZone\'s.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [providerId] provider_id
     * @param {string} [regionId] region_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listServiceProviderAvailabilityZones(page?: number, pageSize?: number, providerId?: string, regionId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listServiceProviderAvailabilityZones(page, pageSize, providerId, regionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A readonly ViewSet for viewing ServiceProviderRegion\'s.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [providerId] provider_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listServiceProviderRegions(page?: number, pageSize?: number, providerId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listServiceProviderRegions(page, pageSize, providerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A readonly ViewSet for viewing ServiceProviders.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [organizationId] organization_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listServiceProviders(page?: number, pageSize?: number, organizationId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listServiceProviders(page, pageSize, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [alarmId] alarm_id
     * @param {string} [periodStart] period_start
     * @param {string} [periodStop] period_stop
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listSimpleAlarmEvaluations(page?: number, pageSize?: number, alarmId?: string, periodStart?: string, periodStop?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listSimpleAlarmEvaluations(page, pageSize, alarmId, periodStart, periodStop, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [alarmId] alarm_id
     * @param {string} [evaluationPeriodStart] evaluation_period_start
     * @param {string} [evaluationPeriodStop] evaluation_period_stop
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listSimpleAlarmHistories(page?: number, pageSize?: number, alarmId?: string, evaluationPeriodStart?: string, evaluationPeriodStop?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listSimpleAlarmHistories(page, pageSize, alarmId, evaluationPeriodStart, evaluationPeriodStop, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [metricId] metric_id
     * @param {string} [canaryId] canary_id
     * @param {string} [taskId] task_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listSimpleAlarms(page?: number, pageSize?: number, metricId?: string, canaryId?: string, taskId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listSimpleAlarms(page, pageSize, metricId, canaryId, taskId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A readonly ViewSet for viewing SnowflakeCanaryDailyForecastData Services.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [start] start
     * @param {string} [stop] stop
     * @param {string} [organizationId] organization_id
     * @param {string} [canaryId] canary_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listSnowflakeCanaryDailyForecastData(page?: number, pageSize?: number, start?: string, stop?: string, organizationId?: string, canaryId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listSnowflakeCanaryDailyForecastData(page, pageSize, start, stop, organizationId, canaryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A readonly ViewSet for viewing SnowflakeCanaryDailyForecast\'s
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [start] start
     * @param {string} [stop] stop
     * @param {string} [organizationId] organization_id
     * @param {string} [canaryId] canary_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listSnowflakeCanaryDailyForecasts(page?: number, pageSize?: number, start?: string, stop?: string, organizationId?: string, canaryId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listSnowflakeCanaryDailyForecasts(page, pageSize, start, stop, organizationId, canaryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A readonly ViewSet for viewing SnowflakeCompositeDailyForecast Services.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [start] start
     * @param {string} [stop] stop
     * @param {string} [organizationId] organization_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listSnowflakeCompositeDailyForecasts(page?: number, pageSize?: number, start?: string, stop?: string, organizationId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listSnowflakeCompositeDailyForecasts(page, pageSize, start, stop, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [organizationId] organization_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listSnowflakeForecastModels(page?: number, pageSize?: number, organizationId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listSnowflakeForecastModels(page, pageSize, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A readonly ViewSet for viewing SnowflakeServiceDailyForecast
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [start] start
     * @param {string} [stop] stop
     * @param {string} [organizationId] organization_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listSnowflakeServiceDailyForecasts(page?: number, pageSize?: number, start?: string, stop?: string, organizationId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listSnowflakeServiceDailyForecasts(page, pageSize, start, stop, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [organizationId] organization_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listSnowflakes(page?: number, pageSize?: number, organizationId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listSnowflakes(page, pageSize, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint to manipulate StoreCanary
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [cloud] cloud
     * @param {string} [component] component
     * @param {string} [status] status
     * @param {string} [isPrivate] is_private
     * @param {string} [isPinnedCanary] is_pinned_canary
     * @param {string} [uploadedUser] uploaded_user
     * @param {string} [company] company
     * @param {string} [search] A search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listStoreCanaries(page?: number, pageSize?: number, cloud?: string, component?: string, status?: string, isPrivate?: string, isPinnedCanary?: string, uploadedUser?: string, company?: string, search?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listStoreCanaries(page, pageSize, cloud, component, status, isPrivate, isPinnedCanary, uploadedUser, company, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listStoreCanaryClouds(page?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listStoreCanaryClouds(page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listStoreCanaryComponents(page?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listStoreCanaryComponents(page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listStoreCanaryProducts(page?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listStoreCanaryProducts(page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [subscriptionId] subscription_id
     * @param {string} [subscriptionUserId] subscription_user_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listSubscriptionCancelRequests(page?: number, pageSize?: number, subscriptionId?: string, subscriptionUserId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listSubscriptionCancelRequests(page, pageSize, subscriptionId, subscriptionUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint to manipulate TemplateCanary
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [canaryTemplateLibraryId] canary_template_library_id
     * @param {string} [providerId] provider_id
     * @param {string} [serviceId] service_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listTemplateCanaries(page?: number, pageSize?: number, canaryTemplateLibraryId?: string, providerId?: string, serviceId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listTemplateCanaries(page, pageSize, canaryTemplateLibraryId, providerId, serviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint to manipulate TemplateTask
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [canaryTemplateLibraryId] canary_template_library_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listTemplateTasks(page?: number, pageSize?: number, canaryTemplateLibraryId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listTemplateTasks(page, pageSize, canaryTemplateLibraryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listUserGatedContentDownloadRequests(page?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listUserGatedContentDownloadRequests(page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [userId] user_id
     * @param {string} [platformActive] platform_active
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listUserSubscribedPlans(page?: number, pageSize?: number, userId?: string, platformActive?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listUserSubscribedPlans(page, pageSize, userId, platformActive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint that allows authenticated clients to view or edit users
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listUsers(page?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listUsers(page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint to manipulate StoreCanary
     * @param {string} id A UUID string identifying this Store Canary.
     * @param {StoreCanaryAutoLoadCanaryTemplateLibraryReadOnly} [storeCanaryAutoLoadCanaryTemplateLibraryReadOnly] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public loadTemplateLibrariesStoreCanary(id: string, storeCanaryAutoLoadCanaryTemplateLibraryReadOnly?: StoreCanaryAutoLoadCanaryTemplateLibraryReadOnly, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).loadTemplateLibrariesStoreCanary(id, storeCanaryAutoLoadCanaryTemplateLibraryReadOnly, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public logoutUserAuthViewSet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).logoutUserAuthViewSet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this Abstract Metric Condition.
     * @param {string} [metricId] metric_id
     * @param {string} [canaryId] canary_id
     * @param {string} [taskId] task_id
     * @param {AbstractMetricBoundaryCondition} [abstractMetricBoundaryCondition] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public partialUpdateAbstractMetricBoundaryCondition(id: string, metricId?: string, canaryId?: string, taskId?: string, abstractMetricBoundaryCondition?: AbstractMetricBoundaryCondition, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).partialUpdateAbstractMetricBoundaryCondition(id, metricId, canaryId, taskId, abstractMetricBoundaryCondition, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this billing detail.
     * @param {string} [userId] user_id
     * @param {BillingDetail} [billingDetail] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public partialUpdateBillingDetail(id: string, userId?: string, billingDetail?: BillingDetail, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).partialUpdateBillingDetail(id, userId, billingDetail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint that manages Canarys
     * @param {string} id A UUID string identifying this Canary.
     * @param {string} [organizationId] organization_id
     * @param {PartialUpdateCanaryStatusEnum} [status] status
     * @param {PartialUpdateCanaryOrderingEnum} [ordering] Ordering
     * @param {string} [search] A search term.
     * @param {Canary} [canary] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public partialUpdateCanary(id: string, organizationId?: string, status?: PartialUpdateCanaryStatusEnum, ordering?: PartialUpdateCanaryOrderingEnum, search?: string, canary?: Canary, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).partialUpdateCanary(id, organizationId, status, ordering, search, canary, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this canary cluster deployment.
     * @param {CanaryClusterDeployment} [canaryClusterDeployment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public partialUpdateCanaryClusterDeployment(id: string, canaryClusterDeployment?: CanaryClusterDeployment, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).partialUpdateCanaryClusterDeployment(id, canaryClusterDeployment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint to manipulate CustomCanaryPrototypeSource
     * @param {string} id A UUID string identifying this Canary Prototype Source Code.
     * @param {string} [canaryTemplateLibraryId] canary_template_library_id
     * @param {CanaryPrototypeSource} [canaryPrototypeSource] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public partialUpdateCanaryPrototypeSourceCode(id: string, canaryTemplateLibraryId?: string, canaryPrototypeSource?: CanaryPrototypeSource, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).partialUpdateCanaryPrototypeSourceCode(id, canaryTemplateLibraryId, canaryPrototypeSource, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint to manipulate CustomCanaryRestfulLibSource
     * @param {string} id A UUID string identifying this Canary Restful Library Source Code.
     * @param {string} [restfulLibraryId] restful_library_id
     * @param {CanaryRestfulLibSourceFile} [canaryRestfulLibSourceFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public partialUpdateCanaryRestfulLibSourceCode(id: string, restfulLibraryId?: string, canaryRestfulLibSourceFile?: CanaryRestfulLibSourceFile, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).partialUpdateCanaryRestfulLibSourceCode(id, restfulLibraryId, canaryRestfulLibSourceFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get or update a single CanaryTask
     * @param {string} id A UUID string identifying this Canary Task.
     * @param {string} [canaryId] canary_id
     * @param {CanaryTask} [canaryTask] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public partialUpdateCanaryTask(id: string, canaryId?: string, canaryTask?: CanaryTask, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).partialUpdateCanaryTask(id, canaryId, canaryTask, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get or update a single CanaryTask
     * @param {string} id A UUID string identifying this canary task env vars.
     * @param {CanaryTaskEnvVars} [canaryTaskEnvVars] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public partialUpdateCanaryTaskEnvVars(id: string, canaryTaskEnvVars?: CanaryTaskEnvVars, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).partialUpdateCanaryTaskEnvVars(id, canaryTaskEnvVars, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint to manipulate CanaryTemplateLibrary
     * @param {string} id A UUID string identifying this Canary Template Library.
     * @param {string} [organizationId] organization_id
     * @param {CanaryTemplateLibrary} [canaryTemplateLibrary] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public partialUpdateCanaryTemplateLibrary(id: string, organizationId?: string, canaryTemplateLibrary?: CanaryTemplateLibrary, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).partialUpdateCanaryTemplateLibrary(id, organizationId, canaryTemplateLibrary, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Expose clusters to users  - important because it shares a security boundary with users - we must allow users to read all platform wide clusters (e.g. ours), but not change anything
     * @param {string} id A UUID string identifying this Kubernetes Cluster.
     * @param {string} [organizationId] organization_id
     * @param {KubernetesCluster} [kubernetesCluster] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public partialUpdateKubernetesCluster(id: string, organizationId?: string, kubernetesCluster?: KubernetesCluster, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).partialUpdateKubernetesCluster(id, organizationId, kubernetesCluster, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this Notification.
     * @param {string} [alarmId] alarm_id
     * @param {Notification} [notification] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public partialUpdateNotification(id: string, alarmId?: string, notification?: Notification, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).partialUpdateNotification(id, alarmId, notification, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this Notification Channel Setting.
     * @param {string} [organizationId] organization_id
     * @param {NotificationChannelSetting} [notificationChannelSetting] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public partialUpdateNotificationChannelSetting(id: string, organizationId?: string, notificationChannelSetting?: NotificationChannelSetting, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).partialUpdateNotificationChannelSetting(id, organizationId, notificationChannelSetting, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint to manipulate CustomCanaryRestfulLibSource
     * @param {string} id A UUID string identifying this OpenAPI Restful Library.
     * @param {string} [organizationId] organization_id
     * @param {OpenAPIRestfulLibrary} [openAPIRestfulLibrary] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public partialUpdateOpenAPIRestfulLibrary(id: string, organizationId?: string, openAPIRestfulLibrary?: OpenAPIRestfulLibrary, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).partialUpdateOpenAPIRestfulLibrary(id, organizationId, openAPIRestfulLibrary, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this Organization.
     * @param {OrganizationUpdate} [organizationUpdate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public partialUpdateOrganization(id: string, organizationUpdate?: OrganizationUpdate, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).partialUpdateOrganization(id, organizationUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} prefix 
     * @param {string} [organizationId] organization_id
     * @param {OrganizationAPIKey} [organizationAPIKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public partialUpdateOrganizationAPIKey(prefix: string, organizationId?: string, organizationAPIKey?: OrganizationAPIKey, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).partialUpdateOrganizationAPIKey(prefix, organizationId, organizationAPIKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * View to manipulate ServiceComplianceColors for an organization
     * @param {string} id A UUID string identifying this Service Compliance Color.
     * @param {string} [organizationId] organization_id
     * @param {ServiceComplianceColors} [serviceComplianceColors] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public partialUpdateServiceComplianceColors(id: string, organizationId?: string, serviceComplianceColors?: ServiceComplianceColors, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).partialUpdateServiceComplianceColors(id, organizationId, serviceComplianceColors, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * View to manipulate ServiceHealthColors for an organization
     * @param {string} id A UUID string identifying this Service Health Color.
     * @param {string} [organizationId] organization_id
     * @param {ServiceHealthColors} [serviceHealthColors] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public partialUpdateServiceHealthColors(id: string, organizationId?: string, serviceHealthColors?: ServiceHealthColors, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).partialUpdateServiceHealthColors(id, organizationId, serviceHealthColors, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * View to get ServiceHealthEvaluationSettingsGroup for an organization
     * @param {string} id A UUID string identifying this Service Health Evaluation Settings Group.
     * @param {string} [organizationId] organization_id
     * @param {ServiceHealthEvaluationSettingsGroup} [serviceHealthEvaluationSettingsGroup] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public partialUpdateServiceHealthEvaluationSettingsGroup(id: string, organizationId?: string, serviceHealthEvaluationSettingsGroup?: ServiceHealthEvaluationSettingsGroup, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).partialUpdateServiceHealthEvaluationSettingsGroup(id, organizationId, serviceHealthEvaluationSettingsGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this Simple Alarm.
     * @param {string} [metricId] metric_id
     * @param {string} [canaryId] canary_id
     * @param {string} [taskId] task_id
     * @param {SimpleAlarm} [simpleAlarm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public partialUpdateSimpleAlarm(id: string, metricId?: string, canaryId?: string, taskId?: string, simpleAlarm?: SimpleAlarm, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).partialUpdateSimpleAlarm(id, metricId, canaryId, taskId, simpleAlarm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this Snowflake Setting.
     * @param {string} [organizationId] organization_id
     * @param {Snowflake} [snowflake] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public partialUpdateSnowflake(id: string, organizationId?: string, snowflake?: Snowflake, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).partialUpdateSnowflake(id, organizationId, snowflake, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this Snowflake Forecast Model.
     * @param {string} [organizationId] organization_id
     * @param {SnowflakeForecastModel} [snowflakeForecastModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public partialUpdateSnowflakeForecastModel(id: string, organizationId?: string, snowflakeForecastModel?: SnowflakeForecastModel, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).partialUpdateSnowflakeForecastModel(id, organizationId, snowflakeForecastModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint to manipulate StoreCanary
     * @param {string} id A UUID string identifying this Store Canary.
     * @param {string} [cloud] cloud
     * @param {string} [component] component
     * @param {string} [status] status
     * @param {string} [isPrivate] is_private
     * @param {string} [isPinnedCanary] is_pinned_canary
     * @param {string} [uploadedUser] uploaded_user
     * @param {string} [company] company
     * @param {string} [search] A search term.
     * @param {StoreCanaryWrite} [storeCanaryWrite] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public partialUpdateStoreCanary(id: string, cloud?: string, component?: string, status?: string, isPrivate?: string, isPinnedCanary?: string, uploadedUser?: string, company?: string, search?: string, storeCanaryWrite?: StoreCanaryWrite, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).partialUpdateStoreCanary(id, cloud, component, status, isPrivate, isPinnedCanary, uploadedUser, company, search, storeCanaryWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this Subscription Cancel Request.
     * @param {string} [subscriptionId] subscription_id
     * @param {string} [subscriptionUserId] subscription_user_id
     * @param {SubscriptionCancelRequest} [subscriptionCancelRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public partialUpdateSubscriptionCancelRequest(id: string, subscriptionId?: string, subscriptionUserId?: string, subscriptionCancelRequest?: SubscriptionCancelRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).partialUpdateSubscriptionCancelRequest(id, subscriptionId, subscriptionUserId, subscriptionCancelRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint to manipulate TemplateCanary
     * @param {string} id A UUID string identifying this Template Canary.
     * @param {string} [canaryTemplateLibraryId] canary_template_library_id
     * @param {string} [providerId] provider_id
     * @param {string} [serviceId] service_id
     * @param {TemplateCanaryWritable} [templateCanaryWritable] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public partialUpdateTemplateCanary(id: string, canaryTemplateLibraryId?: string, providerId?: string, serviceId?: string, templateCanaryWritable?: TemplateCanaryWritable, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).partialUpdateTemplateCanary(id, canaryTemplateLibraryId, providerId, serviceId, templateCanaryWritable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint to manipulate TemplateTask
     * @param {string} id A UUID string identifying this Template Task.
     * @param {string} [canaryTemplateLibraryId] canary_template_library_id
     * @param {TemplateTaskReadOnly} [templateTaskReadOnly] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public partialUpdateTemplateTask(id: string, canaryTemplateLibraryId?: string, templateTaskReadOnly?: TemplateTaskReadOnly, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).partialUpdateTemplateTask(id, canaryTemplateLibraryId, templateTaskReadOnly, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint that allows authenticated clients to view or edit users
     * @param {string} id A UUID string identifying this user.
     * @param {User} [user] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public partialUpdateUser(id: string, user?: User, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).partialUpdateUser(id, user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this User Subscribed Plan.
     * @param {string} [userId] user_id
     * @param {string} [platformActive] platform_active
     * @param {UserSubscribedPlan} [userSubscribedPlan] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public partialUpdateUserSubscribedPlan(id: string, userId?: string, platformActive?: string, userSubscribedPlan?: UserSubscribedPlan, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).partialUpdateUserSubscribedPlan(id, userId, platformActive, userSubscribedPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public refreshAuthTokenAuthViewSet(body?: any, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).refreshAuthTokenAuthViewSet(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this Organization.
     * @param {OrganizationMember} [organizationMember] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public removeMemberOrganization(id: string, organizationMember?: OrganizationMember, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).removeMemberOrganization(id, organizationMember, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public requestResetPasswordAuthViewSet(body?: any, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).requestResetPasswordAuthViewSet(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public resetPasswordAuthViewSet(body?: any, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).resetPasswordAuthViewSet(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this Abstract Metric.
     * @param {string} [canaryId] canary_id
     * @param {string} [taskId] task_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveAbstractMetric(id: string, canaryId?: string, taskId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveAbstractMetric(id, canaryId, taskId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this Abstract Metric Condition.
     * @param {string} [metricId] metric_id
     * @param {string} [canaryId] canary_id
     * @param {string} [taskId] task_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveAbstractMetricBoundaryCondition(id: string, metricId?: string, canaryId?: string, taskId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveAbstractMetricBoundaryCondition(id, metricId, canaryId, taskId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveApiVersion(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveApiVersion(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this billing detail.
     * @param {string} [userId] user_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveBillingDetail(id: string, userId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveBillingDetail(id, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint that manages Canarys
     * @param {string} id A UUID string identifying this Canary.
     * @param {string} [organizationId] organization_id
     * @param {RetrieveCanaryStatusEnum} [status] status
     * @param {RetrieveCanaryOrderingEnum} [ordering] Ordering
     * @param {string} [search] A search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveCanary(id: string, organizationId?: string, status?: RetrieveCanaryStatusEnum, ordering?: RetrieveCanaryOrderingEnum, search?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveCanary(id, organizationId, status, ordering, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A readonly ViewSet for viewing CanaryDailyForecast\'s
     * @param {string} id A UUID string identifying this Canary Daily Forecast.
     * @param {string} [start] start
     * @param {string} [stop] stop
     * @param {string} [organizationId] organization_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveCanaryDailyForecast(id: string, start?: string, stop?: string, organizationId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveCanaryDailyForecast(id, start, stop, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint to manipulate CustomCanaryPrototypeSource
     * @param {string} id A UUID string identifying this Canary Prototype Source Code.
     * @param {string} [canaryTemplateLibraryId] canary_template_library_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveCanaryPrototypeSourceCode(id: string, canaryTemplateLibraryId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveCanaryPrototypeSourceCode(id, canaryTemplateLibraryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint to manipulate CustomCanaryRestfulLibSource
     * @param {string} id A UUID string identifying this Canary Restful Library Source Code.
     * @param {string} [restfulLibraryId] restful_library_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveCanaryRestfulLibSourceCode(id: string, restfulLibraryId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveCanaryRestfulLibSourceCode(id, restfulLibraryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get or update a single CanaryTask
     * @param {string} id A UUID string identifying this Canary Task.
     * @param {string} [canaryId] canary_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveCanaryTask(id: string, canaryId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveCanaryTask(id, canaryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * View to get CanaryData as a graph  * Requires token authentication.
     * @param {string} id A UUID string identifying this canary task data graph.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveCanaryTaskDataGraph(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveCanaryTaskDataGraph(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get or update a single CanaryTask
     * @param {string} id A UUID string identifying this canary task env vars.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveCanaryTaskEnvVars(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveCanaryTaskEnvVars(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this Canary Task Metric Data.
     * @param {string} [measuredFrom] measured_from
     * @param {string} [measuredUntil] measured_until
     * @param {string} [metricId] metric_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveCanaryTaskMetricData(id: string, measuredFrom?: string, measuredUntil?: string, metricId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveCanaryTaskMetricData(id, measuredFrom, measuredUntil, metricId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this Canary Task Run Data Export.
     * @param {string} [measuredFrom] The Start date of the data export period, should be in this format: YYYY-MM-DD+HH%3AMM, note that %3A means \&#39;:\&#39; so don\&#39;t remove it.
     * @param {string} [measuredUntil] The End date of the data export period, should be in this format: YYYY-MM-DD+HH%3AMM, note that %3A means \&#39;:\&#39; so don\&#39;t remove it.
     * @param {string} [canaryId] The Canary id to get the exported data for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveCanaryTaskRunDataExport(id: string, measuredFrom?: string, measuredUntil?: string, canaryId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveCanaryTaskRunDataExport(id, measuredFrom, measuredUntil, canaryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint to manipulate CanaryTemplateLibrary
     * @param {string} id A UUID string identifying this Canary Template Library.
     * @param {string} [organizationId] organization_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveCanaryTemplateLibrary(id: string, organizationId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveCanaryTemplateLibrary(id, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A readonly ViewSet for viewing Canaryable Services.
     * @param {string} id A UUID string identifying this Canaryable Service.
     * @param {string} [providerId] provider_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveCanaryableService(id: string, providerId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveCanaryableService(id, providerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A readonly ViewSet for viewing CompositeDailyForecasts Services.
     * @param {string} id A UUID string identifying this Composite Daily Forecast.
     * @param {string} [start] start
     * @param {string} [stop] stop
     * @param {string} [organizationId] organization_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveCompositeDailyForecast(id: string, start?: string, stop?: string, organizationId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveCompositeDailyForecast(id, start, stop, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Expose clusters to users  - important because it shares a security boundary with users - we must allow users to read all platform wide clusters (e.g. ours), but not change anything
     * @param {string} id A UUID string identifying this Kubernetes Cluster.
     * @param {string} [organizationId] organization_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveKubernetesCluster(id: string, organizationId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveKubernetesCluster(id, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A readonly ViewSet for viewing NeuralProphetCanaryDailyForecast\'s
     * @param {string} id A UUID string identifying this Neural Prophet Canary Daily Forecast.
     * @param {string} [start] start
     * @param {string} [stop] stop
     * @param {string} [organizationId] organization_id
     * @param {string} [canaryId] canary_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveNeuralProphetCanaryDailyForecast(id: string, start?: string, stop?: string, organizationId?: string, canaryId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveNeuralProphetCanaryDailyForecast(id, start, stop, organizationId, canaryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A readonly ViewSet for viewing NeuralProphetCanaryDailyForecastData\'s
     * @param {string} id A UUID string identifying this Neural Prophet Canary Daily Forecast Data.
     * @param {string} [start] start
     * @param {string} [stop] stop
     * @param {string} [organizationId] organization_id
     * @param {string} [canaryId] canary_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveNeuralProphetCanaryDailyForecastData(id: string, start?: string, stop?: string, organizationId?: string, canaryId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveNeuralProphetCanaryDailyForecastData(id, start, stop, organizationId, canaryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A readonly ViewSet for viewing NeuralProphetCompositeDailyForecast Services.
     * @param {string} id A UUID string identifying this Neural Prophet Composite Daily Forecast.
     * @param {string} [start] start
     * @param {string} [stop] stop
     * @param {string} [organizationId] organization_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveNeuralProphetCompositeDailyForecast(id: string, start?: string, stop?: string, organizationId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveNeuralProphetCompositeDailyForecast(id, start, stop, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A readonly ViewSet for viewing NeuralProphetServiceDailyForecast
     * @param {string} id A UUID string identifying this Neural Prophet Service Daily Forecast.
     * @param {string} [start] start
     * @param {string} [stop] stop
     * @param {string} [organizationId] organization_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveNeuralProphetServiceDailyForecast(id: string, start?: string, stop?: string, organizationId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveNeuralProphetServiceDailyForecast(id, start, stop, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this Notification.
     * @param {string} [alarmId] alarm_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveNotification(id: string, alarmId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveNotification(id, alarmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this Notification Channel Setting.
     * @param {string} [organizationId] organization_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveNotificationChannelSetting(id: string, organizationId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveNotificationChannelSetting(id, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this Notification History.
     * @param {string} [alarmId] alarm_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveNotificationHistory(id: string, alarmId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveNotificationHistory(id, alarmId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint to manipulate CustomCanaryRestfulLibSource
     * @param {string} id A UUID string identifying this OpenAPI Restful Library.
     * @param {string} [organizationId] organization_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveOpenAPIRestfulLibrary(id: string, organizationId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveOpenAPIRestfulLibrary(id, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this Organization.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveOrganization(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveOrganization(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} prefix 
     * @param {string} [organizationId] organization_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveOrganizationAPIKey(prefix: string, organizationId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveOrganizationAPIKey(prefix, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint that gets the status of the canary * Requires token authentication.
     * @param {string} id A UUID string identifying this public api canary.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrievePublicAPICanary(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrievePublicAPICanary(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A readonly ViewSet for viewing ServiceCompliance Summaries.
     * @param {string} id A UUID string identifying this public api service compliance summary.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrievePublicAPIServiceComplianceSummary(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrievePublicAPIServiceComplianceSummary(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * View to get ServiceHealth of a canary * Requires token authentication.
     * @param {string} id A UUID string identifying this public api service health.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrievePublicAPIServiceHealth(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrievePublicAPIServiceHealth(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * View to manipulate ServiceComplianceColors for an organization
     * @param {string} id A UUID string identifying this Service Compliance Color.
     * @param {string} [organizationId] organization_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveServiceComplianceColors(id: string, organizationId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveServiceComplianceColors(id, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A readonly ViewSet for viewing ServiceCompliance Histories
     * @param {string} id A UUID string identifying this service compliance histories.
     * @param {string} [organizationId] organization_id
     * @param {string} [stop] stop
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveServiceComplianceHistories(id: string, organizationId?: string, stop?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveServiceComplianceHistories(id, organizationId, stop, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A readonly ViewSet for viewing ServiceCompliance Summaries.
     * @param {string} id A UUID string identifying this Service Compliance Summary.
     * @param {string} [organizationId] organization_id
     * @param {RetrieveServiceComplianceSummaryPeriodCategoryEnum} [periodCategory] period_category
     * @param {string} [start] start
     * @param {string} [stop] stop
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveServiceComplianceSummary(id: string, organizationId?: string, periodCategory?: RetrieveServiceComplianceSummaryPeriodCategoryEnum, start?: string, stop?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveServiceComplianceSummary(id, organizationId, periodCategory, start, stop, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A readonly ViewSet for viewing ServiceDailyForecasts
     * @param {string} id A UUID string identifying this Service Daily Forecast.
     * @param {string} [start] start
     * @param {string} [stop] stop
     * @param {string} [organizationId] organization_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveServiceDailyForecast(id: string, start?: string, stop?: string, organizationId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveServiceDailyForecast(id, start, stop, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * View to get ServiceHealth of a group of canaries
     * @param {string} id A UUID string identifying this Service Health.
     * @param {string} [organizationId] organization_id
     * @param {string} [providerId] provider_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveServiceHealth(id: string, organizationId?: string, providerId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveServiceHealth(id, organizationId, providerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * View to manipulate ServiceHealthColors for an organization
     * @param {string} id A UUID string identifying this Service Health Color.
     * @param {string} [organizationId] organization_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveServiceHealthColors(id: string, organizationId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveServiceHealthColors(id, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * View to get ServiceHealthEvaluationSettingsGroup for an organization
     * @param {string} id A UUID string identifying this Service Health Evaluation Settings Group.
     * @param {string} [organizationId] organization_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveServiceHealthEvaluationSettingsGroup(id: string, organizationId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveServiceHealthEvaluationSettingsGroup(id, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this service health incident.
     * @param {string} [organizationId] organization_id
     * @param {string} [serviceId] service_id
     * @param {RetrieveServiceHealthIncidentIncidentStatusEnum} [incidentStatus] incident_status
     * @param {RetrieveServiceHealthIncidentOrderingEnum} [ordering] Ordering
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveServiceHealthIncident(id: string, organizationId?: string, serviceId?: string, incidentStatus?: RetrieveServiceHealthIncidentIncidentStatusEnum, ordering?: RetrieveServiceHealthIncidentOrderingEnum, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveServiceHealthIncident(id, organizationId, serviceId, incidentStatus, ordering, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A readonly ViewSet for viewing ServiceProviders.
     * @param {string} id A UUID string identifying this Service Provider.
     * @param {string} [organizationId] organization_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveServiceProvider(id: string, organizationId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveServiceProvider(id, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A readonly ViewSet for viewing ServiceProviderAvailabilityZone\'s.
     * @param {string} id A UUID string identifying this Service Provider Availability Zone.
     * @param {string} [providerId] provider_id
     * @param {string} [regionId] region_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveServiceProviderAvailabilityZone(id: string, providerId?: string, regionId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveServiceProviderAvailabilityZone(id, providerId, regionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A readonly ViewSet for viewing ServiceProviderRegion\'s.
     * @param {string} id A UUID string identifying this Service Provider Region.
     * @param {string} [providerId] provider_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveServiceProviderRegion(id: string, providerId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveServiceProviderRegion(id, providerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this Simple Alarm.
     * @param {string} [metricId] metric_id
     * @param {string} [canaryId] canary_id
     * @param {string} [taskId] task_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveSimpleAlarm(id: string, metricId?: string, canaryId?: string, taskId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveSimpleAlarm(id, metricId, canaryId, taskId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this Simple Alarm Evaluation.
     * @param {string} [alarmId] alarm_id
     * @param {string} [periodStart] period_start
     * @param {string} [periodStop] period_stop
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveSimpleAlarmEvaluation(id: string, alarmId?: string, periodStart?: string, periodStop?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveSimpleAlarmEvaluation(id, alarmId, periodStart, periodStop, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this Simple Alarm History.
     * @param {string} [alarmId] alarm_id
     * @param {string} [evaluationPeriodStart] evaluation_period_start
     * @param {string} [evaluationPeriodStop] evaluation_period_stop
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveSimpleAlarmHistory(id: string, alarmId?: string, evaluationPeriodStart?: string, evaluationPeriodStop?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveSimpleAlarmHistory(id, alarmId, evaluationPeriodStart, evaluationPeriodStop, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this Snowflake Setting.
     * @param {string} [organizationId] organization_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveSnowflake(id: string, organizationId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveSnowflake(id, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A readonly ViewSet for viewing SnowflakeCanaryDailyForecast\'s
     * @param {string} id A UUID string identifying this Snowflake Canary Daily Forecast.
     * @param {string} [start] start
     * @param {string} [stop] stop
     * @param {string} [organizationId] organization_id
     * @param {string} [canaryId] canary_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveSnowflakeCanaryDailyForecast(id: string, start?: string, stop?: string, organizationId?: string, canaryId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveSnowflakeCanaryDailyForecast(id, start, stop, organizationId, canaryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A readonly ViewSet for viewing SnowflakeCanaryDailyForecastData Services.
     * @param {string} id A UUID string identifying this Snowflake Canary Daily Data Forecast.
     * @param {string} [start] start
     * @param {string} [stop] stop
     * @param {string} [organizationId] organization_id
     * @param {string} [canaryId] canary_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveSnowflakeCanaryDailyForecastData(id: string, start?: string, stop?: string, organizationId?: string, canaryId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveSnowflakeCanaryDailyForecastData(id, start, stop, organizationId, canaryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A readonly ViewSet for viewing SnowflakeCompositeDailyForecast Services.
     * @param {string} id A UUID string identifying this Snowflake Composite Daily Forecast.
     * @param {string} [start] start
     * @param {string} [stop] stop
     * @param {string} [organizationId] organization_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveSnowflakeCompositeDailyForecast(id: string, start?: string, stop?: string, organizationId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveSnowflakeCompositeDailyForecast(id, start, stop, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this Snowflake Forecast Model.
     * @param {string} [organizationId] organization_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveSnowflakeForecastModel(id: string, organizationId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveSnowflakeForecastModel(id, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A readonly ViewSet for viewing SnowflakeServiceDailyForecast
     * @param {string} id A UUID string identifying this Snowflake Service Daily Forecast.
     * @param {string} [start] start
     * @param {string} [stop] stop
     * @param {string} [organizationId] organization_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveSnowflakeServiceDailyForecast(id: string, start?: string, stop?: string, organizationId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveSnowflakeServiceDailyForecast(id, start, stop, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint to manipulate StoreCanary
     * @param {string} id A UUID string identifying this Store Canary.
     * @param {string} [cloud] cloud
     * @param {string} [component] component
     * @param {string} [status] status
     * @param {string} [isPrivate] is_private
     * @param {string} [isPinnedCanary] is_pinned_canary
     * @param {string} [uploadedUser] uploaded_user
     * @param {string} [company] company
     * @param {string} [search] A search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveStoreCanary(id: string, cloud?: string, component?: string, status?: string, isPrivate?: string, isPinnedCanary?: string, uploadedUser?: string, company?: string, search?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveStoreCanary(id, cloud, component, status, isPrivate, isPinnedCanary, uploadedUser, company, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this Subscription Cancel Request.
     * @param {string} [subscriptionId] subscription_id
     * @param {string} [subscriptionUserId] subscription_user_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveSubscriptionCancelRequest(id: string, subscriptionId?: string, subscriptionUserId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveSubscriptionCancelRequest(id, subscriptionId, subscriptionUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint to manipulate TemplateCanary
     * @param {string} id A UUID string identifying this Template Canary.
     * @param {string} [canaryTemplateLibraryId] canary_template_library_id
     * @param {string} [providerId] provider_id
     * @param {string} [serviceId] service_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveTemplateCanary(id: string, canaryTemplateLibraryId?: string, providerId?: string, serviceId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveTemplateCanary(id, canaryTemplateLibraryId, providerId, serviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint to manipulate TemplateTask
     * @param {string} id A UUID string identifying this Template Task.
     * @param {string} [canaryTemplateLibraryId] canary_template_library_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveTemplateTask(id: string, canaryTemplateLibraryId?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveTemplateTask(id, canaryTemplateLibraryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint that allows authenticated clients to view or edit users
     * @param {string} id A UUID string identifying this user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveUser(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this User Subscribed Plan.
     * @param {string} [userId] user_id
     * @param {string} [platformActive] platform_active
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public retrieveUserSubscribedPlan(id: string, userId?: string, platformActive?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).retrieveUserSubscribedPlan(id, userId, platformActive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint that manages Canarys
     * @param {string} id A UUID string identifying this Canary.
     * @param {CanarySchedule} [canarySchedule] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public scheduleCanary(id: string, canarySchedule?: CanarySchedule, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).scheduleCanary(id, canarySchedule, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this User Subscribed Plan.
     * @param {UserSubscribedPlanAssignSubtractCanary} [userSubscribedPlanAssignSubtractCanary] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public subtractCanaryUserSubscribedPlan(id: string, userSubscribedPlanAssignSubtractCanary?: UserSubscribedPlanAssignSubtractCanary, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).subtractCanaryUserSubscribedPlan(id, userSubscribedPlanAssignSubtractCanary, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this Abstract Metric Condition.
     * @param {string} [metricId] metric_id
     * @param {string} [canaryId] canary_id
     * @param {string} [taskId] task_id
     * @param {AbstractMetricBoundaryCondition} [abstractMetricBoundaryCondition] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateAbstractMetricBoundaryCondition(id: string, metricId?: string, canaryId?: string, taskId?: string, abstractMetricBoundaryCondition?: AbstractMetricBoundaryCondition, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateAbstractMetricBoundaryCondition(id, metricId, canaryId, taskId, abstractMetricBoundaryCondition, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this billing detail.
     * @param {string} [userId] user_id
     * @param {BillingDetail} [billingDetail] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateBillingDetail(id: string, userId?: string, billingDetail?: BillingDetail, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateBillingDetail(id, userId, billingDetail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint that manages Canarys
     * @param {string} id A UUID string identifying this Canary.
     * @param {string} [organizationId] organization_id
     * @param {UpdateCanaryStatusEnum} [status] status
     * @param {UpdateCanaryOrderingEnum} [ordering] Ordering
     * @param {string} [search] A search term.
     * @param {Canary} [canary] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateCanary(id: string, organizationId?: string, status?: UpdateCanaryStatusEnum, ordering?: UpdateCanaryOrderingEnum, search?: string, canary?: Canary, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateCanary(id, organizationId, status, ordering, search, canary, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this canary cluster deployment.
     * @param {CanaryClusterDeployment} [canaryClusterDeployment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateCanaryClusterDeployment(id: string, canaryClusterDeployment?: CanaryClusterDeployment, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateCanaryClusterDeployment(id, canaryClusterDeployment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint to manipulate CustomCanaryPrototypeSource
     * @param {string} id A UUID string identifying this Canary Prototype Source Code.
     * @param {string} [canaryTemplateLibraryId] canary_template_library_id
     * @param {CanaryPrototypeSource} [canaryPrototypeSource] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateCanaryPrototypeSourceCode(id: string, canaryTemplateLibraryId?: string, canaryPrototypeSource?: CanaryPrototypeSource, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateCanaryPrototypeSourceCode(id, canaryTemplateLibraryId, canaryPrototypeSource, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint to manipulate CustomCanaryRestfulLibSource
     * @param {string} id A UUID string identifying this Canary Restful Library Source Code.
     * @param {string} [restfulLibraryId] restful_library_id
     * @param {CanaryRestfulLibSourceFile} [canaryRestfulLibSourceFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateCanaryRestfulLibSourceCode(id: string, restfulLibraryId?: string, canaryRestfulLibSourceFile?: CanaryRestfulLibSourceFile, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateCanaryRestfulLibSourceCode(id, restfulLibraryId, canaryRestfulLibSourceFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get or update a single CanaryTask
     * @param {string} id A UUID string identifying this Canary Task.
     * @param {string} [canaryId] canary_id
     * @param {CanaryTask} [canaryTask] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateCanaryTask(id: string, canaryId?: string, canaryTask?: CanaryTask, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateCanaryTask(id, canaryId, canaryTask, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get or update a single CanaryTask
     * @param {string} id A UUID string identifying this canary task env vars.
     * @param {CanaryTaskEnvVars} [canaryTaskEnvVars] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateCanaryTaskEnvVars(id: string, canaryTaskEnvVars?: CanaryTaskEnvVars, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateCanaryTaskEnvVars(id, canaryTaskEnvVars, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint to manipulate CanaryTemplateLibrary
     * @param {string} id A UUID string identifying this Canary Template Library.
     * @param {string} [organizationId] organization_id
     * @param {CanaryTemplateLibrary} [canaryTemplateLibrary] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateCanaryTemplateLibrary(id: string, organizationId?: string, canaryTemplateLibrary?: CanaryTemplateLibrary, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateCanaryTemplateLibrary(id, organizationId, canaryTemplateLibrary, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Expose clusters to users  - important because it shares a security boundary with users - we must allow users to read all platform wide clusters (e.g. ours), but not change anything
     * @param {string} id A UUID string identifying this Kubernetes Cluster.
     * @param {string} [organizationId] organization_id
     * @param {KubernetesCluster} [kubernetesCluster] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateKubernetesCluster(id: string, organizationId?: string, kubernetesCluster?: KubernetesCluster, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateKubernetesCluster(id, organizationId, kubernetesCluster, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this Notification.
     * @param {string} [alarmId] alarm_id
     * @param {Notification} [notification] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateNotification(id: string, alarmId?: string, notification?: Notification, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateNotification(id, alarmId, notification, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this Notification Channel Setting.
     * @param {string} [organizationId] organization_id
     * @param {NotificationChannelSetting} [notificationChannelSetting] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateNotificationChannelSetting(id: string, organizationId?: string, notificationChannelSetting?: NotificationChannelSetting, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateNotificationChannelSetting(id, organizationId, notificationChannelSetting, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint to manipulate CustomCanaryRestfulLibSource
     * @param {string} id A UUID string identifying this OpenAPI Restful Library.
     * @param {string} [organizationId] organization_id
     * @param {OpenAPIRestfulLibrary} [openAPIRestfulLibrary] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateOpenAPIRestfulLibrary(id: string, organizationId?: string, openAPIRestfulLibrary?: OpenAPIRestfulLibrary, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateOpenAPIRestfulLibrary(id, organizationId, openAPIRestfulLibrary, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this Organization.
     * @param {Organization} [organization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateOrganization(id: string, organization?: Organization, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateOrganization(id, organization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} prefix 
     * @param {string} [organizationId] organization_id
     * @param {OrganizationAPIKeyUpdate} [organizationAPIKeyUpdate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateOrganizationAPIKey(prefix: string, organizationId?: string, organizationAPIKeyUpdate?: OrganizationAPIKeyUpdate, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateOrganizationAPIKey(prefix, organizationId, organizationAPIKeyUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * View to manipulate ServiceComplianceColors for an organization
     * @param {string} id A UUID string identifying this Service Compliance Color.
     * @param {string} [organizationId] organization_id
     * @param {ServiceComplianceColors} [serviceComplianceColors] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateServiceComplianceColors(id: string, organizationId?: string, serviceComplianceColors?: ServiceComplianceColors, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateServiceComplianceColors(id, organizationId, serviceComplianceColors, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * View to manipulate ServiceHealthColors for an organization
     * @param {string} id A UUID string identifying this Service Health Color.
     * @param {string} [organizationId] organization_id
     * @param {ServiceHealthColors} [serviceHealthColors] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateServiceHealthColors(id: string, organizationId?: string, serviceHealthColors?: ServiceHealthColors, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateServiceHealthColors(id, organizationId, serviceHealthColors, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * View to get ServiceHealthEvaluationSettingsGroup for an organization
     * @param {string} id A UUID string identifying this Service Health Evaluation Settings Group.
     * @param {string} [organizationId] organization_id
     * @param {ServiceHealthEvaluationSettingsGroup} [serviceHealthEvaluationSettingsGroup] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateServiceHealthEvaluationSettingsGroup(id: string, organizationId?: string, serviceHealthEvaluationSettingsGroup?: ServiceHealthEvaluationSettingsGroup, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateServiceHealthEvaluationSettingsGroup(id, organizationId, serviceHealthEvaluationSettingsGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this Simple Alarm.
     * @param {string} [metricId] metric_id
     * @param {string} [canaryId] canary_id
     * @param {string} [taskId] task_id
     * @param {SimpleAlarm} [simpleAlarm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateSimpleAlarm(id: string, metricId?: string, canaryId?: string, taskId?: string, simpleAlarm?: SimpleAlarm, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateSimpleAlarm(id, metricId, canaryId, taskId, simpleAlarm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this Snowflake Setting.
     * @param {string} [organizationId] organization_id
     * @param {Snowflake} [snowflake] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateSnowflake(id: string, organizationId?: string, snowflake?: Snowflake, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateSnowflake(id, organizationId, snowflake, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this Snowflake Forecast Model.
     * @param {string} [organizationId] organization_id
     * @param {SnowflakeForecastModel} [snowflakeForecastModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateSnowflakeForecastModel(id: string, organizationId?: string, snowflakeForecastModel?: SnowflakeForecastModel, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateSnowflakeForecastModel(id, organizationId, snowflakeForecastModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint to manipulate StoreCanary
     * @param {string} id A UUID string identifying this Store Canary.
     * @param {string} [cloud] cloud
     * @param {string} [component] component
     * @param {string} [status] status
     * @param {string} [isPrivate] is_private
     * @param {string} [isPinnedCanary] is_pinned_canary
     * @param {string} [uploadedUser] uploaded_user
     * @param {string} [company] company
     * @param {string} [search] A search term.
     * @param {StoreCanaryWrite} [storeCanaryWrite] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateStoreCanary(id: string, cloud?: string, component?: string, status?: string, isPrivate?: string, isPinnedCanary?: string, uploadedUser?: string, company?: string, search?: string, storeCanaryWrite?: StoreCanaryWrite, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateStoreCanary(id, cloud, component, status, isPrivate, isPinnedCanary, uploadedUser, company, search, storeCanaryWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this Subscription Cancel Request.
     * @param {string} [subscriptionId] subscription_id
     * @param {string} [subscriptionUserId] subscription_user_id
     * @param {SubscriptionCancelRequest} [subscriptionCancelRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateSubscriptionCancelRequest(id: string, subscriptionId?: string, subscriptionUserId?: string, subscriptionCancelRequest?: SubscriptionCancelRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateSubscriptionCancelRequest(id, subscriptionId, subscriptionUserId, subscriptionCancelRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint to manipulate TemplateCanary
     * @param {string} id A UUID string identifying this Template Canary.
     * @param {string} [canaryTemplateLibraryId] canary_template_library_id
     * @param {string} [providerId] provider_id
     * @param {string} [serviceId] service_id
     * @param {TemplateCanaryWritable} [templateCanaryWritable] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateTemplateCanary(id: string, canaryTemplateLibraryId?: string, providerId?: string, serviceId?: string, templateCanaryWritable?: TemplateCanaryWritable, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateTemplateCanary(id, canaryTemplateLibraryId, providerId, serviceId, templateCanaryWritable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint to manipulate TemplateTask
     * @param {string} id A UUID string identifying this Template Task.
     * @param {string} [canaryTemplateLibraryId] canary_template_library_id
     * @param {TemplateTaskReadOnly} [templateTaskReadOnly] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateTemplateTask(id: string, canaryTemplateLibraryId?: string, templateTaskReadOnly?: TemplateTaskReadOnly, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateTemplateTask(id, canaryTemplateLibraryId, templateTaskReadOnly, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint that allows authenticated clients to view or edit users
     * @param {string} id A UUID string identifying this user.
     * @param {User} [user] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateUser(id: string, user?: User, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateUser(id, user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateUserPartialAuthViewSet(body?: any, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateUserPartialAuthViewSet(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this User Subscribed Plan.
     * @param {string} [userId] user_id
     * @param {string} [platformActive] platform_active
     * @param {UserSubscribedPlan} [userSubscribedPlan] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateUserSubscribedPlan(id: string, userId?: string, platformActive?: string, userSubscribedPlan?: UserSubscribedPlan, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateUserSubscribedPlan(id, userId, platformActive, userSubscribedPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userProfileAuthViewSet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userProfileAuthViewSet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public validateResetTokenAuthViewSet(body?: any, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).validateResetTokenAuthViewSet(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public verifyEmailAuthViewSet(body?: any, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).verifyEmailAuthViewSet(body, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const DestroyCanaryStatusEnum = {
    Running: 'RUNNING',
    Stopped: 'STOPPED',
    Error: 'ERROR',
    New: 'NEW'
} as const;
export type DestroyCanaryStatusEnum = typeof DestroyCanaryStatusEnum[keyof typeof DestroyCanaryStatusEnum];
/**
 * @export
 */
export const DestroyCanaryOrderingEnum = {
    Name: 'name',
    Name2: '-name',
    TemplateCanaryName: 'template_canary__name',
    TemplateCanaryName2: '-template_canary__name',
    Status: 'status',
    Status2: '-status',
    RegionName: 'region__name',
    RegionName2: '-region__name',
    ClusterName: 'cluster__name',
    ClusterName2: '-cluster__name',
    TemplateCanaryProviderName: 'template_canary__provider__name',
    TemplateCanaryProviderName2: '-template_canary__provider__name',
    TemplateCanaryServiceName: 'template_canary__service__name',
    TemplateCanaryServiceName2: '-template_canary__service__name'
} as const;
export type DestroyCanaryOrderingEnum = typeof DestroyCanaryOrderingEnum[keyof typeof DestroyCanaryOrderingEnum];
/**
 * @export
 */
export const ListCanariesStatusEnum = {
    Running: 'RUNNING',
    Stopped: 'STOPPED',
    Error: 'ERROR',
    New: 'NEW'
} as const;
export type ListCanariesStatusEnum = typeof ListCanariesStatusEnum[keyof typeof ListCanariesStatusEnum];
/**
 * @export
 */
export const ListCanariesOrderingEnum = {
    Name: 'name',
    Name2: '-name',
    TemplateCanaryName: 'template_canary__name',
    TemplateCanaryName2: '-template_canary__name',
    Status: 'status',
    Status2: '-status',
    RegionName: 'region__name',
    RegionName2: '-region__name',
    ClusterName: 'cluster__name',
    ClusterName2: '-cluster__name',
    TemplateCanaryProviderName: 'template_canary__provider__name',
    TemplateCanaryProviderName2: '-template_canary__provider__name',
    TemplateCanaryServiceName: 'template_canary__service__name',
    TemplateCanaryServiceName2: '-template_canary__service__name'
} as const;
export type ListCanariesOrderingEnum = typeof ListCanariesOrderingEnum[keyof typeof ListCanariesOrderingEnum];
/**
 * @export
 */
export const ListPublicAPIServiceComplianceSummariesPeriodCategoryEnum = {
    Yearly: 'yearly',
    Quarterly: 'quarterly',
    Monthly: 'monthly',
    Weekly: 'weekly'
} as const;
export type ListPublicAPIServiceComplianceSummariesPeriodCategoryEnum = typeof ListPublicAPIServiceComplianceSummariesPeriodCategoryEnum[keyof typeof ListPublicAPIServiceComplianceSummariesPeriodCategoryEnum];
/**
 * @export
 */
export const ListServiceComplianceSummariesPeriodCategoryEnum = {
    Yearly: 'yearly',
    Quarterly: 'quarterly',
    Monthly: 'monthly',
    Weekly: 'weekly'
} as const;
export type ListServiceComplianceSummariesPeriodCategoryEnum = typeof ListServiceComplianceSummariesPeriodCategoryEnum[keyof typeof ListServiceComplianceSummariesPeriodCategoryEnum];
/**
 * @export
 */
export const ListServiceHealthIncidentsIncidentStatusEnum = {
    Ok: 'OK',
    Started: 'STARTED',
    ResolvedByUser: 'RESOLVED_BY_USER',
    Resolved: 'RESOLVED'
} as const;
export type ListServiceHealthIncidentsIncidentStatusEnum = typeof ListServiceHealthIncidentsIncidentStatusEnum[keyof typeof ListServiceHealthIncidentsIncidentStatusEnum];
/**
 * @export
 */
export const ListServiceHealthIncidentsOrderingEnum = {
    Service: 'service',
    Service2: '-service',
    SettingsGroup: 'settings_group',
    SettingsGroup2: '-settings_group',
    Start: 'start',
    Start2: '-start',
    Stop: 'stop',
    Stop2: '-stop',
    Downtime: 'downtime',
    Downtime2: '-downtime',
    IncidentStatus: 'incident_status',
    IncidentStatus2: '-incident_status',
    Impact: 'impact',
    Impact2: '-impact',
    Urgency: 'urgency',
    Urgency2: '-urgency'
} as const;
export type ListServiceHealthIncidentsOrderingEnum = typeof ListServiceHealthIncidentsOrderingEnum[keyof typeof ListServiceHealthIncidentsOrderingEnum];
/**
 * @export
 */
export const PartialUpdateCanaryStatusEnum = {
    Running: 'RUNNING',
    Stopped: 'STOPPED',
    Error: 'ERROR',
    New: 'NEW'
} as const;
export type PartialUpdateCanaryStatusEnum = typeof PartialUpdateCanaryStatusEnum[keyof typeof PartialUpdateCanaryStatusEnum];
/**
 * @export
 */
export const PartialUpdateCanaryOrderingEnum = {
    Name: 'name',
    Name2: '-name',
    TemplateCanaryName: 'template_canary__name',
    TemplateCanaryName2: '-template_canary__name',
    Status: 'status',
    Status2: '-status',
    RegionName: 'region__name',
    RegionName2: '-region__name',
    ClusterName: 'cluster__name',
    ClusterName2: '-cluster__name',
    TemplateCanaryProviderName: 'template_canary__provider__name',
    TemplateCanaryProviderName2: '-template_canary__provider__name',
    TemplateCanaryServiceName: 'template_canary__service__name',
    TemplateCanaryServiceName2: '-template_canary__service__name'
} as const;
export type PartialUpdateCanaryOrderingEnum = typeof PartialUpdateCanaryOrderingEnum[keyof typeof PartialUpdateCanaryOrderingEnum];
/**
 * @export
 */
export const RetrieveCanaryStatusEnum = {
    Running: 'RUNNING',
    Stopped: 'STOPPED',
    Error: 'ERROR',
    New: 'NEW'
} as const;
export type RetrieveCanaryStatusEnum = typeof RetrieveCanaryStatusEnum[keyof typeof RetrieveCanaryStatusEnum];
/**
 * @export
 */
export const RetrieveCanaryOrderingEnum = {
    Name: 'name',
    Name2: '-name',
    TemplateCanaryName: 'template_canary__name',
    TemplateCanaryName2: '-template_canary__name',
    Status: 'status',
    Status2: '-status',
    RegionName: 'region__name',
    RegionName2: '-region__name',
    ClusterName: 'cluster__name',
    ClusterName2: '-cluster__name',
    TemplateCanaryProviderName: 'template_canary__provider__name',
    TemplateCanaryProviderName2: '-template_canary__provider__name',
    TemplateCanaryServiceName: 'template_canary__service__name',
    TemplateCanaryServiceName2: '-template_canary__service__name'
} as const;
export type RetrieveCanaryOrderingEnum = typeof RetrieveCanaryOrderingEnum[keyof typeof RetrieveCanaryOrderingEnum];
/**
 * @export
 */
export const RetrieveServiceComplianceSummaryPeriodCategoryEnum = {
    Yearly: 'yearly',
    Quarterly: 'quarterly',
    Monthly: 'monthly',
    Weekly: 'weekly'
} as const;
export type RetrieveServiceComplianceSummaryPeriodCategoryEnum = typeof RetrieveServiceComplianceSummaryPeriodCategoryEnum[keyof typeof RetrieveServiceComplianceSummaryPeriodCategoryEnum];
/**
 * @export
 */
export const RetrieveServiceHealthIncidentIncidentStatusEnum = {
    Ok: 'OK',
    Started: 'STARTED',
    ResolvedByUser: 'RESOLVED_BY_USER',
    Resolved: 'RESOLVED'
} as const;
export type RetrieveServiceHealthIncidentIncidentStatusEnum = typeof RetrieveServiceHealthIncidentIncidentStatusEnum[keyof typeof RetrieveServiceHealthIncidentIncidentStatusEnum];
/**
 * @export
 */
export const RetrieveServiceHealthIncidentOrderingEnum = {
    Service: 'service',
    Service2: '-service',
    SettingsGroup: 'settings_group',
    SettingsGroup2: '-settings_group',
    Start: 'start',
    Start2: '-start',
    Stop: 'stop',
    Stop2: '-stop',
    Downtime: 'downtime',
    Downtime2: '-downtime',
    IncidentStatus: 'incident_status',
    IncidentStatus2: '-incident_status',
    Impact: 'impact',
    Impact2: '-impact',
    Urgency: 'urgency',
    Urgency2: '-urgency'
} as const;
export type RetrieveServiceHealthIncidentOrderingEnum = typeof RetrieveServiceHealthIncidentOrderingEnum[keyof typeof RetrieveServiceHealthIncidentOrderingEnum];
/**
 * @export
 */
export const UpdateCanaryStatusEnum = {
    Running: 'RUNNING',
    Stopped: 'STOPPED',
    Error: 'ERROR',
    New: 'NEW'
} as const;
export type UpdateCanaryStatusEnum = typeof UpdateCanaryStatusEnum[keyof typeof UpdateCanaryStatusEnum];
/**
 * @export
 */
export const UpdateCanaryOrderingEnum = {
    Name: 'name',
    Name2: '-name',
    TemplateCanaryName: 'template_canary__name',
    TemplateCanaryName2: '-template_canary__name',
    Status: 'status',
    Status2: '-status',
    RegionName: 'region__name',
    RegionName2: '-region__name',
    ClusterName: 'cluster__name',
    ClusterName2: '-cluster__name',
    TemplateCanaryProviderName: 'template_canary__provider__name',
    TemplateCanaryProviderName2: '-template_canary__provider__name',
    TemplateCanaryServiceName: 'template_canary__service__name',
    TemplateCanaryServiceName2: '-template_canary__service__name'
} as const;
export type UpdateCanaryOrderingEnum = typeof UpdateCanaryOrderingEnum[keyof typeof UpdateCanaryOrderingEnum];


