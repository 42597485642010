import RoutesService from 'services/routes'

type NAV_ROUTES_LABELS = 'Canaries' | 'Health' | 'Compliance' | 'Forecast'

type NAVROUTESTYPE = {
  id: number
  label: NAV_ROUTES_LABELS
  to: string
}[]
export const NAV_ROUTES: NAVROUTESTYPE = [
  {
    id: 0,
    label: 'Canaries',
    to: RoutesService.dashboardCanaries()
  },
  {
    id: 1,
    label: 'Health',
    to: RoutesService.dashboardServiceHealth()
  },
  {
    id: 2,
    label: 'Compliance',
    to: RoutesService.dashboardCompliance()
  },
  {
    id: 3,
    label: 'Forecast',
    to: RoutesService.dashboardServicesForecast()
  }
]

type DROPDOWN_ROUTES_LABELS =
  | 'My Account'
  | 'My Organizations'
  | 'Profile'
  | 'Billing'
  | 'Plan'
  | 'My Canary Templates'
  | 'My Service Settings'
  | 'My Schemas'
  | 'My Forecast Models'
  | 'My Wallet'
  | 'My Clusters'
  | 'My Shared Canaries'
  | 'My API Keys'
  | 'Logout'

export type DROPDOWNROUTESTYPE = {
  id: number
  label: DROPDOWN_ROUTES_LABELS
  to?: string
  subMenu?: DROPDOWNROUTESTYPE
}[]

export const DROPDOWN_ROUTES: DROPDOWNROUTESTYPE = [
  {
    id: 0,
    label: 'My Account',
    subMenu: [
      {
        id: 0,
        label: 'Profile',
        to: RoutesService.userProfile()
      },
      {
        id: 1,
        label: 'Billing',
        to: RoutesService.userBillingDetails()
      },
      {
        id: 3,
        label: 'Plan',
        to: RoutesService.userPlansDetails()
      }
    ]
  },
  {
    id: 1,
    label: 'My Organizations',
    to: RoutesService.userOrganizationsList()
  },
  {
    id: 2,
    label: 'My Canary Templates',
    to: RoutesService.templateLibraryList()
  },
  {
    id: 3,
    label: 'My Shared Canaries',
    to: RoutesService.sharedTemplateLibraryList()
  },
  {
    id: 4,
    label: 'My Service Settings',
    to: RoutesService.userSettingGroupsList()
  },
  {
    id: 5,
    label: 'My Schemas',
    to: RoutesService.restfulLibList()
  },
  {
    id: 6,
    label: 'My Forecast Models',
    to: RoutesService.userForecastModels()
  },
  {
    id: 7,
    label: 'My Wallet',
    to: RoutesService.userWallet()
  },
  {
    id: 8,
    label: 'My Clusters',
    to: RoutesService.userClusters()
  },
  {
    id: 9,
    label: 'My API Keys',
    to: RoutesService.APIKeysList()
  },
  {
    id: 10,
    label: 'Logout',
    to: RoutesService.userLogout()
  }
]
