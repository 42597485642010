import DialogContentText from '@mui/material/DialogContentText'
import DialogModal from 'components/Modals/DialogModal'
import Button from './Button'

interface InActiveModalProps {
  open: boolean
  activeAction: () => void
  cancelAction: () => void
}

export default function InActiveModal(props: InActiveModalProps) {
  const { open, activeAction, cancelAction } = props
  const handleOnClose = () => {
    cancelAction()
  }
  return (
    <DialogModal
      open={open}
      onClose={handleOnClose}
      dialogTitle="Inactive Warning"
      dialogContent={
        <DialogContentText id="inactive-dialog-description">
          Your session is about to expire, do you want to stay logged in?
        </DialogContentText>
      }
      dialogActions={
        <>
          <Button label="Yes" action={activeAction} />
          <Button label="No" action={cancelAction} />
        </>
      }
    />
  )
}
