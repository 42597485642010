import useDocumentTitle from 'hooks/useDocumentTitle'
import SwiperCore from 'swiper'
import 'swiper/css/bundle'
import 'swiper/css/navigation'
import { Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useContext, useRef } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import CanariesGrid from 'components/CanariesGrid/CanariesGrid'
import ServiceHealthCard from 'components/CanaryServiceHealth/ServiceHealthCard'
import SettingsModal from 'components/CanaryServiceHealth/SettingsModal'
import DashboardsCommonHeader from 'components/Dashboards/DashboardsCommonHeader'
import CanariesGridLoader from 'components/common/Loaders/CanariesGridLoader'
import HealthAndComplianceDashboardsLoader from 'components/common/Loaders/HealthAndComplianceDashboardsLoader'
import PageFilterServiceProvider from 'components/common/PageFilterServiceProvider'
import { ServiceHealthContext } from 'contexts/ServiceHealthContext'
import { ServiceHealth } from 'lib/CloudCanariesRestfulAPI'
import { DashboardContainer } from 'styles/components/CanaryDashboard/DashboardContainer.styled'

export default function ServiceHealthDashboard() {
  useDocumentTitle('Service Health Dashboard')

  const {
    areServiceHealthsLoading,
    filteredServiceHealths,
    areCanariesLoading,
    visibleCanaries,
    selectedServiceName
  } = useContext(ServiceHealthContext)

  SwiperCore.use([Navigation])
  const PrevRef = useRef<any>(null)
  const NextRef = useRef<any>(null)

  return (
    <DashboardContainer>
      {areServiceHealthsLoading ? (
        <HealthAndComplianceDashboardsLoader />
      ) : (
        <>
          <DashboardsCommonHeader pageName="Service Health" />
          <Grid
            container
            spacing={2}
            style={{
              marginBottom: '1.5rem',
              justifyContent: 'space-between'
            }}
          >
            <Grid item xs={12} md={2}>
              <PageFilterServiceProvider />
            </Grid>
            <Grid item xs={12} md={8} />
            <Grid
              item
              xs={12}
              md={2}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <SettingsModal />
            </Grid>
          </Grid>

          {filteredServiceHealths.length <= 0 ? (
            <Typography variant="h3" align="center">
              No Service selected or found...
            </Typography>
          ) : (
            <div
              style={{
                position: 'relative'
              }}
            >
              <div className=" swiper-button-prev " ref={PrevRef}></div>
              <div className=" swiper-button-next " ref={NextRef}></div>
              <Swiper
                navigation={{
                  prevEl: PrevRef.current ? PrevRef.current : undefined,
                  nextEl: NextRef.current ? NextRef.current : undefined
                }}
                onBeforeInit={(swiper) => {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  // eslint-disable-next-line no-param-reassign
                  swiper.params.navigation.prevEl = PrevRef.current
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  // eslint-disable-next-line no-param-reassign
                  swiper.params.navigation.nextEl = NextRef.current
                  swiper.navigation.update()
                }}
                slidesPerView={3}
                spaceBetween={10}
                breakpoints={{
                  640: {
                    slidesPerView: 1
                  },
                  850: {
                    slidesPerView: 2
                  },
                  1240: {
                    slidesPerView: 3
                  },
                  1650: {
                    slidesPerView: 4
                  }
                }}
              >
                {filteredServiceHealths.map((serviceHealth: ServiceHealth) => {
                  return (
                    <SwiperSlide key={serviceHealth.id}>
                      <ServiceHealthCard serviceHealthSummary={serviceHealth} />
                    </SwiperSlide>
                  )
                })}
              </Swiper>
            </div>
          )}
        </>
      )}

      {areCanariesLoading ? (
        <CanariesGridLoader />
      ) : (
        <CanariesGrid
          canaries={visibleCanaries}
          serviceName={selectedServiceName}
        />
      )}
    </DashboardContainer>
  )
}
