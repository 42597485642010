import { setSelectedTabName } from 'client_side_state/slices/canaryAlarmPage'
import { useAppDispatch } from 'client_side_state/store'
import { SyntheticEvent, useContext } from 'react'
import Container from '@mui/material/Container'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import Box from '@mui/system/Box'
import DateTimeFilter from 'components/CanaryManage/DateTimeFilter'
import Icon, { IconType } from 'components/Icons'
import MetricAlarmManager from 'components/MetricAlarmManager'
import PerTaskAlarmEditorPanelLoader from 'components/common/Loaders/PerTaskAlarmEditorPanelLoader'
import { CanaryMetricsContext } from 'contexts/CanaryMetricsContext'
import { SimpleAlarmAlarmTypeEnum } from 'lib/CloudCanariesRestfulAPI'
import { splitByCapitalLetters } from 'services/utils'
import { ButtonContainer } from 'styles/pages/CanaryManage.styled'
import { MainTabsNames } from './TabsNames'

export default function PerTaskAlarmEditorPanel() {
  const dispatch = useAppDispatch()

  const {
    selectedTaskId,
    taskMeta,
    selectedAlarmId,
    areMetricAlarmsLoading,
    metricAlarms,
    selectedAlarmCategory,
    setSelectedAlarmCategory,
    setSelectedAlarmID
  } = useContext(CanaryMetricsContext)

  function hasNotificationsForSelectedCategory(tab: MainTabsNames): boolean {
    if (
      metricAlarms.find((item) =>
        splitByCapitalLetters(item.alarm_type!).includes(tab.toLowerCase())
      )
    ) {
      let selectedAlarm = metricAlarms.filter((item) =>
        splitByCapitalLetters(item.alarm_type!).includes(tab.toLowerCase())
      )[0]
      if (tab === MainTabsNames.RESPONSE) {
        selectedAlarm = metricAlarms.filter((item) =>
          item.alarm_type?.includes(
            SimpleAlarmAlarmTypeEnum.CanaryTaskResponseValue
          )
        )[0]
      }
      if (selectedAlarm.notifications_count! > 0) {
        return true
      }
    }
    return false
  }

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setSelectedAlarmCategory(newValue)
    const selectedCategory = Object.values(MainTabsNames).filter(
      (tabName) => tabName === newValue
    )[0]

    dispatch(setSelectedTabName(selectedCategory))
    if (
      metricAlarms.find((item) =>
        splitByCapitalLetters(item.alarm_type!).includes(
          selectedCategory.toLowerCase()
        )
      )
    ) {
      let selectedAlarmId = metricAlarms.filter((item) =>
        splitByCapitalLetters(item.alarm_type!).includes(
          selectedCategory.toLowerCase()
        )
      )[0].id
      if (selectedCategory === MainTabsNames.RESPONSE) {
        selectedAlarmId = metricAlarms.filter((item) =>
          item.alarm_type?.includes(
            SimpleAlarmAlarmTypeEnum.CanaryTaskResponseValue
          )
        )[0].id
      }
      setSelectedAlarmID(selectedAlarmId!)
    }
  }

  return (
    <Container maxWidth="xl">
      {areMetricAlarmsLoading ? (
        <PerTaskAlarmEditorPanelLoader />
      ) : (
        selectedTaskId &&
        taskMeta.id && (
          <Box sx={{ width: '100%', paddingTop: 2 }}>
            <Typography variant="h4">Notifications</Typography>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: 10
              }}
            >
              <Typography variant="h6">Alarm Categories</Typography>
              <ButtonContainer>
                <DateTimeFilter />
              </ButtonContainer>
            </div>
            <Tabs
              value={selectedAlarmCategory}
              onChange={handleChange}
              aria-label="Alarm Categories"
            >
              {Object.values(MainTabsNames).map((tab) => {
                return (
                  <Tab
                    key={tab}
                    icon={
                      hasNotificationsForSelectedCategory(tab) ? (
                        <Icon name={IconType.Notifications} />
                      ) : undefined
                    }
                    iconPosition="end"
                    label={tab}
                    value={tab}
                    sx={{
                      minHeight: 48,
                      fontSize: 18,
                      textTransform: 'capitalize'
                    }}
                  />
                )
              })}
            </Tabs>
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: 400,
                mt: 1,
                ml: 2,
                color: '#1976d2'
              }}
            >
              {
                metricAlarms.find((metric) => metric.id === selectedAlarmId)
                  ?.name
              }
            </Typography>
            <MetricAlarmManager alarmId={selectedAlarmId} />
          </Box>
        )
      )}
    </Container>
  )
}
