import { selectUserProfile } from 'client_side_state/slices/user'
import { useAppSelector } from 'client_side_state/store'
import usePrevious from 'hooks/usePrevious'
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { IChangeEvent } from '@rjsf/core'
import { RJSFSchema } from '@rjsf/utils'
import { isEqual } from 'lodash'
import { queryParamPageSizeJumbo } from 'constants/AppConfig'
import {
  ReportLevel,
  canaryArtifactConfigs
} from 'constants/formconfig/ArtifactConfigs'
import {
  InitialFormData,
  PDFFileData,
  ReportData,
  initialFormData,
  initialPDFFileData
} from 'interfaces/PDFFileData'
import {
  ServiceComplianceSummary,
  ServiceHealth
} from 'lib/CloudCanariesRestfulAPI'
import ArtifactReportsPeriodAndDatesService, {
  PeriodType,
  getMonthAbbreviation
} from 'services/ArtifactReportsPeriodAndDatesService'
import { APIServiceContext } from './APIServiceContext'
import { OrganizationsContext } from './OrganizationsContext'

interface ArtifactsContextProviderProps {
  children: ReactNode
}

interface ArtifactsContextProps {
  PDFFileDataLoading: boolean
  serviceSummaryEvaluations: ServiceComplianceSummary[]
  viewPDFModalOpen: boolean
  toggleViewPDFModalOpen: () => void
  selectedFormSchema: RJSFSchema
  artifactFormData: any
  handleFormSubmit: (
    data: IChangeEvent<any, RJSFSchema, any>,
    event: React.FormEvent<any>
  ) => void
  handleFormChange: (
    event: IChangeEvent<any, RJSFSchema, any>,
    id?: string | undefined
  ) => void
  pdfFileData: PDFFileData
  artifactsDialogOpen: boolean
  toggleArtifactsDialogOpen: () => void
}
export const ArtifactsContext = createContext<ArtifactsContextProps>(
  null as any
)

export default function ArtifactsContextProvider(
  props: ArtifactsContextProviderProps
) {
  const { children } = props

  const userData = useAppSelector(selectUserProfile)

  const { apiService } = useContext(APIServiceContext)
  const { organization } = useContext(OrganizationsContext)

  const [artifactFormData, setArtifactFormData] =
    useState<InitialFormData>(initialFormData)
  const [pdfFileData, setPdfFileData] =
    useState<PDFFileData>(initialPDFFileData)
  const [PDFFileDataLoading, setPDFFileDataLoading] = useState<boolean>(true)
  const [artifactsDialogOpen, setArtifactsDialogOpen] = useState<boolean>(false)

  const [serviceSummaryEvaluations, setServiceSummaryEvaluations] = useState<
    ServiceComplianceSummary[]
  >([])
  const [serviceHealths, setServiceHealths] = useState<ServiceHealth[]>([])

  const [viewPDFModalOpen, setViewPDFModalOpen] = useState<boolean>(false)

  const [selectedFormSchema, setSelectedFormSchema] = useState<RJSFSchema>(
    canaryArtifactConfigs.schema
  )

  const artifactReportsPeriodAndDatesService = useMemo(() => {
    return new ArtifactReportsPeriodAndDatesService(
      artifactFormData.period_selection as PeriodType,
      artifactFormData.period
    )
  }, [artifactFormData.period, artifactFormData.period_selection])

  useEffect(() => {
    setArtifactFormData(initialFormData)
    return () => {
      setArtifactFormData(initialFormData)
    }
  }, [])

  // const previousPDFFileData = usePrevious(pdfFileData)
  const previousArtifactFormData = usePrevious(artifactFormData)

  useEffect(() => {
    if (
      artifactsDialogOpen &&
      previousArtifactFormData &&
      !isEqual(artifactFormData, previousArtifactFormData)
    ) {
      const periodSelected =
        artifactReportsPeriodAndDatesService.getPeriodBasedOnPeriodType()
      const schema: RJSFSchema = {
        ...canaryArtifactConfigs.schema,
        type: 'object',
        properties: {
          ...canaryArtifactConfigs.schema.properties,
          period: {
            oneOf: [{ type: 'string' }, { type: 'number' }],
            title: 'Period',
            enum: periodSelected
          }
        }
      }
      setSelectedFormSchema(schema)
      if ('period_selection' in previousArtifactFormData) {
        if (
          artifactFormData.period_selection !==
          previousArtifactFormData.period_selection
        )
          setArtifactFormData((prev) => ({
            ...prev,
            period:
              artifactReportsPeriodAndDatesService.getPeriodDataBasedOnPeriodType()
          }))
      }
    }
  }, [
    artifactFormData,
    previousArtifactFormData,
    artifactsDialogOpen,
    artifactReportsPeriodAndDatesService
  ])

  // useEffect(() => {
  //   if (viewPDFModalOpen && serviceSummaryEvaluations.length > 0) {
  //     if (previousPDFFileData && !isEqual(pdfFileData, previousPDFFileData)) {
  //       let newPDFFileData = { ...pdfFileData }
  //       newPDFFileData.services = serviceSummaryEvaluations.map((service) => {
  //         return {
  //           serviceName: service.service_name!,
  //           period: artifactFormData.period,
  //           errors: [],
  //           incidents: [],
  //           meantimeBetweenErrors: service.downtime_seconds_calculated!,
  //           meantimeBetweenIncidents:
  //             service.mean_time_between_system_incidents_seconds_calculated!
  //         }
  //       })
  //       setPdfFileData(newPDFFileData)
  //     }
  //   }
  // }, [
  //   viewPDFModalOpen
  // ])

  async function getArtifactReportData() {
    setPDFFileDataLoading(true)
    const page = undefined

    const { start, end } =
      artifactReportsPeriodAndDatesService.getStartAndEndDateOfSelectedPeriod()
    const [serviceData, healthData] = await Promise.all([
      apiService.listServiceComplianceSummaries(
        page,
        queryParamPageSizeJumbo,
        organization?.id,
        artifactReportsPeriodAndDatesService.getMappedPeriodCategoryFromSelectedType(),
        start!.toISOString().slice(0, 10),
        end!.toISOString().slice(0, 10)
      ),
      apiService.listServiceHealths(
        page,
        queryParamPageSizeJumbo,
        organization?.id
      )
    ])

    setServiceSummaryEvaluations(serviceData.data.results ?? [])
    setServiceHealths(healthData.data.results ?? [])
    setPDFFileDataLoading(false)
    return true
  }

  const handleFormSubmit = async (
    data: IChangeEvent<any, RJSFSchema, any>,
    event: React.FormEvent<any>
  ) => {
    let newValues = JSON.parse(JSON.stringify(data.formData))
    setArtifactFormData(newValues)

    await getArtifactReportData()

    toggleViewPDFModalOpen()
  }

  useEffect(() => {
    if (
      !PDFFileDataLoading &&
      serviceHealths.length > 0 &&
      serviceSummaryEvaluations.length > 0
    ) {
      const { start, end } =
        artifactReportsPeriodAndDatesService.getStartAndEndDateOfSelectedPeriod()

      let data: ReportData = undefined
      const totalSLATarget = parseFloat(
        (
          serviceSummaryEvaluations.reduce(
            (sum, sla) => sum + sla.sla_target_goal!,
            0
          ) / serviceSummaryEvaluations.length
        ).toFixed(2)
      )
      const totalSLA = parseFloat(
        (
          serviceSummaryEvaluations.reduce(
            (sum, sla) => sum + sla.sla_seconds_percentage!,
            0
          ) / serviceSummaryEvaluations.length
        ).toFixed(2)
      )
      switch (artifactFormData.report_level) {
        case 0:
          data = {
            totalCanaries: serviceHealths.reduce(
              (sum, obj) => sum + obj.canaries_total!,
              0
            ),
            totalErrors: serviceHealths.reduce(
              (sum, obj) => sum + obj.canaries_erroring_count!,
              0
            ),
            totalIncidents: serviceSummaryEvaluations.reduce(
              (sum, obj) => sum + obj.total_incidents!,
              0
            ),
            totalDowntime: serviceSummaryEvaluations.reduce(
              (sum, obj) => sum + obj.downtime_seconds_calculated!,
              0
            ),
            totalSLA: totalSLA,
            totalCompliant: totalSLA >= totalSLATarget ? true : false,
            services: serviceHealths.map((serviceHealth) => {
              const relatedServiceComplianceObj =
                serviceSummaryEvaluations.find(
                  (obj) => obj.service === serviceHealth.service_id
                )!
              const dateObj: Date = new Date(
                relatedServiceComplianceObj.service_start_date!
              )

              const day: string = String(dateObj.getDate()).padStart(2, '0')
              const month: string = getMonthAbbreviation(dateObj.getMonth())
              const year: string = String(dateObj.getFullYear()).slice(-2)

              const formattedDate: string = `${day}-${month}-${year}`

              return {
                serviceName: serviceHealth.service_name!,
                provider: serviceHealth.provider_name!,
                canaries: serviceHealth.canaries_total!,
                errors: serviceHealth.canaries_erroring_count!,
                meantimeBetweenErrors:
                  relatedServiceComplianceObj.mean_time_recovery_system_seconds_calculated!,
                incidents: relatedServiceComplianceObj.total_incidents!,
                meantimeBetweenIncidents:
                  relatedServiceComplianceObj.mean_time_between_system_incidents_seconds_calculated!,
                downtime:
                  relatedServiceComplianceObj.downtime_seconds_calculated!,
                sla: relatedServiceComplianceObj.sla_seconds_percentage! || 0,
                compliant:
                  relatedServiceComplianceObj.sla_seconds_percentage! >=
                  relatedServiceComplianceObj.sla_target_goal!
                    ? true
                    : false,
                startDate: formattedDate
              }
            })
          }
          break
        case 1:
          break
        case 2:
          // data = [{
          //   serviceName: 'one',
          //   totalCanaries: serviceHealths.reduce(
          //     (sum, obj) => sum + obj.canaries_total!,
          //     0
          //   ),
          //   totalErrors: serviceHealths.reduce(
          //     (sum, obj) => sum + obj.canaries_erroring_count!,
          //     0
          //   ),
          //   totalIncidents: serviceSummaryEvaluations.reduce(
          //     (sum, obj) => sum + obj.total_incidents!,
          //     0
          //   ),
          //   totalDowntime: serviceSummaryEvaluations.reduce(
          //     (sum, obj) => sum + obj.downtime_seconds_calculated!,
          //     0
          //   ),
          //   totalSLA: 99.9,
          //   totalCompliant: false,
          //   totalDays: 400,
          //   canaries:  serviceHealths.map((serviceHealth) => {
          //     return {

          //     }
          //   })
          // }]
          break
      }
      setPdfFileData((prev) => ({
        ...prev,
        formData: artifactFormData,
        companyName: userData.company_name!,
        organizationName: organization?.name!,
        reporterEmail: userData.email!,
        reporterName: `${userData.first_name} ${userData.last_name}`,
        period: artifactFormData.period,
        reportStartDate: start!.toLocaleString().split(',')[0],
        reportEndDate: end!.toLocaleString().split(',')[0],
        periodEnded:
          artifactReportsPeriodAndDatesService.checkIfSelectedPeriodEnded(),
        remainingDays:
          artifactReportsPeriodAndDatesService.getRemainingDaysInPeriod(),
        servicesByOrganization:
          artifactFormData.report_level === 0 ? data : undefined,
        canariesByOrganization:
          artifactFormData.report_level === 1 ? data : undefined,
        canariesByServices:
          artifactFormData.report_level === 2 ? data : undefined
      }))
    }
  }, [
    PDFFileDataLoading,
    artifactFormData,
    artifactReportsPeriodAndDatesService,
    organization?.name,
    serviceHealths,
    serviceSummaryEvaluations,
    userData.company_name,
    userData.email,
    userData.first_name,
    userData.last_name
  ])

  const handleFormChange = (
    event: IChangeEvent<any, RJSFSchema, any>,
    id?: string | undefined
  ) => {
    let newValues = JSON.parse(JSON.stringify(event.formData))
    if (newValues.report_level !== pdfFileData.formData.report_level) {
      newValues.report_name = ReportLevel[newValues.report_level]
    }
    setArtifactFormData(newValues)
    setPdfFileData((prev) => ({
      ...prev,
      formData: newValues,
      companyName: userData.company_name!,
      organizationName: organization?.name!,
      reporterEmail: userData.email!,
      reporterName: `${userData.first_name} ${userData.last_name}`,
      period: newValues.period
    }))
  }

  const toggleArtifactsDialogOpen = () => {
    setArtifactsDialogOpen((prev) => !prev)
  }

  const toggleViewPDFModalOpen = () => {
    setViewPDFModalOpen((prev) => !prev)
  }

  return (
    <ArtifactsContext.Provider
      value={{
        PDFFileDataLoading,
        serviceSummaryEvaluations,
        viewPDFModalOpen,
        toggleViewPDFModalOpen,
        selectedFormSchema,
        artifactFormData,
        handleFormSubmit,
        handleFormChange,
        pdfFileData,
        artifactsDialogOpen,
        toggleArtifactsDialogOpen
      }}
    >
      {children}
    </ArtifactsContext.Provider>
  )
}
