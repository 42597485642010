import { Theme } from '@mui/material/styles'
import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles(({ spacing }: Theme) =>
  createStyles({
    paperWidthSm: {
      '& .MuiDialog-paperWidthSm': {
        maxWidth: 750
      }
    }
  })
)
