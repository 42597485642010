import SwaggerUI from 'swagger-ui-react'
import 'swagger-ui-react/swagger-ui.css'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import { RJSFSchema } from '@rjsf/utils'
import DialogModal from 'components/Modals/DialogModal'
import BackButton from 'components/common/BackButton'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { GenericIDMatchProps } from 'interfaces/GenericIDMatchProps'

const useStyles = makeStyles({
  pageHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginRight: '1rem',
    marginTop: '0.5rem',
    position: 'absolute',
    right: '1rem',
    top: '3.5rem'
  }
})

export default function CustomRestfulSDKSchemaViewer(
  props: GenericIDMatchProps
) {
  const {
    match: {
      params: { id: schemaId }
    }
  } = props

  const classes = useStyles()

  const history = useHistory()
  const { apiService } = useContext(APIServiceContext)

  const [schema, setSchema] = useState<RJSFSchema | undefined>({} as RJSFSchema)
  const [schemaRetrieved, setSchemaRetrieved] = useState<boolean>(false)

  const getschema = useCallback(async () => {
    if (schemaId) {
      await apiService.retrieveOpenAPIRestfulLibrary(schemaId).then((json) => {
        const data = json.data.processed_schema
        setSchema(data)
        setSchemaRetrieved(true)
      })
    }
  }, [apiService, schemaId])

  useEffect(() => {
    getschema()
  }, [getschema])

  return schemaId && schemaRetrieved ? (
    <DialogModal
      fullScreen
      open={schemaRetrieved}
      onClose={() => history.goBack()}
    >
      <div
        style={{
          padding: '0 12px 16px 0',
          position: 'relative'
        }}
      >
        <div className={classes.pageHeader}>
          <BackButton />
        </div>
        <SwaggerUI spec={schema} />
      </div>
    </DialogModal>
  ) : null
}
