export default function AsOfDateTimeSpan() {
  /* TODO: dynamic update here */

  /*
    const [today, setDate] = useState(stop)

    useEffect(() => {
    const timer = setInterval(() => {
      const now
      setDate(new Date().toLocaleDateString())
    }, 3600 * 1000)
    return () => {
      clearInterval(timer)
    }
  }, [])

   */
  const AsOfDate = new Date().toLocaleDateString()
  const AsOfTime = new Date().toLocaleTimeString()
  return (
    <span>
      As of {AsOfDate}, {AsOfTime}
    </span>
  )
}
