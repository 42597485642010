import useDocumentTitle from 'hooks/useDocumentTitle'
import { useContext } from 'react'
import { toast } from 'react-toastify'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import { AdminPagesTitles } from 'components/MyAdminPages/Constants'
import MyAdminPagesCommonHeader from 'components/MyAdminPages/MyAdminPagesCommonHeader'
import NoDataFound from 'components/MyAdminPages/NoDataFound'
import WalletCreateModal from 'components/SnowFlakes/WalletCreateModal'
import WalletDeleteModal from 'components/SnowFlakes/WalletDeleteModal'
import WalletViewModal from 'components/SnowFlakes/WalletViewModal'
import Toast from 'components/Toast'
import PagesSkeletonLoader from 'components/common/Loaders/PagesSkeletonLoader'
import { rowsPerPageOptions } from 'constants/AppConfig'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { OrganizationsContext } from 'contexts/OrganizationsContext'
import { WalletContext } from 'contexts/WalletContext'
import adminPagesSharedStyles from 'pages/AdminPages/adminPagesSharedStyles'

export default function WalletList() {
  const classes = adminPagesSharedStyles()
  useDocumentTitle('Wallet')

  const { apiService } = useContext(APIServiceContext)
  const { organizationId } = useContext(OrganizationsContext)

  const {
    areWalletsLoading,
    wallets,
    rowCount,
    rowsPerPage,
    onRowsPerPageChange,
    handleChangePage,
    page,
    getWallets
  } = useContext(WalletContext)

  const deleteWalletSetting = async (schemaId: string, currentName: string) => {
    await apiService
      .destroySnowflake(schemaId, organizationId)
      .then(async (response) => {
        if (response.status === 204) {
          toast.success(
            <Toast
              title="Success"
              text={`Snowflake setting "${currentName}" is deleted successfully!`}
            />,
            {
              position: 'top-right'
            }
          )
        } else {
          toast.error(
            <Toast
              title="Error"
              text="Something went wrong, please try again!"
            />,
            {
              position: 'top-right'
            }
          )
        }
        await getWallets()
      })
  }

  return (
    <Container maxWidth="xl" className={classes.mainContainer}>
      <MyAdminPagesCommonHeader />
      <h1 className={classes.headerRow}>
        {AdminPagesTitles.Wallet}
        <WalletCreateModal />
      </h1>
      {areWalletsLoading ? (
        <PagesSkeletonLoader />
      ) : (
        <>
          <TableContainer component={Paper} elevation={3}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>View</TableCell>
                  <TableCell>Organization</TableCell>
                  <TableCell>Account</TableCell>
                  <TableCell>Related K8S Secret ID</TableCell>
                  <TableCell className={classes.firstCell}>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {wallets.length > 0 ? (
                  wallets.map((wallet) => {
                    return (
                      <TableRow key={wallet.id}>
                        <TableCell>
                          <WalletViewModal wallet={wallet} />
                        </TableCell>
                        <TableCell>{wallet.organization_name}</TableCell>
                        <TableCell>Snowflake</TableCell>
                        <TableCell>{wallet.related_k8s_secret_id}</TableCell>
                        <TableCell className={classes.firstCell}>
                          <WalletDeleteModal
                            currentName={wallet.related_k8s_secret_id!}
                            afterSubmit={() =>
                              deleteWalletSetting(
                                wallet.id!,
                                wallet.related_k8s_secret_id!
                              )
                            }
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <NoDataFound pageName="Wallet" colSpan={6} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={rowCount}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </>
      )}
    </Container>
  )
}
