import { Templates } from '@rjsf/mui'
import { BaseInputTemplateProps } from '@rjsf/utils'

const { BaseInputTemplate } = Templates

export default function CustomBaseInputTemplate(props: BaseInputTemplateProps) {
  const customProps = {
    label: '',
    placeholder: props.label
  }
  if (BaseInputTemplate) {
    return <BaseInputTemplate {...props} {...customProps} />
  } else {
    return <></>
  }
}
