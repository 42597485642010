import {
  setOrganizationRequiredModalMessage,
  setOrganizationRequiredModalOpen
} from 'client_side_state/slices/app'
import { useAppDispatch } from 'client_side_state/store'
import { useContext, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { IChangeEvent } from '@rjsf/core'
import MuiForm from '@rjsf/mui'
import { ErrorSchema, ErrorSchemaBuilder, RJSFSchema } from '@rjsf/utils'
import validator from '@rjsf/validator-ajv8'
import { AxiosError, isAxiosError } from 'axios'
import DialogModal from 'components/Modals/DialogModal'
import OrganizationRequiredDialog, {
  OrganizationRequiredMessage
} from 'components/UserOrganization/OrganizationRequiredDialog'
import CreateButton from 'components/common/CreateButton'
import FormsCreateButton from 'components/common/FormsCreateButton'
import { configCreateWallet } from 'constants/formconfig/WalletCreate'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { OrganizationsContext } from 'contexts/OrganizationsContext'
import { WalletContext } from 'contexts/WalletContext'

const schema = configCreateWallet.schema
const uiSchema = configCreateWallet.uiSchema

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  muiForm: {
    '& label.MuiFormLabel-root': {
      fontSize: '1.2rem'
    },
    '& span.MuiFormControlLabel-label ': {
      fontSize: '1.2rem'
    },
    '& input[type="file" i]': {
      fontSize: '1.2rem'
    }
  }
})

export default function WalletCreateModal() {
  const classes = useStyles()

  const dispatch = useAppDispatch()

  const { apiService } = useContext(APIServiceContext)
  const { organizationId, organization } = useContext(OrganizationsContext)

  const { getWallets } = useContext(WalletContext)
  const [open, setOpen] = useState(false)
  const [extraErrors, setExtraErrors] = useState<ErrorSchema>({})

  const handleClickOpen = () => {
    if (!organizationId) {
      dispatch(
        setOrganizationRequiredModalMessage(
          OrganizationRequiredMessage.WalletSettings
        )
      )
      dispatch(setOrganizationRequiredModalOpen(true))
    } else {
      setOpen(true)
    }
  }

  const handleClose = () => {
    setExtraErrors({})
    setOpen(false)
  }

  const onSubmit = async (
    data: IChangeEvent<any, RJSFSchema, any>,
    event: React.FormEvent<any>
  ) => {
    let requestBody = JSON.parse(JSON.stringify(data.formData))
    requestBody.organization = organizationId
    try {
      await apiService.createSnowflake(requestBody).then(async (json) => {
        if (json.status === 201) {
          handleClose()
          await getWallets()
        }
      })
    } catch (e: any) {
      if (isAxiosError(e)) {
        const err = e as AxiosError
        if (err.response?.status === 400) {
          const errors = err.response?.data as {}
          const builder = new ErrorSchemaBuilder()
          Object.entries(errors).forEach(([key, value]) => {
            builder.addErrors(value as string, key)
          })
          setExtraErrors(builder.ErrorSchema)
        }
      }
    }
  }
  return (
    <div>
      <CreateButton text="Create" onClick={handleClickOpen} />
      {organization && organization.id ? (
        <DialogModal
          open={open}
          onClose={handleClose}
          dialogTitle="Create Wallet Settings"
          dialogContent={
            <MuiForm
              schema={schema}
              onSubmit={onSubmit}
              uiSchema={uiSchema}
              extraErrors={extraErrors}
              className={classes.muiForm}
              validator={validator}
            >
              <FormsCreateButton>Create</FormsCreateButton>
            </MuiForm>
          }
        />
      ) : (
        <OrganizationRequiredDialog />
      )}
    </div>
  )
}
