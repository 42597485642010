import Skeleton from '@mui/material/Skeleton'
import Box from '@mui/system/Box'
import SharedStyles from './SharedStyles'

export default function CanaryManageHeaderLoader() {
  const classes = SharedStyles()
  return (
    <Box
      className={classes.loadingSkeletons}
      style={{ width: '100%', padding: '2rem', paddingTop: '1rem' }}
    >
      <Box className={classes.skeletonsRow}>
        <Skeleton
          variant="rectangular"
          width="13%"
          height={45}
          className={classes.skeleton}
        />
        <>
          <Skeleton
            variant="rectangular"
            width="35%"
            height={45}
            className={classes.skeleton}
          />
        </>
        <>
          <Skeleton
            variant="rectangular"
            width="2%"
            height={45}
            className={classes.skeleton}
          />
        </>
      </Box>
      <Box className={classes.skeletonsRow} sx={{ px: '12rem' }}>
        <Skeleton
          variant="rectangular"
          width="5%"
          height={45}
          className={classes.skeleton}
          sx={{ pr: '3rem' }}
        />
        <Skeleton
          variant="rectangular"
          width="40%"
          height={45}
          className={classes.skeleton}
        />
        <Skeleton
          variant="rectangular"
          width="15%"
          height={45}
          className={classes.skeleton}
        />
      </Box>
      <Box className={classes.skeletonsRow} sx={{ px: '8rem' }}>
        <Skeleton
          variant="rectangular"
          width="48%"
          height={450}
          className={classes.skeleton}
        />
        <Skeleton
          variant="rectangular"
          width="48%"
          height={450}
          className={classes.skeleton}
        />
      </Box>
      {Array.from([1, 2, 3, 4]).map((element) => (
        <Skeleton
          key={element}
          variant="rectangular"
          width="100%"
          height={60}
          className={classes.skeleton}
        />
      ))}
    </Box>
  )
}
