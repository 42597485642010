import { CanaryDailyForcastManageChart, CanaryForecastDaily } from '.'
import { ChangeEvent } from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import { uniqueId } from 'lodash'
import PageLoader from 'components/common/PageLoader'
import { CanaryTaskReturnDataTypeEnum } from 'lib/CloudCanariesRestfulAPI'

interface CanaryForecastDataTableProps {
  isCanaryDailyForecastsDataLoading: boolean
  isCanaryDailyForecastDataLoading: boolean
  canaryDailyForecastData: CanaryDailyForcastManageChart
  canaryDailyDetailedForecastsData: CanaryForecastDaily[]
  page: number
  rowCount: number
  rowsPerPage: number
  rowsPerPageOptions: number[]
  handleChangePage: (event: any, newPage: number) => void
  onRowsPerPageChange: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void
}

export default function CanaryForecastDataTable(
  props: CanaryForecastDataTableProps
) {
  const {
    canaryDailyForecastData,
    isCanaryDailyForecastsDataLoading,
    isCanaryDailyForecastDataLoading,
    canaryDailyDetailedForecastsData,
    page,
    rowCount,
    handleChangePage,
    onRowsPerPageChange,
    rowsPerPage,
    rowsPerPageOptions
  } = props

  if (isCanaryDailyForecastsDataLoading || isCanaryDailyForecastDataLoading) {
    return <PageLoader isTableLoader={false} />
  }

  return (
    <div>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Timestamp</TableCell>
              <TableCell align="center">Raw Response Value</TableCell>
              <TableCell align="center">Chance of Incident</TableCell>
              <TableCell align="center">Response Latency (ms)</TableCell>
              <TableCell align="center">Latency Health</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isCanaryDailyForecastsDataLoading ||
            isCanaryDailyForecastDataLoading ? (
              <TableRow>
                <TableCell style={{ position: 'relative' }}>
                  <PageLoader isTableLoader />
                </TableCell>
              </TableRow>
            ) : (
              <>
                {canaryDailyForecastData.day_of_week!.map(
                  (dayOfWeak, index) => {
                    return (
                      <TableRow key={dayOfWeak}>
                        <TableCell align="center">{`${dayOfWeak}, ${
                          canaryDailyForecastData.for_date![index]
                        }`}</TableCell>
                        <TableCell align="center" />
                        <TableCell align="center">
                          {
                            canaryDailyForecastData
                              .percentage_chance_of_incident![index]
                          }
                          %
                        </TableCell>
                        <TableCell align="center" />
                        <TableCell align="center">
                          {
                            canaryDailyForecastData.latency_issues_predicted![
                              index
                            ]
                          }
                          %
                        </TableCell>
                      </TableRow>
                    )
                  }
                )}
                <TableRow>
                  <TableCell align="center" />
                  <TableCell align="center" />
                  <TableCell align="center" />
                  <TableCell align="center" />
                  <TableCell align="center" />
                </TableRow>
                {canaryDailyDetailedForecastsData.map(
                  (canaryDailyDetailedForecastData) => {
                    let forecastedValue:
                      | string
                      | number
                      | boolean
                      | undefined
                      | null =
                      canaryDailyDetailedForecastData.forecast_normalized_value ??
                      canaryDailyDetailedForecastData.forecasted_response_value
                    if (
                      canaryDailyDetailedForecastData.canary_return_data_type ===
                      CanaryTaskReturnDataTypeEnum.String
                    ) {
                      if (typeof forecastedValue === 'string') {
                        if (parseInt(forecastedValue!, 10) === 0) {
                          forecastedValue = `Not ${canaryDailyDetailedForecastData.abstract_metric_condition_boundary_value}`
                        } else if (parseInt(forecastedValue!, 10) === 1) {
                          forecastedValue =
                            canaryDailyDetailedForecastData.abstract_metric_condition_boundary_value
                        }
                      }
                    } else if (
                      canaryDailyDetailedForecastData.canary_return_data_type ===
                      CanaryTaskReturnDataTypeEnum.Json
                    ) {
                      if (typeof forecastedValue === 'string') {
                        if (parseInt(forecastedValue!, 10) === 0) {
                          forecastedValue = `Not ${canaryDailyDetailedForecastData.abstract_metric_condition_boundary_value}`
                        } else if (parseInt(forecastedValue!, 10) === 1) {
                          forecastedValue =
                            canaryDailyDetailedForecastData.abstract_metric_condition_boundary_value
                        }
                      }
                    } else if (
                      canaryDailyDetailedForecastData.canary_return_data_type ===
                      CanaryTaskReturnDataTypeEnum.Decimal
                    ) {
                      if (forecastedValue !== 'No Forecast Value') {
                        if (typeof forecastedValue === 'string') {
                          forecastedValue = Number(
                            parseFloat(forecastedValue!).toFixed(2)
                          )
                        }
                      }
                    } else {
                      forecastedValue = forecastedValue?.toString()
                    }
                    return (
                      <TableRow
                        key={uniqueId(canaryDailyDetailedForecastData.id)}
                      >
                        <TableCell align="center">
                          {canaryDailyDetailedForecastData.for_timestamp ||
                            canaryDailyDetailedForecastData.for_date}
                        </TableCell>
                        <TableCell align="center">{forecastedValue}</TableCell>
                        <TableCell align="center" />
                        <TableCell align="center">
                          {canaryDailyDetailedForecastData.forecasted_response_latency ||
                            canaryDailyDetailedForecastData.response_latency}
                        </TableCell>
                        <TableCell align="center" />
                      </TableRow>
                    )
                  }
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={rowCount}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </div>
  )
}
