import {
  selectDateFrom,
  selectDateTo,
  selectTimeFrom,
  selectTimeTo
} from 'client_side_state/slices/pageFilter'
import { useAppSelector } from 'client_side_state/store'
import { useCallback, useContext, useEffect, useState } from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TabPanel from 'components/common/MUITabPanel'
import { queryParamPageSizeDefault } from 'constants/AppConfig'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { CanaryTaskMetricData } from 'lib/CloudCanariesRestfulAPI'
import DateTimeService from 'services/DateTimeService'

const datetimeService: DateTimeService = new DateTimeService()

interface PerAlarmAlarmMetricDataTabPanelProps {
  alarmId: string
  metricId: string | null
  selectedTab: number
  tabIndex: number
}

const rowsPerPage = queryParamPageSizeDefault
const rowsPerPageOptions = [rowsPerPage]

export default function PerAlarmAlarmMetricDataTabPanel({
  alarmId,
  metricId,
  selectedTab,
  tabIndex
}: PerAlarmAlarmMetricDataTabPanelProps) {
  const { apiService } = useContext(APIServiceContext)
  const isSelected = selectedTab === tabIndex

  const [page, setPage] = useState<number>(0)
  const [rowCount, setRowCount] = useState<number>(0)
  const [metricData, setMetricData] = useState<CanaryTaskMetricData[]>([])

  const pageDateFromFilter = useAppSelector(selectDateFrom)
  const pageDateToFilter = useAppSelector(selectDateTo)
  const pageTimeFromFilter = useAppSelector(selectTimeFrom)
  const pageTimeToFilter = useAppSelector(selectTimeTo)

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage)
  }

  const getMetricData = useCallback(async () => {
    if (isSelected && metricId) {
      if (metricId) {
        const requestedPage = page + 1
        const start = datetimeService.datetimeformatted(
          pageDateFromFilter,
          pageTimeFromFilter
        )
        const stop = datetimeService.datetimeformatted(
          pageDateToFilter,
          pageTimeToFilter
        )
        const djangoDateTimeStart = datetimeService.djangoDateTimeValue(start)
        const djangoDateTimeStop = datetimeService.djangoDateTimeValue(stop)

        await apiService
          .listCanaryTaskMetricData(
            requestedPage,
            rowsPerPage,
            djangoDateTimeStart,
            djangoDateTimeStop,
            metricId
          )
          .then((json) => {
            setMetricData(json.data.results ?? [])
            setRowCount(json.data.count ?? 0)
          })
      }
    }
  }, [
    isSelected,
    metricId,
    page,
    pageDateFromFilter,
    pageTimeFromFilter,
    pageDateToFilter,
    pageTimeToFilter,
    apiService
  ])

  useEffect(() => {
    getMetricData()
  }, [getMetricData])

  return (
    <TabPanel value={selectedTab} index={tabIndex}>
      <TablePagination
        component="div"
        count={rowCount}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        page={page}
        onPageChange={handleChangePage}
      />
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Timestamp</TableCell>
              <TableCell align="center">Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {metricData.map((metricDatum) => {
              return (
                <TableRow key={metricDatum.measured}>
                  <TableCell align="center">{metricDatum.measured}</TableCell>
                  <TableCell align="center">
                    {metricDatum.normalized_value}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={rowCount}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        page={page}
        onPageChange={handleChangePage}
      />
    </TabPanel>
  )
}
