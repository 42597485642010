import { getQuartersPeriods } from 'services/ArtifactReportsPeriodAndDatesService'
import { FormConfigTypes } from './FormConfigsTypes'

export const PeriodSelection = ['Year', 'Quarter', 'Month', 'Week']

export const ReportLevel = [
  'Services by Organization',
  'Canaries by Organization',
  'Canaries by Services'
]

export const canaryArtifactConfigs: FormConfigTypes = {
  schema: {
    type: 'object',
    required: ['report_name', 'period_selection', 'period', 'report_level'],
    properties: {
      report_name: { type: 'string', title: 'Report Name' },
      period_selection: {
        type: 'string',
        title: 'Type',
        enum: PeriodSelection
      },
      period: {
        type: 'string',
        title: 'Period',
        enum: getQuartersPeriods()
      },
      report_level: {
        type: 'number',
        title: 'Report Type',
        oneOf: [
          { const: 0, title: ReportLevel[0] },
          { const: 1, title: ReportLevel[1] },
          { const: 2, title: ReportLevel[2] }
        ],
        default: 2
      }
    }
  },
  uiSchema: {
    report_level: {
      'ui:widget': 'radio',
      'ui:enumDisabled': [1, 2]
    }
  }
}
