import BillingContextProvider from 'contexts/BillingContext'
import UserBillingDetails from 'pages/AdminPages/UserBillingDetails/UserBillingDetails'

export default function UserBillingDetailsAdminPage() {
  return (
    <BillingContextProvider>
      <UserBillingDetails />
    </BillingContextProvider>
  )
}
