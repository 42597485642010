import {
  setOrganizationRequiredModalMessage,
  setOrganizationRequiredModalOpen
} from 'client_side_state/slices/app'
import { useAppDispatch } from 'client_side_state/store'
import { useContext, useState } from 'react'
import { IChangeEvent } from '@rjsf/core'
import MuiForm from '@rjsf/mui'
import { RJSFSchema } from '@rjsf/utils'
import validator from '@rjsf/validator-ajv8'
import Icon, { IconType } from 'components/Icons'
import DialogModal from 'components/Modals/DialogModal'
import OrganizationRequiredDialog, {
  OrganizationRequiredMessage
} from 'components/UserOrganization/OrganizationRequiredDialog'
import FormsCreateButton from 'components/common/FormsCreateButton'
import { configKubernetesCluster } from 'constants/formconfig/KubernetesCluster'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { ClustersContext } from 'contexts/ClustersContext'
import { OrganizationsContext } from 'contexts/OrganizationsContext'
import { KubernetesCluster } from 'lib/CloudCanariesRestfulAPI'
import { CreateBtn } from 'styles/components/common/Buttons.styled'

const schema = configKubernetesCluster.schema

export default function UserOrganizationClusterCreateModal() {
  const dispatch = useAppDispatch()

  const { apiService } = useContext(APIServiceContext)
  const { organizationId } = useContext(OrganizationsContext)
  const { fetchUserClusters } = useContext(ClustersContext)

  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    if (!organizationId) {
      dispatch(
        setOrganizationRequiredModalMessage(
          OrganizationRequiredMessage.ClusterCreate
        )
      )
      dispatch(setOrganizationRequiredModalOpen(true))
    } else {
      setOpen(true)
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  const onSubmit = async (
    data: IChangeEvent<any, RJSFSchema, any>,
    event: React.FormEvent<any>
  ) => {
    let kubernetesCluster = JSON.parse(
      JSON.stringify(data.formData)
    ) as KubernetesCluster
    kubernetesCluster.organization = organizationId
    try {
      await apiService
        .createKubernetesCluster(kubernetesCluster)
        .then(async (json) => {
          handleClose()
          await fetchUserClusters()
        })
    } catch {
      handleClose()
      console.log('error contacting api')
    }
  }

  return (
    <div>
      <CreateBtn style={{ marginLeft: 8 }} onClick={handleClickOpen}>
        <Icon name={IconType.Add} /> Create
      </CreateBtn>
      {organizationId ? (
        <DialogModal
          open={open}
          onClose={handleClose}
          dialogTitle="Add Cluster Name"
          dialogContent={
            <MuiForm schema={schema} onSubmit={onSubmit} validator={validator}>
              <FormsCreateButton>Create</FormsCreateButton>
            </MuiForm>
          }
        />
      ) : (
        <OrganizationRequiredDialog />
      )}
    </div>
  )
}
