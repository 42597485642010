import { selectUserIsStaff } from 'client_side_state/slices/user'
import { useAppSelector } from 'client_side_state/store'
import {
  ChangeEvent,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { KubernetesCluster } from 'lib/CloudCanariesRestfulAPI'
import { APIServiceContext } from './APIServiceContext'
import { OrganizationsContext } from './OrganizationsContext'

interface ClustersContextProviderProps {
  children: ReactNode
}

interface ClustersContextProps {
  areUserClustersLoading: boolean
  userClusters: KubernetesCluster[]
  isStaff: boolean
  rowsPerPage: number
  page: number
  rowCount: number
  fetchUserClusters: () => Promise<void>
  onRowsPerPageChange: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void
  handleChangePage: (event: any, newPage: number) => void
}
export const ClustersContext = createContext<ClustersContextProps>(null as any)

export default function ClustersContextProvider(
  props: ClustersContextProviderProps
) {
  const { children } = props

  const isStaff = useAppSelector(selectUserIsStaff)

  const { apiService } = useContext(APIServiceContext)
  const { organizationId } = useContext(OrganizationsContext)

  const [page, setPage] = useState<number>(0)
  const [rowCount, setRowCount] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)

  const [areUserClustersLoading, setAreUserClustersLoading] = useState(true)
  const [userClusters, setUserClusters] = useState<KubernetesCluster[]>([])

  useEffect(() => {
    setRowCount(0)
  }, [organizationId])

  const fetchUserClusters = useCallback(async () => {
    setRowCount(0)
    const requestedPage = page + 1

    setAreUserClustersLoading(true)
    await apiService
      .listKubernetesClusters(requestedPage, rowsPerPage, organizationId)
      .then((json) => {
        setUserClusters(json.data.results ?? [])
        setRowCount(json.data.count ?? 0)
        setAreUserClustersLoading(false)
      })
  }, [apiService, organizationId, page, rowsPerPage])

  useEffect(() => {
    fetchUserClusters()
  }, [fetchUserClusters])

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage)
  }

  const onRowsPerPageChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <ClustersContext.Provider
      value={{
        areUserClustersLoading,
        userClusters,
        isStaff,
        page,
        rowCount,
        rowsPerPage,
        fetchUserClusters,
        handleChangePage,
        onRowsPerPageChange
      }}
    >
      {children}
    </ClustersContext.Provider>
  )
}
