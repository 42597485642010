import { Theme } from '@mui/material/styles'
import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles(({ spacing }: Theme) =>
  createStyles({
    paperWidthSm: {
      minWidth: 450,
      overflowX: 'scroll',
      overflowY: 'visible'
    },
    dialogTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontWeight: 700,
      fontSize: '1.25rem',
      paddingRight: '0.25rem',
      borderBottom: '0.25px solid #e0e0e0'
    },
    dialogContent: {
      padding: '1rem 1.5rem'
    },
    yesButton: {
      color: '#ffffff',
      backgroundColor: '#48A863',
      '&:hover': {
        backgroundColor: '#48A863'
      }
    },
    deleteButton: {
      color: '#ffffff',
      backgroundColor: '#d32f2f',
      '&:hover': {
        backgroundColor: '#d32f3f'
      }
    },
    dialogActions: {
      padding: '12px 16px'
    },
    muiForm: {
      '& label.MuiFormLabel-root': {
        fontSize: '1.2rem'
      },
      '& span.MuiFormControlLabel-label ': {
        fontSize: '1.2rem'
      },
      '& input[type="file" i]': {
        fontSize: '1.2rem'
      }
    },
    logo: {
      height: 20,
      width: 20,
      marginRight: '0.5rem'
    }
  })
)
