import PlansDetailsContextProvider from 'contexts/PlansDetailsContext'
import UserPlansDetails from 'pages/AdminPages/UserPlansDetails/UserPlansDetails'

export default function UserPlansDetailsAdminPage() {
  return (
    <PlansDetailsContextProvider>
      <UserPlansDetails />
    </PlansDetailsContextProvider>
  )
}
