import useDocumentTitle from 'hooks/useDocumentTitle'
import { useContext } from 'react'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import ProvidersFilter from 'components/ComplianceHistory/ProviderFilter'
import ServiceFilter from 'components/ComplianceHistory/ServiceFilter'
import { AdminPagesTitles } from 'components/MyAdminPages/Constants'
import MyAdminPagesCommonHeader from 'components/MyAdminPages/MyAdminPagesCommonHeader'
import NoDataFound from 'components/MyAdminPages/NoDataFound'
import PagesSkeletonLoader from 'components/common/Loaders/PagesSkeletonLoader'
import { rowsPerPageOptions } from 'constants/AppConfig'
import { ComplianceHistoryContext } from 'contexts/ComplianceHistoryContext'
import { getCurrentDate, isDateBetween } from 'services/utils'

export default function ComplianceHistory() {
  useDocumentTitle('SLA Results')

  const {
    areComplianceHistoriesLoading,
    filteredComplianceHistories,
    rowCount,
    rowsPerPage,
    onRowsPerPageChange,
    handleChangePage,
    page
  } = useContext(ComplianceHistoryContext)
  const currentDate = getCurrentDate()

  return (
    <Container
      maxWidth="xl"
      sx={{
        top: '3rem',
        position: 'relative'
      }}
    >
      <MyAdminPagesCommonHeader />
      <Typography
        component="h1"
        variant="h3"
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          padding: '10px 0 12px',
          my: '0.5em'
        }}
      >
        {AdminPagesTitles.ComplianceHistory}
      </Typography>
      {areComplianceHistoriesLoading ? (
        <PagesSkeletonLoader />
      ) : (
        <>
          <Grid container spacing={2} justifyContent="flex-end">
            <ProvidersFilter />
            <ServiceFilter />
          </Grid>
          <TableContainer component={Paper} elevation={3}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Provider</TableCell>
                  <TableCell>Service</TableCell>
                  <TableCell>Period</TableCell>
                  <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell>
                  <TableCell>SLA Target</TableCell>
                  <TableCell>SLA Result</TableCell>
                  <TableCell>Metric</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredComplianceHistories.length > 0 ? (
                  filteredComplianceHistories.map((complianceHistory) => {
                    const templateLibraryId = complianceHistory.id!

                    return (
                      <TableRow key={templateLibraryId}>
                        <TableCell>
                          {complianceHistory.provider_name!}
                        </TableCell>
                        <TableCell>{complianceHistory.service_name!}</TableCell>
                        <TableCell>
                          {complianceHistory.period_category!}
                        </TableCell>
                        <TableCell>{complianceHistory.period_from!}</TableCell>
                        <TableCell>
                          {isDateBetween(
                            currentDate,
                            complianceHistory.period_from!,
                            complianceHistory.period_to!
                          )
                            ? undefined
                            : complianceHistory.period_to!}
                        </TableCell>
                        <TableCell>
                          {`${complianceHistory.sla_target_goal}%`}
                        </TableCell>
                        <TableCell>
                          {`${complianceHistory.sla_seconds_percentage}%`}
                        </TableCell>
                        <TableCell>Downtime</TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <NoDataFound pageName="Compliance Histories" colSpan={8} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={rowCount}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </>
      )}
    </Container>
  )
}
