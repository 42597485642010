import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { userLogout } from './auth'
import type { RootState } from '../store'

export interface ServiceComplianceState {
  selectedServiceComplianceId: string
  selectedServiceComplianceName: string
  selectedServiceComplianceSummaryEvaluationId: string
}

const initialState: ServiceComplianceState = {
  selectedServiceComplianceId: '',
  selectedServiceComplianceName: '',
  selectedServiceComplianceSummaryEvaluationId: ''
}

export const serviceComplianceSlice = createSlice({
  name: 'serviceCompliance',
  initialState,
  reducers: {
    setSelectedServiceComplianceID: (state, action: PayloadAction<string>) => {
      state.selectedServiceComplianceId = action.payload
    },
    setSelectedServiceComplianceNAME: (
      state,
      action: PayloadAction<string>
    ) => {
      state.selectedServiceComplianceName = action.payload
    },
    setSelectedServiceComplianceSummaryEvaluationsId: (
      state,
      action: PayloadAction<string>
    ) => {
      state.selectedServiceComplianceSummaryEvaluationId = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(userLogout, (state, action) => {
      state.selectedServiceComplianceId = ''
      state.selectedServiceComplianceName = ''
      state.selectedServiceComplianceSummaryEvaluationId = ''
    })
  }
})

// Action creators are generated for each case reducer function
export const {
  setSelectedServiceComplianceID,
  setSelectedServiceComplianceNAME,
  setSelectedServiceComplianceSummaryEvaluationsId
} = serviceComplianceSlice.actions

export default serviceComplianceSlice.reducer

export const selectSelectedServiceComplianceId = (state: RootState) =>
  state.serviceCompliance.selectedServiceComplianceId

export const selectSelectedServiceComplianceName = (state: RootState) =>
  state.serviceCompliance.selectedServiceComplianceName

export const selectSelectedServiceComplianceSummaryEvaluationId = (
  state: RootState
) => state.serviceCompliance.selectedServiceComplianceSummaryEvaluationId
