import Skeleton from '@mui/material/Skeleton'
import Box from '@mui/system/Box'
import SharedStyles from './SharedStyles'

export default function PerTaskAlarmEditorPanelLoader() {
  const classes = SharedStyles()
  return (
    <Box className={classes.loadingSkeletons}>
      <Skeleton
        variant="rounded"
        width="20%"
        height={44}
        className={classes.skeleton}
        sx={{ mt: 2 }}
      />
      <Box className={classes.skeletonsRow} sx={{ mt: 1 }}>
        <Skeleton
          variant="rounded"
          width="16%"
          height={40}
          className={classes.skeleton}
        />
        <Skeleton
          variant="rounded"
          width="60%"
          height={40}
          className={classes.skeleton}
        />
      </Box>
      <Box className={classes.skeletonsRow}>
        <Skeleton
          variant="rounded"
          width="45%"
          height={40}
          className={classes.skeleton}
        />
      </Box>
      <Box className={classes.skeletonsRow} sx={{ mt: 1 }}>
        <Skeleton
          variant="rounded"
          width="40%"
          height={40}
          className={classes.skeleton}
        />
      </Box>
      <Skeleton
        variant="rounded"
        width="100%"
        height={50}
        className={classes.skeleton}
      />
      <Skeleton
        variant="rounded"
        width="5%"
        height={50}
        className={classes.skeleton}
        sx={{ mt: 2 }}
      />
      <Box
        className={classes.skeletonsRow}
        sx={{ mt: 1, justifyContent: 'center' }}
      >
        <Skeleton
          variant="rounded"
          width="95%"
          height={50}
          className={classes.skeleton}
          sx={{ mt: 1 }}
        />
      </Box>
    </Box>
  )
}
