import Skeleton from '@mui/material/Skeleton'
import SharedStyles from './SharedStyles'

export default function CanariesDashboardLoader() {
  const classes = SharedStyles()
  return (
    <div className={classes.loadingSkeletons}>
      <div className={classes.centerRow}>
        <Skeleton
          variant="text"
          width="15%"
          height={55}
          className={classes.skeleton}
          animation="wave"
        />
      </div>
      <Skeleton
        variant="rectangular"
        width="10%"
        height={30}
        className={classes.skeleton}
      />
      <div className={classes.skeletonsRow}>
        <Skeleton
          variant="rectangular"
          width="35%"
          height={40}
          className={classes.skeleton}
        />
        <Skeleton
          variant="rectangular"
          width="40%"
          height={40}
          className={classes.skeleton}
        />
      </div>
      <Skeleton
        variant="rectangular"
        width="100%"
        height={60}
        className={classes.skeleton}
        classes={{
          root: classes.skeletonRoot
        }}
      />
      {Array.from([1, 2, 3]).map((element) => (
        <Skeleton
          key={element}
          variant="rectangular"
          width="100%"
          height={60}
          className={classes.skeleton}
        />
      ))}
    </div>
  )
}
