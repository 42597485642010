import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 60px;
  padding: 0 20px;
  background-color: aliceblue;
  border-bottom: 2px solid black;
  vertical-align: middle;

  img {
    cursor: pointer;
  }
  h1,
  input {
    margin: 0 15px 0 30px;
    font-size: 16pt;
    color: black;
    font-weight: 400;
    &.error {
      border: 1px solid red;
    }
  }
  .error-message {
    color: #f44336;
    margin: 0;
    font-size: 0.75rem;
    margin-top: 3px;
    text-align: left;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
  }
  h1 {
    padding-left: 4px;
  }
  input {
    border-radius: 4px;
    transition: 0.1s;
    &.empty {
      background-color: rgba(255, 0, 0, 0.15);
    }
  }
  .left-end {
    margin: 0 auto 0 0;
  }
`
