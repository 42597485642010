import { SxProps, Theme } from '@mui/material'

const newModalSharedSxProps: Record<string, SxProps<Theme> | undefined> = {
  mainButton: {
    textTransform: 'capitalize',
    letterSpacing: '0.1em'
  },
  dialog: {
    '& .MuiDialog-paperWidthSm': {
      minWidth: 500,
      minHeight: 600
    }
  },
  dialogTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#dff2ff',
    padding: 10
  },
  dialogTitle: {
    margin: '0 auto'
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  sectionTitle: {
    fontWeight: 400,
    fontSize: 24,
    textAlign: 'center',
    marginBottom: '0.5rem'
  },
  dialogActions: {
    padding: 16
  },
  settingsForm: {
    textAlign: 'right'
  },
  artifactsForm: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1
  },
  saveBtn: {
    '&.MuiButton-outlined': {
      fontWeight: 'bold',
      fontSize: 18,
      lineHeight: 22,
      padding: '4px 10px',
      height: 36,
      textTransform: 'capitalize'
    }
  },
  formActionsButtons: {
    marginTop: '1.25rem',
    '& > :nth-child(2)': {
      marginLeft: '0.5rem'
    }
  },
  artifactsDialog: {
    '& .MuiDialog-paperWidthSm': {
      minWidth: 600,
      minHeight: 600
    }
  },
  whatIfDialog: {
    '& .MuiDialog-paperWidthSm': {
      minWidth: 1000
    }
  },
  tabs: {
    '& .MuiTab-textColorInherit': {
      color: '#000000',
      fontSize: 24,
      fontWeight: 700,
      textTransform: 'capitalize'
    }
  },
  tabIndicator: {
    backgroundColor: '#000000'
  },
  publishButton: {
    color: '#ffffff',
    backgroundColor: '#3d89ff',
    '&:hover': {
      backgroundColor: '#3d89ff'
    }
  }
}

export default newModalSharedSxProps
