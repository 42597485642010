import { RJSFSchema } from '@rjsf/utils'
import { TaskUpdates } from 'interfaces/CanaryEditor'
import {
  AbstractMetricBoundaryCondition,
  SimpleAlarm
} from 'lib/CloudCanariesRestfulAPI'

type SimpleAlarmKeys = keyof SimpleAlarm
type BoundaryConditionKeys = keyof AbstractMetricBoundaryCondition
type ChangeDiff = {
  [p: string]: any
}

const defaultTaskUpdates: TaskUpdates = {
  latency_alarm_boundary_condition_updates: {},
  latency_alarm_meta_updates: {},
  response_value_alarm_boundary_condition_updates: {},
  response_value_alarm_meta_updates: {},
  forecasted_latency_alarm_boundary_condition_updates: {},
  forecasted_latency_alarm_meta_updates: {},
  forecasted_response_value_alarm_boundary_condition_updates: {},
  forecasted_response_value_alarm_meta_updates: {},
  env_vars_updates: {}
}

export enum MetricUpdateTypes {
  LATENCY,
  RESPONSE_VALUE,
  FORECASTED_LATENCY,
  FORECASTED_RESPONSE_VALUE
}

export default class CanaryTaskUpdateService {
  static logMetricUpdate(taskUpdateType: MetricUpdateTypes) {
    if (taskUpdateType === MetricUpdateTypes.LATENCY) {
      // console.log('taskUpdateType', 'LATENCY')
    }
    if (taskUpdateType === MetricUpdateTypes.RESPONSE_VALUE) {
      // console.log('taskUpdateType', 'RESPONSE_VALUE')
    }
  }

  static getCurrentEnvVarsFormData(
    taskId: string,
    envVars: any,
    tasksUpdates: Map<string, TaskUpdates>
  ) {
    let combined = { ...envVars }
    if (tasksUpdates.get(taskId)) {
      combined = { ...combined, ...tasksUpdates.get(taskId)!.env_vars_updates }
    }
    return combined
  }

  static getCurrentMetricFormData(
    taskId: string,
    taskUpdateType: MetricUpdateTypes,
    tasksUpdates: Map<string, TaskUpdates>,
    alarmMeta: SimpleAlarm,
    alarmBoundaryCondition: AbstractMetricBoundaryCondition
  ) {
    let combined = { ...alarmMeta, ...alarmBoundaryCondition }
    combined.name = alarmMeta.name
    this.logMetricUpdate(taskUpdateType)
    if (tasksUpdates.get(taskId)) {
      // console.log('found global updates')
      const taskUpdates = tasksUpdates.get(taskId)!
      // console.log(taskUpdates)

      // Actual Data alarms
      if (taskUpdateType === MetricUpdateTypes.LATENCY) {
        combined = {
          ...combined,
          ...taskUpdates.latency_alarm_meta_updates,
          ...taskUpdates.latency_alarm_boundary_condition_updates
        }
      }
      if (taskUpdateType === MetricUpdateTypes.RESPONSE_VALUE) {
        combined = {
          ...combined,
          ...taskUpdates.response_value_alarm_meta_updates,
          ...taskUpdates.response_value_alarm_boundary_condition_updates
        }
      }

      // Forecast Data alarms
      if (taskUpdateType === MetricUpdateTypes.FORECASTED_LATENCY) {
        // if (combined.enabled) {
        combined = {
          ...combined,
          ...taskUpdates.forecasted_latency_alarm_meta_updates,
          ...taskUpdates.forecasted_latency_alarm_boundary_condition_updates
        }
        // }
      }
      if (taskUpdateType === MetricUpdateTypes.FORECASTED_RESPONSE_VALUE) {
        // if (combined.enabled) {
        combined = {
          ...combined,
          ...taskUpdates.forecasted_response_value_alarm_meta_updates,
          ...taskUpdates.forecasted_response_value_alarm_boundary_condition_updates
        }
        // }
      }
    }
    // console.log('formData', combined)
    return combined
  }

  static mergeEnvVarsUpdate(
    taskId: string,
    schema: RJSFSchema,
    formVariables: any,
    retrieved_data: any,
    tasksUpdates: Map<string, TaskUpdates>
  ) {
    const { properties } = schema
    // console.log('formVariables', formVariables)

    let currentChanges = {} as ChangeDiff
    for (let key in properties) {
      const value = formVariables[key]
      const existingValue = retrieved_data[key]
      // if the value has changed, keep track of that
      if (value !== existingValue) {
        currentChanges[key] = value
      }
    }

    // copy the global TASK[S] updates map so as not to mutate state directly
    let clone = new Map(tasksUpdates)
    // console.log('clone before change', clone)
    // console.log('current changes', currentChanges)

    // update the global TASK[S] changes map with the overall changes

    // start with a default update
    let taskUpdate = Object.assign({}, defaultTaskUpdates)

    // if the task already exists in the map, grab it, so we can update
    if (clone.get(taskId)) {
      // console.log('found existing updates for task')
      taskUpdate = clone.get(taskId)!
    }

    taskUpdate.env_vars_updates = {
      ...taskUpdate.env_vars_updates,
      ...currentChanges
    }

    clone.set(taskId, taskUpdate)

    // console.log('tasksUpdates', tasksUpdates)
    // console.log('taskUpdate', taskUpdate)
    // console.log('clone', clone)

    return clone
  }

  static mergeTaskMetricUpdate(
    taskId: string,
    taskUpdateType: MetricUpdateTypes,
    schema: RJSFSchema,
    formVariables: any,
    alarmMeta: SimpleAlarm,
    alarmBoundaryCondition: AbstractMetricBoundaryCondition,
    tasksUpdates: Map<string, TaskUpdates>
  ) {
    const { properties } = schema
    let currentAlarmMetaChanges = {} as Partial<SimpleAlarm>
    let currentBoundaryConditionChanges =
      {} as Partial<AbstractMetricBoundaryCondition>

    // first diff all properties in schema
    for (let key in properties) {
      const value = formVariables[key]

      // check which schema the keys belong to
      if (alarmBoundaryCondition.hasOwnProperty(key) && key !== 'name') {
        const _key = key as BoundaryConditionKeys
        // if the value has changed, keep track of that
        currentBoundaryConditionChanges[_key] = value
      }

      if (alarmMeta.hasOwnProperty(key)) {
        const _key = key as SimpleAlarmKeys
        currentAlarmMetaChanges[_key] = value
      }
    }

    // copy the global TASK[S] updates map so as not to mutate state directly
    let clone = new Map(tasksUpdates)
    // console.log('clone before change', clone)

    // update the global TASK[S] changes map with the overall changes

    // start with a default update
    let taskUpdate = Object.assign({}, defaultTaskUpdates)

    // if the task already exists in the map, grab it, so we can update
    if (clone.get(taskId)) {
      // console.log('found existing updates for task')
      taskUpdate = clone.get(taskId)!
    }

    // Actual Data alarms
    if (taskUpdateType === MetricUpdateTypes.LATENCY) {
      taskUpdate.latency_alarm_meta_updates = {
        ...taskUpdate.latency_alarm_meta_updates,
        ...currentAlarmMetaChanges
      }
      taskUpdate.latency_alarm_boundary_condition_updates = {
        ...taskUpdate.latency_alarm_boundary_condition_updates,
        ...currentBoundaryConditionChanges
      }
    }

    if (taskUpdateType === MetricUpdateTypes.RESPONSE_VALUE) {
      taskUpdate.response_value_alarm_meta_updates = {
        ...taskUpdate.response_value_alarm_meta_updates,
        ...currentAlarmMetaChanges
      }
      taskUpdate.response_value_alarm_boundary_condition_updates = {
        ...taskUpdate.response_value_alarm_boundary_condition_updates,
        ...currentBoundaryConditionChanges
      }
    }

    // Forecast Data alarms
    if (taskUpdateType === MetricUpdateTypes.FORECASTED_LATENCY) {
      // if (taskUpdate.forecasted_latency_alarm_meta_updates.enabled) {
      taskUpdate.forecasted_latency_alarm_meta_updates = {
        ...taskUpdate.forecasted_latency_alarm_meta_updates,
        ...currentAlarmMetaChanges
      }
      taskUpdate.forecasted_latency_alarm_boundary_condition_updates = {
        ...taskUpdate.forecasted_latency_alarm_boundary_condition_updates,
        ...currentBoundaryConditionChanges
      }
      // }
    }

    if (taskUpdateType === MetricUpdateTypes.FORECASTED_RESPONSE_VALUE) {
      // if (taskUpdate.forecasted_response_value_alarm_meta_updates.enabled) {
      taskUpdate.forecasted_response_value_alarm_meta_updates = {
        ...taskUpdate.forecasted_response_value_alarm_meta_updates,
        ...currentAlarmMetaChanges
      }
      taskUpdate.forecasted_response_value_alarm_boundary_condition_updates = {
        ...taskUpdate.forecasted_response_value_alarm_boundary_condition_updates,
        ...currentBoundaryConditionChanges
      }
      // }
    }

    clone.set(taskId, taskUpdate)

    // console.log('tasksUpdates', tasksUpdates)
    // console.log('taskUpdate', taskUpdate)
    // console.log('clone', clone)

    return clone
  }
}
