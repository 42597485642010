import styled from 'styled-components'

export const DashboardContainer = styled.div`
  padding: 20px 40px;

  .dot {
    // dot indicators for seven-day schedule
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: inline-block;

    &.false {
      background-color: #bbb;
    }
    &.true {
      background-color: #0d0;
    }
  }

  #filters-display {
    // display for active filters
    display: flex;
    margin: 8px 0 12px 0;
    line-height: 18pt;
    align-items: center;

    select {
      margin: 0 5px;
      height: 22px;
      font-size: 11pt;
    }
  }
`
