import CanariesContextProvider from 'contexts/CanariesContext'
import SnowflakeForecastModelsContextProvider from 'contexts/SnowflakeForecastModelsContext'
import WalletContextProvider from 'contexts/WalletContext'
import ForecastModels from 'pages/AdminPages/ForecastModels'

export default function ForecastModelsPage() {
  return (
    <CanariesContextProvider>
      <WalletContextProvider>
        <SnowflakeForecastModelsContextProvider>
          <ForecastModels />
        </SnowflakeForecastModelsContextProvider>
      </WalletContextProvider>
    </CanariesContextProvider>
  )
}
