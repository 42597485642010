import { StyleSheet, Text, View } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 24,
    textAlign: 'center',
    fontFamily: 'Helvetica-Bold',
    fontStyle: 'bold',
    flexGrow: 1,
    marginBottom: 6
  },
  service: {
    width: '16%'
  },
  provider: {
    width: '16%'
  },
  startDate: {
    width: '15%'
  },
  rest: {
    width: '10%'
  }
})

interface ReportHeaderProps {
  reportLevel: string
}

export default function ReportHeader(props: ReportHeaderProps) {
  // const { reportLevel } = props

  return (
    <View style={styles.container}>
      <Text style={styles.service}>Service</Text>
      <Text style={styles.provider}>Provider</Text>
      <Text style={styles.rest}>Canaries</Text>
      <Text style={styles.rest}>Errors</Text>
      <Text style={styles.rest}>MTBE</Text>
      <Text style={styles.rest}>Incidents</Text>
      <Text style={styles.rest}>MTBI</Text>
      <Text style={styles.rest}>Downtime</Text>
      <Text style={styles.rest}>SLA</Text>
      <Text style={styles.rest}>Compliant</Text>
      <Text style={styles.startDate}>Start date</Text>
    </View>
  )
}
