import { history } from 'client_side_state/history'
import { retrieveAuthUserProfile } from 'client_side_state/slices/auth'
import { useAppDispatch } from 'client_side_state/store'
import { useCallback, useEffect } from 'react'
import Routes from 'services/routes'

export default function UserLoggedInInsterstial() {
  const dispatch = useAppDispatch()

  const fetchUserProfile = useCallback(async () => {
    await dispatch(retrieveAuthUserProfile())
    history.push(Routes.authenticatedDefaultRoute())
  }, [dispatch])

  useEffect(() => {
    fetchUserProfile()
  }, [fetchUserProfile])

  return <></>
}
