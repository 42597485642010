import { FormConfigTypes } from './FormConfigsTypes'

export const configChangePassword: FormConfigTypes = {
  schema: {
    type: 'object',
    required: ['email', 'old_password', 'new_password', 'new_password_repeat'],
    properties: {
      email: { type: 'string', title: 'Email' },
      old_password: { type: 'string', title: 'Temp Password', minLength: 8 },
      new_password: { type: 'string', title: 'New Password', minLength: 8 },
      new_password_repeat: {
        type: 'string',
        title: 'Repeat New Password',
        minLength: 8
      }
    }
  },
  uiSchema: {
    old_password: {
      'ui:widget': 'password'
    },
    new_password: {
      'ui:widget': 'password'
    },
    new_password_repeat: {
      'ui:widget': 'password'
    }
  }
}
