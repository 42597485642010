import { FormConfigTypes } from './FormConfigsTypes'

export const configAddCustomSchema: FormConfigTypes = {
  schema: {
    type: 'object',
    required: ['input_file', 'name'],
    properties: {
      name: { type: 'string', title: 'Schema Name' },
      input_file: {
        type: 'string',
        format: 'data-url',
        title: 'OpenAPI JSON file'
      },
      organization_id: { type: 'string' }
    }
  },
  uiSchema: {
    organization_id: { 'ui:widget': 'hidden' },
    input_file: {
      'ui:options': { accept: '.json' },
      'ui:help': 'Please use .JSON files only.'
    }
  }
}
export const configEditCustomSchemaName: FormConfigTypes = {
  schema: {
    type: 'object',
    required: ['name'],
    properties: {
      name: {
        type: 'string',
        title: 'Schema Name'
      }
    }
  }
}
