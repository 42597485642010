import { selectUserProfile } from 'client_side_state/slices/user'
import { useAppSelector } from 'client_side_state/store'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { createStyles, makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    companyName: {
      fontSize: '1.75rem',
      fontWeight: 700,
      letterSpacing: '0.02em'
    }
  })
)

export default function MyAdminPagesCommonHeader() {
  const classes = useStyles()

  const userData = useAppSelector(selectUserProfile)
  return (
    <Grid container className={classes.container}>
      <Typography variant="h1" align="center" className={classes.companyName}>
        {userData?.company_name || 'Company Name'}
      </Typography>
    </Grid>
  )
}
