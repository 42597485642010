import WalletContextProvider from 'contexts/WalletContext'
import WalletList from 'pages/AdminPages/WalletList'

export default function WalletPage() {
  return (
    <WalletContextProvider>
      <WalletList />
    </WalletContextProvider>
  )
}
