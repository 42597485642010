import Grid from '@mui/material/Grid'
import { ObjectFieldTemplateProps } from '@rjsf/utils'

export default function CreateModalObjectFieldTemplate({
  properties
}: ObjectFieldTemplateProps) {
  return (
    <Grid container spacing={2}>
      {properties.map((element, index) => (
        <Grid
          item
          xs={index === 0 ? 12 : 6}
          key={index}
          sx={{
            mb: 1,
            '& .MuiGrid-spacing-xs-2': {
              '&:nth-child(1)': {
                mt: 0
              }
            },
            '& .MuiInputBase-root': {
              color: '#6D6D6D',
              fontSize: '1.25rem',
              fontWeight: 400
            },
            '&:nth-child(6)': {
              width: '100%',
              maxWidth: '100%',
              flexBasis: '100%'
            },
            '&:nth-child(7)': {
              pt: 0,
              width: '100%',
              maxWidth: '100%',
              flexBasis: '100%'
            }
          }}
        >
          {element.content}
        </Grid>
      ))}
    </Grid>
  )
}
