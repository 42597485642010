import SharedCanaryTemplateLibrariesContextProvider from 'contexts/SharedCanaryTemplateLibrariesContext'
import SharedCanaryTemplateLibrariesList from 'pages/AdminPages/SharedCanaryTemplateLibrariesList/SharedCanaryTemplateLibrariesList'

export default function SharedTemplateLibrariesAdminPage() {
  return (
    <SharedCanaryTemplateLibrariesContextProvider>
      <SharedCanaryTemplateLibrariesList />
    </SharedCanaryTemplateLibrariesContextProvider>
  )
}
