import styled from 'styled-components'
import { ReactNode, useRef } from 'react'
import useOutsideAlerter from 'components/common/outsideAlerter'
import BackButton from './BackButton'

interface CustomModalProps {
  showModal: boolean
  setShowModal: (show: boolean) => void
  children: ReactNode
  width?: string
}
export default function CustomModal(props: CustomModalProps) {
  const { showModal, setShowModal, children, width } = props
  const outsideClickRef = useRef(null)
  useOutsideAlerter(outsideClickRef, () => setShowModal(false))

  if (showModal) {
    return (
      <ModalStyles width={width}>
        <div className="Background"></div>
        <div className="Spread">
          <div className="Content" ref={outsideClickRef}>
            <div className="closeBtn">
              <BackButton action={() => setShowModal(false)} />
            </div>
            {children}
          </div>
        </div>
      </ModalStyles>
    )
  } else {
    return <div></div>
  }
}

const ModalStyles = styled.div<{
  width?: string
}>`
  .Background {
    position: fixed;
    left: 0;
    top: 0;
    background-color: #000000;
    opacity: 0.2;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .Spread {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    .Content {
      padding: 50px 60px 50px 50px;
      background-color: white;
      position: relative;
      min-width: ${({ width }) => (width !== undefined ? `${width}` : '70%')};
      .closeBtn {
        position: absolute;
        z-index: 1;
        right: 0;
        top: 10px;
      }
    }
  }
`
