import Grid from '@mui/material/Grid'
import { makeStyles } from '@mui/styles'
import {
  ObjectFieldTemplateProps // descriptionId,
  // getTemplate,
  // getUiOptions,
  // titleId
} from '@rjsf/utils'

const useStyles = makeStyles({
  root: {
    // marginTop: 10
  },
  gridItem: {
    marginBottom: 8,
    '&:nth-child(5)': {
      paddingTop: 0
    },
    '& .MuiGrid-spacing-xs-2': {
      '&:nth-child(1)': {
        marginTop: 0
      }
    }
  }
})

export default function ObjectFieldTemplate({
  description,
  title,
  properties,
  required,
  schema,
  uiSchema,
  idSchema,
  registry
}: ObjectFieldTemplateProps) {
  const classes = useStyles()
  // const uiOptions = getUiOptions(uiSchema)
  // const TitleFieldTemplate = getTemplate(
  //   'TitleFieldTemplate',
  //   registry,
  //   uiOptions
  // )
  // const DescriptionFieldTemplate = getTemplate(
  //   'DescriptionFieldTemplate',
  //   registry,
  //   uiOptions
  // )

  return (
    <>
      {/* {(uiSchema!['ui:title'] || title) && (
        <TitleFieldTemplate
          id={'asdfasdfasdf'}
          title={title}
          required={required}
          schema={schema}
          registry={registry}
          uiSchema={uiSchema}
        />
      )}
      {description && (
        <DescriptionFieldTemplate
          id={descriptionId(idSchema)}
          description={description}
          schema={schema}
          uiSchema={uiSchema}
          registry={registry}
        />
      )} */}
      <Grid container spacing={2} className={classes.root}>
        {properties.map((element, index) => (
          <Grid
            item
            xs={index === 0 ? 12 : 6}
            key={index}
            className={classes.gridItem}
          >
            {element.content}
          </Grid>
        ))}
      </Grid>
    </>
  )
}
