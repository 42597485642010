import {
  setOrganizationRequiredModalMessage,
  setOrganizationRequiredModalOpen
} from 'client_side_state/slices/app'
import { useAppDispatch } from 'client_side_state/store'
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import isEqual from 'lodash/isEqual'
import newModalSharedSxProps from 'components/DashboardsModals/newModalSharedSxProps'
import DialogModal from 'components/Modals/DialogModal'
import OrganizationRequiredDialog, {
  OrganizationRequiredMessage
} from 'components/UserOrganization/OrganizationRequiredDialog'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { OrganizationsContext } from 'contexts/OrganizationsContext'
import { ServiceHealthContext } from 'contexts/ServiceHealthContext'
import { ServiceHealthColors } from 'lib/CloudCanariesRestfulAPI'
import {
  splitByCapitalLetters,
  underScoreStringToFirstLetterCapital
} from 'services/utils'
import { ColorPicker, PropertyName } from 'styles/pages/HealthCheck.styled'

type SeverityColors = {
  [key: string]: string
}

type DialColors = {
  [key: string]: string
}

export default function SettingsModal() {
  const dispatch = useAppDispatch()

  const { apiService } = useContext(APIServiceContext)
  const { organizationId, organization } = useContext(OrganizationsContext)
  const {
    serviceHealthColorsSettings,
    serviceHealthColors: serviceHealthColor,
    areServiceHealthColorsSettingsLoading,
    fetchServiceHealthColors: fetchBaseServiceHealthColors
  } = useContext(ServiceHealthContext)

  const serviceHealthColorsSetting = useMemo<ServiceHealthColors>(
    () => serviceHealthColor || serviceHealthColorsSettings[0],
    [serviceHealthColor, serviceHealthColorsSettings]
  )

  const [serviceHealthColors, setServiceHealthColors] =
    useState<ServiceHealthColors>(serviceHealthColorsSetting!)

  const [baseSeverityBarState, setBaseSeverityBarState] =
    useState<SeverityColors>({
      high: serviceHealthColorsSetting!.severity_bar_high_color!,
      medium: serviceHealthColorsSetting!.severity_bar_medium_color!,
      low: serviceHealthColorsSetting!.severity_bar_low_color!,
      none: serviceHealthColorsSetting!.severity_bar_none_color!
    })

  const [baseDialColorsState, setBaseDialColorsState] = useState<DialColors>({
    RunningCanariesNoAlarms:
      serviceHealthColorsSetting!.alert_dial_color_running_canaries_no_alarms!,
    RunningCanariesWithAlarms:
      serviceHealthColorsSetting!
        .alert_dial_color_running_canaries_with_alarms!,
    StoppedCanaries:
      serviceHealthColorsSetting!.alert_dial_color_stopped_canaries!,
    ErroringCanaries:
      serviceHealthColorsSetting!.alert_dial_color_erroring_canaries!,
    NewCanaries: serviceHealthColorsSetting!.alert_dial_color_new_canaries!
  })

  const [severityBarState, setSeverityBarState] =
    useState<SeverityColors>(baseSeverityBarState)
  const [colorsChanged, setColorsChanged] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [dialColorsState, setDialColorsState] =
    useState<DialColors>(baseDialColorsState)

  const fetchServiceHealthColors = useCallback(async () => {
    if (!areServiceHealthColorsSettingsLoading) {
      await apiService
        .retrieveServiceHealthColors(serviceHealthColorsSetting.id!)
        .then((json) => {
          setServiceHealthColors(json.data)
        })
    }
  }, [
    apiService,
    areServiceHealthColorsSettingsLoading,
    serviceHealthColorsSetting.id
  ])

  useEffect(() => {
    fetchServiceHealthColors()
  }, [fetchServiceHealthColors])

  useEffect(() => {
    setServiceHealthColors(serviceHealthColorsSetting!)
  }, [serviceHealthColorsSetting])

  useEffect(() => {
    setBaseSeverityBarState({
      high: serviceHealthColors.severity_bar_high_color!,
      medium: serviceHealthColors.severity_bar_medium_color!,
      low: serviceHealthColors.severity_bar_low_color!,
      none: serviceHealthColors.severity_bar_none_color!
    })
  }, [serviceHealthColors])

  useEffect(() => {
    setBaseDialColorsState({
      RunningCanariesNoAlarms:
        serviceHealthColors.alert_dial_color_running_canaries_no_alarms!,
      RunningCanariesWithAlarms:
        serviceHealthColors.alert_dial_color_running_canaries_with_alarms!,
      StoppedCanaries: serviceHealthColors.alert_dial_color_stopped_canaries!,
      ErroringCanaries: serviceHealthColors.alert_dial_color_erroring_canaries!,
      NewCanaries: serviceHealthColors.alert_dial_color_new_canaries!
    })
  }, [serviceHealthColors])

  const hasUnsavedChanges = useMemo<boolean>(
    () =>
      colorsChanged ||
      !isEqual(severityBarState, baseSeverityBarState) ||
      !isEqual(dialColorsState, baseDialColorsState),
    [
      baseDialColorsState,
      baseSeverityBarState,
      colorsChanged,
      dialColorsState,
      severityBarState
    ]
  )

  const checkUnsavedChanges = (): boolean =>
    !hasUnsavedChanges ||
    window.confirm('You have unsaved changes. Are you sure you want to leave?')

  const toggleDialogOpen = () => {
    if (!organizationId) {
      dispatch(
        setOrganizationRequiredModalMessage(
          OrganizationRequiredMessage.ServiceHealthStatusColors
        )
      )
      dispatch(setOrganizationRequiredModalOpen(true))
    } else {
      if (!checkUnsavedChanges()) return
      setSeverityBarState(baseSeverityBarState)
      setColorsChanged(false)
      setOpen(!open)
    }
  }

  const handleSeverityColorsChange = (
    event: ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    const value = event.target.value
    setSeverityBarState((prev) => ({ ...prev, [key]: value }))
    setColorsChanged(true)
  }

  const handleDialColorsChange = (
    event: ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    const value = event.target.value
    setDialColorsState((prev) => ({ ...prev, [key]: value }))
    setColorsChanged(true)
  }

  const SubmitColorsChange = async () => {
    const updatedServiceHealthColors: ServiceHealthColors = {
      severity_bar_high_color: severityBarState.high,
      severity_bar_medium_color: severityBarState.medium,
      severity_bar_low_color: severityBarState.low,
      severity_bar_none_color: severityBarState.none,
      alert_dial_color_running_canaries_no_alarms:
        dialColorsState.RunningCanariesNoAlarms,
      alert_dial_color_running_canaries_with_alarms:
        dialColorsState.RunningCanariesWithAlarms,
      alert_dial_color_stopped_canaries: dialColorsState.StoppedCanaries,
      alert_dial_color_erroring_canaries: dialColorsState.ErroringCanaries,
      alert_dial_color_new_canaries: dialColorsState.NewCanaries
    }
    await apiService
      .partialUpdateServiceHealthColors(
        serviceHealthColorsSetting.id!,
        undefined,
        updatedServiceHealthColors
      )
      .then((json) => {
        if (json.status === 200) {
          setColorsChanged(false)
          fetchBaseServiceHealthColors()
        }
      })
  }

  return (
    <>
      <Button
        variant="outlined"
        onClick={toggleDialogOpen}
        sx={newModalSharedSxProps.mainButton}
      >
        Status Colors
      </Button>
      {!areServiceHealthColorsSettingsLoading &&
      organization &&
      organization.id ? (
        <DialogModal
          onClose={toggleDialogOpen}
          fullWidth
          maxWidth="md"
          open={open}
          dialogTitle="Service Status Colors"
          dialogContent={
            <>
              <Grid container>
                <Grid item xs={9}>
                  <Typography
                    variant="h6"
                    sx={newModalSharedSxProps.sectionTitle}
                  >
                    Severity Bar
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {Object.keys(severityBarState).map((key) => {
                    const barName = underScoreStringToFirstLetterCapital(key)
                    return (
                      <Grid key={key} container>
                        <Grid
                          item
                          xs={9}
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <ColorPicker
                            type={'color'}
                            value={severityBarState[key]}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              handleSeverityColorsChange(e, key)
                            }
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <PropertyName>{barName}</PropertyName>
                        </Grid>
                      </Grid>
                    )
                  })}
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={9}>
                      <Typography
                        variant="h6"
                        sx={newModalSharedSxProps.sectionTitle}
                      >
                        Alert Dial Colors
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {Object.keys(dialColorsState).map((key) => {
                    const dialName = splitByCapitalLetters(key, true)
                    return (
                      <Grid key={key} container>
                        <Grid
                          item
                          xs={9}
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <ColorPicker
                            type={'color'}
                            value={dialColorsState[key]}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              handleDialColorsChange(e, key)
                            }
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <PropertyName>{dialName}</PropertyName>
                        </Grid>
                      </Grid>
                    )
                  })}
                </Grid>
              </Grid>
            </>
          }
          dialogActions={
            <>
              {hasUnsavedChanges && <span>You have unsaved changes</span>}
              <Button
                disabled={!hasUnsavedChanges}
                onClick={SubmitColorsChange}
                variant="outlined"
                sx={newModalSharedSxProps.saveBtn}
              >
                Save
              </Button>
            </>
          }
        />
      ) : (
        <OrganizationRequiredDialog />
      )}
    </>
  )
}
