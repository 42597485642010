import { ReactNode, createContext, useContext } from 'react'
import { AxiosError, AxiosResponse } from 'axios'
import { AuthContext } from './AuthContext'
import {
  promptTimout,
  userInactivityAllowedMinutes
} from '../constants/AppConfig'
import { Configuration, DefaultApi } from '../lib/CloudCanariesRestfulAPI'

interface APIServiceContextProviderProps {
  children: ReactNode
}

export interface APIServiceContextProps {
  apiService: DefaultApi
  refreshToken: () => Promise<void>
  getTokenRefreshAble: () => string
  logoutUser: () => Promise<boolean>
}
export const APIServiceContext = createContext<APIServiceContextProps>(
  null as any
)

export default function APIServiceContextProvider(
  props: APIServiceContextProviderProps
) {
  const { children } = props
  const {
    getUserToken,
    getTokenExpiration,
    handleLogout,
    handleLoginSuccess,
    handleLoginFail,
    isAuthenticated
  } = useContext(AuthContext)

  const APIConfiguration = new Configuration()
  let apiService = new DefaultApi(APIConfiguration)

  const refreshToken = async () => {
    const currentTokenValue = getUserToken()

    const token = currentTokenValue ? currentTokenValue : ''

    const userToken = { token }

    await apiService
      .refreshAuthTokenAuthViewSet(userToken)
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          handleLoginSuccess(response)
        } else {
          handleLoginFail()
        }
      })
      .catch((error: AxiosError) => {
        handleLoginFail(error)
      })
  }

  const getTokenRefreshAble = () => {
    let refreshedToken = ''
    if (isAuthenticated()) {
      const expiration = getTokenExpiration()
      const nowDate = new Date().toUTCString()
      const now = Date.parse(nowDate)

      const allowedDiff = (userInactivityAllowedMinutes - promptTimout) * 60000
      const diff = expiration - now

      if (expiration === 0) {
        handleLogout()
      } else if (diff <= 0) {
        handleLogout()
      } else if (diff <= allowedDiff) {
        refreshToken()
      }

      const token = getUserToken()

      if (token === '') {
        handleLogout()
      }

      refreshedToken = token ? token : ''
    }
    return refreshedToken
  }

  const accessToken = getTokenRefreshAble()
  APIConfiguration.baseOptions = {
    headers: {
      Authorization: 'Bearer ' + accessToken || ''
    }
  }
  apiService = new DefaultApi(APIConfiguration)

  const logoutUser = async () => {
    const response = await apiService.logoutUserAuthViewSet()
    if (response.status === 200) {
      return true
    } else {
      return false
    }
  }

  return (
    <APIServiceContext.Provider
      value={{
        apiService,
        refreshToken,
        getTokenRefreshAble,
        logoutUser
      }}
    >
      {children}
    </APIServiceContext.Provider>
  )
}

export function useAPIServiceContext() {
  const context = useContext(APIServiceContext)
  if (context === undefined) {
    throw new Error(
      'useAPIServiceContext must be used within an APIServiceContextProvider'
    )
  }
  return context
}
