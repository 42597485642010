import ClustersContextProvider from 'contexts/ClustersContext'
import UserClusters from 'pages/AdminPages/Clusters'

export default function UserClustersPage() {
  return (
    <ClustersContextProvider>
      <UserClusters />
    </ClustersContextProvider>
  )
}
