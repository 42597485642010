import { useContext, useState } from 'react'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Box from '@mui/system/Box'
import { IChangeEvent } from '@rjsf/core'
import MuiForm from '@rjsf/mui'
import { RJSFSchema } from '@rjsf/utils'
import validator from '@rjsf/validator-ajv8'
import { isAxiosError } from 'axios'
import CustomBaseInputTemplate from 'components/common/RJSF/MUI/CustomBaseInputTemplate'
import { configRequestResetPassword } from 'constants/formconfig/RequestResetPassword'
import { AuthContext } from 'contexts/AuthContext'
import useStyles from './styles'

const initialFormData: any = {
  email: ''
}

export default function RequestResetPasswordLink() {
  const classes = useStyles()
  const { unAuthenticatedAPIService } = useContext(AuthContext)
  const schema = configRequestResetPassword.schema

  const [formData, setFormData] = useState<any>(initialFormData)
  const [passwordResetLinkSentSuccess, setPasswordResetLinkSentSuccess] =
    useState<boolean>(false)
  const [passwordResetLinkSent, setPasswordResetLinkSent] =
    useState<boolean>(false)
  const [passwordResetRequestError, setPasswordResetRequestError] =
    useState<boolean>(false)

  const handleFormChange = (
    event: IChangeEvent<any, RJSFSchema, any>,
    id?: string | undefined
  ) => {
    const data = JSON.parse(JSON.stringify(event.formData))
    setFormData(data)
  }

  const handleSubmit = async (
    data: IChangeEvent<any, RJSFSchema, any>,
    event: React.FormEvent<any>
  ) => {
    const requestBody = JSON.parse(JSON.stringify(data.formData))
    try {
      await unAuthenticatedAPIService
        .requestResetPasswordAuthViewSet(requestBody)
        .then((json) => {
          if (json.status === 200) {
            setPasswordResetLinkSent(true)
            if (json.data.detail === 'Password reset link sent successfully.') {
              setPasswordResetLinkSentSuccess(true)
            } else {
              setPasswordResetRequestError(true)
              setPasswordResetLinkSentSuccess(false)
            }
          } else {
            setPasswordResetRequestError(true)
            setPasswordResetLinkSent(false)
          }
        })
    } catch (err: any) {
      if (isAxiosError(err)) {
        if (err && err.response && err.response.data) {
          setPasswordResetRequestError(true)
        }
      }
    }
  }
  return (
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Forgot Password
      </Typography>
      {passwordResetLinkSent &&
        (passwordResetLinkSentSuccess ? (
          <Typography
            variant="body2"
            sx={{
              mt: 1,
              color: '#4eb020',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <CheckCircleOutlineRoundedIcon sx={{ mr: 1 }} />
            We have sent a reset password link to your email. Please Check.
          </Typography>
        ) : (
          <Typography
            variant="body2"
            sx={{
              mt: 1,
              color: '#f93e3e',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <CancelOutlinedIcon sx={{ mr: 1 }} />
            This email address is not registered. Please try again.
          </Typography>
        ))}
      {passwordResetRequestError && (
        <Typography
          variant="body2"
          sx={{
            mt: 1,
            color: '#f93e3e',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <CancelOutlinedIcon sx={{ mr: 1 }} />
          Invalid email address. Please try again.
        </Typography>
      )}
      <MuiForm
        schema={schema}
        onSubmit={handleSubmit}
        formData={formData}
        onChange={handleFormChange}
        uiSchema={configRequestResetPassword.uiSchema}
        validator={validator}
        templates={{ BaseInputTemplate: CustomBaseInputTemplate }}
      >
        <Button
          color="primary"
          className={`${classes.button} ${classes.forgotPasswordButton}`}
          type="submit"
        >
          Get New Password
        </Button>
      </MuiForm>
    </Box>
  )
}
