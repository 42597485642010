import { useContext } from 'react'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { ComplianceHistoryContext } from 'contexts/ComplianceHistoryContext'

export default function ProvidersFilter() {
  const { providersOptions, selectedProvider, setSelectedProvider } =
    useContext(ComplianceHistoryContext)

  const onChange = (event: SelectChangeEvent) => {
    setSelectedProvider(String(event.target.value))
  }

  if (!providersOptions || providersOptions.length <= 0) return <></>
  return (
    <FormControl
      sx={{
        padding: 1,
        paddingLeft: 0,
        paddingTop: 0,
        margin: 1,
        marginTop: 0
      }}
    >
      <InputLabel id="select-helper-label-filter-service-provider">
        {Boolean(selectedProvider) ? 'Provider' : null}
      </InputLabel>
      <Select
        style={{ minWidth: 240 }}
        labelId="select-helper-label-filter-service-provider"
        id="select-helper-filter-service-provider"
        value={selectedProvider}
        onChange={onChange}
        autoWidth
        displayEmpty
        label={Boolean(selectedProvider) ? 'Provider' : null}
        sx={{
          '& .MuiSelect-select': {
            padding: 1
          }
        }}
      >
        <MenuItem value="">
          <em>All Providers</em>
        </MenuItem>
        {providersOptions.map((o) => (
          <MenuItem key={o.value} value={o.value}>
            {o.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
