import APIKeysContextProvider from 'contexts/APIKeysContext'
import APIKeysList from 'pages/AdminPages/APIKeysList'

export default function APIKeysAdminPage() {
  return (
    <APIKeysContextProvider>
      <APIKeysList />
    </APIKeysContextProvider>
  )
}
