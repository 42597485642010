import { useContext, useEffect, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import { makeStyles } from '@mui/styles'
import { IChangeEvent } from '@rjsf/core'
import MuiForm from '@rjsf/mui'
import { ErrorSchema, ErrorSchemaBuilder, RJSFSchema } from '@rjsf/utils'
import validator from '@rjsf/validator-ajv8'
import { AxiosError, isAxiosError } from 'axios'
import Icon, { IconType } from 'components/Icons'
import DialogModal from 'components/Modals/DialogModal'
import FormsUpdateCancelButtons from 'components/common/FormsUpdateCancelButtons'
import { configOrganizationEdit } from 'constants/formconfig/Organizations'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { OrganizationsContext } from 'contexts/OrganizationsContext'
import { UserOrganization } from 'lib/CloudCanariesRestfulAPI'

const useStyles = makeStyles({
  muiForm: {
    '& .MuiGrid-root': {
      '& .MuiGrid-item': {
        marginBottom: `6px !important`,
        paddingTop: 4
      },
      '& .MuiCheckbox-root': {
        paddingTop: 6,
        paddingBottom: 6
      }
    }
  }
})

const schema = configOrganizationEdit.schema

interface OrganizationEditModalProps {
  organization?: UserOrganization
}

export default function OrganizationEditModal(
  props: OrganizationEditModalProps
) {
  const classes = useStyles()
  const { organization } = props
  const { apiService } = useContext(APIServiceContext)
  const { fetchUserOrganizations } = useContext(OrganizationsContext)

  const [formData, setFormData] = useState<any>({})
  const [extraErrors, setExtraErrors] = useState<ErrorSchema>({})
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (organization) {
      setFormData({
        name: organization.name,
        send_data_to_snowflake: organization.send_data_to_snowflake,
        use_cortex_forecast_model: organization.use_cortex_forecast_model
      })
    }
  }, [organization])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleFormChange = (
    event: IChangeEvent<any, RJSFSchema, any>,
    id?: string | undefined
  ) => {
    let data = JSON.parse(JSON.stringify(event.formData))
    setFormData(data)
  }

  const onSubmit = async (
    data: IChangeEvent<any, RJSFSchema, any>,
    event: React.FormEvent<any>
  ) => {
    let requestBody = JSON.parse(JSON.stringify(data.formData))
    try {
      await apiService
        .partialUpdateOrganization(organization?.id!, requestBody)
        .then(async (json) => {
          if (json.status === 200) {
            await fetchUserOrganizations().then(() => handleClose)
          }
        })
    } catch (e: any) {
      if (isAxiosError(e)) {
        const err = e as AxiosError
        if (err.response?.status === 400) {
          const errors = err.response?.data as {}
          const builder = new ErrorSchemaBuilder()
          Object.entries(errors).forEach(([key, value]) => {
            builder.addErrors(value as string, key)
          })
          setExtraErrors(builder.ErrorSchema)
        }
      }
    }
  }

  return (
    <>
      <IconButton
        onClick={handleClickOpen}
        color="primary"
        size="small"
        style={{ marginRight: '0.5rem' }}
      >
        <Icon name={IconType.Edit} />
      </IconButton>
      <DialogModal
        open={open}
        onClose={handleClose}
        dialogTitle="Edit Organization"
        dialogContent={
          <MuiForm
            schema={schema}
            onSubmit={onSubmit}
            formData={formData}
            className={classes.muiForm}
            onChange={handleFormChange}
            extraErrors={extraErrors}
            showErrorList={false}
            validator={validator}
          >
            <FormsUpdateCancelButtons cancelButtonOnClick={handleClose} />
          </MuiForm>
        }
      />
    </>
  )
}
