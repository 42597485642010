import {
  clearUserOrganization,
  selectAllOrganizations,
  selectLastSeletedOrganization,
  selectOrganization,
  selectOrganizationID,
  selectOrganizations,
  selectUserOrganization,
  setAllOrganizations,
  setLastSelectedOrganization,
  setOrganizations,
  setSelectedUserOrganization
} from 'client_side_state/slices/user'
import { useAppDispatch, useAppSelector } from 'client_side_state/store'
import {
  ChangeEvent,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { Organization, UserOrganization } from 'lib/CloudCanariesRestfulAPI'
import { APIServiceContext } from './APIServiceContext'
import { AuthContext } from './AuthContext'

interface OrganizationsContextProviderProps {
  children: ReactNode
}

interface OrganizationsContextProps {
  areUserOrganizationsLoading: boolean
  userOrganizations: UserOrganization[]
  allOrganizations: UserOrganization[]
  organization: Organization | null
  userOrganization: UserOrganization | null
  lastSelectedOrganization: UserOrganization | null
  rowsPerPage: number
  page: number
  rowCount: number
  organizationId: string | undefined
  fetchUserOrganizations: () => Promise<void>
  fetchAllOrganizations: () => Promise<void>
  setUserSelectedOrganization: (
    selectedOrganization: Organization | null
  ) => void
  setLastUserSelectedOrganization: (
    lastSelectedOrganization: Organization | null
  ) => void
  clearOrganization: () => void
  onRowsPerPageChange: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void
  handleChangePage: (event: any, newPage: number) => void
}
export const OrganizationsContext = createContext<OrganizationsContextProps>(
  null as any
)

export default function OrganizationsContextProvider(
  props: OrganizationsContextProviderProps
) {
  const { children } = props
  const dispatch = useAppDispatch()

  const selectedOrganizations = useAppSelector(selectOrganizations)
  const selectedAllOrganizations = useAppSelector(selectAllOrganizations)
  const selectedOrganization = useAppSelector(selectOrganization)
  const selectedOrganizationId = useAppSelector(selectOrganizationID)
  const selectedLastSelectedOrganization = useAppSelector(
    selectLastSeletedOrganization
  )
  const selectedUserOrganization = useAppSelector(selectUserOrganization)

  const { isAuthenticated } = useContext(AuthContext)
  const { apiService } = useContext(APIServiceContext)

  const [page, setPage] = useState<number>(0)
  const [rowCount, setRowCount] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)

  const [areUserOrganizationsLoading, setAreUserOrganizationsLoading] =
    useState(true)

  const allOrganizations = useMemo(
    () => selectedAllOrganizations,
    [selectedAllOrganizations]
  )

  const userOrganizations = useMemo(
    () => selectedOrganizations,
    [selectedOrganizations]
  )
  const organization = useMemo(
    () => selectedOrganization,
    [selectedOrganization]
  )
  const lastSelectedOrganization = useMemo(
    () => selectedLastSelectedOrganization,
    [selectedLastSelectedOrganization]
  )
  const userOrganization = useMemo(
    () => selectedUserOrganization,
    [selectedUserOrganization]
  )
  const organizationId = useMemo(
    () => selectedOrganizationId,
    [selectedOrganizationId]
  )

  useEffect(() => {
    setRowCount(0)
  }, [])

  const fetchAllOrganizations = useCallback(async () => {
    setAreUserOrganizationsLoading(true)
    await apiService.listOrganizations().then((json) => {
      dispatch(setAllOrganizations(json.data.results ?? []))
      setAreUserOrganizationsLoading(false)
    })
  }, [apiService, dispatch])

  useEffect(() => {
    if (isAuthenticated()) {
      fetchAllOrganizations()
    }
  }, [fetchAllOrganizations, isAuthenticated])

  const fetchUserOrganizations = useCallback(async () => {
    setRowCount(0)
    const requestedPage = page + 1

    setAreUserOrganizationsLoading(true)
    await apiService
      .listOrganizations(requestedPage, rowsPerPage)
      .then((json) => {
        dispatch(setOrganizations(json.data.results ?? []))
        setRowCount(json.data.count ?? 0)
        setAreUserOrganizationsLoading(false)
      })
  }, [apiService, dispatch, page, rowsPerPage])

  useEffect(() => {
    if (isAuthenticated()) {
      fetchUserOrganizations()
    }
  }, [fetchUserOrganizations, isAuthenticated])

  const setUserSelectedOrganization = (
    selectedOrganization: Organization | null
  ) => {
    dispatch(setSelectedUserOrganization(selectedOrganization))
  }

  const setLastUserSelectedOrganization = (
    lastSelectedOrganization: Organization | null
  ) => {
    dispatch(setLastSelectedOrganization(lastSelectedOrganization))
  }
  const clearOrganization = () => {
    dispatch(clearUserOrganization())
  }

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage)
  }

  const onRowsPerPageChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <OrganizationsContext.Provider
      value={{
        areUserOrganizationsLoading,
        userOrganizations,
        allOrganizations,
        organization,
        lastSelectedOrganization,
        userOrganization,
        organizationId,
        page,
        rowCount,
        rowsPerPage,
        fetchUserOrganizations,
        fetchAllOrganizations,
        setUserSelectedOrganization,
        setLastUserSelectedOrganization,
        clearOrganization,
        handleChangePage,
        onRowsPerPageChange
      }}
    >
      {children}
    </OrganizationsContext.Provider>
  )
}
