import { Link } from 'react-router-dom'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import RoutesService from 'services/routes'
import { GraphContainer } from 'styles/pages/CanaryManage.styled'
import ForecastCanaryDailyForecastsGridCharts from './ForecastCanaryDailyForecastsGridCharts'
import { CanaryDailyForecasts } from './IndividualCanaryForecastsSection'

const useStyles = makeStyles({
  cardContentRoot: {
    paddingTop: '0.5rem'
  },
  cardHeaderRoot: {
    textAlign: 'center',
    color: '#000000de',
    paddingBottom: '0.5rem'
  },
  cardHeaderTitle: {
    color: '#000000de',
    borderRadius: 4
  },
  cardContentTypography: {
    marginBottom: '0.75rem'
  }
})

interface GridItemProps {
  canaryDailyForecast: CanaryDailyForecasts
}

export default function ForecastCanaryGridItem(props: GridItemProps) {
  const { canaryDailyForecast } = props
  const classes = useStyles()

  return (
    <Card>
      <CardHeader
        title={canaryDailyForecast.canary_name}
        classes={{
          root: classes.cardHeaderRoot,
          title: classes.cardHeaderTitle
        }}
      />
      <CardContent classes={{ root: classes.cardContentRoot }}>
        <Typography
          align="center"
          gutterBottom
          classes={{ root: classes.cardContentTypography }}
        >
          {canaryDailyForecast?.task_name!}
        </Typography>
        <CardActionArea>
          <Link
            to={RoutesService.canaryForecastManage(canaryDailyForecast.canary)}
            style={{
              textDecoration: 'none',
              cursor: 'default'
            }}
          >
            <GraphContainer>
              <ForecastCanaryDailyForecastsGridCharts
                canaryDailyForecast={canaryDailyForecast}
              />
            </GraphContainer>
          </Link>
        </CardActionArea>
      </CardContent>
    </Card>
  )
}
