import { FormConfigTypes } from './FormConfigsTypes'

export const configUserOrganizationAddMember: FormConfigTypes = {
  schema: {
    title: 'Add Member to Organization',
    type: 'object',
    required: ['email'],
    properties: {
      email: { type: 'string', title: 'Email Address' }
    }
  }
}
