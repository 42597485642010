import {
  getSelectedAlarmId,
  getSelectedTabName,
  setSelectAlarmId
} from 'client_side_state/slices/canaryAlarmPage'
import { useAppDispatch, useAppSelector } from 'client_side_state/store'
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { MainTabsNames } from 'components/Alarms/TabsNames'
import { queryParamPageSizeJumbo } from 'constants/AppConfig'
import {
  CanaryDetailTaskReadOnlyMinimal,
  CanaryTask,
  SimpleAlarm,
  SimpleAlarmAlarmTypeEnum
} from 'lib/CloudCanariesRestfulAPI'
import { splitByCapitalLetters } from 'services/utils'
import { APIServiceContext } from './APIServiceContext'

interface CanaryMetricsContextProviderProps {
  children: ReactNode
  canaryId: string
}

interface CanaryMetricsContextProps {
  canaryId: string
  selectedTaskId: string
  selectedAlarmId: string
  taskMeta: CanaryTask
  selectedAlarmCategory: string
  areMetricAlarmsLoading: boolean
  metricAlarms: SimpleAlarm[]
  setSelectedAlarmID: (selectedAlarmId: string) => void
  setSelectedAlarmCategory: (selectedAlarmCategory: string) => void
  setAreAlarmMetricsLoading: (areAlarmMetricsLoading: boolean) => void
}
export const CanaryMetricsContext = createContext<CanaryMetricsContextProps>(
  null as any
)

const defaultTask = {
  latency_metric_alarm_id: '',
  name: '',
  task_id: ''
} as CanaryTask

const metricId = undefined

export default function CanaryMetricsContextProvider(
  props: CanaryMetricsContextProviderProps
) {
  const { children, canaryId } = props
  const dispatch = useAppDispatch()
  const selectAlarmId = useAppSelector(getSelectedAlarmId)
  const selectTabName = useAppSelector(getSelectedTabName)

  const { apiService } = useContext(APIServiceContext)

  const [taskMeta, setTaskMeta] = useState<CanaryTask>(defaultTask)
  const [selectedAlarmId, setSelectedAlarmId] = useState<string>(selectAlarmId)
  const [selectedAlarmCategory, setSelectedAlarmCategory] =
    useState<string>(selectTabName)

  const [areMetricAlarmsLoading, setAreAlarmMetricsLoading] =
    useState<boolean>(true)
  const [metricAlarms, setMetricAlarms] = useState<SimpleAlarm[]>([])

  const [canaryTasks, setCanaryTasks] = useState<
    CanaryDetailTaskReadOnlyMinimal[]
  >([])

  const [selectedTaskId, setTaskId] = useState<string>('')

  const getCanaryTasks = useCallback(async () => {
    await apiService
      .listCanaryTasks(undefined, queryParamPageSizeJumbo, canaryId)
      .then((json) => {
        setCanaryTasks(json.data.results ?? [])
      })
  }, [apiService, canaryId])

  const getTaskMeta = useCallback(async () => {
    if (selectedTaskId) {
      await apiService.retrieveCanaryTask(selectedTaskId).then((json) => {
        setTaskMeta(json.data)
      })
    }
  }, [apiService, selectedTaskId])

  const getAlarms = useCallback(async () => {
    setAreAlarmMetricsLoading(true)
    await apiService
      .listSimpleAlarms(
        undefined,
        queryParamPageSizeJumbo,
        metricId,
        canaryId,
        selectedTaskId
      )
      .then((json) => {
        setMetricAlarms(json.data.results ?? [])
        setAreAlarmMetricsLoading(false)
      })
  }, [apiService, canaryId, selectedTaskId])

  useEffect(() => {
    if (canaryId) {
      getCanaryTasks()
    }
  }, [getCanaryTasks, canaryId])

  useEffect(() => {
    if (canaryId && canaryTasks.length > 0) {
      setTaskId(canaryTasks?.[0]?.id!)
    }
  }, [canaryTasks, canaryId, setTaskId])

  useEffect(() => {
    getTaskMeta()
  }, [getTaskMeta])

  useEffect(() => {
    getAlarms()
  }, [getAlarms])

  const setSelectedAlarmID = useCallback(
    (selectedAlarmId: string) => {
      setSelectedAlarmId(selectedAlarmId)
      dispatch(setSelectAlarmId(selectedAlarmId!))
    },
    [dispatch]
  )

  useEffect(() => {
    if (
      metricAlarms.find((item) =>
        splitByCapitalLetters(item.alarm_type!).includes(
          selectedAlarmCategory.toLowerCase()
        )
      )
    ) {
      let selectedAlarmId = metricAlarms.filter((item) =>
        splitByCapitalLetters(item.alarm_type!).includes(
          selectedAlarmCategory.toLowerCase()
        )
      )[0].id
      if (selectedAlarmCategory === MainTabsNames.RESPONSE) {
        selectedAlarmId = metricAlarms.filter((item) =>
          item.alarm_type?.includes(
            SimpleAlarmAlarmTypeEnum.CanaryTaskResponseValue
          )
        )[0].id
      }
      setSelectedAlarmID(selectedAlarmId!)
    }
  }, [
    dispatch,
    metricAlarms,
    selectedAlarmCategory,
    selectedAlarmId,
    setSelectedAlarmID
  ])

  return (
    <CanaryMetricsContext.Provider
      value={{
        canaryId,
        selectedTaskId,
        areMetricAlarmsLoading,
        metricAlarms,
        selectedAlarmCategory,
        selectedAlarmId,
        taskMeta,
        setAreAlarmMetricsLoading,
        setSelectedAlarmCategory,
        setSelectedAlarmID
      }}
    >
      {children}
    </CanaryMetricsContext.Provider>
  )
}
