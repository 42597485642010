import {
  selectCanaryForecastCharts,
  setSelectedCanaryForecastCharts
} from 'client_side_state/slices/forecastManagePage'
import { useAppDispatch, useAppSelector } from 'client_side_state/store'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Icon, { IconType } from 'components/Icons'
import { CanaryForecastGraphMetrics } from 'constants/CanaryForecastGraphMetrics'

const options = Object.entries(CanaryForecastGraphMetrics).map(
  ([key, value]) => ({
    label: key
      .toLowerCase()
      .split('_')
      .map((word) => word[0].toUpperCase() + word.substring(1))
      .join(' '),
    value: value
  })
)
export default function CanaryDailyForecastChartPanesSelector() {
  const dispatch = useAppDispatch()
  const selectedCanaryDailyForecastCharts = useAppSelector(
    selectCanaryForecastCharts
  )

  const handleChange = (metricType: CanaryForecastGraphMetrics) => {
    let filtered
    if (selectedCanaryDailyForecastCharts.includes(metricType)) {
      filtered = selectedCanaryDailyForecastCharts.filter((v) => {
        return v !== metricType
      })
    } else {
      filtered = selectedCanaryDailyForecastCharts.concat([metricType])
    }
    dispatch(setSelectedCanaryForecastCharts(filtered))
  }

  return (
    <FormControl size="small">
      <Select
        value={''}
        displayEmpty
        renderValue={() => 'Panes'}
        inputProps={{ 'aria-label': 'Panes' }}
      >
        {options.map(({ label, value }) => (
          <MenuItem
            key={value}
            value={value}
            onClick={() => {
              handleChange(value)
            }}
          >
            {selectedCanaryDailyForecastCharts.includes(value) ? (
              <Icon name={IconType.Check} />
            ) : null}{' '}
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
