import useDocumentTitle from 'hooks/useDocumentTitle'
import { useHistory } from 'react-router-dom'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import Box from '@mui/system/Box'
import BackButton from 'components/common/BackButton'
import { PublicPageContainer } from 'styles/pages/common/Page.styled'

const useStyles = makeStyles({
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 0
  },
  dialogContent: {
    padding: '20px',
    paddingTop: 0,
    backgroundColor: 'white'
  },
  container: {
    padding: '1rem',
    margin: '0.5rem'
  },
  termsText: {
    padding: '0.25rem',
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: 1.5
  }
})

export default function TermsAndConditions() {
  useDocumentTitle('Terms and Conditions')
  const history = useHistory()
  const classes = useStyles()

  return (
    <PublicPageContainer>
      <DialogTitle className={classes.dialogTitle}>
        <h2>Terms and Conditions</h2>
        <BackButton
          action={() => {
            history.goBack()
          }}
        />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container className={classes.container}>
          <Typography variant="h5">CLOUD CANARIES</Typography>
          <Box component="div" className={classes.termsText}>
            <br />
            END USER LICENSE AGREEMENT
            <br />
            (SaaS and HOSTED SERVICES)
            <br />
            <br />
            PLEASE CAREFULLY READ ALL OF THE TERMS AND CONDITIONS OF THIS END
            USER LICENSE AGREEMENT AND OUR PRIVACY POLICY. BY CLICKING I ACCEPT
            OR TAKING ANY STEPS TO ACCESS OR USE CLOUDCANARIES.ai SERVICES YOU
            ACKNOWLEDGE THAT YOU HAVE READ THIS AGREEMENT AND OUR PRIVACY POLICY
            AND AGREE TO BE BOUND BY EACH OF THEIR TERMS AND CONDITIONS. IF YOU
            DO NOT AGREE WITH ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT
            AND OUR PRIVACY POLICY, DO NOT CLICK” I ACCEPT” AND YOU ARE NOT
            PERMITTED TO ACCESS OR USE CLOUDCANARIES.ai SERVICES.
            <br />
            <br />
            The following terms and conditions govern all use of the Cloud
            Canaries websites (https://www.CloudCanaries.ai), the Cloud Canaries
            mobile phone application(s) and all content, services and products
            available at or through the websites and/or application (“Services”
            or “Site”) by the user (“user” “you” “your”). The Services are
            provided by, owned and operated by Cloud Canaries Inc. (“Cloud
            Canaries” “we” “us” “our”) a Delaware corporation with offices at 8
            Merrill Street, Cambridge, MA 02138. The Services are offered
            subject to your acceptance without modification of all of the terms
            and conditions contained herein and all other operating rules,
            policies and procedures that may be published from time to time on
            this Site by Cloud Canaries (collectively, the “Agreement”).
            <br />
            <br />
            If using the Cloud Canaries mobile phone application, you
            acknowledge and agree that by accepting the terms of this Agreement,
            a binding agreement is concluded solely between you and Cloud
            Canaries, and not with Apple Inc. ("Apple"), and that Cloud
            Canaries, not Apple, is solely responsible for the applications
            licensed hereunder and the content thereof.
            <br />
            <br />
            Cloud Canaries may change, add or remove any part of this Agreement,
            or any other terms associated with the use of the Services, at any
            time, by providing notice in writing or electronically. Any changes
            shall become part of the Agreement and shall apply as soon as such a
            notice is sent. By continuing to use the Services after the notice
            is sent, you are indicating your acceptance of those changes.
            <br />
            <br />
            1. ABOUT CLOUD CANARIES
            <br />
            <br />
            1.1 Miners have used canaries to monitor their environments for
            centuries. Cloud Canaries likewise enable corporations and
            businesses an easy to use, inexpensive and robust early warning
            system by exercising cloud workloads, such as a web server,
            database, and compute.
            <br />
            <br />
            1.2 Cloud Canaries simulate cloud workloads. Cloud Canaries report
            success, failure, and latency information. Cloud Canaries
            crowdsource from other Cloud Canaries to pinpoint failures.
            Information is collected, analyzed and actioned via standard
            messaging systems and displayed on Cloud Canary dashboards.
            <br />
            <br />
            2. LICENSES; LICENSE RESTRICTIONS
            <br />
            <br />
            2.1 License Grant. Subject to the terms and conditions of this
            Agreement, Cloud Canaries hereby grants to you a limited, personal,
            non-exclusive, non-transferable, non-sublicenseable, revocable
            license to remotely create, update, deploy and manage Cloud Canaries
            to access Cloud Canaries Services and features and to engage in
            permissible activities and participation thereon.
            <br />
            <br />
            2.2 Third Party Licenses. The Cloud Canaries application and/or
            website may contain third party software which is subject to
            additional notices and/or terms and conditions. Such required third
            party software notices and/or additional terms and conditions are
            located at http://www.CloudCanaries.ai/thirdparty and are made a
            part of, and incorporated by reference into this Agreement. In
            addition, you acknowledge and agree that certain third party
            software which is not incorporated into the Cloud Canaries
            application and/or website may be required in order for you to use
            or enjoy the full benefit of the Services. You shall be fully
            responsible for obtaining a lawful copy of such software.
            Furthermore, your use of such separately acquired third party
            software shall be in accordance with any terms and conditions of the
            end user license agreement provided with such software. You
            acknowledge that your access to and use of the Services does not
            carry, and you do not receive under this Agreement any license,
            covenant not to sue, or other rights under any third party
            intellectual property rights or other rights.
            <br />
            <br />
            2.3 License Restrictions. You agree that the license granted herein
            is solely for non-commercial purposes (i.e. you are not permitted to
            sell, rent, lease, or otherwise charge for access to or use of any
            Cloud Canaries or Dashboards created by you). In addition, you agree
            that you will not, nor permit others to: (a) attempt to reverse
            engineer, decompile, disassemble, or extract any element of and/or
            otherwise discover any source code, algorithms, methods or
            techniques embodied in the Cloud Canaries Services, application or
            website, except to the extent expressly permitted by applicable law,
            notwithstanding contractual obligations to the contrary, and then
            only after (i) you have notified Cloud Canaries in writing of your
            intended activities and the information sought and (ii) Cloud
            Canaries fails to provide such information within a reasonable
            period of time following such notice; (c) modify, transfer, assign,
            pledge, sublicense, rent, lease, sell, resell, or create derivative
            works based on the Cloud Canaries Services or any software, content
            or information thereon, including but not limited to any user
            interfaces related to the foregoing.; nor (d) distribute any content
            available through the Services except as expressly permitted.
            <br />
            <br />
            2.4 Product Activation; Server Access Technology. You acknowledge
            and agree that the Services may include server access restrictions,
            security and other technology designed to prevent unauthorized use
            and copying beyond the scope of the licenses granted in this
            Agreement. In addition, you acknowledge and agree that the Services
            may include technology designed to access (both manually and
            automatically) the Cloud Canaries servers. You agree that you will
            not attempt to, or encourage or assist any other person to,
            circumvent or modify any security technologies included as part of
            the Services.
            <br />
            <br />
            2.5 No Support by Apple. You acknowledge that Apple has no
            obligation whatsoever to furnish any maintenance and support
            services with respect to any of the Services and/or Cloud Canaries
            software.
            <br />
            <br />
            2.6 User Access. You acknowledge and agree that your access to the
            Services from your computer, mobile phone, or tablet pc may require
            that you create a user account, including the use of a user name and
            password or other logon credentials (altogether, Logon Credentials).
            It is important that you remember your Logon Credentials and that
            you keep them in a safe offline location. If you forget your Logon
            Credentials you may not be able to access the Services. You are
            solely responsible for maintaining the confidentiality of your Logon
            Credentials and for all activities that occur through the use of
            your Logon Credentials. you agree to: (i) immediately notify Cloud
            Canaries of any unauthorized use of your user account or any other
            breach of security of which you become aware, and (ii) ensure that
            you secure your user account and computer, mobile phone, or tablet
            pc from unauthorized third party access. Cloud Canaries will not be
            liable for any loss or damage arising from your failure to comply
            with this Section. Please note that you may be issued new Logon
            Credentials or required to change your Logon Credentials from time
            to time.
            <br />
            <br />
            2.7 Privacy. You acknowledge and agree that your access to the
            Services may require you or provide you the option to transmit to
            Cloud Canaries certain personal and non-personal information about
            you, such as your Login Credentials, name, email address, telephone
            number, mailing address, company name, employment title, and/or
            certain technical information about your system hardware and
            software. Cloud Canaries will collect, use and in certain limited
            circumstances disclose your personal information (e.g. to
            contractors and service providers who are assisting Cloud Canaries
            in the operation or hosting of the Cloud Canaries website,
            application, or other software) in accordance with its Privacy
            Policy. As a condition to accessing and using the Services, you are
            required to accept the terms of our Privacy Policy. You hereby
            acknowledge and agree that you have read our Privacy Policy which is
            available at www.CloudCanaries.ai/privacy and is incorporated herein
            by this reference.
            <br />
            <br />
            2.8 Security. Cloud Canaries has implemented physical, electronic
            and managerial procedures in order to help safeguard and prevent
            unauthorized access, use and/or disclosure of your personal and
            other sensitive information Cloud Canaries has collected from you.
            Although Cloud Canaries uses reasonable efforts to safeguard the
            security of such information, transmissions made on or through the
            Internet and information stored on Cloud Canaries's servers are
            vulnerable to attack and cannot be guaranteed to be secure. In
            addition, submissions made via e-mail are not protected by
            encryption and are vulnerable to interception during transmission.
            In addition, please be aware that any Content which you submit in a
            public forum can be freely accessed by third parties. You covenant
            that you will not attempt to access, upload, distribute or make
            available for distribution any proprietary and/or confidential
            Content unless you have sufficient rights and proper authorization
            to do so and you further agree that you will not distribute nor make
            available for distribution any proprietary and/or confidential
            Content to third parties who do not themselves have sufficient
            rights and proper authorization to access and/or download such
            proprietary and/or confidential Content.
            <br />
            <br />
            2.9 Modifications. Cloud Canaries reserves the right, at any time in
            Cloud Canaries's discretion, to (i) modify the features,
            functionality and/or product specifications for the Services without
            notice to you, and/or (ii) to commence charging fees in order to
            access and use the Services or to increase any such fees already
            being charged.
            <br />
            <br />
            2.10 Canary Review. Cloud Canaries disclaims any responsibility for
            any harm resulting from the use by users of the Services.
            <br />
            <br />
            2.11 Copyright Infringement and DMCA Policy. As Cloud Canaries asks
            others to respect its intellectual property rights, it respects the
            intellectual property rights of others. If you believe that material
            located on or linked to by CloudCanaries.ai violates your copyright,
            you are encouraged to notify Cloud Canaries in accordance with Cloud
            Canaries's Digital Millennium Copyright Act (“DMCA”) Policy (see
            below). Cloud Canaries will respond to all such notices, including
            as required or appropriate by removing the infringing material or
            disabling all links to the infringing material that are contained on
            the Website. Cloud Canaries may terminate a visitor's access to and
            use of the Website at Cloud Canaries's sole discretion if Cloud
            Canaries determines that the visitor or user is or may be infringing
            on the copyrights or other intellectual property rights of Cloud
            Canaries or others. In the case of such termination, Cloud Canaries
            will have no obligation to provide a refund of any amounts
            previously paid to Cloud Canaries.
            <br />
            <br />
            DMCA/IP Notification Policy. To notify Cloud Canaries of infringing
            or unlawful content, please provide Cloud Canaries with the
            following information:
            <br />
            <ul>
              <li>Your name, address, telephone number and e-mail address;</li>
              <li>
                A description of the exact location on the Site of the
                infringing or unlawful content;
              </li>
              <li>
                A description of the claimed infringing or unlawful content,
                specifying which parts you belief infringe or are unlawful and
                which parts you believe should be removed;
              </li>
              <li>
                In the event that you believe that the content infringes your
                rights, please include a detailed statement specifying your
                asserted rights and why the content infringes them.
              </li>
              <li>
                In the event that you believe that the content is unlawful,
                please include a detailed statement specifying why you believe
                that the content breaches a particular law; and
              </li>
              <li>
                A sworn and signed statement that the information provided is
                accurate and that you are directly or indirectly damages by the
                content.
              </li>
            </ul>
            Cloud Canaries takes such notices very seriously. We will evaluate
            the provided notice and if appropriate, based on our sole
            discretion, remove the content or disable access to the content.
            Based on our judgment, we may notify the source of the content of
            your complaint and our actions in response to your complaint. We
            reserve the right in our sole discretion to restore such content if
            the source of such content provides evidence that the content should
            not have been removed. We encourage you to see the advice of an
            independent attorney before filing an intellectual property notice
            or responding to a notice filed by another user. Cloud Canaries may
            be notified of claims of intellectual property infringement by
            emailing the above requested information to Legal@CloudCanaries.ai.
            <br />
            <br />
            3. FEEDBACK
            <br />
            <br />
            3.1 Feedback. If you elect to provide any feedback or comments of
            any nature to Cloud Canaries, all of your feedback and comments
            shall be the sole and exclusive property of Cloud Canaries, and
            Cloud Canaries shall have the right to use such feedback in any
            manner and for any purpose in Cloud Canaries's discretion without
            remuneration, compensation or attribution to you, provided that
            Cloud Canaries is under no obligation to use such feedback.
            <br />
            <br />
            4. OWNERSHIP. Cloud Canaries retains all right, title and interest
            (including all intellectual property rights and other rights) in and
            to the Services, including without limitation the Cloud Canaries
            trademarks, copyrights, software, the documentation and updates of
            any of the foregoing, and any feedback submitted by you in
            accordance with Section 3.1, subject only to the limited licenses
            expressly set forth in Section 2.1 of this Agreement. You do not
            acquire any other rights, express or implied, in the Services other
            than those rights expressly granted under this Agreement. However,
            you do retain the rights and/or the ownership in all content and
            data uploaded by you. You hereby grant Cloud Canaries, its
            affiliates, contractors and each of their agents a non-exclusive,
            irrevocable, worldwide license to (a) upload, copy and store the
            Content; (b) extract some or all of the Content and data from your
            account (c) create and store Content derived from some or all of the
            data from your account and (d) distribute or otherwise use for
            marketing and promotional purposes the Content you make publicly
            available through your account. You hereby represent and warrant
            that you have the right to grant the foregoing license to Cloud
            Canaries and that Cloud Canaries's exercise of its rights hereunder
            will not infringe or misappropriate the intellectual property rights
            or other rights of any third party.
            <br />
            <br />
            5. NO WARRANTY; LIMITATION OF LIABILITY
            <br />
            <br />
            5.1 No Warranty. THE SERVICES, SITE, APPLICATION OR SOFTWARE MAY
            CONTAIN ERRORS AND DEFECTS OR MAY SUFFER INTERMITTENT DOWNTIME AND
            IS PROVIDED "AS IS", AS-AVAILABLE, WITH ALL FAULTS AND WITHOUT
            WARRANTY OF ANY KIND, AND CLOUD CANARIES AND ITS LICENSORS
            (INCLUDING BUT NOT LIMITED TO APPLE) (COLLECTIVELY REFERRED TO AS
            "CLOUD CANARIES" FOR THE PURPOSES OF SECTION 5) HEREBY DISCLAIM ALL
            WARRANTIES AND CONDITIONS WITH RESPECT TO THE SERVICES, EITHER
            EXPRESS, IMPLIED OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, THE
            IMPLIED WARRANTIES AND/OR CONDITIONS OF MERCHANTABILITY, OF
            SATISFACTORY QUALITY, OF FITNESS FOR A PARTICULAR PURPOSE, OF
            ACCURACY AND NON-INFRINGEMENT OF THIRD PARTY RIGHTS, AND ANY
            WARRANTIES THAT MAY ARISE FROM COURSE OF DEALING, COURSE OF
            PERFORMANCE OR USAGE OF TRADE. NO ORAL OR WRITTEN INFORMATION OR
            ADVICE GIVEN BY CLOUD CANARIES OR ITS REPRESENTATIVE SHALL CREATE A
            WARRANTY. CLOUD CANARIES DOES NOT WARRANT THAT THE SERVICES WILL BE
            UNINTERRUPTED, SECURE, TIMELY OR ERROR-FREE, THAT DEFECTS WILL BE
            CORRECTED, OR THAT THE SERVICES IS FREE OF VIRUSES OR OTHER HARMFUL
            COMPONENTS. SHOULD THE CLOUD CANARIES .AI SERVER PROVE DEFECTIVE,
            YOU ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR OR
            CORRECTION. YOU ASSUME ALL RESPONSIBILITY AND RISK FOR USE OF THE
            SERVICES PROVIDED BY CLOUD CANARIES. YOUR USE OF THE SERVICES IS
            ENTIRELY AT YOUR OWN RISK AND THE ENTIRE RISK AS TO SATISFACTORY
            QUALITY, PERFORMANCE, ACCURACY AND EFFORT IS WITH YOU. SOME
            JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR
            LIMITATIONS ON APPLICABLE STATUTORY RIGHTS OF A CONSUMER, SO THE
            ABOVE EXCLUSION AND LIMITATIONS MAY NOT APPLY TO YOU.
            <br />
            <br />
            5.2 Limitation of Liability. EXCEPT TO THE EXTENT THE FOREGOING
            LIMITATION OF LIABILITY IS PROHIBITED BY LAW, NEITHER CLOUD CANARIES
            NOR ANY OF ITS LICENSORS SHALL BE LIABLE TO YOU FOR PERSONAL INJURY,
            OR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL,
            EXEMPLARY, PUNITIVE OR OTHER DAMAGES (INCLUDING WITHOUT LIMITATION
            DAMAGES FOR LOSS OR INTERRUPTION OF BUSINESS, LOSS OF DATA, LOSS OF
            GOODWILL OR LOST PROFITS), UNDER ANY THEORY OF LIABILITY, INCLUDING
            WITHOUT LIMITATION CONTRACT, NEGLIGENCE, STRICT LIABILITY OR OTHER
            THEORY ARISING OUT OF OR RELATING IN ANY WAY TO THIS AGREEMENT, EVEN
            IF CLOUD CANARIES HAS BEN ADVISED OF THE RISK OF SUCH DAMAGES. SOME
            JURISDICTIONS DO NOT ALLOW THE LIMITATION OF LIABILITY FOR PERSONAL
            INJURY, OR OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THIS
            LIMITATION MAY NOT APPLY TO YOU (OTHER THAN AS MAY BE REQUIRED BY
            APPLICABLE LAW IN CASES INVOLVING PERSONAL INJURY). The foregoing
            limitations will apply even if the above stated remedy fails of its
            essential purpose. The parties acknowledge that the limitations of
            liability in this Section 5.2 and in the other provisions of this
            Agreement and the allocation of risk herein are an essential element
            of the agreement between the parties, without which Cloud Canaries
            would not have entered into this Agreement.
            <br />
            <br />
            5.3 No Liability for Third Party software. Cloud Canaries may
            incorporate or provide access to third-party websites and may
            provide links, widgets, or references to websites operated by third
            parties. We do not monitor or investigate such websites and we are
            not responsible for the content, functionality, or practices of such
            websites. Cloud Canaries has no control over or responsibility for
            the maintenance or any outages of third-party websites or widgets.
            Inclusion of links to third-party websites on our Site does not
            imply approval or endorsement of the linked website by us. If you
            decide to leave our Site and access these third party websites, you
            do so at your own risk. Cloud Canaries suggests that you read the
            terms of use and privacy policies (if any) on those third party
            websites. You agree that Cloud Canaries has no liability for any
            damage or loss of any type that is a result of your use of a third
            party website.
            <br />
            <br />
            6. INDEMNITY.
            <br />
            <br />
            You agree to defend Cloud Canaries, its affiliates, employees,
            agents, assigns and licensors against any third party suits,
            actions, claims or proceedings (i) alleging that the Content
            uploaded by you infringes or misappropriates a third party's
            intellectual property or other rights or (ii) resulting from your
            breach of or failure to comply with any term, condition,
            representation or covenant under this Agreement, and you agree to
            indemnify and hold Cloud Canaries its affiliates, employees, agents,
            assigns and licensors harmless from all damages, liabilities, costs
            and expenses, including reasonable attorneys' fees, incurred by or
            awarded against Cloud Canaries or its affiliates, employees, agents,
            assigns and licensors that may result from any such third party
            claim.
            <br />
            <br />
            7. TERMINATION. This Agreement shall remain in effect until
            terminated in accordance with its terms. You may terminate this
            Agreement at any time by notifying Cloud Canaries in writing (or
            electronically). Cloud Canaries may terminate this Agreement at any
            time upon by notifying you in writing (or electronically). Upon
            termination of this Agreement, you will cease all use of the
            Services. Note that upon termination of this Agreement you will no
            longer be able to access any Content, features, or Services and
            Cloud Canaries shall have the right to delete the same. Sections 3,
            4, 5, 6, 7 and 8 will survive any termination of this Agreement.
            <br />
            <br />
            8. GENERAL TERMS
            <br />
            <br />
            8.1 Governing Law. This Agreement and all matters arising out of or
            relating to this Agreement shall be governed by the internal laws of
            the Commonwealth of Massachusetts without giving effect to any
            choice of law rule. This Agreement shall not be governed by the
            United Nations Convention on Contracts for the International Sales
            of Goods, the application of which is expressly excluded. Each party
            hereby irrevocably consents to the exclusive jurisdiction and venue
            of the state and federal courts located in Middlesex County,
            Massachusetts in connection with any claim, action, suit, or
            proceeding relating to this Agreement, except that either party may
            seek injunctive, equitable or similar relief from any court of
            competent jurisdiction. Any claims of any nature whatsoever sought
            to be made by you with respect to the Services, including but not
            limited to any product liability or intellectual property
            infringement claims, shall be solely addressed to Cloud Canaries,
            and not Apple, and Cloud Canaries shall be solely responsible for
            the investigation, defense, settlement and discharge of any such
            claims.
            <br />
            <br />
            8.2 Severability and Waiver. If any provision of this Agreement is
            held to be illegal, invalid or otherwise unenforceable, such
            provision will be enforced to the extent possible consistent with
            the stated intention of the parties, or, if incapable of such
            enforcement, will be deemed to be severed and deleted from this
            Agreement, while the remainder of this Agreement will continue in
            full force and effect. The waiver by either party of any default or
            breach of this Agreement will not constitute a waiver of any other
            or subsequent default or breach.
            <br />
            <br />
            8.3 No Assignment. You may not assign, sell, transfer, delegate or
            otherwise dispose of, whether voluntarily or involuntarily, by
            operation of law or otherwise, this Agreement or any rights or
            obligations under this Agreement without the prior written consent
            of Cloud Canaries which may be withheld in Cloud Canaries's
            discretion. Any purported assignment, transfer or delegation by you
            shall be null and void. Subject to the foregoing, this Agreement
            shall be binding upon and shall inure to the benefit of the parties
            and their respective successors and assigns.
            <br />
            <br />
            8.4 Legal Compliance; Export Administration; and Government Users.
            By accepting this Agreement you represent and warrant that you (i)
            are not located in a jurisdiction that is subject to a U.S.
            government embargo, or that has been designated by the U.S.
            government as a terrorist supporting country, (ii) you are not
            listed on any U.S. government list of prohibited or restricted
            parties, and (iii) you will comply fully with all relevant export
            laws and regulations of the United States, including, without
            limitation, the U.S. Export Administration Regulations (collectively
            Export Controls). Without limiting the generality of the foregoing,
            you will not, and you will require your representatives not to,
            export, direct or transfer any Content, to any destination, person
            or entity restricted or prohibited by the Export Controls. If you
            are an agency or instrumentality of the United States Government,
            the Cloud Canaries software constitutes commercial computer software
            and the documentation constitutes commercial computer software
            documentation, and pursuant to FAR 12.212 or DFARS 227.7202, and
            their successors, as applicable, use, reproduction and disclosure of
            the Cloud Canaries software and documentation are governed by the
            terms of this Agreement. The manufacturer of the software for the
            Services is Cloud Canaries, Inc. 19 Arrow Street, Cambridge MA
            02138.
            <br />
            <br />
            8.5 Third Party Beneficiary. You and Cloud Canaries acknowledge and
            agree that Apple, and any of its subsidiaries, are third party
            beneficiaries of this Agreement, and upon your acceptance of the
            terms of this Agreement, Apple will have the right (and will be
            deemed to have accepted the right) to enforce this Agreement against
            you as a third party beneficiary hereunder.
            <br />
            <br />
            8.6 Injunctive Relief. You acknowledge and agree that a breach or
            threatened breach of any covenant contained in this Agreement would
            cause irreparable injury, that money damages would be an inadequate
            remedy and that Cloud Canaries shall be entitled to temporary and
            permanent injunctive relief, without the posting of any bond or
            other security, to restrain you, from such breach or threatened
            breach. Nothing in this Section 8.6 shall be construed as preventing
            Cloud Canaries from pursuing any and all remedies available to it,
            including the recovery of money damages from you.
            <br />
            <br />
            8.7 Entire Agreement. This Agreement constitutes the entire
            agreement between the parties and supersedes all prior or
            contemporaneous agreements or representations, written or oral,
            concerning the subject matter of this Agreement. This Agreement may
            not be modified or amended except in a writing signed by a duly
            authorized representative of each party; and no other act, document,
            usage or custom shall be deemed to amend or modify this Agreement.
            <br />
            <br />
            <br />
            <br />© {new Date().getFullYear()} Cloud Canaries Inc.
          </Box>
        </Grid>
      </DialogContent>
    </PublicPageContainer>
  )
}
