import ObjectFieldTemplate from 'components/MetricNotifications/ObjectFieldTemplate'
import {
  NotificationChannelsEnum,
  NotificationImpactEnum,
  NotificationPriorityEnum,
  NotificationUrgencyEnum,
  SimpleAlarmEvaluationStatusEnum
} from 'lib/CloudCanariesRestfulAPI'
import { FormConfigTypes } from './FormConfigsTypes'

export const configCreateEditNotification: FormConfigTypes = {
  schema: {
    type: 'object',
    required: ['name'],
    properties: {
      name: { type: 'string', title: 'Notification Name' },
      channel_and_from_to_status: {
        type: 'object',
        title: '',
        required: ['channels', 'transition_from', 'transition_to'],
        properties: {
          channels: {
            type: 'array',
            title: 'Channels',
            items: {
              type: 'string',
              enum: Object.values(NotificationChannelsEnum)
            },
            uniqueItems: true
          },
          transition_from: {
            type: 'string',
            title: 'From Status',
            enum: Object.values(SimpleAlarmEvaluationStatusEnum)
          },
          transition_to: {
            type: 'string',
            title: 'To Status',
            enum: Object.values(SimpleAlarmEvaluationStatusEnum)
          }
        }
      },
      urgency_impact_priority: {
        type: 'object',
        title: '',
        required: [],
        properties: {
          urgency: {
            type: 'string',
            title: 'Urgency',
            enum: Object.values(NotificationUrgencyEnum),
            default: NotificationUrgencyEnum.Low
          },
          impact: {
            type: 'string',
            title: 'Impact',
            enum: Object.values(NotificationImpactEnum),
            default: NotificationImpactEnum.Lowest
          },
          priority: {
            type: 'string',
            title: 'Priority',
            enum: Object.values(NotificationPriorityEnum),
            default: NotificationPriorityEnum.Lowest
          }
        }
      },
      summary_description: {
        type: 'object',
        title: '',
        properties: {
          summary: { type: 'string', title: 'Summary' },
          description: { type: 'string', title: 'Description' }
        }
      }
    }
  },
  uiSchema: {
    'ui:ObjectFieldTemplate': ObjectFieldTemplate,
    channel_and_from_to_status: {
      channels: {
        'ui:options': {
          inline: true,
          mulitLine: true
        }
      }
    },
    summary_description: {
      summary: {
        'ui:description':
          'Summary of the notification sent to the channel as the title.'
      },
      description: {
        'ui:options': {
          widget: 'textarea',
          rows: 10
        },
        'ui:description':
          'Descrption of the notification to describe what this notification is about.'
      }
    }
  }
}
