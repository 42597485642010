import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import RoutesService from 'services/routes'
import sharedNavBarStyles from './sharedNavBarStyles'

export default function PublicNav() {
  const classes = sharedNavBarStyles()
  const location = useLocation()

  const getNav = (l: string): string => l.split('/')[l.split('/').length - 1]
  const [nav, setNav] = useState<string>(getNav(location.pathname))

  useEffect(() => {
    setNav(getNav(location.pathname))
  }, [location])

  const isActiveLink = (nav: string, itemName: string): boolean =>
    nav === itemName

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        classes={{
          colorPrimary: classes.appBarRoot
        }}
        elevation={0}
      >
        <Toolbar>
          <div className={classes.linkContainer}>
            <a href={RoutesService.landingPage()} className={classes.mainLink}>
              <img
                className={classes.logo}
                src="/nav_bar_logo.png"
                alt="Cloud Canaries"
              />
            </a>
          </div>

          {/* <Link
            to={RoutesService.userRegister()}
            className={`${classes.button} ${
              isActiveLink(nav, 'register') ? classes.activeLink : ''
            }`}
          >
            Sign Up
          </Link> */}
          <Link
            to={RoutesService.userLogin()}
            className={`${classes.button} ${classes.signInButton} ${
              isActiveLink(nav, 'login') ? classes.activeLink : ''
            }`}
          >
            Sign In
          </Link>
        </Toolbar>
      </AppBar>
    </div>
  )
}
