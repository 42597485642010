import { CSSProperties } from 'react'
import Add from '@mui/icons-material/Add'
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
import ArrowLeft from '@mui/icons-material/ArrowLeft'
import ArrowRight from '@mui/icons-material/ArrowRight'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import CancelIcon from '@mui/icons-material/Cancel'
import Check from '@mui/icons-material/Check'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import Clear from '@mui/icons-material/Clear'
import Close from '@mui/icons-material/Close'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import DateRangeIcon from '@mui/icons-material/DateRange'
import Delete from '@mui/icons-material/Delete'
import DoneIcon from '@mui/icons-material/Done'
import Edit from '@mui/icons-material/Edit'
import ErrorIcon from '@mui/icons-material/Error'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import FilterList from '@mui/icons-material/FilterList'
import LocalLibraryIcon from '@mui/icons-material/FolderZipOutlined'
import HistoryIcon from '@mui/icons-material/History'
import HourglassEmptyOutlined from '@mui/icons-material/HourglassEmptyOutlined'
import LaunchIcon from '@mui/icons-material/LaunchRounded'
import LinkOffIcon from '@mui/icons-material/LinkOff'
import Lock from '@mui/icons-material/Lock'
import NotificationsNoneOutlined from '@mui/icons-material/NotificationsNoneOutlined'
import Person from '@mui/icons-material/Person'
import PlayArrow from '@mui/icons-material/PlayArrow'
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined'
import SearchRounded from '@mui/icons-material/SearchRounded'
import SettingsIcon from '@mui/icons-material/Settings'
import ShowChartIcon from '@mui/icons-material/ShowChart'
import Stop from '@mui/icons-material/Stop'
import VisibilityIcon from '@mui/icons-material/Visibility'
import WarningIcon from '@mui/icons-material/Warning'
import { SvgIconPropsSizeOverrides } from '@mui/material'
import { OverridableStringUnion } from '@mui/types'
import ClusterIcon from 'components/Icons/ClusterIcon'
import DockerIcon from 'components/Icons/DockerIcon'
import LockIcon from 'components/Icons/LockIcon'
import OpenLockIcon from 'components/Icons/OpenLockIcon'
import SnowflakeIcon from 'components/Icons/SnowflakeIcon'

export enum IconType {
  None,
  Add,
  Analytics,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  ArrowDown,
  ArrowDropDown,
  Cancel,
  Check,
  ChevronLeftIcon,
  Clear,
  Clock,
  ClusterIcon,
  DateRange,
  DockerIcon,
  Download,
  Done,
  Edit,
  Error,
  ExpandMoreIcon,
  FileCopy,
  FilterList,
  History,
  LaunchIcon,
  LocalLibraryIcon,
  Lock,
  LockIcon,
  LockOpenIcon,
  Notifications,
  PlayArrow,
  Refresh,
  Revoke,
  SearchRounded,
  Settings,
  ShowChart,
  SnowflakeIcon,
  StateClear,
  Stop,
  Trash,
  Upload,
  User,
  View,
  Warning,
  X
}

interface IconProps {
  name: IconType
  color?:
    | 'inherit'
    | 'disabled'
    | 'action'
    | 'primary'
    | 'secondary'
    | 'error'
    | undefined
  fontSize?:
    | OverridableStringUnion<
        'inherit' | 'small' | 'large' | 'medium',
        SvgIconPropsSizeOverrides
      >
    | undefined
  onClick?: () => void
  className?: string
  style?: CSSProperties
  disabled?: boolean
}

export default function Icon(props: IconProps) {
  const { name, ...other } = props
  // alphabetical
  switch (name) {
    case IconType.Add:
      return <Add {...other} />
    case IconType.Analytics:
      return <AnalyticsOutlinedIcon {...other} />
    case IconType.ArrowLeft:
      return <ArrowLeft {...other} />
    case IconType.ArrowRight:
      return <ArrowRight {...other} />
    case IconType.ArrowUp:
      return <ArrowUpwardIcon {...other} />
    case IconType.ArrowDown:
      return <ArrowDownwardIcon {...other} />
    case IconType.ArrowDropDown:
      return <ArrowDropDown {...other} />
    case IconType.Cancel:
      return <CancelIcon {...other} />
    case IconType.Check:
      return <Check {...other} />
    case IconType.ChevronLeftIcon:
      return <ChevronLeftIcon {...other} />
    case IconType.Clear:
      return <Clear {...other} />
    case IconType.Clock:
      return <HourglassEmptyOutlined {...other} />
    case IconType.ClusterIcon:
      return <ClusterIcon {...other} />
    case IconType.DateRange:
      return <DateRangeIcon {...other} />
    case IconType.DockerIcon:
      return <DockerIcon {...other} />
    case IconType.Download:
      return <CloudDownloadIcon {...other} />
    case IconType.Done:
      return <DoneIcon {...other} />
    case IconType.Edit:
      return <Edit {...other} />
    case IconType.Error:
      return <ErrorIcon {...other} />
    case IconType.ExpandMoreIcon:
      return <ExpandMoreIcon {...other} />
    case IconType.FileCopy:
      return <FileCopyIcon {...other} />
    case IconType.FilterList:
      return <FilterList {...other} />
    case IconType.History:
      return <HistoryIcon {...other} />
    case IconType.LaunchIcon:
      return <LaunchIcon {...other} />
    case IconType.LocalLibraryIcon:
      return <LocalLibraryIcon {...other} />
    case IconType.Lock:
      return <Lock {...other} />
    case IconType.LockIcon:
      return <LockIcon {...other} />
    case IconType.LockOpenIcon:
      return <OpenLockIcon {...other} />
    case IconType.Notifications:
      return <NotificationsNoneOutlined {...other} />
    case IconType.PlayArrow:
      return <PlayArrow {...other} />
    case IconType.Refresh:
      return <RefreshOutlinedIcon {...other} />
    case IconType.Revoke:
      return <LinkOffIcon {...other} />
    case IconType.SearchRounded:
      return <SearchRounded {...other} />
    case IconType.Settings:
      return <SettingsIcon {...other} />
    case IconType.ShowChart:
      return <ShowChartIcon {...other} />
    case IconType.SnowflakeIcon:
      return <SnowflakeIcon {...other} />
    case IconType.StateClear:
      return <CheckCircleIcon {...other} />
    case IconType.Stop:
      return <Stop {...other} />
    case IconType.Trash:
      return <Delete {...other} />
    case IconType.Warning:
      return <WarningIcon {...other} />
    case IconType.X:
      return <Close {...other} />
    case IconType.View:
      return <VisibilityIcon {...other} />
    case IconType.User:
      return <Person {...other} />
    case IconType.Upload:
      return <CloudUploadIcon {...other} />
    default:
      return null
  }
}
