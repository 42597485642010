import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { CanaryStatusEnum } from 'lib/CloudCanariesRestfulAPI'
import { userLogout } from './auth'
import type { RootState } from '../store'

export interface CanaryDashboardState {
  selectedCanaryStatus: CanaryStatusEnum | ''
  searchText: string
  rowsPerPageNumber: number
}

const initialState: CanaryDashboardState = {
  selectedCanaryStatus: '',
  searchText: '',
  rowsPerPageNumber: 10
}

export const canaryDashboardSlice = createSlice({
  name: 'canaryDashboard',
  initialState,
  reducers: {
    setSelectedCanaryStatus: (
      state,
      action: PayloadAction<CanaryStatusEnum | ''>
    ) => {
      state.selectedCanaryStatus = action.payload
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload
    },
    setRowsPerPageNumber: (state, action: PayloadAction<number>) => {
      state.rowsPerPageNumber = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(userLogout, (state, action) => {
      state.rowsPerPageNumber = 10
      state.searchText = ''
      state.selectedCanaryStatus = ''
    })
  }
})

// Action creators are generated for each case reducer function
export const { setSelectedCanaryStatus, setSearchText, setRowsPerPageNumber } =
  canaryDashboardSlice.actions

export default canaryDashboardSlice.reducer

export const selectedCanaryStatus = (state: RootState) =>
  state.canaryDashboard.selectedCanaryStatus

export const selectSearchText = (state: RootState) =>
  state.canaryDashboard.searchText

export const selectRowsPerPageNumber = (state: RootState) =>
  state.canaryDashboard.rowsPerPageNumber
