import CircularProgress from '@mui/material/CircularProgress'
import { makeStyles } from '@mui/styles'
import Box from '@mui/system/Box'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '2rem',
    paddingTop: '5rem',
    top: '0',
    left: '0',
    right: '0',
    '& > * + *': {
      marginLeft: 16
    }
  }
})

interface PageLoaderProps {
  isTableLoader?: boolean
}

export default function PageLoader(props: PageLoaderProps) {
  const { isTableLoader } = props
  const classes = useStyles()
  return (
    <Box
      className={classes.root}
      sx={{ position: isTableLoader ? 'absolute' : 'relative' }}
    >
      <CircularProgress size="5rem" />
    </Box>
  )
}
