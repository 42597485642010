import { useContext, useState } from 'react'
import Button from '@mui/material/Button'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Icon, { IconType } from 'components/Icons'
import DialogModal from 'components/Modals/DialogModal'
import { ClustersContext } from 'contexts/ClustersContext'
import { KubernetesCluster } from 'lib/CloudCanariesRestfulAPI'

interface UserOrganizationClusterViewSecretModalProps {
  cluster: KubernetesCluster
}

export default function UserOrganizationClusterViewSecretModal(
  props: UserOrganizationClusterViewSecretModalProps
) {
  const { cluster } = props

  const { isStaff } = useContext(ClustersContext)

  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  if (cluster.is_internal && !isStaff) {
    return <Icon name={IconType.Lock} color="disabled" />
  } else {
    return (
      <>
        <Button onClick={handleClickOpen} color="primary">
          <Icon name={IconType.FilterList} style={{ marginRight: '0.5rem' }} />{' '}
          View
        </Button>
        <DialogModal
          open={open}
          onClose={handleClose}
          dialogTitle="Image Registry Secret"
          dialogContent={
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell
                    sx={{
                      fontSize: 14,
                      fontWeight: 700
                    }}
                  >
                    Secret Name
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: 14,
                      fontWeight: 700
                    }}
                  >
                    {cluster.kube_image_secret_name}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Server</TableCell>
                  <TableCell>{cluster.kube_image_server}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>UserName</TableCell>
                  <TableCell>{cluster.kube_image_username}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>{cluster.kube_image_email}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Password</TableCell>
                  <TableCell>{cluster.kube_image_password}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          }
        />
      </>
    )
  }
}
