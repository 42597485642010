import parse from 'html-react-parser'
import { useContext, useState } from 'react'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Icon, { IconType } from 'components/Icons'
import DialogModal from 'components/Modals/DialogModal'
import ModalSharedStyles from 'components/Modals/ModalSharedStyles'
import { OrganizationsContext } from 'contexts/OrganizationsContext'
import { ServiceSettingsGroupContext } from 'contexts/ServiceSettingsGroupContext'
import { ServiceHealthEvaluationSettingsGroup } from 'lib/CloudCanariesRestfulAPI'

interface DeleteModalProps {
  settingsGroup: ServiceHealthEvaluationSettingsGroup
}

export default function DeleteModal(props: DeleteModalProps) {
  const classes = ModalSharedStyles()
  const { settingsGroup } = props

  const { organizationId } = useContext(OrganizationsContext)
  const { deleteSettingsGroup } = useContext(ServiceSettingsGroupContext)

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)
  const [warningText, setWarningText] = useState(
    `Do you really want to delete "${settingsGroup.name!}"?`
  )

  const handleClickOpen = () => {
    if (settingsGroup.is_default) {
      setWarningText(
        `This is the default <b>Service Settings Group</b> for <b>"${
          settingsGroup.organization_name
        }"</b>. Do you really want to delete "${settingsGroup.name!}"?`
      )
    }
    setDeleteConfirmOpen(true)
  }

  const handleClose = () => {
    setDeleteConfirmOpen(false)
  }

  const confirmDelete = () => {
    deleteSettingsGroup(settingsGroup.id!, organizationId!)
  }

  return (
    <>
      <IconButton size="small" onClick={handleClickOpen}>
        <Icon name={IconType.Trash} color="error" />
      </IconButton>
      <DialogModal
        open={deleteConfirmOpen}
        onClose={handleClose}
        dialogTitle="Delete Service Settings Group"
        dialogContent={
          <Typography variant="body1" align="center">
            {parse(warningText)}
          </Typography>
        }
        dialogActions={
          <>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              onClick={confirmDelete}
              variant="contained"
              className={classes.deleteButton}
              autoFocus
            >
              Delete
            </Button>
          </>
        }
      />
    </>
  )
}
