import { Theme } from '@mui/material/styles'
import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles(({ spacing }: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    appBarRoot: {
      backgroundColor: '#f0f8ff',
      boxShadow: 'inset 0px -1px 1px #E7EBF0'
    },
    menuButton: {
      marginRight: spacing(2)
    },
    linkContainer: {
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center',
      color: '#000000'
    },
    mainLink: {
      display: 'inline-flex',
      alignItems: 'center',
      color: '#48A2F4',
      fontSize: '1.25rem',
      fontWeight: 500,
      lineHeight: 1.6,
      letterSpacing: '0.0075em',
      textDecoration: 'none',
      margin: 0,
      '& > *': {
        margin: '0.25em 0.75rem'
      }
    },
    logo: {
      width: 180,
      height: 60,
      marginRight: '0.75rem'
    },
    button: {
      padding: spacing(0.75),
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: 1.5,
      letterSpacing: 0,
      textDecoration: 'none',
      borderRadius: 10,
      color: '#48A2F4',
      margin: '0 0.25rem',
      textTransform: 'capitalize',
      '&:hover': {
        backgroundColor: '#dfe6ed',
        color: '#48A2F4CC'
      }
    },
    signInButton: {
      marginLeft: '1rem'
    },
    activeLink: {
      backgroundColor: '#dfe6ed',
      fontWeight: 600
    },
    companyName: {
      fontSize: '1.5rem',
      fontWeight: 700,
      marginLeft: '0.5rem',
      marginRight: '0.75rem'
    },
    userName: {
      textTransform: 'capitalize',
      fontWeight: 500,
      fontSize: '0.875rem',
      marginRight: '0.25rem'
    },
    menuLink: {
      textDecoration: 'none',
      fontWeight: 500,
      color: '#48A2F4',
      '&:hover': {
        color: '#48A2F4CC'
      }
    },
    menuLinkSelected: {
      backgroundColor: '#dfe6ed',
      fontWeight: 600
    },
    popover: {
      pointerEvents: 'none'
    },
    paper: {
      pointerEvents: 'auto'
    }
  })
)
