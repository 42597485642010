import styled from 'styled-components'

export const UserProfileStyle = styled.div<{ $editingMode: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  .Inner {
    max-width: 50%;
    .EditContainer {
      visibility: ${(props) => (props.$editingMode ? 'hidden' : 'visible')};
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: flex-end;
      padding: 1rem;
    }

    .SaveAndCancelContainer {
      visibility: ${(props) => (props.$editingMode ? 'visible' : 'hidden')};
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 1rem;
      .SaveButton {
        background-color: #007bff;
        color: white;
        padding: 5px 15px;
      }
      .CancelButton {
        background-color: #ff3a3a;
        color: white;
        padding: 5px 15px;
      }
    }

    .UserProfileForm {
      color: rgb(140, 140, 140);
      background-color: rgb(255, 255, 255);
      padding: 1rem;
    }

    .ResetPasswordContainer {
      padding-top: 10px;
      padding-bottom: 80px;
    }

    .ResetPasswordButton {
      background-color: #000000;
      color: white;
      margin-left: 8px;
    }
  }
`
