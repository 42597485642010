import { userImpersonateSetup } from 'client_side_state/slices/auth'
import { useAppDispatch } from 'client_side_state/store'
import { useContext, useEffect } from 'react'
import { RouteComponentProps } from 'react-router'
import { useHistory } from 'react-router-dom'
import { AuthContext } from 'contexts/AuthContext'
import RoutesService from 'services/routes'

interface MatchParams {
  user_id: string
  token: string
  company: string
  is_first_time_user: string
  use_platform_clusters: string
  is_temp_password: string
}

interface ImpersonateMatchProps extends RouteComponentProps<MatchParams> {}

export default function ImpersonateUser(props: ImpersonateMatchProps) {
  const history = useHistory()

  const dispatch = useAppDispatch()
  const { impersonateUser } = useContext(AuthContext)
  const userId = props.match.params.user_id
  const userToken = props.match.params.token
  const companyId = props.match.params.company
  const isFirstTimeUser = props.match.params.is_first_time_user
  const usePlatformClusters = props.match.params.use_platform_clusters
  const isTempPassword = props.match.params.is_temp_password

  useEffect(() => {
    dispatch(userImpersonateSetup())
  }, [dispatch])

  useEffect(() => {
    console.log('usePlatformClusters', usePlatformClusters)
    impersonateUser(
      userId,
      userToken,
      companyId,
      isFirstTimeUser,
      usePlatformClusters,
      isTempPassword
    )
  }, [
    impersonateUser,
    userId,
    userToken,
    companyId,
    isFirstTimeUser,
    usePlatformClusters,
    isTempPassword
  ])

  useEffect(() => {
    history.push({
      pathname: RoutesService.userLoggedInInterstitial()
    })
  }, [history])

  return (
    <div>
      <h1>UserID: {userId}</h1>
      <h1>Token: {userToken}</h1>
      <h1>CompanyID: {companyId}</h1>
    </div>
  )
}
