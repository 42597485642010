import {
  setOrganizationRequiredModalMessage,
  setOrganizationRequiredModalOpen
} from 'client_side_state/slices/app'
import { useAppDispatch } from 'client_side_state/store'
import { useContext, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import CanaryTemplatesDataGrid from 'components/AutoLoadCanaryTemplatesModal/CanaryTemplatesDataGrid'
import SkipAutoLoadCanaryTemplatesButton from 'components/AutoLoadCanaryTemplatesModal/SkipAutoLoadCanaryTemplatesButton'
import DialogModal from 'components/Modals/DialogModal'
import OrganizationRequiredDialog, {
  OrganizationRequiredMessage
} from 'components/UserOrganization/OrganizationRequiredDialog'
import AuoLoadCanaryTemplatesLoader from 'components/common/Loaders/AuoLoadCanaryTemplatesLoader'
import { ToastErrors } from 'constants/toast_errors'
import { ToastSuccessMessages } from 'constants/toast_success_messages'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { AutoLoadCanaryTemplatesContext } from 'contexts/AutoLoadCanaryTemplatesContext'
import { OrganizationsContext } from 'contexts/OrganizationsContext'

export default function CanaryTemplatesAutoLoader() {
  const dispatch = useAppDispatch()

  const { apiService } = useContext(APIServiceContext)
  const { organizationId, organization } = useContext(OrganizationsContext)
  const { selectedCanaryTemplates } = useContext(AutoLoadCanaryTemplatesContext)

  const [isLoadingCanaryTemplates, setIsLoadingCanaryTemplates] =
    useState<boolean>(false)

  const [open, setOpen] = useState<boolean>(false)

  const skipButtonRef = useRef<{
    setDeleteConfirmOpen: (open: boolean) => void
  }>(null)

  const handleClickOpen = () => {
    if (!organizationId) {
      dispatch(
        setOrganizationRequiredModalMessage(
          OrganizationRequiredMessage.TemplateLibraryAutoCanaryLoader
        )
      )
      dispatch(setOrganizationRequiredModalOpen(true))
    } else {
      setOpen(true)
    }
  }

  const handleClose = async (skipCancel?: boolean) => {
    setOpen(false)
  }
  const delay = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms))

  const onLoadCanaryTemplateLibraries = async () => {
    if (selectedCanaryTemplates.length > 0) {
      setIsLoadingCanaryTemplates(true)
      let canariesAutoLoaded = 0

      await Promise.all(
        selectedCanaryTemplates.map(async (template, index) => {
          await delay(index * 750)
          await apiService
            .loadTemplateLibrariesStoreCanary(template.toString(), {
              id: template.toString(),
              organization_id: organizationId
            })
            .then((json) => {
              if (json.status === 200) {
                canariesAutoLoaded += 1
              }
            })
        })
      )
      if (canariesAutoLoaded === selectedCanaryTemplates.length) {
        setIsLoadingCanaryTemplates(false)
        setOpen(false)
        toast.success(
          ToastSuccessMessages.SUCCESS_AUTO_LOAD_CANARY_TEMPLATE_LIBRARIES,
          {
            position: 'top-right'
          }
        )
      } else {
        setIsLoadingCanaryTemplates(false)
        setOpen(false)
        toast.error(ToastErrors.ERROR_AUTOLOAD_CANARY_TEMPLATES, {
          position: 'top-right'
        })
      }
    } else {
      setIsLoadingCanaryTemplates(false)
      setOpen(false)
      toast.warn(ToastErrors.WARN_SELECT_CANARY_TEMPLATES, {
        position: 'top-right'
      })
    }
  }

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        sx={{ textTransform: 'capitalize', borderRadius: 1, m: 1, mr: 0 }}
      >
        More Template Libraries
      </Button>

      {open && organization && organization.id ? (
        <DialogModal
          maxWidth="xl"
          open={open}
          sx={{
            p: 3,
            '.MuiDialog-paper': {
              width: '60vw'
            }
          }}
          onClose={handleClose}
          dialogTitle="Auto load sample Canary Template Libraries"
          dialogContent={
            <>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  pr: 3,
                  pl: 1
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    mb: 2,
                    justifyContent: 'space-between'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}
                  >
                    <Typography fontSize={20} fontWeight={500} sx={{ mr: 1 }}>
                      Organization:
                    </Typography>
                    <Typography fontSize={20} fontWeight={500}>
                      {organization?.name}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {isLoadingCanaryTemplates ? (
                <AuoLoadCanaryTemplatesLoader />
              ) : (
                <CanaryTemplatesDataGrid />
              )}
            </>
          }
          dialogActions={
            <>
              <SkipAutoLoadCanaryTemplatesButton
                afterSubmit={handleClose}
                ref={skipButtonRef}
                skip={true}
              />
              <Button
                onClick={onLoadCanaryTemplateLibraries}
                variant="outlined"
                autoFocus
                sx={{
                  textTransform: 'capitalize'
                }}
              >
                Load
              </Button>
            </>
          }
        />
      ) : (
        <OrganizationRequiredDialog />
      )}
    </>
  )
}
