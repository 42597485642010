import localforage from 'localforage'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore
} from 'redux-persist'
import { configureStore } from '@reduxjs/toolkit'
import { APIServiceContextProps } from 'contexts/APIServiceContext'
import rootReducer from './slices/root'

const pkg = require('../../package.json')

let localforageStorage = localforage.createInstance({ name: 'dev-storage' })

switch (pkg.environment) {
  case 'prod':
    localforageStorage = localforage.createInstance({
      name: 'prod-storage'
    })
    break
  case 'staging':
    localforageStorage = localforage.createInstance({ name: 'staging-storage' })
    break
  case 'testing':
    localforageStorage = localforage.createInstance({ name: 'testing-storage' })
    break
  default:
    localforageStorage = localforage.createInstance({ name: 'dev-storage' })
    break
}

const persistConfig = {
  key: 'root',
  storage: localforageStorage,
  blacklist: [],
  transforms: [],
  timeout: 5000
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export function configureAppStore(
  apiServiceContextValue: APIServiceContextProps
) {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: apiServiceContextValue
        },
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
        }
      })
  })

  const persistor = persistStore(store)
  return { store, persistor }
}

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = ReturnType<
  typeof configureAppStore
>['store']['dispatch']

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
