import {
  setOrganizationRequiredModalMessage,
  setOrganizationRequiredModalOpen
} from 'client_side_state/slices/app'
import { useAppDispatch } from 'client_side_state/store'
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import isEqual from 'lodash/isEqual'
import modalsSharedStyles from 'components/DashboardsModals/modalsSharedStyles'
import DialogModal from 'components/Modals/DialogModal'
import OrganizationRequiredDialog, {
  OrganizationRequiredMessage
} from 'components/UserOrganization/OrganizationRequiredDialog'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { ComplianceContext } from 'contexts/ComplianceContext'
import { OrganizationsContext } from 'contexts/OrganizationsContext'
import { ServiceComplianceColors } from 'lib/CloudCanariesRestfulAPI'
import { ColorPicker, PropertyName } from 'styles/pages/HealthCheck.styled'
import { getMappedColorNames } from './utils'

interface ComplianceColors {
  [key: string]: string
}

export default function SettingsModal() {
  const classes = modalsSharedStyles()
  const dispatch = useAppDispatch()

  const { apiService } = useContext(APIServiceContext)
  const { organizationId, organization } = useContext(OrganizationsContext)

  const {
    serviceComplianceColors,
    serviceComplianceColorsSettings,
    fetchBaseServiceComplianceColors
  } = useContext(ComplianceContext)

  const [localServiceComplianceColors, setLocalServiceComplianceColors] =
    useState<ServiceComplianceColors>(
      serviceComplianceColors! || serviceComplianceColorsSettings[0]!
    )
  const [baseBarState, setBaseBarState] = useState<ComplianceColors>({
    GoodMeetingOrHasMetSLATarget:
      localServiceComplianceColors!.bar_meeting_sla!,
    WarningPossibleRiskToOutOfSLAComplianceWithin5:
      localServiceComplianceColors!
        .bar_missing_sla_with_less_50_period_completed!,
    AlarmOutOfCompliance:
      localServiceComplianceColors!
        .bar_missing_sla_with_more_50_period_completed!
  })

  const [barState, setBarState] = useState<ComplianceColors>(baseBarState)
  const [colorsChanged, setColorsChanged] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)

  const fetchServiceComplianceColors = useCallback(async () => {
    await apiService
      .retrieveServiceComplianceColors(localServiceComplianceColors.id!)
      .then((json) => {
        setLocalServiceComplianceColors(json.data)
      })
  }, [apiService, localServiceComplianceColors.id])

  useEffect(() => {
    fetchServiceComplianceColors()
  }, [fetchServiceComplianceColors])

  useEffect(() => {
    setBaseBarState({
      GoodMeetingOrHasMetSLATarget:
        localServiceComplianceColors!.bar_meeting_sla!,
      WarningPossibleRiskToOutOfSLAComplianceWithin5:
        localServiceComplianceColors!
          .bar_missing_sla_with_less_50_period_completed!,
      AlarmOutOfCompliance:
        localServiceComplianceColors!
          .bar_missing_sla_with_more_50_period_completed!
    })
  }, [localServiceComplianceColors])

  useEffect(() => {
    if (serviceComplianceColors) {
      setLocalServiceComplianceColors(serviceComplianceColors)
    }
  }, [serviceComplianceColors])

  const hasUnsavedChanges = useMemo<boolean>(
    () => colorsChanged || !isEqual(barState, baseBarState),
    [barState, baseBarState, colorsChanged]
  )

  const checkUnsavedChanges = (): boolean =>
    !hasUnsavedChanges ||
    window.confirm('You have unsaved changes. Are you sure you want to leave?')

  const toggleDialogOpen = () => {
    if (!organizationId) {
      dispatch(
        setOrganizationRequiredModalMessage(
          OrganizationRequiredMessage.ServiceComplianceStatusColors
        )
      )
      dispatch(setOrganizationRequiredModalOpen(true))
    } else {
      if (!checkUnsavedChanges()) return
      setBarState(baseBarState)
      setColorsChanged(false)
      setOpen(!open)
    }
  }

  const handleSeverityColorsChange = (
    event: ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    const value = event.target.value
    setBarState((prev) => ({ ...prev, [key]: value }))
    setColorsChanged(true)
  }

  const SubmitColorsChange = async () => {
    const updatedServiceComplianceColors: ServiceComplianceColors = {
      bar_meeting_sla: barState.GoodMeetingOrHasMetSLATarget,
      bar_missing_sla_with_less_50_period_completed:
        barState.WarningPossibleRiskToOutOfSLAComplianceWithin5,
      bar_missing_sla_with_more_50_period_completed:
        barState.AlarmOutOfCompliance
    }
    await apiService
      .partialUpdateServiceComplianceColors(
        localServiceComplianceColors.id!,
        localServiceComplianceColors.organization_id!,
        updatedServiceComplianceColors
      )
      .then((json) => {
        if (json.status === 200) {
          fetchBaseServiceComplianceColors()
          setColorsChanged(false)
        }
      })
  }

  return (
    <>
      <Button
        variant="outlined"
        onClick={toggleDialogOpen}
        className={classes.mainButton}
      >
        Status Colors
      </Button>
      {organization && organization.id ? (
        <DialogModal
          onClose={toggleDialogOpen}
          fullWidth
          maxWidth="md"
          open={open}
          dialogTitle="Service Status Colors"
          dialogContent={
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.sectionTitle}>
                  SLA Bar
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {Object.keys(barState).map((key) => {
                  const barName = getMappedColorNames(key)
                  return (
                    <Grid key={key} container>
                      <Grid
                        item
                        xs={6}
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <ColorPicker
                          type={'color'}
                          value={barState[key]}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleSeverityColorsChange(e, key)
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <PropertyName>{barName}</PropertyName>
                      </Grid>
                    </Grid>
                  )
                })}
              </Grid>
            </Grid>
          }
          dialogActions={
            <>
              {hasUnsavedChanges && <span>You have unsaved changes</span>}
              <Button
                disabled={!hasUnsavedChanges}
                onClick={SubmitColorsChange}
                variant="outlined"
                classes={{
                  outlined: classes.saveBtn
                }}
              >
                Save
              </Button>
            </>
          }
        />
      ) : (
        <OrganizationRequiredDialog />
      )}
    </>
  )
}
