import { useContext } from 'react'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { CanariesContext } from 'contexts/CanariesContext'
import { CanaryStatusEnum } from 'lib/CloudCanariesRestfulAPI'

export default function PageFilterCanaryStatus() {
  const { canaryStatusValueSelected, changeCanaryStatusValueSelected } =
    useContext(CanariesContext)

  const options = Array.from(Object.values(CanaryStatusEnum))

  const onChange = (event: SelectChangeEvent) => {
    const newlySelectedValue = String(event.target.value) as CanaryStatusEnum
    changeCanaryStatusValueSelected(newlySelectedValue)
  }

  return (
    <FormControl size="small">
      <InputLabel id="select-helper-label-page-filter-canary-status">
        {Boolean(canaryStatusValueSelected) ? null : null}
      </InputLabel>
      <Select
        style={{ minWidth: '240px' }}
        labelId="select-helper-label-page-filter-canary-status"
        id="select-helper-page-filter-canary-status"
        value={canaryStatusValueSelected}
        onChange={onChange}
        autoWidth
        displayEmpty={true}
      >
        <MenuItem value="">
          <em>All Statuses</em>
        </MenuItem>
        {options.map((o) => {
          return (
            <MenuItem key={o} value={String(o)}>
              {String(o)}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}
