import {
  selectSLAPeriodCategory,
  selectServiceProviderSelected,
  setServiceProviderOptions
} from 'client_side_state/slices/pageFilter'
import {
  selectSelectedServiceComplianceId,
  selectSelectedServiceComplianceName,
  selectSelectedServiceComplianceSummaryEvaluationId,
  setSelectedServiceComplianceID,
  setSelectedServiceComplianceNAME,
  setSelectedServiceComplianceSummaryEvaluationsId
} from 'client_side_state/slices/serviceCompliance'
import { useAppDispatch, useAppSelector } from 'client_side_state/store'
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { queryParamPageSizeJumbo } from 'constants/AppConfig'
import {
  CanaryReadWriteMinimal,
  ServiceComplianceColors,
  ServiceComplianceSummary
} from 'lib/CloudCanariesRestfulAPI'
import DateTimeService from 'services/DateTimeService'
import { deDuplicateServiceProviderOptions } from 'services/utils'
import { APIServiceContext } from './APIServiceContext'
import { OrganizationsContext } from './OrganizationsContext'

interface ComplianceContextProviderProps {
  children: ReactNode
}

interface ComplianceContextProps {
  areServiceSummaryAndColorsLoading: boolean
  filteredSummaryEvaluations: ServiceComplianceSummary[]
  serviceComplianceColorsSettings: ServiceComplianceColors[]
  serviceComplianceColors: ServiceComplianceColors
  filteredCanaries: CanaryReadWriteMinimal[]
  selectedServiceId: string
  selectedServiceName: string
  selectedSummaryEvaluationId: string
  areCanariesLoading: boolean
  fetchBaseServiceComplianceColors: () => Promise<void>
  updateSelectedServiceId: (id: string) => void
  updateSelectedServiceName: (name: string) => void
  updateSelectedServiceSummaryEvaluationId: (id: string) => void
  resetSelectedService: () => void
}
export const ComplianceContext = createContext<ComplianceContextProps>(
  null as any
)
const page = undefined

export default function ComplianceContextProvider(
  props: ComplianceContextProviderProps
) {
  const { children } = props

  const dispatch = useAppDispatch()
  const defaults = DateTimeService.getDefaultDateTimes()
  const stop = defaults.dateStop

  const selectedPeriodCategory = useAppSelector(selectSLAPeriodCategory)
  const selectedProviderID = useAppSelector(selectServiceProviderSelected)
  const selectedServiceComplianceID = useAppSelector(
    selectSelectedServiceComplianceId
  )
  const selectedServiceComplianceNAME = useAppSelector(
    selectSelectedServiceComplianceName
  )
  const selectedServiceComplianceSummaryEvaluationId = useAppSelector(
    selectSelectedServiceComplianceSummaryEvaluationId
  )

  const { apiService } = useContext(APIServiceContext)
  const { organizationId } = useContext(OrganizationsContext)

  const [
    areServiceSummaryAndColorsLoading,
    setAreServiceSummaryAndColorsLoading
  ] = useState<boolean>(true)
  const [areCanariesLoading, setAreCanariesLoading] = useState<boolean>(true)

  const [serviceSummaryEvaluations, setServiceSummaryEvaluations] = useState<
    ServiceComplianceSummary[]
  >([])
  const [serviceComplianceColorsSettings, setServiceComplianceColorsSettings] =
    useState<ServiceComplianceColors[]>([])
  const [canaries, setCanaries] = useState<CanaryReadWriteMinimal[]>([])

  const [selectedServiceId, setSelectedServiceId] = useState<string>(
    selectedServiceComplianceID
  )
  const [selectedServiceName, setSelectedServiceName] = useState<string>(
    selectedServiceComplianceNAME
  )
  const [selectedSummaryEvaluationId, setSelectedSummaryEvaluationId] =
    useState<string>(selectedServiceComplianceSummaryEvaluationId)

  const [serviceComplianceColors, setServiceComplianceColors] =
    useState<ServiceComplianceColors>(serviceComplianceColorsSettings[0]!)

  // API calls
  const fetchServiceComplianceSummariesAndColors = useCallback(async () => {
    setAreServiceSummaryAndColorsLoading(true)
    const [serviceComplianceSummaries, serviceComplianceColors] =
      await Promise.all([
        apiService.listServiceComplianceSummaries(
          page,
          queryParamPageSizeJumbo,
          organizationId,
          selectedPeriodCategory,
          stop,
          stop
        ),
        apiService.listServiceComplianceColors(organizationId)
      ])
    setServiceSummaryEvaluations(serviceComplianceSummaries.data.results ?? [])
    setServiceComplianceColorsSettings(serviceComplianceColors.data ?? [])
    setAreServiceSummaryAndColorsLoading(false)
  }, [apiService, organizationId, selectedPeriodCategory, stop])

  const resetSelectedService = useCallback(() => {
    setSelectedServiceId('')
    setSelectedServiceName('')
    setSelectedSummaryEvaluationId('')
    dispatch(setSelectedServiceComplianceID(''))
    dispatch(setSelectedServiceComplianceNAME(''))
  }, [dispatch])

  const fetchCanaries = useCallback(async () => {
    setAreCanariesLoading(true)
    await apiService
      .listCanaries(page, queryParamPageSizeJumbo, organizationId)
      .then((json) => {
        setCanaries(json.data.results ?? [])
        setAreCanariesLoading(false)
      })
  }, [apiService, organizationId])

  const fetchBaseServiceComplianceColors = useCallback(async () => {
    if (serviceComplianceColorsSettings.length > 0) {
      await apiService
        .retrieveServiceComplianceColors(serviceComplianceColorsSettings[0].id!)
        .then((json) => {
          setServiceComplianceColors(json.data)
        })
    }
  }, [apiService, serviceComplianceColorsSettings])

  useEffect(() => {
    fetchServiceComplianceSummariesAndColors()
  }, [fetchServiceComplianceSummariesAndColors])

  useEffect(() => {
    const providerOptions = deDuplicateServiceProviderOptions(
      serviceSummaryEvaluations
    )
    dispatch(setServiceProviderOptions(providerOptions))
  }, [dispatch, serviceSummaryEvaluations])

  useEffect(() => {
    fetchCanaries()
  }, [fetchCanaries])

  useEffect(() => {
    resetSelectedService()
  }, [organizationId, resetSelectedService])

  useEffect(() => {
    if (selectedProviderID === '') {
      resetSelectedService()
    }
  }, [resetSelectedService, selectedProviderID])

  const updateSelectedServiceId = (id: string) => {
    setSelectedServiceId(id)
    dispatch(setSelectedServiceComplianceID(id))
  }
  const updateSelectedServiceName = (name: string) => {
    setSelectedServiceName(name)
    dispatch(setSelectedServiceComplianceNAME(name))
  }
  const updateSelectedServiceSummaryEvaluationId = (id: string) => {
    setSelectedSummaryEvaluationId(id)
    dispatch(setSelectedServiceComplianceSummaryEvaluationsId(id))
  }

  const filteredSummaryEvaluations = useMemo(() => {
    let sortedServiceSummaryEvaluations = [...serviceSummaryEvaluations]
    if (selectedProviderID) {
      sortedServiceSummaryEvaluations = serviceSummaryEvaluations.filter(
        (o) => o.provider_id === selectedProviderID
      )
    }
    sortedServiceSummaryEvaluations = sortedServiceSummaryEvaluations.sort(
      (a, b) => {
        if (a.sla_seconds_percentage && b.sla_seconds_percentage) {
          return (
            (a.sla_seconds_percentage || 0) - (b.sla_seconds_percentage || 0)
          )
        } else {
          return 0
        }
      }
    )
    return sortedServiceSummaryEvaluations
  }, [serviceSummaryEvaluations, selectedProviderID])

  const filteredCanaries = useMemo(() => {
    let _filteredCanaries = canaries

    if (selectedProviderID) {
      _filteredCanaries = _filteredCanaries.filter(
        (o) => o.provider === selectedProviderID
      )
    }
    if (selectedServiceName) {
      _filteredCanaries = _filteredCanaries.filter(
        (o) => o.service_name === selectedServiceName
      )
    }
    return _filteredCanaries
  }, [canaries, selectedProviderID, selectedServiceName])

  return (
    <ComplianceContext.Provider
      value={{
        areServiceSummaryAndColorsLoading,
        filteredSummaryEvaluations,
        areCanariesLoading,
        filteredCanaries,
        serviceComplianceColors,
        serviceComplianceColorsSettings,
        selectedServiceId,
        selectedServiceName,
        selectedSummaryEvaluationId,
        fetchBaseServiceComplianceColors,
        updateSelectedServiceId,
        updateSelectedServiceName,
        updateSelectedServiceSummaryEvaluationId,
        resetSelectedService
      }}
    >
      {children}
    </ComplianceContext.Provider>
  )
}
