import { ServiceHealthEvaluationSettingsGroupPeriodCategoryEnum } from 'lib/CloudCanariesRestfulAPI'

export const initialFormData: any = {
  organization: '',
  service: '',
  provider: '',
  canaries: [],
  is_default: false,
  name: '',
  range_settings: {
    concurrent_alarms_healthy_to_stop_incident: 1,
    concurrent_alarms_alarming_to_start_incident: 1,
    period_category:
      ServiceHealthEvaluationSettingsGroupPeriodCategoryEnum.Weekly,
    downtime_seconds_allowed: 1,
    mean_time_between_system_incidents_seconds_allowed: 1,
    mean_time_recovery_system_seconds_allowed: 1,
    coverage_denominator: 1,
    sla_target_goal: 0
  },
  group: {
    urgency_setting: {
      events_per_high_urgency: 3,
      events_per_medium_urgency: 2,
      events_per_low_urgency: 1
    },
    impact_settings: {
      events_per_high_impact: 3,
      events_per_medium_impact: 2,
      events_per_low_impact: 1
    }
  }
}
