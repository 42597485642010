import { useContext, useState } from 'react'
import Button from '@mui/material/Button'
import { IChangeEvent } from '@rjsf/core'
import MuiForm from '@rjsf/mui'
import { ErrorSchema, ErrorSchemaBuilder, RJSFSchema } from '@rjsf/utils'
import validator from '@rjsf/validator-ajv8'
import { AxiosError, isAxiosError } from 'axios'
import Icon, { IconType } from 'components/Icons'
import DialogModal from 'components/Modals/DialogModal'
import FormsCreateButton from 'components/common/FormsCreateButton'
import { configCreateEditNotification } from 'constants/formconfig/CreateEditNotification'
import { APIServiceContext } from 'contexts/APIServiceContext'
import useStyles from './styles'

interface CreateNotificationDialogProps {
  alarmId: string
  onClose: () => void
}

export default function CreateNotificationDialog(
  props: CreateNotificationDialogProps
) {
  const classes = useStyles()
  const { alarmId, onClose } = props

  const { apiService } = useContext(APIServiceContext)

  const schema = configCreateEditNotification.schema
  const [open, setOpen] = useState(false)
  const [extraErrors, setExtraErrors] = useState<ErrorSchema>({})

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  function handleModalClose() {
    setExtraErrors({})
    handleClose()
    onClose()
  }

  const onSubmit = async (
    data: IChangeEvent<any, RJSFSchema, any>,
    event: React.FormEvent<any>
  ) => {
    let requestBody = JSON.parse(JSON.stringify(data.formData))

    requestBody.alarm = alarmId
    requestBody.channels = requestBody.channel_and_from_to_status.channels
    requestBody.transition_from =
      requestBody.channel_and_from_to_status.transition_from
    requestBody.transition_to =
      requestBody.channel_and_from_to_status.transition_to
    requestBody.urgency = requestBody.urgency_impact_priority.urgency
    requestBody.impact = requestBody.urgency_impact_priority.impact
    requestBody.priority = requestBody.urgency_impact_priority.priority
    requestBody.summary = requestBody.summary_description.summary
    requestBody.description = requestBody.summary_description.description
    try {
      await apiService.createNotification(requestBody).then((json) => {
        if (json.status === 201) {
          handleModalClose()
        }
      })
    } catch (e: any) {
      if (isAxiosError(e)) {
        const err = e as AxiosError
        if (err.response?.status === 400) {
          const errors = err.response?.data as {}
          const builder = new ErrorSchemaBuilder()
          Object.entries(errors).forEach(([key, value]) => {
            builder.addErrors(value as string, key)
          })
          setExtraErrors(builder.ErrorSchema)
        }
      }
    }
  }

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        color="primary"
        sx={{ height: 36 }}
      >
        <Icon name={IconType.Add} /> Create
      </Button>
      <DialogModal
        onClose={handleClose}
        open={open}
        fullWidth
        dialogTitle="Add Notification"
        dialogContent={
          <MuiForm
            schema={schema}
            onSubmit={onSubmit}
            extraErrors={extraErrors}
            uiSchema={configCreateEditNotification.uiSchema}
            className={classes.muiForm}
            validator={validator}
          >
            <FormsCreateButton>Add</FormsCreateButton>
          </MuiForm>
        }
      />
    </>
  )
}
