import { OpenAPISchemaValidatorResult } from 'openapi-schema-validator'
import { ChangeEvent, useState } from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import { createStyles, makeStyles } from '@mui/styles'
import { uniqueId } from 'lodash'
import DialogModal from 'components/Modals/DialogModal'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%'
    },
    container: {
      maxHeight: 440
    },
    errorList: {
      listStyle: 'none',
      padding: 0
    },
    errorItem: {
      marginBottom: '0.5rem',
      color: 'red'
    }
  })
)

interface Column {
  id: 'count' | 'path' | 'message'
  label: string
  minWidth?: number
  align?: 'left'
  format?: (value: number) => string
}

const columns: Column[] = [
  { id: 'count', label: 'No', minWidth: 50 },
  { id: 'path', label: 'Path', minWidth: 200 },
  { id: 'message', label: 'Error Message', minWidth: 350 }
]
interface OpenAPIErrorsMoalProps {
  openAPIErrorsModalOpen: boolean
  handleOpenAPIErrorsModalClose: () => void
  errors: OpenAPISchemaValidatorResult['errors']
}

export default function OpenAPIErrorsMoal(props: OpenAPIErrorsMoalProps) {
  const classes = useStyles()
  const { openAPIErrorsModalOpen, handleOpenAPIErrorsModalClose, errors } =
    props

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  return (
    <DialogModal
      open={openAPIErrorsModalOpen}
      onClose={handleOpenAPIErrorsModalClose}
      dialogTitle="Open API Schema Errors"
      maxWidth="md"
      fullWidth
      dialogContent={
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {errors
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={uniqueId(row.message)}
                      >
                        <TableCell align="left">{index + 1}</TableCell>
                        <TableCell align="left" style={{ fontWeight: 600 }}>
                          {row.instancePath === ''
                            ? 'Object root level'
                            : row.instancePath}
                        </TableCell>
                        <TableCell align="left">{row.message}</TableCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={errors.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      }
    />
  )
}
