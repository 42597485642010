import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { CanaryForecastGraphMetrics } from 'constants/CanaryForecastGraphMetrics'
import { userLogout } from './auth'
import type { RootState } from '../store'

export interface ForecastManagePageState {
  selectedCanaryForecastCharts: CanaryForecastGraphMetrics[]
}

const initialState: ForecastManagePageState = {
  selectedCanaryForecastCharts: [
    CanaryForecastGraphMetrics.PERCENTAGE_CHANCE_OF_INCIDENT,
    CanaryForecastGraphMetrics.LATENCY_ISSUES_PREDICTED,
    CanaryForecastGraphMetrics.RESPONSE_VALUE,
    CanaryForecastGraphMetrics.RESPONSE_LATENCY
  ]
}

export const forecastManagePageSlice = createSlice({
  name: 'forecastPage',
  initialState,
  reducers: {
    setSelectedCanaryForecastCharts: (
      state,
      action: PayloadAction<CanaryForecastGraphMetrics[]>
    ) => {
      state.selectedCanaryForecastCharts = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(userLogout, (state, action) => {
      state.selectedCanaryForecastCharts =
        initialState.selectedCanaryForecastCharts
    })
  }
})

// Action creators are generated for each case reducer function
export const { setSelectedCanaryForecastCharts } =
  forecastManagePageSlice.actions

export default forecastManagePageSlice.reducer

export const selectCanaryForecastCharts = (state: RootState) =>
  state.forecastManagePage.selectedCanaryForecastCharts
