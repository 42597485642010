import { useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import uniqueId from 'lodash/uniqueId'
import { ServiceDailyForecast } from 'lib/CloudCanariesRestfulAPI'
import CardDailyServiceForecast from './CardDailyServiceForecast'
import IndividualCanaryForecastsSection from './IndividualCanaryForecastsSection'

interface DailyServiceForecastsSectionProps {
  dayOfWeek: string
  serviceDailyForecasts: ServiceDailyForecast[]
  selectedProviderId: string
}

export default function DailyServiceForecastsSection(
  props: DailyServiceForecastsSectionProps
) {
  const { dayOfWeek, serviceDailyForecasts, selectedProviderId } = props
  const [selectedServiceId, setSelectedServiceId] = useState<string>('')

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} style={{ alignContent: 'space-between' }}>
          <Typography
            gutterBottom
            variant="h4"
            component="div"
            align={'center'}
            style={{
              marginTop: '32px',
              marginBottom: '32px',
              fontWeight: 700
            }}
          >
            {dayOfWeek} Detailed Service Forecast
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {serviceDailyForecasts.map((serviceDailyForecast) => {
          return (
            <CardDailyServiceForecast
              key={uniqueId(serviceDailyForecast.service_id)}
              selected={selectedServiceId === serviceDailyForecast.service_id}
              handleCardClick={() => {
                if (serviceDailyForecast.service_id === selectedServiceId) {
                  setSelectedServiceId('')
                } else {
                  setSelectedServiceId(serviceDailyForecast.service_id!)
                }
              }}
              dailyForecast={serviceDailyForecast}
            />
          )
        })}
      </Grid>
      <IndividualCanaryForecastsSection
        serviceId={selectedServiceId}
        selectedProviderId={selectedProviderId}
      />
    </>
  )
}
