import Grid from '@mui/material/Grid'
import { makeStyles } from '@mui/styles'
import BackButton from './BackButton'

const useStyles = makeStyles({
  headerRow: {
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  }
})

interface PageHeaderProps {
  title: string
}

export default function PageHeader(props: PageHeaderProps) {
  const { title } = props
  const classes = useStyles()
  return (
    <Grid container className={classes.headerRow}>
      <Grid item xs={8}>
        <h1>{title}</h1>
      </Grid>
      <Grid item>
        <BackButton />
      </Grid>
    </Grid>
  )
}
