import { ReactNode } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { SxProps, Theme } from '@mui/material/styles'
import Icon, { IconType } from 'components/Icons'
import {
  BillingDetailInvoicesInner,
  BillingDetailInvoicesInnerInvoiceStatusEnum
} from 'lib/CloudCanariesRestfulAPI'
import { formatDate } from 'services/utils'

type InvoiceRowProps = {
  invoice: BillingDetailInvoicesInner
}

function TypographyRow({
  children,
  sx
}: {
  children: ReactNode
  sx?: SxProps<Theme> | undefined
}) {
  return (
    <Box component="span" sx={sx}>
      {children}
    </Box>
  )
}

export default function InvoiceRow(props: InvoiceRowProps) {
  const { invoice } = props

  const handleViewInvoice = (invoiceUrl: string | null | undefined) => {
    if (invoiceUrl) {
      window.open(invoiceUrl, '_blank')
    }
  }
  return (
    <Box sx={{ padding: '10px 0' }}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Button
            disableElevation
            disableFocusRipple
            disableRipple
            disableTouchRipple
            endIcon={<Icon name={IconType.LaunchIcon} />}
            onClick={() => handleViewInvoice(invoice.invoice_url)}
            sx={{
              color: '#000000de',
              '&:hover': {
                backgroundColor: 'transparent'
              }
            }}
          >
            {formatDate(invoice.invoice_payment_date!)}
          </Button>
        </Grid>
        <Grid item xs={3}>
          <TypographyRow>${invoice.invoice_amount_billed}</TypographyRow>
        </Grid>
        <Grid item xs={2}>
          <TypographyRow
            sx={{
              height: '20px',
              lineHeight: '20px',
              px: 0.85,
              py: 0.25,
              fontWeight: 600,
              borderRadius: 1,
              borderStyle: 'solid',
              borderWidth: 1,
              color:
                invoice.invoice_status ===
                BillingDetailInvoicesInnerInvoiceStatusEnum.Paid
                  ? '#006908'
                  : '#b30000',
              background:
                invoice.invoice_status ===
                BillingDetailInvoicesInnerInvoiceStatusEnum.Paid
                  ? '#d7f7c2'
                  : '#ffd6d6',
              borderColor:
                invoice.invoice_status ===
                BillingDetailInvoicesInnerInvoiceStatusEnum.Paid
                  ? '#a6eb84'
                  : '#ff6666'
            }}
          >
            {invoice.invoice_status?.toLocaleUpperCase()}
          </TypographyRow>
        </Grid>
        <Grid item xs={4}>
          <TypographyRow>{invoice.plan_name}</TypographyRow>
        </Grid>
      </Grid>
    </Box>
  )
}
