import { setServiceProviderOptionValueSelected } from 'client_side_state/slices/pageFilter'
import {
  setSelectedServiceHealthID,
  setSelectedServiceHealthNAME
} from 'client_side_state/slices/serviceHealth'
import { useAppDispatch } from 'client_side_state/store'
import { useContext, useEffect, useMemo, useState } from 'react'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import Box from '@mui/system/Box'
import CircularPercentages, {
  Segment
} from 'components/common/CircularPercentages'
import { OrganizationsContext } from 'contexts/OrganizationsContext'
import { ServiceHealthContext } from 'contexts/ServiceHealthContext'
import {
  CanaryReadWriteMinimalStatusEnum,
  ServiceHealth,
  ServiceHealthColors,
  ServiceHealthIncidentUrgencyEnum
} from 'lib/CloudCanariesRestfulAPI'

const useStyles = makeStyles({
  complianceCard: {
    marginBottom: '16px',
    marginInline: '8px',
    width: '380px',
    height: '100%'
  },
  severityBar: {
    display: 'flex',
    width: '100%',
    height: 13,
    borderRadius: 8
  },
  organizationName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    marginLeft: '1rem',
    marginRight: '1rem'
  },
  tooltip: {
    fontSize: 18
  },
  grayedName: {
    color: '#000',
    opacity: 0.5
  }
})

interface GraphColors {
  percentage: number
  color: string
  name: string
}

interface ServiceHealthCardProps {
  serviceHealthSummary: ServiceHealth
}

const initialColor: GraphColors = {
  percentage: 0,
  color: '',
  name: ''
}

const enum GraphLabels {
  GOOD = 'Good',
  WARNING = 'Warning',
  ALARMING = 'Alarming',
  URGENT = 'Urgent'
}

export default function ServiceHealthCard(props: ServiceHealthCardProps) {
  const classes = useStyles()

  const { serviceHealthSummary } = props

  const dispatch = useAppDispatch()

  const { organizationId } = useContext(OrganizationsContext)
  const {
    canaries,
    serviceHealthColorsSettings,
    selectedServiceId,
    serviceHealthColors,
    setSelectedServiceId,
    setSelectedServiceName,
    resetSelectedService
  } = useContext(ServiceHealthContext)

  const selected = useMemo<boolean>(
    () => selectedServiceId === serviceHealthSummary.id,
    [selectedServiceId, serviceHealthSummary.id]
  )
  const showOrganizationName = useMemo<boolean>(
    () => !Boolean(organizationId),
    [organizationId]
  )
  const serviceHealthColorsSetting = useMemo<ServiceHealthColors>(
    () => serviceHealthColors || serviceHealthColorsSettings[0],
    [serviceHealthColors, serviceHealthColorsSettings]
  )

  const DEFAULT_COLORS = [
    serviceHealthColorsSetting!.alert_dial_color_running_canaries_with_alarms!,
    serviceHealthColorsSetting!.alert_dial_color_running_canaries_no_alarms!,
    serviceHealthColorsSetting!.alert_dial_color_erroring_canaries!,
    serviceHealthColorsSetting!.alert_dial_color_stopped_canaries!,
    serviceHealthColorsSetting!.alert_dial_color_new_canaries!
  ]

  const [severityBarColor, setSeverityBarColor] = useState<string>('')
  const [redColor, setRedColor] = useState<GraphColors>({
    ...initialColor,
    name: 'Running with Alarms'
  })
  const [brownColor, setBrownColor] = useState<GraphColors>({
    ...initialColor,
    name: 'Erroring Canaries'
  })
  const [greenColor, setGreenColor] = useState<GraphColors>({
    ...initialColor,
    name: 'Running Canaries, no Alarms'
  })
  const [grayColor, setGrayColor] = useState<GraphColors>({
    ...initialColor,
    name: 'Stopped Canaries'
  })
  const [blueColor, setBlueColor] = useState<GraphColors>({
    ...initialColor,
    name: 'New Canaries'
  })

  const [graphLabel, setGraphLabel] = useState<string>(GraphLabels.GOOD)

  useEffect(() => {
    if (serviceHealthSummary) {
      if (
        serviceHealthSummary.total_active_incidents_in_current_period_count! > 0
      ) {
        const canariesAlarmingPercentage =
          (serviceHealthSummary.canaries_with_alarms_alarming! /
            serviceHealthSummary.canaries_total!) *
          100

        if (canariesAlarmingPercentage < 5) {
          if (
            serviceHealthSummary.incidents_level_in_current_period ===
            ServiceHealthIncidentUrgencyEnum.Ok
          ) {
            setSeverityBarColor(
              serviceHealthColorsSetting.severity_bar_none_color!
            )
          } else if (
            serviceHealthSummary.incidents_level_in_current_period ===
            ServiceHealthIncidentUrgencyEnum.Low
          ) {
            setSeverityBarColor(
              serviceHealthColorsSetting.severity_bar_low_color!
            )
          } else if (
            serviceHealthSummary.incidents_level_in_current_period ===
            ServiceHealthIncidentUrgencyEnum.Medium
          ) {
            setSeverityBarColor(
              serviceHealthColorsSetting.severity_bar_medium_color!
            )
          } else if (
            serviceHealthSummary.incidents_level_in_current_period ===
            ServiceHealthIncidentUrgencyEnum.High
          ) {
            setSeverityBarColor(
              serviceHealthColorsSetting.severity_bar_high_color!
            )
          }
        } else if (
          canariesAlarmingPercentage > 5 &&
          canariesAlarmingPercentage < 15
        ) {
          setSeverityBarColor(
            serviceHealthColorsSetting.severity_bar_low_color!
          )
        } else if (canariesAlarmingPercentage >= 15) {
          setSeverityBarColor(
            serviceHealthColorsSetting.severity_bar_high_color!
          )
        }
      } else {
        if (serviceHealthSummary.canaries_with_alarms_alarming === 0) {
          setSeverityBarColor(
            serviceHealthColorsSetting.severity_bar_none_color!
          )
        } else if (serviceHealthSummary.canaries_with_alarms_alarming! > 0) {
          const canariesAlarmingPercentage =
            (serviceHealthSummary.canaries_with_alarms_alarming! /
              serviceHealthSummary.canaries_total!) *
            100

          if (canariesAlarmingPercentage < 5) {
            setSeverityBarColor(
              serviceHealthColorsSetting.severity_bar_low_color!
            )
          } else if (
            canariesAlarmingPercentage >= 5 &&
            canariesAlarmingPercentage <= 15
          ) {
            setSeverityBarColor(
              serviceHealthColorsSetting.severity_bar_medium_color!
            )
          } else if (canariesAlarmingPercentage >= 16) {
            setSeverityBarColor(
              serviceHealthColorsSetting.severity_bar_high_color!
            )
          }
        }
      }
    }
  }, [
    serviceHealthColorsSetting,
    serviceHealthSummary,
    serviceHealthSummary.canaries_total,
    serviceHealthSummary.canaries_with_alarms_alarming,
    serviceHealthSummary.total_active_incidents_in_current_period_count
  ])

  const serviceCanaries = useMemo(() => {
    return canaries.filter(
      (canary) => canary.service === serviceHealthSummary.service_id
    )
  }, [canaries, serviceHealthSummary.service_id])

  const runningCanaries: number = useMemo(() => {
    return serviceCanaries.filter(
      (canary) => canary.status === CanaryReadWriteMinimalStatusEnum.Running
    ).length
  }, [serviceCanaries])

  const erroringCanaries: number = useMemo(() => {
    return serviceCanaries.filter(
      (canary) => canary.status === CanaryReadWriteMinimalStatusEnum.Error
    ).length
  }, [serviceCanaries])

  const stoppedCanaries: number = useMemo(() => {
    return serviceCanaries.filter(
      (canary) => canary.status === CanaryReadWriteMinimalStatusEnum.Stopped
    ).length
  }, [serviceCanaries])

  const newCanaries: number = useMemo(() => {
    return serviceCanaries.filter(
      (canary) => canary.status === CanaryReadWriteMinimalStatusEnum.New
    ).length
  }, [serviceCanaries])

  useEffect(() => {
    if (serviceHealthSummary) {
      if (serviceHealthSummary.canaries_with_alarms_alarming) {
        if (
          serviceHealthSummary.total_active_incidents_in_current_period_count! >
          0
        ) {
          setGraphLabel(GraphLabels.URGENT)
        } else {
          setGraphLabel(GraphLabels.ALARMING)
        }
      } else {
        if (erroringCanaries > 0 || stoppedCanaries > 0) {
          setGraphLabel(GraphLabels.WARNING)
        } else {
          setGraphLabel(GraphLabels.GOOD)
        }
      }
    }
  }, [erroringCanaries, serviceHealthSummary, stoppedCanaries])

  useEffect(() => {
    if (serviceHealthSummary) {
      const totalCanaries = serviceHealthSummary.canaries_total!
      setRedColor((prev) => ({
        ...prev,
        percentage:
          (serviceHealthSummary.canaries_with_alarms_alarming! /
            totalCanaries) *
            100 || 0,
        color:
          serviceHealthColorsSetting.alert_dial_color_running_canaries_with_alarms!
      }))
      setGreenColor((prev) => ({
        ...prev,
        percentage:
          ((runningCanaries -
            serviceHealthSummary.canaries_with_alarms_alarming!) /
            totalCanaries) *
            100 || 0,
        color:
          serviceHealthColorsSetting.alert_dial_color_running_canaries_no_alarms!
      }))
      setBrownColor((prev) => ({
        ...prev,
        percentage: (erroringCanaries / totalCanaries) * 100 || 0,
        color: serviceHealthColorsSetting.alert_dial_color_erroring_canaries!
      }))
      setGrayColor((prev) => ({
        ...prev,
        percentage: (stoppedCanaries / totalCanaries) * 100 || 0,
        color: serviceHealthColorsSetting.alert_dial_color_stopped_canaries!
      }))
      setBlueColor((prev) => ({
        ...prev,
        percentage: (newCanaries / totalCanaries) * 100 || 0,
        color: serviceHealthColorsSetting.alert_dial_color_new_canaries!
      }))
    }
  }, [
    erroringCanaries,
    newCanaries,
    runningCanaries,
    serviceHealthColorsSetting,
    serviceHealthSummary,
    stoppedCanaries
  ])

  let segments: Segment[] = []
  Array.from([redColor, greenColor, brownColor, grayColor, blueColor]).forEach(
    (segment, index) => {
      if (segment !== undefined) {
        let percentage = segment.percentage
        while (percentage > 0) {
          const prevSegment = segments[segments.length - 1]
          segments.push({
            color: DEFAULT_COLORS[index],
            percentage: Math.min(percentage, 25),
            startPercentage:
              segments.length === 0
                ? 0
                : prevSegment.startPercentage + prevSegment.percentage,
            name: segment.name
          })
          percentage -= Math.min(percentage, 25)
        }
      }
    }
  )

  const handleCheckbox = () => {
    if (selectedServiceId === serviceHealthSummary.id) {
      resetSelectedService()
      dispatch(setServiceProviderOptionValueSelected(''))
    } else {
      setSelectedServiceId(serviceHealthSummary.id!)
      setSelectedServiceName(serviceHealthSummary.service_name!)
      dispatch(setSelectedServiceHealthID(serviceHealthSummary.id!))
      dispatch(setSelectedServiceHealthNAME(serviceHealthSummary.service_name!))
      dispatch(
        setServiceProviderOptionValueSelected(serviceHealthSummary.provider_id!)
      )
    }
  }

  return (
    <Card className={classes.complianceCard}>
      <Box
        className={classes.severityBar}
        sx={{ backgroundColor: severityBarColor }}
      />
      <CardActionArea onClick={handleCheckbox}>
        <CardHeader
          avatar={
            <input
              type="checkbox"
              checked={selected}
              onChange={handleCheckbox}
            />
          }
          title={
            showOrganizationName ? (
              <Tooltip
                title={serviceHealthSummary.organization_name!}
                placement="top"
                arrow
                classes={{
                  tooltip: classes.tooltip
                }}
              >
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  align="center"
                  className={classes.organizationName}
                >
                  {serviceHealthSummary.organization_name}
                </Typography>
              </Tooltip>
            ) : null
          }
        />
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={3}>
            <Typography
              gutterBottom
              component="div"
              align="center"
              className={classes.grayedName}
            >
              Service
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Tooltip
              title={serviceHealthSummary.service_name!}
              placement="top"
              arrow
              classes={{
                tooltip: classes.tooltip
              }}
            >
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                align="center"
                className={classes.organizationName}
              >
                {serviceHealthSummary.service_name}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={3} />
        </Grid>
        <CardContent>
          <CircularPercentages segments={segments} label={graphLabel} />
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={3}>
              <Typography
                gutterBottom
                component="div"
                align="left"
                className={classes.grayedName}
              >
                Provider
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Tooltip
                title={serviceHealthSummary.provider_name!}
                placement="top"
                arrow
                classes={{
                  tooltip: classes.tooltip
                }}
              >
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  align="center"
                  className={classes.organizationName}
                >
                  {serviceHealthSummary.provider_name}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={3} />
          </Grid>
          <hr />
          <TableContainer component={Paper}>
            <Table aria-label="simple table" size={'small'}>
              <TableBody>
                <TableRow>
                  <TableCell align="left">Total Canaries</TableCell>
                  <TableCell align="right">
                    {serviceHealthSummary.canaries_total}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Alarming Canaries</TableCell>
                  <TableCell align="right">
                    {serviceHealthSummary.canaries_with_alarms_alarming}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Stopped Canaries</TableCell>
                  <TableCell align="right">{stoppedCanaries}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Erroring Canaries</TableCell>
                  <TableCell align="right">{erroringCanaries}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
