import { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Button from '@mui/material/Button'
import DialogContentText from '@mui/material/DialogContentText'
import { isAxiosError } from 'axios'
import Icon, { IconType } from 'components/Icons'
import DialogModal from 'components/Modals/DialogModal'
import ModalSharedStyles from 'components/Modals/ModalSharedStyles'
import { ToastErrors } from 'constants/toast_errors'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { CanariesContext } from 'contexts/CanariesContext'
import { CanaryErrors } from 'interfaces/Canary'
import { CanaryReadWriteMinimalStatusEnum } from 'lib/CloudCanariesRestfulAPI'

export default function ModalDeleteCanary() {
  const classes = ModalSharedStyles()

  const { apiService } = useContext(APIServiceContext)
  const { selectedCanaryIds, canaries, getCanaries, setSelectedCanaryIds } =
    useContext(CanariesContext)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isCanaryAgentRunning, setIsCanaryAgentRunning] =
    useState<boolean>(false)
  const [isCanaryAgentWarningModalOpen, setIsCanaryAgentWarningModalOpen] =
    useState<boolean>(false)

  useEffect(() => {
    const selectedCanaries = canaries.filter((canary) =>
      selectedCanaryIds.includes(canary.id!)
    )
    if (
      selectedCanaries.find(
        (canary) =>
          canary.is_agent_canary &&
          canary.status === CanaryReadWriteMinimalStatusEnum.Running
      )
    ) {
      setIsCanaryAgentRunning(true)
    } else {
      setIsCanaryAgentRunning(false)
    }
  }, [canaries, selectedCanaryIds])

  const handleClickOpen = () => {
    if (isCanaryAgentRunning) {
      setIsCanaryAgentWarningModalOpen(true)
    } else {
      setIsDeleteModalOpen(true)
    }
  }

  const handleClose = () => {
    setIsDeleteModalOpen(false)
  }

  const handleCanaryAgentModalClose = () => {
    setIsCanaryAgentWarningModalOpen(false)
  }

  const handleAction = async () => {
    let canaryErrors: CanaryErrors[] = []
    await Promise.all(
      selectedCanaryIds.map(async (canaryId) => {
        try {
          await apiService.destroyCanary(canaryId)
        } catch (err: any) {
          if (isAxiosError(err)) {
            if (err && err.response && err.response.data) {
              const erroringCanary: CanaryErrors = {
                canaryName: canaries.find((canary) => canary.id === canaryId)
                  ?.name!,
                error: true
              }
              canaryErrors.push(erroringCanary)
            }
          }
        }
      })
    )
    if (canaryErrors.some((canary) => canary.error)) {
      canaryErrors.forEach((error) => {
        toast.error(
          `${ToastErrors.ERROR_DELETING_CANARIES} "${error.canaryName}"`,
          {
            position: 'top-right'
          }
        )
      })
    }
    setIsDeleteModalOpen(false)
    setSelectedCanaryIds([])
    await getCanaries()
  }

  const isDisabled = !selectedCanaryIds || selectedCanaryIds.length === 0

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        color={'primary'}
        disabled={isDisabled}
      >
        <Icon name={IconType.Trash} /> Delete
      </Button>
      <DialogModal
        onClose={handleCanaryAgentModalClose}
        open={isCanaryAgentWarningModalOpen}
        fullWidth
        dialogTitle="Can't delete running Canary Agents"
        dialogContent={
          <DialogContentText id="delete-canaries-dialog-description">
            To delete a canary agent from the Canary Dashboard, first stop the
            canary using Control-C, then delete.
          </DialogContentText>
        }
        dialogActions={
          <Button onClick={handleCanaryAgentModalClose}>Ok</Button>
        }
      />
      <DialogModal
        onClose={handleClose}
        open={isDeleteModalOpen}
        fullWidth
        dialogTitle="DELETE Selected Canaries"
        dialogContent={
          <DialogContentText id="delete-canaries-dialog-description">
            Are you sure you want to DELETE the selected canaries
          </DialogContentText>
        }
        dialogActions={
          <>
            <Button onClick={handleClose}>NO</Button>
            <Button
              onClick={handleAction}
              variant="contained"
              className={classes.deleteButton}
              autoFocus
              disabled={isCanaryAgentRunning}
            >
              YES
            </Button>
          </>
        }
      />
    </>
  )
}
