import { useCallback, useContext, useEffect, useState } from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TabPanel from 'components/common/MUITabPanel'
import { queryParamPageSizeDefault } from 'constants/AppConfig'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { NotificationHistory } from 'lib/CloudCanariesRestfulAPI'

interface PerAlarmNotificationHistoryTabPanelProps {
  alarmId: string
  selectedTab: number
  tabIndex: number
}

export default function PerAlarmNotificationHistoryTabPanel({
  alarmId,
  selectedTab,
  tabIndex
}: PerAlarmNotificationHistoryTabPanelProps) {
  const { apiService } = useContext(APIServiceContext)

  const isSelected = selectedTab === tabIndex
  const [page, setPage] = useState<number>(0)
  const rowsPerPage = queryParamPageSizeDefault
  const rowsPerPageOptions = [rowsPerPage]
  const [rowCount, setRowCount] = useState<number>(0)

  const [notificationHistorys, setNotificationHistorys] = useState<
    NotificationHistory[]
  >([])

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage)
  }

  const getNotificationHistorys = useCallback(async () => {
    if (isSelected) {
      const requestedPage = page + 1

      await apiService
        .listNotificationHistories(
          requestedPage,
          queryParamPageSizeDefault,
          alarmId
        )
        .then((json) => {
          setNotificationHistorys(json.data.results ?? [])
          setRowCount(json.data.count ?? 0)
        })
    }
  }, [isSelected, page, apiService, alarmId])

  useEffect(() => {
    getNotificationHistorys()
  }, [getNotificationHistorys])

  return (
    <TabPanel value={selectedTab} index={tabIndex}>
      <TablePagination
        component="div"
        count={rowCount}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        page={page}
        onPageChange={handleChangePage}
      />
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Created</TableCell>
              <TableCell align="center">Notification</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {notificationHistorys.map((notificationHistory) => {
              return (
                <TableRow key={notificationHistory.id}>
                  <TableCell align="center">
                    {notificationHistory.created}
                  </TableCell>
                  <TableCell align="center">
                    {notificationHistory.notification_name}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={rowCount}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        page={page}
        onPageChange={handleChangePage}
      />
    </TabPanel>
  )
}
