import {
  ChangeEvent,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { GenericSelectItemOption } from 'interfaces/PageFilters'
import { CanaryTemplateLibrary } from 'lib/CloudCanariesRestfulAPI'
import { deDuplicateServiceProviderOptions } from 'services/utils'
import { APIServiceContext } from './APIServiceContext'
import { OrganizationsContext } from './OrganizationsContext'

interface TemplateLibrariesContextProviderProps {
  children: ReactNode
}

interface TemplateLibrariesContextProps {
  areTemplateLibrariesLoading: boolean
  filteredTemplateLibraries: CanaryTemplateLibrary[]
  providersOptions: GenericSelectItemOption[]
  selectedProvider: string
  rowsPerPage: number
  page: number
  rowCount: number
  setSelectedProvider: (value: string) => void
  getTemplateLibraries: () => Promise<void>
  onRowsPerPageChange: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void
  handleChangePage: (event: any, newPage: number) => void
}
export const TemplateLibrariesContext =
  createContext<TemplateLibrariesContextProps>(null as any)

export default function TemplateLibrariesContextProvider(
  props: TemplateLibrariesContextProviderProps
) {
  const { children } = props

  const { apiService } = useContext(APIServiceContext)
  const { organizationId } = useContext(OrganizationsContext)

  const [page, setPage] = useState<number>(0)
  const [rowCount, setRowCount] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [templateLibraries, setTemplateLibraries] = useState<
    CanaryTemplateLibrary[]
  >([])
  const [areTemplateLibrariesLoading, setAreTemplateLibrariesLoading] =
    useState(true)

  const [filteredTemplateLibraries, setFilteredTemplateLibraries] = useState<
    CanaryTemplateLibrary[]
  >([])
  const [providersOptions, setProvidersOptions] = useState<
    GenericSelectItemOption[]
  >([])
  const [selectedProvider, setSelectedProvider] = useState<string>('')

  useEffect(() => {
    setRowCount(0)
  }, [organizationId])

  const getTemplateLibraries = useCallback(async () => {
    setRowCount(0)
    const requestedPage = page + 1

    setAreTemplateLibrariesLoading(true)
    await apiService
      .listCanaryTemplateLibraries(requestedPage, rowsPerPage, organizationId)
      .then((json) => {
        setTemplateLibraries(json.data.results ?? [])
        setRowCount(json.data.count ?? 0)
        setAreTemplateLibrariesLoading(false)
      })
  }, [apiService, organizationId, page, rowsPerPage])

  useEffect(() => {
    getTemplateLibraries()
  }, [getTemplateLibraries])

  useEffect(() => {
    const providers = deDuplicateServiceProviderOptions(templateLibraries)
    setProvidersOptions(providers)
  }, [templateLibraries])

  useEffect(() => {
    setSelectedProvider('')
  }, [organizationId])

  useEffect(() => {
    if (selectedProvider !== '') {
      const filteredTemplateLibraries = templateLibraries.filter(
        (library) => library.provider_id === selectedProvider
      )
      setFilteredTemplateLibraries(filteredTemplateLibraries)
    } else {
      setFilteredTemplateLibraries(templateLibraries)
    }
  }, [selectedProvider, templateLibraries])

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage)
  }

  const onRowsPerPageChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <TemplateLibrariesContext.Provider
      value={{
        areTemplateLibrariesLoading,
        filteredTemplateLibraries,
        providersOptions,
        selectedProvider,
        page,
        rowCount,
        rowsPerPage,
        setSelectedProvider,
        getTemplateLibraries,
        handleChangePage,
        onRowsPerPageChange
      }}
    >
      {children}
    </TemplateLibrariesContext.Provider>
  )
}
