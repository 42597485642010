import {
  selectServiceProviderSelected,
  setServiceProviderOptions
} from 'client_side_state/slices/pageFilter'
import {
  selectSelectedServiceHealthId,
  selectSelectedServiceHealthName,
  setSelectedServiceHealthID,
  setSelectedServiceHealthNAME
} from 'client_side_state/slices/serviceHealth'
import { useAppDispatch, useAppSelector } from 'client_side_state/store'
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { queryParamPageSizeJumbo } from 'constants/AppConfig'
import {
  CanaryReadWriteMinimal,
  ServiceHealth,
  ServiceHealthColors
} from 'lib/CloudCanariesRestfulAPI'
import { deDuplicateServiceProviderOptions } from 'services/utils'
import { APIServiceContext } from './APIServiceContext'
import { OrganizationsContext } from './OrganizationsContext'

type ServiceHealthContextProviderProps = {
  children: ReactNode
}

type ServiceHealthContextProps = {
  areServiceHealthsLoading: boolean
  filteredServiceHealths: ServiceHealth[]
  areCanariesLoading: boolean
  canaries: CanaryReadWriteMinimal[]
  visibleCanaries: CanaryReadWriteMinimal[]
  selectedServiceName: string
  serviceHealthColorsSettings: ServiceHealthColors[]
  selectedServiceId: string
  serviceHealthColors: ServiceHealthColors | null
  resetSelectedService: () => void
  setSelectedServiceId: (id: string) => void
  setSelectedServiceName: (serviceName: string) => void
  fetchServiceHealthColors: () => Promise<void>
  areServiceHealthColorsSettingsLoading: boolean
}
export const ServiceHealthContext = createContext<ServiceHealthContextProps>(
  null as any
)
const page = undefined

export default function ServiceHealthContextProvider(
  props: ServiceHealthContextProviderProps
) {
  const { children } = props

  const dispatch = useAppDispatch()

  const selectedProviderID = useAppSelector(selectServiceProviderSelected)

  const selectedServiceHealthID = useAppSelector(selectSelectedServiceHealthId)
  const selectedServiceHealthNAME = useAppSelector(
    selectSelectedServiceHealthName
  )

  const { apiService } = useContext(APIServiceContext)
  const { organizationId } = useContext(OrganizationsContext)

  const [areServiceHealthsLoading, setAreServiceHealthsLoading] =
    useState<boolean>(true)
  const [areCanariesLoading, setAreCanariesLoading] = useState<boolean>(true)
  const [
    areServiceHealthColorsSettingsLoading,
    setAreServiceHealthColorsSettingsLoading
  ] = useState<boolean>(true)

  const [serviceHealths, setServiceHealths] = useState<ServiceHealth[]>([])
  const [serviceHealthColorsSettings, setServiceHealthColorsSettings] =
    useState<ServiceHealthColors[]>([])
  const [canaries, setCanaries] = useState<CanaryReadWriteMinimal[]>([])

  const [selectedServiceId, setSelectedServiceId] = useState<string>(
    selectedServiceHealthID
  )
  const [selectedServiceName, setSelectedServiceName] = useState<string>(
    selectedServiceHealthNAME
  )

  const [serviceHealthColors, setServiceHealthColors] =
    useState<ServiceHealthColors | null>(serviceHealthColorsSettings[0] ?? null)

  const fetchServiceHealths = useCallback(async () => {
    setAreServiceHealthsLoading(true)
    await apiService
      .listServiceHealths(
        page,
        queryParamPageSizeJumbo,
        organizationId,
        undefined
      )
      .then((json) => {
        setServiceHealths(json.data.results ?? [])
        setAreServiceHealthsLoading(false)
      })
  }, [apiService, organizationId])

  useEffect(() => {
    fetchServiceHealths()
  }, [fetchServiceHealths])

  const fetchCanaries = useCallback(async () => {
    setAreCanariesLoading(true)
    await apiService
      .listCanaries(page, queryParamPageSizeJumbo, organizationId)
      .then((json) => {
        setCanaries(json.data.results ?? [])
        setAreCanariesLoading(false)
      })
  }, [apiService, organizationId])

  useEffect(() => {
    fetchCanaries()
  }, [fetchCanaries])

  const fetchServiceHealthColorsSettings = useCallback(async () => {
    setAreServiceHealthColorsSettingsLoading(true)
    await apiService.listServiceHealthColors(organizationId).then((json) => {
      setServiceHealthColorsSettings(json.data ?? [])
      setAreServiceHealthColorsSettingsLoading(false)
    })
  }, [apiService, organizationId])

  useEffect(() => {
    fetchServiceHealthColorsSettings()
  }, [fetchServiceHealthColorsSettings])

  useEffect(() => {
    const providerOptions = deDuplicateServiceProviderOptions(serviceHealths)

    dispatch(setServiceProviderOptions(providerOptions))
  }, [serviceHealths, dispatch])

  const resetSelectedService = useCallback(() => {
    setSelectedServiceName('')
    setSelectedServiceId('')
    dispatch(setSelectedServiceHealthID(''))
    dispatch(setSelectedServiceHealthNAME(''))
  }, [dispatch])

  useEffect(() => {
    if (selectedProviderID === '') {
      resetSelectedService()
    }
  }, [resetSelectedService, selectedProviderID])

  const fetchServiceHealthColors = useCallback(async () => {
    if (serviceHealthColorsSettings.length > 0) {
      await apiService
        .retrieveServiceHealthColors(serviceHealthColorsSettings[0].id!)
        .then((json) => {
          setServiceHealthColors(json.data)
        })
    }
  }, [apiService, serviceHealthColorsSettings])

  const filteredServiceHealths = useMemo(() => {
    let sortedServiceHealths = [...serviceHealths]
    if (selectedProviderID) {
      sortedServiceHealths = serviceHealths.filter(
        (o) => o.provider_id === selectedProviderID
      )
    }
    sortedServiceHealths = sortedServiceHealths.sort((a, b) => {
      if (a.provider_name && b.provider_name) {
        return a.provider_name.localeCompare(b.provider_name)
      } else {
        return -1
      }
    })
    sortedServiceHealths = sortedServiceHealths.sort((a, b) => {
      if (a.canaries_with_alarms_alarming && b.canaries_with_alarms_alarming) {
        return (
          (b.total_active_incidents_in_current_period_count || 0) -
          (a.total_active_incidents_in_current_period_count || 0)
        )
      } else if (a.canaries_with_alarms_alarming) {
        return -1
      } else if (b.canaries_with_alarms_alarming) {
        return 1
      } else if ((a.canaries_total || 0) > (b.canaries_total || 0)) {
        return (b.canaries_total || 0) - (a.canaries_total || 0)
      }
      return 0
    })
    return sortedServiceHealths
  }, [serviceHealths, selectedProviderID])

  const visibleCanaries = useMemo(() => {
    let filteredCanaries = canaries

    if (selectedProviderID) {
      filteredCanaries = filteredCanaries.filter(
        (o) => o.provider === selectedProviderID
      )
    }
    if (selectedServiceName) {
      filteredCanaries = filteredCanaries.filter(
        (o) => o.service_name === selectedServiceName
      )
    }
    return filteredCanaries
  }, [canaries, selectedProviderID, selectedServiceName])

  return (
    <ServiceHealthContext.Provider
      value={{
        areServiceHealthsLoading,
        filteredServiceHealths,
        areCanariesLoading,
        canaries,
        visibleCanaries,
        selectedServiceName,
        selectedServiceId,
        serviceHealthColorsSettings,
        serviceHealthColors,
        resetSelectedService,
        setSelectedServiceId,
        setSelectedServiceName,
        fetchServiceHealthColors,
        areServiceHealthColorsSettingsLoading
      }}
    >
      {children}
    </ServiceHealthContext.Provider>
  )
}
