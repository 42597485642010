import { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Button from '@mui/material/Button'
import DialogContentText from '@mui/material/DialogContentText'
import { isAxiosError } from 'axios'
import Icon, { IconType } from 'components/Icons'
import DialogModal from 'components/Modals/DialogModal'
import { ToastErrors } from 'constants/toast_errors'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { CanariesContext } from 'contexts/CanariesContext'
import { CanaryErrors } from 'interfaces/Canary'
import {
  Canary,
  CanaryReadWriteMinimalStatusEnum,
  CanaryStatusEnum
} from 'lib/CloudCanariesRestfulAPI'

const canaryStartRequest = { status: CanaryStatusEnum.Running } as Canary

export default function ModalStartCanary() {
  const { apiService } = useContext(APIServiceContext)
  const { canaries, selectedCanaryIds, getCanaries } =
    useContext(CanariesContext)

  const [open, setOpen] = useState(false)
  const [isDisabledForCanaryAgent, setIsDisabledForCanaryAgent] =
    useState<boolean>(false)
  const [isDisabledForRunningCanary, setIsDisabledForRunningCanary] =
    useState<boolean>(false)

  useEffect(() => {
    const selectedCanaries = canaries.filter((canary) =>
      selectedCanaryIds.includes(canary.id!)
    )
    if (selectedCanaries.find((canary) => canary.is_agent_canary)) {
      setIsDisabledForCanaryAgent(true)
    } else {
      setIsDisabledForCanaryAgent(false)
    }
  }, [canaries, selectedCanaryIds])

  useEffect(() => {
    const selectedCanaries = canaries.filter((canary) =>
      selectedCanaryIds.includes(canary.id!)
    )
    if (
      selectedCanaries.find(
        (canary) => canary.status === CanaryReadWriteMinimalStatusEnum.Running
      )
    ) {
      setIsDisabledForRunningCanary(true)
    } else {
      setIsDisabledForRunningCanary(false)
    }
  }, [canaries, selectedCanaryIds])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleAction = async () => {
    let canaryErrors: CanaryErrors[] = []
    await Promise.all(
      selectedCanaryIds.map(async (canaryId) => {
        try {
          await apiService.partialUpdateCanary(
            canaryId,
            undefined,
            undefined,
            undefined,
            undefined,
            canaryStartRequest
          )
        } catch (err: any) {
          if (isAxiosError(err)) {
            if (err && err.response && err.response.data) {
              const data = err.response.data as { [key: string]: string }
              const erroringCanary: CanaryErrors = {
                canaryName: canaries.find((canary) => canary.id === canaryId)
                  ?.name!,
                error: true,
                errorMessage: Object.keys(data).map((key) => data[key])[0]
              }
              canaryErrors.push(erroringCanary)
            }
          }
        }
      })
    )
    if (canaryErrors.some((canary) => canary.error)) {
      canaryErrors.forEach((error) => {
        const errorMessage = error.errorMessage
          ? `"${error.canaryName}" due to ${error.errorMessage}`
          : `"${error.canaryName}"`
        toast.error(`${ToastErrors.ERROR_STARTING_CANARIES} ${errorMessage}`, {
          position: 'top-right'
        })
      })
    }
    setOpen(false)
    await getCanaries()
  }

  const isDisabled =
    !selectedCanaryIds ||
    selectedCanaryIds.length === 0 ||
    isDisabledForCanaryAgent ||
    isDisabledForRunningCanary

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        color={'primary'}
        disabled={isDisabled}
      >
        <Icon name={IconType.PlayArrow} /> Start
      </Button>
      <DialogModal
        onClose={handleClose}
        open={open}
        fullWidth
        dialogTitle="Start Selected Canaries"
        dialogContent={
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to start the selected canaries
          </DialogContentText>
        }
        dialogActions={
          <>
            <Button onClick={handleClose}>NO</Button>
            <Button onClick={handleAction}>YES</Button>
          </>
        }
      />
    </>
  )
}
