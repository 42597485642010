import { setServiceProviderOptionValueSelected } from 'client_side_state/slices/pageFilter'
import { useAppDispatch } from 'client_side_state/store'
import { Link } from 'react-router-dom'
import {
  ServiceHealthIncident,
  ServiceHealthIncidentIncidentStatusEnum
} from 'lib/CloudCanariesRestfulAPI'
import RoutesService from 'services/routes'

interface IncidentsServiceLinkProps {
  incident: ServiceHealthIncident
}

export default function IncidentsServiceLink(props: IncidentsServiceLinkProps) {
  const {
    incident: { provider_id, service_name, incident_status }
  } = props
  const dispatch = useAppDispatch()

  function navigateToHealthDashboard(provider_id: string | undefined) {
    dispatch(setServiceProviderOptionValueSelected(provider_id!))
  }
  return incident_status === ServiceHealthIncidentIncidentStatusEnum.Started ? (
    <Link
      onClick={() => navigateToHealthDashboard(provider_id)}
      to={RoutesService.dashboardServiceHealth()}
    >
      {service_name}
    </Link>
  ) : (
    <>{service_name}</>
  )
}
