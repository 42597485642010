import { useCallback, useContext, useEffect, useState } from 'react'
import Container from '@mui/material/Container'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Box from '@mui/system/Box'
import CanaryEditPageLoader from 'components/common/Loaders/CanaryEditPageLoader'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { TaskUpdates } from 'interfaces/CanaryEditor'
import { CanaryTask } from 'lib/CloudCanariesRestfulAPI'
import { MetricUpdateTypes } from 'services/CanaryTaskUpdateService'
import { a11yProps, cleanHumanReadableTaskName } from 'services/utils'
import TabPanelAbstractMetric from './TabPanelAbstractMetric'
import TabPanelEnvironmentalVariables from './TabPanelEnvironmentalVariables'

const defaultTask = {
  latency_metric_alarm_id: '',
  name: '',
  task_id: ''
} as CanaryTask

export interface TabsNamesProps {
  id: number
  name:
    | 'Environmental Variables'
    | 'Latency'
    | 'Response'
    | 'Forecast Latency'
    | 'Forecast Response'
}

const TabsNames: TabsNamesProps[] = [
  {
    id: 0,
    name: 'Environmental Variables'
  },
  {
    id: 1,
    name: 'Latency'
  },
  {
    id: 2,
    name: 'Response'
  },
  {
    id: 3,
    name: 'Forecast Latency'
  },
  {
    id: 4,
    name: 'Forecast Response'
  }
]
interface VariablesPanelProps {
  selectedTaskId: string
  tasksUpdates: Map<string, TaskUpdates>
  setTasksUpdates: (data: Map<string, TaskUpdates>) => void
}

export default function VariablesPanel(props: VariablesPanelProps) {
  const { selectedTaskId, tasksUpdates, setTasksUpdates } = props

  const { apiService } = useContext(APIServiceContext)

  const [selectedTab, setSelectedTab] = useState<TabsNamesProps>(TabsNames[0])
  const [isTaskMetaLoading, setIsTaskMetaLoading] = useState<boolean>(true)
  const [taskMeta, setTaskMeta] = useState<CanaryTask>(defaultTask)

  const handleTabsChange = (event: any, newValue: number) => {
    setSelectedTab(TabsNames[newValue])
  }

  const getTaskMeta = useCallback(async () => {
    setIsTaskMetaLoading(true)
    await apiService.retrieveCanaryTask(selectedTaskId).then((json) => {
      setTaskMeta(json.data)
      setIsTaskMetaLoading(false)
    })
  }, [apiService, selectedTaskId])

  useEffect(() => {
    if (selectedTaskId) {
      getTaskMeta()
    } else {
      setTaskMeta(defaultTask)
    }
  }, [selectedTaskId, getTaskMeta])

  const defaultBox = (
    <Box sx={{ width: '100%' }}>
      <h1>Select a task to edit</h1>
    </Box>
  )

  return isTaskMetaLoading ? (
    <CanaryEditPageLoader />
  ) : taskMeta && taskMeta.id ? (
    <Container maxWidth="xl">
      <Box sx={{ width: '100%' }}>
        <h1>{cleanHumanReadableTaskName(taskMeta.name)}</h1>
        <Box>
          <Tabs
            value={selectedTab.id}
            onChange={handleTabsChange}
            aria-label="Canary Update Tabs"
            sx={{
              '& .MuiTabs-indicator': {
                backgroundColor: '#f50057'
              }
            }}
          >
            {TabsNames.map((tabName) => (
              <Tab
                key={tabName.id}
                value={tabName.id}
                label={tabName.name}
                {...a11yProps(tabName.id)}
                sx={{
                  '&.Mui-selected': {
                    color: '#000'
                  }
                }}
              />
            ))}
          </Tabs>
        </Box>
        {taskMeta.id && selectedTab.id === 0 && (
          <TabPanelEnvironmentalVariables
            selectedTab={selectedTab.id}
            tabIndex={0}
            task={taskMeta}
            tasksUpdates={tasksUpdates}
            setTasksUpdates={setTasksUpdates}
          />
        )}
        {/* Actual Data alarms configurations */}
        {taskMeta.id &&
          taskMeta.latency_metric_alarm_id &&
          selectedTab.id === 1 && (
            <TabPanelAbstractMetric
              taskId={taskMeta.id}
              alarmId={taskMeta.latency_metric_alarm_id!}
              metricUpdateType={MetricUpdateTypes.LATENCY}
              selectedTab={selectedTab}
              tabIndex={1}
              tasksUpdates={tasksUpdates}
              setTasksUpdates={setTasksUpdates}
            />
          )}
        {taskMeta.id &&
          taskMeta.response_value_metric_alarm_id &&
          selectedTab.id === 2 && (
            <TabPanelAbstractMetric
              taskId={taskMeta.id}
              alarmId={taskMeta.response_value_metric_alarm_id!}
              metricUpdateType={MetricUpdateTypes.RESPONSE_VALUE}
              selectedTab={selectedTab}
              tabIndex={2}
              tasksUpdates={tasksUpdates}
              setTasksUpdates={setTasksUpdates}
            />
          )}
        {/* Forecast Data alarms configurations */}
        {taskMeta.id &&
          taskMeta.forecasted_latency_metric_alarm_id &&
          selectedTab.id === 3 && (
            <TabPanelAbstractMetric
              taskId={taskMeta.id}
              alarmId={taskMeta.forecasted_latency_metric_alarm_id!}
              metricUpdateType={MetricUpdateTypes.FORECASTED_LATENCY}
              selectedTab={selectedTab}
              tabIndex={3}
              tasksUpdates={tasksUpdates}
              setTasksUpdates={setTasksUpdates}
            />
          )}
        {taskMeta.id &&
          taskMeta.forecasted_response_value_metric_alarm_id &&
          selectedTab.id === 4 && (
            <TabPanelAbstractMetric
              taskId={taskMeta.id}
              alarmId={taskMeta.forecasted_response_value_metric_alarm_id!}
              metricUpdateType={MetricUpdateTypes.FORECASTED_RESPONSE_VALUE}
              selectedTab={selectedTab}
              tabIndex={4}
              tasksUpdates={tasksUpdates}
              setTasksUpdates={setTasksUpdates}
            />
          )}
      </Box>
    </Container>
  ) : (
    defaultBox
  )
}
