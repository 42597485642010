import { useEffect, useRef } from 'react'

function useDocumentTitle(
  title: string | undefined,
  prevailOnUnmount: boolean = false
) {
  const defaultTitle = useRef(document.title)

  useEffect(() => {
    if (title !== undefined && title !== null) {
      document.title = `Cloud Canaries - ${title}`
    }
  }, [title])

  useEffect(
    () => () => {
      if (!prevailOnUnmount && title !== undefined && title !== null) {
        document.title = defaultTitle.current
      }
    },
    [prevailOnUnmount, title]
  )
}

export default useDocumentTitle
