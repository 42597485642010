import { useCallback, useContext, useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Icon, { IconType } from 'components/Icons'
import DialogModal from 'components/Modals/DialogModal'
import ModalSharedStyles from 'components/Modals/ModalSharedStyles'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { CanaryReadWriteMinimal } from 'lib/CloudCanariesRestfulAPI'
import { DAYS_OF_WEEK, DAY_OPTIONS } from 'lib/util'
import { canaryDisplaySchedule } from 'services/Cron'

interface ModalConfirmDownloadAgentCanaryProps {
  canary: CanaryReadWriteMinimal
  afterSubmit: () => void
}

export default function ModalConfirmDownloadAgentCanary(
  props: ModalConfirmDownloadAgentCanaryProps
) {
  const { canary, afterSubmit } = props
  const classes = ModalSharedStyles()

  const { apiService } = useContext(APIServiceContext)
  const canaryAgentMinutesSchedule =
    60 / canaryDisplaySchedule(canary.schedule!).frequency

  const [isDownloadConfirmOpen, setDownloadConfirmOpen] = useState(false)
  const [runDays, setRunDays] = useState('24x7')

  const [warningText, setWarningText] = useState(
    `Canary will run ${runDays} at a frequency of every ${canaryAgentMinutesSchedule} minutes.`
  )

  useEffect(() => {
    const tokens = canary.schedule?.split(' ')[4].split(',')

    let selectedDays: boolean[] = Array(DAY_OPTIONS['weekly'].length).fill(
      false
    )
    if (tokens && tokens[0] === '*')
      selectedDays = Array(DAY_OPTIONS['weekly'].length).fill(true)

    // format of each token is a single number, no ranges
    tokens?.forEach((token) => (selectedDays[parseInt(token) - 0] = true))

    // calculate ranges of days for easier displaying
    let description = ''
    const days = selectedDays.flatMap((selected, index) =>
      selected ? [index] : []
    )
    if (days.length === 0) {
      description = 'never active'
    }

    if (days.length > 0) {
      // display for which recurring days
      let line1 = ''
      if (days.length === selectedDays.length) {
        line1 = 'every day'
      } else {
        const ranges = [[days[0], days[0]]]
        for (let day of days) {
          const lastRange = ranges[ranges.length - 1]
          if (day <= lastRange[1] + 1) {
            lastRange[1] = day
          } else {
            ranges.push([day, day])
          }
        }
        const tokens = ranges.map((range) =>
          range[0] === range[1]
            ? DAYS_OF_WEEK[range[0]]
            : `${DAYS_OF_WEEK[range[0]]} thru ${DAYS_OF_WEEK[range[1]]}`
        )
        line1 += `on every ${tokens.join(', ')}`
      }
      description = line1
    }
    setRunDays(description)
  }, [canary.schedule])

  useEffect(() => {
    setWarningText(
      `Canary will run ${runDays} at a frequency of every ${canaryAgentMinutesSchedule} minutes.`
    )
  }, [canaryAgentMinutesSchedule, runDays])

  const getCanaryMeta = useCallback(async () => {
    if (isDownloadConfirmOpen && canary.id) {
      await apiService.retrieveCanary(canary.id).then(async (json) => {
        await apiService
          .retrieveCanaryTask(json.data.tasks![0]!.id!)
          .then((taskJson) => {
            if (
              !Object.values(taskJson.data.env_vars!).some(
                (x) => x !== null && x !== ''
              )
            ) {
              setWarningText(
                `Canary will run ${runDays} at a frequency of every ${canaryAgentMinutesSchedule} minutes.  The env variables have not been set in the Edit Page.`
              )
            }
          })
      })
    }
  }, [
    apiService,
    canary.id,
    canaryAgentMinutesSchedule,
    isDownloadConfirmOpen,
    runDays
  ])

  useEffect(() => {
    getCanaryMeta()
  }, [getCanaryMeta])

  const handleClickOpen = () => {
    setDownloadConfirmOpen(true)
  }

  const handleClose = () => {
    setDownloadConfirmOpen(false)
  }

  const confirmDelete = () => {
    afterSubmit()
    handleClose()
  }

  return (
    <>
      Agent
      <IconButton
        color="primary"
        aria-label="Download Canary Agent Zip File"
        style={{
          marginLeft: '0.5rem'
        }}
        disabled={!canary.is_agent_canary}
        disableFocusRipple
        disableRipple
        disableTouchRipple
        onClick={handleClickOpen}
        title="Download Canary Agent Zip File"
      >
        <Icon name={IconType.Download} color="primary" />
      </IconButton>
      <DialogModal
        open={isDownloadConfirmOpen}
        onClose={handleClose}
        dialogTitle="Download Canary Agent"
        dialogContent={
          <>
            <Typography variant="body1" align="center">
              {warningText}
            </Typography>
            <Typography
              variant="body1"
              align="center"
              style={{ marginTop: '0.5rem' }}
            >
              Do you want to continue?
            </Typography>
          </>
        }
        dialogActions={
          <>
            <Button onClick={handleClose}>No</Button>
            <Button
              onClick={confirmDelete}
              variant="contained"
              className={classes.yesButton}
              autoFocus
            >
              Yes
            </Button>
          </>
        }
      />
    </>
  )
}
