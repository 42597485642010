import logo from 'assets/logo.png'
import Icon, { IconType } from 'components/Icons'
import Button from 'components/common/Button'
import { Container } from 'styles/components/CanaryEditor/EditorMenu.styled'

interface EditorMenuProps {
  canaryName: string
  onClose: () => void
}

export default function EditorMenu(props: EditorMenuProps) {
  const { canaryName, onClose } = props
  return (
    <Container>
      <img src={logo} width="45" height="45" alt="logo" />
      <h1>{canaryName}</h1>
      <Button
        label={''}
        style={{ marginLeft: 'auto' }}
        leftIcon={<Icon name={IconType.X} />}
        action={() => {
          onClose()
        }}
      />
    </Container>
  )
}
