import { RJSFSchema } from '@rjsf/utils'
import {
  configCanaryHealthyResponseCriteriaBoolean,
  configCanaryHealthyResponseCriteriaDecimal,
  configCanaryHealthyResponseCriteriaDefault,
  configCanaryHealthyResponseCriteriaInteger
} from 'constants/formconfig/CanaryHealthyResponseCriteria'
import {
  CanaryDetailTaskReadOnlyMinimal,
  CanaryServiceProvider,
  CanaryServiceProviderAvailabilityZone,
  CanaryServiceProviderRegion,
  CanaryableService,
  OrganizationAPIKey,
  ServiceComplianceSummary,
  ServiceDailyForecast,
  ServiceHealth,
  TemplateCanaryReadOnlyListItem,
  UserOrganization
} from 'lib/CloudCanariesRestfulAPI'
import { range } from 'lib/util'
import { cleanHumanReadableTaskName } from './utils'

interface LabelableItem {
  id: string
  name: string
}

function isOrganizationAPIKey(item: any): item is OrganizationAPIKey {
  return 'prefix' in item
}

export default class CanarySchemaService {
  static generateCronSchema() {
    const hourOptions = range(0, 23)
    const dayOfWeekOptions = range(0, 7)
    return {
      schema: {
        title: 'Schedule',
        type: 'object',
        required: [],
        properties: {
          frequency: {
            type: 'number',
            title: 'Every X minutes',
            minimum: 1,
            maximum: 60,
            default: 1
          },
          hour_start: {
            type: 'number',
            title: 'Starting At',
            enum: hourOptions,
            default: 0
          },
          hour_end: {
            type: 'number',
            title: 'Ending At',
            enum: hourOptions,
            default: 23
          },
          day_of_week: {
            type: 'array',
            title: 'On Day of Week',
            items: { enum: dayOfWeekOptions, type: 'number' },
            default: dayOfWeekOptions,
            multiple: true
          }
        }
      }
    }
  }

  private generateBooleanFieldSchema(
    typeName: string | null,
    readOnly: boolean = false
  ) {
    return {
      title: typeName,
      type: 'boolean',
      enum: [true, false],
      readOnly: readOnly
    }
  }

  private generateFieldSchema(
    typeName: string | null,
    fieldValues:
      | TemplateCanaryReadOnlyListItem[]
      | UserOrganization[]
      | OrganizationAPIKey[],
    readOnly: boolean = false,
    fieldType: string = 'string'
  ) {
    const enumNames: string[] = fieldValues.flatMap((value, index) => {
      let name = ''
      if (isOrganizationAPIKey(value) && value.prefix) {
        if (index === 0) {
          name = `${value.prefix} - Default Key`
        } else {
          name = value.prefix
        }
      } else if (value.name) {
        name = value.name
      }
      return name
    })
    const enumValues: string[] = fieldValues
      .flatMap((value) => {
        if (isOrganizationAPIKey(value)) {
          return value.prefix
        }
        return value.id
      })
      .flatMap((f) => (f ? [f] : []))
    return {
      title: typeName,
      type: fieldType,
      enum: enumValues,
      enumNames: enumNames,
      readOnly: readOnly
    }
  }

  private generateNullableFieldSchema(
    typeName: string,
    fieldValues: LabelableItem[],
    readOnly: boolean = false
  ) {
    const enumNames: string[] = fieldValues.flatMap((o) =>
      o.name ? o.name : ''
    )
    const enumValues: string[] = fieldValues.flatMap((o) => {
      return o.id
    })
    return {
      title: typeName,
      type: 'string',
      enum: enumValues,
      enumNames: enumNames,
      readOnly: readOnly
    }
  }

  generateCanaryHealthyResponseCriteriaSchema(dataType: string) {
    const defaultSchema = configCanaryHealthyResponseCriteriaDefault.schema
    const booleanSchema = configCanaryHealthyResponseCriteriaBoolean.schema
    const integerSchema = configCanaryHealthyResponseCriteriaInteger.schema
    const decimalSchema = configCanaryHealthyResponseCriteriaDecimal.schema

    if (dataType === 'integer') {
      return integerSchema
    } else if (dataType === 'boolean') {
      return booleanSchema
    } else if (dataType === 'decimal') {
      return decimalSchema
    }
    return defaultSchema
  }

  generateCanaryLogicSchema = (
    canaryTypes: TemplateCanaryReadOnlyListItem[]
  ) => {
    return {
      type: 'object',
      required: ['step_type'],
      properties: {
        step_type: this.generateFieldSchema(' ', canaryTypes)
      }
    } as RJSFSchema
  }

  generateSelectTaskSchema = (tasks: CanaryDetailTaskReadOnlyMinimal[]) => {
    return {
      type: 'object',
      required: ['selected_task'],
      properties: {
        selected_task: this.generateFieldSchema(null, tasks)
      }
    } as RJSFSchema
  }

  generateSelectProviderSchema = (
    services:
      | ServiceHealth[]
      | ServiceComplianceSummary[]
      | ServiceDailyForecast[]
  ) => {
    let uniqueProviders = new Map<string, string>()

    services.forEach((s) => {
      uniqueProviders.set(s.provider_id!, s.provider_name!)
    })

    let providers = Array.from(uniqueProviders.entries()).flatMap((o) => {
      return { id: o[0]!, name: o[1]! }
    })

    const noProvider = { id: '', name: 'All providers' }
    providers.splice(0, 0, noProvider)
    return {
      type: 'object',
      required: ['selected_service_provider'],
      properties: {
        selected_service_provider: this.generateNullableFieldSchema(
          'Select Service Provider',
          providers
        )
      }
    } as RJSFSchema
  }

  generateCanaryMetaSchema = (
    serviceProviders: CanaryServiceProvider[],
    serviceProviderServices: CanaryableService[],
    canaryTypes: TemplateCanaryReadOnlyListItem[],
    serviceProviderRegions: CanaryServiceProviderRegion[],
    serviceProviderAvailabilityZones: CanaryServiceProviderAvailabilityZone[],
    apiKeys: OrganizationAPIKey[],
    canSetService = false,
    canSetCanaryType = false,
    canSetRegion = false,
    canSetAvailabilityZone = false,
    canaryName: string = ''
  ) => {
    const _canaryTypes = canaryTypes.map((ct) => {
      let _ct = ct
      _ct.name = cleanHumanReadableTaskName(ct.name!)
      return _ct
    })
    const _properties = {
      service_provider: this.generateFieldSchema(
        'Service Provider',
        serviceProviders
      ),
      service: this.generateFieldSchema(
        'Service',
        serviceProviderServices,
        !canSetService
      ),
      canary_type: this.generateFieldSchema(
        'Canary Type',
        _canaryTypes,
        !canSetCanaryType
      ),
      api_key: this.generateFieldSchema('API Key', apiKeys),
      region: this.generateFieldSchema(
        'Region',
        serviceProviderRegions,
        !canSetRegion
      ),
      availability_zone: this.generateFieldSchema(
        'Availability Zone',
        serviceProviderAvailabilityZones,
        !canSetAvailabilityZone
      ),
      is_canary_agent: this.generateBooleanFieldSchema(
        'Is Canary Agent?',
        false
      ),
      name: {
        title: 'Canary Name',
        type: 'string'
      }
    }

    return {
      title: undefined,
      type: 'object',
      required: Object.keys(_properties),
      properties: _properties
    } as RJSFSchema
  }
}
