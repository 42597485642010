import styled from 'styled-components'

export const ControlsContainerLeft = styled.div`
  display: flex;
  align-items: center;
  > :first-child {
    text-decoration: none;
  }

  .right-start {
    margin-left: auto;
  }

  button.MuiButton-outlinedPrimary {
    margin-right: 1em;
  }

  .MuiInput-formControl {
    margin-top: 0;
  }

  div.MuiFormControl-root {
    padding-left: 1em;
  }
`

export const ControlsContainerRight = styled.div`
  display: flex;
  margin-bottom: 1rem;
  justify-content: flex-end;

  > :first-child {
    text-decoration: none;
  }

  .right-start {
    margin-left: auto;
  }

  button.MuiButton-outlinedPrimary {
    margin-right: 1em;
  }
`
