import { Theme } from '@mui/material/styles'
import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles(({ spacing }: Theme) =>
  createStyles({
    button: {
      padding: spacing(1),
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: 1.5,
      letterSpacing: 0,
      textDecoration: 'none',
      borderRadius: 4,
      textTransform: 'capitalize'
    },
    forgotPasswordButton: {
      textTransform: 'capitalize',
      borderColor: 'transparent',
      fontSize: '1rem',
      fontWeight: 700,
      float: 'right',
      borderRadius: '5px',
      marginTop: 8,
      backgroundColor: '#4eb020',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#4eb020'
      }
    }
  })
)
