import {
  selectDateFrom,
  selectDateTo,
  selectTimeFrom,
  selectTimeTo
} from 'client_side_state/slices/pageFilter'
import { useAppSelector } from 'client_side_state/store'
import { useContext } from 'react'
import Button from '@mui/material/Button'
import { makeStyles } from '@mui/styles'
import { APIServiceContext } from 'contexts/APIServiceContext'
import DateTimeService from 'services/DateTimeService'

const useStyles = makeStyles({
  exportBtn: {
    background: 'cyan !important',
    border: 'cyan !important',
    color: 'black !important',
    fontWeight: 700,
    marginInline: 8,
    paddingTop: 8,
    paddingBottom: 8
  }
})

const datetimeService: DateTimeService = new DateTimeService()

interface ExportDataButtonProps {
  canaryId: string
}

export default function ExportDataButton(props: ExportDataButtonProps) {
  const { canaryId } = props
  const classes = useStyles()

  const pageDateFromFilter = useAppSelector(selectDateFrom)
  const pageDateToFilter = useAppSelector(selectDateTo)

  const pageTimeFromFilter = useAppSelector(selectTimeFrom)
  const pageTimeToFilter = useAppSelector(selectTimeTo)

  const { apiService } = useContext(APIServiceContext)

  const start = datetimeService.datetimeformatted(
    pageDateFromFilter,
    pageTimeFromFilter
  )
  const stop = datetimeService.datetimeformatted(
    pageDateToFilter,
    pageTimeToFilter
  )

  const measuredFrom = datetimeService.djangoDateTimeValue(start)
  const measuredTo = datetimeService.djangoDateTimeValue(stop)

  async function exportCanaryData() {
    await apiService
      .listCanaryTaskRunDataExports_1(measuredFrom, measuredTo, canaryId)
      .then((response) => {
        if (response) {
          const blob = new Blob([response.data.toString()], {
            type: 'text/csv;charset=utf-8;'
          })
          const link = document.createElement('a')
          const url = URL.createObjectURL(blob)
          link.setAttribute('href', url)
          link.setAttribute('download', `export.csv`)
          link.style.visibility = 'hidden'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      })
  }

  return (
    <Button
      variant="outlined"
      size="small"
      className={classes.exportBtn}
      onClick={() => exportCanaryData()}
    >
      Export
    </Button>
  )
}
