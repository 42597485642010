import {
  SimpleAlarmComparisonOperatorEnum,
  SimpleAlarmEvaluationStatusEnum
} from 'lib/CloudCanariesRestfulAPI'
import { FormConfigTypes } from './FormConfigsTypes'

export const configEditAlarmConfigurationCombined: FormConfigTypes = {
  schema: {
    type: 'object',
    required: [
      'name',
      'comparison_operator',
      'comparison_true_status',
      'no_data_status',
      'data_points_to_trigger',
      'period'
    ],
    properties: {
      name: { type: 'string', title: 'Name' },
      comparison_operator: {
        type: 'string',
        title: 'Comparison Operator',
        enum: Object.values(SimpleAlarmComparisonOperatorEnum)
      },
      comparison_true_status: {
        type: 'string',
        title: 'Comparison True Status',
        enum: Object.values(SimpleAlarmEvaluationStatusEnum)
      },
      no_data_status: {
        type: 'string',
        title: 'No Data Status',
        enum: Object.values(SimpleAlarmEvaluationStatusEnum)
      },
      data_points_to_trigger: {
        type: 'number',
        title: 'Data Points to Trigger'
      },
      period: { type: 'number', title: 'Period' },
      boundary_boolean_value: {
        type: ['null', 'boolean'],
        title: 'boundary_boolean_value'
      },
      boundary_integer_value_min: {
        type: ['null', 'integer'],
        title: 'boundary_integer_value_min'
      },
      boundary_integer_value_max: {
        type: ['null', 'integer'],
        title: 'boundary_integer_value_max'
      },
      boundary_decimal_value_min: {
        type: ['null', 'number'],
        title: 'boundary_decimal_value_min'
      },
      boundary_decimal_value_max: {
        type: ['null', 'number'],
        title: 'boundary_decimal_value_max'
      },
      boundary_string_value_contains_case_sensitive: {
        type: ['null', 'string'],
        title: 'boundary_string_value_contains_case_sensitive'
      },
      boundary_string_value_contains_not_case_sensitive: {
        type: ['null', 'string'],
        title: 'boundary_string_value_contains_not_case_sensitive'
      },
      included_as_downtime_alarm: {
        type: 'string',
        title: ''
      },
      enabled: {
        type: 'boolean',
        title: ''
      }
    }
  }
}
