import { SourceFileType } from 'components/CustomizableCanary/SourceFileEditor'
import SourceLibraryEditor from 'components/CustomizableCanary/SourceLibraryEditor'
import { GenericIDMatchProps } from 'interfaces/GenericIDMatchProps'
import 'styles/pages/SourceCodeEditor.scss'

export default function RestfulLibEdit(props: GenericIDMatchProps) {
  const {
    match: {
      params: { id: schemaId }
    }
  } = props
  return (
    <SourceLibraryEditor
      schemaId={schemaId}
      sourceFileType={SourceFileType.RestfulLib}
    />
  )
}
