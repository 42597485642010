import Box from '@mui/system/Box'
import Cloud from 'components/Forecast/Cloud'

interface CloudBoxProps {
  name: string
  percent: number
  hasPrediction: boolean
}

export default function CloudBox(props: CloudBoxProps) {
  return (
    <Box
      sx={{
        textAlign: 'center',
        margin: '1em',
        transition: '1s',
        '& > p': {
          fontStyle: 'italic',
          fontSize: '1em'
        },
        '&:hover': {
          transform: 'scale(1.1)',
          cursor: 'pointer',
          '& > p': {
            color: 'blue'
          }
        }
      }}
    >
      <Cloud percent={props.percent} hasPrediction={props.hasPrediction} />
      <p>{props.name}</p>
    </Box>
  )
}
