import { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import PerTaskAlarmEditorPanel from 'components/Alarms/PerTaskAlarmEditorPanel'
import EditorMenu from 'components/MetricNotifications/EditorMenu'
import { APIServiceContext } from 'contexts/APIServiceContext'
import CanaryMetricsContextProvider from 'contexts/CanaryMetricsContext'
import { OrganizationsContext } from 'contexts/OrganizationsContext'
import { GenericIDMatchProps } from 'interfaces/GenericIDMatchProps'
import RoutesService from 'services/routes'

export default function CanaryMetricsPage(props: GenericIDMatchProps) {
  const {
    match: {
      params: { id: canaryId }
    }
  } = props
  const history = useHistory()
  // const lastSelectedUserOrganization = useAppSelector(
  //   selectLastSeletedOrganization
  // )

  const { apiService } = useContext(APIServiceContext)
  const { lastSelectedOrganization, setUserSelectedOrganization } =
    useContext(OrganizationsContext)

  const [canaryName, setName] = useState<string>('')

  const getCanaryMeta = useCallback(async () => {
    await apiService.retrieveCanary(canaryId).then((json) => {
      setName(json.data.name ?? 'Canary Name')
    })
  }, [apiService, canaryId])

  useEffect(() => {
    if (canaryId) {
      getCanaryMeta()
    }
  }, [getCanaryMeta, canaryId])

  const onCanaryMetricsPageClose = () => {
    setUserSelectedOrganization(lastSelectedOrganization)
    history.push({
      pathname: RoutesService.dashboardCanaries()
    })
  }

  return (
    <CanaryMetricsContextProvider canaryId={canaryId}>
      <EditorMenu canaryName={canaryName} onClose={onCanaryMetricsPageClose} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} style={{ overflowX: 'scroll' }}>
          <PerTaskAlarmEditorPanel />
        </Grid>
      </Grid>
    </CanaryMetricsContextProvider>
  )
}
