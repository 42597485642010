import useIsMatchingMD from 'hooks/useIsMatchingMD'
import { useMemo, useState } from 'react'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import { Theme } from '@mui/material/styles'
import { createStyles, makeStyles } from '@mui/styles'
import CanaryTaskGraphSingleSelector from 'components/common/CanaryTaskGraphSingleSelector'
import SearchField from 'components/common/SearchField'
import { CanaryReadWriteMinimal } from 'lib/CloudCanariesRestfulAPI'
import CanaryGridItem from './CanaryGridItem'
import CanaryStatusFilter from './CanaryStatusFilter'

const useStyles = makeStyles(({ breakpoints }: Theme) =>
  createStyles({
    canariesGridContainer: {
      marginTop: '1.5rem'
    },
    filtersContainer: {
      marginBottom: '1.5rem',
      alignItems: 'center'
    },
    leftSideFiltersContainer: {
      [breakpoints.down('sm')]: {
        '& .MuiGrid-item': {
          width: '100%'
        },
        flexDirection: 'column',
        alignItems: 'center'
      }
    },
    searchFieldContainer: {
      display: 'flex',
      justifyContent: 'flex-end'
    }
  })
)

interface CanariesGridProps {
  canaries: CanaryReadWriteMinimal[]
  serviceName: string
}

type Filter = { type: keyof CanaryReadWriteMinimal; value: string }

export default function CanariesGrid(props: CanariesGridProps) {
  const { canaries, serviceName } = props
  const classes = useStyles()

  const isMatchingMD = useIsMatchingMD()

  const [filters, setFilters] = useState<Filter[]>([])
  const [selectedRegionName, setSelectedRegionName] = useState<string>('')
  const [selectedZoneName, setSelectedZoneName] = useState<string>('')
  const [selectedStatus, setSelectedStatus] = useState('')

  // TODO: Filter show canaries using search terms on the client side
  const handleSearch = (search: string) => {
    if (search.length === 0)
      setFilters(filters.filter((filter) => filter.type !== 'name'))
    else
      setFilters(
        filters
          .filter((filter) => filter.type !== 'name')
          .concat({ type: 'name', value: search })
      )
  }

  const visibleCanaries = useMemo(() => {
    let _visible_canaries = canaries
    if (selectedRegionName) {
      _visible_canaries = _visible_canaries.filter(
        (o) => o.region_name === selectedRegionName
      )
    }
    if (selectedZoneName) {
      _visible_canaries = _visible_canaries.filter(
        (o) => o.availability_zone_name === selectedZoneName
      )
    }
    return _visible_canaries.filter((c) => {
      if (selectedStatus !== '') {
        return c.status === selectedStatus
      } else {
        return c
      }
    })
  }, [canaries, selectedRegionName, selectedStatus, selectedZoneName])

  const hasVisibleCanaries = visibleCanaries.length > 0

  const onRegionNameChange = (event: any) => {
    setSelectedRegionName(event.target.value)
    setSelectedZoneName('')
  }

  const regionNameOptions = useMemo<string[]>(
    () =>
      canaries
        .flatMap((c) => c.region_name!)
        .filter((v, i, a) => a.indexOf(v) === i),
    [canaries]
  )

  const zoneNameOptions = useMemo<string[]>(
    () =>
      canaries
        .flatMap((c) => c.availability_zone_name!)
        .filter((v, i, a) => a.indexOf(v) === i),
    [canaries]
  )

  const onZoneNameChange = (event: any) => {
    setSelectedZoneName(event.target.value)
  }

  return (
    <Grid container spacing={2} className={classes.canariesGridContainer}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={4}>
          <h1 style={{ textAlign: 'center' }}>{serviceName} Canaries</h1>
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.filtersContainer}>
        <Grid item xs={12} md={9}>
          <Grid
            container
            spacing={5}
            className={classes.leftSideFiltersContainer}
          >
            <Grid item xs={12} md={2}>
              <FormControl fullWidth size="small">
                <InputLabel id="canaries-grid-select-helper-label-region">
                  Region
                </InputLabel>
                <Select
                  style={{ minWidth: '120px' }}
                  labelId="canaries-grid-select-helper-label-region"
                  id="simple-select-helper-region"
                  value={selectedRegionName}
                  label="Region"
                  onChange={onRegionNameChange}
                  autoWidth={!isMatchingMD}
                  fullWidth={isMatchingMD}
                >
                  <MenuItem value="">
                    <em>All Regions</em>
                  </MenuItem>
                  {regionNameOptions.map((o) => {
                    return (
                      <MenuItem key={o} value={o}>
                        {o}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl fullWidth size="small">
                <InputLabel id="canaries-grid-select-helper-label-zone">
                  Zone
                </InputLabel>
                <Select
                  style={{ minWidth: '120px' }}
                  labelId="canaries-grid-select-helper-label-zone"
                  id="simple-select-helper-zone"
                  value={selectedZoneName}
                  label="Zone"
                  onChange={onZoneNameChange}
                  autoWidth={!isMatchingMD}
                  fullWidth={isMatchingMD}
                >
                  <MenuItem value="">
                    <em>All Availability Zones</em>
                  </MenuItem>
                  {zoneNameOptions.map((o) => {
                    return (
                      <MenuItem key={o} value={o}>
                        {o}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <CanaryTaskGraphSingleSelector />
            </Grid>
            <Grid item xs={12} md={2}>
              <CanaryStatusFilter
                selectedStatus={selectedStatus}
                setSelectedStatus={setSelectedStatus}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={3} className={classes.searchFieldContainer}>
          <SearchField handleSubmit={handleSearch} width={230} searchText="" />
        </Grid>
      </Grid>
      <br />
      <Grid
        container
        spacing={2}
        justifyContent={hasVisibleCanaries ? 'flex-start' : 'center'}
      >
        {hasVisibleCanaries ? (
          visibleCanaries.map((canary) => (
            <Grid key={canary.id} item xs={12} md={3} lg={2}>
              <CanaryGridItem
                canaryName={canary.name!}
                canaryId={canary.id!}
                isAlarming={Boolean(canary.has_alarms_alarming!)}
                hidden={false}
              />
            </Grid>
          ))
        ) : (
          <Typography variant="h3">No canaries found...</Typography>
        )}
      </Grid>
    </Grid>
  )
}
