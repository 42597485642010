import { FormConfigTypes } from './FormConfigsTypes'

export const configCanaryHealthyResponseCriteriaDefault: FormConfigTypes = {
  schema: {
    title: '',
    type: 'object',
    required: ['latency_allowed_min', 'latency_allowed_max'],
    properties: {
      latency_allowed_min: {
        type: 'integer',
        title: 'Allowed Latency (min)'
      },
      latency_allowed_max: {
        type: 'integer',
        title: 'Allowed Latency (max)'
      },
      healthy_boolean_value: {
        type: ['boolean', 'null'],
        title: 'Healthy Boolean value'
      },
      healthy_integer_value_min: {
        type: ['integer', 'null'],
        title: 'Healthy Integer Value (min)'
      },
      healthy_integer_value_max: {
        type: ['integer', 'null'],
        title: 'Healthy Integer Value (max)'
      },
      healthy_string_value_starts_with: {
        type: ['string', 'null'],
        title: 'Healthy string Value (starts with)'
      },
      healthy_string_value_ends_with: {
        type: ['string', 'null'],
        title: 'Healthy string Value (ends with)'
      },
      healthy_string_value_contains: {
        type: ['string', 'null'],
        title: 'Healthy string Value (contains)'
      }
    }
  }
}

export const configCanaryHealthyResponseCriteriaBoolean = {
  schema: {
    title: '',
    type: 'object',
    required: ['latency_allowed_min', 'latency_allowed_max'],
    properties: {
      latency_allowed_min: {
        type: 'integer',
        title: 'Allowed Latency (min)'
      },
      latency_allowed_max: {
        type: 'integer',
        title: 'Allowed Latency (max)'
      },
      healthy_boolean_value: {
        type: ['boolean'],
        title: 'Healthy value'
      }
    }
  }
}

export const configCanaryHealthyResponseCriteriaInteger = {
  schema: {
    title: '',
    type: 'object',
    required: ['latency_allowed_min', 'latency_allowed_max'],
    properties: {
      latency_allowed_min: {
        type: 'integer',
        title: 'Allowed Latency (min)'
      },
      latency_allowed_max: {
        type: 'integer',
        title: 'Allowed Latency (max)'
      },
      healthy_integer_value_min: {
        type: ['integer'],
        title: 'Healthy Value (min)'
      },
      healthy_integer_value_max: {
        type: ['integer'],
        title: 'Healthy Value (max)'
      }
    }
  }
}

export const configCanaryHealthyResponseCriteriaDecimal = {
  schema: {
    title: '',
    type: 'object',
    required: ['latency_allowed_min', 'latency_allowed_max'],
    properties: {
      latency_allowed_min: {
        type: 'integer',
        title: 'Allowed Latency (min)'
      },
      latency_allowed_max: {
        type: 'integer',
        title: 'Allowed Latency (max)'
      },
      healthy_decimal_value_min: {
        type: ['number'],
        title: 'Healthy Value (min)'
      },
      healthy_decimal_value_max: {
        type: ['number'],
        title: 'Healthy Value (max)'
      }
    }
  }
}
