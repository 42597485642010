import {
  selectSelectedTaskGraph,
  setCanaryTaskSelectedGraph
} from 'client_side_state/slices/pageFilter'
import { useAppDispatch, useAppSelector } from 'client_side_state/store'
import useIsMatchingMD from 'hooks/useIsMatchingMD'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Icon, { IconType } from 'components/Icons'
import { CanaryGraphMetrics } from 'constants/CanaryGraphMetrics'
import { underScoreStringToFirstLetterCapital } from 'services/utils'

const metricTypesOptions = Object.values(CanaryGraphMetrics).map((value) => ({
  label: underScoreStringToFirstLetterCapital(value),
  value: value
}))

export default function CanaryTaskGraphSingleSelector() {
  const isMatchesMD = useIsMatchingMD()
  const dispatch = useAppDispatch()

  const selected = useAppSelector(selectSelectedTaskGraph)

  const handleChange = (event: SelectChangeEvent) => {
    dispatch(
      setCanaryTaskSelectedGraph(event.target.value as CanaryGraphMetrics)
    )
  }

  const selectedLabel = metricTypesOptions.find(
    (option) => option.value === selected
  )?.label

  return (
    <FormControl fullWidth size="small">
      <Select
        value={selected}
        renderValue={() =>
          selected ? 'Displayed Graph: ' + selectedLabel : 'Displayed Graph'
        }
        labelId="canaries-grid-display-graphp-select"
        autoWidth={!isMatchesMD}
        fullWidth={isMatchesMD}
        displayEmpty
        onChange={handleChange}
      >
        {metricTypesOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {selected === option.value ? <Icon name={IconType.Check} /> : null}{' '}
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
