import { CanaryDetailTaskReadOnlyMinimal } from 'lib/CloudCanariesRestfulAPI'

interface GraphiteGraphProps {
  canaryTask: CanaryDetailTaskReadOnlyMinimal
  metricName: string
  graphiteFrom: string
  graphiteUntil: string
  width: number
}
export default function GraphiteGraph(props: GraphiteGraphProps) {
  const { canaryTask, metricName, graphiteFrom, graphiteUntil, width } = props
  return (
    <img
      alt={metricName + graphiteFrom + graphiteUntil}
      width={width + '%'}
      src={
        canaryTask?.graph_url +
        '?metric=' +
        metricName +
        '&from=' +
        graphiteFrom +
        '&until=' +
        graphiteUntil
      }
    />
  )
}
