import styled from 'styled-components'

export const Container = styled.div<{ $createMode: boolean }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  overflow-x: hidden;
  z-index: 99;

  hr {
    margin: 40px auto 20px !important;
    width: 98%;
    height: 1px;
    background-color: rgba(0, 0, 128, 0.6);
  }

  .info {
    display: block;
    font-style: italic;
    font-size: 11pt;
    margin-bottom: 5px;
  }

  .placeholder {
    background-color: #f4f4f4;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .main {
    display: flex;
    flex: 1;
    height: 0;
    z-index: 3;
    align-items: stretch;

    .properties {
      /* flex: ${({ $createMode }) => ($createMode ? 10 : 0)} 320px; */
      flex: 0.33;
      min-width: 320px;
      flex-grow: inherit;
      padding: 20px;
      background-color: #eee;
      box-shadow: 3px 0 7px rgba(0, 0, 0, 0.2);
      overflow: auto;
      z-index: 4;

      > *:not(:first-child) {
        margin-bottom: 30px;
      }

      :not(.loaded) > *:not(:first-child) {
        animation: 0.15s ease-out fadeIn;
      }
      .title {
        font-size: 18px;
        font-weight: 700;
        margin: 0.75rem 0;
      }
    }

    .graph-container {
      flex: 1 0 360px;
    }

    h1 {
      font-size: 18pt;
      margin-bottom: 20px;
      color: navy;
    }

    > * {
      transition: flex 0.2s;
    }
  }
`
