import { retrieveAuthUserProfile } from 'client_side_state/slices/auth'
import { selectUserProfile } from 'client_side_state/slices/user'
import { useAppDispatch, useAppSelector } from 'client_side_state/store'
import useDocumentTitle from 'hooks/useDocumentTitle'
import { useCallback, useContext, useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import { IChangeEvent } from '@rjsf/core'
import MuiForm from '@rjsf/mui'
import { RJSFSchema } from '@rjsf/utils'
import validator from '@rjsf/validator-ajv8'
import MyAdminPagesCommonHeader from 'components/MyAdminPages/MyAdminPagesCommonHeader'
import ResetPasswordModal from 'components/User/ResetPasswordModal'
import { queryParamPageSizeJumbo } from 'constants/AppConfig'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { AuthContext } from 'contexts/AuthContext'
import { UserRoleReadOnly } from 'lib/CloudCanariesRestfulAPI'
import { UserProfileStyle } from 'styles/pages/User/UserProfile.styled'

export default function UserProfile() {
  const userprofile = useAppSelector(selectUserProfile)
  const [userData, setUserData] = useState(userprofile)
  const [showModal, setShowModal] = useState(false)
  const [userRoles, setUserRoles] = useState<UserRoleReadOnly[]>([])

  const dispatch = useAppDispatch()

  const { unAuthenticatedAPIService } = useContext(AuthContext)
  const { apiService } = useContext(APIServiceContext)

  const fetchUserData = useCallback(async () => {
    dispatch(retrieveAuthUserProfile())
  }, [dispatch])

  useDocumentTitle('User Profile')

  useEffect(() => {
    fetchUserData()
  }, [fetchUserData])

  const fetchUserRoles = useCallback(async () => {
    await unAuthenticatedAPIService
      .listCustomUserRoles(undefined, queryParamPageSizeJumbo)
      .then((json) => {
        setUserRoles(json.data.results ?? [])
      })
  }, [unAuthenticatedAPIService])

  useEffect(() => {
    fetchUserRoles()
  }, [fetchUserRoles])

  const schema: RJSFSchema = {
    title: 'User Profile',
    type: 'object',
    required: ['email'],
    properties: {
      first_name: { type: 'string', title: 'First Name' },
      last_name: { type: 'string', title: 'Last Name' },
      country: { type: 'string', title: 'Country' },
      email: { type: 'string', title: 'Email' },
      phone_number: { type: 'string', title: 'Phone Number' },
      role: {
        type: 'number',
        title: 'Role',
        oneOf: userRoles.map((role) => {
          return {
            const: role.id,
            title: role.name
          }
        })
      }
    }
  }

  const [editingMode, setEditingMode] = useState(false)
  const handleSaveChanges = () => setEditingMode(false)
  const handleCancelChanges = () => setEditingMode(false)

  const onChange = (
    event: IChangeEvent<any, RJSFSchema, any>,
    id?: string | undefined
  ) => {
    setUserData(JSON.parse(JSON.stringify(event.formData)))
  }

  const onSubmit = async (
    data: IChangeEvent<any, RJSFSchema, any>,
    event: React.FormEvent<any>
  ) => {
    const requestBody = JSON.parse(JSON.stringify(data.formData))
    try {
      await apiService
        .partialUpdateUser(requestBody.id!, requestBody)
        .then(() => {
          fetchUserData()
        })
      alert('user info successfully updated')
    } catch {
      setEditingMode(true)
      fetchUserData()
      // TODO: Add extraErrors to the Form
      alert('try inputs again')
    }
    return false
  }

  return (
    <UserProfileStyle className={'pageContainer'} $editingMode={editingMode}>
      <MyAdminPagesCommonHeader />
      <div className="Inner">
        <div className="EditContainer">
          <Button variant="outlined" onClick={() => setEditingMode(true)}>
            Edit Profile
          </Button>
        </div>

        <MuiForm
          className="UserProfileForm"
          schema={schema}
          formData={userData}
          onSubmit={onSubmit}
          disabled={!editingMode}
          onChange={onChange}
          liveValidate
          onError={(e) => setEditingMode(true)}
          validator={validator}
        >
          <div className="SaveAndCancelContainer">
            <Button
              className="SaveButton"
              variant="contained"
              type="submit"
              onClick={handleSaveChanges}
            >
              Save Changes
            </Button>
            <Button
              className="CancelButton"
              variant="contained"
              type="reset"
              onClick={handleCancelChanges}
            >
              Cancel
            </Button>
          </div>
        </MuiForm>

        <div className="ResetPasswordContainer">
          Remember to reset your password every X months
          <span> </span>
          <Button
            className="ResetPasswordButton"
            variant="contained"
            onClick={() => setShowModal(true)}
          >
            Reset Password
          </Button>
          <ResetPasswordModal
            showModal={showModal}
            setShowModal={setShowModal}
          />
        </div>
      </div>
    </UserProfileStyle>
  )
}
