import { useContext, useEffect, useMemo, useState } from 'react'
import { IChangeEvent } from '@rjsf/core'
import MuiForm from '@rjsf/mui'
import {
  ErrorSchema,
  ErrorSchemaBuilder,
  RJSFSchema,
  UiSchema
} from '@rjsf/utils'
import validator from '@rjsf/validator-ajv8'
import { AxiosError, isAxiosError } from 'axios'
import DialogModal from 'components/Modals/DialogModal'
import ModalSharedStyles from 'components/Modals/ModalSharedStyles'
import OrganizationRequiredDialog from 'components/UserOrganization/OrganizationRequiredDialog'
import CreateButton from 'components/common/CreateButton'
import FormsCreateButton from 'components/common/FormsCreateButton'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { OrganizationsContext } from 'contexts/OrganizationsContext'
import { ServiceSettingsGroupContext } from 'contexts/ServiceSettingsGroupContext'
import { ServicesContext } from 'contexts/ServicesContext'
import {
  CanaryServiceProvider,
  CanaryableService,
  ServiceHealthEvaluationSettingsGroupPeriodCategoryEnum
} from 'lib/CloudCanariesRestfulAPI'
import { initialFormData } from './FormData'
import ObjectFieldTemplate from './ObjectFieldTemplate'
import useStyles from './useStyles'

export default function CreateModal() {
  const classes = ModalSharedStyles()
  const extraClasses = useStyles()

  const { apiService } = useContext(APIServiceContext)
  const { organizationId } = useContext(OrganizationsContext)
  const { serviceProviders, canaryableServices } = useContext(ServicesContext)
  const {
    canaries,
    createModalOpen,
    fetchServiceSettingsGroups,
    handleCreateModalClose,
    handleCreateModalOpen
  } = useContext(ServiceSettingsGroupContext)

  const [formData, setFormData] = useState<any>(initialFormData)
  const [filteredServiceProviders, setFilteredServiceProviders] = useState<
    CanaryServiceProvider[] | null
  >(serviceProviders)
  const [filteredCanaryableServices, setFilteredCanaryableServices] = useState<
    CanaryableService[] | null
  >(canaryableServices)
  const [extraErrors, setExtraErrors] = useState<ErrorSchema>({})

  useEffect(() => {
    if (organizationId) {
      setFormData((prev: any) => ({ ...prev, organization: organizationId }))
    }
  }, [organizationId])

  useEffect(() => {
    const newServiceProviders = serviceProviders?.filter((provider) => {
      return provider.organization_id === organizationId
    })
    setFilteredServiceProviders(newServiceProviders ?? [])
  }, [organizationId, serviceProviders])

  useEffect(() => {
    const newCanaryableServices = canaryableServices?.filter((service) => {
      return service.organization_id === organizationId
    })
    setFilteredCanaryableServices(newCanaryableServices ?? [])
  }, [canaryableServices, organizationId])

  const handleFormDataChange = (
    event: IChangeEvent<any, RJSFSchema, any>,
    id?: string | undefined
  ) => {
    const data = JSON.parse(JSON.stringify(event.formData))
    setFormData(data)
  }

  function handleModalClose() {
    setFormData({ ...initialFormData, organization: organizationId })
    setExtraErrors({})
    handleCreateModalClose()
  }

  const onSubmit = async (
    data: IChangeEvent<any, RJSFSchema, any>,
    event: React.FormEvent<any>
  ) => {
    let requestBody = JSON.parse(JSON.stringify(data.formData))

    requestBody.concurrent_alarms_healthy_to_stop_incident =
      requestBody.range_settings.concurrent_alarms_healthy_to_stop_incident
    requestBody.concurrent_alarms_alarming_to_start_incident =
      requestBody.range_settings.concurrent_alarms_alarming_to_start_incident
    requestBody.period_category = requestBody.range_settings.period_category
    requestBody.downtime_seconds_allowed =
      requestBody.range_settings.downtime_seconds_allowed
    requestBody.mean_time_between_system_incidents_seconds_allowed =
      requestBody.range_settings.mean_time_between_system_incidents_seconds_allowed
    requestBody.mean_time_recovery_system_seconds_allowed =
      requestBody.range_settings.mean_time_recovery_system_seconds_allowed
    requestBody.coverage_denominator =
      requestBody.range_settings.coverage_denominator
    requestBody.sla_target_goal = parseFloat(
      requestBody.range_settings.sla_target_goal
    )

    requestBody.events_per_high_urgency =
      requestBody.group.urgency_setting.events_per_high_urgency
    requestBody.events_per_medium_urgency =
      requestBody.group.urgency_setting.events_per_medium_urgency
    requestBody.events_per_low_urgency =
      requestBody.group.urgency_setting.events_per_low_urgency

    requestBody.events_per_high_impact =
      requestBody.group.impact_settings.events_per_high_impact
    requestBody.events_per_medium_impact =
      requestBody.group.impact_settings.events_per_medium_impact
    requestBody.events_per_low_impact =
      requestBody.group.impact_settings.events_per_low_impact

    requestBody.canaries = canaries
      ?.filter((canary) => canary.service === formData.service)
      .map((canary) => {
        return canary.id!
      })
    try {
      await apiService
        .createServiceHealthEvaluationSettingsGroup(requestBody)
        .then((json) => {
          if (json.status === 201) {
            fetchServiceSettingsGroups()
            handleModalClose()
          }
        })
    } catch (e: any) {
      if (isAxiosError(e)) {
        const err = e as AxiosError
        if (err.response?.status === 400) {
          const errors = err.response?.data as {}
          const builder = new ErrorSchemaBuilder()
          Object.entries(errors).forEach(([key, value]) => {
            builder.addErrors(value as string, key)
          })
          setExtraErrors(builder.ErrorSchema)
        }
      }
    }
  }

  const serviceProvidersEnumNames: string[] | undefined =
    filteredServiceProviders?.flatMap((o) => {
      return o.name ?? ''
    })
  const serviceProvidersEnumValues: string[] | undefined =
    filteredServiceProviders
      ?.flatMap((o) => {
        return o.id
      })
      .flatMap((f) => (f ? [f] : []))

  const canaryableServicesEnumNames = useMemo<string[] | undefined>(() => {
    return filteredCanaryableServices
      ?.filter((service) => service.provider_id === formData.provider)
      ?.flatMap((o) => {
        return o.name ?? ''
      })
  }, [filteredCanaryableServices, formData.provider])

  const canaryableServicesEnumValues = useMemo<string[] | undefined>(() => {
    return filteredCanaryableServices
      ?.filter((service) => service.provider_id === formData.provider)
      ?.flatMap((o) => {
        return o.id
      })
      .flatMap((f) => (f ? [f] : []))
  }, [filteredCanaryableServices, formData.provider])

  const canaryEnumValues = useMemo<string[] | undefined>(() => {
    return canaries
      ?.filter((canary) => canary.service === formData.service)
      .map((canary) => canary.id || '')
  }, [canaries, formData.service])

  const canaryEnumNames = useMemo<string[] | undefined>(() => {
    return canaries
      ?.filter((canary) => canary.service === formData.service)
      .map((canary) => canary.name || '')
  }, [canaries, formData.service])

  const schema = {
    type: 'object',
    required: ['name', 'service', 'provider', 'canaries'],
    properties: {
      name: { type: 'string', title: 'Service Settings Group name' },
      range_settings: {
        type: 'object',
        title: 'Range Settings',
        properties: {
          period_category: {
            type: 'string',
            title: 'Period',
            enum: Object.values(
              ServiceHealthEvaluationSettingsGroupPeriodCategoryEnum
            )
          },
          concurrent_alarms_alarming_to_start_incident: {
            type: 'number',
            title: 'Concurrent Alarms to Start Incident'
          },
          concurrent_alarms_healthy_to_stop_incident: {
            type: 'number',
            title: 'Now Healthy Concurrent Alarms to Stop Incident'
          },
          downtime_seconds_allowed: {
            type: 'number',
            title: 'Downtime Sec. Allowed'
          },
          mean_time_between_system_incidents_seconds_allowed: {
            type: 'number',
            title: 'MTBI Sec. Allowed'
          },
          mean_time_recovery_system_seconds_allowed: {
            type: 'number',
            title: 'MTBR Sec. Allowed'
          },
          coverage_denominator: {
            type: 'number',
            title: 'Coverage Demoninator'
          },
          sla_target_goal: {
            type: 'number',
            title: 'SLA Target Goal'
          }
        }
      },
      group: {
        type: 'object',
        title: '',
        properties: {
          urgency_setting: {
            type: 'object',
            title: 'Urgency Settings',
            properties: {
              events_per_high_urgency: {
                type: 'number',
                title: '1 - High'
              },
              events_per_medium_urgency: {
                type: 'number',
                title: '2 - Medium'
              },
              events_per_low_urgency: {
                type: 'number',
                title: '3 - Low'
              }
            }
          },
          impact_settings: {
            type: 'object',
            title: 'Impact Setting',
            properties: {
              events_per_high_impact: {
                type: 'number',
                title: '1 - High'
              },
              events_per_medium_impact: {
                type: 'number',
                title: '2 - Medium'
              },
              events_per_low_impact: {
                type: 'number',
                title: '3 - Low'
              }
            }
          }
        }
      },
      service: {
        type: 'string',
        title: 'Service',
        enum: canaryableServicesEnumValues,
        enumNames: canaryableServicesEnumNames
      },
      provider: {
        type: 'string',
        title: 'Provider',
        enum: serviceProvidersEnumValues,
        enumNames: serviceProvidersEnumNames
      },
      canaries: {
        type: 'array',
        title: 'Canaries',
        uniqueItems: true,
        items: {
          type: 'string',
          enum: canaryEnumValues,
          enumNames: canaryEnumNames
        }
      },
      is_default: { type: 'boolean', title: 'Is Default' },
      organization: { type: 'string' }
    }
  } as RJSFSchema

  const uiSchema: UiSchema = {
    organization: { 'ui:widget': 'hidden' },
    canaries: { 'ui:widget': 'hidden' },
    'ui:ObjectFieldTemplate': ObjectFieldTemplate,
    'ui:order': [
      'name',
      'provider',
      'service',
      'range_settings',
      'group',
      'is_default',
      'canaries',
      'organization'
    ]
  }

  return (
    <div>
      <CreateButton text="Create" onClick={handleCreateModalOpen} />
      {organizationId ? (
        <DialogModal
          open={createModalOpen}
          onClose={handleModalClose}
          className={extraClasses.paperWidthSm}
          dialogTitle="Create Service Settings Group"
          dialogContent={
            <MuiForm
              schema={schema}
              onSubmit={onSubmit}
              formData={formData}
              onChange={handleFormDataChange}
              extraErrors={extraErrors}
              uiSchema={uiSchema}
              className={classes.muiForm}
              validator={validator}
            >
              <FormsCreateButton>Create</FormsCreateButton>
            </MuiForm>
          }
        />
      ) : (
        <OrganizationRequiredDialog />
      )}
    </div>
  )
}
