import Chip from '@mui/material/Chip'
import Box from '@mui/system/Box'

interface CutomTooltipChipProps {
  chipLabel: string[] | undefined
}

export default function CutomTooltipChip(props: CutomTooltipChipProps) {
  const { chipLabel } = props

  return (
    <Box>
      <Chip
        sx={{
          height: 'auto',
          backgroundColor: chipLabel!.some((label) =>
            label.includes('Forecast')
          )
            ? '#ff9831'
            : 'unset',
          '& .MuiChip-label': {
            backgroundColor: '#cbcecf',
            borderRadius: 1,
            display: 'block',
            whiteSpace: 'pre-line'
          }
        }}
        label={chipLabel}
      />
    </Box>
  )
}
