import useDocumentTitle from 'hooks/useDocumentTitle'
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import { RawAxiosRequestConfig } from 'axios'
import Icon, { IconType } from 'components/Icons'
import { AdminPagesTitles } from 'components/MyAdminPages/Constants'
import MyAdminPagesCommonHeader from 'components/MyAdminPages/MyAdminPagesCommonHeader'
import NoDataFound from 'components/MyAdminPages/NoDataFound'
import CreateModal from 'components/RestfulSDK/CreateModal'
import DeleteModal from 'components/RestfulSDK/DeleteModal'
import EditModal from 'components/RestfulSDK/EditModal'
import Toast from 'components/Toast'
import PagesSkeletonLoader from 'components/common/Loaders/PagesSkeletonLoader'
import { rowsPerPageOptions } from 'constants/AppConfig'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { OpenAPIRestfulLibrariesContext } from 'contexts/OpenAPIRestfulLibrariesContext'
import { OrganizationsContext } from 'contexts/OrganizationsContext'
import { OpenAPIRestfulLibrary } from 'lib/CloudCanariesRestfulAPI'
import adminPagesSharedStyles from 'pages/AdminPages/adminPagesSharedStyles'
import RoutesService from 'services/routes'

interface GenerateReGenerateButtonProps {
  generated: boolean
  buttonAction: (schemaId: string) => void
  schemaId: string
}

function GenerateReGenerateButton({
  generated,
  buttonAction,
  schemaId
}: GenerateReGenerateButtonProps) {
  const generateButton = (
    <Button
      onClick={() => buttonAction(schemaId)}
      startIcon={<Icon name={IconType.Add} />}
    >
      Generate
    </Button>
  )
  const regenerateButton = (
    <Button
      onClick={() => buttonAction(schemaId)}
      startIcon={<Icon name={IconType.PlayArrow} />}
    >
      ReGenerate
    </Button>
  )
  return generated ? regenerateButton : generateButton
}

export default function CustomRestfulSDKList() {
  const classes = adminPagesSharedStyles()
  useDocumentTitle('Canary Custom Schemas')

  const { apiService } = useContext(APIServiceContext)
  const { organizationId } = useContext(OrganizationsContext)

  const {
    areOpenAPIRestfulLibrariesLoading,
    openAPIRestfulLibraries,
    fetchOpenAPIRestfulLibraries,
    page,
    rowCount,
    rowsPerPage,
    onRowsPerPageChange,
    handleChangePage
  } = useContext(OpenAPIRestfulLibrariesContext)

  const generateRestfulLib = async (schemaId: string) => {
    const openAPIRestfulLibrary = {
      is_generated: false
    } as OpenAPIRestfulLibrary

    await apiService
      .partialUpdateOpenAPIRestfulLibrary(
        schemaId,
        organizationId,
        openAPIRestfulLibrary
      )
      .then(fetchOpenAPIRestfulLibraries)
  }

  const deleteRestfulLibrary = async (schemaId: string) => {
    await apiService
      .destroyOpenAPIRestfulLibrary(schemaId, organizationId)
      .then((response) => {
        if (response.status === 204) {
          toast.success(
            <Toast
              title="Success"
              text={`Custom Schema is deleted successfully!`}
            />,
            {
              position: 'top-right'
            }
          )
        } else {
          toast.error(
            <Toast
              title="Error"
              text="Something went wrong, please try again!"
            />,
            {
              position: 'top-right'
            }
          )
        }
        fetchOpenAPIRestfulLibraries()
      })
  }

  async function downloadRestfulZip(libraryId: string, name?: string) {
    const options: RawAxiosRequestConfig = { responseType: 'blob' }

    await apiService
      .downloadRestfulZipOpenAPIRestfulLibrary(libraryId, options)
      .then((response) => {
        if (response) {
          const blob = new Blob([response.data])
          const link = document.createElement('a')
          const url = URL.createObjectURL(blob)
          link.setAttribute('href', url)
          link.setAttribute(
            'download',
            `${name || 'RestfulLib'}-Restful-Library.zip`
          )
          link.style.visibility = 'hidden'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      })
  }

  async function downloadTemplateZip(libraryId: string, name?: string) {
    const options: RawAxiosRequestConfig = { responseType: 'blob' }

    await apiService
      .downloadTemplateZipOpenAPIRestfulLibrary(libraryId, options)
      .then((response) => {
        if (response) {
          const blob = new Blob([response.data])
          const link = document.createElement('a')
          const url = URL.createObjectURL(blob)
          link.setAttribute('href', url)
          link.setAttribute(
            'download',
            `${name || 'TemplateLibrary'}-Template-Library.zip`
          )
          link.style.visibility = 'hidden'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      })
  }

  return (
    <Container maxWidth="xl" className={classes.mainContainer}>
      <MyAdminPagesCommonHeader />
      <h1 className={classes.headerRow}>
        {AdminPagesTitles.CustomSchemas}
        <CreateModal />
      </h1>
      {areOpenAPIRestfulLibrariesLoading ? (
        <PagesSkeletonLoader />
      ) : (
        <>
          <TableContainer component={Paper} elevation={3}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Schema - view/edit</TableCell>
                  <TableCell>ReGenerate SDK</TableCell>
                  <TableCell>View/Edit Generated Files</TableCell>
                  <TableCell>Restful Python Library</TableCell>
                  <TableCell>Canary Template Library</TableCell>
                  <TableCell className={classes.firstCell}>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {openAPIRestfulLibraries.length > 0 ? (
                  openAPIRestfulLibraries?.map((restfulLibrary) => {
                    const schemaId = restfulLibrary.id!
                    const currentName = restfulLibrary.name!

                    return (
                      <TableRow key={schemaId}>
                        <TableCell>
                          <EditModal
                            schemaId={schemaId}
                            currentName={currentName}
                          />
                        </TableCell>
                        <TableCell>
                          <Link to={RoutesService.customSchemaView(schemaId)}>
                            View
                          </Link>
                          {' | '}
                          <Link to={RoutesService.customSchemaEdit(schemaId)}>
                            Edit
                          </Link>
                        </TableCell>
                        <TableCell>
                          <GenerateReGenerateButton
                            generated={restfulLibrary.is_generated!}
                            buttonAction={generateRestfulLib}
                            schemaId={schemaId}
                          />
                        </TableCell>
                        <TableCell>
                          <Link
                            to={RoutesService.customRestfulLibEdit(schemaId)}
                          >
                            {' '}
                            Edit Files
                          </Link>
                        </TableCell>
                        <TableCell>
                          Zip
                          <IconButton
                            onClick={() =>
                              downloadRestfulZip(
                                restfulLibrary.id!,
                                restfulLibrary.name
                              )
                            }
                            color="primary"
                            aria-label="Download Schema Zip File"
                            title="Download Schema Zip File"
                            disabled={!restfulLibrary.is_generated}
                            disableFocusRipple
                            disableRipple
                            disableTouchRipple
                          >
                            <Icon name={IconType.Download} />
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          Zip
                          <IconButton
                            onClick={() =>
                              downloadTemplateZip(
                                restfulLibrary.id!,
                                restfulLibrary.name
                              )
                            }
                            color="primary"
                            aria-label="Download Canary Template Zip File"
                            title="Download Canary Template Zip File"
                            disabled={!restfulLibrary.is_generated}
                            disableFocusRipple
                            disableRipple
                            disableTouchRipple
                          >
                            <Icon name={IconType.Download} />
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          <DeleteModal
                            afterSubmit={() =>
                              deleteRestfulLibrary(restfulLibrary.id!)
                            }
                            currentName={currentName}
                            schema={restfulLibrary}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <NoDataFound pageName="Schemas" colSpan={7} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={rowCount}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </>
      )}
    </Container>
  )
}
