import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
  container: {
    // padding: theme.spacing(3)
  },
  section: {
    marginBottom: theme.spacing(3)
  },
  radioLabel: {
    display: 'flex',
    alignItems: 'center'
  },
  button: {
    margin: theme.spacing(1, 0),
    textTransform: 'capitalize'
  },
  cancelButton: {
    color: '#ffffff',
    backgroundColor: '#d32f2f',
    '&:hover': {
      backgroundColor: '#d32f3f'
    }
  },
  table: {
    marginTop: theme.spacing(2)
  }
}))
