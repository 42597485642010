import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Box from '@mui/system/Box'
import Icon, { IconType } from 'components/Icons'
import TabPanel from 'components/common/MUITabPanel'
import { queryParamPageSizeDefault } from 'constants/AppConfig'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { Notification } from 'lib/CloudCanariesRestfulAPI'
import ChannelConfigurationModal from './ChannelConfigurationModal'
import CreateNotificationDialog from './CreateNotificationDialog'
import DeleteNotificationConfirmationDialog from './DeleteNotificationConfirmationDialog'
import EditNotificationDialog from './EditNotificationDialog'

interface PerAlarmNotificationsConfiguredTabPanelProps {
  alarmId: string
  selectedTab: number
  tabIndex: number
}

export default function PerAlarmNotificationsConfiguredTabPanel(
  props: PerAlarmNotificationsConfiguredTabPanelProps
) {
  const { alarmId, selectedTab, tabIndex } = props
  const { apiService } = useContext(APIServiceContext)
  const isSelected = selectedTab === tabIndex

  const [notifications, setNotifications] = useState<Notification[]>([])

  const getNotifications = useCallback(async () => {
    if (isSelected) {
      await apiService
        .listNotifications(undefined, queryParamPageSizeDefault, alarmId)
        .then((json) => setNotifications(json.data.results ?? []))
    }
  }, [alarmId, apiService, isSelected])

  useEffect(() => {
    getNotifications()
  }, [getNotifications])

  const hasSystemNotification = useMemo(() => {
    return notifications.some(
      (notification) => notification.is_system_notification
    )
  }, [notifications])

  return (
    <TabPanel value={selectedTab} index={tabIndex}>
      <Box
        sx={{
          m: 1,
          ml: 0,
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <CreateNotificationDialog
          alarmId={alarmId}
          onClose={() => {
            getNotifications()
          }}
        />
        <ChannelConfigurationModal />
      </Box>

      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Channels</TableCell>
              <TableCell align="left">From</TableCell>
              <TableCell align="left">To</TableCell>
              <TableCell align="right">Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {notifications.map((notification) => {
              return (
                notification.id && (
                  <TableRow key={notification.id}>
                    <TableCell align="left">
                      <EditNotificationDialog
                        notification={notification}
                        alarmId={alarmId}
                        onClose={() => {
                          getNotifications()
                        }}
                      />
                    </TableCell>
                    <TableCell align="left">
                      {notification.channels?.join(', ')}
                    </TableCell>
                    <TableCell align="left">
                      {notification.transition_from}
                    </TableCell>
                    <TableCell align="left">
                      {notification.transition_to}
                    </TableCell>
                    <TableCell align="right">
                      <DeleteNotificationConfirmationDialog
                        notificationId={notification.id}
                        onClose={() => {
                          getNotifications()
                        }}
                      />
                    </TableCell>
                  </TableRow>
                )
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {hasSystemNotification ? (
        <Box
          sx={{
            m: 1,
            ml: 3,
            alignContent: 'center',
            display: 'flex',
            alignItems: 'center',
            color: '#555'
          }}
        >
          <Icon name={IconType.Analytics} style={{ marginRight: 4 }} />
          <Typography variant="body2">
            This syllable indicates the Notification is system generated and
            used for internal metrics
          </Typography>
        </Box>
      ) : null}
    </TabPanel>
  )
}
