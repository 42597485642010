import Icon, { IconType } from 'components/Icons'

interface CanaryErrorButtonProps {
  error?: boolean
}

export default function CanaryErrorButton(props: CanaryErrorButtonProps) {
  const { error } = props
  if (error) {
    return <Icon name={IconType.X} />
  } else {
    return <Icon name={IconType.Check} />
  }
}
