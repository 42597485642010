import { FormConfigTypes } from './FormConfigsTypes'

export const configRequestResetPassword: FormConfigTypes = {
  schema: {
    type: 'object',
    required: ['email'],
    properties: {
      email: { type: 'string', title: 'Email' }
    }
  },
  uiSchema: {
    email: {
      'ui:widget': 'email'
    }
  }
}
