import {
  ChangeEvent,
  MouseEvent,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import {
  ServiceHealthIncident,
  ServiceHealthIncidentIncidentStatusEnum,
  ServiceHealthIncidentUrgencyEnum
} from 'lib/CloudCanariesRestfulAPI'
import { APIServiceContext } from './APIServiceContext'
import { AuthContext } from './AuthContext'
import { OrganizationsContext } from './OrganizationsContext'

const selectedServiceID = undefined
const refreshInterval = 5

type orderingChoices =
  | 'service'
  | '-service'
  | 'settings_group'
  | '-settings_group'
  | 'start'
  | '-start'
  | 'stop'
  | '-stop'
  | 'downtime'
  | '-downtime'
  | 'incident_status'
  | '-incident_status'
  | 'impact'
  | '-impact'
  | 'urgency'
  | '-urgency'
  | undefined
type orderingFieldNames = Exclude<orderingChoices, undefined>

interface IncidentsContextProviderProps {
  children: ReactNode
}

interface IncidentsContextProps {
  areIncidentsLoading: boolean
  incidents: ServiceHealthIncident[]
  incidentsExist: boolean
  incidentsLevel: ServiceHealthIncidentUrgencyEnum
  rowsPerPage: number
  page: number
  rowCount: number
  incidentStatusValueSelected: ServiceHealthIncidentIncidentStatusEnum | string
  ordering: orderingChoices
  setIncidentStatusValueSelected: (
    status: ServiceHealthIncidentIncidentStatusEnum
  ) => void
  onRowsPerPageChange: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void
  handleChangePage: (event: any, newPage: number) => void
  onChangeSort: (
    event: MouseEvent<HTMLSpanElement>,
    field_name: orderingFieldNames
  ) => void
}
export const IncidentsContext = createContext<IncidentsContextProps>(
  null as any
)

export default function IncidentsContextProvider(
  props: IncidentsContextProviderProps
) {
  const { children } = props
  const { isAuthenticated } = useContext(AuthContext)

  const { organizationId } = useContext(OrganizationsContext)

  const { apiService } = useContext(APIServiceContext)

  const [page, setPage] = useState<number>(0)
  const [rowCount, setRowCount] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [areIncidentsLoading, setAreIncidentsLoading] = useState<boolean>(true)
  const [incidents, setIncidents] = useState<ServiceHealthIncident[]>([])
  const [incidentsExist, setIncidentsExist] = useState<boolean>(false)
  const [incidentsLevel, setIncidentsLevel] =
    useState<ServiceHealthIncidentUrgencyEnum>(
      ServiceHealthIncidentUrgencyEnum.Ok
    )
  const [incidentStatusValueSelected, setIncidentStatusValueSelected] =
    useState<ServiceHealthIncidentIncidentStatusEnum | ''>('')

  const [ordering, setOrdering] = useState<orderingChoices>(undefined)

  const fetchIncidents = useCallback(() => {
    if (isAuthenticated()) {
      setRowCount(0)
      const requestedPage = page + 1
      const incidentStatus = incidentStatusValueSelected
        ? incidentStatusValueSelected
        : undefined
      setAreIncidentsLoading(true)
      apiService
        .listServiceHealthIncidents(
          requestedPage,
          rowsPerPage,
          organizationId,
          selectedServiceID,
          incidentStatus,
          ordering
        )
        .then((json) => {
          const incidentsData = json.data.results
          setIncidents(incidentsData ?? [])
          setRowCount(json.data.count ?? 0)
          const incidentExist = incidentsData?.filter(
            (incident) =>
              incident.incident_status ===
              ServiceHealthIncidentIncidentStatusEnum.Started
          )
          if (incidentExist && incidentExist.length > 0) {
            if (
              incidentExist.some(
                (incident) =>
                  incident.urgency! === ServiceHealthIncidentUrgencyEnum.High
              )
            ) {
              setIncidentsLevel(ServiceHealthIncidentUrgencyEnum.High)
            } else if (
              incidentExist.some(
                (incident) =>
                  incident.urgency! ===
                    ServiceHealthIncidentUrgencyEnum.Medium ||
                  incident.urgency! === ServiceHealthIncidentUrgencyEnum.Low
              )
            ) {
              setIncidentsLevel(ServiceHealthIncidentUrgencyEnum.Medium)
            } else {
              setIncidentsLevel(ServiceHealthIncidentUrgencyEnum.Ok)
            }
            setIncidentsExist(true)
          } else {
            setIncidentsExist(false)
          }
          setAreIncidentsLoading(false)
        })
    }
    return () => {
      setAreIncidentsLoading(false)
      setIncidentsExist(false)
      setIncidentsLevel(ServiceHealthIncidentUrgencyEnum.Ok)
      setIncidents([])
    }
  }, [
    apiService,
    incidentStatusValueSelected,
    isAuthenticated,
    ordering,
    organizationId,
    page,
    rowsPerPage
  ])

  useEffect(() => {
    fetchIncidents()
    const sync = setInterval(
      () => {
        if (isAuthenticated()) {
          fetchIncidents()
        }
      },
      60 * 1000 * refreshInterval
    )

    return () => clearInterval(sync)
  }, [fetchIncidents, isAuthenticated])

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage)
  }

  const onRowsPerPageChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const onChangeSort = (
    event: MouseEvent<HTMLSpanElement>,
    field_name: orderingFieldNames
  ) => {
    setOrdering((oldOrdering) => {
      let new_ordering = field_name
      if (oldOrdering) {
        if (oldOrdering.indexOf(field_name) === 0) {
          new_ordering = ('-' + field_name) as orderingFieldNames
        } else if (oldOrdering.indexOf(field_name) === 1) {
          new_ordering = field_name
        }
      }
      return new_ordering
    })
  }
  return (
    <IncidentsContext.Provider
      value={{
        areIncidentsLoading,
        incidents,
        incidentsExist,
        incidentsLevel,
        page,
        rowsPerPage,
        rowCount,
        incidentStatusValueSelected,
        ordering,
        setIncidentStatusValueSelected,
        handleChangePage,
        onRowsPerPageChange,
        onChangeSort
      }}
    >
      {children}
    </IncidentsContext.Provider>
  )
}
