export default class DateTimeService {
  static daypickerSelectedDayToDateString(selectedDay: any) {
    return new Date(selectedDay).toISOString().slice(0, 10)
  }
  dateStringFromParsedDateTimeString(datetimestring: string) {
    return datetimestring.substring(0, 10)
  }
  timeStringFromParsedDateTimeString(datetimestring: string) {
    return datetimestring.substring(11, 16)
  }
  dateObjectFromDjangoString(datestring: string) {
    // takes in a date string of the form YYYY-MM-DD
    // returns a date object for that date
    let d = new Date()
    const parts = datestring.split('-')
    const year = parseInt(parts[0])
    const month = parseInt(parts[1])
    // months in JS go from 0 --> 11
    const monthValue = month === 0 ? month : month - 1
    const day = parseInt(parts[2])
    d.setUTCFullYear(year, monthValue, day)
    return d
  }
  addOrSubtractDays(dateobject: Date, days: number) {
    let d = new Date()
    d.setTime(dateobject.getTime())
    d.setTime(dateobject.getTime() + days * 24 * 60 * 60 * 1000)
    return d
  }
  datetimeObjectToDjangoString(dateobject: Date) {
    return dateobject.toISOString().slice(0, 10)
  }
  calendarDateInputToDjangoString(datestring: string) {
    const selectedDay = new Date(datestring)
    return this.datetimeObjectToDjangoString(selectedDay)
  }
  datetimeformatted = (_date: string, _time: string) => {
    // takes in a partial UTC formatted date and time
    // normalizes them for what Date() expects to parse
    // concatenates them to form what Date() expects to parse
    if (_time.length === 4) {
      _time = '0' + _time
    }
    return _date + 'T' + _time + ':00'
  }

  stateDateTimeStringToISO = (datestring: string) => {
    // takes in the concatenated Date string + time string in UTC
    // format = 2021-07-31T18:58:00
    // creates a Date object
    // sets it to ISO string in UTC time
    let parsed
    let isodate

    try {
      const year = parseInt(datestring.slice(0, 4))
      // months are set from zero in this format
      let month = parseInt(datestring.slice(5, 7)) - 1

      const day = parseInt(datestring.slice(8, 10))
      const hour = parseInt(datestring.slice(11, 14))
      const minute = parseInt(datestring.slice(14, 16))
      parsed = new Date(Date.UTC(year, month, day, hour, minute, 0))
      isodate = parsed.toISOString()
    } catch (e) {
      parsed = new Date()
      isodate = parsed.toISOString()
    }
    return isodate
  }

  djangoDateTimeValue = (datestring: string) => {
    const isodate = this.stateDateTimeStringToISO(datestring)
    const HH = isodate.slice(11, 13)
    const mm = isodate.slice(14, 16)
    return isodate.slice(0, 10) + ' ' + HH + ':' + mm
  }

  graphiteDateTimeValue = (datestring: string) => {
    const isodate = this.stateDateTimeStringToISO(datestring)
    const HH = isodate.slice(11, 13)
    const mm = isodate.slice(14, 16)
    return HH + ':' + mm + '_' + isodate.slice(0, 10).replace(/-/g, '')
  }

  static getDefaultDateTimes = () => {
    const defaultStop = new Date()
    const defaultStart = new Date(
      defaultStop.getTime() - 7 * 24 * 60 * 60 * 1000
    )
    const isoStart = defaultStart.toISOString()
    const isoStop = defaultStop.toISOString()

    const defaults = {
      timeStart: isoStart.slice(11, 16),
      timeStop: isoStop.slice(11, 16),
      dateStart: isoStart.slice(0, 10),
      dateStop: isoStop.slice(0, 10)
    }
    return defaults
  }

  static getForecastDefaultDateTimes = () => {
    const defaultStart = new Date()
    const defaultStop = new Date(
      defaultStart.getTime() + 4 * 24 * 60 * 60 * 1000
    )
    const isoStart = defaultStart.toISOString()
    const isoStop = defaultStop.toISOString()

    const defaults = {
      dateStart: isoStart.slice(0, 10),
      dateStop: isoStop.slice(0, 10)
    }

    return defaults
  }
}
