export const range = (start: number, end: number) => {
  const inc = (end - start) / Math.abs(end - start)

  return Array.from(Array(Math.abs(end - start) + 1), (_, i) => start + i * inc)
}

export class Dict extends Map<any, any> {
  getValueOrDefault(key: bigint | string | undefined, _default: any) {
    if (!this.has(key)) return _default
    return super.get(key)
  }
}

export function pairwise(array: Array<any>, callback: Function) {
  const copy = array.concat([])
  let next, current

  while (copy.length) {
    current = next ? next : copy.shift()
    next = copy.shift()
    callback(current, next)
  }
}

export const DAY_OPTIONS = {
  monthly: Array(31) // [1, 2, ..., 31]
    .fill('')
    .map((s, i) => i + 1),
  weekly: 'SMTWTFS'.split('') // ['S', 'M', ..., 'S']
}

export const HOUR_OPTIONS = Array(24)
  .fill('')
  .map((_, index) => `${index < 10 ? '0' : ''}${index}:00 UTC`)

export const DAYS_OF_WEEK = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
