import useDocumentTitle from 'hooks/useDocumentTitle'
import { useContext, useState } from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Icon, { IconType } from 'components/Icons'
import { AdminPagesTitles } from 'components/MyAdminPages/Constants'
import MyAdminPagesCommonHeader from 'components/MyAdminPages/MyAdminPagesCommonHeader'
import BillingDetailsSkeletonLoader from 'components/UserBillingDetails/BillingDetailsSkeletonLoader'
import NoDataFound from 'components/UserBillingDetails/NoDataFound'
import InvoiceRow from 'components/UserPlansDetails/InvoiceRow'
import { BillingContext } from 'contexts/BillingContext'
import adminPagesSharedStyles from 'pages/AdminPages/adminPagesSharedStyles'
import { stringToFirstLetterCapital } from 'services/utils'
import useStyles from './styles'

export default function UserBillingDetails() {
  const adminPagesClasses = adminPagesSharedStyles()
  const classes = useStyles()
  useDocumentTitle('Billing Page')
  const {
    areBillingDetailsLoading,
    billingDetails,
    hasMore,
    nextPage,
    prevPage
  } = useContext(BillingContext)
  const [currentPage, setCurrentPage] = useState(1)

  const handleNextPage = () => {
    nextPage()
    setCurrentPage(currentPage + 1)
  }

  const handlePrevPage = () => {
    prevPage()
    setCurrentPage(currentPage - 1)
  }

  return (
    <Container maxWidth="xl" className={adminPagesClasses.mainContainer}>
      <MyAdminPagesCommonHeader />
      <h1 className={adminPagesClasses.headerRow}>
        {AdminPagesTitles.BillingDetails}
      </h1>
      {areBillingDetailsLoading ? (
        <BillingDetailsSkeletonLoader />
      ) : (
        <Container maxWidth="xl" disableGutters>
          {billingDetails.length > 0 ? (
            <>
              {billingDetails.map((subscription, index) => (
                <Box key={index} className={classes.container}>
                  <Box className={classes.section}>
                    <Typography variant="h6" className={classes.label}>
                      {subscription.plan_name} Subscription
                    </Typography>
                    <Typography className={classes.value}>
                      #{subscription.hubspot_id}
                    </Typography>
                  </Box>
                  <Grid container spacing={2} className={classes.section}>
                    <Grid item xs={12} md={2} sm={2}>
                      <Typography className={classes.label}>
                        Billing frequncy
                      </Typography>
                      <Typography className={classes.value}>
                        {stringToFirstLetterCapital(
                          subscription.billing_frequency!
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={2} sm={2}>
                      <Typography className={classes.label}>Amount</Typography>
                      <Typography className={classes.value}>
                        ${subscription.plan_price}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={3} sm={2}>
                      <Typography className={classes.label}>
                        Last payment date
                      </Typography>
                      <Typography className={classes.value}>
                        {subscription.last_payment_date
                          ? subscription.last_payment_date
                          : subscription.status === 'canceled'
                            ? 'Subscription canceled'
                            : 'Will be updated once charged'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={3} sm={2}>
                      <Typography className={classes.label}>
                        Next billing date
                      </Typography>
                      <Typography className={classes.value}>
                        {subscription.next_payment_due_date
                          ? subscription.next_payment_due_date
                          : 'Subscription canceled'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={2} sm={2}>
                      <Typography className={classes.label}>
                        Payment method
                      </Typography>
                      <Typography className={classes.value}>
                        {subscription.payment_method}
                      </Typography>
                      <Typography className={classes.value}>
                        {subscription.card_holder}
                      </Typography>
                    </Grid>
                  </Grid>
                  {subscription.invoices.length > 0 ? (
                    <Accordion elevation={0} sx={{ mt: 1 }}>
                      <AccordionSummary
                        expandIcon={<Icon name={IconType.ExpandMoreIcon} />}
                        aria-controls={`panel-${subscription.id}-content`}
                        id={`panel-${subscription.id}-header`}
                      >
                        <Typography>Invoice History</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {subscription.invoices.map((invoice) => (
                          <InvoiceRow
                            key={invoice.hs_object_id}
                            invoice={invoice}
                          />
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  ) : (
                    <>
                      <Divider
                        style={{
                          height: '1px'
                        }}
                      />
                      <Typography sx={{ px: 2, my: 1.5, color: '#000000de' }}>
                        No invoices
                      </Typography>
                    </>
                  )}
                </Box>
              ))}
              <Box display="flex" justifyContent="space-between" mt={2}>
                <Button
                  variant="contained"
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                >
                  Previous
                </Button>
                <Button
                  variant="contained"
                  onClick={handleNextPage}
                  disabled={!hasMore}
                >
                  Next
                </Button>
              </Box>
            </>
          ) : (
            <NoDataFound />
          )}
        </Container>
      )}
    </Container>
  )
}
