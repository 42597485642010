import {
  setOrganizationRequiredModalMessage,
  setOrganizationRequiredModalOpen
} from 'client_side_state/slices/app'
import { useAppDispatch } from 'client_side_state/store'
import copy from 'copy-to-clipboard'
import { useContext, useEffect, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { createStyles, makeStyles } from '@mui/styles'
import Box from '@mui/system/Box'
import Icons, { IconType } from 'components/Icons'
import DialogModal from 'components/Modals/DialogModal'
import OrganizationRequiredDialog, {
  OrganizationRequiredMessage
} from 'components/UserOrganization/OrganizationRequiredDialog'
import CreateButton from 'components/common/CreateButton'
import { APIKeysContext } from 'contexts/APIKeysContext'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { OrganizationsContext } from 'contexts/OrganizationsContext'

const useStyles = makeStyles(() =>
  createStyles({
    generatedKey: {
      position: 'relative',
      padding: '20px 16px',
      borderStyle: 'solid',
      borderWidth: 1,
      borderRadius: 6,
      color: '#0d1117',
      backgroundImage:
        'linear-gradient(rgba(56,139,253,0.15), rgba(56,139,253,0.15))',
      borderColor: 'rgba(56,139,253,0.4)'
    },
    generatedKeyContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    apiKeyContents: {
      fontSize: '1rem'
    },
    copyButton: {
      '&:hover': {
        backgroundColor: 'transparent'
      }
    }
  })
)

function generateKeyButton(generateKey: () => Promise<void>) {
  return (
    <CreateButton text="Create" icon={IconType.Add} onClick={generateKey} />
  )
}

function KeyContentsDiv({ keyContents }: { keyContents: string }) {
  const classes = useStyles()
  const [copied, setCopied] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState(false)

  function copyText() {
    copy(keyContents)
    setCopied(true)
    setTooltipOpen(true)
  }

  useEffect(() => {
    return () => {
      setCopied(false)
      setTooltipOpen(false)
    }
  }, [])

  return (
    <Box>
      <p className={classes.generatedKey}>
        Make sure to copy your API Key now. You won't be able to see it again!
      </p>
      <Box className={classes.generatedKeyContainer}>
        <Box component="code" className={classes.apiKeyContents}>
          {keyContents}
        </Box>
        <Tooltip open={tooltipOpen} title="Copied" placement="top" arrow>
          <IconButton
            classes={{ root: classes.copyButton }}
            disableFocusRipple
            disableRipple
            disableTouchRipple
            onMouseLeave={() => setTooltipOpen(false)}
            onClick={copyText}
          >
            <Icons
              name={copied ? IconType.Done : IconType.FileCopy}
              color={copied ? undefined : 'primary'}
              style={{ color: copied ? '#3fb950' : 'inherit' }}
            />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  )
}
export default function APIKeysCreateModal() {
  const { apiService } = useContext(APIServiceContext)
  const { organizationId, organization } = useContext(OrganizationsContext)
  const { fetchAPIKeys } = useContext(APIKeysContext)

  const [open, setOpen] = useState(false)

  const [generated, setGenerated] = useState<boolean>(false)
  const [keyContents, setKeyContents] = useState<string>('')

  const dispatch = useAppDispatch()

  const handleClickOpen = () => {
    if (!organizationId) {
      dispatch(
        setOrganizationRequiredModalMessage(
          OrganizationRequiredMessage.APIKeyCreate
        )
      )
      dispatch(setOrganizationRequiredModalOpen(true))
    } else {
      setOpen(true)
    }
  }

  const handleClose = () => {
    setKeyContents('')
    setGenerated(false)
    setOpen(false)
    if (generated) {
      fetchAPIKeys()
    }
  }

  async function generateKey() {
    await apiService
      .generateKeyOrganizationAPIKey({
        organization: organizationId!
      })
      .then((json) => {
        const apiKey = json.data
        if (apiKey) {
          if (apiKey.key_contents) {
            setKeyContents(apiKey.key_contents)
            setGenerated(true)
          }
        }
      })
  }

  const generateKeyButtonEl = generateKeyButton(generateKey)

  return (
    <div>
      <CreateButton
        text="Create"
        icon={IconType.Add}
        onClick={handleClickOpen}
      />
      {organizationId ? (
        <DialogModal
          dialogTitle="Generate API Key"
          open={open}
          onClose={handleClose}
          dialogContent={
            <>
              <h2>
                Organization:{' '}
                <span style={{ marginLeft: 8 }}>{organization!.name}</span>
              </h2>
              {generated ? (
                <KeyContentsDiv keyContents={keyContents} />
              ) : (
                generateKeyButtonEl
              )}
            </>
          }
        />
      ) : (
        <OrganizationRequiredDialog />
      )}
    </div>
  )
}
