import { useEffect, useMemo, useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { IChangeEvent } from '@rjsf/core'
import MuiForm from '@rjsf/mui'
import { RJSFSchema, UiSchema } from '@rjsf/utils'
import validator from '@rjsf/validator-ajv8'
import TabPanel from 'components/common/MUITabPanel'
import { TaskUpdates } from 'interfaces/CanaryEditor'
import {
  AbstractMetricBoundaryCondition,
  CanaryTask,
  SimpleAlarm
} from 'lib/CloudCanariesRestfulAPI'
import CanaryTaskUpdateService from 'services/CanaryTaskUpdateService'

interface TabPanelEnvironmentalVariablesProps {
  selectedTab: number
  tabIndex: number
  task: CanaryTask
  tasksUpdates: Map<string, TaskUpdates>
  setTasksUpdates: (data: Map<string, TaskUpdates>) => void
}

export default function TabPanelEnvironmentalVariables(
  props: TabPanelEnvironmentalVariablesProps
) {
  const { selectedTab, tabIndex, task, tasksUpdates, setTasksUpdates } = props

  const [hasEnvVars, setHasEnvVars] = useState<boolean>(false)
  const schema = task.env_vars_schema as RJSFSchema
  const uiSchema = task.env_vars_ui_schema as UiSchema<any, typeof schema>
  const taskId = task.id!

  useEffect(() => {
    if (
      'properties' in task.env_vars_schema! &&
      typeof task.env_vars_schema.properties === 'object' &&
      Object.keys(task.env_vars_schema.properties as any).length > 0
    ) {
      setHasEnvVars(true)
    } else {
      setHasEnvVars(false)
    }
  }, [task.env_vars_schema])

  const formData = useMemo<
    SimpleAlarm | AbstractMetricBoundaryCondition
  >(() => {
    return CanaryTaskUpdateService.getCurrentEnvVarsFormData(
      taskId,
      task.env_vars,
      tasksUpdates
    )
  }, [task.env_vars, tasksUpdates, taskId])

  const onChange = (
    data: IChangeEvent<any, RJSFSchema, any>,
    id?: string | undefined
  ) => {
    const formVariables = JSON.parse(JSON.stringify(data.formData))
    const retrieved_data = task.env_vars ?? {}
    const mergedTaskUpdates = CanaryTaskUpdateService.mergeEnvVarsUpdate(
      taskId,
      schema,
      formVariables,
      retrieved_data,
      tasksUpdates
    )
    setTasksUpdates(mergedTaskUpdates)
  }

  return hasEnvVars ? (
    <TabPanel value={selectedTab} index={tabIndex}>
      <Grid container style={{ width: '100%' }}>
        <Grid item xs={12} md={6}>
          <MuiForm
            schema={schema ?? {}}
            uiSchema={uiSchema ?? {}}
            formData={formData}
            onChange={onChange}
            children={true}
            validator={validator}
          />
        </Grid>
      </Grid>
    </TabPanel>
  ) : (
    <Typography variant="h6" sx={{ m: 1, ml: 1.5 }}>
      There are no Environmental Variable in this Canary.
    </Typography>
  )
}
