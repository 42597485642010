import { useEffect, useRef, useState } from 'react'
import logo from 'assets/logo.png'
import Icon, { IconType } from 'components/Icons'
import Button from 'components/common/Button'
import { Container } from 'styles/components/CanaryEditor/EditorMenu.styled'

interface EditorMenuProps {
  canaryName: string
  canaryNameDuplicateError: boolean
  hasUpdates: boolean
  saving: boolean
  setName: (name: string) => void
  onClose: () => void
  onSave: () => void
}

export default function EditorMenu(props: EditorMenuProps) {
  const {
    canaryName,
    canaryNameDuplicateError,
    hasUpdates,
    saving,
    setName,
    onClose,
    onSave
  } = props
  const [editingName, setEditing] = useState<boolean>(false)
  const inputElement = useRef<HTMLInputElement>(null)

  const checkUnsavedChanges = (): boolean =>
    !hasUpdates ||
    window.confirm('You have unsaved changes. Are you sure you want to leave?')

  useEffect(() => {
    if (canaryNameDuplicateError) {
      setEditing(true)
    } else {
      setEditing(false)
    }
  }, [canaryNameDuplicateError])

  return (
    <Container>
      <img src={logo} width="45" height="45" alt="logo" />

      {editingName ? (
        <div>
          <input
            ref={inputElement}
            className={
              canaryNameDuplicateError
                ? 'error'
                : canaryName === ''
                  ? 'empty'
                  : undefined
            }
            type="text"
            defaultValue={canaryName}
            placeholder="Canary name"
            onChange={(e) => {
              const newName = e.target.value
              if (newName !== canaryName) setName(e.target.value)
            }}
          />
          {canaryNameDuplicateError && (
            <p className="error-message">
              "{canaryName}" canary name already exists for Organization!
            </p>
          )}
        </div>
      ) : (
        <h1>{canaryName}</h1>
      )}
      {editingName ? (
        <Icon
          name={IconType.Check}
          className="left-end"
          onClick={() => {
            setEditing(false)
            setName(inputElement.current!.value)
          }}
        />
      ) : (
        <Icon
          name={IconType.Edit}
          className="left-end"
          onClick={() => setEditing(true)}
        />
      )}
      {!hasUpdates ? null : (
        <span className="info" style={{ marginBottom: 0 }}>
          {saving ? 'Saving...' : 'You have unsaved changes'}
        </span>
      )}
      {!hasUpdates ? null : (
        <Button label="Save" disabled={saving} action={onSave} />
      )}

      <Button
        label={''}
        leftIcon={<Icon name={IconType.X} />}
        action={() => {
          if (!checkUnsavedChanges()) return
          onClose()
        }}
      />
    </Container>
  )
}
