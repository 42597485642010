import { useContext } from 'react'
import AppBar from '@mui/material/AppBar'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { Theme } from '@mui/material/styles'
import { createStyles, makeStyles } from '@mui/styles'
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer'
import Icon, { IconType } from 'components/Icons'
import DialogModal from 'components/Modals/DialogModal'
import { ArtifactsContext } from 'contexts/ArtifactsContext'
import PDFCreator from './PDFCreator'

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    appBar: {
      position: 'relative'
    },
    title: {
      marginLeft: spacing(2),
      flex: 1
    },
    dialogContent: {
      '& .MuiDialog-paperWidthSm': {
        '& .MuiDialogContent-root': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          padding: spacing(3),
          paddingBottom: spacing(0),
          height: '100%'
        }
      }
    }
  })
)

export default function PDFViewerDialog() {
  const classes = useStyles()
  const { pdfFileData, viewPDFModalOpen, toggleViewPDFModalOpen } =
    useContext(ArtifactsContext)

  return (
    <DialogModal
      fullScreen
      open={viewPDFModalOpen}
      onClose={toggleViewPDFModalOpen}
      className={classes.dialogContent}
      dialogContent={
        <PDFViewer
          showToolbar={false}
          style={{
            width: '100%',
            height: '100%'
          }}
        >
          <PDFCreator pdfFileData={pdfFileData} />
        </PDFViewer>
      }
      keepMounted={false}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Artifact Report
          </Typography>
          <PDFDownloadLink
            document={<PDFCreator pdfFileData={pdfFileData} />}
            fileName={pdfFileData.formData.report_name}
          >
            {({ blob, url, loading, error }) =>
              loading ? (
                'Loading document...'
              ) : (
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="download pdf"
                >
                  <Icon name={IconType.Download} />
                </IconButton>
              )
            }
          </PDFDownloadLink>
          <IconButton
            edge="start"
            color="inherit"
            onClick={toggleViewPDFModalOpen}
            aria-label="close"
            sx={{ ml: 0.5 }}
          >
            <Icon name={IconType.X} />
          </IconButton>
        </Toolbar>
      </AppBar>
    </DialogModal>
  )
}
