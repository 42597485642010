import { Canary } from 'lib/CloudCanariesRestfulAPI'

interface SidebarPropertiesProps {
  canaryMeta: Canary
}

export default function SidebarProperties(props: SidebarPropertiesProps) {
  const { canaryMeta } = props
  return (
    <div className={'properties loaded'}>
      <h1>
        <b>Properties</b>
      </h1>
      <div key={`organization-name`}>
        <p className="title">Organization</p>
        <p className="head1ing4 ms-2">
          <em>{canaryMeta.organization_name}</em>
        </p>
      </div>
      <div key={`service-provider-name`}>
        <p className="title">Service Provider</p>
        <p className="head1ing4 ms-2">
          <em>{canaryMeta.provider_name}</em>
        </p>
      </div>
      <div key={`service-name`}>
        <p className="title">Service</p>
        <p className="head1ing4 ms-2">
          <em>{canaryMeta.service_name}</em>
        </p>
      </div>
      <div key={`region-name`}>
        <p className="title">Region</p>
        <p className="head1ing4 ms-2">
          <em>{canaryMeta.region_name}</em>
        </p>
      </div>
      <div key={`availability-zone-name`}>
        <p className="title">Availability Zone</p>
        <p className="head1ing4 ms-2">
          <em>{canaryMeta.availability_zone_name}</em>
        </p>
      </div>
    </div>
  )
}
