import styled from 'styled-components'

export const PageContainer = styled.div`
  box-sizing: border-box;
  min-height: 80%;
  margin: 25px;
  .running {
    color: #7abf21;
    font-size: 24px;
    margin-left: 16px;
  }
  .stopped {
    color: red;
    font-size: 24px;
    margin-left: 16px;
  }
  .error {
    color: red;
    font-size: 24px;
    margin-left: 16px;
  }
  .new {
    color: blue;
    font-size: 24px;
    margin-left: 16px;
  }
  .canaryName {
    vertical-align: top;
    display: inline;
    font-size: 24px;
    line-height: 28px;
  }

  #manageCanarySelectTaskContainer {
    margin-top: 2rem;
    .MuiGrid-item {
      padding-top: 0;
    }
    .rjsf .MuiGrid-root {
      padding-top: 0;
      margin-top: 0;
    }
  }
`

export const MiddleContainer = styled.div`
  width: 90%;
  margin: 15px auto;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: -10px;

  .CustomButton {
    margin: 10px;
  }

  .DateModify {
    display: flex;
    flex-flow: row no-wrap;
    justify-content: space-evenly;
    align-items: center;
    min-width: 200px;

    .FromTo {
      margin: 5px;
    }

    .DayPicker {
      margin: 2px;
      height: 35px;
      border-radius: 4px;
      border: 1px solid black;
    }

    .TimePicker {
      display: inline-flex;
    }
  }

  .btn {
    padding-top: 0.15em;
    padding-bottom: 0.15em;
    padding-left: 0.25em;
    padding-right: 0.25em;
  }
`

export const GraphContainer = styled.div`
  background-color: #f0feff3d;
  background-size: 100% 100%;
  margin: 10px auto;
`

export const TableContainer = styled.div`
  width: 100%;
  margin: 30px auto;
  margin-bottom: 100px;

  .Title {
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    margin: 10px;
  }

  .TableValues {
    background-color: #e8e8e8;

    thead > tr {
      font-weight: 500;
    }
  }
`
