import { ReactNode } from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/system/Box'

interface FormsCreateButtonProps {
  children: ReactNode
  disabled?: boolean
}

export default function FormsCreateButton(props: FormsCreateButtonProps) {
  const { children, disabled } = props
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        mt: 1.25
      }}
    >
      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={{ textTransform: 'capitalize' }}
        disabled={disabled}
      >
        {children}
      </Button>
    </Box>
  )
}
