import {
  selectDateFrom,
  selectDateTo,
  selectRefreshInterval,
  selectTimeFrom,
  selectTimeTo
} from 'client_side_state/slices/pageFilter'
import { useAppSelector } from 'client_side_state/store'
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { rowsPerPageOptions } from 'constants/AppConfig'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { CanaryTaskRunDataExportReadOnly } from 'lib/CloudCanariesRestfulAPI'
import DateTimeService from 'services/DateTimeService'
import CanaryErrorButton from './CanaryErrorButton'

const datetimeService: DateTimeService = new DateTimeService()

interface CanaryDataTableProps {
  canaryId: string
}

export default function CanaryDataTable(props: CanaryDataTableProps) {
  const { canaryId } = props
  const pageDateFromFilter = useAppSelector(selectDateFrom)
  const pageDateToFilter = useAppSelector(selectDateTo)
  const pageTimeFromFilter = useAppSelector(selectTimeFrom)
  const pageTimeToFilter = useAppSelector(selectTimeTo)
  const interval = useAppSelector(selectRefreshInterval)

  const { apiService } = useContext(APIServiceContext)
  const [page, setPage] = useState<number>(0)
  const [rowCount, setRowCount] = useState<number>(0)
  const [canaryData, setCanaryData] = useState<
    CanaryTaskRunDataExportReadOnly[]
  >([])
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage)
  }

  const getCanaryData = useCallback(async () => {
    if (canaryId) {
      const start = datetimeService.datetimeformatted(
        pageDateFromFilter,
        pageTimeFromFilter
      )
      const stop = datetimeService.datetimeformatted(
        pageDateToFilter,
        pageTimeToFilter
      )

      const djangoDateTimeStart = datetimeService.djangoDateTimeValue(start)
      const djangoDateTimeStop = datetimeService.djangoDateTimeValue(stop)

      const requestedPage = page + 1

      await apiService
        .listCanaryTaskRunDataExports(
          requestedPage,
          rowsPerPage,
          djangoDateTimeStart,
          djangoDateTimeStop,
          canaryId
        )
        .then((json) => {
          setCanaryData(json.data.results ?? [])
          setRowCount(json.data.count ?? 0)
        })
    }
  }, [
    canaryId,
    pageDateFromFilter,
    pageTimeFromFilter,
    pageDateToFilter,
    pageTimeToFilter,
    page,
    apiService,
    rowsPerPage
  ])

  useEffect(() => {
    if (canaryId) {
      getCanaryData()
    }
    const sync = setInterval(
      () => {
        getCanaryData()
      },
      60 * 1000 * interval
    )

    return () => clearInterval(sync)
  }, [canaryId, getCanaryData, interval])

  const onRowsPerPageChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value))
  }

  return (
    <div>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Timestamp</TableCell>
              <TableCell align="center">Raw Response Value</TableCell>
              <TableCell align="center">Got Response</TableCell>
              <TableCell align="center">Parsed Response</TableCell>
              <TableCell align="center">Response Value Healthy</TableCell>
              <TableCell align="center">Response Latency (ms)</TableCell>
              <TableCell align="center">Latency Healthy</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {canaryData.map((canaryData) => {
              return (
                <TableRow key={canaryData.id}>
                  <TableCell align="center">{canaryData.measured}</TableCell>
                  <TableCell align="center">
                    {canaryData.is_json ||
                    (canaryData.is_string &&
                      canaryData.normalized_value?.length! > 50) ? (
                      <Tooltip
                        title={
                          <Typography variant="body2">
                            {canaryData.normalized_value}
                          </Typography>
                        }
                        componentsProps={{
                          tooltip: {
                            sx: {
                              backgroundColor: '#ffffff',
                              color: '#000000C3',
                              border: '1px solid #0000004D'
                            }
                          }
                        }}
                        arrow
                      >
                        <Typography variant="button">
                          {canaryData.is_json ? 'JSON Value' : 'String Value'}
                        </Typography>
                      </Tooltip>
                    ) : (
                      canaryData.normalized_value
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <CanaryErrorButton error={canaryData.error_get} />
                  </TableCell>
                  <TableCell align="center">
                    <CanaryErrorButton error={canaryData.error_parse} />
                  </TableCell>
                  <TableCell align="center">
                    {canaryData.response_value_alarm_status}
                  </TableCell>
                  <TableCell align="center">
                    {canaryData.response_latency}
                  </TableCell>
                  <TableCell align="center">
                    {canaryData.latency_value_alarm_status}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={rowCount}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </div>
  )
}
