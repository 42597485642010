import { selectCanaryForecastCharts } from 'client_side_state/slices/forecastManagePage'
import { useAppSelector } from 'client_side_state/store'
import useDocumentTitle from 'hooks/useDocumentTitle'
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useHistory } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import { SelectChangeEvent } from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import Box from '@mui/system/Box'
import CanaryDailyForecastChartPanesSelector from 'components/CanaryForecastManage/CanaryDailyForecastChartPanesSelector'
import CanaryForecastDataTable from 'components/CanaryForecastManage/CanaryForecastDataTable'
import { CanaryDailyForecasts } from 'components/Forecast/IndividualCanaryForecastsSection'
import Icon, { IconType } from 'components/Icons'
import CustomButton from 'components/common/Button'
import CanaryManageHeaderLoader from 'components/common/Loaders/CanaryManageHeaderLoader'
import { queryParamPageSizeDefault } from 'constants/AppConfig'
import { CanaryForecastGraphMetrics } from 'constants/CanaryForecastGraphMetrics'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { OrganizationsContext } from 'contexts/OrganizationsContext'
import { GenericIDMatchProps } from 'interfaces/GenericIDMatchProps'
import {
  NeuralCanaryDailyForecast,
  NeuralCanaryDailyForecastData,
  SnowflakeCanaryDailyForecast
} from 'lib/CloudCanariesRestfulAPI'
import FuturePeriodService, {
  FuturePeriodOption
} from 'services/FuturePeriodService'
import {
  ButtonContainer,
  MiddleContainer,
  PageContainer
} from 'styles/pages/CanaryManage.styled'
import ExportCanaryDailyForecastButton from './ExportCanaryDailyForecastButton'
import ForecastCanaryDailyForecastDataManageChart from './ForecastCanaryDailyForecastDataManageChart'
import ForecastCanaryDailyForecastManageChart from './ForecastCanaryDailyForecastManageChart'
import FuturePeriodSelection from './FuturePeriodSelection'

const useStyles = makeStyles({
  root: {
    alignItems: 'center',
    alignContent: 'center',
    paddingTop: '8px',
    paddingBottom: '8px'
  },
  forecastStatus: {
    color: '#7abf21',
    fontSize: '1.5rem',
    marginLeft: 16,
    textTransform: 'uppercase'
  },
  providerSegment: {
    display: 'flex',
    justifyContent: 'center',
    color: '#969696',
    fontSize: '0.75rem',
    lineHeight: 0.9
  },
  provider: {
    fontSize: '1.25rem',
    fontWeight: 700,
    color: '#000',
    marginTop: '0.5rem'
  },
  center: {
    display: 'flex',
    justifyContent: 'center'
  },
  forecastTitle: {
    fontSize: '1.125rem',
    fontWeight: 700,
    lineHeight: '0.788rem'
  },
  dateFromTo: {
    textAlign: 'center',
    fontSize: '1.25rem',
    fontWeight: 700,
    lineHeight: '1.5rem',
    marginTop: 0,
    marginBottom: '1rem'
  },
  closeIcon: {
    textAlign: 'right',
    marginTop: '8px'
  },
  muiForm: {
    marginBottom: '0px !important',
    '& .MuiGrid-grid-xs-12"': {
      marginBottom: '0px !important'
    }
  }
})

export interface CanaryDailyForcastManageChart {
  id?: string
  for_date?: string[]
  day_of_week?: string[]
  percentage_chance_of_incident?: number[]
  latency_issues_predicted?: number[]
  service_id?: string
  service_name?: string
  provider_id?: string
  provider_name?: string
  canary_name?: string
  canary?: string
  has_prediction?: boolean[]
  task_name?: string
  template_canary_name?: string
  label?: string
  predictedResponseValue?: string
  responseLatency?: number
}
export interface CanaryForecastDaily
  extends NeuralCanaryDailyForecastData,
    SnowflakeCanaryDailyForecast {}

const futurePeriodService = new FuturePeriodService()
const futurePeriodsOptions = FuturePeriodService.futurePeriodsOptions
const rowsPerPageOptions = [50, 100, 200]

export default function CanaryForecastManage(props: GenericIDMatchProps) {
  const {
    match: {
      params: { id: canaryId }
    }
  } = props
  const history = useHistory()
  const classes = useStyles()
  const selectedCanaryDailyForecasts = useAppSelector(
    selectCanaryForecastCharts
  )

  const { apiService } = useContext(APIServiceContext)
  const { organization, organizationId } = useContext(OrganizationsContext)

  const isUsingSnowflakeCortex = useMemo<boolean>(
    () => organization?.use_cortex_forecast_model!,
    [organization?.use_cortex_forecast_model]
  )

  const [
    isCanaryDailyForecastsDataLoading,
    setIsCanaryDailyForecastsDataLoading
  ] = useState<boolean>(true)
  const [
    isCanaryDailyForecastDataLoading,
    setIsCanaryDailyForecastDataLoading
  ] = useState<boolean>(true)

  const [canaryDailyForecastsData, setCanaryDailyForecastsData] = useState<
    NeuralCanaryDailyForecast[] | SnowflakeCanaryDailyForecast[]
  >([])
  const [
    canaryDailyDetailedForecastsData,
    setCanaryDailyDetailedForecastsData
  ] = useState<CanaryForecastDaily[]>([])
  const [page, setPage] = useState<number>(0)
  const [rowCount, setRowCount] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(50)

  const [selectedFuturePeriod, setSelectedFuturePeriod] =
    useState<FuturePeriodOption>(futurePeriodsOptions[4])

  const [startDate, setStartDate] = useState<string>(
    futurePeriodService.getSelectedPeriodDates().startDate
  )
  const [stopDate, setStopDate] = useState<string>(
    futurePeriodService.getSelectedPeriodDates().stopDate
  )
  const [startDateTime, setStartDateTime] = useState<string>(
    futurePeriodService.getSelectedPeriodDateTimes().startDate
  )
  const [stopDateTime, setStopDateTime] = useState<string>(
    futurePeriodService.getSelectedPeriodDateTimes().stopDate
  )

  useEffect(() => {
    if (selectedFuturePeriod) {
      const { startDate, stopDate } =
        futurePeriodService.getSelectedPeriodDates()
      setStartDate(startDate)
      setStopDate(stopDate)
    }
  }, [selectedFuturePeriod])

  useEffect(() => {
    if (selectedFuturePeriod) {
      const { startDate, stopDate } =
        futurePeriodService.getSelectedPeriodDateTimes()
      setStartDateTime(startDate)
      setStopDateTime(stopDate)
    }
  }, [selectedFuturePeriod])

  const fetchCanaryDailyForecastsData = useCallback(async () => {
    setIsCanaryDailyForecastsDataLoading(true)
    if (!isUsingSnowflakeCortex) {
      await apiService
        .listNeuralProphetCanaryDailyForecasts(
          undefined,
          queryParamPageSizeDefault,
          startDate,
          stopDate,
          organizationId,
          canaryId
        )
        .then((json) => {
          setCanaryDailyForecastsData(json.data.results ?? [])
          setIsCanaryDailyForecastsDataLoading(false)
        })
    } else {
      await apiService
        .listSnowflakeCanaryDailyForecasts(
          1,
          queryParamPageSizeDefault,
          startDate,
          stopDate,
          organizationId,
          canaryId
        )
        .then((json) => {
          setCanaryDailyForecastsData(json.data.results ?? [])
          setIsCanaryDailyForecastsDataLoading(false)
        })
    }
  }, [
    apiService,
    canaryId,
    isUsingSnowflakeCortex,
    organizationId,
    startDate,
    stopDate
  ])

  useEffect(() => {
    fetchCanaryDailyForecastsData()
  }, [fetchCanaryDailyForecastsData])

  const fetchCanaryDailyForecastData = useCallback(async () => {
    const requestedPage = page + 1
    setIsCanaryDailyForecastDataLoading(true)
    if (!isUsingSnowflakeCortex) {
      await apiService
        .listNeuralProphetCanaryDailyForecastData(
          requestedPage,
          rowsPerPage,
          startDateTime,
          stopDateTime,
          organizationId,
          canaryId
        )
        .then((json) => {
          setCanaryDailyDetailedForecastsData(json.data.results ?? [])
          setRowCount(json.data.count ?? 0)
          setIsCanaryDailyForecastDataLoading(false)
        })
    } else {
      await apiService
        .listSnowflakeCanaryDailyForecasts(
          requestedPage,
          rowsPerPage,
          startDate,
          stopDate,
          organizationId,
          canaryId
        )
        .then((json) => {
          setCanaryDailyDetailedForecastsData(json.data.results ?? [])
          setRowCount(json.data.count ?? 0)
          setIsCanaryDailyForecastDataLoading(false)
        })
    }
  }, [
    apiService,
    canaryId,
    isUsingSnowflakeCortex,
    organizationId,
    page,
    rowsPerPage,
    startDate,
    startDateTime,
    stopDate,
    stopDateTime
  ])

  useEffect(() => {
    fetchCanaryDailyForecastData()
  }, [fetchCanaryDailyForecastData])

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage)
  }
  const onRowsPerPageChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value))
  }

  const reducedCanaryDailyForecastData =
    useMemo<CanaryDailyForcastManageChart>(() => {
      const data = canaryDailyForecastsData.reduce(
        (result: CanaryDailyForecasts[], current) => {
          const existingEntry = result.find(
            (item) => item.canary_name === current.canary_name
          )!
          if (existingEntry) {
            existingEntry.percentage_chance_of_incident?.push(
              current.percentage_chance_of_incident!
            )
            existingEntry.latency_issues_predicted?.push(
              current.latency_issues_predicted!
            )
            existingEntry.day_of_week?.push(current.day_of_week!)
            existingEntry.for_date?.push(current.for_date!)
            existingEntry.has_prediction?.push(current.has_prediction!)
          } else {
            const newEntry: CanaryDailyForecasts = {
              canary_name: current.canary_name!,
              percentage_chance_of_incident: [
                current.percentage_chance_of_incident!
              ],
              latency_issues_predicted: [current.latency_issues_predicted!],
              canary: current.canary,
              day_of_week: [current.day_of_week!],
              for_date: [current.for_date!],
              has_prediction: [current.has_prediction!],
              provider_id: current.provider_id,
              provider_name: current.provider_name,
              service_id: current.service_id,
              service_name: current.service_name,
              task_name: current.task_name,
              template_canary_name: current.template_canary_name
            }
            result.push(newEntry)
          }
          return result
        },
        []
      )
      return data[0]
    }, [canaryDailyForecastsData])

  const canaryName = reducedCanaryDailyForecastData?.canary_name

  useDocumentTitle(
    canaryName
      ? `Canary "${reducedCanaryDailyForecastData!
          .canary_name!}" Forecast Management`
      : 'Canary Forecast Management'
  )
  function formatDateRange(
    startDateString: string,
    endDateString: string
  ): string {
    const startDate = new Date(startDateString)
    const endDate = new Date(endDateString)

    const options: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      timeZone: 'UTC'
    }

    const formattedStartDate = startDate.toLocaleDateString('en-US', options)
    const formattedEndDate = endDate.toLocaleDateString('en-US', options)
    return `${formattedStartDate} to ${formattedEndDate}`
  }
  const handleFuturePeriodChange = (event: SelectChangeEvent) => {
    const selectedValue = futurePeriodService.findAndSetSelectedPeriod(
      event.target.value as string
    )
    if (selectedValue) {
      setSelectedFuturePeriod(selectedValue)
    }
  }

  if (isCanaryDailyForecastsDataLoading) {
    return <CanaryManageHeaderLoader />
  }
  return (
    <PageContainer>
      <Grid container spacing={2} style={{ marginBottom: 8 }}>
        <Grid item xs={11} md={11}>
          <Grid container alignItems="flex-start">
            <Grid item xs={12} md={4} style={{ marginTop: '8px' }}>
              <h2 className={'canaryName'}>
                {reducedCanaryDailyForecastData.canary_name! || ''}
                <span className={classes.forecastStatus}>Forecast</span>
              </h2>
            </Grid>
            <Grid item xs={12} md={8} style={{ marginTop: '8px' }}>
              <span className={classes.provider}>
                {reducedCanaryDailyForecastData.provider_name || ''} {'>'}{' '}
                {reducedCanaryDailyForecastData.service_name || ''} {'>'}{' '}
                {reducedCanaryDailyForecastData.template_canary_name || ''}
              </span>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} md={1} className={classes.closeIcon}>
          <CustomButton
            className="CustomButton"
            leftIcon={<Icon name={IconType.X} />}
            backgroundColor="#48A2F4"
            action={() => history.goBack()}
          />
        </Grid>
      </Grid>
      <Grid
        direction="row"
        className={classes.root}
        container
        id={'manageCanarySelectTaskContainer'}
        justifyContent="center"
      >
        <Grid item xs={4} md={3} className={classes.center}>
          <ButtonContainer>
            <CanaryDailyForecastChartPanesSelector />
          </ButtonContainer>
        </Grid>
        <Grid item xs={5} md={5} className={classes.center}>
          <h4 className={classes.dateFromTo}>
            {formatDateRange(startDate, stopDate)}
          </h4>
        </Grid>
        <Grid item xs={2} className={classes.center}>
          <ButtonContainer>
            <FuturePeriodSelection
              selectedFuturePeriod={selectedFuturePeriod}
              futurePeriodsOptions={futurePeriodsOptions}
              handleFuturePeriodChange={handleFuturePeriodChange}
            />
          </ButtonContainer>
        </Grid>
        <Grid
          container
          item
          xs={1}
          md={1}
          className={classes.center}
          style={{
            justifyContent: 'flex-end'
          }}
        >
          <ButtonContainer>
            <ExportCanaryDailyForecastButton canaryId={canaryId} />
          </ButtonContainer>
        </Grid>
      </Grid>
      <MiddleContainer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            margin: '10px auto'
          }}
        >
          {selectedCanaryDailyForecasts.map(
            (selectedCanaryDailyForecastChart) => {
              if (
                selectedCanaryDailyForecastChart ===
                  CanaryForecastGraphMetrics.INICDENT_AND_LATENCY ||
                selectedCanaryDailyForecastChart ===
                  CanaryForecastGraphMetrics.LATENCY_ISSUES_PREDICTED ||
                selectedCanaryDailyForecastChart ===
                  CanaryForecastGraphMetrics.PERCENTAGE_CHANCE_OF_INCIDENT
              ) {
                return (
                  <ForecastCanaryDailyForecastManageChart
                    key={selectedCanaryDailyForecastChart}
                    selectedCanaryDailyForecastChart={
                      selectedCanaryDailyForecastChart
                    }
                    canaryDailyForecast={reducedCanaryDailyForecastData}
                  />
                )
              } else {
                return (
                  <ForecastCanaryDailyForecastDataManageChart
                    key={selectedCanaryDailyForecastChart}
                    canaryDailyDetailedForecastsData={
                      canaryDailyDetailedForecastsData
                    }
                    selectedCanaryDailyForecastChart={
                      selectedCanaryDailyForecastChart
                    }
                  />
                )
              }
            }
          )}
        </Box>
      </MiddleContainer>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ margin: '1rem 0' }}
      >
        <Grid item xs={12}>
          <Typography
            className={classes.forecastTitle}
            variant="h5"
            gutterBottom
            component="div"
          >
            Forecast Data
          </Typography>
        </Grid>
      </Grid>
      <CanaryForecastDataTable
        isCanaryDailyForecastsDataLoading={isCanaryDailyForecastsDataLoading}
        canaryDailyForecastData={reducedCanaryDailyForecastData}
        canaryDailyDetailedForecastsData={canaryDailyDetailedForecastsData}
        isCanaryDailyForecastDataLoading={isCanaryDailyForecastDataLoading}
        page={page}
        rowCount={rowCount}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        onRowsPerPageChange={onRowsPerPageChange}
        rowsPerPageOptions={rowsPerPageOptions}
      />
    </PageContainer>
  )
}
