import { FormConfigTypes } from './FormConfigsTypes'

export const generalConfigChannelConfigurations: FormConfigTypes = {
  schema: {
    type: 'object',
    required: [
      'user_email',
      'auth_token',
      'channel_identifier',
      'organization_id',
      'notification_channel'
    ],
    properties: {
      id: { type: 'string', title: 'Channel Configuration ID' },
      user_email: { type: 'string', title: 'User Email' },
      auth_token: { type: 'string', title: 'Auth Token' },
      channel_identifier: { type: 'string', title: 'Channel Identifier' },
      organization_id: { type: 'string', title: 'Organization' },
      notification_channel: { type: 'string', title: 'Notification Channel' }
    }
  },
  uiSchema: {
    user_email: { 'ui:autocomplete': 'off' },
    auth_token: {
      'ui:widget': 'password',
      'ui:autocomplete': 'off'
    },
    channel_identifier: {
      'ui:help':
        'This could be the project name on Jira or the channel name on Slack.'
    },
    id: { 'ui:widget': 'hidden' },
    organization_id: { 'ui:widget': 'hidden' },
    notification_channel: { 'ui:widget': 'hidden' }
  }
}

export const jiraConfigChannelConfigurations: FormConfigTypes = {
  schema: {
    type: 'object',
    required: [
      'user_email',
      'auth_token',
      'channel_identifier',
      'jira_service_desk_id',
      'jira_request_type_id',
      'auto_resolve_tickets_okay',
      'organization_id',
      'notification_channel'
    ],
    properties: {
      id: { type: 'string', title: 'Channel Configuration ID' },
      user_email: { type: 'string', title: 'User Email' },
      auth_token: { type: 'string', title: 'Auth Token' },
      channel_identifier: { type: 'string', title: 'Channel Identifier' },
      jira_service_desk_id: {
        type: 'string',
        title: 'Jira Project Service Desk ID'
      },
      jira_request_type_id: { type: 'string', title: 'Jira Request Type ID' },
      auto_resolve_tickets_okay: {
        type: 'string',
        title: 'Auto Resolve Tickets?',
        enum: ['Yes', 'No'],
        default: 'No'
      },
      organization_id: { type: 'string', title: 'Organization' },
      notification_channel: { type: 'string', title: 'Notification Channel' }
    },
    allOf: [
      {
        if: {
          properties: {
            auto_resolve_tickets_okay: {
              const: 'Yes'
            }
          }
        },
        then: {
          properties: {
            jira_ticket_resolve_transition_id: {
              type: 'string',
              title: 'Ticket Resolve Transition ID'
            },
            jira_ticket_no_data_transition_id: {
              type: 'string',
              title: 'Ticket NO_DATA Transition ID'
            }
          },
          required: [
            'jira_ticket_resolve_transition_id',
            'jira_ticket_no_data_transition_id'
          ]
        }
      }
    ]
  },
  uiSchema: {
    user_email: { 'ui:autocomplete': 'off' },
    auth_token: {
      'ui:widget': 'password',
      'ui:autocomplete': 'off'
    },
    channel_identifier: {
      'ui:help':
        'This could be the project name on Jira or the channel name on Slack.'
    },
    jira_service_desk_id: {
      'ui:help':
        'The service desk id is the project id, which can be obtained from Project Settings > Portal Settings > Portal URL, it will be the number at the end.'
    },
    jira_request_type_id: {
      'ui:help':
        'The customer request id, which can be obtained from Project Settings > Request Types > Incidents, click the request type and get the id from the url, it should be after /request-type/'
    },
    auto_resolve_tickets_okay: {
      'ui:widget': 'radio',
      'ui:options': {
        inline: true
      }
    },
    jira_ticket_resolve_transition_id: {
      'ui:help':
        'The Resolve transition ID that will be performed when the alarm transits to OK, can be obtained from Project Settings > Workflows, choose the workflow related to the request type and click the edit icon under the actions column, view the workflow as a diagram, click the transition arrow, select Show transition in the customer portal, configure it to Set issue resolution to Done, then publish the worflow.'
    },
    jira_ticket_no_data_transition_id: {
      'ui:help':
        'The NO_DATA transition ID that will be performed when the alarm transits to NO_DATA, this step "NO_DATA" and transition from "Open" to "NO_DATA" and transition from "Resolved" to "NO_DATA" need to be added in the workflow and configured to be Shown in the customer portal and Set issue resolution needs to be "Open" so they can be available for the requests.'
    },
    id: { 'ui:widget': 'hidden' },
    organization_id: { 'ui:widget': 'hidden' },
    notification_channel: { 'ui:widget': 'hidden' }
  }
}
