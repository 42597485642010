import { useContext, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import { IChangeEvent } from '@rjsf/core'
import MuiForm from '@rjsf/mui'
import { RJSFSchema } from '@rjsf/utils'
import validator from '@rjsf/validator-ajv8'
import Icon, { IconType } from 'components/Icons'
import DialogModal from 'components/Modals/DialogModal'
import { configEditCustomSchemaName } from 'constants/formconfig/CustomeSchemas'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { OpenAPIRestfulLibrariesContext } from 'contexts/OpenAPIRestfulLibrariesContext'
import { OrganizationsContext } from 'contexts/OrganizationsContext'

const schema = configEditCustomSchemaName.schema

interface EditModalProps {
  schemaId: string
  currentName: string
}

export default function EditModal(props: EditModalProps) {
  const { schemaId, currentName } = props

  const { apiService } = useContext(APIServiceContext)
  const { organizationId } = useContext(OrganizationsContext)
  const { fetchOpenAPIRestfulLibraries } = useContext(
    OpenAPIRestfulLibrariesContext
  )

  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const onSubmit = async (
    data: IChangeEvent<any, RJSFSchema, any>,
    event: React.FormEvent<any>
  ) => {
    let requestBody = JSON.parse(JSON.stringify(data.formData))
    try {
      await apiService.partialUpdateOpenAPIRestfulLibrary(
        schemaId,
        organizationId,
        requestBody
      )
      await fetchOpenAPIRestfulLibraries()
      handleClose()
    } catch {
      console.log('error contacting api')
    }
    handleClose()
    return false
  }

  const formData = { name: currentName }

  return (
    <>
      <IconButton
        onClick={handleClickOpen}
        color="primary"
        size="small"
        style={{ marginRight: '0.5rem' }}
      >
        <Icon name={IconType.Edit} />
      </IconButton>
      {currentName}
      <DialogModal
        open={open}
        onClose={handleClose}
        dialogTitle="Edit Name"
        dialogContent={
          <MuiForm
            schema={schema}
            onSubmit={onSubmit}
            formData={formData}
            validator={validator}
          />
        }
      />
    </>
  )
}
