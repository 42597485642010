import { Buffer } from 'buffer'
import {
  setOrganizationRequiredModalMessage,
  setOrganizationRequiredModalOpen
} from 'client_side_state/slices/app'
import { useAppDispatch } from 'client_side_state/store'
import { OpenAPISchemaValidatorResult } from 'openapi-schema-validator'
import { OpenAPI, OpenAPIV2, OpenAPIV3, OpenAPIV3_1 } from 'openapi-types'
import { useContext, useEffect, useState } from 'react'
import FormHelperText from '@mui/material/FormHelperText'
import { createStyles, makeStyles } from '@mui/styles'
import { IChangeEvent } from '@rjsf/core'
import MuiForm from '@rjsf/mui'
import { RJSFSchema } from '@rjsf/utils'
import validator from '@rjsf/validator-ajv8'
import DialogModal from 'components/Modals/DialogModal'
import OrganizationRequiredDialog, {
  OrganizationRequiredMessage
} from 'components/UserOrganization/OrganizationRequiredDialog'
import CreateButton from 'components/common/CreateButton'
import FormsCreateButton from 'components/common/FormsCreateButton'
import { configAddCustomSchema } from 'constants/formconfig/CustomeSchemas'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { OpenAPIRestfulLibrariesContext } from 'contexts/OpenAPIRestfulLibrariesContext'
import { OrganizationsContext } from 'contexts/OrganizationsContext'
import OpenAPIErrorsMoal from './OpenAPIErrorsMoal'

const schema = configAddCustomSchema.schema

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    muiForm: {
      '& label.MuiFormLabel-root': {
        fontSize: '1.2rem'
      },
      '& span.MuiFormControlLabel-label ': {
        fontSize: '1.2rem'
      },
      '& input[type="file" i]': {
        fontSize: '1.2rem'
      }
    }
  })
)

export default function CreateModal() {
  const classes = useStyles()
  const dispatch = useAppDispatch()

  const { apiService } = useContext(APIServiceContext)
  const { organizationId } = useContext(OrganizationsContext)
  const { fetchOpenAPIRestfulLibraries } = useContext(
    OpenAPIRestfulLibrariesContext
  )

  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [openAPIErrorsModalOpen, setOpenAPIErrorsModalOpen] =
    useState<boolean>(false)
  const [formData, setFormData] = useState<any>(null)
  const [hasFileErrors, setHasFileErrors] = useState<boolean>(false)
  const [hasOpenAPIErrors, setHasOpenAPIErrors] = useState<boolean>(false)
  const [fileErrors, setFileErrors] = useState(null)
  const [openAPIErrors, setOpenAPIErrors] = useState<
    OpenAPISchemaValidatorResult['errors']
  >([])

  const handleCreateModalClickOpen = () => {
    if (!organizationId) {
      dispatch(
        setOrganizationRequiredModalMessage(
          OrganizationRequiredMessage.RestfulLibraryCreate
        )
      )
      dispatch(setOrganizationRequiredModalOpen(true))
    } else {
      setCreateModalOpen(true)
    }
  }

  const handleCreateModalClose = () => {
    setCreateModalOpen(false)
    setFormData({})
  }

  const handleOpenAPIErrorsModalOpen = () => {
    setOpenAPIErrorsModalOpen(true)
  }
  const handleOpenAPIErrorsModalClose = () => {
    setOpenAPIErrorsModalOpen(false)
    setHasFileErrors(false)
    setHasOpenAPIErrors(false)
  }

  const onChange = (
    event: IChangeEvent<any, RJSFSchema, any>,
    id?: string | undefined
  ) => {
    const formData = JSON.parse(JSON.stringify(event.formData))
    setFormData(formData)
    setHasFileErrors(false)
    setHasOpenAPIErrors(false)
  }

  const onSubmit = async (
    data: IChangeEvent<any, RJSFSchema, any>,
    event: React.FormEvent<any>
  ) => {
    let requestBody = JSON.parse(JSON.stringify(data.formData))
    const decodedData = Buffer.from(
      requestBody.input_file.split(',')[1],
      'base64'
    ).toString()

    let fileContents:
      | (OpenAPIV3.Document<{}> &
          OpenAPIV2.Document<{}> &
          OpenAPI.Document<{}> &
          OpenAPIV3_1.Document<{}>)
      | null = null
    let hasFileErrors = false
    let hasOpenAPIErrors = false
    try {
      fileContents = JSON.parse(decodedData)
      hasFileErrors = false
      setHasFileErrors(hasFileErrors)
    } catch (error: any) {
      setFileErrors(error.toString())
      hasFileErrors = true
      setFormData({ ...formData, input_file: '' })
      setHasFileErrors(hasFileErrors)
    }

    if (fileContents && !hasFileErrors) {
      if (parseInt(fileContents.swagger) >= 3 && !fileContents.openapi) {
        setOpenAPIErrors([
          {
            instancePath: '',
            keyword: 'required',
            message:
              '"swagger" parameter is provided, but "openapi" is missing, replace "swagger" with "openapi".',
            params: { missingParam: 'openapi' },
            schemaPath: '#/required'
          }
        ])
        hasOpenAPIErrors = true
        setHasOpenAPIErrors(hasOpenAPIErrors)
      } else if (parseInt(fileContents.swagger) < 3) {
        setOpenAPIErrors([
          {
            instancePath: '',
            keyword: 'required',
            message:
              '"swagger" parameter is provided with version lower than 3.0.0, but "openapi" is missing, replace "swagger" with "openapi", and update the OpenAPI file to version 3.0.0 at least.',
            params: { versionMissMatch: 'openapi' },
            schemaPath: '#/required'
          }
        ])
        hasOpenAPIErrors = true
        setHasOpenAPIErrors(hasOpenAPIErrors)
      } else if (parseInt(fileContents.openapi) < 3) {
        setOpenAPIErrors([
          {
            instancePath: '',
            keyword: 'required',
            message: 'OpenAPI version must be at least 3.0.0.',
            params: { versionMissMatch: 'openapi' },
            schemaPath: '#/required'
          }
        ])
        hasOpenAPIErrors = true
        setHasOpenAPIErrors(hasOpenAPIErrors)
      } else if (!fileContents.servers || fileContents.servers?.length === 0) {
        setOpenAPIErrors([
          {
            instancePath: 'servers',
            keyword: 'required',
            message:
              '"servers" parameter is missing, please add "servers" parameter with at least one server URL.',
            params: { missingParam: 'servers' },
            schemaPath: '#/required'
          }
        ])
        hasOpenAPIErrors = true
        setHasOpenAPIErrors(hasOpenAPIErrors)
      } else {
        setOpenAPIErrors([])
        hasOpenAPIErrors = false
        setHasOpenAPIErrors(hasOpenAPIErrors)
      }
    }
    if (!hasFileErrors && !hasOpenAPIErrors) {
      try {
        await apiService.createOpenAPIRestfulLibrary(requestBody)
        await fetchOpenAPIRestfulLibraries()
      } catch {
        console.log('error contacting api')
      }
      handleCreateModalClose()
    }
  }

  useEffect(() => {
    if (hasOpenAPIErrors) {
      handleOpenAPIErrorsModalOpen()
    }
  }, [hasOpenAPIErrors])

  return (
    <div>
      <CreateButton onClick={handleCreateModalClickOpen} text={'upload'} />
      {organizationId ? (
        <DialogModal
          open={createModalOpen}
          onClose={handleCreateModalClose}
          dialogTitle="Upload Custom Schema"
          dialogContent={
            <>
              <MuiForm
                schema={schema}
                onSubmit={onSubmit}
                onChange={onChange}
                formData={{ ...formData, organization_id: organizationId }}
                uiSchema={configAddCustomSchema.uiSchema}
                className={classes.muiForm}
                validator={validator}
              >
                <FormsCreateButton disabled={hasFileErrors || hasOpenAPIErrors}>
                  Upload
                </FormsCreateButton>
              </MuiForm>
              {hasFileErrors && fileErrors && (
                <FormHelperText error style={{ fontSize: 18, fontWeight: 500 }}>
                  {fileErrors}
                </FormHelperText>
              )}
              {openAPIErrorsModalOpen && (
                <OpenAPIErrorsMoal
                  openAPIErrorsModalOpen={openAPIErrorsModalOpen}
                  handleOpenAPIErrorsModalClose={handleOpenAPIErrorsModalClose}
                  errors={openAPIErrors}
                />
              )}
            </>
          }
        />
      ) : (
        <OrganizationRequiredDialog />
      )}
    </div>
  )
}
