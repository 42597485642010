import useDocumentTitle from 'hooks/useDocumentTitle'
import { useContext } from 'react'
import { toast } from 'react-toastify'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import ForecastModelsCreateModal from 'components/ForecastModels/ForecastModelsCreateModal'
import ForecastModelsDeleteModal from 'components/ForecastModels/ForecastModelsDeleteModal'
import ForecastModelsEditModal from 'components/ForecastModels/ForecastModelsEditModal'
import Icon, { IconType } from 'components/Icons'
import { AdminPagesTitles } from 'components/MyAdminPages/Constants'
import MyAdminPagesCommonHeader from 'components/MyAdminPages/MyAdminPagesCommonHeader'
import NoDataFound from 'components/MyAdminPages/NoDataFound'
import Toast from 'components/Toast'
import PagesSkeletonLoader from 'components/common/Loaders/PagesSkeletonLoader'
import { rowsPerPageOptions } from 'constants/AppConfig'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { OrganizationsContext } from 'contexts/OrganizationsContext'
import { SnowflakeForecastModelsContext } from 'contexts/SnowflakeForecastModelsContext'
import adminPagesSharedStyles from 'pages/AdminPages/adminPagesSharedStyles'

export default function ForecastModels() {
  const classes = adminPagesSharedStyles()
  useDocumentTitle('Forecast Models')

  const { apiService } = useContext(APIServiceContext)
  const { organizationId } = useContext(OrganizationsContext)
  const {
    areForecastModelsLoading,
    forecastModels,
    selectedForecastModelsIds,
    onChangeSelectRow,
    setSelectedModel,
    fetchForecastModels
  } = useContext(SnowflakeForecastModelsContext)

  const deleteForecastModel = async (schemaId: string, currentName: string) => {
    await apiService
      .destroySnowflakeForecastModel(schemaId, organizationId)
      .then(async (response) => {
        if (response.status === 204) {
          toast.success(
            <Toast
              title="Success"
              text={`Forecast model "${currentName}" is deleted successfully!`}
            />,
            {
              position: 'top-right'
            }
          )
        } else {
          toast.error(
            <Toast
              title="Error"
              text="Something went wrong, please try again!"
            />,
            {
              position: 'top-right'
            }
          )
        }
        await fetchForecastModels()
      })
  }

  return (
    <Container maxWidth="xl" className={classes.mainContainer}>
      <MyAdminPagesCommonHeader />
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <h1 className={classes.headerRow}>
          {AdminPagesTitles.ForecastModels}
          <ForecastModelsCreateModal />
        </h1>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Button
            variant="contained"
            sx={{ color: '#000000', bgcolor: '#AFD2FF' }}
            disabled={
              !organizationId ||
              selectedForecastModelsIds.length === 0 ||
              selectedForecastModelsIds.length > 1
            }
            onClick={setSelectedModel}
          >
            Select
          </Button>
          {/* <Button
            variant="contained"
            disabled
            sx={{
              color: '#000000',
              bgcolor: '#AFD2FF',
              ml: '0.5rem',
              width: '7rem'
            }}
          >
            Fine Tune
          </Button> */}
        </Box>
      </Box>
      {areForecastModelsLoading ? (
        <PagesSkeletonLoader />
      ) : (
        <>
          <TableContainer component={Paper} elevation={3}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox"></TableCell>
                  <TableCell>Model Name</TableCell>
                  <TableCell>Selected</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Creation Date</TableCell>
                  <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell>
                  <TableCell>Fine Tuned</TableCell>
                  <TableCell className={classes.firstCell}>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {forecastModels && forecastModels.length > 0 ? (
                  forecastModels.map((forecastModel) => {
                    return (
                      <TableRow key={forecastModel.id}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={selectedForecastModelsIds.includes(
                              forecastModel.id!
                            )}
                            onChange={(event) => {
                              onChangeSelectRow(event, forecastModel.id!)
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <ForecastModelsEditModal
                            forecastModel={forecastModel}
                          />
                        </TableCell>
                        <TableCell>
                          <Icon
                            name={
                              forecastModel.is_selected
                                ? IconType.StateClear
                                : IconType.None
                            }
                            style={{
                              color: forecastModel.is_selected ? '#81c784' : ''
                            }}
                          />
                        </TableCell>
                        <TableCell>{forecastModel.status}</TableCell>
                        <TableCell>
                          {new Date(forecastModel.created!).toLocaleString()}
                        </TableCell>
                        <TableCell>
                          {new Date(
                            forecastModel.data_start_date!
                          ).toLocaleString()}
                        </TableCell>
                        <TableCell>
                          {new Date(
                            forecastModel.data_end_date!
                          ).toLocaleString()}
                        </TableCell>
                        <TableCell>
                          {forecastModel.fine_tuned ? 'Yes' : 'No'}
                        </TableCell>
                        <TableCell className={classes.firstCell}>
                          <ForecastModelsDeleteModal
                            currentName={forecastModel.name!}
                            afterSubmit={() =>
                              deleteForecastModel(
                                forecastModel.id!,
                                forecastModel.name!
                              )
                            }
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <NoDataFound pageName="Forecast Models" colSpan={7} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={3}
            rowsPerPage={10}
            rowsPerPageOptions={rowsPerPageOptions}
            page={0}
            onPageChange={() => {}}
            onRowsPerPageChange={() => {}}
          />
        </>
      )}
    </Container>
  )
}
