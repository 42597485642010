import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Box from '@mui/system/Box'
import { IChangeEvent } from '@rjsf/core'
import MuiForm from '@rjsf/mui'
import { ErrorSchema, ErrorSchemaBuilder, RJSFSchema } from '@rjsf/utils'
import validator from '@rjsf/validator-ajv8'
import { AxiosError, isAxiosError } from 'axios'
import Icon, { IconType } from 'components/Icons'
import DialogModal from 'components/Modals/DialogModal'
import FormsCreateButton from 'components/common/FormsCreateButton'
import { queryParamPageSizeDefault } from 'constants/AppConfig'
import {
  generalConfigChannelConfigurations,
  jiraConfigChannelConfigurations
} from 'constants/formconfig/channelConfigurations'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { OrganizationsContext } from 'contexts/OrganizationsContext'
import {
  NotificationChannelSetting,
  NotificationChannelsEnum
} from 'lib/CloudCanariesRestfulAPI'

function SelectLabel() {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: '#3d89ff'
      }}
    >
      <Icon name={IconType.Settings} />
      Channel
    </Box>
  )
}
const defaultFormData = {
  id: '',
  user_email: '',
  auth_token: '',
  organization_id: '',
  channel_identifier: '',
  notification_channel: '',
  jira_service_desk_id: '',
  jira_request_type_id: '',
  jira_ticket_resolve_transition_id: '',
  jira_ticket_no_data_transition_id: ''
}

export default function ChannelConfigurationModal() {
  const { apiService } = useContext(APIServiceContext)
  const { organizationId } = useContext(OrganizationsContext)

  const [selectedChannel, setSelectedChannel] = useState<
    NotificationChannelsEnum | string
  >('')
  const [isUpdateConfigurations, setIsUpdateConfigurations] =
    useState<boolean>(false)
  const [channelModalOpen, setChannelModalOpen] = useState<boolean>(false)
  const [formData, setFormData] = useState({
    ...defaultFormData,
    organization_id: organizationId
  })
  const [extraErrors, setExtraErrors] = useState<ErrorSchema>({})
  const [channelConfigurations, setChannelConfigurations] = useState<
    NotificationChannelSetting[]
  >([])

  const fetchChannelConfigurations = useCallback(async () => {
    await apiService
      .listNotificationChannelSettings(
        undefined,
        queryParamPageSizeDefault,
        organizationId
      )
      .then((json) => {
        setChannelConfigurations(json.data.results ?? [])
      })
  }, [apiService, organizationId])

  useEffect(() => {
    fetchChannelConfigurations()
  }, [fetchChannelConfigurations])

  function handleModalClose() {
    setExtraErrors({})
    setChannelModalOpen(false)
    setSelectedChannel('')
  }

  const handlechannelChange = (event: SelectChangeEvent) => {
    const selectedChannel = event.target.value as string
    setSelectedChannel(selectedChannel)
    const selectedChannelConfigurations = channelConfigurations.find(
      (config) => config.notification_channel === selectedChannel
    )
    if (selectedChannelConfigurations) {
      setIsUpdateConfigurations(true)
      setFormData({
        ...formData,
        id: selectedChannelConfigurations.id!,
        user_email: selectedChannelConfigurations.user_email,
        auth_token: selectedChannelConfigurations.auth_token,
        notification_channel:
          selectedChannelConfigurations.notification_channel!,
        channel_identifier: selectedChannelConfigurations.channel_identifier!,
        jira_service_desk_id:
          selectedChannelConfigurations.jira_service_desk_id!,
        jira_request_type_id:
          selectedChannelConfigurations.jira_request_type_id!,
        jira_ticket_resolve_transition_id:
          selectedChannelConfigurations.jira_ticket_resolve_transition_id!,
        jira_ticket_no_data_transition_id:
          selectedChannelConfigurations.jira_ticket_no_data_transition_id!
      })
    } else {
      setIsUpdateConfigurations(false)
      setFormData({
        ...formData,
        notification_channel: selectedChannel,
        id: '',
        user_email: '',
        auth_token: '',
        channel_identifier: '',
        jira_service_desk_id: '',
        jira_request_type_id: '',
        jira_ticket_resolve_transition_id: '',
        jira_ticket_no_data_transition_id: ''
      })
    }
  }

  useEffect(() => {
    if (selectedChannel === '') {
      setChannelModalOpen(false)
    } else {
      setChannelModalOpen(true)
    }
  }, [channelConfigurations, formData, selectedChannel])

  const onSubmit = async (
    data: IChangeEvent<any, RJSFSchema, any>,
    event: React.FormEvent<any>
  ) => {
    let requestBody = JSON.parse(JSON.stringify(data.formData))
    if (!isUpdateConfigurations) {
      try {
        await apiService
          .createNotificationChannelSetting(requestBody)
          .then(async (json) => {
            if (json.status === 201) {
              fetchChannelConfigurations()
              handleModalClose()
            }
          })
      } catch (e: any) {
        if (isAxiosError(e)) {
          const err = e as AxiosError
          if (err.response?.status === 400) {
            const errors = err.response?.data as {}
            const builder = new ErrorSchemaBuilder()
            Object.entries(errors).forEach(([key, value]) => {
              builder.addErrors(value as string, key)
            })
            setExtraErrors(builder.ErrorSchema)
          }
        }
      }
    } else {
      try {
        await apiService
          .partialUpdateNotificationChannelSetting(
            requestBody.id,
            organizationId,
            requestBody
          )
          .then(async (json) => {
            if (json.status === 200) {
              fetchChannelConfigurations()
              handleModalClose()
            }
          })
      } catch (e: any) {
        if (isAxiosError(e)) {
          const err = e as AxiosError
          if (err.response?.status === 400) {
            const errors = err.response?.data as {}
            const builder = new ErrorSchemaBuilder()
            Object.entries(errors).forEach(([key, value]) => {
              builder.addErrors(value as string, key)
            })
            setExtraErrors(builder.ErrorSchema)
          }
        }
      }
    }
  }

  const schema = useMemo(() => {
    if (selectedChannel === NotificationChannelsEnum.Jira) {
      return jiraConfigChannelConfigurations.schema
    } else {
      return generalConfigChannelConfigurations.schema
    }
  }, [selectedChannel])

  const uiSchema = useMemo(() => {
    if (selectedChannel === NotificationChannelsEnum.Jira) {
      return jiraConfigChannelConfigurations.uiSchema
    } else {
      return generalConfigChannelConfigurations.uiSchema
    }
  }, [selectedChannel])

  return (
    <>
      <FormControl sx={{ minWidth: 140 }} size="small">
        <InputLabel id="channel-config-select-label">
          <SelectLabel />
        </InputLabel>
        <Select
          labelId="channel-config-select-label"
          id="channel-config-select"
          value={selectedChannel}
          label={<SelectLabel />}
          onChange={handlechannelChange}
          sx={{
            minWidth: 140,
            '& .MuiSelect-icon': {
              color: '#3d89ff'
            }
          }}
        >
          <MenuItem value="">None</MenuItem>
          {Object.values(NotificationChannelsEnum)
            .filter(
              (channel) => channel !== NotificationChannelsEnum.CloudCanaries
            )
            .map((channel) => (
              <MenuItem key={channel} value={channel}>
                {channel}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <DialogModal
        onClose={handleModalClose}
        open={channelModalOpen}
        fullWidth
        maxWidth={
          selectedChannel === NotificationChannelsEnum.Jira ? 'lg' : undefined
        }
        dialogTitle={`${selectedChannel} Channel Configuration`}
        dialogContent={
          <MuiForm
            schema={schema}
            formData={formData}
            onSubmit={onSubmit}
            autoComplete="off"
            extraErrors={extraErrors}
            uiSchema={uiSchema}
            validator={validator}
          >
            <FormsCreateButton>Update</FormsCreateButton>
          </MuiForm>
        }
      />
    </>
  )
}
