import { useMemo } from 'react'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import uniqueId from 'lodash/uniqueId'
import {
  CompositeDailyForecast,
  SnowflakeCompositeDailyForecast
} from 'lib/CloudCanariesRestfulAPI'
import CloudBox from './CloudBox'

const useStyles = makeStyles({
  topBar: {
    height: '14px',
    width: '100%',
    background: 'transparent',
    border: 'gray'
  }
})

interface NoCompositeForcastEmptyGridProps {
  compositeDailyForecasts:
    | CompositeDailyForecast[]
    | SnowflakeCompositeDailyForecast[]
}

export default function NoCompositeForcastEmptyGrid(
  props: NoCompositeForcastEmptyGridProps
) {
  const classes = useStyles()
  const { compositeDailyForecasts } = props
  let dateDays = useMemo(() => {
    return new Map<string, string>()
  }, [])
  let _dates = new Array<string>()
  compositeDailyForecasts.forEach((forecast) => {
    if (forecast.for_date && forecast.day_of_week) {
      dateDays.set(forecast.for_date, forecast.day_of_week)
      _dates.push(forecast.for_date)
    }
  })

  const datesSet = new Set(_dates)
  const dates = Array.from(datesSet.values())

  const emptyCardsItems = useMemo(() => {
    let result = []

    for (let dateOfForecast of dates) {
      const dayOfWeek = dateDays.get(dateOfForecast)
      if (dayOfWeek) {
        result.push({
          dateOfForecast,
          dayOfWeek
        })
      }
    }
    return result
  }, [dates, dateDays])

  return (
    <Grid container spacing={2}>
      {emptyCardsItems.map((item) => {
        return (
          <Grid
            key={uniqueId(item.dateOfForecast)}
            item
            style={{
              width: '300px',
              marginBottom: '24px'
            }}
          >
            <Card style={{ height: '480px' }}>
              <CardActionArea>
                <div className={classes.topBar}></div>
                <CardContent>
                  <h1 style={{ textAlign: 'center' }}>No Forecast</h1>
                  <CloudBox name="" percent={0} hasPrediction={false} />
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    align={'center'}
                  >
                    {item.dayOfWeek}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    align={'center'}
                  >
                    {item.dateOfForecast}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        )
      })}
    </Grid>
  )
}
