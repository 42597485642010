import {
  setOrganizationRequiredModalMessage,
  setOrganizationRequiredModalOpen
} from 'client_side_state/slices/app'
import { useAppDispatch } from 'client_side_state/store'
import { useContext, useEffect, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import { IChangeEvent } from '@rjsf/core'
import MuiForm from '@rjsf/mui'
import {
  ErrorSchema,
  ErrorSchemaBuilder,
  RJSFSchema,
  UiSchema
} from '@rjsf/utils'
import validator from '@rjsf/validator-ajv8'
import { AxiosError, isAxiosError } from 'axios'
import Icon, { IconType } from 'components/Icons'
import DialogModal from 'components/Modals/DialogModal'
import { OrganizationRequiredMessage } from 'components/UserOrganization/OrganizationRequiredDialog'
import FormsUpdateCancelButtons from 'components/common/FormsUpdateCancelButtons'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { CanariesContext } from 'contexts/CanariesContext'
import { OrganizationsContext } from 'contexts/OrganizationsContext'
import { SnowflakeForecastModelsContext } from 'contexts/SnowflakeForecastModelsContext'
import { SnowflakeForecastModel } from 'lib/CloudCanariesRestfulAPI'

interface ForecastModelsEditModalProps {
  forecastModel: SnowflakeForecastModel
}

export default function ForecastModelsEditModal(
  props: ForecastModelsEditModalProps
) {
  const dispatch = useAppDispatch()
  const { apiService } = useContext(APIServiceContext)
  const { organizationId } = useContext(OrganizationsContext)
  const { canaries } = useContext(CanariesContext)
  const { fetchForecastModels } = useContext(SnowflakeForecastModelsContext)

  const { forecastModel } = props
  const forecastModelName = forecastModel.name!

  const [editModalOpen, setEditModalOpen] = useState<boolean>(false)
  const [formData, setFormData] = useState<any>({})
  const [extraErrors, setExtraErrors] = useState<ErrorSchema>({})

  useEffect(() => {
    const newFormData: any = {
      name: forecastModel.name,
      data_start_date: forecastModel.data_start_date,
      data_end_date: forecastModel.data_end_date,
      excluded_canaries: forecastModel.excluded_canaries
    }
    setFormData(newFormData)
  }, [
    forecastModel.data_end_date,
    forecastModel.data_start_date,
    forecastModel.excluded_canaries,
    forecastModel.name,
    forecastModelName
  ])

  const handleClickOpen = () => {
    if (!organizationId) {
      dispatch(
        setOrganizationRequiredModalMessage(
          OrganizationRequiredMessage.SnowflakeForecastModels
        )
      )
      dispatch(setOrganizationRequiredModalOpen(true))
    } else {
      setEditModalOpen(true)
    }
  }

  const handleClose = () => {
    setExtraErrors({})
    setEditModalOpen(false)
  }

  const onSubmit = async (
    data: IChangeEvent<any, RJSFSchema, any>,
    event: React.FormEvent<any>
  ) => {
    let requestBody = JSON.parse(JSON.stringify(data.formData))
    try {
      await apiService
        .partialUpdateSnowflakeForecastModel(
          forecastModel.id!,
          forecastModel.organization_id!,
          requestBody
        )
        .then(async (json) => {
          if (json.status === 200) {
            await fetchForecastModels()
            handleClose()
          }
        })
    } catch (e: any) {
      if (isAxiosError(e)) {
        const err = e as AxiosError
        if (err.response?.status === 400) {
          const errors = err.response?.data as {}
          const builder = new ErrorSchemaBuilder()
          Object.entries(errors).forEach(([key, value]) => {
            builder.addErrors(value as string, key)
          })
          setExtraErrors(builder.ErrorSchema)
        }
      }
    }
  }

  const schema = {
    type: 'object',
    required: ['name', 'data_start_date', 'data_end_date'],
    properties: {
      name: { type: 'string', title: 'Model Name' },
      data_start_date: {
        type: 'string',
        title: 'Data Start Date',
        format: 'date-time'
      },
      data_end_date: {
        type: 'string',
        title: 'Data End Date',
        format: 'date-time'
      },
      excluded_canaries: {
        type: 'array',
        uniqueItems: true,
        title: 'Excluded canaries from Snowflake forecasts',
        items: {
          type: 'string',
          enum: canaries?.map((canary) => canary.id || ''),
          enumNames: canaries?.map((canary) => canary.name || '')
        }
      },
      estimated_model_build_time: {
        title: 'Estimated model build time',
        description: 'Estimated model build time',
        type: 'null'
      },
      estimated_model_availability: {
        title: 'Estimated model availability',
        description: 'Estimated model availability',
        type: 'null'
      }
    }
  } as RJSFSchema

  const uiSchema: UiSchema = {
    excluded_canaries: {
      // 'ui:widget': 'checkboxes',
      // 'ui:options': {
      //   inline: true,
      // }
    }
  }

  return (
    <>
      <IconButton
        onClick={handleClickOpen}
        color="primary"
        size="small"
        style={{ marginRight: '0.5rem' }}
      >
        <Icon name={IconType.Edit} />
      </IconButton>
      {forecastModelName}
      <DialogModal
        open={editModalOpen}
        onClose={handleClose}
        dialogTitle="Edit Forecast Model"
        dialogContent={
          <MuiForm
            schema={schema}
            onSubmit={onSubmit}
            formData={formData}
            extraErrors={extraErrors}
            uiSchema={uiSchema}
            validator={validator}
          >
            <FormsUpdateCancelButtons cancelButtonOnClick={handleClose} />
          </MuiForm>
        }
      />
    </>
  )
}
