import {
  selectSelectedGraphs,
  setCanaryTaskSelectedGraphs
} from 'client_side_state/slices/pageFilter'
import { useAppDispatch } from 'client_side_state/store'
import { useAppSelector } from 'client_side_state/store'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Icon, { IconType } from 'components/Icons'
import { CanaryGraphMetrics } from 'constants/CanaryGraphMetrics'

const options = Object.entries(CanaryGraphMetrics).map(([key, value]) => ({
  label: key
    .toLowerCase()
    .split('_')
    .map((word) => word[0].toUpperCase() + word.substring(1))
    .join(' '),
  value: value
}))

export default function CanaryTaskGraphSelector() {
  const dispatch = useAppDispatch()

  const selected = useAppSelector(selectSelectedGraphs)

  const handleChange = (metricType: CanaryGraphMetrics) => {
    let filtered
    if (selected.includes(metricType)) {
      filtered = selected.filter((v) => {
        return v !== metricType
      })
    } else {
      filtered = selected.concat([metricType])
    }
    dispatch(setCanaryTaskSelectedGraphs(filtered))
  }

  return (
    <FormControl size="small">
      <Select
        value={''}
        displayEmpty
        renderValue={() => 'Panes'}
        inputProps={{ 'aria-label': 'Panes' }}
      >
        {options.map(({ label, value }) => (
          <MenuItem
            key={value}
            value={value}
            onClick={() => {
              handleChange(value)
            }}
          >
            {selected.includes(value) ? <Icon name={IconType.Check} /> : null}{' '}
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
