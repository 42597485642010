import { setServiceProviderOptionValueSelected } from 'client_side_state/slices/pageFilter'
import { useAppDispatch } from 'client_side_state/store'
import { useContext, useEffect, useMemo, useState } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Tab from '@mui/material/Tab'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Tabs from '@mui/material/Tabs'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import Box from '@mui/system/Box'
import CircularPercentages, {
  Segment
} from 'components/common/CircularPercentages'
import TabPanel from 'components/common/MUITabPanel'
import { ComplianceContext } from 'contexts/ComplianceContext'
import { OrganizationsContext } from 'contexts/OrganizationsContext'
import { ServiceComplianceSummary } from 'lib/CloudCanariesRestfulAPI'
import { secondsToHhMmSs } from 'services/utils'
import { ColorMeanings } from './utils'

const useStyles = makeStyles({
  complianceCard: {
    marginBottom: '16px',
    marginInline: '8px',
    width: '380px',
    height: '600px',
    cursor: 'pointer'
  },
  tab: {
    '& .MuiBox-root': {
      padding: '8px'
    },
    '& .MuiTableCell-root': {
      padding: '8px 4px'
    }
  },
  severityBar: {
    display: 'flex',
    width: '100%',
    height: 13,
    borderRadius: 8
  },
  organizationName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  tooltip: {
    fontSize: 18
  },
  grayedName: {
    color: '#000',
    opacity: 0.5
  }
})

interface GraphColors {
  percentage: number
  color: string
  name: string
}

interface ComplianceCardProps {
  complianceSummary: ServiceComplianceSummary
}

const initialColor: GraphColors = {
  percentage: 0,
  color: '',
  name: ''
}

export default function ComplianceCard(props: ComplianceCardProps) {
  const { complianceSummary } = props
  const dispatch = useAppDispatch()
  const { organizationId } = useContext(OrganizationsContext)

  const {
    serviceComplianceColors,
    serviceComplianceColorsSettings,
    selectedSummaryEvaluationId,
    resetSelectedService,
    updateSelectedServiceId,
    updateSelectedServiceName,
    updateSelectedServiceSummaryEvaluationId
  } = useContext(ComplianceContext)
  const localServiceComplianceColors = useMemo(
    () => serviceComplianceColors || serviceComplianceColorsSettings[0],
    [serviceComplianceColors, serviceComplianceColorsSettings]
  )

  const DEFAULT_COLORS = [
    localServiceComplianceColors.bar_missing_sla_with_more_50_period_completed!,
    localServiceComplianceColors!
      .bar_missing_sla_with_less_50_period_completed!,
    localServiceComplianceColors!.bar_meeting_sla!,
    '#FFFFFF'
  ]

  const [severityBarColor, setSeverityBarColor] = useState('')

  const [cardSelectedTabValue, setCardSelectedTabValue] = useState(0)

  const [redColor, setRedColor] = useState<GraphColors>({
    ...initialColor,
    color:
      localServiceComplianceColors.bar_missing_sla_with_more_50_period_completed!,
    name: ColorMeanings.AlarmOutOfCompliance
  })
  const [yellowColor, setYellowColor] = useState<GraphColors>({
    ...initialColor,
    color:
      localServiceComplianceColors.bar_missing_sla_with_less_50_period_completed!,
    name: ColorMeanings.WarningPossibleRiskToOutOfSLAComplianceWithin5
  })
  const [greenColor, setGreenColor] = useState<GraphColors>({
    ...initialColor,
    color: localServiceComplianceColors.bar_meeting_sla!,
    name: ColorMeanings.GoodMeetingOrHasMetSLATarget
  })

  const graphLabel = useMemo(() => {
    return complianceSummary.sla_seconds_percentage
  }, [complianceSummary.sla_seconds_percentage])

  const graphEmptyPercentage = useMemo<GraphColors>(() => {
    return {
      name: 'Remaining Period Days',
      color: '#FFFFFF',
      percentage:
        (complianceSummary.days_remaining_current! /
          complianceSummary.current_period_days!) *
        100
    }
  }, [
    complianceSummary.current_period_days,
    complianceSummary.days_remaining_current
  ])

  useEffect(() => {
    if (complianceSummary && localServiceComplianceColors) {
      if (
        complianceSummary.sla_seconds_percentage! >=
        complianceSummary.sla_target_goal
      ) {
        setSeverityBarColor(localServiceComplianceColors.bar_meeting_sla!)
        setGreenColor((prev) => ({
          ...prev,
          percentage: complianceSummary.sla_seconds_percentage!
        }))
      } else if (
        complianceSummary.sla_seconds_percentage! <
        complianceSummary.sla_target_goal!
      ) {
        if (
          (complianceSummary.current_period_days! -
            complianceSummary.days_remaining_current!) /
            complianceSummary.current_period_days! <
          0.5
        ) {
          setSeverityBarColor(
            localServiceComplianceColors.bar_missing_sla_with_less_50_period_completed!
          )
          setYellowColor((prev) => ({
            ...prev,
            percentage: complianceSummary.sla_seconds_percentage!
          }))
        } else if (
          (complianceSummary.current_period_days! -
            complianceSummary.days_remaining_current!) /
            complianceSummary.current_period_days! >=
          0.5
        ) {
          setSeverityBarColor(
            localServiceComplianceColors.bar_missing_sla_with_more_50_period_completed!
          )
          setRedColor((prev) => ({
            ...prev,
            percentage: complianceSummary.sla_seconds_percentage!
          }))
        }
      }
    }
  }, [
    complianceSummary,
    graphEmptyPercentage.percentage,
    localServiceComplianceColors
  ])

  const handleCardTabsChange = (event: any, newValue: number) => {
    event.stopPropagation()
    setCardSelectedTabValue(newValue)
  }

  const handleCheckBox = () => {
    if (complianceSummary.id === selectedSummaryEvaluationId) {
      resetSelectedService()
      dispatch(setServiceProviderOptionValueSelected(''))
    } else {
      updateSelectedServiceName(complianceSummary.service_name!)
      updateSelectedServiceId(complianceSummary.service!)
      updateSelectedServiceSummaryEvaluationId(complianceSummary.id!)
      dispatch(
        setServiceProviderOptionValueSelected(complianceSummary.provider_id!)
      )
    }
  }

  let segments: Segment[] = []
  Array.from([redColor, yellowColor, greenColor, graphEmptyPercentage]).forEach(
    (segment, index) => {
      if (segment !== undefined) {
        let percentage = segment.percentage
        while (percentage > 0) {
          const prevSegment = segments[segments.length - 1]
          segments.push({
            name: segment.name,
            color: DEFAULT_COLORS[index],
            percentage: Math.min(percentage, 25),
            startPercentage:
              segments.length === 0
                ? 0
                : prevSegment.startPercentage + prevSegment.percentage
          })
          percentage -= Math.min(percentage, 25)
        }
      }
    }
  )

  const classes = useStyles()

  return (
    <Card className={classes.complianceCard}>
      <Box
        component="span"
        className={classes.severityBar}
        sx={{ backgroundColor: severityBarColor }}
      />
      <div onClick={handleCheckBox}>
        <CardHeader
          avatar={
            <input
              type="checkbox"
              checked={complianceSummary.id === selectedSummaryEvaluationId}
              onChange={handleCheckBox}
            />
          }
          title={
            !Boolean(organizationId) ? (
              <Tooltip
                title={complianceSummary.organization_name!}
                placement="top"
                arrow
                classes={{
                  tooltip: classes.tooltip
                }}
              >
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  align="center"
                  className={classes.organizationName}
                >
                  {complianceSummary.organization_name}
                </Typography>
              </Tooltip>
            ) : null
          }
        />
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={3}>
            <Typography
              gutterBottom
              component="div"
              align="center"
              className={classes.grayedName}
            >
              Service
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Tooltip
              title={complianceSummary.service_name!}
              placement="top"
              arrow
              classes={{
                tooltip: classes.tooltip
              }}
            >
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                align="center"
                className={classes.organizationName}
              >
                {complianceSummary.service_name}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={3} />
        </Grid>
        <CardContent>
          <CircularPercentages
            segments={segments}
            label={`SLA\n${graphLabel?.toString()}%`}
          />
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={3}>
              <Typography
                gutterBottom
                component="div"
                align="left"
                className={classes.grayedName}
              >
                Provider
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Tooltip
                title={complianceSummary.provider_name!}
                placement="top"
                arrow
                classes={{
                  tooltip: classes.tooltip
                }}
              >
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  align="center"
                  className={classes.organizationName}
                >
                  {complianceSummary.provider_name}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={3} />
          </Grid>
          <hr />

          <Tabs
            value={cardSelectedTabValue}
            onChange={handleCardTabsChange}
            aria-label="Compliance Metrics"
            centered
            sx={{
              '& .MuiTabs-indicator': {
                backgroundColor: '#f50057'
              }
            }}
          >
            <Tab
              label={
                <Typography
                  variant="body1"
                  align="center"
                  sx={{
                    letterSpacing: '0.04rem'
                  }}
                >
                  Service SLA
                </Typography>
              }
              sx={{
                textTransform: 'capitalize',
                '&.Mui-selected': {
                  color: '#000'
                }
              }}
            />

            <Tab
              label={
                <Typography
                  variant="body1"
                  align="center"
                  sx={{
                    letterSpacing: '0.04rem'
                  }}
                >
                  Measurement
                </Typography>
              }
              sx={{
                textTransform: 'capitalize',
                '&.Mui-selected': {
                  color: '#000'
                }
              }}
            />
          </Tabs>

          <TabPanel
            value={cardSelectedTabValue}
            index={0}
            className={classes.tab}
          >
            <TableContainer component={Paper}>
              <Table aria-label="simple table" size={'small'}>
                <TableBody>
                  <TableRow>
                    <TableCell align="left">SLA Period From</TableCell>
                    <TableCell align="right">
                      {complianceSummary.period_from}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">SLA Period To</TableCell>
                    <TableCell align="right">
                      {complianceSummary.period_to}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">Remaining Days</TableCell>
                    <TableCell align="right">
                      {complianceSummary.days_remaining_current}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">Down Time</TableCell>
                    <TableCell align="right">
                      {secondsToHhMmSs(
                        complianceSummary.downtime_seconds_calculated ?? 0
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel
            value={cardSelectedTabValue}
            index={1}
            className={classes.tab}
          >
            <TableContainer component={Paper}>
              <Table aria-label="simple table" size={'small'}>
                <TableBody>
                  <TableRow>
                    <TableCell align="left">
                      Meantime To Recover System
                    </TableCell>
                    <TableCell align="right">
                      {secondsToHhMmSs(
                        complianceSummary.mean_time_recovery_system_seconds_calculated ??
                          0
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">
                      Meantime Between Incidents
                    </TableCell>
                    <TableCell align="right">
                      {secondsToHhMmSs(
                        complianceSummary.mean_time_between_system_incidents_seconds_calculated ??
                          0
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
        </CardContent>
      </div>
    </Card>
  )
}
