import {
  JSXElementConstructor,
  Key,
  ReactElement,
  ReactNode,
  ReactPortal
} from 'react'
import Grid from '@mui/material/Grid'
import { makeStyles } from '@mui/styles'
import { ObjectFieldTemplateProps } from '@rjsf/utils'

const useStyles = makeStyles({
  gridItem: {
    marginBottom: 8,
    '&:nth-child(5)': {
      paddingTop: 0
    },
    '& .MuiGrid-spacing-xs-2': {
      '&:nth-child(1)': {
        marginTop: 0
      }
    }
  }
})

export default function ObjectFieldTemplate({
  description,
  title,
  properties,
  required,
  schema,
  uiSchema,
  idSchema,
  registry
}: ObjectFieldTemplateProps) {
  const classes = useStyles()

  return (
    <Grid container spacing={2}>
      {properties.map(
        (
          element: {
            content:
              | string
              | number
              | boolean
              | ReactElement<any, string | JSXElementConstructor<any>>
              | Iterable<ReactNode>
              | ReactPortal
              | null
              | undefined
          },
          index: Key | null | undefined
        ) => (
          <Grid
            item
            xs={index === 0 || index === 3 ? 12 : 6}
            key={index}
            className={classes.gridItem}
          >
            {element.content}
          </Grid>
        )
      )}
    </Grid>
  )
}
