import { useContext, useState } from 'react'
import { toast } from 'react-toastify'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Icon, { IconType } from 'components/Icons'
import DialogModal from 'components/Modals/DialogModal'
import ModalSharedStyles from 'components/Modals/ModalSharedStyles'
import Toast from 'components/Toast'
import { APIKeysContext } from 'contexts/APIKeysContext'
import { APIServiceContext } from 'contexts/APIServiceContext'
import { OrganizationsContext } from 'contexts/OrganizationsContext'
import {
  OrganizationAPIKey,
  OrganizationAPIKeyUpdate
} from 'lib/CloudCanariesRestfulAPI'

interface DeactivateModalProps {
  apiKey: OrganizationAPIKey
}

export default function DeactivateModal(props: DeactivateModalProps) {
  const { apiKey } = props
  const classes = ModalSharedStyles()

  const { apiService } = useContext(APIServiceContext)
  const { organizationId } = useContext(OrganizationsContext)
  const { fetchAPIKeys } = useContext(APIKeysContext)

  const [revokeConfirmOpen, setRevokeConfirmOpen] = useState(false)

  const handleClickOpen = () => {
    setRevokeConfirmOpen(true)
  }

  const handleClose = () => {
    setRevokeConfirmOpen(false)
  }

  async function revokeAPIKey() {
    if (apiKey) {
      let revokedAPIKey: OrganizationAPIKeyUpdate = {
        name: apiKey.name,
        prefix: apiKey.prefix,
        organization: apiKey.organization,
        revoked: true
      }
      if (revokedAPIKey.prefix) {
        await apiService
          .partialUpdateOrganizationAPIKey(
            revokedAPIKey.prefix,
            organizationId,
            revokedAPIKey
          )
          .then((response) => {
            if (response.status === 200) {
              toast.success(
                <Toast
                  title="Success"
                  text={`API Key "${apiKey.name}" is revoked!`}
                />,
                {
                  position: 'top-right'
                }
              )
            } else {
              toast.error(
                <Toast
                  title="Error"
                  text="Something went wrong, please try again!"
                />,
                {
                  position: 'top-right'
                }
              )
            }
            fetchAPIKeys()
          })
      }
    }
  }

  const confirmDelete = () => {
    revokeAPIKey()
    handleClose()
  }

  return (
    <>
      <IconButton
        size="small"
        disabled={apiKey.revoked}
        onClick={handleClickOpen}
      >
        <Icon
          name={IconType.Revoke}
          color={apiKey.revoked ? 'disabled' : 'error'}
        />
      </IconButton>
      <DialogModal
        dialogTitle="Revoke API Key"
        open={revokeConfirmOpen}
        onClose={handleClose}
        dialogContent={
          <Typography variant="body1" align="center">
            {`Do you really want to revoke key "${apiKey.name}"?`}
          </Typography>
        }
        dialogActions={
          <>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              onClick={confirmDelete}
              variant="contained"
              className={classes.deleteButton}
              autoFocus
            >
              Revoke
            </Button>
          </>
        }
      />
    </>
  )
}
