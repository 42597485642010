import { Theme } from '@mui/material/styles'
import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles(({ spacing, breakpoints }: Theme) =>
  createStyles({
    loadingSkeletons: {
      display: 'flex',
      flexDirection: 'column'
    },
    skeletonsRow: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '0.25rem 0',
      alignItems: 'center'
    },
    skeleton: {
      margin: '0.25rem 0',
      borderRadius: 4,
      backgroundColor: 'rgba(0, 0, 0, 0.08)'
    },
    skeletonRoot: {
      margin: '0.5rem 0',
      backgroundColor: 'rgba(0, 0, 0, 0.15)'
    },
    centerRow: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center'
    },
    forecastCardsRow: {
      justifyContent: 'flex-start',
      marginTop: spacing(6)
    },
    forecastCard: {
      margin: spacing(0, 0.75)
    },
    cardsContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      margin: '1.25rem 0',
      [breakpoints.down('sm')]: {
        flexDirection: 'column',
        margin: '0 1rem',
        alignItems: 'center'
      }
    },
    card: {
      margin: '0 1rem',
      '&:nth-child(1)': {
        margin: '0',
        marginRight: '1rem',
        [breakpoints.down('sm')]: {
          margin: '1rem 0'
        }
      },
      '&:nth-child(4)': {
        margin: '0',
        marginLeft: '1rem',
        [breakpoints.down('sm')]: {
          margin: '1rem 0'
        }
      }
    }
  })
)
